import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import StatusRow from './StatusRow';
import DragIcon from '../../_shared/Icon/DragIcon';
import cx from 'classnames';
import { withStatusManageConsumer } from './Context/StatusManageProvider';

function StatusItem({
    id,
    commentStatus,
    editingComment,
    onCheckChanged,
    index,
    enableDragging
}) {
    const { attributes, listeners, setNodeRef, setHandleRef, transform, transition } = useSortable({
        id,
        // Restrict movement to the vertical axis
        restrictions: {
            x: 0,
            y: null
        }
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        backgroundColor:
            index % 2 === 0
                ? 'var(--secondary-background-color)'
                : 'var(--primary-background-color)',
        transition
    };

    return (
        <div ref={setNodeRef} style={style} className="sortable-item">
            <div className="sortable-item-handle">
                {enableDragging && (
                    <div
                        ref={setHandleRef}
                        {...attributes}
                        {...listeners}
                        className={cx({
                            'sortable-item-handle-button': true,
                            'authorization-disabled': editingComment !== undefined
                        })}>
                        <DragIcon />
                    </div>
                )}
            </div>
            <div className="sortable-item-content">
                <CommentStatusRowHOC
                    {...{
                        commentStatus,                                                
                        onCheckChanged,
                        index
                    }}
                />
            </div>
        </div>
    );
}

const CommentStatusRowHOC = withStatusManageConsumer(StatusRow);

export { StatusItem };
