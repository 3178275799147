import { authHeader, config, handleResponse } from '../../_helpers';

export const fileShareService = {
    getFileDownloadShareLink,
    updateFileDownloadShareLink,
    deleteFileDownloadShareLink,
    VerifyFileDownloadLink,
    VerifyFileDownloadOTP,
    sendFileShareMail,
    requestProofAnnotatedDownload,
    getAnnotationShareLink,
    verifyAnnotationShareLink,
    updateAnnotationShareLink
};

async function getFileDownloadShareLink(requestObject) {

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };

    return fetch(`${config.apiUrl}/fileshare/getfiledownloadsharelink`, requestOptions).then(handleResponse);
}

async function getAnnotationShareLink(requestObject) {

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };

    return fetch(`${config.apiUrl}/fileshare/getAnnotationShareLink`, requestOptions).then(handleResponse);
}

async function updateFileDownloadShareLink(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };

    return fetch(`${config.apiUrl}/fileshare/UpdateFileDownloadShareLink`, requestOptions).then(
        handleResponse,
    );
}

async function updateAnnotationShareLink(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };

    return fetch(`${config.apiUrl}/fileshare/UpdateAnnotationShareLink`, requestOptions).then(
        handleResponse,
    );
}

async function deleteFileDownloadShareLink(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };

    return fetch(`${config.apiUrl}/fileshare/DeleteFileDownloadShareLink`, requestOptions).then(
        handleResponse,
    );
}

async function VerifyFileDownloadLink(request, targetParam, isLegacy = false) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    var endpointLink = `${config.apiUrl}/fileshare/VerifyFileDownloadLink/${request}?isLegacy=${isLegacy}`
    endpointLink = targetParam? `${endpointLink}&targetParam=${targetParam}` : endpointLink;

    return fetch(endpointLink, requestOptions).then(
        handleResponse,
    );
}


async function verifyAnnotationShareLink(request, targetParam) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    var endpointLink = `${config.apiUrl}/fileshare/verifyAnnotationShareLink/${request}?`
    endpointLink = targetParam? `${endpointLink}targetParam=${targetParam}` : endpointLink;

    return fetch(endpointLink, requestOptions).then(
        handleResponse,
    );
}


async function VerifyFileDownloadOTP(request, targetParam, otp, isEditable = false) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    var endpointLink = `${config.apiUrl}/fileshare/${ isEditable === true? 'VerifyAnnotationShareOTP' : 'VerifyFileDownloadOTP'}/${request}/${otp}?targetParam=${targetParam}`

    return fetch(endpointLink, requestOptions).then(
        handleResponse,
    );
}

async function sendFileShareMail(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };

    return fetch(`${config.apiUrl}/fileshare/SendFileShareMail`, requestOptions).then(
        handleResponse,
    );
}

async function requestProofAnnotatedDownload(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };

    return fetch(`${config.apiUrl}/fileshare/RequestProofAnnotatedDownload`, requestOptions).then(
        handleResponse,
    );
}