export const directoryConstants = {
    ROOT_DIRECTORY_ID : '000000000000000000000000',
    ADD_REQUEST: 'DIRECTORY_ADD_REQUEST',
    ADD_SUCCESS: 'DIRECTORY_ADD_SUCCESS',
    ADD_FAILURE: 'DIRECTORY_ADD_FAILURE',

    GETALL_REQUEST: 'DIRECTORY_GETALL_REQUEST',
    GETALL_SUCCESS: 'DIRECTORY_GETALL_SUCCESS',
    GETALL_FAILURE: 'DIRECTORY_GETALL_FAILURE',

    GETALL_FILTERS_REQUEST: 'DIRECTORY_FILTERS_GETALL_REQUEST',
    GETALL_FILTERS_SUCCESS: 'DIRECTORY_FILTERS_GETALL_SUCCESS',
    GETALL_FILTERS_FAILURE: 'DIRECTORY_FILTERS_GETALL_FAILURE',
    
    DELETE_REQUEST: 'DIRECTORY_DELETE_REQUEST',
    DELETE_SUCCESS: 'DIRECTORY_DELETE_SUCCESS',
    DELETE_FAILURE: 'DIRECTORY_DELETE_FAILURE',
    
    IS_NAME_UNIQUE_REQUEST: 'DIRECTORY_IS_NAME_UNIQUE_REQUEST',
    IS_NAME_UNIQUE_SUCCESS: 'DIRECTORY_IS_NAME_UNIQUE_SUCCESS',
    IS_NAME_UNIQUE_FAILURE: 'DIRECTORY_IS_NAME_UNIQUE_FAILURE',
};
