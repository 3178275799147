import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { utilService } from '../../_helpers';
import { groupActions, MultiSelect, fileShareService, userService, tenantService } from '..';
import { alertActions } from '../App';
import { FILE_SHARE_SETTINGS_TYPES, FILE_SHARE_MESSAGE_LIMIT } from '../_shared/fileshare.constants';

class AnnotationShareModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            linkShareObject: { expirationInDays: 30 },
            defaultMessage: '',
            messageBody: '',
            originalCombinedMembers: [],
            originalMembers: [],
            filteredMembers: [],
            selectedMembers: [],
            fileType: 4,
            fileShareSettingType: 2,
            requireOTP: false
        };
    }

    async componentDidMount() {
        var { name: fileName } = { ...this.props.proofVersion };
        var { authData: userFromService } = await userService.getCurrentUser();
        var { name: tenantName } = await tenantService.getCurrentTenantObject();

        var messageBody = `${userFromService.fullName} has shared the following file from ${tenantName} ->\n${fileName}`;
        var proofSettings = this.props.proofSettings;

        this.setState({
            messageBody,
            defaultMessage: messageBody,
            fileType:
                proofSettings?.fileShareSettingType === 0 ||
                proofSettings?.fileShareSettingType == 1
                    ? proofSettings.defaultFileShareType
                    : proofSettings.defaultFileShareType,
            fileShareSettingType: proofSettings?.fileShareSettingType || 2,
            requireOTP: proofSettings?.requireOTP
        });
        await this.loadMembers();
        await this.loadShareLink(
            proofSettings.defaultFileShareType,
            this.state.linkShareObject.expirationInDays
        );
    }

    async loadShareLink(fileType, expirationInDays) {
        var request = {
            expirationInDays: expirationInDays,
            proofId: this.props.proofId,
            proofVersionId: this.props.proofVersion.id,
            fileType: 4
        };
        var shareLinkResponse = await fileShareService.getAnnotationShareLink(request);

        this.setState({ linkShareObject: shareLinkResponse });
    }

    async loadMembers() {
        var membersData = await this.props.dispatch(groupActions.getAllMembers({}));
        var rowsTemp = [...membersData];
        const rows = rowsTemp.map((item) => {
            var newItem = { ...item };
            if (newItem.memberType === 1) {
                newItem.label = `${newItem.label} (${newItem.email})`;
            }
            return newItem;
        });

        var users = rows.filter((x) => x.memberType === 1);
        var groups = rows.filter((x) => x.memberType === 2);
        var groupedOptions = [];
        if (users && users.length > 0) {
            groupedOptions.push({
                label: 'Users',
                options: users
            });
        }
        if (groups && groups.length > 0) {
            groupedOptions.push({
                label: 'Groups',
                options: groups
            });
        }
        await utilService.setStateAsync.bind(this)({
            originalCombinedMembers: membersData,
            originalMembers: groupedOptions,
            filteredMembers: groupedOptions
        });
    }

    async onChangeUsersMultiSelect(selectedOptions) {
        console.log(selectedOptions);
        selectedOptions = selectedOptions ? selectedOptions : [];

        const validEntry = (x) => !x.__isNew__ || (x.__isNew__ && x.value && !userService.validateEmail(x.value.trim()));

        var validated = [];
        selectedOptions.forEach((x) => {
            if(x.__isNew__ === true) {
                var entries = x.value.split(';');
                if(entries.length > 1) {                   
                    entries.forEach((entry)=> {
                        let item = {label : entry, value: entry, __isNew__: x.__isNew__};
                        if(validEntry(item)) {
                            validated.push(item);
                        }
                    }) 
                }
                else {
                    if(validEntry(x)) {
                        validated.push(x);
                    } 
                }
                
            }else {
                if(validEntry(x)) {
                    validated.push(x);
                }
            }               
        });
        this.setState({ selectedMembers: validated || [] });
    }

    handleChange(event) {
        var { name, value } = event.target;
        this.setState({
            messageBody: value,
            submitted: false
        });
    }

    async onFileShareChange(event) {
        this.props.dispatch(alertActions.loading(true));
        var { name, value } = event.target;
        const { linkShareObject } = this.state;

        await utilService.setStateAsync.bind(this)({
            linkShareObject: {
                ...linkShareObject,
                expirationInDays: value
            },
            submitted: false
        });

        if (!value || value < 1 || value > 365) {
            await utilService.setStateAsync.bind(this)({
                validated: false,
                validationMessage: 'Value must be between 1 and 365'
            });
            return;
        }
        var request = {
            expirationInDays: value,
            proofId: this.props.proofId,
            proofVersionId: this.props.proofVersion.id
        };
        var shareLinkResponse = await fileShareService.updateAnnotationShareLink(request);

        await utilService.setStateAsync.bind(this)({
            linkShareObject: shareLinkResponse,
            validated: true
        });
        this.props.dispatch(alertActions.loading(false));
    }

    async onFileTypeChange(e) {
        this.props.dispatch(alertActions.loading(true));
        let { fileType, linkShareObject } = this.state;
        let fileTypeToggled = fileType === 1 ? 4 : 1;

        let expirationInDays = fileTypeToggled === 1 ? 30 : 1;
        this.setState({
            fileType: fileTypeToggled
        });

        await this.loadShareLink(fileTypeToggled, expirationInDays);
        this.props.dispatch(alertActions.loading(false));
    }

    async handleOnDelete() {
        var request = {
            linkToken: this.state.linkShareObject.linkToken,
            proofId: this.props.proofId,
            proofVersionId: this.props.proofVersion.id
        };
        await fileShareService.deleteFileDownloadShareLink(request);

        this.props.dispatch(alertActions.success('Share Link Deleted !'));
        // this.onModalClose();
        await this.onFileTypeChange();
    }

    async onSendMailClick() {
        this.props.dispatch(alertActions.loading(true));
        var linkShareObject = { ...this.state.linkShareObject };
        var members = this.state.selectedMembers.map((x) => {
            if (x.__isNew__) {
                return {
                    name: 'Anonymous User',
                    email: x.value,
                    memberType: 3
                };
            } else {
                return x;
            }
        });
        var requestData = {
            id: linkShareObject.id,
            proofId: linkShareObject.proofId,
            proofVersionId: linkShareObject.proofVersionId,
            members: members,
            messageBody: this.state.messageBody,
            fileType: 4
        };
        await fileShareService.sendFileShareMail(requestData);
        this.props.dispatch(alertActions.success('Mail Sent !'));
        this.resetMessageBox();
        this.props.dispatch(alertActions.loading(false));
    }

    resetMessageBox() {
        this.setState({
            messageBody: this.state.defaultMessage,
            selectedMembers: []
        });
    }

    validateSendMailButton() {
        var members = [...this.state.selectedMembers];
        if (members && members.length > 0 && !this.props.isProcessing) {
            return false;
        } else {
            return true;
        }
    }

    onModalClose() {
        this.props.onHide();
    }

    render() {
        const { fileType, linkShareObject, fileShareSettingType, requireOTP } = this.state;

        const isFileDownload = fileType === 1 ? true : false;

        const isForEveryone = fileShareSettingType === FILE_SHARE_SETTINGS_TYPES.everyone.value;

        const shareLink = isFileDownload
            ? `${window.location.origin}/#/share/${linkShareObject.linkToken}`
            : `${window.location.href}`;

        // const hideLink =
        //     (fileShareSettingType === FILE_SHARE_SETTINGS_TYPES.registered.value ||
        //         fileShareSettingType === FILE_SHARE_SETTINGS_TYPES.everyone.value) &&
        //     requireOTP && isFileDownload;

        const hideLink = true;

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'}
                scrollable>
                <Modal.Header closeButton>
                    <Modal.Title
                        className="pre-wrap-modal-title"
                        id="contained-modal-title-vcenter">
                        <h4>Share : {this.props.proofVersion.name}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ minHeight: '320px' }}>
                        <div className={'form-group'}>
                            <label htmlFor="selection">Send to</label>
                            <MultiSelect
                                isMulti={true}
                                options={this.state.filteredMembers}
                                selectedOptions={this.state.selectedMembers}
                                onChange={(selectedOptions, addedUserIds, removedUserIds) =>
                                    this.onChangeUsersMultiSelect(
                                        selectedOptions,
                                        addedUserIds,
                                        removedUserIds
                                    )
                                }
                                onFocus={() => {}}
                                closeMenuOnSelect={true}
                                isCreatable={true}
                                createLabelText={'Add Email'}
                            />
                        </div>
                        <div className={'form-group'}>
                            <label htmlFor="messageBody">Message Body</label>
                            <textarea
                                className="form-control mrnda-input-textarea"
                                style={{ resize: 'none', height: '150px' }}
                                name="messageBody"
                                value={this.state.messageBody || ''}
                                onChange={this.handleChange.bind(this)}
                                maxLength={FILE_SHARE_MESSAGE_LIMIT}
                            />
                        </div>

                        {!hideLink && (
                            <div className={'form-group'}>
                                <label htmlFor="link">Link</label>
                                <input
                                    style={{ fontSize: '12px' }}
                                    type="text"
                                    className="form-control form-control-themed"
                                    name="link"
                                    value={`${shareLink}` || ''}
                                />
                            </div>
                        )}

                        
                            <div className="form-group pt-2" style={{ display: 'flex', alignItems: 'center' }}>
                                <label htmlFor="expiresIn">Expires in</label>
                                <input
                                    type="number"
                                    min="1"
                                    max="365"
                                    className="mrnda-input-text"
                                    name="expiresIn"
                                    style={{
                                        height: '32px',                                        
                                        margin: '0px 12px 0px 12px',
                                        textAlign: 'center',
                                        width: '100px'                                        
                                    }}
                                    value={linkShareObject.expirationInDays || ''}
                                    onChange={this.onFileShareChange.bind(this)}
                                />
                                <label htmlFor="link">
                                    {linkShareObject.expirationInDays > 1 ? `Days` : `Day`}
                                </label>
                                {!this.state.validated && (
                                    <div className="help-block">{this.state.validationMessage}</div>
                                )}
                            </div>
                            
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn mrnda-btn"
                        style={{ padding: 10, display: 'block' }}
                        onClick={this.onSendMailClick.bind(this)}
                        disabled={this.validateSendMailButton()}>
                        <i className="far fa-paper-plane mr-2" />
                        Send Email
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    const { alert } = state;
    return {
        isProcessing: alert && alert.isLoading ? true : false
    };
}

const connectedCreateProofModel = connect(mapStateToProps)(AnnotationShareModal);
export { connectedCreateProofModel as AnnotationShareModal };
