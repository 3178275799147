//@ts-check

import React from 'react';

const ReplyIcon = ({ height = 14, width = 14, color = 'currentColor' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 14 14"
            fill="none">
            <mask id="path-1-inside-1_778_123" fill="white">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 2C11.1046 2 12 2.89543 12 4V8C12 9.10457 11.1046 10 10 10H5L2.85355 12.1464C2.53857 12.4614 2 12.2383 2 11.7929V10V7V4C2 2.89543 2.89543 2 4 2H10Z"
                />
            </mask>
            <path
                d="M5 10L4.29289 9.29289C4.48043 9.10536 4.73478 9 5 9V10ZM11 4C11 3.44772 10.5523 3 10 3V1C11.6569 1 13 2.34315 13 4H11ZM11 8V4H13V8H11ZM10 9C10.5523 9 11 8.55228 11 8H13C13 9.65685 11.6569 11 10 11V9ZM5 9H10V11H5V9ZM2.14645 11.4393L4.29289 9.29289L5.70711 10.7071L3.56066 12.8536L2.14645 11.4393ZM3 11.7929C3 11.3474 2.46143 11.1244 2.14645 11.4393L3.56066 12.8536C2.61571 13.7985 1 13.1292 1 11.7929H3ZM3 10V11.7929H1V10H3ZM3 7V10H1V7H3ZM3 4V7H1V4H3ZM4 3C3.44772 3 3 3.44772 3 4H1C1 2.34315 2.34315 1 4 1V3ZM10 3H4V1H10V3Z"
                fill={color}                
                mask="url(#path-1-inside-1_778_123)"
            />
        </svg>
    );
};

export default ReplyIcon;
