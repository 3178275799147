import serviceJson from '../assets/serviceConfig.json';
const subdomain = window.location.hostname.split('.')[0];
const config = {
    // apiUrl: process.env.API_URL,
    apiUrl: process.env.API_URL.replace('://', `://${subdomain}.`),
    docUrl: `${serviceJson['serviceHost']}${serviceJson['serviceApiPath']}`,
    documentServiceHost: `${process.env.DOC_SERVICE_HOST}`,
    documentServiceApiPath: `${process.env.DOC_SERVICE_API_PATH}`,
    documentServiceUrl: `${process.env.DOC_SERVICE_HOST}/${process.env.DOC_SERVICE_API_PATH}`,
    liveUpdateApi: process.env.LIVE_UPDATE_API,
    liveUpdateWSURL: process.env.LIVE_UPDATE_WS_URL,
    awsS3Key: process.env.AWS_S3_KEY,
    oktaGatewayLoginUrl: process.env.OKTA_GATEWAY_LOGIN_URL,
    editThresholdInMinute: process.env.EDIT_THRESHOLD_IN_MINUTE
};

export { config };
