import React from 'react';
import { connect } from 'react-redux';
import {
    proofService,
    proofActions,
    alertActions,
    tenantService,
    CreateProofVersionModal,
    ProofCompareModal,
    ConfirmationModal,
    DirectoryMoveModal,
    s3Service,
    authorizationConstants,
    AuthorizationComponent,
    directoryActions,
    ProofMoveModal,
    ProofCopyModal,
    DirectoryPathStatic,
    directoryConstants,
    FileShareModal
} from '../../../components';

import {
    ProofEditButton,
    AutoCompareButton,
    CreateProofVersionButton,
    MoveProofButton,
    CopyProofButton,
    DeleteProofButton,
    DeleteProofVersionButton,
    SetDefaultButton,
    DownloadVersionButton,
    DowloadOriginalVersionButton,
    DowloadAnnotatedVersionButton,
    ShareVersionButton,
    LockUnlockButton,
    ProofSummaryButton
} from './ProofViewerComponents';
import { history, utilService, config } from '../../../_helpers';
import './ProofViewer.css';
import { ProofCompareGlobalModal } from '../ProofCompare/ProofCompareGlobalModal';
import ProofViewerDocument from './ProofViewerDocument';
import ProofViewerVideo from './ProofViewerVideo';
import { fileShareService } from '../../_shared';
import { CreateProofModal } from '..';
import { CREATE_PROOF_MODE, PROOF_TYPE } from '../proof.constants';
import withLiveUpdateHook from '../LiveUpdateWrapper';
import { FILE_SHARE_SETTINGS_TYPES } from '../../_shared/fileshare.constants';
import ProofVersionStatus from './ProofVersionStatus';
import cx from 'classnames';
import { ProofViewerCloseButton, ViewerTitlebar } from './ViewerTitlebar';
import { isCompactNav } from '../../../_helpers/navbarUtil';


class ProofViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            proof: {},
            proofAvailable: false,
            proofHasProcessingError: false,
            currentVersionId: '',
            currentVersion: {},
            proofVersions: [],
            proccesingDownload: false,
            documentReady: false,

            tenantName: tenantService.getCurrentTenant(),
            uploadPath: '',

            treeData: [],
            currentDirectoryNode: {},

            downloadRequested: false,

            showCreateNewProofVersion: false,
            showProofCompareModal: false,
            showDeleteProofVersionConfirmModal: false,
            showDeleteProofConfirmModal: false,
            showProofMoveModal: false,
            showProofCopyModal: false,
            showFileShareModal: false,
            showProofCopyConfirmModal: false,

            hasBackRoute: false,
            proofSettings: {},
            historyLength: undefined
        };

        this.changeLiveUpdateTarget();
    }

    changeLiveUpdateTarget = () => {
        const targets = [
            {
                key: 'Dashboard',
                value: 'dashboard/ProofProcessingCompleted'
            }
        ];
        this.props.liveUpdateHook.changeTarget(targets);
        this.props.liveUpdateHook.setProcessingCompletedListener(
            this.handleProcessingCompleted.bind(this)
        );
    };

    handleProcessingCompleted(data) {
        // var { proof, version } = data;
        // this.changeStateProperties(version);

        // var modified = this.state.proofVersions.map((v)=>{

        //     if(v.id === version.id){
        //         v = version;
        //     }

        //     return v;
        // });

        // this.setStateAsync({
        //     proofVersions: modified
        // });
        this.onComponentLoad(this.state.proof.id, this.state.currentVersion.id);
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async onProofNavigate(proofId, currentVersionId) {
        history.replace({
            pathname: `/proof/${proofId}/${currentVersionId}`
            // data: this.state.proof,
        });
        // this.resetViewer();
        await this.onComponentLoad(proofId, currentVersionId);
    }

    async componentDidMount() {
        const { proofid: proofId, versionid: versionId } = this.props.match.params;
        const { hasBackRoute } = this.props.location;
        this.setState({ hasBackRoute });
        this.keepHistoryIndex();
        await this.onComponentLoad(proofId, versionId);
    }

    keepHistoryIndex() {
        const length = window.history.length;
        this.setState({ historyLength: length - 1 });
    }
    async onComponentLoad(proofId, versionId) {
        if (!proofId || !versionId) {
            history.push('/dashboard/folders');
            return;
        }
        var proof = proofId && (await this.props.dispatch(proofActions.get(proofId)));

        proof.fileExtention = utilService.getFileExtention(proof.documentName).toUpperCase();
        await this.setStateAsync({
            currentVersionId: versionId
        });
        var tenant = await tenantService.getCurrentTenantObject();
        var proofSettings = await tenantService.getProofSettings();
        await this.setStateAsync({
            proof: proof,
            uploadPath: `${tenant.id}/${proof.id}`,
            proofSettings
        });

        await this.loadAndSetProofVersions();
        await this.reFetchAndReload();
    }

    async loadAndSetProofVersions() {
        var proofVersions = await proofService.getProofVersions(this.state.proof.id);
        await this.setStateAsync({
            proofVersions: Object.values(proofVersions)
        });
        return proofVersions;
    }

    async reFetchAndReload() {
        await utilService.setStateAsync.bind(this)({
            documentReady: false
        });
        var currentVersion = this.state.proofVersions.find(
            (x) => x.id === this.state.currentVersionId
        );
        await this.changeStateProperties(currentVersion);
        // await this.initDocumentViewer();
    }

    async changeStateProperties(version) {
        let currentVersion = version;
        var proofAvailable =
            currentVersion.mediaType === 2 ||
            currentVersion.mediaType === 4 ||
            currentVersion.mediaType === 5 ||
            currentVersion.mediaType === 6
                ? currentVersion.status &&
                  currentVersion.status.thumbnailServiceStatus === 2 &&
                  currentVersion.status.metadataServiceStatus === 2 &&
                  currentVersion.status.conversionServiceStatus === 2
                : currentVersion.status.documentProcessingStatus === 2;

        var proofHasProcessingError =
            (currentVersion.status && currentVersion.status.thumbnailServiceStatus === 999) ||
            currentVersion.status.metadataServiceStatus === 999 ||
            currentVersion.status.conversionServiceStatus === 999 ||
            currentVersion.status.htmlStaticToImageStatus === 999 ||
            currentVersion.status.htmlDynamicStatus === 999;

        await this.setStateAsync({
            // proofVersions: Object.values(proofVersions),
            currentVersion,
            currentVersionId: currentVersion.id,
            proofAvailable,
            proofHasProcessingError
        });

        if (currentVersion?.mediaType !== 2 && proofAvailable) {
            // var annotations = currentVersion.annotations;
            // if (annotations) {
            //     var annotationsParamObj = {
            //         documentId: currentVersion.id,
            //         pageNumber: 0,
            //         annotations
            //     };
            //     await proofService.setAnnotations(annotationsParamObj);
            // }
            await utilService.setStateAsync.bind(this)({
                documentReady: true
            });
        }
    }

    onEdit() {
        let baseRoute =
            this.state.currentVersion.mediaType === 2 || this.state.currentVersion.mediaType === 4
                ? '/proof/video'
                : '/proof/document';
        // @ts-ignore
        history.push({
            pathname: `${baseRoute}/${this.state.proof.id}/${this.state.currentVersion.id}`,
            data: this.state.proof
        });
    }

    onCreateNewProofVersionClick() {
        this.setState({
            showCreateNewProofVersion: true
        });
    }

    renderVersionLabel() {
        var enableDefaultLabel = false;
        if (this.state.proof.defaultVersionId === this.state.currentVersion.id) {
            enableDefaultLabel = true;
        }
        return (
            <>
                {' '}
                {enableDefaultLabel && (
                    <>
                        <span className="mrnda-tag--outline">Default</span>
                    </>
                )}{' '}
            </>
        );
    }

    createNewProofModalClose() {
        this.changeLiveUpdateTarget();
        this.setState({
            showCreateNewProofVersion: false
        });
    }

    proofCompareModalClose() {
        this.setState({
            showProofCompareModal: false
        });
    }

    async onProofVersionSelectorChange(e) {
        var currentVersionId = e.target.value;
        var currentVersion = this.state.proofVersions.find((x) => x.id === currentVersionId);
        await this.setStateAsync({
            currentVersionId: currentVersionId,
            currentVersion: currentVersion
        });
        await this.onProofNavigate(this.state.proof.id, currentVersionId);
    }

    async onFileUploadCompleted(newVersion) {
        var newProofState = {
            ...this.state.proof
        };

        //Dirty fix for documents
        newVersion.pages = [];
        newProofState.defaultVersionId = newVersion.id;
        const newProofVersions = [...this.state.proofVersions, newVersion];
        await this.setStateAsync({
            currentVersionId: newVersion.id,
            currentVersion: newVersion,
            proof: newProofState,
            proofVersions: newProofVersions
        });
        await this.onProofNavigate(this.state.proof.id, newVersion.id);
    }

    async onMakeDefaultClick() {
        if (this.state.proof.defaultVersionId !== this.state.currentVersion.id) {
            var newProofState = {
                ...this.state.proof
            };
            newProofState.defaultVersionId = this.state.currentVersion.id;
            var proofVersionIngestRequestObj = {
                id: this.state.proof.id,
                defaultVersionId: this.state.currentVersion.id
            };
            this.props.dispatch(alertActions.loading(true));
            await this.props.dispatch(proofActions.ingestProof(proofVersionIngestRequestObj));
            this.props.dispatch(alertActions.success('Default Set Successfully !'));
            await this.setStateAsync({
                proof: newProofState
            });
            this.props.dispatch(alertActions.loading(false));
        }
    }

    onAutoCompareClick() {
        this.setState({
            showProofCompareModal: true
        });
    }

    onProofSummaryClick() {
        // let baseRoute =
        //     this.state.currentVersion.mediaType === 2 ? '/proof/videoreport' : '/proof/report';
        let baseRoute = '/proof/annotationreport';
        const url = `${window.origin}/#${baseRoute}/${this.state.proof.id}/${this.state.currentVersion.id}`;
        window.open(url, '_blank');
    }

    onDeleteProof() {
        this.setState({
            showDeleteProofConfirmModal: true
        });
    }

    async onCopyProofYesClick() {}

    onCopyProofNoClick() {
        this.setState({
            showProofCopyConfirmModal: false
        });
    }

    async onCopyProof() {
        await this.loadDirectoryDataFromServer();
        await utilService.setStateAsync.bind(this)({
            showProofCopyModal: true
        });
    }

    async onMoveProof() {
        await this.loadDirectoryDataFromServer();
        await utilService.setStateAsync.bind(this)({
            showProofMoveModal: true
        });
    }

    async loadDirectoryDataFromServer() {
        var directories = await this.props.dispatch(directoryActions.getPermittedAll({}));
        var node = utilService.searchTree(directories[0], this.state.proof.directoryNodeId, 'key');
        await utilService.setStateAsync.bind(this)({
            treeData: directories,
            currentDirectoryNode: node
        });
    }

    async handleMoveDirectoryOnClose(hasChanges) {
        this.setState({ showProofMoveModal: false });
        if (hasChanges) {
            await this.onComponentLoad(this.state.proof.id, this.state.currentVersion.id);
        }
    }

    async onDeleteProofYesClick() {
        //delete proof
        this.props.dispatch(alertActions.loading(true));
        await this.props.dispatch(proofActions.delete(this.state.proof));
        this.props.dispatch(alertActions.success('Deleted !'));
        await this.setStateAsync({
            showDeleteProofConfirmModal: false
        });
        this.onCloseClick();
    }

    onDeleteProofNoClick() {
        this.setState({
            showDeleteProofConfirmModal: false
        });
    }

    onDeleteProofVersion() {
        this.setState({
            showDeleteProofVersionConfirmModal: true
        });
    }

    async onDeleteProofVersionYesClick() {
        //delete the version
        this.props.dispatch(alertActions.loading(true));
        var proofVersionDeletePayload = this.state.currentVersion;
        var indexOfDeleted = this.state.proofVersions.indexOf(proofVersionDeletePayload);
        proofVersionDeletePayload.proofId = this.state.proof.id;
        proofVersionDeletePayload.proofVersionId = proofVersionDeletePayload.id;
        await this.props.dispatch(proofActions.deleteProofVersion(proofVersionDeletePayload));
        this.props.dispatch(alertActions.success('Deleted !'));
        await this.setStateAsync({
            showDeleteProofVersionConfirmModal: false
        });

        //Refetch and reload with new versions
        var proofVersions = [...this.state.proofVersions];
        const proofVersionsTemp = proofVersions.map((item) => {
            var newItem = { ...item };
            return newItem;
        });
        var newProofVerions = proofVersionsTemp.filter(
            (x) => x.id !== proofVersionDeletePayload.proofVersionId
        );
        var newCurrentVersion = null;
        if (indexOfDeleted === 0) {
            newCurrentVersion = newProofVerions[0];
        } else {
            newCurrentVersion = newProofVerions[indexOfDeleted - 1];
        }
        await this.setStateAsync({
            proofVersions: newProofVerions,
            currentVersion: newCurrentVersion,
            currentVersionId: newCurrentVersion.id
        });
        await this.onProofNavigate(this.state.proof.id, newCurrentVersion.id);
    }

    onDeleteProofVersionNoClick() {
        this.setState({
            showDeleteProofVersionConfirmModal: false
        });
    }

    getFileTypeName(proofVersion) {
        switch (proofVersion.mediaType) {
            case 1:
            case 2:
                if (!proofVersion?.name) return '';
                const name = proofVersion.name;
                const lastDot = name.lastIndexOf('.');
                return name.substring(lastDot + 1);
            case PROOF_TYPE.HTMLStatic:
            case PROOF_TYPE.HTMLDynamic:
                return 'HTML';
            case PROOF_TYPE.HTMLLive:
            case PROOF_TYPE.HTMLDynamicLive:
                return 'Website';
            default:
                return 'Unknown';
        }
    }

    isThisVersionDefault(versionId) {
        var result = false;
        if (versionId === this.state.proof.defaultVersionId) result = true;
        return result;
    }

    skippedBack() {
        if (this.state.historyLength) {
            const length = window.history.length;
            var diff = this.state.historyLength - length;
            history.go(diff);
        } else {
            history.goBack();
        }
    }

    onCloseClick() {
        if (
            this.state.currentVersion.mediaType !== PROOF_TYPE.HTMLLive ||
            this.state.currentVersion.mediaType !== PROOF_TYPE.HTMLDynamicLive
        ) {
            this.skippedBack();
            return;
        }

        //Back for normal files
        if (this.state.hasBackRoute) {
            history.goBack();
        } else {
            // if(this.state.proof.directoryNodeId == undefined){
            //     //if user clicks back button very quickly in annotation editor and viewer sequentially
            //     //then proof is null and the path redirects to dashboard, that creates an unexpected result.
            //     //so we are preventing the back when there is not proof
            //     history.goBack();
            //     return;
            // }
            history.replace({
                pathname: `/dashboard/folders/${this.state.proof.directoryNodeId}`
            });
        }
    }

    async onDownloadClick() {
        var { currentVersion } = this.state;
        var payload = {
            proofId: this.state.proof.id,
            proofVersionId: currentVersion.id
        };
        await fileShareService.requestProofAnnotatedDownload(payload);
        this.props.dispatch(
            alertActions.success("Download Requested ! You'll get the link in email")
        );
        this.setState({ downloadRequested: true });
    }
    async onDownloadOriginalClick() {
        // event.preventDefault();
        var request = {
            proofId: this.state.proof.id,
            proofVersionId: this.state.currentVersion.id
        };
        var response = await this.props.dispatch(proofActions.getMainFileDownloadLink(request));

        if (response) {
            window.location.href = response.downloadURL;
        }
    }
    async onShareClick() {
        this.setState({
            showFileShareModal: true
        });
    }

    async onLockUnlockClick() {
        this.props.dispatch(alertActions.loading(true));
        const lockStatus = this.state.currentVersion.isLocked;
        var currentVersion = { ...this.state.currentVersion };
        currentVersion.isLocked = !lockStatus;
        var request = {
            proofId: this.state.proof.id,
            proofVersionId: this.state.currentVersion.id,
            isLocked: !lockStatus
        };

        var responseProofVersion = await proofService.lockToggleProofVersion(request);
        var mutatedVersions = this.state.proofVersions.map((item) => {
            var newItem = { ...item };
            if (item.id == responseProofVersion.id) {
                newItem = {
                    ...item,
                    isLocked: responseProofVersion.isLocked,
                    lockChangedAt: responseProofVersion.lockChangedAt,
                    lockChangedBy: responseProofVersion.lockChangedBy,
                    lockChangedById: responseProofVersion.lockChangedById
                };
            }
            return newItem;
        });

        var updatedProofVersion = mutatedVersions.find((x) => x.id === this.state.currentVersionId);

        await utilService.setStateAsync.bind(this)({
            currentVersion: updatedProofVersion,
            proofVersions: mutatedVersions
        });
        this.props.dispatch(alertActions.loading(false));
        this.props.dispatch(alertActions.success(lockStatus ? 'Unlocked !' : 'Locked !'));
    }

    render() {
        var { currentVersion, proofVersions, proofSettings } = this.state;
        const fileShareEnabled =
            proofSettings?.fileShareSettingType != FILE_SHARE_SETTINGS_TYPES.none.value;
        let proofViewerContainer;

        if (this.state.proofHasProcessingError) {
            proofViewerContainer = (
                <div className="d-flex">
                    <div className="mrnda-empty-state-wrapper">
                        <div className="mrnda-empty-state">
                            <i
                                style={{ fontSize: '40px', marginBottom: '12px' }}
                                className="fas fa-exclamation-circle typography--muted text-danger"></i>
                            <p className="typography--h1 typography--light typography--muted margin-1x--bottom">
                                Something went wrong
                            </p>
                            <p className="typography--h2 typography--light typography--muted">
                                {currentVersion?.status?.documentConvertionErrorReason ||
                                    "The system couldn't process the file properly"}
                            </p>
                        </div>
                    </div>
                </div>
            );
        } else if (!this.state.proofAvailable) {
            proofViewerContainer = (
                <div className="d-flex">
                    <div className="mrnda-empty-state-wrapper">
                        <div className="mrnda-empty-state">
                            <i
                                style={{ fontSize: '40px', marginBottom: '12px' }}
                                className="fas fa-hourglass-half typography--muted"></i>
                            <p className="typography--h1 typography--light typography--muted">
                                Still Processing...
                            </p>
                            <p className="typography--h2 typography--light typography--muted">
                                Check back in few seconds
                            </p>
                        </div>
                    </div>
                </div>
            );
        } else {
            console.log('Executing: ' + currentVersion.mediaType);

            if (currentVersion?.mediaType === 2 || currentVersion.mediaType === 4) {
                proofViewerContainer = <ProofViewerVideo proofVersion={{ ...currentVersion }} />;
            } else {
                proofViewerContainer = (
                    <ProofViewerDocument
                        proofVersion={{ ...currentVersion }}
                        documentReady={this.state.documentReady}
                    />
                );
            }
        }

        return (
        <>
            <ViewerTitlebar
            onEdit={this.onEdit.bind(this)}
            proofAvailable={this.state.proofAvailable}
            onCreateNewProofVersionClick={this.onCreateNewProofVersionClick.bind(this)}
            onAutoCompareClick={this.onAutoCompareClick.bind(this)}
            onProofSummaryClick={this.onProofSummaryClick.bind(this)}
            onCopyProof={this.onCopyProof.bind(this)}
            onMoveProof={this.onMoveProof.bind(this)}
            onDeleteProof={this.onDeleteProof.bind(this)}
            onCloseClick={this.onCloseClick.bind(this)}
            hasBackRoute={this.state.hasBackRoute}
            directoryNodeId={this.state.proof.directoryNodeId}
            currentVersion={currentVersion}/>

            <div className="main-content">
               
                <div className="mrnda-sidebar--left mrnda-sidebar--subnav-open">
                    <div className="mrnda-sidebar__content">
                        <ul className="mrnda-tabs">
                            <li className="mrnda-tabs__item mrnda-tabs__item--active">
                                <a>Metadata Info</a>
                            </li>
                            <li className="mrnda-tabs__item d-none">
                                <a>History</a>
                            </li>
                            <li className="mrnda-tabs__item d-none">
                                <a>Comments</a>
                            </li>
                        </ul>
                        <div className="padding-2x">
                            <div className="row">
                                <div className="col">
                                    <label className="mrnda-input-label">Choose Version</label>
                                    <div className="belect">
                                        <select
                                            id="versionSelector"
                                            name="versionSelector"
                                            onChange={this.onProofVersionSelectorChange.bind(this)}
                                            value={this.state.currentVersionId}>
                                            {this.state.proofVersions &&
                                                this.state.proofVersions.map((proofVersion) => (
                                                    <option
                                                        key={proofVersion.id}
                                                        value={proofVersion.id}>
                                                        V
                                                        {this.isThisVersionDefault(proofVersion.id)
                                                            ? `${proofVersion.versionLabel} - Default`
                                                            : proofVersion.versionLabel}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="float--right text--small">
                                        <AuthorizationComponent
                                            component={ShareVersionButton}
                                            classes="mrnda-icon-btn typography--dark"
                                            permissionKey={{
                                                module: authorizationConstants.ProofViewer.KEY,
                                                action: authorizationConstants.ProofViewer
                                                    .ACTION_PROOF_VIEWER_SHARE_FILE_VERSION
                                            }}
                                            onClick={this.onShareClick.bind(this)}
                                            disabled={
                                                !this.state.proofAvailable || !fileShareEnabled
                                            }
                                        />
                                    </div>
                                    <div className="float--right text--small">
                                        <div className="dropdown">
                                            <AuthorizationComponent
                                                component={DownloadVersionButton}
                                                classes="mrnda-icon-btn typography--dark"
                                                permissionKey={{
                                                    module: authorizationConstants.ProofViewer.KEY,
                                                    action: authorizationConstants.ProofViewer
                                                        .ACTION_PROOF_VIEWER_DOWNLOAD_FILE_VERSION
                                                }}
                                                disabled={
                                                    this.props.proofVersionDownloadStatus
                                                        .isProcessing ||
                                                    !this.state.proofAvailable ||
                                                    utilService.isWebsiteProof(
                                                        this.state.currentVersion
                                                    )
                                                }
                                            />
                                            <div
                                                className="dropdown-menu drop-down-themed"
                                                aria-labelledby="dropdownMenuButton">
                                                <AuthorizationComponent
                                                    component={DowloadOriginalVersionButton}
                                                    classes="dropdown-item rounded-0"
                                                    permissionKey={{
                                                        module: authorizationConstants.ProofViewer
                                                            .KEY,
                                                        action: authorizationConstants.ProofViewer
                                                            .ACTION_PROOF_VIEWER_DOWNLOAD_FILE_VERSION
                                                    }}
                                                    onClick={this.onDownloadOriginalClick.bind(
                                                        this
                                                    )}
                                                    disabled={
                                                        this.props.proofVersionDownloadStatus
                                                            .isProcessing ||
                                                        !this.state.proofAvailable
                                                    }
                                                />
                                                <AuthorizationComponent
                                                    component={DowloadAnnotatedVersionButton}
                                                    classes="dropdown-item rounded-0"
                                                    permissionKey={{
                                                        module: authorizationConstants.ProofViewer
                                                            .KEY,
                                                        action: authorizationConstants.ProofViewer
                                                            .ACTION_PROOF_VIEWER_DOWNLOAD_FILE_VERSION
                                                    }}
                                                    onClick={this.onDownloadClick.bind(this)}
                                                    disabled={
                                                        this.props.proofVersionDownloadStatus
                                                            .isProcessing ||
                                                        !this.state.proofAvailable ||
                                                        this.state.downloadRequested
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="float--right text--small">
                                        <AuthorizationComponent
                                            component={LockUnlockButton}
                                            classes="mrnda-icon-btn typography--dark"
                                            permissionKey={{
                                                module: authorizationConstants.ProofViewer.KEY,
                                                action: authorizationConstants.ProofViewer
                                                    .ACTION_PROOF_VIEWER_LOCKUNLOCK_VERSION
                                            }}
                                            onClick={this.onLockUnlockClick.bind(this)}
                                            isLocked={this.state.currentVersion.isLocked}
                                        />
                                    </div>
                                    <div className="float--right text--small">
                                        <AuthorizationComponent
                                            component={SetDefaultButton}
                                            classes="mrnda-icon-btn typography--dark"
                                            permissionKey={{
                                                module: authorizationConstants.ProofViewer.KEY,
                                                action: authorizationConstants.ProofViewer
                                                    .ACTION_PROOF_VIEWER_EDIT
                                            }}
                                            disabled={
                                                this.state.proof.defaultVersionId ===
                                                this.state.currentVersion.id
                                            }
                                            onClick={this.onMakeDefaultClick.bind(this)}
                                        />
                                    </div>
                                    <div className="float--right text--small">
                                        <AuthorizationComponent
                                            component={DeleteProofVersionButton}
                                            classes="mrnda-icon-btn typography--dark"
                                            permissionKey={{
                                                module: authorizationConstants.ProofViewer.KEY,
                                                action: authorizationConstants.ProofViewer
                                                    .ACTION_PROOF_VIEWER_DELETE_VERSION
                                            }}
                                            disabled={
                                                this.state.proof.defaultVersionId ===
                                                    this.state.currentVersion.id ||
                                                !this.state.proofAvailable
                                            }
                                            onClick={this.onDeleteProofVersion.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="clear d-none">
                                <p className="label">Proof Status</p>
                                <div className="float--left">
                                    <p className="pill--status text--small">
                                        <span className="icon-circle mr-1">
                                            <i className="fas fa-book-reader" />
                                        </span>{' '}
                                        Awaiting review
                                    </p>
                                </div>
                                <div
                                    className="float--right text--small"
                                    style={{ lineHeight: '34px' }}>
                                    <a>Update status</a>
                                </div>
                            </div>
                        </div>

                        <hr className="mrnda-divider--sidebar" />

                        <div className="padding-3x--ends padding-2x--sides">
                            <p className="typography--h3 margin-_5x--bottom">Title</p>
                            <p className="typography--break typography--dark padding-2x--bottom">
                                {this.state.currentVersion.name}
                            </p>

                            <div className="row padding-3x--bottom">
                                <div className="col">
                                    <p className="typography--h3 margin-_5x--bottom">
                                        File Version
                                    </p>

                                    <p>
                                        <span className="typography--dark margin-_5x--right">
                                            {' '}
                                            V{this.state.currentVersion.versionLabel}
                                        </span>{' '}
                                        {this.renderVersionLabel()}
                                    </p>
                                </div>
                                <div className="col">
                                    <p className="typography--h3 margin-_5x--bottom">File Type</p>
                                    <p className="typography--dark">
                                        {this.getFileTypeName(
                                            this.state.currentVersion
                                        ).toUpperCase()}
                                    </p>
                                </div>
                            </div>
                            {currentVersion.mediaType === 3 ||
                                (currentVersion.mediaType === PROOF_TYPE.HTMLLive && (
                                    <>
                                        <p className="typography--h3 margin-_5x--bottom">
                                            Website URL
                                        </p>
                                        <p className="typography--break typography--dark padding-2x--bottom">
                                            <a
                                                href={currentVersion.fileMetadata.websiteURL}
                                                target="_blank">
                                                {currentVersion.fileMetadata.websiteURL}
                                            </a>
                                        </p>
                                    </>
                                ))}

                            {currentVersion.proofId !== '' &&
                                currentVersion.proofId !== undefined && (
                                    <ProofVersionStatus
                                        proofVersion={currentVersion}
                                        updateProofVersion={async (updated) => {
                                            await this.setStateAsync({
                                                currentVersion: updated
                                            });
                                        }}
                                    />
                                )}

                            <hr className="mrnda-divider" />

                            <div className="row padding-3x--top">
                                <div className="col">
                                    <p>
                                        <span className="typography--dark">
                                            {this.state.currentVersion.createdBy}
                                        </span>{' '}
                                        created this
                                    </p>
                                    <p className="typography--muted">
                                        {new Date(
                                            this.state.currentVersion.createdAt
                                        ).toLocaleString()}
                                    </p>
                                </div>
                            </div>

                            <div className="row padding-2x--top">
                                <div className="col">
                                    <p>
                                        <span className="typography--dark">
                                            {this.state.currentVersion.modifiedBy}
                                        </span>{' '}
                                        last modified this
                                    </p>
                                    <p className="typography--muted">
                                        {new Date(
                                            this.state.currentVersion.modifiedAt
                                        ).toLocaleString()}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <hr className="mrnda-divider--sidebar" />

                        <div className="padding-3x--ends padding-2x--sides">
                            <p className="typography--h3 margin-_5x--bottom">File Path</p>
                            <div>
                                <DirectoryPathStatic path={this.state.proof.path} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mrnda-main-content mrnda-main-content--subnav-open mrnda-main-content--sidebar-left-open">
                    {
                        isCompactNav(this.props.navBarConfig) === true &&
                        <div style={{ position: 'absolute' , zIndex: '4', top: '16px', right: '16px'}}>
                            <ProofViewerCloseButton onCloseClick={this.onCloseClick.bind(this)} directoryNodeId={this.state.proof.directoryNodeId} hasBackRoute={this.state.hasBackRoute} />
                        </div>
                    }
                    <div id="proofViewerContainer">{proofViewerContainer}</div>
                </div>
                
                {this.state.showCreateNewProofVersion && (
                    // <CreateProofVersionModal
                    //     show={this.state.showCreateNewProofVersion}
                    //     onHide={this.createNewProofModalClose.bind(this)}
                    //     proofObject={this.state.proof}
                    //     onFileUploadCompleted={this.onFileUploadCompleted.bind(this)}
                    // />
                    <CreateProofModal
                        show={this.state.showCreateNewProofVersion}
                        onHide={this.createNewProofModalClose.bind(this)}
                        proofId={this.state.proof.id}
                        createMode={CREATE_PROOF_MODE.VERSION}
                        onFileUploadCompleted={this.onFileUploadCompleted.bind(this)}
                    />
                )}
                {this.state.showProofCompareModal && (
                    <ProofCompareGlobalModal
                        proofId={this.props.proof.id}
                        currentDirectoryNodeId={this.props.proof.directoryNodeId}
                        show={this.state.showProofCompareModal}
                        onHide={this.proofCompareModalClose.bind(this)}
                        proofObject={{
                            proofVersions: this.state.proofVersions,
                            proof: this.state.proof,
                            currentVersion: this.state.currentVersion,
                            currentVersionId: this.state.currentVersion.id,
                            currentVersionProofId: this.state.proof.id
                        }}
                    />
                )}
                {this.state.showProofMoveModal && (
                    <ProofMoveModal
                        show={this.state.showProofMoveModal}
                        treeData={this.state.treeData}
                        proof={this.state.proof}
                        targetParentDirectoryNode={{ key: directoryConstants.ROOT_DIRECTORY_ID }}
                        onClose={this.handleMoveDirectoryOnClose.bind(this)}
                    />
                )}
                {this.state.showProofCopyModal && (
                    <ProofCopyModal
                        show={this.state.showProofCopyModal}
                        treeData={this.state.treeData}
                        proof={this.state.proof}
                        targetParentDirectoryNode={this.state.currentDirectoryNode}
                        onOpenProof={this.onProofNavigate.bind(this)}
                        onClose={() => this.setState({ showProofCopyModal: false })}
                    />
                )}
                {this.state.showDeleteProofConfirmModal && (
                    <ConfirmationModal
                        show
                        onHide={this.onDeleteProofNoClick.bind(this)}
                        onPrimaryClick={this.onDeleteProofYesClick.bind(this)}
                        onSecondaryClick={this.onDeleteProofNoClick.bind(this)}
                        primaryButtonText="Yes"
                        secondaryButtonText="No"
                        title="Delete File"
                        bodyText="Are you sure you want to delete this file ?"
                    />
                )}
                {this.state.showDeleteProofVersionConfirmModal && (
                    <ConfirmationModal
                        show
                        onHide={this.onDeleteProofVersionNoClick.bind(this)}
                        onPrimaryClick={this.onDeleteProofVersionYesClick.bind(this)}
                        onSecondaryClick={this.onDeleteProofVersionNoClick.bind(this)}
                        primaryButtonText="Yes"
                        secondaryButtonText="No"
                        title="Delete File Version"
                        bodyText="Are you sure you want to delete this file version ?"
                    />
                )}
                {this.state.showProofCopyConfirmModal && (
                    <ConfirmationModal
                        show
                        onHide={this.onCopyProofNoClick.bind(this)}
                        onPrimaryClick={this.onCopyProofYesClick.bind(this)}
                        onSecondaryClick={this.onCopyProofNoClick.bind(this)}
                        primaryButtonText="Yes"
                        secondaryButtonText="No"
                        title="Copy File"
                        bodyText="Are you sure you want to copy this file ?"
                    />
                )}
                {this.state.showFileShareModal && (
                    <FileShareModal
                        show={this.state.showFileShareModal}
                        onHide={() => this.setState({ showFileShareModal: false })}
                        proof={this.state.proof}
                        proofVersion={this.state.currentVersion}
                        onClose={this.handleMoveDirectoryOnClose.bind(this)}
                    />
                )}
            </div>
        </>
        );
    }
}

function mapStateToProps(state) {
    const { proofVersions, proofData, proofVersionDownload } = state;
    return {
        proofVersions: proofVersions.proofVersions
            ? Object.values(proofVersions.proofVersions)
            : null,
        proof: proofData.proof ? proofData.proof : null,
        proofVersionDownloadStatus: proofVersionDownload ? proofVersionDownload : {}
    };
}

var withLiveUpdate = withLiveUpdateHook(ProofViewer);
const connectedRegisterPage = connect(mapStateToProps)(withLiveUpdate);
export { connectedRegisterPage as ProofViewer };
