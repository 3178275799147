import React from 'react';
import CustomEmailTemplateBuilder from './CustomEmailTemplateBuilder';

function CustomEmailTemplate({ templatesUploaderRef, setEmailSettings, emailSettings,submitValidation }) {
    return (
        <div className="custom-template">
            <CustomEmailTemplateBuilder
                templatesUploaderRef={templatesUploaderRef}
                setEmailSettings={setEmailSettings}
                emailSettings={emailSettings}
                submitValidation = {submitValidation}
            />
        </div>
    );
}

export default CustomEmailTemplate;
