import {
    apiAccessService,
    apiAccessConstants,
    alertConstants,
    tenantService,
    userConstants,
} from '../../components';

export const apiAccessActions = {
    add,
    delete: _delete,
    getAll,
    isNameUnique,
    validateToken,
};

function add(apiAccess) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(apiAccess));
            dispatch(loading(true));
            apiAccessService.add(apiAccess).then(
                apiAccess => {
                    dispatch(success(apiAccess));
                    dispatch(loading(false));
                    resolve(apiAccess);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };
    function request(apiAccess) {
        return { type: apiAccessConstants.ADD_REQUEST, apiAccess };
    }
    function success(apiAccess) {
        return { type: apiAccessConstants.ADD_SUCCESS, apiAccess };
    }
    function failure(error) {
        return { type: apiAccessConstants.ADD_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function _delete(apiAccess) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(apiAccess));
            dispatch(loading(true));
            apiAccessService.delete(apiAccess).then(
                apiAccess => {
                    dispatch(success(apiAccess));
                    dispatch(loading(false));
                    resolve(apiAccess);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };
    function request(apiAccess) {
        return { type: apiAccessConstants.DELETE_REQUEST, apiAccess };
    }
    function success(apiAccess) {
        return { type: apiAccessConstants.DELETE_SUCCESS, apiAccess };
    }
    function failure(error) {
        return { type: apiAccessConstants.DELETE_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function getAll() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            apiAccessService.getAll().then(
                async responseData => {
                    dispatch(success(responseData));
                    dispatch(loading(false));
                    resolve(responseData);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: apiAccessConstants.GETALL_REQUEST };
    }
    function success(directories) {
        return { type: apiAccessConstants.GETALL_SUCCESS, directories };
    }
    function failure(error) {
        return { type: apiAccessConstants.GETALL_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function validateToken(token) {
    return dp => {
        return new Promise((resolve, reject) => {
            dp(request(token));
            dp(loading(true));
            apiAccessService.validateToken({ tokenValue: token }).then(
                async user => {
                    if (user && user.success) {
                        localStorage.setItem('user', JSON.stringify(user));
                        var tenant = await tenantService.getSetCurrentTenant();
                        dp(success(user));
                        dp(loading(false));
                        resolve(user);
                        // history.push('/dashboard/folders');
                    } else {
                        dp(failure(user.message.toString()));
                        // dp(alertActions.error(error.toString()));
                        dp(loading(false));
                        resolve(user);
                    }
                },
                error => {
                    dp(failure(error.toString()));
                    // dp(alertActions.error(error.toString()));
                    dp(loading(false));
                    reject();
                },
            );
        });
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function isNameUnique(requestObject) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            apiAccessService.isNameUnique(requestObject).then(
                response => {
                    dispatch(success(response));
                    dispatch(loading(false));
                    resolve(response);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: apiAccessConstants.IS_NAME_UNIQUE_REQUEST };
    }
    function success(IsapiAccessUnique) {
        return { type: apiAccessConstants.IS_NAME_UNIQUE_SUCCESS, IsapiAccessUnique };
    }
    function failure(error) {
        return { type: apiAccessConstants.IS_NAME_UNIQUE_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
