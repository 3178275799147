import { authHeader, handleResponse, config } from '../../../_helpers';
import localStorage from 'local-storage';
import { userService } from '../../User/user.service';
import { formatStrategyKeys } from 'rc-tree-select/lib/utils/strategyUtil';

const API_CONTROLLER = 'DocumentAnnotationComment';

export const documentCommentService = {
    addComment,
    addCommentReply,
    updateAnnotation,
    getComments,
    deleteComment,
    setCommentStatus,
    deleteCommentReply,
    addCommentAttachments,
    getAttachment,
    deleteAttachment,
    updateCommentContent,
    updateReplyContent,
    addAnnotation
};

function getComments(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/${API_CONTROLLER}/getComments`, requestOptions).then(
        handleResponse
    );
}


function getAttachment(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/${API_CONTROLLER}/GetAttachment`, requestOptions).then(
        handleResponse
    );
}

function deleteAttachment (request) {
   
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(
        `${config.apiUrl}/${API_CONTROLLER}/deleteAttachment`,
        requestOptions
    ).then(handleResponse);
}

function addComment(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/${API_CONTROLLER}/AddComment`, requestOptions).then(
        handleResponse
    );
}
function addAnnotation(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/${API_CONTROLLER}/AddAnnotation`, requestOptions).then(
        handleResponse
    );
}

function updateCommentContent(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/${API_CONTROLLER}/UpdateCommentContent`, requestOptions).then(
        handleResponse
    );
}

function updateAnnotation(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/${API_CONTROLLER}/UpdateAnnotation`, requestOptions).then(
        handleResponse
    );
}

function deleteComment(id) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
        // body: JSON.stringify(request)
    };
    return fetch(
        `${config.apiUrl}/${API_CONTROLLER}/deleteComment/${id}`,
        requestOptions
    ).then(handleResponse);
}

function deleteCommentReply(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(
        `${config.apiUrl}/${API_CONTROLLER}/deleteCommentReply`,
        requestOptions
    ).then(handleResponse);
}

function setCommentStatus(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/${API_CONTROLLER}/setCommentStatus`, requestOptions).then(
        handleResponse
    );
}

function addCommentReply(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/${API_CONTROLLER}/AddCommentReply`, requestOptions).then(
        handleResponse
    );
}

function updateReplyContent(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/${API_CONTROLLER}/updateReplyContent`, requestOptions).then(
        handleResponse
    );
}

function addCommentAttachments(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/${API_CONTROLLER}/AddCommentAttachments`, requestOptions).then(
        handleResponse
    );
}