import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { documentCommentService } from '../../documentComment.service';
import { videoCommentService } from '../../../VideoAnnotation/videoComment.service';
import { utilService } from '../../../../../_helpers';
import { useScreenSizes } from '../../../../responsive/useScreenSizes';
import { EditorToolbarActionButton } from '../../../VideoAnnotation/Subs/EditorToolbarActionButton';
import CloseIcon from '../../../../_shared/Icon/CloseIcon';
import DownloadIcon from '../../../../_shared/Icon/DownloadIcon';
import DesktopBreakpoint from '../../../../responsive/DesktopBreakpoint';

const ImageFileTypes = ['jpg', 'jpeg', 'png'];
const VideoFileTypes = ['mp4', 'webm', 'avi', 'mov', 'wmv', 'mkv', 'ogg', 'flv', 'mpeg'];
const PdfFileTypes = ['pdf'];
const audioFileTypes = ['mp3', 'oog', 'wav'];

const ViewerType = {
    Image: 'Image',
    Video: 'Video',
    Doc: 'Doc',
    Pdf: 'Pdf',
    Audio: 'Audio'
};
const AttachmentModal = ({ show, onClose, attachment, getAttachment }) => {
    const [fileUrl, setFileUrl] = useState('');
    const [downloadUrl, setDownloadUrl] = useState('');

    const [viewerType, setViewerType] = useState('');
    const { isMobile } = useScreenSizes();

    const onModalClose = () => {
        onClose();
    };

    const onAttachmentDownload = () => {
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', attachment.name);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    };

    useEffect(() => {
        var fileExtention = utilService.getFileExtention(attachment.name);

        if (ImageFileTypes.includes(fileExtention)) {
            setViewerType(ViewerType.Image);
        } else if (VideoFileTypes.includes(fileExtention)) {
            setViewerType(ViewerType.Video);
        } else if (PdfFileTypes.includes(fileExtention)) {
            setViewerType(ViewerType.Pdf);
        } else if (audioFileTypes.includes(fileExtention)) {
            setViewerType(ViewerType.Audio);
        } else {
            setViewerType(ViewerType.Doc);
        }

        var request = {
            id: attachment.id,
            commentId: attachment.commentId,
            replyId: attachment.replyId
        };

        getAttachment(request).then((data) => {
            setFileUrl(data.fileUrl);
            setDownloadUrl(data.downloadUrl);
        });
    }, []);

    const renderViewer = () => {
        switch (viewerType) {
            case ViewerType.Image: {
                return (
                    <img
                        crossOrigin="anonymous"
                        style={{
                            display: 'flex',
                            maxWidth: '100%',
                            maxHeight: '75vh',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                        src={fileUrl}
                    />
                );
            }
            case ViewerType.Video: {
                return (
                    <>
                        <video
                            style={{
                                display: 'flex',
                                maxWidth: '100%',
                                maxHeight: '75vh',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                            src={fileUrl}
                            controls>
                            <source src={fileUrl} type={attachment.fileType}></source>
                        </video>
                    </>
                );
            }
            case ViewerType.Pdf: {
                return (
                    isMobile === true?
                    <>
                        <h1>{attachment.name}</h1>
                        <p>Preview not supported yet !</p>
                    </>
                    :
                    <>
                        {/* <embed src={fileUrl} /> */}
                        <embed
                            style={{
                                display: 'flex',
                                maxWidth: '100%',
                                maxHeight: '75vh',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                height: '100%',
                                width: '100%'
                            }}
                            src={`${fileUrl}#toolbar=0`}></embed>
                        {/* <p>Preview is not supported yet</p> */}
                    </>
                );
            }
            case ViewerType.Audio: {
                return (
                    <>
                        <audio
                            controls
                            src={fileUrl}
                            style={{
                                display: 'flex',
                                maxHeight: '75vh',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '100%'
                            }}>
                            <source src={fileUrl} type={attachment.fileType} />
                        </audio>
                    </>
                );
            }
            case ViewerType.Doc: {
                return (
                    <>
                        <h1>{attachment.name}</h1>
                        <p>Preview not supported yet !</p>
                    </>
                );
            }
            default:
                break;
        }
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={'static'}
            style={{
                width: '100vw',
                height: '100vh'
            }}
            dialogClassName={isMobile? "fullscreen-modal" : ""}>
            <Modal.Header closeButton={!isMobile}>
                {
                    isMobile? 
                    <div
                    style={{ width: '100%', paddingRight: '8px' }}
                    className={'mrnda-video-editor-area-toolbox--mobile'}>
                    <div style={{ width: 'calc(100% - 112px)'}}  className={`mrnda-video-editor-area-toolbox--mobile__header text-truncate`}>
                        <span className={`mrnda-video-editor-area-toolbox--mobile__header__text`}>
                            {attachment.name}
                        </span>
                    </div>

                    <div className={`mrnda-video-editor-area-toolbox-shapes`}>
                        {/* <EditorToolbarActionsMobile
                            {...props}
                            showMuteButton={props.proofVersion.mediaType === PROOF_TYPE.Video}
                            currentContributor={props.contributors?.find(
                                (x) => x.userID === props.userId
                            )}
                        /> */}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <EditorToolbarActionButton handleClicked={onAttachmentDownload} icon={<DownloadIcon  />} />
                            <EditorToolbarActionButton handleClicked={onModalClose} icon={<CloseIcon height={18} width={18} />} />
                        </div>
                    </div>
                </div>
                : 
                <Modal.Title id="contained-modal-title-vcenter">{attachment.name}</Modal.Title>
                }
            </Modal.Header>
            <Modal.Body style={viewerType === ViewerType.Pdf ? { height: '75vh' } : {}}>
                <>
                    <p> {JSON.stringify(getAttachment)} </p>
                    {renderViewer()}
                </>
            </Modal.Body>
            <DesktopBreakpoint>
                <Modal.Footer>
                    <Button className="mrnda-btn--secondary" onClick={onAttachmentDownload}>
                        Download
                    </Button>
                </Modal.Footer>
            </DesktopBreakpoint>
        </Modal>
    );
};

export { AttachmentModal };
