//@ts-check

import React from 'react';

const ArrowRightIcon = ({ height = 14, width = 14, color = 'currentColor' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 14"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="14px/Interaction/Navigation/Arrow/arrow--right" fill={color}>
                    <rect id="Rectangle" fillOpacity="0" x="0" y="0" width="14" height="14"></rect>
                    <g id="Outline" transform="translate(1.750000, 1.749995)" fillRule="nonzero">
                        <path
                            d="M4.83218192,0.126300696 C5.0385733,0.309759705 5.05716367,0.625795891 4.87370466,0.832187275 L1.612,4.5 L10,4.50000536 C10.2761424,4.50000536 10.5,4.72386298 10.5,5.00000536 C10.5,5.27614773 10.2761424,5.50000536 10,5.50000536 L1.613,5.5 L4.87370466,9.16782344 C5.05716367,9.37421482 5.0385733,9.69025101 4.83218192,9.87371002 C4.62579054,10.057169 4.30975435,10.0385787 4.12629534,9.83218728 L0.126295341,5.33218728 C0.0838690336,5.28361737 0.0525191561,5.23150568 0.0312812627,5.17447155 L0.00789345879,5.08867788 C-0.010139565,4.98824636 0.00272545808,4.88340957 0.0464885279,4.78926365 C0.0538067646,4.77351069 0.0616252286,4.7587646 0.0701356163,4.74447864 C0.0734043145,4.73900277 0.0770504257,4.73314382 0.080829018,4.72734531 C0.0938801622,4.70735695 0.107919391,4.68883641 0.123151284,4.67138369 L4.12629534,0.167823436 C4.30975435,-0.038567948 4.62579054,-0.0571583118 4.83218192,0.126300696 Z"
                            id="Combined-Shape"
                            transform="translate(5.250000, 5.000005) scale(-1, 1) translate(-5.250000, -5.000005) "></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ArrowRightIcon;
