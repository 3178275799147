import React from 'react';
import { Filters } from 'react-data-grid-addons';
import { Record } from 'immutable';

const { AutoCompleteFilter } = Filters;
class MyAutoCompleteFilter extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        //console.log('first name filter column data get called.');
        return <AutoCompleteFilter {...this.props} />;
    }
}

export {MyAutoCompleteFilter};
