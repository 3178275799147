import React from 'react';
import { connect } from 'react-redux';
import { utilService } from '../../_helpers';
import { apiAccessActions, ConfirmationModal, alertActions } from '../../components';
import { DataFetcher } from '../_shared/DataFetcher/DataFetcher';
const initialValidationState = {
    name: {
        validated: true,
        message: '',
    },
    description: {
        validated: true,
        message: '',
    },
};
class APIAccessManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiAccessArray: [],
            name: null,
            validations: initialValidationState,
            showDeleteConfirmationModal: false,
            current: {},
        };
    }

    async loadAllData() {
        await this.loadDirectoryDataFromServer();
    }
    async loadDirectoryDataFromServer() {
        var apiAccessArray = await this.props.dispatch(apiAccessActions.getAll());
        this.setState({
            apiAccessArray: apiAccessArray,
        });
    }
    async validateFields() {
        var globalValidation = true;
        var { validations } = this.state;
        var validationCopy = { ...validations };
        if (!this.state.name || (this.state.name && this.state.name.length < 3)) {
            validationCopy.name = {
                validated: false,
                message: 'Name must be more than 2 characters ',
            };
            globalValidation = false;
        } else {
            var groupObjectRequest = {
                name: this.state.name,
            };
            // keep provide id existing item unique check
            var response = await this.props.dispatch(
                apiAccessActions.isNameUnique(groupObjectRequest),
            );
            if (!response.isNameUnique) {
                validationCopy.name = {
                    validated: false,
                    message: 'api access with the same name already exists',
                };
                globalValidation = false;
            }
        }
        await utilService.setStateAsync.bind(this)({
            validations: validationCopy,
        });
        return globalValidation;
    }
    async onSave() {}

    async onAdd() {
        await utilService.setStateAsync.bind(this)({
            submitted: true,
            validations: initialValidationState,
        });

        if (!(await this.validateFields())) {
            return;
        }

        const { dispatch } = this.props;
        var request = {
            name: this.state.name,
        };
        if (this.state.name) {
            await dispatch(apiAccessActions.add(request));
            await this.loadAllData();
            this.setState({ name: null });
            this.props.dispatch(alertActions.success('Saved !'));
        }
    }

    async onDelete(apiAccess) {
        this.setState({ showDeleteConfirmationModal: true, current: apiAccess });
    }
    async onDeleteYes() {
        await this.props.dispatch(apiAccessActions.delete(this.state.current.id));
        await this.loadAllData();
        this.setState({ showDeleteConfirmationModal: false });
        this.props.dispatch(alertActions.success('Saved !'));
    }

    handleNameChange(event) {
        var { value } = event.target;
        this.setState({
            name: value,
            submitted: false,
        });
    }

    isValid() {
        var result = this.state.permissions
            .filter(x => x.operationStatus !== 3 && x.operationStatus !== -1)
            .find(permission => {
                return !(permission.members && permission.members.length > 0);
            });
        return result === undefined || result === null;
    }
    render() {
        return (
            <>
                <div className="settings-view__manage__container">
                    <div className="m-2">
                        <div className="settings-view-modal">
                            <div style={{ minHeight: '80%', fontSize: '13px' }}>
                                <button
                                    id="editor-close-btn"
                                    type="button"
                                    className="close modal-close"
                                    title="Close Modal"
                                    onClick={this.props.onClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h3 className="">API Access</h3>
                                <hr></hr>
                                <DataFetcher onFetchData={this.loadAllData.bind(this)} height={'476px'}   >
                                    <div>
                                        <div
                                            style={{
                                                maxWidth: '300px',
                                                display: 'inline-flex',
                                            }}>
                                            <div
                                                className={'form-group'}
                                                style={{ minWidth: '250px', marginTop: '10px' }}>
                                                <input
                                                    type="text"
                                                    className="form-control mrnda-input-text"
                                                    name="name"
                                                    placeholder="Enter a name"
                                                    value={this.state.name || ''}
                                                    onChange={this.handleNameChange.bind(this)}
                                                />
                                                {this.state.submitted &&
                                                    !this.state.validations.name.validated && (
                                                        <div className="help-block">
                                                            {this.state.validations.name.message}
                                                        </div>
                                                    )}
                                            </div>
                                            <button
                                                style={{ minWidth: '65px'}}
                                                className="btn mrnda-btn m-2"
                                                disabled={
                                                    this.state.name == null || this.state.name == ''
                                                }
                                                onClick={this.onAdd.bind(this)}>
                                                Add
                                            </button>
                                        </div>
                                        <div className="m-2">
                                            <table
                                                id="FormContainerTable"
                                                className="table table-striped">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>Delete</th>
                                                        <th>Name</th>
                                                        <th>ClientId</th>
                                                        <th style={{ minWidth: 400 }}>Client Secret</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ paddingTop: 16 }}>
                                                    {this.state.apiAccessArray.map(apiAccess => (
                                                        <tr
                                                            caption={apiAccess.name}
                                                            fieldtype="UserSelectorField"
                                                            className="element UserSelectorField permission-folder-row"
                                                            key={apiAccess.id}>
                                                            <td
                                                                className="_s_DeleteIcon node-delete-icon hide"
                                                                style={{
                                                                    display: 'table-cell',
                                                                }}>
                                                                <a
                                                                    data-value="59c3a2488986220f3466afb2"
                                                                    className="_s_DeleteTreeItems folder-node-delete"
                                                                    title="Delete"
                                                                    onClick={() =>
                                                                        this.onDelete(apiAccess)
                                                                    }>
                                                                    <i className="fa fa-trash fa-margin" />
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    className="span3 control-label"
                                                                    data-value="59c3a2488986220f3466afb2"
                                                                    title={apiAccess.name}>
                                                                    {apiAccess.name}
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    className="span3 control-label"
                                                                    data-value="59c3a2488986220f3466afb2"
                                                                    title={apiAccess.clientId}>
                                                                    {apiAccess.clientId}
                                                                </label>
                                                            </td>
                                                            <td className="_s_NodePermissionIcon node-permission-icon ">
                                                                <div className="" tabIndex="1">
                                                                    <span className="sensitive_toggle rounded">
                                                                        Show
                                                                    </span>
                                                                    <span className="sensitive_data span3 control-label">
                                                                        {apiAccess.clientSecret}
                                                                    </span>
                                                                </div>
                                                                {/* <label className="span3 control-label">
                                                                    {apiAccess.clientSecret}
                                                                </label> */}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </DataFetcher>
                            </div>
                        </div>
                    </div>
                    {this.state.showDeleteConfirmationModal && 
                      (<ConfirmationModal
                        show={this.state.showDeleteConfirmationModal}
                        onHide={() => {
                            this.setState({ showDeleteConfirmationModal: false });
                        }}
                        onPrimaryClick={this.onDeleteYes.bind(this)}
                        onSecondaryClick={() => {
                            this.setState({ showDeleteConfirmationModal: false });
                        }}
                        primaryButtonText="Yes"
                        secondaryButtonText="No"
                        title={`Delete API Access: ${this.state.current.name}`}
                        bodyText={`Are you sure you want to delete the API Access?`}
                    />)}
                </div>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

const connectedSettingsPage = connect(mapStateToProps)(APIAccessManage);
export { connectedSettingsPage as APIAccessManage };
