import React from 'react';
import PulseLoader from '../../../../_shared/Loaders/PulseLoader';

function CommentStatus({ commentItem, isStatusUpdating }) {
    return (
        <div className="comment__status">
            {isStatusUpdating === true ? (
                <PulseLoader backgroundColor='var(--primary-text-color)' size='20px'/>
            ) : (
                <>
                    {commentItem.status && (
                        <span className="comment__status__item text-truncate">
                            <span>{commentItem.status}</span>
                        </span>
                    )}
                </>
            )}
        </div>
    );
}

export default CommentStatus;
