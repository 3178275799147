import React, { useEffect } from 'react';
import { VideoCommentAnnotation } from './VideoCommentAnnotation';
import { VideoCommentPreview } from './VideoCommentPreview';
export function VideoComment({ comment, metadata, pages, onGetBackdropSource, onBackgroundLoaded, getPageDetail }) {
    
    return (
        <div className='print-section-div'>            
            {comment.annotations &&
                comment.annotations.map((annotationObject) => (
                    <div className="comment display-thumbnail"
                    key={annotationObject.id}
                    >                                         
                        <div className="comment-content">
                            <VideoCommentAnnotation
                                annotationObject={annotationObject}
                                comment={comment}></VideoCommentAnnotation>
                        </div>

                        <div className="comment-preview">
                            <div className="css-1fmc5ej e17exdub0">
                                <div className="comment-preview-item">
                                    <VideoCommentPreview
                                        key={comment.Id}
                                        comment={comment}
                                        metadata={metadata}
                                        annotationObject = {annotationObject}
                                        pageNo={annotationObject.page}
                                        getPageDetail = {getPageDetail}
                                        pages = {pages}   
                                        onGetBackdropSource = {onGetBackdropSource}      
                                        onBackgroundLoaded = {onBackgroundLoaded}                              
                                        previewUrl={
                                            annotationObject.previewUrl
                                        }></VideoCommentPreview>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
}
