import React from 'react';
import cx from 'classnames';
import { readableTime } from '../../VideoAnnotation/VideoAnnotationUtil';
import { withVideoTranscriptionConsumer } from '../../VideoAnnotation/Context/VideoAnnotationConsumer';
import { colorUtilService } from '../../../../_helpers';
import { ProofVideoTranscriptionView } from './ProofVideoTranscriptionView';

const ProofVideoTranscriptionList = ({
    proofVersion,
    transcriptions,
    status,
    onItemClick,
    currentTimeInSeconds,
    playerColors
}) => {
    let { primaryColor, primaryTextColor } = playerColors;
    return (
        <div className="mrnda-video-transcription__sidebar" >
            <div className="mrnda-video-editor-sidebar__header d-none">
                <div className="mrnda-video-editor-sidebar__header__container">
                    <h1
                        className="mrnda-video-editor-sidebar__header__text"                        
                        title={proofVersion.name}>
                        {proofVersion.name}
                    </h1>
                    {/* <button
                        title="Close Proof"
                        onClick={() => history.goBack()}
                        className="mrnda-video-editor-sidebar__header_close mrnda-icon-btn">
                        <i className="fas fa-times fa-2x"></i>
                    </button> */}
                </div>
                <div className="d-flex mb-2" style={{ color: primaryTextColor }}>
                    <label>{`Version: v${proofVersion.versionNumber}`}</label>
                </div>
            </div>
            <div
                className={''}
                style={{
                    display: 'flex',
                    overflow: 'hidden auto',
                    // justifyContent: 'center',
                    flexDirection: 'column',
                    // alignItems: 'center',
                    flex: 1
                }}>
                <ProofVideoTranscriptionView
                    transcriptions={transcriptions}
                    transcritionStatus={status}
                    componentColors={playerColors}
                    currentTimeInSeconds={currentTimeInSeconds}
                    onItemClick={onItemClick}
                />
            </div>
        </div>
    );
};

const hoc = withVideoTranscriptionConsumer(ProofVideoTranscriptionList);
export { hoc as ProofVideoTranscriptionList };
