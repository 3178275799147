//@ts-check

import React, { useState } from 'react';
import { readableTime } from '../../VideoAnnotation/VideoAnnotationUtil';
import { colorUtilService } from '../../../../_helpers';
import cx from 'classnames';
import { transcriptionStatus } from '../../VideoAnnotation/Context/videoAnnotation.constants';
import PulseLoader from '../../../_shared/Loaders/PulseLoader';

const transcriptionPlaceholderStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    flex: 1
};
export const ProofVideoTranscriptionView = ({
    transcriptions,
    transcritionStatus: status,
    currentTimeInSeconds,
    componentColors,
    onItemClick,
    onEnableTranscription
}) => {
    const [transcriptionEnablePosted, setTranscriptionEnablePosted] = useState(false);
    let { primaryTextColor, primaryAccentColor } = componentColors;
    let secondaryAccentColor = colorUtilService.changeOpacity(primaryAccentColor, 0.2);
    const { COMPLETED, IN_PROGRESS, NOT_GENERATED, ERROR, LOADING } = transcriptionStatus;
    const handleEnableTranscription = () => {
        if (onEnableTranscription) onEnableTranscription();
        setTranscriptionEnablePosted(true);
    };
    return (
        <div className="mrnda-video-transcription__sidebar__transcriptions" style={{}}>
            {status == 'Completed' &&
                transcriptions?.map((transcription) => {
                    let transcriptionItem = { ...transcription };
                    let { timeRange, value } = transcriptionItem;
                    let readableTimeText = `${readableTime(timeRange[0])} - ${readableTime(
                        timeRange[1]
                    )}`;
                    let isCurrent =
                        currentTimeInSeconds >= timeRange[0] &&
                        currentTimeInSeconds <= timeRange[1];
                    let selectedColor = isCurrent ? { backgroundColor: secondaryAccentColor } : {};
                    return (
                        <div
                            id={`tr-${timeRange[0]}-${timeRange[1]}`}
                            className={cx({
                                'mrnda-video-transcription__sidebar__transcriptions--item': true,
                                selected: isCurrent
                            })}
                            style={{ ...selectedColor }}
                            onClick={() => onItemClick(timeRange)}
                            key={`${timeRange[0]}-${timeRange[0]}`}>
                            <span
                                className="mrnda-video-transcription__sidebar__transcriptions--time-range"
                                >
                                {readableTimeText}
                            </span>
                            <span
                                className="mrnda-video-transcription__sidebar__transcriptions--value"
                                style={{marginLeft : '-2px'}}
                                >
                                {` ${value}`}
                            </span>
                        </div>
                    );
                })}
            {status == COMPLETED && transcriptions.length == 0 && (
                <div style={{ ...transcriptionPlaceholderStyle }}>
                    <span style={{ textAlign: 'center' }}>
                        There is no transcription available for this video{' '}
                    </span>
                </div>
            )}
            {status == NOT_GENERATED && (
                <div style={{ ...transcriptionPlaceholderStyle }}>
                    <span style={{ marginBottom: '12px', textAlign: 'center' }}>
                        Transcription isn't enabled for this video
                    </span>
                    <div className="control-group">
                        <div className="controls">
                            <button
                                className="mrnda-btn mrnda-btn--full margin-2x--bottom"
                                onClick={handleEnableTranscription}
                                disabled={transcriptionEnablePosted}>
                                Enable Transcription
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {status == IN_PROGRESS && (
                <div style={{ ...transcriptionPlaceholderStyle }}>
                    <i style={{ fontSize: "40px", marginBottom: "12px"}} className="fas fa-hourglass-half"></i>
                    <span style={{ textAlign: 'center' }}>
                        Transcription is being generated for this video
                        <br />
                        Please check back in few seconds
                    </span>
                </div>
            )}

            {status == ERROR && (
                <div style={{ ...transcriptionPlaceholderStyle }}>                   
                    <i style={{ fontSize: "20px", marginBottom: "10px"}} className="fas fa-exclamation-circle typography--muted"></i>
                    <span style={{ textAlign: 'center', marginBottom:'20px' }}>
                        No transcript available for this video                        
                    </span>
                </div>
            )}

            {status == LOADING && (
                <div style={{ ...transcriptionPlaceholderStyle }}>
                    <span style={{ textAlign: 'center' }}>
                        <PulseLoader/>
                    </span>
                </div>
            )}
        </div>
    );
};
