export const documentAnnotationConstants = {
    TIME_MODE: {
        TIME: 'VIDEO_ANNOTATION_TIME_MODE_TIME',
        TIME_FRAME: 'VIDEO_ANNOTATION_TIME_MODE_TIME_FRAME',
        TIME_CODE: 'VIDEO_ANNOTATION_TIME_MODE_TIME_CODE'
    },
    COMMENT_STATUS: {
        'video-comment.status.none': 'None',
        'video-comment.status.accepted': 'Accepted',
        'video-comment.status.completed': 'Completed',
        'video-comment.status.cancelled': 'Cancelled',
        'video-comment.status.rejected': 'Rejected',
        // 'video-comment.status.resolved': 'Resolved',
        'video-comment.status.reopened': 'Reopened',
        filter: (status) => {
            let value =
                status &&
                status !== 'video-comment.status.none' &&
                status !== 'video-comment.status.reopened' &&
                status !== 'filter';
            return value;
        }
    },
    ZOOM_STEP : 5,
    ZOOM_SELECTIONS : [
        {
            label: 'custom',
            value: -1
            
        },
        {
            label: '10%',
            value: 10,
        },
        {
            label: '25%',
            value: 25,
        },
        {
            label: '50%',
            value: 50,
        },
        {
            label: '75%',
            value: 75,
        },
        {
            label: '100%',
            value: 100,
        },
        {
            label: '125%',
            value: 125,
        },
        {
            label: '200%',
            value: 200,
        },
        {
            label: '400%',
            value: 400,
        },
        {
            label: '800%',
            value: 800,
        }
    ]
    
};
