//@ts-check

import React from 'react';

const TextHighlightIcon = ({
    height = 22,
    width = 22,
    color = 'currentColor',
    marginTop = 0,
    ...rest
}) => {
    return (
        <svg
            version="1.1"
            width={width}
            height={height}
            viewBox="0 0 512 512"
            className=""
            fill={color}
            {...rest}
            >
            <g>
                <g>
                    <g>
                        <path
                            d="m288.89 263.498v-138.239h90.799v40.161h42.058v-85.762h-331.676v85.762h42.058v-40.161h90.799v138.239z"
                            fill="#9da5be"
                            data-original="#9da5be"
                            className=""></path>
                    </g>
                    <g>
                        <path
                            d="m218.911 343.773v50.221h-34.737v50.377h143.47v-50.377h-34.736v-50.221z"
                            fill="#ced3dd"
                            data-original="#ced3dd"
                            className=""></path>
                    </g>
                    <g>
                        <path
                            d="m421.747 329.336c5.522 0 10-4.477 10-10v-37.354c0-5.523-4.478-10-10-10s-10 4.477-10 10v10.237h-315.304v-10.237c0-5.523-4.478-10-10-10s-10 4.477-10 10v37.354c0 5.523 4.478 10 10 10s10-4.477 10-10v-7.117h315.304v7.117c0 5.523 4.478 10 10 10z"
                            fill={color}
                            data-original={color}
                            className=""></path>
                        <path
                            d="m471.849 0h-86.308c-5.522 0-10 4.477-10 10s4.478 10 10 10h86.308c10.45 0 18.951 8.501 18.951 18.951v86.308c0 5.523 4.478 10 10 10s10-4.477 10-10v-86.308c0-21.477-17.474-38.951-38.951-38.951z"
                            fill={color}
                            data-original={color}
                            className=""></path>
                        <path
                            d="m500.8 376.741c-5.522 0-10 4.477-10 10v86.308c0 10.45-8.501 18.951-18.951 18.951h-86.308c-5.522 0-10 4.477-10 10s4.478 10 10 10h86.308c21.478 0 38.951-17.474 38.951-38.951v-86.308c0-5.523-4.478-10-10-10z"
                            fill={color}
                            data-original={color}
                            className=""></path>
                        <path
                            d="m11.2 135.259c5.522 0 10-4.477 10-10v-86.308c0-10.45 8.501-18.951 18.951-18.951h86.308c5.522 0 10-4.477 10-10s-4.478-10-10-10h-86.308c-21.477 0-38.951 17.474-38.951 38.951v86.308c0 5.523 4.478 10 10 10z"
                            fill={color}
                            data-original={color}
                            className=""></path>
                        <path
                            d="m126.459 492h-86.308c-10.45 0-18.951-8.501-18.951-18.951v-86.308c0-5.523-4.478-10-10-10s-10 4.477-10 10v86.308c0 21.477 17.474 38.951 38.951 38.951h86.308c5.522 0 10-4.477 10-10s-4.478-10-10-10z"
                            fill={color}
                            data-original={color}
                            className=""></path>
                        <path
                            d="m431.747 79.659c0-5.523-4.478-10-10-10h-331.676c-5.522 0-10 4.477-10 10v85.761c0 5.523 4.478 10 10 10h42.059c5.522 0 10-4.477 10-10v-30.161h70.798v128.239c0 5.523 4.478 10 10 10s10-4.477 10-10v-138.239c0-5.523-4.478-10-10-10h-90.798c-5.522 0-10 4.477-10 10v30.161h-22.059v-65.761h311.676v65.761h-22.058v-30.161c0-5.523-4.478-10-10-10h-90.799c-5.522 0-10 4.477-10 10v93.107c-.037.352-.057.709-.057 1.071 0 5.446 4.354 9.875 9.771 9.998.097.002.198.004.286.004 5.522 0 10-4.477 10-10v-84.18h70.799v30.161c0 5.523 4.478 10 10 10h42.058c5.522 0 10-4.477 10-10z"
                            fill={color}
                            data-original={color}
                            className=""></path>
                        <path
                            d="m327.645 383.994h-24.736v-40.221c0-5.523-4.478-10-10-10s-10 4.477-10 10v50.221c0 5.523 4.478 10 10 10h24.736v30.376h-123.471v-30.376h24.736c5.522 0 10-4.477 10-10v-50.221c0-5.523-4.478-10-10-10s-10 4.477-10 10v40.221h-24.736c-5.522 0-10 4.477-10 10v50.376c0 5.523 4.478 10 10 10h143.471c5.522 0 10-4.477 10-10v-50.376c0-5.523-4.478-10-10-10z"
                            fill={color}
                            data-original={color}
                            className=""></path>
                        <path
                            d="m280.58 269.05c4.862 7.445 16.643 4.786 18.152-3.842.733-4.189-1.372-8.528-5.132-10.53-3.687-1.963-8.331-1.394-11.427 1.414-3.623 3.287-4.305 8.893-1.593 12.958z"
                            fill={color}
                            data-original={color}
                            className=""></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default TextHighlightIcon;
