//@ts-check

import React from 'react';

const ChevronUp = ({ height = 14, width = 14, color = 'currentColor' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 14"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="14px/Interaction/Navigation/Chevron/chevron--left" fill={color}>
                    <rect id="Rectangle" fillOpacity="0" x="0" y="0" width="14" height="14"></rect>
                    <g id="Outline" transform="translate(2.999991, 3.999991)" fillRule="nonzero">
                        <path
                            d="M2.17461791,-1.87962377 C2.38428083,-2.05933484 2.69993082,-2.03505408 2.8796419,-1.82539115 L5.8796419,1.67460885 C6.0401375,1.86185371 6.0401375,2.13815535 5.8796419,2.32540022 L2.8796419,5.82540022 C2.69993082,6.03506314 2.38428083,6.05934391 2.17461791,5.87963283 C1.96495499,5.69992176 1.94067422,5.38427177 2.1203853,5.17460885 L4.8410136,1.99999547 L2.1203853,-1.17459978 C1.96064212,-1.36096682 1.96207768,-1.63107673 2.1116218,-1.81501716 L2.17461791,-1.87962377 Z"
                            id="Path-13"
                            transform="translate(4.000009, 2.000005) rotate(-810.000000) translate(-4.000009, -2.000005) "></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ChevronUp;
