import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { DrawingCanvas } from '@wunderman-thompson/miranda-drawing';
const AnnotationBackdrop = ({
    details,
    comment,
    onGetBackdropSource,
    onBackgroundLoaded,
    annotationObject,
    ...rest
}) => {
    const [src, setSrc] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {        
        var src = comment.viewportPath ? comment.viewportPath :  annotationObject.previewUrl;
        setSrc(src);
    }, []);

    const getSrc = async () => {               
        let res = await onGetBackdropSource(details);
        setSrc(res);
    };
    return (
        <div className={cx('annotation-background', { skeleton: isLoading && comment.reportLoadStatus === 'Loading' })} {...rest}>
            <img
                style={{ opacity: isLoading === false ? 1 : 0 }}
                className="transition-opacity annotation-background-image"
                onLoad={()=> {
                    setIsLoading(false);
                    onBackgroundLoaded();
                }}
                onError={(e) => {                    
                    setIsLoading(false);
                    setHasError(true);
                    onBackgroundLoaded();
                }}
                src={src}
            />
        </div>
    );
};

export default AnnotationBackdrop;
