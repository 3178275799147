import React, { Component } from 'react';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data } from 'react-data-grid-addons';
import { Pagination } from '../../../components';

const selectors = Data.Selectors;
import './Grid.css';
const firstNameActions = [
    {
        icon: <span className="far fa-comment-dots" />,
        callback: () => {
            alert('Deleting');
        },
    },
];
class Grid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            columns: [],
            filtersParam: {},
            pageSize: 10,
            pageIndex: 0,
            totalPages: 0,
            totalCount: 0,
            enableFilter: true,
            proofsFilters: {},
            selectedRow: {},
        };
    }

    componentDidMount() {
        console.log('Grid Moundting...');
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            rows: nextProps.rows || [],
            columns: nextProps.columns,
            totalPages: nextProps.totalPages,
            totalCount: nextProps.totalCount,
            proofsFilters: nextProps.proofsFilters
                ? nextProps.proofsFilters
                : this.state.proofsFilters,
        });
    }

    pageChanged(data) {
        const page = data.selected;
        this.setState({ pageIndex: page });
        if (this.props.handlePageIndexChange) {
            this.props.handlePageIndexChange(page);
        }
    }

    pageSizeChanged(pSize) {
        this.setState({ pageSize: pSize, pageIndex: 0 });
        if (this.props.handlePageSizeChange) {
            this.props.handlePageSizeChange(pSize);
        }
    }
    handleFilterChange(filter) {
        const newFilters = { ...this.state.filtersParam };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        this.setState({ filtersParam: newFilters }, () => {
            this.props.handleFilterChange(this.state.filtersParam);
        });
        return newFilters;
    }
    onGridRowsUpdated({ fromRow, toRow, updated }) {
        this.setState(state => {
            const rows = state.rows.slice();
            for (let i = fromRow; i <= toRow; i++) {
                rows[i] = { ...rows[i], ...updated };
                if (this.props.onItemsUpdated) {
                    this.props.onItemsUpdated(rows[i]);
                }
            }
            return { rows };
        });
    }
    EmptyRowsView() {
        const message = 'No Results Found';

        return (
            <div
                style={{
                    textAlign: 'center',
                    padding: '200px',
                }}>
                <h3>{message}</h3>
            </div>
        );
    }
    getValidFilterValues(rows, columnId) {
        //TODO: MUST REFACTOR AFTER BUSINESS HAS BEEN FIXED
        if (columnId == 'createdBy' || columnId == 'modifiedBy' || columnId === 'isEnabled') {
            var filtersData = this.state.proofsFilters;
            return filtersData[columnId];
        }
        var r = rows
            .map(r => r[columnId])
            .filter((item, i, a) => {
                return i === a.indexOf(item);
            });
        return r;
    }

    getRows(rows, filtersParam) {
        return selectors.getRows({ rows, filtersParam });
    }
    onRowSelected(index, row) {
        if (index === -1) return;

        var selectedRow = this.state.rows.find(item => item.id == row.id);
        this.setState({ selectedRow: selectedRow }, () => {
            this.props.onRowSelected(selectedRow);
        });
    }

    getCellActions(column, row) {
        const cellActions = {
            status: firstNameActions,
        };
        return cellActions[column.key];
    }
    render() {
        return (
            <>
                <Pagination
                    totalPages={this.state.totalPages}
                    pageChanged={this.pageChanged.bind(this)}
                    pageSizeChanged={this.pageSizeChanged.bind(this)}
                    selectedPage={this.state.pageIndex}
                    totalCount={this.state.totalCount}
                />
                <ReactDataGrid
                    columns={this.state.columns}
                    toolbar={
                        this.props.enableFilter ? (
                            <Toolbar enableFilter={this.props.enableFilter} />
                        ) : (
                            <></>
                        )
                    }
                    minHeight={this.props.minHeight}
                    rowGetter={i => this.state.rows[i]}
                    rowsCount={this.state.rows.length}
                    onAddFilter={filter => this.handleFilterChange(filter)}
                    onClearFilters={() => this.setState({ filtersParam: {} })}
                    getValidFilterValues={columnKey =>
                        this.getValidFilterValues(this.state.rows, columnKey)
                    }
                    onGridRowsUpdated={this.onGridRowsUpdated}
                    onGridSort={(sortColumn, sortDirection) => {
                        this.setState({ pageIndex: 0 }, () => {
                            this.props.handleGridSort(sortColumn, sortDirection);
                        });
                    }}
                    emptyRowsView={this.EmptyRowsView}
                    enableCellAutoFocus={false}
                    enableCellSelect={false}
                    // enableRowSelect="single"
                    rowKey="id"
                    onRowClick={this.onRowSelected.bind(this)}
                    // getCellActions={this.getCellActions}
                />
            </>
        );
    }
}

export { Grid };
