import React, { useState, useRef, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
const URL_VALIDITY_TEXT = "URL not working, failed to make post request.";
const CustomEmailApiCallBack = ({ emailSettings, setEmailSettings, }) => {
    const [errorText, setErrorText] = useState();
    const isFetchingRef = useRef(false);
    const emailSettingsRef = useRef();
    const onCallbackUrlChanged = async  (e) => {  
        setErrorText("");
        var { value } = e.target;           
        performSearchDebounced(value);        
    };


    useEffect(() => {
        emailSettingsRef.current = emailSettings;
    }, [emailSettings] ) 

    const updateSettings = (value) => {
        var copiedSettings = { ...emailSettingsRef.current };
        copiedSettings.callbackUrl = value;
        copiedSettings.callbackInvalid = false;        
        setEmailSettings((prev) => copiedSettings);
    }

    const updateSettingsInvalid = () => {
        var copiedSettings = { ...emailSettingsRef.current };
        copiedSettings.callbackInvalid = true;
        setEmailSettings((prev) => copiedSettings);
    }
    
    const performSearch = async (value) => {
        isFetchingRef.current = true;
        console.log('searching...' + value);
        
        var response = await postAsync(value);
        console.log("RESPONSE", response.ok);
        if(response.ok) {
            console.log("valid url!");  
            updateSettings(value);          
        }
        else {
            setErrorText(URL_VALIDITY_TEXT);
            updateSettingsInvalid();
        }
        isFetchingRef.current = false;    
    }
    
    const performSearchDebounced = useCallback(debounce(performSearch, 300),[]);


    async function postAsync(url) {
        try{
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'no-cors' },
            };
            
            let response = await  fetch(url,
                
                requestOptions
            );
    
            return response;
        }
        catch {
            return {
                ok: false
            }
        }
    }

    return (
        <div className="custom-email-api-callback">
            <div className={'form-group'}>
                <label htmlFor="name">CallBack URL<span className='custom-template-builder-cell-placeholder-required' >*</span></label>
                <input
                    type="text"
                    defaultValue={emailSettings.callbackUrl}
                    className="form-control mrnda-input-text"
                    name="name"
                    onChange={onCallbackUrlChanged}
                />  
                {
                    errorText  !== undefined && errorText !== "" && 
                    <div style={{ marginTop: '12px', color: 'red' }}>{errorText}</div>                    
                }
                <div className='custom-email-api-callback-remarks'>
                Please be noted that, if API Callback is selected, user needs to configure mails for these cases:<br/>
                    <div className = 'custom-email-api-callback-remarks-list'>                    
                    1. User Registration<br/>
                    2. Password Reset<br/>
                    3. File share<br/>
                    4. File share OTP<br/>
                    5. Comment mention<br/>   
                    6. Annotated File Download<br/>   

                    </div>            
                </div>
            </div>
        </div>
    );
};

export default CustomEmailApiCallBack;
