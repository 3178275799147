import React, { useEffect, useState, useCallback, useRef } from 'react';
// import './CommentBox.scss';
import { withDocumentAnnotationConsumer } from '../../Context/DocumentAnnotationConsumer';
import { documentCommentService } from '../../documentComment.service';
import { utilService, store, authHeader, config, contributorsHelper, colorUtilService } from '../../../../../_helpers';
import { MetadataUrl, MetadataCommentPosition, MetadataPageNumber, MetadataTimeRange } from './MetadataComponents';
import cx from 'classnames';

import { AttachmentsUploader } from './AttachmentsUploader';
import { AttachmentPicker } from './AttachmentPicker';
import MirandaMentionInput from './MirandaMentionInput';
import { mentionHelper } from '../../../../../_helpers/mentionHelper';
import { CommentItemLite } from './CommentItemLite';
import { PROOF_TYPE } from '../../../proof.constants';
import Button from '../../../../_shared/Button';
import { useScreenSizes } from '../../../../responsive/useScreenSizes';
import DesktopBreakpoint from '../../../../responsive/DesktopBreakpoint';
import PhoneBreakpoint from '../../../../responsive/PhoneBreakpoint';
import CommentFooterActions from './CommentFooterActions';
import CommentBoxFooterActions from './CommentBoxFooterActions';
import { onSecondaryBackgroundRounded } from '../../../../_shared/Styles/BackdropStyles';
import { useTheme } from '../../../../App/Theme/ThemeContext';
import { secondarySmallTextStyle } from '../../../../_shared/Styles/TextStyles';


function CommentBox(props) {
    const [commentPost, setcommentPost] = useState('');
    const [posted, setPosted] = useState(false);
    const [attachments, setAttachments] = useState(null);
    const [uploadedAttachments, setUploadedAttachments] = useState(null);
    const [selectedComment, setSelectedComment] = useState('');
    const [addNew, setAddNew] = useState(true);
    const attachmentsRef = useRef(null);
    const uploadHandlersRef = useRef([]);
    const uploadedAttachmentsRef = useRef(null);
    const indexRef = useRef(0);
    // const [index, setIndex] = useState(0);
    const [fontColor, setFontColor] = useState();

    const commentPostRef = useRef('');
    const mentionDataRef = useRef({});
    const { isMobile } = useScreenSizes();
    const { themeColors } = useTheme();
    useEffect(() => {
        attachmentsRef.current = attachments;
        var fontCol = contributorsHelper.findFontColor(props.userAnnotationColor);
        setFontColor(fontCol);
    }, [attachments]);

    useEffect(() => {
        uploadedAttachmentsRef.current = uploadedAttachments;
    }, [uploadedAttachments]);

    useEffect(() => {
        commentPostRef.current = commentPost;
    }, [commentPost]);

    const handleChange = (event) => {
        var { name, value } = event.target;
        setcommentPost(value);
    };


    const handleOnPostComment = useCallback(() => {

        var mentionRes = mentionHelper.processInputValue(props.commentText);
        mentionDataRef.current = mentionRes;
        commentPostRef.current = mentionRes.tValue;
        console.log(mentionRes);

        setPosted(true);
        var uploadHandler = uploadHandlersRef.current[indexRef.current];
        if (uploadHandler) {
            uploadHandler(indexRef.current);
        }
        else {
            if (props.editMode) {
                updateComment();
            } else {
                postComment();
            }
        }
    });

    const handleOnAttachAnnotationToComment = useCallback((commentId) => {
        setPosted(true);
        props.handleOnAttachAnnotationWithComment(selectedComment);
        console.log("Attach with existing comment");
    });

    const handleOnCancelComment = useCallback(() => {
        setPosted(false);
        props.setEditingCommentId('');
        props.handleOnCancelComment();
        props.clearShapeTools();
    }, []);




    const onUploadComplete = (uploadedNow, attachmentNo) => {
        var prev = uploadedAttachmentsRef.current === null ? [] : uploadedAttachmentsRef.current;
        uploadedAttachmentsRef.current = ([...prev, uploadedNow]);

        var newIndex = indexRef.current + 1;
        indexRef.current = (newIndex);
        var uploadHandler = uploadHandlersRef.current[newIndex];

        if (uploadHandler) {
            uploadHandler(newIndex);
        }
        else {
            postComment();
        }


    }

    const postComment = () => {
        props.handleOnPostComment(commentPostRef.current, uploadedAttachmentsRef.current, mentionDataRef.current.mentions);
    }

    const updateComment = () => {
        props.handleOnUpdateComment(commentPostRef.current, mentionDataRef.current.mentions);
    }

    const updateUploadHandlers = (handler) => {
        var newArr = [...uploadHandlersRef.current, handler];
        uploadHandlersRef.current = (newArr);
    }
    const onRemoveAttachment = (index) => {

        var attachmentsCopy = attachments.map((attachment) => {
            var newAttachment = { ...attachment };
            return newAttachment;
        });

        attachmentsCopy.splice(index, 1);
        uploadHandlersRef.current.splice(index, 1);
        setAttachments(attachmentsCopy);

    }

    return (
        <div
            style={isMobile ? { padding: '0 20px', margin: '16px 0 0px 0px' } : {}}
            className={cx({
                'comments-container': true,
                'comments-container__add-comment-box': !props.editMode,
                'comments-container__edit-comment-box': props.editMode
            })}
        >
            <div>
                <div className="comment-form" style={isMobile ? { ...onSecondaryBackgroundRounded, padding: '0px' } : {}}>
                    {!props.editMode && (
                        <div
                            style={isMobile ? { margin: '20px 20px 0px 20px' } : {}}
                            className="comment-form__header">
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div className="comment-form__avatar" style={isMobile ? { flex: '0 32px' } : {}}>
                                    <span
                                        style={
                                            isMobile
                                                ? {

                                                    background: colorUtilService.changeOpacity(
                                                        themeColors.primaryTextColor,
                                                        0.1
                                                    ),
                                                    color: 'var(--primary-text-color)',
                                                    height: '32px',
                                                    width: '32px',
                                                    fontWeight: '600',
                                                    lineHeight: '18px',
                                                    fontSize: '12px'
                                                }
                                                : {
                                                    background: props.userAnnotationColor,
                                                    color: fontColor
                                                }
                                        }
                                        className="mrnda-avatar--secondary--small"
                                    >
                                        {utilService.getUserInitial(
                                            props.proofVersion.mediaType === 2 ||
                                                props.proofVersion.mediaType === 4
                                                ? props.videoMetadata.currentUser?.fullName
                                                : props.documentMetadata.currentUser?.fullName
                                        )}
                                    </span>
                                </div>
                                <div style={isMobile ? { minWidth: '62%', marginLeft: '12px' } : {}} className="comment-form__details" >
                                    <div
                                        style={
                                            isMobile
                                                ? {
                                                    fontSize: '12px',
                                                    fontWeight: 600,
                                                    lineHeight: '16px',
                                                    color: 'var(--primary-text-color)'
                                                }
                                                : {}
                                        }
                                        className="comment-form__author">
                                        {props.proofVersion.mediaType === 2 ||
                                            props.proofVersion.mediaType === 4
                                            ? props.videoMetadata.currentUser?.fullName
                                            : props.documentMetadata.currentUser?.fullName}
                                    </div>
                                    <PhoneBreakpoint>
                                        <div
                                            className="comment__date-area"
                                            style={isMobile ? secondarySmallTextStyle : {}}>
                                            <div className="comment__date-area__date">
                                                <span className="date-field text-truncate">
                                                    {new Date().toLocaleString([], {
                                                        year: 'numeric',
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    </PhoneBreakpoint>
                                    <DesktopBreakpoint>
                                        <div className="comment-form__info">
                                            <span>
                                                <div className="time-range">
                                                    {props.proofVersion.mediaType === 2 ||
                                                        props.proofVersion.mediaType === 4 ? (
                                                        <MetadataTimeRange
                                                            frameRange={props.frameRange}
                                                            classes="comment__metadata__time-range__item"
                                                            frameRate={props.videoMetadata.frameRate}
                                                        />
                                                    ) : (
                                                        utilService.isWebsiteProof(props.proofVersion)
                                                            ? <MetadataUrl url={ utilService.isHTMLLiveProof(props.proofVersion) ? props.htmlLiveProofViewerInfo?.url : utilService.getOnlyLastPart(props.htmlLiveProofViewerInfo?.url) } classes="comment__metadata__time-range__item" />
                                                            : <MetadataPageNumber page={props.page} classes="comment__metadata__time-range__item" />
                                                    )}
                                                </div>
                                            </span>

                                            <DesktopBreakpoint>
                                                    <AttachmentPicker
                                                        setAttachments={setAttachments}
                                                        attachments={attachments}
                                                    />
                                            </DesktopBreakpoint>


                                        </div>
                                    </DesktopBreakpoint>

                                </div>
                                <PhoneBreakpoint>
                                    <MetadataCommentPosition
                                        style={{
                                            flex: '1',
                                            flexBasis: 'auto',
                                            display: 'flex',
                                            alignItems: 'start',
                                            textAlign: 'right',
                                            fontSize: '12px',
                                            lineHeight: '16px',
                                            justifyContent: 'end'
                                        }}
                                        {...{
                                            proofVersion: props.proofVersion,
                                            rangeSelectorValue: props.frameRange,
                                            commentItem: { frameRange: props.frameRange, page: props.page },
                                            editMode: false,
                                            metadata: props.videoMetadata
                                        }}
                                    />
                                </PhoneBreakpoint>
                            </div>
                        </div>
                    )}
                    {addNew === true && (
                        <>
                            <div style={isMobile ? { margin: '32px 20px 0 20px' } : {}} className="comment__content comment-form__content">
                                <MirandaMentionInput
                                    placeholder={'Enter your comment here...'}
                                    aValue={props.commentText}
                                    setAValue={props.setCommentText}
                                    inputFor="comment"
                                />
                                <div
                                    style={isMobile ? { margin: '0px 0px 0px 0px', padding: '0px 0px' } : {}}
                                    className="comment-form__attachments">
                                    <AttachmentsUploader
                                        attachments={attachments}
                                        onUploadComplete={onUploadComplete}
                                        updateUploadHandlers={updateUploadHandlers}
                                        onRemoveAttachment={onRemoveAttachment}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    {addNew === false && (
                        <div className="comment-list-lite">
                            <span className="mrnda-input-label padding-1x--bottom">
                                Select a comment to add...
                            </span>
                            {props.annotationComments.map((comment) => {
                                return (
                                    <div key={comment.id}>
                                        <CommentItemLite
                                            onCommentItemLiteClicked={() => {
                                                setSelectedComment(comment.id);
                                            }}
                                            commentItem={comment}
                                            proofVersion={props.proofVersion}
                                            isSelected={
                                                selectedComment === comment.id
                                            }></CommentItemLite>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    <DesktopBreakpoint>
                        <div
                            className="comment-form__footer"
                            style={{ marginTop: props.editMode ? '12px' : '0' }}>
                            <div className="comment-form__footer__action-buttons">
                                {addNew === false ? (
                                    <button
                                        style={{ marginLeft: '-36px' }}
                                        className="button button-comment-form--primary"
                                        onClick={handleOnAttachAnnotationToComment}
                                        disabled={
                                            props.canvasTextEditEntered ||
                                            posted ||
                                            props.disableCommentPost
                                        }>
                                        <span>Add</span>
                                        <div className="tooltip button-comment-form--primary__popover tooltip--bottom">
                                            <span>Add to selected comment</span>
                                        </div>
                                    </button>
                                ) : (
                                    <button
                                        className="button button-comment-form--primary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleOnPostComment();
                                        }}
                                        disabled={
                                            props.canvasTextEditEntered ||
                                            posted ||
                                            props.disableCommentPost
                                        }>
                                        <span>Save</span>
                                        <div className="tooltip button-comment-form--primary__popover tooltip--bottom">
                                            <span>Post comment</span>
                                        </div>
                                    </button>
                                )}
                                <button
                                    className="button button-comment-form--textual"
                                    onClick={handleOnCancelComment}>
                                    <span>Cancel</span>
                                </button>
                            </div>
                        </div>
                    </DesktopBreakpoint>
                    <PhoneBreakpoint>
                        <CommentBoxFooterActions
                            handleOnCancelClicked={handleOnCancelComment}
                            handleSaveClicked={handleOnPostComment}
                            setAttachments={setAttachments}
                            attachments={attachments}
                            style={{ padding: '20px' }}
                        />
                    </PhoneBreakpoint>
                </div>
            </div>
        </div>
    );
}
export { CommentBox };
