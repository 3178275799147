import { authHeader, handleResponse, config } from '../../_helpers';

export const annotationService = {
    getAnnotationComments,
    setAnnotationComments,
    getDocumentComments
};

async function getAnnotationComments(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject)
    };

    return fetch(
        `${config.apiUrl}/AnnotationComment/getAnnotationCommentsTree`,
        requestOptions
    );
}
async function setAnnotationComments(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject)
    };

    return fetch(`${config.apiUrl}/AnnotationComment/set`, requestOptions).then(
        handleResponse
    );
}


async function getDocumentComments(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject)
    };
    return fetch(
        `${config.apiUrl}/AnnotationComment/GetDocumentComments`,
        requestOptions
    ).then(handleResponse);
}