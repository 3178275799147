import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { userActions, SupportContactArea } from '../../../components';
import { utilService, history } from '../../../_helpers';
import './UserLoginPage.scss';
import { tenantService } from '../../Tenant';
import withLiveUpdateHook from '../../Proof/LiveUpdateWrapper';
import { withRouter } from 'react-router-dom';
import localStorage from 'local-storage';
import { isCompactNav } from '../../../_helpers/navbarUtil';
import { LoginPageTitleBar } from './LoginPageTitleBar';

class UserLoginPage extends React.Component {
    constructor(props) {
        super(props);
        const subdomain = window.location.host.split('.')[0];
        const username = (props.location.username == undefined)? "": props.location.username;
        this.state = {
            username: username,
            password: '',
            submitted: false,
            subdomain: subdomain,
            loginNoMatch: true,
            loginErrorMessage: "",
            redirectMessage: '',
            tenantExist: true
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        console.log('REROUTE', this.props);
        document.title = 'Brandshare Collaboration - Login';
        // this.props.dispatch(userActions.logout());
        this.props.liveUpdateHook.setConnectionStatus("Disconnected");
        const { data } = this.props.location;
        if (data && data.redirectMessage) {
            this.setState({ redirectMessage: data.redirectMessage });
        }
        const subdomain = window.location.hostname.split('.')[0];
        var tenantNotExist = await tenantService.isSubdomainUnique(subdomain);
        if (tenantNotExist) {
            this.setState({ redirectMessage: "Tenant isn't registered ! ", tenantExist: false });
        } else {
            const { authentication } = this.props;
            if (authentication && Object.getOwnPropertyNames(authentication).length !== 0) {
                history.replace({ pathname: '/' });
            }
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
            submitted: false,
            loginNoMatch: false,
            redirectMessage: ''
        });
    }

    getImageSource() {
        var preference = localStorage.get('preference-theme') || 'light';        
        return preference === 'dark'?  '../../assets/Images/brand_logo_all_white.png' : '../../assets/Images/brand_logo.png';
    }

    async handleSubmit(e) {
        e.preventDefault();
        await utilService.setStateAsync.bind(this)({
            submitted: true
        });
        const { username, password } = this.state;
        const subdomain = window.location.host.split('.')[0];
        if (username && password) {
            var responseAuth = await this.props.dispatch(
                userActions.login(username, password, subdomain)
            );
            
            if (responseAuth && !responseAuth.success) {
                await utilService.setStateAsync.bind(this)({
                    loginNoMatch: true,
                    loginErrorMessage: responseAuth.message || "Incorrect email or password"
                });
            } else {
                var { from } = this.props?.location?.redirect || {}
                from = from?.pathname ? from : {
                    pathname: '/' 
                };
                history.push({ ...from, hasBackRoute: false });
            }
        }
    }
    
    render() {
        const {
            username,
            password,
            submitted,
            loginNoMatch,
            loginErrorMessage,
            redirectMessage,
            tenantExist
        } = this.state;
        return (
           <>
           {            
            isCompactNav() === true  && 
            <LoginPageTitleBar/>
           }
            <div className="mrnda-main-content padding-2x">
                <div className="mrnda-flex-center-wrapper">
                    <div className="sign-in-box-wrapper">
                        <div className="tenant-logo margin-2x--bottom typography--center">
                            <div>
                                {/* <i style={{ color: '#fc275c' }} className="fab fa-mix fa-7x"></i> */}
                                <img src={this.getImageSource()}></img>
                            </div>
                        </div>
                        <div className="sign-in-box mrnda-card padding-3x margin-4x--bottom">
                            <h1 className="margin-3x--bottom text--center">Welcome</h1>
                            <div className="tile-message">
                                {submitted && loginNoMatch && (
                                    <div className="typography--center typography--caution margin-2x--bottom">
                                        {loginErrorMessage}
                                    </div>
                                )}
                                {redirectMessage && redirectMessage.trim().length && (
                                    <div className="typography--center typography--caution margin-2x--bottom">
                                        {redirectMessage}
                                    </div>
                                )}
                                <div className={cx({ 'd-none': !tenantExist })}>
                                    <form className="" name="form" onSubmit={this.handleSubmit}>
                                        <div className="form-horizontal">
                                            <div className="form-horizontal">
                                                <input
                                                    type="email"
                                                    id="inputEmail"
                                                    className="mrnda-input-text margin-2x--bottom"
                                                    name="username"
                                                    value={username}
                                                    onChange={this.handleChange}
                                                    placeholder="Email"
                                                    required
                                                    autoFocus=""
                                                />

                                                <input
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    value={password}
                                                    onChange={this.handleChange}
                                                    className="mrnda-input-text margin-2x--bottom"
                                                    placeholder="Password"
                                                    required
                                                />

                                                <div className="control-group">
                                                    <div className="controls">
                                                        <button className="mrnda-btn mrnda-btn--full margin-2x--bottom">
                                                            Sign in
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <p className="text sm-forward typography--center">
                                        <a
                                            className="typography--small"
                                            onClick={() => history.push('/forgotpassword')}>
                                            Forgot your password?
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <SupportContactArea />
                    </div>
                </div>
            </div>
            
           </>
        );
    }
}

function mapStateToProps(state) {
    console.log('IN Login:');
    console.log(state);
    const { authentication } = state;
    return {
        authentication: authentication.user ? authentication.user.authData : {}
    };
}

const withLiveUpdate = withLiveUpdateHook(UserLoginPage);

const connectedUserLoginPage = connect(mapStateToProps)(withRouter(withLiveUpdate));
export { connectedUserLoginPage as UserLoginPage };
