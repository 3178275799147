import React, { Component } from 'react';
import { connect } from 'react-redux';
import { proofActions, Grid, TreeView } from '..';
import { Filters } from 'react-data-grid-addons';

const { MultiSelectFilter } = Filters;
const viewButtonFormatter = ({ value }) => {
    return <button onClick={() => onButtonClick(value)}>View</button>;
};
const columns = [
    // { key: 'documentId', name: 'Id' ,  formatter: viewButtonFormatter},
    {
        key: 'documentName',
        name: 'File Name',
        // filterRenderer: AutoCompleteFilter,
    },
    // { key: 'filePath', name: 'File Path', filterRenderer: AutoCompleteFilter },
    { key: 'createdBy', name: 'Created By', filterRenderer: MultiSelectFilter },
    { key: 'createdAt', name: 'Created on', filterRenderer: MultiSelectFilter },
    { key: 'modifiedAt', name: 'Last Modified' },
    {
        key: 'modifiedBy',
        name: 'Last Modified by',
        filterRenderer: MultiSelectFilter,
    },
    // { key: 'status', name: 'Status', filterRenderer: MultiSelectFilter },
    { key: 'defaultVersionLabel', name: 'Current Version' },
    // { key: 'action', name: 'View', formatter: viewButtonFormatter },
];

const defaultColumnProperties = {
    sortable: true,
    filterable: true,
    resizable: true,
};
class ProofsContainerListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            proofs: [],
            columns: columns.map(c => ({
                ...c,
                ...defaultColumnProperties,
            })),
            pageSize: 20,
            pageIndex: 0,
            totalPages: 1,
            totalCount: 0,
            sortColumn: null,
            sortDirection: 'NONE',
            filtersParam: [],
            proofsFilters: {},
        };
    }
    async componentDidMount() {
        await this.loadFilterDataFromServer();
        this.loadDataFromServer();
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.proofs) {
            return {
                proofs: nextProps.proofs,
                // pageSize: nextProps.pageSize,
                // pageIndex: nextProps.pageIndex,
                totalPages: nextProps.totalPages,
                totalCount: nextProps.totalCount,
                proofsFilters: nextProps.proofsFilters
                    ? nextProps.proofsFilters
                    : prevState.proofsFilters,
            };
        } else {
            return null;
        }
    }
    loadDataFromServer() {
        const sortMapper = {
            ['ASC']: 1,
            ['DESC']: -1,
            ['NONE']: 1,
        };
        const data = {
            page: this.state.pageIndex,
            pageSize: this.state.pageSize,
            proofSortModel: {
                sortDirection: sortMapper[this.state.sortDirection],
                sortColumn: this.state.sortColumn,
            },
            proofFilters: this.state.filtersParam,
        };
        this.props.dispatch(proofActions.getAll(data));
    }
    async loadFilterDataFromServer() {
        await this.props.dispatch(proofActions.getAllProofFilters());
    }
    handleGridSort(sCol, sDir) {
        this.setState({ pageIndex: 0, sortColumn: sCol, sortDirection: sDir }, () => {
            this.loadDataFromServer();
        });
    }
    handlePageIndexChange(pageIndex) {
        this.setState({ pageIndex: pageIndex }, () => {
            this.loadDataFromServer();
        });
    }
    handlePageSizeChange(psize) {
        this.setState({ pageSize: psize, pageIndex: 0 }, () => {
            this.loadDataFromServer();
        });
    }
    handleFilterChange(filtersParam) {
        console.log('FiltersParam', filtersParam);
        var filtersPayload = [];
        for (var key in filtersParam) {
            if (filtersParam.hasOwnProperty(key)) {
                if (Array.isArray(filtersParam[key].filterTerm)) {
                    filtersParam[key].filterTerm.forEach(function(item) {
                        const filterItem = {
                            filterColumn: filtersParam[key].column.key,
                            filterValue: item.value,
                        };
                        filtersPayload.push(filterItem);
                    });
                } else {
                    const filterItem = {
                        filterColumn: filtersParam[key].column.key,
                        filterValue: filtersParam[key].filterTerm,
                    };
                    filtersPayload.push(filterItem);
                }
            }
        }
        this.setState({ pageIndex: 0, filtersParam: filtersPayload }, () => {
            this.loadDataFromServer();
        });
    }
    render() {
        return (
            <>
              {/* <div className="left left--draggable clear">
                    <div className="left__content">
                    </div>
                </div> */}
                
                <div className="grid-area">
                    <div className="right__content clear">
                        <Grid
                            columns={this.state.columns}
                            rows={this.state.proofs}
                            enableFilter={true}
                            minHeight={500}
                            proofsFilters={this.state.proofsFilters}
                            handleGridSort={this.handleGridSort.bind(this)}
                            totalPages={this.state.totalPages}
                            handlePageIndexChange={this.handlePageIndexChange.bind(this)}
                            handlePageSizeChange={this.handlePageSizeChange.bind(this)}
                            totalCount={this.state.totalCount}
                            handleFilterChange={this.handleFilterChange.bind(this)}
                            onRowSelected={row => this.props.onRowSelected(row)}
                        />
                    </div>
                </div>                
            </>
        );
    }
}

function mapStateToProps(state) {
    const { proofData, proofsFilters } = state;
    const { rows, pageIndex, pageSize, totalCount, totalPages } = proofData;

    return {
        proofs: rows,
        pageIndex,
        pageSize,
        totalCount,
        totalPages,
        proofsFilters: proofsFilters.proofsFilters,
    };
}

const connectedProofsContainer = connect(mapStateToProps)(ProofsContainerListView);
export { connectedProofsContainer as ProofsContainerListView };
