import React from 'react';
import cx from 'classnames';
import { useScreenSizes } from '../../../../responsive/useScreenSizes';
import Button from '../../../../_shared/Button';

export const AddGroupAnnotation = (props) => {
    const mainStyle = { background : 'transparent', marginTop: '12px' ,marginLeft: '20px' , marginRight: '20px' };
    const { isMobile } = useScreenSizes();
    return (
        <div style={isMobile? {...mainStyle}: {}} className="multi-annotation">
            <span>Adding annotation to this comment</span>

            <div
                style={{
                    marginTop: !isMobile? '8px' : '12px'
                }}
                className="comment-form__footer__action-buttons">
                {isMobile ? (
                    <>    
                        <Button
                            width = "70px"
                            variant={'primary'}
                            onClick={() => props.onAnnotationMerge(props.commentItem.id)}
                            disabled={props.length <= 0}>
                            <span>Add</span>
                        </Button>                    
                        <Button
                            variant={'transparent'}
                            style={{ marginRight: '12px' }}
                            onClick={() => props.cancelMergeAnnotation(props.commentItem.id)}
                            >
                            <span>Cancel</span>
                        </Button>
                    </>
                ) : (
                    <>
                        <button
                            className={cx({
                                button: true,
                                'button-comment-form--primary': true,
                                'authorization-disabled': props.length <= 0
                            })}
                            onClick={() => props.onAnnotationMerge(props.commentItem.id)}>
                            <span>Add</span>
                        </button>

                        <button
                            className="button button-comment-form--textual"
                            onClick={() => props.cancelMergeAnnotation(props.commentItem.id)}>
                            <span>Cancel</span>
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};
