//@ts-check

import React from 'react';

const DragRightIcon = ({ height = 20, width = 7, color = 'currentColor' }) => {
    return (
        <svg height={height} viewBox="0 0 7 20" width={width} xmlns="http://www.w3.org/2000/svg">
            <path
                d="m-6.37406619 7.01463034c.26821319-.48278374.87701648-.65672753 1.35980021-.38851435l8.51435707 4.72915731 8.51435711-4.72915731c.4482991-.2490551 1.005258-.1168626 1.2972828.28983642l.0625174.09867793c.2682131.48278373.0942694 1.09158702-.3885144 1.3598002l-8.99999998 4.99999996c-.30202254.1677903-.66926332.1677903-.97128586 0l-9-4.99999996c-.48278373-.26821318-.65672753-.87701647-.38851435-1.3598002z"
                transform="matrix(0 1 1 0 -6.500273 6.499909)"
            />
        </svg>
    );
};

export default DragRightIcon;
