import React from 'react';

import { connect } from 'react-redux';
import { alertActions } from '../../components';
import './Nav.css';
import { FooterComponent } from './FooterComponent';
import DesktopBreakpoint from '../responsive/DesktopBreakpoint';
import { NavPrimaryActions } from './NavPrimaryActions';
import { NavMiddleArea } from './NavMiddleArea';
import NavSecondaryActions from './NavSecondaryActions';

class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingAnimation: false
        };
        this.handleSearch = this.handleSearch.bind(this);        
    }
    handleSearch(e) {
        e.preventDefault();
        this.props.dispatch(alertActions.loading(!this.state.loadingAnimation));
        // alertActions.success("This is a message");
        this.setState({
            loadingAnimation: !this.state.loadingAnimation
        });
    }
    
    render() {
        const { alert, authentication } = this.props;
        return (
            <>
                <nav   
                    id={"main-nav"}                 
                    className={`mrnda-navigation  ${alert.isHideTopbar ? 'd-none' : ''}`}>
                    <NavPrimaryActions/>
                    <NavMiddleArea
                        authentication={authentication}
                        />
                    
                    <NavSecondaryActions/>

                </nav>
                {/* <header role="main" className="main-header">
                    <div className="main-header__content">
                        <div className="main-header__logo">Brandshare Collaboration</div>
                        <nav className="main-header__nav">
                            <ul className="nav-list">
                                <li className="nav-list__item margin-2x--right">
                                    <a href="#" className="text">
                                        <i className="far fa-comment-dots mr-1" />
                                        Open Live Chat Room
                                    </a>
                                </li>

                                <li className="nav-list__item">
                                    <a href="#" className="main-header__user">
                                        <span className="main-header__user-icon margin-_5x--right">
                                            <i className="fas fa-user-alt" />
                                        </span>
                                        James Dean
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <nav className="main-header__nav">
                            <ul className="nav-list">
                                <li className="nav-list__item margin-2x--right">
                                    <a href="#" className="text">
                                        <i className="far fa-comment-dots mr-1" />
                                        Open Live Chat Room
                                    </a>
                                </li>

                                <li className="nav-list__item">
                                    <a href="#" className="main-header__user">
                                        <span className="main-header__user-icon margin-_5x--right">
                                            <i className="fas fa-user-alt" />
                                        </span>
                                        James Dean
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </header> */}
                {/* {alert.isLoading && <div id="rainbow-progress-bar" />} */}
                <DesktopBreakpoint>
                    <FooterComponent />
                </DesktopBreakpoint>
            </>
        );
    }
}

export const FooterLoadingElipsis = () => {
    return (
        <div className="footer-loading-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};
function mapStateToProps(state) {
    const { alert, authentication } = state;
    return {
        alert,
        authentication: authentication.user ? authentication.user.authData : {}
    };
}

const NavBar = connect(mapStateToProps)(Nav);
export default NavBar;

// export default Nav;
