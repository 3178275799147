//@ts-check

import React from 'react';

const LinkIcon = ({ height = 32, width = 32, color = 'currentColor' }) => {
    return (
        // <svg
        //     width={width}
        //     height={height}
        //     viewBox="0 0 32 32"
        //     version="1.1"
        //     xmlns="http://www.w3.org/2000/svg" >

        //     <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        //         <g id="32px/Interaction/Multimedia/pause" fill={color} >
        //             <rect id="Rectangle" fillOpacity="0" x="0" y="0" width={width} height={height}></rect>
        //             <g id="Outline" transform="translate(3.000000, 3.000000)" fillRule="nonzero">
        //                 <path
        //                     d="M8.5,0 C9.32842712,0 10,0.671572875 10,1.5 L10,24.5 C10,25.3284271 9.32842712,26 8.5,26 L1.5,26 C0.671572875,26 0,25.3284271 0,24.5 L0,1.5 C0,0.671572875 0.671572875,0 1.5,0 L8.5,0 Z M8.5,1 L1.5,1 C1.22385763,1 1,1.22385763 1,1.5 L1,24.5 C1,24.7761424 1.22385763,25 1.5,25 L8.5,25 C8.77614237,25 9,24.7761424 9,24.5 L9,1.5 C9,1.22385763 8.77614237,1 8.5,1 Z M24.5,0 C25.3284271,0 26,0.671572875 26,1.5 L26,24.5 C26,25.3284271 25.3284271,26 24.5,26 L17.5,26 C16.6715729,26 16,25.3284271 16,24.5 L16,1.5 C16,0.671572875 16.6715729,0 17.5,0 L24.5,0 Z M24.5,1 L17.5,1 C17.2238576,1 17,1.22385763 17,1.5 L17,24.5 C17,24.7761424 17.2238576,25 17.5,25 L24.5,25 C24.7761424,25 25,24.7761424 25,24.5 L25,1.5 C25,1.22385763 24.7761424,1 24.5,1 Z"
        //                     id="Combined-Shape"></path>
        //             </g>
        //         </g>
        //     </g>
        // </svg>

        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width={width}
            height={height}
            x="0"
            y="0"
            viewBox="0 0 512 512">
            <g>
                <path
                    d="M307.148 35.148 203.081 139.214c-.201.2-.326.443-.526.645 25.637-3.734 51.974-1.174 76.388 8.346l70.63-70.63c23.399-23.399 61.454-23.399 84.853 0 23.399 23.397 23.399 61.454 0 84.851-3.99 3.992-110.083 110.085-104.066 104.066-23.584 23.586-62.276 22.577-84.853 0-11.693-11.693-30.731-11.693-42.426 0l-18.214 18.214c5.052 8.584 10.844 16.844 18.214 24.214 44.44 44.44 120.934 47.99 169.061.526.201-.2.443-.326.645-.526l104.066-104.066c46.862-46.864 46.862-122.842 0-169.706-46.864-46.864-122.841-46.864-169.705 0z"
                    fill={color}
                    opacity="1"></path>
                <path
                    d="m233.285 363.568-70.858 70.858c-23.397 23.399-61.454 23.399-84.851 0-23.399-23.399-23.399-61.454 0-84.853L181.869 245.28c23.584-23.584 62.276-22.577 84.853 0 11.693 11.695 30.732 11.695 42.426 0l18.214-18.214c-5.052-8.584-10.844-16.844-18.214-24.212-44.355-44.357-120.793-48.131-169.061-.527-.201.2-.443.326-.645.527L35.148 307.148c-46.862 46.862-46.864 122.842 0 169.706 46.864 46.862 122.844 46.862 169.706 0l104.293-104.295c.201-.2.326-.441.526-.645-25.637 3.734-51.973 1.174-76.388-8.346z"
                    fill={color}
                    opacity="1"></path>
            </g>
        </svg>
    );
};

export default LinkIcon;
