import React from 'react';

const Button = ({ variant, children, style ,disabled,...rest }) => {
    const buttonStyles = {
        backgroundColor:
            'var(--primary-color)',
        height: '40px',
        width: '64px',
        fontWeight: '400px',
        fontSize: '14px',
        color: 'var(--primary-text-color)',
        borderRadius: '4px',
        boxShadow: 'none',
        lineHeight: '26px',
        paddingLeft: '16px',
        paddingRight: '16px',
        outline: '0',
        textOverflow: 'clip',
        minWidth: '70px',
        border: '0',
        cursor: disabled ? 'not-allowed' : 'pointer', // Add cursor style for disabled state
        opacity: disabled ? 0.35 : 1,
    
      };

  if (variant === 'primary') {
    
  } else if (variant === 'transparent') {
    buttonStyles.backgroundColor = 'transparent';    
    buttonStyles.color = 'var(--secondary-text-color)';
  }

  return (
    <button {...rest} style={{...buttonStyles, ...style}}>
      {children}
    </button>
  );
};

export default Button;