import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { withVideoCompareConsumer } from '../VideoAnnotation/Context/VideoAnnotationConsumer';
import { VideoCompareProvider } from './VideoCompareProvider';
import { proofService } from '../proof.service';
import { CompareVideoPlayer } from './CompareVideoPlayer';
import { colorUtilService, history } from '../../../_helpers';
import { ProofVideoCompareToolbarHOC, VideoCompareToolbar } from './ProofVideoCompareToolbar';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { proofVersionDownload, proofVersions } from '../proofs.reducer';
import {
    convertZoomToScale,
    calculateNewZoom,
    bringToView
} from '../DocumentAnnotation/DocumentAnnotationUtil';

const pages = [
    { page: 1, width: 1275, height: 1650, src: 'https://i.imgur.com/uOlQynF.png' },
    { page: 2, width: 1275, height: 1650, src: 'https://i.imgur.com/yueerJX.png' }
];

const ProofVideoCompare = (props) => {
    return (
        <VideoCompareProvider>
            <ProofDocumentCompareWrapperHOC {...props}></ProofDocumentCompareWrapperHOC>
        </VideoCompareProvider>
    );
};


class ProofDocumentCompareWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.initialCounter = 0;
        this.threshold = 0.05;
        this.contrast = 0.2;
        this.left = null;
        this.right = null;
        this.state = {
            proof: {},
            proofCompareVersions: [{ name: 'Side A' }, { name: 'Side B' }],
            resizeCounter: 0
        };
        this.isSyncingLeftScroll = false;
        this.isSyncingRightScroll = false;
    }

    async componentDidMount() {
        var pp = this.proofVersionA;
        const { data } = this.props.location;
        if (!data) {
            history.goBack();
            return;
        }
        if (!data.proofCompareVersions) {
            history.goBack();
            return;
        }
       
        
        var proofCompareVersions = data.proofCompareVersions;
        document.title = 'Brandshare Collaboration - Compare';

        
        

        this.props.setProofVersionA({ ...proofCompareVersions[0] });
        this.props.setProofVersionB({ ...proofCompareVersions[1] });

        document.title = 'Brandshare Collaboration - Compare';

        //await utilService.setStateAsync.bind(this)({ proofCompareVersions });
        ///ltCompareService.windowOnLoad(proofCompareVersions);

        this.left = document.getElementById('left');
        this.right = document.getElementById('right');

        this.props.setDocumentViewerA(this.left);
        this.props.setDocumentViewerB(this.right);

        this.props.setStartAutoCompare(this.startCompare.bind(this));
        this.props.setClearCanvas(this.clearCanvas.bind(this));
        
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this.handleResize.bind(this));
    };

    handleResize() {
        this.setState({resizeCounter: this.initialCounter++});        
        var currentSide = this.props.compareSide;        
        this.props.setCompareSide('none');            
        this.props.setCompareSide(currentSide);                   
    }

    clearCanvas() {
        // console.log("CLEARING CONTEXT")
        if (this.ctx) {
            this.ctx.clearColor(0, 0, 0, 0);
            this.ctx.clear(this.ctx.COLOR_BUFFER_BIT);
        }
    }

    regenerateCanvas = () => {
        this.outputCanvas.width = 0;
        this.outputCanvas.height = 0;
        var z = this.props.containerZoom;
        var scale = convertZoomToScale(z);
        var clientRect = this.secondaryChild.getBoundingClientRect();
        var width = clientRect.width;
        var height = clientRect.height;
        console.log("CANVAS SIZE", width, height);
        if (
            ((this.ctx_shader = null),
            (this.ctx_vx_ptr = null),
            (this.ctx_vx = null),
            (this.ctx_ix = null),
            (this.ctx_tex1 = null),
            (this.ctx_tex2 = null),
            (this.ctx = null),
            this.outputCanvas)
        ) {
            (this.outputCanvas.width = width),
                (this.outputCanvas.height = height),
                (this.ctx = (function (t) {
                    for (var e = 0; e < 4; e++) {
                        var i = t.getContext(
                            ['webgl', 'experimental-webgl', 'moz-webgl', 'webkit-3d'][e]
                        );
                        if (i) return i;
                    }
                    return null;
                })(this.outputCanvas)),
                this.ctx.clearColor(0, 0, 0, 0),
                this.ctx.clear(this.ctx.COLOR_BUFFER_BIT);
            var t = this.ctx.createShader(this.ctx.VERTEX_SHADER);
            this.ctx.shaderSource(
                t,
                '\n\t\t\tattribute vec2 vx;\n\t\t\tvarying vec2 tx;\n\t\t\tvoid main(){\n\t\t\t\tgl_Position=vec4(vx.x*2.0-1.0,1.0-vx.y*2.0,0,1);\n\t\t\t\ttx=vx;\n\t\t\t}\n\t\t'
            ),
                this.ctx.compileShader(t);
            var e = this.ctx.createShader(this.ctx.FRAGMENT_SHADER);
            if (
                (this.ctx.shaderSource(
                    e,
                    '\n\t\t\tprecision lowp float;\n\t\t\tuniform sampler2D sm;\n\t\t\tuniform sampler2D sm2;\n\t\t\tuniform float threshold;\n\t\t\tuniform float contrast;\n\t\t\tuniform vec4 highlightColor;\n\t\t\tvarying vec2 tx;\n\t\t\tfloat sigmoid(float x){\n\t\t\t\treturn 1.0/(1.0+exp2(-(x-0.5)*contrast*contrast*100.0));\n\t\t\t}\n\t\t\tvoid main(){\n\t\t\t\tvec4 c1 = texture2D(sm,tx);\n\t\t\t\tvec4 c2 = texture2D(sm2,tx);\n\t\t\t\tfloat sums = 0.0;\n\t\t\t\tsums += abs(c1.r-c2.r);\n\t\t\t\tsums += abs(c1.g-c2.g);\n\t\t\t\tsums += abs(c1.b-c2.b);\n\t\t\t\tfloat diff = clamp(sums/3.0, 0.0, 1.0);\n\t\t\t\tif(c1.a > 0.0 && c2.a > 0.0 && diff>threshold){\n\t\t\t\t\tfloat midR = sigmoid((c2.r+c2.g+c2.b)/3.0);\n\t\t\t\t\tvec3 diffMask = vec3(\n\t\t\t\t\t\tc1.r * ((highlightColor.r) + midR * (1.0-highlightColor.r)),\n\t\t\t\t\t\tc1.g * ((highlightColor.g) + midR * (1.0-highlightColor.g)),\n\t\t\t\t\t\tc1.b * ((highlightColor.b) + midR * (1.0-highlightColor.b))\n\t\t\t\t\t);\n\t\t\t\t\tc1.r = c1.r * (1.0-highlightColor.a) + diffMask.r * highlightColor.a;\n\t\t\t\t\tc1.g = c1.g * (1.0-highlightColor.a) + diffMask.g * highlightColor.a;\n\t\t\t\t\tc1.b = c1.b * (1.0-highlightColor.a) + diffMask.b * highlightColor.a;\n\t\t\t\t\tgl_FragColor=c1;\n\t\t\t\t}else{\n\t\t\t\t\tdiscard;\n\t\t\t\t}\n\n\t\t\t}\n\t\t'
                ),
                this.ctx.compileShader(e),
                !this.ctx.getShaderParameter(e, this.ctx.COMPILE_STATUS))
            ) {
                var i = this.ctx.getShaderInfoLog(e);
                console.error('Shader compiler log: ' + i);
            }
            (this.ctx_shader = this.ctx.createProgram()),
                this.ctx.attachShader(this.ctx_shader, t),
                this.ctx.attachShader(this.ctx_shader, e),
                this.ctx.linkProgram(this.ctx_shader),
                this.ctx.useProgram(this.ctx_shader),
                (this.ctx_vx_ptr = this.ctx.getAttribLocation(this.ctx_shader, 'vx')),
                this.ctx.enableVertexAttribArray(this.ctx_vx_ptr),
                this.ctx.uniform1i(this.ctx.getUniformLocation(this.ctx_shader, 'sm'), 0),
                this.ctx.uniform1i(this.ctx.getUniformLocation(this.ctx_shader, 'sm2'), 1),
                (this.ctx_vx = this.ctx.createBuffer()),
                this.ctx.bindBuffer(this.ctx.ARRAY_BUFFER, this.ctx_vx),
                this.ctx.bufferData(
                    this.ctx.ARRAY_BUFFER,
                    new Float32Array([0, 0, 1, 0, 1, 1, 0, 1]),
                    this.ctx.STATIC_DRAW
                ),
                (this.ctx_ix = this.ctx.createBuffer()),
                this.ctx.bindBuffer(this.ctx.ELEMENT_ARRAY_BUFFER, this.ctx_ix),
                this.ctx.bufferData(
                    this.ctx.ELEMENT_ARRAY_BUFFER,
                    new Uint16Array([0, 1, 2, 0, 2, 3]),
                    this.ctx.STATIC_DRAW
                ),
                (this.ctx_tex1 = this.ctx.createTexture()),
                (this.ctx_tex2 = this.ctx.createTexture()),
                this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.ctx_tex1),
                this.ctx.texParameteri(
                    this.ctx.TEXTURE_2D,
                    this.ctx.TEXTURE_WRAP_T,
                    this.ctx.CLAMP_TO_EDGE
                ),
                this.ctx.texParameteri(
                    this.ctx.TEXTURE_2D,
                    this.ctx.TEXTURE_WRAP_S,
                    this.ctx.CLAMP_TO_EDGE
                ),
                this.ctx.texParameteri(
                    this.ctx.TEXTURE_2D,
                    this.ctx.TEXTURE_MAG_FILTER,
                    this.ctx.LINEAR
                ),
                this.ctx.texParameteri(
                    this.ctx.TEXTURE_2D,
                    this.ctx.TEXTURE_MIN_FILTER,
                    this.ctx.LINEAR
                ),
                this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.ctx_tex2),
                this.ctx.texParameteri(
                    this.ctx.TEXTURE_2D,
                    this.ctx.TEXTURE_WRAP_T,
                    this.ctx.CLAMP_TO_EDGE
                ),
                this.ctx.texParameteri(
                    this.ctx.TEXTURE_2D,
                    this.ctx.TEXTURE_WRAP_S,
                    this.ctx.CLAMP_TO_EDGE
                ),
                this.ctx.texParameteri(
                    this.ctx.TEXTURE_2D,
                    this.ctx.TEXTURE_MAG_FILTER,
                    this.ctx.LINEAR
                ),
                this.ctx.texParameteri(
                    this.ctx.TEXTURE_2D,
                    this.ctx.TEXTURE_MIN_FILTER,
                    this.ctx.LINEAR
                ),
                (this.canvas_2d_1 = null),
                (this.canvas_2d_2 = null),
                (this.canvas_2d_1 = document.createElement('canvas')),
                (this.canvas_2d_1.width = this.outputCanvas.width),
                (this.canvas_2d_1.height = this.outputCanvas.height),
                (this.context_2d_1 = this.canvas_2d_1.getContext('2d')),
                (this.canvas_2d_2 = document.createElement('canvas')),
                (this.canvas_2d_2.width = this.outputCanvas.width),
                (this.canvas_2d_2.height = this.outputCanvas.height),
                (this.context_2d_2 = this.canvas_2d_2.getContext('2d'));



               

              

        }
    };

    drawInstant = (color, opacity) => {       
        this.clearCanvas();
        var e = this;
        if ((this.ctx.clear(this.ctx.COLOR_BUFFER_BIT), this.outputCanvas)) {
            // var i = this.outputCanvas.width / 512,
            //     o = this.outputCanvas.height / 512,
            //     r = 90 === 0 || 270 === 0;

            var i = 1,
                o = 1,
                r = 90 === 0 || 270 === 0;

            var l = this.outputCanvas.getBoundingClientRect();
            if (
                (this.context_2d_1.clearRect(0, 0, this.canvas_2d_1.width, this.canvas_2d_1.height),
                this.context_2d_2.clearRect(0, 0, this.canvas_2d_2.width, this.canvas_2d_2.height),
                this.primaryChild)
            ) {
                var a = ReactDOM.findDOMNode(this.primaryChild);
                var n = a.getBoundingClientRect();
                if (a) {
                    // var s = a.querySelectorAll('img.autocomparable');
                    // var image = s[0];
                    // e.context_2d_1.save();
                    // e.context_2d_1.translate(
                    //     (n.left - l.left + n.width / 2) * i,
                    //     (n.top - l.top + n.height / 2) * o
                    // ),
                    // e.context_2d_1.drawImage(
                    //     image,
                    //     n.width * i,
                    //     n.height * o
                    // ),
                    // e.context_2d_1.restore();
                }
                for (
                    var s = a.querySelectorAll('autocomparable'),
                        p = function (t) {
                            
                            if (
                                // (a.addEventListener('load', function t() {
                                //     a.removeEventListener('load', t), e.drawDebounced(!0);
                                // }),
                                // a.src)
                                true
                            ) {
                                var n = a.getBoundingClientRect();
                                // var dx = (-n.width / 2),
                                var dx = 0,
                                    dy = 0,
                                    dw = n.width,
                                    dh = n.height;

                                e.context_2d_1.save(),
                                    // e.context_2d_1.translate(
                                    //     (n.left - l.left + n.width / 2) * i,
                                    //     (n.top - l.top + n.height / 2) * o
                                    // ),

                                    e.context_2d_1.drawImage(a, dx, dy, dw, dh),                                    
                                    // e.context_2d_1.fillStyle = "blue";
                                    // e.context_2d_1.fillRect(dx, dy, dw, dh);
                                    e.context_2d_1.restore();
                            }
                        },
                        h = 0;
                    h < 1;
                    h++
                )
                    p(h);
            }
            if (this.secondaryChild) {
                var u = 90 === 0 || 270 === 0,
                    c = ReactDOM.findDOMNode(this.secondaryChild);
                if (c) {
                    var d = c.querySelectorAll('autocomparable'),
                        f = c.getBoundingClientRect(),
                        g = function (t) {
                            var r = c;
                            if (
                                // (r.addEventListener('load', function t() {
                                //     r.removeEventListener('load', t), e.drawDebounced(!0);
                                // }),
                                // r.src)
                                true
                            ) {
                                var a = r.getBoundingClientRect();

                                e.context_2d_2.save(),                                                                        
                                e.context_2d_2.drawImage(
                                        r,
                                        0,
                                        0,
                                        f.width,
                                        f.height
                                        
                                    ),
                                    e.context_2d_2.restore();
                            }
                        };
                    for (h = 0; h < 1; h++) g(h);
                    // e.context_2d_2.save();

                    // e.context_2d_2.drawImage(image, 0, 0);
                    // e.context_2d_2.restore();
                }
            }
            
            this.ctx.uniform1f(
                this.ctx.getUniformLocation(this.ctx_shader, 'threshold'),
                this.threshold
            ),
                this.ctx.uniform1f(
                    this.ctx.getUniformLocation(this.ctx_shader, 'contrast'),
                    this.contrast
                ),
                this.ctx.uniform4f(
                    this.ctx.getUniformLocation(this.ctx_shader, 'highlightColor'),
                    ((16711680 & color) >> 16) / 255,
                    ((65280 & color) >> 8) / 255,
                    (255 & color) / 255,
                    opacity
                ),
                this.ctx.clear(this.ctx.COLOR_BUFFER_BIT),
                this.ctx.activeTexture(this.ctx.TEXTURE0),
                this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.ctx_tex1),
                this.ctx.texImage2D(
                    this.ctx.TEXTURE_2D,
                    0,
                    this.ctx.RGBA,
                    this.ctx.RGBA,
                    this.ctx.UNSIGNED_BYTE,
                    this.canvas_2d_1
                ),
                this.ctx.activeTexture(this.ctx.TEXTURE1),
                this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.ctx_tex2),
                this.ctx.texImage2D(
                    this.ctx.TEXTURE_2D,
                    0,
                    this.ctx.RGBA,
                    this.ctx.RGBA,
                    this.ctx.UNSIGNED_BYTE,
                    this.canvas_2d_2
                ),
                this.ctx.bindBuffer(this.ctx.ARRAY_BUFFER, this.ctx_vx),
                this.ctx.vertexAttribPointer(this.ctx_vx_ptr, 2, this.ctx.FLOAT, !1, 0, 0),
                this.ctx.bindBuffer(this.ctx.ELEMENT_ARRAY_BUFFER, this.ctx_ix),
                this.ctx.drawElements(this.ctx.TRIANGLES, 6, this.ctx.UNSIGNED_SHORT, 0);
        }
    };

    startCompare = (color, opacity) => {
        let primaryChildId = this.props.compareSide === 'right'? 'rightPlayer' : 'leftPlayer';
        let secondaryChildId = this.props.compareSide === 'right'? 'leftPlayer' : 'rightPlayer';

        this.outputCanvas = document.getElementById('compare-output-canvas');
        this.primaryChild = document.getElementById(primaryChildId);
        this.secondaryChild = document.getElementById(secondaryChildId);

        this.regenerateCanvas();
        this.drawInstant(color, opacity);
    };

    onDocumentViewerClicked = (index) => {
        console.log('DocumentCompareViewer Clicked at' + index);
        if (
            this.props.proofVersionA.pages[index] !== undefined &&
            this.props.proofVersionB.pages[index] !== undefined
        ) {
            this.props.setCompareIndex(index);
        } else {
            console.log('Not equivalent page to select');
        }
    };

    leftAreaOnScrolled(e) {
        var element = e.currentTarget;
        console.log('on scrolled ', element.id);
        if (!this.isSyncingLeftScroll) {
            this.isSyncingRightScroll = true;
            this.right.scrollTop = element.scrollTop;
        }
        this.isSyncingLeftScroll = false;
    }

    rightAreaOnScrolled(e) {
        var element = e.currentTarget;
        this.props.translateOnScroll(e);
        console.log('on scrolled ', element.id);
        if (!this.isSyncingRightScroll) {
            this.isSyncingLeftScroll = true;
            this.left.scrollTop = element.scrollTop;
        }
        this.isSyncingRightScroll = false;
    }

    render() {
        const { resizeCounter } = this.state;
        let heightStyle = {};
        if (this.props.alert?.isHideTopbar) {
            heightStyle = {
                top: 0,
                height: "calc(100vh - 0px)"
            };
        }
        return (
           
            <div  className="mrnda-video-compare">
            {/* <div style={heightStyle} className="mrnda-video-compare__container"> */}
            <div style={heightStyle} className="mrnda-video-compare__container">
                <VideoCompareToolbar/>
                <div
                    style={{
                        display: 'flex',                        
                        height: '100%',
                        overflow: 'hidden',                                                                       
                    }}>
                    <div 
                    id= "left"
                    style={{
                       
                        borderRight: '1px solid var(--control-border-color)',                                                
                    }}
                    className="mrnda-video-compare__player-area"
                    onClick={()=> {this.props.setFocusedSide('left')}}
                         >
                        <ProofVideoPlayerHOC
                            playerSide ="left"
                            compareClassName="autocomparable"
                            version="A"
                            resizeCounter={resizeCounter}
                            proofVersion={this.props.proofVersionA}
                            setVideoPlayer={(videoPlayer) => {
                                this.props.setVideoPlayerA(videoPlayer);
                            }}
                            setVideoCanvas={() => {}}
                            seekToTime={(frame) => this.props.seekToTimeFromA(frame)}
                            timeMode={this.props.timeMode}
                            onTimeModeChange={this.props.setTimeMode}
                            overrideTheme = {true}
                            canvasOpacity={1}
                            isAutoCompare={this.props.isAutoCompare}
                            isFocused = {this.props.focusedSide === 'left'}                            
                        />
                    </div>
                    <div style={{
                       
                       marginRight: '1px',                                                
                   }} id="right" className="mrnda-video-compare__player-area"
                   onClick={()=> {this.props.setFocusedSide('right')}}
                         >
                        <ProofVideoPlayerHOC
                            playerSide="right"
                            compareClassName="autocomparable"
                            version="B"                            
                            resizeCounter={resizeCounter}
                            proofVersion={this.props.proofVersionB}
                            setVideoPlayer={(videoPlayer) => {
                                this.props.setVideoPlayerB(videoPlayer);
                            }}
                            setVideoCanvas={(videoCanvas) => {
                                this.props.setCompareCanvas(videoCanvas);
                            }}
                            seekToTime={(frame) => this.props.seekToTimeFromB(frame)}
                            timeMode={this.props.timeMode}
                            onTimeModeChange={this.props.setTimeMode}
                            canvasOpacity={1}
                            overrideTheme = {true}
                            isAutoCompare={this.props.isAutoCompare}
                            isFocused = {this.props.focusedSide === 'right'}                            
                        />
                    </div>
                </div>
            </div>
        </div>
     
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

// ProofVideoEditorWrapper.contextType = VideoAnnotationContext;
const ProofDocumentCompareWrapperHOC = connect(mapStateToProps)(
    withVideoCompareConsumer(ProofDocumentCompareWrapper)
);
//const ProofVideoPlayerHOC = withVideoCompareConsumer(ProofVideoPlayer);

const ProofVideoPlayerHOC = withVideoCompareConsumer(CompareVideoPlayer);

export { ProofVideoCompare, ProofDocumentCompareWrapperHOC as ProofDocumentCompareWrapper };
