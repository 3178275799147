import React from 'react';
import { FooterLoadingElipsis } from './Nav';
import { connect } from 'react-redux';

function Footer(props) {
    
    return (
        <div id="FooterContainer" className="navbar">
            <div
                style={{
                    float: 'right'
                }}>
                

                {props.alert.isLoading && <FooterLoadingElipsis />}
                <a
                    target="_blank"
                    href="#"
                    className="_s_AboutMarcombox"
                    style={{
                        paddingRight: '10px',
                        color: 'lightgrey',
                        fontWeight: 'normal'
                    }}></a>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert       
    };
}

const FooterComponent = connect(mapStateToProps)(Footer);
export { FooterComponent };
