import { useEffect, useState, useCallback } from 'react';
import useStateRef from 'react-usestateref';

function usePanning(view, container, isPanning) {
    const [panningEntered, setPanningEntered] = useState(false);
    const [panningStartCoords, setPanningStartCoords, panningStartCoordsRef] = useStateRef({
        x: 0,
        y: 0
    });
    const [coords, setCoords] = useState({ x: 0, y: 0 });
    const [moveMentCoords, setMoveMentCoords] = useState({ x: 0, y: 0 });
    const [panningStartAreaCoords, setPanningStartAreaCoords, panningStartAreaCoordsRef] =
        useStateRef({ x: 0, y: 0 });

    useEffect(() => {
        if (isPanning === true) {
            if (view === null || container === null) {
                return;
            }

            view.classList.add('cursor-for-hand-tool-selected');
            view.addEventListener('mousedown', mouseDownHandler);
            view.addEventListener('mouseup', mouseUpHandler);
        } else if (isPanning === false) {
            console.log();
            if (view === null || container === null) {
                return;
            }
            view.classList.remove('cursor-for-hand-tool-selected');
            view.removeEventListener('mousedown', mouseDownHandler);
            view.removeEventListener('mouseup', mouseUpHandler);
        }
    }, [isPanning]);

    useEffect(() => {
        if (panningEntered === true) {
            if (view === null || container === null) {
                return;
            }
            view.classList.add('cursor-for-hand-tool-dragging');
            view.addEventListener('mousemove', mouseMoveHandler);
            view.addEventListener('mouseout', mouseExitHandler);

            
        } else if (panningEntered === false) {
            console.log();
            if (view === null || container === null) {
                return;
            }
            view.classList.remove('cursor-for-hand-tool-dragging');
            view.removeEventListener('mousemove', mouseMoveHandler);
            view.addEventListener('mouseout', mouseExitHandler);

        }
    }, [panningEntered]);

    const mouseDownHandler = useCallback(
        (event) => {
            setPanningEntered(true);
            let current = {
                x: event.clientX - event.target.offsetLeft,
                y: event.clientY - event.target.offsetTop
            };

            resetDraggingValues(container, current)
        },
        [container]
    );

    const mouseUpHandler = useCallback(() => {
        setPanningEntered(false);
        setPanningStartCoords({ x: 0, y: 0 });
    }, [container]);
    const mouseExitHandler = useCallback(() => {
        setPanningEntered(false);
        setPanningStartCoords({ x: 0, y: 0 });
    }, [container]);

    const mouseMoveHandler = useCallback(
        (event) => {            
            var current = {
                x: event.clientX - event.target.offsetLeft,
                y: event.clientY - event.target.offsetTop
            };

            setCoords(current);
            var move = {
                x: panningStartCoordsRef.current.x - current.x,
                y: panningStartCoordsRef.current.y - current.y
            };
            setMoveMentCoords(move);

            dragY(container, move, current);
            dragX(container, move, current);
        },
        [container]
    );

    const dragY = (container, move, current) => {
        if (move.y < 0) {
            if (container.scrollTop > 0) {
                var newTop = move.y;
                container.scrollTop = panningStartAreaCoordsRef.current.y + newTop;
                console.log('Dragging up');
            }
            else {
                resetDraggingValues(container, current);
            }
        } else if (move.y > 1) {
            if (container.offsetHeight + container.scrollTop < container.scrollHeight) {
                var newTop = move.y;
                container.scrollTop = panningStartAreaCoordsRef.current.y + newTop;
                console.log('Dragging Down');
            } else {
                resetDraggingValues(container, current);
            }
        }



    };


    const resetDraggingValues = (container, current) => {

        setPanningStartCoords({
                x: current.x,
                y: current.y
            });
        setPanningStartAreaCoords({ x: container.scrollLeft, y: container.scrollTop });

    } 

    const dragX = (container, move, current) => {
        if (move.x < 0) {
            if (container.scrollLeft > 0) {
                var newLeft = move.x;
                container.scrollLeft = panningStartAreaCoordsRef.current.x + newLeft;
                console.log('Dragging left');
            }
            else {
                resetDraggingValues(container, current);
            }
        } else if (move.x > 1) {
            if (container.offsetWidth + container.scrollLeft < container.scrollWidth) {
                var newLeft = move.x;
                container.scrollLeft = panningStartAreaCoordsRef.current.x + newLeft;
                console.log('Dragging right');
            } else {
                resetDraggingValues(container, current);

            }
        }
    };

    return {
        panningEntered,
        panningStartCoords,
        coords,
        moveMentCoords
    };
}

export { usePanning };
