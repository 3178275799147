//@ts-check
import React, { useState, useEffect, useRef } from 'react';
import { tenantService } from '../Tenant';
import { userService } from '../User/user.service';
import { store } from '../../_helpers';
import { alertActions } from '../App';
import { MultiSelect } from '../../components';
import { useLiveUpdate } from '../App/LiveUpdate/LiveUpdateContext';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import { groupService, roleService } from '../User';
import { connect } from 'react-redux';
import { bool } from 'prop-types';
import { DataLoadingIndicator } from '../_shared/DataFetcher/DataLoadingIndicator';
import { DataFetcher } from '../_shared/DataFetcher/DataFetcher';


const initialValues = {
    authURL: {
        value: 'https://[brandsharedam_app_site_url]/AuthProvider/AuthMarcomboxAccount',
        validated: true,
        message: 'Auth Endpoint is not a valid URL'
    },
    forgotPasswordURL: {
        value: 'https://[brandsharedam_app_site_url]/AuthProvider/ForgetPasswordMarcomboxAccount',
        validated: true,
        message: 'Forgot Pass Endpoint is not a valid URL'
    },
    syncUsersURL: {
        value: 'https://[brandsharedam_app_site_url]/AuthProvider/GetMarcomboxUsers',
        validated: true,
        message: 'User Sync Endpoint is not a valid URL'
    },
    apiKey: {
        value: '',
        validated: true,
        message: "API Key can't be empty"
    },
    roleId: {
        value: '',
        validated: true,
        message: 'Must assign a role',
        selected: {}
    },
    groups: {
        value: [],
        selected: [],
        validated: true,
        message: ''
    },
    enabled: {
        value: false,
        validated: true,
        message: ''
    },
    submitted: false,
    canSave: true
};

const oktaInitialValues = {
    idPMetadata: {
        value: 'IdpMetadata (url)',
        validated: true,
        message: 'IdPMetadata is not a valid'
    },
    issuer: {
        value: 'Issuer (text)',
        validated: true,
        message: 'Issuer is not a valid'
    },
    isEncrypted: {
        value: false,
        validated: true,
        message: ''
    },
    enabled : {
        value: false,
        validated: true,
        message: ''
    },
    oktaSubmitted: false,
    oktaCanSave: true
};

const LOGIN_PROVIDER_LABEL  = {
    miranda : 'Brandshare Collaboration',
    marcombox : 'Brandshare DAM',
    octa: 'Okta Gateway'
}

const LoginSettingManage = ({ onClose }) => {
    const { loading, success } = alertActions;
    const { dispatch } = store;
    const [marcomboxLogin, setMarcomboxLogin] = useState(initialValues);
    const [showMaromboxLoginModal, setShowMaromboxLoginModal] = useState(false);
    const [showSyncUserModal, setShowSyncUserModal] = useState(false);
    const [groups, setGroups] = useState([]);
    const [roles, setRoles] = useState([]);
    const [currentTenant, setCurrentTenant] = useState({ id: '' });
    const [syncUserState, setSyncUserState] = useState({
        started: true,
        errorMessage: ''
    });
    const [mirandaLogin, setMirandaLogin] = useState(initialValues);
    const [oktaGatewayLogin, setOktaGatewayLogin] = useState(initialValues);

    const [oktaLogin, setOktaLogin] = useState(oktaInitialValues);
    const [showOktaLoginModal, setShowOktaLoginModal] = useState(false);

    const handleChange = (event) => {
        var { name, value, checked } = event.target;

        if (name === 'enabled') {
            value = checked;
        }

        let updated = { ...marcomboxLogin, submitted: false, canSave: true };
        updated[name].value = value;
        updated[name].validated = true;
        setMarcomboxLogin(updated);
    };

    const handleChangeOkta = (event) => {
        var { name, value, checked } = event.target;
        if (!(name === 'okta')){
            if (name === 'oktaEnabled' || name === 'isEncrypted') {
                value = checked;
            }

            if (name === 'oktaEnabled') {
                name = 'enabled';
            }

            let updated = { ...oktaLogin, oktaSubmitted: false, oktaCanSave: true };
            updated[name].value = value;
            updated[name].validated = true;
            setOktaLogin(updated);
        }
    };
    
    const onChangeGroupSelect = async (selectedOptions, addedGroupIds, removedGroupIds) => {
        var selectedGroupIds = selectedOptions
            ? selectedOptions.map((item) => {
                  var newItem = { ...item };
                  return newItem.id;
              })
            : [];
        var newGroupObject = {
            ...marcomboxLogin,
            groups: {
                ...marcomboxLogin.groups,
                value: selectedGroupIds,
                selected: selectedOptions || [],
                validated: true
            },
            submitted: false
        };

        setMarcomboxLogin(newGroupObject);
    };
    const onChangeRoleSelect = async (selectedOption) => {
        var selectedRoleId = selectedOption ? selectedOption.id : null;
        var newGroupObject = {
            ...marcomboxLogin,
            roleId: {
                ...marcomboxLogin.roleId,
                value: selectedRoleId,
                selected: selectedOption,
                validated: true
            },
            submitted: false
        };
        setMarcomboxLogin(newGroupObject);
    };

    const fetchData = async () => {
        dispatch(loading(true));
        let currentTenant = await tenantService.getCurrentTenantData();
        setCurrentTenant(currentTenant);
        let marcomboxLoginSettings = currentTenant?.settingsLogin?.marcomboxLogin || {};
        let mirandaLoginSettings = currentTenant?.settingsLogin?.mirandaLogin || {};
        let oktaGatewayLoginSettings = currentTenant?.settingsLogin?.oktaGatewayLogin || {};
        let oktaLoginSettings = currentTenant?.settingsLogin?.oktaLogin || {};

        const groupParams = {
            page: 0,
            pageSize: 2147483647,
            proofSortModel: {
                sortDirection: 1,
                sortColumn: 'name'
            },
            proofFilters: []
        };
        let { list: groupsData } = await groupService.getAll(groupParams);

        groupsData = groupsData.map((item) => {
            var newItem = { ...item };
            newItem.value = item.id;
            newItem.label = item.name;
            return newItem;
        });
        const rowsExistingGroups =
            marcomboxLoginSettings?.groups?.length > 0
                ? groupsData.filter((item) => marcomboxLoginSettings.groups.includes(item.id))
                : [];

        let { list: rolesData } = await roleService.getAll(groupParams);
        rolesData = rolesData.map((item) => {
            var newItem = { ...item };
            newItem.value = item.id;
            newItem.label = item.name;
            return newItem;
        });
        const rowsExitingRole = marcomboxLoginSettings?.roleId
            ? rolesData.filter((item) => marcomboxLoginSettings.roleId == item.id)
            : [];

        setGroups(groupsData);
        setRoles(rolesData);
        let marcomboxLoginUpdated = { ...marcomboxLogin, submitted: false, canSave: true };
        marcomboxLoginUpdated.authURL.value = marcomboxLoginSettings.authURL || initialValues.authURL.value;
        marcomboxLoginUpdated.forgotPasswordURL.value =
            marcomboxLoginSettings.forgotPasswordURL || initialValues.forgotPasswordURL.value;
        marcomboxLoginUpdated.syncUsersURL.value = marcomboxLoginSettings.syncUsersURL || initialValues.syncUsersURL.value;
        marcomboxLoginUpdated.apiKey.value = marcomboxLoginSettings.apiKey || '';
        marcomboxLoginUpdated.roleId.value = marcomboxLoginSettings.roleId || '';
        marcomboxLoginUpdated.roleId.selected = rowsExitingRole || {};
        marcomboxLoginUpdated.groups.value = marcomboxLoginSettings.groups || [];
        marcomboxLoginUpdated.groups.selected = rowsExistingGroups || [];
        marcomboxLoginUpdated.enabled.value = !!marcomboxLoginSettings.enabled;
        marcomboxLoginUpdated.submitted = false;
        setMarcomboxLogin(marcomboxLoginUpdated);
        //dispatch(loading(false));

        setMirandaLogin(mirandaLoginSettings);
        setOktaGatewayLogin(oktaGatewayLoginSettings);

        let oktaLoginUpdated = { ...oktaLogin, oktaSubmitted: false, oktaCanSave: true };
        oktaLoginUpdated.idPMetadata.value = oktaLoginSettings.idPMetadata || oktaInitialValues.idPMetadata.value;
        oktaLoginUpdated.issuer.value = oktaLoginSettings.issuer || oktaInitialValues.issuer.value;
            oktaLoginUpdated.isEncrypted.value = oktaLoginSettings.isEncrypted;
            oktaLoginUpdated.enabled.value = !!oktaLoginSettings.enabled;
            oktaLoginUpdated.oktaSubmitted = false;
        setOktaLogin(oktaLoginUpdated);
        dispatch(loading(false));
    };

    const validateFields = async () => {
        var globalValidation = true;
        var validationCopy = { ...marcomboxLogin };
        var { authURL, forgotPasswordURL, syncUsersURL, apiKey, roleId } = marcomboxLogin;
        let websiteURLValidated =
            tenantService.validateWebsiteURL(authURL.value) && authURL.value.length < 600;
        if (!websiteURLValidated) {
            validationCopy.authURL = {
                ...authURL,
                validated: false
            };
            globalValidation = false;
        }
        let forgotPassURLValidated =
            tenantService.validateWebsiteURL(forgotPasswordURL.value) &&
            forgotPasswordURL.value.length < 600;
        if (!forgotPassURLValidated) {
            validationCopy.forgotPasswordURL = {
                ...forgotPasswordURL,
                validated: false
            };
            globalValidation = false;
        }

        let syncUserURLValidated =
            tenantService.validateWebsiteURL(syncUsersURL.value) && syncUsersURL.value.length < 600;
        if (!syncUserURLValidated) {
            validationCopy.syncUsersURL = {
                ...syncUsersURL,
                validated: false
            };
            globalValidation = false;
        }

        if (!apiKey.value) {
            validationCopy.apiKey = {
                ...apiKey,
                validated: false
            };
            globalValidation = false;
        }

        if (!roleId.value) {
            validationCopy.roleId = {
                ...roleId,
                validated: false
            };
            globalValidation = false;
        }
        setMarcomboxLogin({
            ...validationCopy,
            submitted: true,
            canSave: false
        });
        return globalValidation;
    };

    const oktaValidateFields = async () => {
        var oktaGlobalValidation = true;
        var oktaValidationCopy = { ...oktaLogin };
        var { idPMetadata, issuer, isEncrypted, enabled } = oktaLogin;
        if(idPMetadata.value.length == 0){
            oktaValidationCopy.idPMetadata = {
                ...idPMetadata,
                validated: false
            };
            oktaGlobalValidation = false;
        }

        if(issuer.value.length == 0){
            oktaValidationCopy.issuer = {
                ...issuer,
                validated: false
            };
            oktaGlobalValidation = false;
        }
        setOktaLogin({
            ...oktaValidationCopy,
            oktaSubmitted: true,
            oktaCanSave: false
        });
        return oktaGlobalValidation;
    };

    const onUpdateMarcomboxLoginSettings = async (event) => {
        event.preventDefault();
        dispatch(loading(true));
        var allValidated = await validateFields();
        if (!allValidated) {
            dispatch(loading(false));
            return;
        }
        let payload = {
            authURL: marcomboxLogin.authURL.value || '',
            forgotPasswordURL: marcomboxLogin.forgotPasswordURL.value || '',
            syncUsersURL: marcomboxLogin.syncUsersURL.value || '',
            apiKey: marcomboxLogin.apiKey.value || '',
            roleId: marcomboxLogin.roleId.value || '',
            groups: marcomboxLogin.groups.value || [],
            enabled: !!marcomboxLogin.enabled.value
        };
        await tenantService.updateMarcomboxLoginSettings(currentTenant.id, payload);
        dispatch(success('Saved'));
        dispatch(loading(false));
        await fetchData();
        setShowMaromboxLoginModal(false);
    };

    const onUpdateMirandaLoginSettings = async (event) => {
        //event.preventDefault();
        dispatch(loading(true));
        let payload = {
            enabled: !(mirandaLogin.enabled?true:false)
        };
        await tenantService.updateMirandaLoginSettings(currentTenant.id, payload);
        dispatch(success('Saved'));
        dispatch(loading(false));
        await fetchData();
    };

    const onUpdateOktaGatewayLoginSettings = async (event) => {
        //event.preventDefault();
        dispatch(loading(true));
        let payload = {
            enabled: !oktaGatewayLogin.enabled
        };
        await tenantService.updateOktaGatewayLoginSettings(currentTenant.id, payload);
        dispatch(success('Saved'));
        dispatch(loading(false));
        await fetchData();
    };

    const onUpdateOktaLoginSettings = async (event) => {
        event.preventDefault();
        dispatch(loading(true));

        var allOktaValidated = await oktaValidateFields();
        if (!allOktaValidated) {
            dispatch(loading(false));
            return;
        }

        let payload = {
            idPMetadata: oktaLogin.idPMetadata.value || '',
            issuer: oktaLogin.issuer.value || '',
            isEncrypted: !!oktaLogin.isEncrypted.value,
            enabled: !!oktaLogin.enabled.value
        };
        await tenantService.updateOktaLoginSettings(currentTenant.id, payload);
        dispatch(success('Saved'));
        dispatch(loading(false));
        await fetchData();
        setShowOktaLoginModal(false);
    };

    const {
        authURL: { value: authURL, validated: authURLValidated, message: authURLMessage },
        forgotPasswordURL: {
            value: forgotPasswordURL,
            validated: forgotPasswordURLValidated,
            message: forgotPasswordURLMessage
        },
        syncUsersURL: {
            value: syncUsersURL,
            validated: syncUsersURLValidated,
            message: syncUsersURLMessage
        },
        apiKey: { value: apiKey, validated: apiKeyValidated, message: apiKeyMessage },
        roleId: {
            value: roleId,
            selected: selectedRole,
            validated: roleIdValidated,
            message: roleIdMessage
        },
        groups: { value, selected: selectedGroups },
        enabled: { value: enabled },
        submitted,
        canSave
    } = marcomboxLogin;
    console.log(marcomboxLogin);

    const {
        idPMetadata: { value: idPMetadata, validated: idPMetadataValidated, message: idPMetadataMessage },
        issuer: { value: issuer, validated: issuerValidated, message: issuerMessage },
        isEncrypted: { value: isEncrypted },
        enabled: { value: oktaEnabled },
        oktaSubmitted,
        oktaCanSave
    } = oktaLogin;
    console.log(oktaLogin);
    
    return (
        <>
            <div className="settings-view__manage__container">
                <div className="m-2" style={{}}>
                    <div className="settings-view-modal">
                        <div style={{ minHeight: '80%', fontSize: '13px' }}>
                            <button
                                id="editor-close-btn"
                                type="button"
                                className="close modal-close"
                                title="Close Modal"
                                onClick={onClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h3 className="">Manage Login Settings</h3>
                            <hr></hr>
                           
                            <DataFetcher onFetchData={fetchData} height={'476px'}>
                                <div className="form-group d-flex flex-column">
                                    <label className="typography--h2 mb-4" htmlFor="link">
                                        Login Providers
                                    </label>
                                    <div
                                        style={{
                                            marginLeft: '4px',
                                            marginBottom: '15px',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                        <div style={{ marginBottom: 8 }}>
                                            <label
                                                style={{ margin: '0 20px 0 0', fontSize: 16 }}
                                                className="check-container">
                                                <span className="text--small display--block role-permission-section__body__check-label">
                                                    <strong>{LOGIN_PROVIDER_LABEL.miranda}</strong>
                                                </span>
                                                <input
                                                    type="checkbox"
                                                    className="ml-2 mr-2"
                                                    data-toggle="toggle"
                                                    data-on="Enabled"
                                                    data-off="Disabled"
                                                    name={'miranda'}
                                                    value={'miranda'}
                                                    checked={mirandaLogin.enabled ? true : false}
                                                    readOnly
                                                    //onChange={(e) => {onUpdateMirandaLoginSettings}}
                                                    onClick={async (event) => {
                                                        onUpdateMirandaLoginSettings();
                                                    }}
                                                />
                                                <span className="check-container__checkmark">
                                                    <i className="fas fa-check" />
                                                </span>
                                            </label>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>
                                            <label
                                                style={{ margin: '0 20px 0 0', fontSize: 16 }}
                                                className="check-container">
                                                <span className="text--small display--block role-permission-section__body__check-label">
                                                    <strong>
                                                        {LOGIN_PROVIDER_LABEL.marcombox}
                                                    </strong>
                                                </span>
                                                <input
                                                    type="checkbox"
                                                    className="ml-2 mr-2"
                                                    data-toggle="toggle"
                                                    data-on="Enabled"
                                                    data-off="Disabled"
                                                    name={'enabled'}
                                                    value={'marcombox'}
                                                    checked={enabled}
                                                    onChange={async (event) => {
                                                        const { checked } = event.target;

                                                        handleChange(event);
                                                        setShowMaromboxLoginModal(true);
                                                    }}
                                                />
                                                <span className="check-container__checkmark">
                                                    <i className="fas fa-check" />
                                                </span>
                                            </label>
                                            <button
                                                style={{
                                                    fontSize: 10,
                                                    padding: '2px 8px',
                                                    height: 'auto',
                                                    marginTop: 2
                                                }}
                                                className="mrnda-btn--secondary btn-sm btn btn-primary"
                                                onClick={() => {
                                                    setShowMaromboxLoginModal(true);
                                                }}>
                                                <span className="">Configure</span>
                                            </button>
                                        </div>
                                        <div style={{ marginTop: 8 }}>
                                            <label
                                                style={{ margin: '0 20px 0 0', fontSize: 16 }}
                                                className="check-container">
                                                <span className="text--small display--block role-permission-section__body__check-label">
                                                    <strong>{LOGIN_PROVIDER_LABEL.octa}</strong>
                                                </span>
                                                <input
                                                    type="checkbox"
                                                    className="ml-2 mr-2"
                                                    data-toggle="toggle"
                                                    data-on="Enabled"
                                                    data-off="Disabled"
                                                    name={'oktagateway'}
                                                    value={'oktagateway'}
                                                    checked={
                                                        oktaGatewayLogin.enabled ? true : false
                                                    }
                                                    readOnly
                                                    onClick={async (event) => {
                                                        onUpdateOktaGatewayLoginSettings();
                                                    }}
                                                />
                                                <span className="check-container__checkmark">
                                                    <i className="fas fa-check" />
                                                </span>
                                            </label>
                                        </div>

                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginTop: 8
                                            }}>
                                            <label
                                                style={{ margin: '0 20px 0 0', fontSize: 16 }}
                                                className="check-container">
                                                <span className="text--small display--block role-permission-section__body__check-label">
                                                    <strong>{'Okta'}</strong>
                                                </span>
                                                <input
                                                    type="checkbox"
                                                    className="ml-2 mr-2"
                                                    data-toggle="toggle"
                                                    data-on="Enabled"
                                                    data-off="Disabled"
                                                    name={'okta'}
                                                    value={'enabled'}
                                                    checked={oktaEnabled}
                                                    onChange={async (event) => {
                                                        const { checked } = event.target;
                                                        handleChangeOkta(event);
                                                        setShowOktaLoginModal(true);
                                                    }}
                                                />
                                                <span className="check-container__checkmark">
                                                    <i className="fas fa-check" />
                                                </span>
                                            </label>
                                            <button
                                                style={{
                                                    fontSize: 10,
                                                    padding: '2px 8px',
                                                    height: 'auto',
                                                    marginTop: 2
                                                }}
                                                className="mrnda-btn--secondary btn-sm btn btn-primary"
                                                onClick={() => {
                                                    setShowOktaLoginModal(true);
                                                }}>
                                                <span className="">Configure</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </DataFetcher>
                        
                        </div>
                    </div>
                </div>
            </div>
            {showMaromboxLoginModal && (
                <Modal
                    show={showMaromboxLoginModal}
                    onHide={() => {
                        setShowMaromboxLoginModal(false);
                    }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop={'static'}>
                    <Modal.Header closeButton={false}>
                        <h5>{`Configure ${LOGIN_PROVIDER_LABEL.marcombox} Login`}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <form
                                name="form"
                                onSubmit={onUpdateMarcomboxLoginSettings}
                                autoComplete="nope">
                                <div className={'form-group'}>
                                    <label htmlFor="authURL">Auth URL</label>
                                    <input
                                        type="text"
                                        className="form-control mrnda-input-text"
                                        name="authURL"
                                        value={authURL || ''}
                                        onChange={handleChange}
                                        // placeholder={initialValues.authURL.value}
                                        autoComplete="off"
                                    />
                                    {submitted && !authURLValidated && (
                                        <div className="help-block">{authURLMessage}</div>
                                    )}
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor="forgotPasswordURL">Forgot Passsword URL</label>
                                    <input
                                        type="text"
                                        className="form-control mrnda-input-text"
                                        name="forgotPasswordURL"
                                        value={forgotPasswordURL || ''}
                                        onChange={handleChange}
                                        // placeholder={initialValues.forgotPasswordURL.value}
                                        autoComplete="off"
                                    />
                                    {submitted && !forgotPasswordURLValidated && (
                                        <div className="help-block">{forgotPasswordURLMessage}</div>
                                    )}
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor="syncUsersURL">Users Sync URL</label>
                                    <input
                                        type="text"
                                        className="form-control mrnda-input-text"
                                        name="syncUsersURL"
                                        value={syncUsersURL || ''}
                                        onChange={handleChange}
                                        // placeholder={initialValues.syncUsersURL.value}
                                        autoComplete="off"
                                    />
                                    {submitted && !syncUsersURLValidated && (
                                        <div className="help-block">{syncUsersURLMessage}</div>
                                    )}
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor="lastName">API Key</label>
                                    <input
                                        type="text"
                                        className="form-control mrnda-input-text"
                                        name="apiKey"
                                        value={apiKey || ''}
                                        onChange={handleChange}
                                        placeholder={`${LOGIN_PROVIDER_LABEL.marcombox} API Key`}
                                        autoComplete="off"
                                    />
                                    {submitted && !apiKeyValidated && (
                                        <div className="help-block">{apiKeyMessage}</div>
                                    )}
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor="role">Select Role</label>

                                    <MultiSelect
                                        isMulti={false}
                                        options={roles}
                                        selectedOptions={selectedRole}
                                        onChange={onChangeRoleSelect}
                                        closeMenuOnSelect={true}
                                    />
                                    {submitted && !roleIdValidated && (
                                        <div className="help-block">{roleIdMessage}</div>
                                    )}
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor="groups">Select Groups</label>
                                    {true && (
                                        <MultiSelect
                                            isMulti={true}
                                            options={groups}
                                            selectedOptions={selectedGroups}
                                            onChange={onChangeGroupSelect}
                                            closeMenuOnSelect={true}
                                        />
                                    )}
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor="status">Status</label>
                                    <label className="check-container display--block ml-1">
                                        <span className="display--block role-permission-section__body__check-label">
                                            <strong>Enabled</strong>
                                        </span>
                                        <input
                                            type="checkbox"
                                            className="ml-2 mr-2"
                                            data-toggle="toggle"
                                            data-on="Enabled"
                                            data-off="Disabled"
                                            name="enabled"
                                            // value={enabled}
                                            checked={enabled}
                                            onChange={handleChange}
                                        />
                                        <span className="check-container__checkmark">
                                            <i className="fas fa-check" />
                                        </span>
                                    </label>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                        <Button
                            className="btn-sm mrnda-btn--secondary"
                            onClick={async () => {
                                var allValidated = await validateFields();
                                if (!allValidated) {
                                    return;
                                }
                                dispatch(loading(true));
                                setShowSyncUserModal(true);
                                let res = await tenantService.syncMarcomboxUsers();
                                if (!res.success) {
                                    setSyncUserState({
                                        ...syncUserState,
                                        started: false,
                                        errorMessage: res.errorReason
                                    });
                                    dispatch(alertActions.error('Something went wrong'));
                                } else {
                                    setShowSyncUserModal(false);
                                    dispatch(alertActions.success('Sync complete'));
                                }
                                dispatch(loading(false));
                            }}
                            style={{ minWidth: 60 }}>
                            {'Sync Users'}
                        </Button>
                        <div>
                            <Button
                                className="mrnda-btn btn-sm mr-2"
                                onClick={onUpdateMarcomboxLoginSettings}
                                style={{ minWidth: 60 }}
                                disabled={!canSave}>
                                {'Save'}
                            </Button>

                            <Button
                                className="btn-sm mrnda-btn--secondary"
                                onClick={async () => {
                                    await fetchData();
                                    setShowMaromboxLoginModal(false);
                                }}
                                style={{ minWidth: 60 }}>
                                {'Cancel'}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            )}
            {showSyncUserModal && (
                <Modal
                    show={showSyncUserModal}
                    onHide={() => {
                        setSyncUserState({
                            ...syncUserState,
                            started: true,
                            errorMessage: ''
                        });
                        setShowSyncUserModal(false);
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop={'static'}>
                    <Modal.Header closeButton={!syncUserState.started}>
                        <h5>{'Syncing Marcombox Users...'}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <ProgressBar animated now={100} />
                            {syncUserState.errorMessage && (
                            <div className="help-block">{syncUserState.errorMessage}</div>
                        )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        
                    </Modal.Footer>
                </Modal>
            )}

            {showOktaLoginModal && (
                <Modal
                    show={showOktaLoginModal}
                    onHide={() => {
                        setShowOktaLoginModal(false);
                    }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop={'static'}>
                    <Modal.Header closeButton={false}>
                        <h5>{'Configure Okta Login'}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <form
                                name="form"
                                onSubmit={onUpdateOktaLoginSettings}
                                autoComplete="nope">

                                <div className={'form-group'}>
                                    <label htmlFor="idPMetadata">IdPMetadata</label>
                                    <input
                                        type="text"
                                        className="form-control mrnda-input-text"
                                        name="idPMetadata"
                                        value={idPMetadata || ''}
                                        onChange={handleChangeOkta}
                                        autoComplete="off"
                                    />
                                    {oktaSubmitted && !idPMetadataValidated && (
                                        <div className="help-block">{idPMetadataMessage}</div>
                                    )}
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor="issuer">Issuer</label>
                                    <input
                                        type="text"
                                        className="form-control mrnda-input-text"
                                        name="issuer"
                                        value={issuer || ''}
                                        onChange={handleChangeOkta}
                                        autoComplete="off"
                                    />
                                    {oktaSubmitted && !issuerValidated && (
                                        <div className="help-block">{issuerMessage}</div>
                                    )}
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor="isEncrypted">Encrypted</label>
                                    <label className="check-container display--block ml-1">
                                        <span className="display--block role-permission-section__body__check-label">
                                            <strong>Encrypted</strong>
                                        </span>
                                        <input
                                            type="checkbox"
                                            className="ml-2 mr-2"
                                            data-toggle="toggle"
                                            data-on="Enabled"
                                            data-off="Disabled"
                                            name="isEncrypted"
                                            checked={isEncrypted}
                                            onChange={handleChangeOkta}
                                        />
                                        <span className="check-container__checkmark">
                                            <i className="fas fa-check" />
                                        </span>
                                    </label>
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor="status">Status</label>
                                    <label className="check-container display--block ml-1">
                                        <span className="display--block role-permission-section__body__check-label">
                                            <strong>Enabled</strong>
                                        </span>
                                        <input
                                            type="checkbox"
                                            className="ml-2 mr-2"
                                            data-toggle="toggle"
                                            data-on="Enabled"
                                            data-off="Disabled"
                                            name="oktaEnabled"
                                            checked={oktaEnabled}
                                            onChange={handleChangeOkta}
                                        />
                                        <span className="check-container__checkmark">
                                            <i className="fas fa-check" />
                                        </span>
                                    </label>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">                        
                        <div>
                            <Button
                                className="mrnda-btn btn-sm mr-2"
                                onClick={onUpdateOktaLoginSettings}
                                style={{ minWidth: 60 }}
                                disabled={!oktaCanSave}>
                                {'Save'}
                            </Button>

                            <Button
                                className="btn-sm mrnda-btn--secondary"
                                onClick={async () => {
                                    await fetchData();
                                    setShowOktaLoginModal(false);
                                }}
                                style={{ minWidth: 60 }}>
                                {'Cancel'}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            )}


        </>
    );
};

const connected = connect(
    (state) => {
        return {};
    },
    (dispatch) => {
        return {};
    }
)(LoginSettingManage);
export { connected as LoginSettingManage };
