//@ts-check
import React, { useEffect } from 'react';
import { tenantService } from '../../Tenant';
import { store } from '../../../_helpers';
import { alertActions } from '../../App';
import { ConfirmationModal } from '../..';
import { DataFetcher } from '../../_shared/DataFetcher/DataFetcher';

import StatusList from './StatusList';
import StatusHeader from './StatusHeader';
import {
    MAX_LIMIT_TITLE,
    MIN_VISIBLE_BODY,
    MIN_VISIBLE_COUNT,
    MIN_VISIBLE_TITLE,
    StatusType,
    maxLimitBody
} from './helpers/statusConstants';
import { withStatusManageConsumer } from './Context/StatusManageProvider';
import StatusAdd from './StatusAdd';
import { statusUtil } from './helpers/statusUtil';
import WorkflowAdd from './WorkFlowAdd';
import WorkflowManage from './WorkflowManage';

export const StatusManage = ({
    editingComment,
    setEditingComment,
    visibleCommentStatuses,
    setVisibleCommentStatuses,
    hiddenCommentStatuses,
    setHiddenCommentStatuses,
    infoModalData,
    setInfoModalData,
    onClose,
    maxStatusLimit,
    setMaxStatusLimit,
    statusType,
    fetchStatusData,
    updateStatusPriority,
    updateStatus
}) => {
    const removeStatusItem = (status, statusList) => {
        var removed = statusList.filter((x) => x.id !== status.id);
        return removed;
    };

    const addStatusItem = (status, statusList) => {
        var added = [...statusList, status];
        return added;
    };

    const uncheckItem = (newCheck, commentStatus) => {
        let item = visibleCommentStatuses.find((x) => x.id === commentStatus.id);
        let itemCopy = { ...item };
        itemCopy.isVisible = newCheck;
        itemCopy.priority = -1;
        let removed = removeStatusItem(itemCopy, [...visibleCommentStatuses]);
        setVisibleCommentStatuses(removed);

        let added = addStatusItem(itemCopy, [...hiddenCommentStatuses]);

        setHiddenCommentStatuses(statusUtil.sortItemsAlphabetically(added));
        return { status: itemCopy, visibleList : removed, checked : newCheck};
    };

    const checkItem = (newCheck, commentStatus) => {
        console.log('Slot available, showing the status');

        let item = hiddenCommentStatuses.find((x) => x.id === commentStatus.id);
        let itemCopy = { ...item };
        itemCopy.priority = visibleCommentStatuses.length + 1;
        itemCopy.isVisible = newCheck;
        itemCopy;
        var removed = removeStatusItem(itemCopy, [...hiddenCommentStatuses]);
        setHiddenCommentStatuses(statusUtil.sortItemsAlphabetically(removed));

        var added = addStatusItem(itemCopy, [...visibleCommentStatuses]);
        setVisibleCommentStatuses(added);
        return { status: itemCopy, visibleList : added, checked : newCheck };
    };

    const onCheckChanged = async (e, commentStatus) => {
        var newCheck = e.target.checked;
        console.log('Check changed', newCheck);
        var statusUpdated;
        if (newCheck === false) {
            if (visibleCommentStatuses.length <= MIN_VISIBLE_COUNT) {
                var modalData = { ...infoModalData };
                modalData.show = true;
                modalData.secondaryButton = '';
                modalData.title = MIN_VISIBLE_TITLE;
                modalData.body = MIN_VISIBLE_BODY;
                setInfoModalData(modalData);
                return;
            } else {                
                statusUpdated = uncheckItem(newCheck, commentStatus);;

            }
        } else {
            if (visibleCommentStatuses.length >= maxStatusLimit) {
                var modalData = { ...infoModalData };
                modalData.show = true;
                modalData.secondaryButton = '';
                modalData.title = MAX_LIMIT_TITLE;
                modalData.body = maxLimitBody(maxStatusLimit);
                setInfoModalData(modalData);
                return;
            } else {
                statusUpdated = checkItem(newCheck, commentStatus);
            }
        }

        await updateStatus(statusUpdated);
    };

    const handleInfoModalAction = () => {
        var modalData = { ...infoModalData };
        modalData.show = false;
        setInfoModalData(modalData);
    };

    return (
        <>
            <DataFetcher height={'476px'} onFetchData={fetchStatusData}>
                {(
                    <div style={{ height: '100%' }}>
                        <CommentStatusAddHOC />
                        <hr></hr>
                        {visibleCommentStatuses.length === 0 && hiddenCommentStatuses.length === 0 ? (
                            <div
                                style={{
                                    display: 'flex',
                                    paddingBottom: '16px'
                                }}>
                                <span>
                                    No statuses to show. Please add statuses.
                                </span>
                            </div>
                        ) :

                            <div style={{ height: 'calc(100% - 100px)' }}>
                                <StatusHeader />

                                <div className="comment-status-list-container">
                                    <StatusList
                                        {...{
                                            items: visibleCommentStatuses,
                                            setItems: setVisibleCommentStatuses,
                                            editingComment,
                                            setEditingComment,
                                            onCheckChanged,
                                            enableDragging: true,
                                            updateStatusPriority
                                        }}
                                    />
                                    <StatusList
                                        {...{
                                            items: hiddenCommentStatuses,
                                            setItems: setHiddenCommentStatuses,
                                            editingComment,
                                            setEditingComment,
                                            onCheckChanged,
                                            enableDragging: false
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                )}
            </DataFetcher>

            {infoModalData.show && (
                <ConfirmationModal
                    show
                    onHide={handleInfoModalAction}
                    onPrimaryClick={handleInfoModalAction}
                    onSecondaryClick={handleInfoModalAction}
                    primaryButtonText="Ok"
                    secondaryButtonText={infoModalData.secondaryButton}
                    title={infoModalData.title}
                    bodyText={infoModalData.body}
                />
            )}
        </>
    );
};

const CommentStatusAddHOC = withStatusManageConsumer(StatusAdd);
