import React, { useEffect } from 'react';
import { contributorsHelper } from '../../../../_helpers';
import { getProgressMarkerPosition } from '../VideoAnnotationUtil';

function AnnotationMarker({ marker, onMakerClicked, frameCount, isDragging }) {
    useEffect(() => {}, [marker.comment.frameRange]);

    const getColors = (marker, annotation) => {
        var color = marker.isShortcut === true? '#888888' : contributorsHelper.findCommentColor(annotation);
        var fontCol = contributorsHelper.findFontColor(color);
        return { backgroundColor: color, color: fontCol };
    };

    const getMarkerWidth = (left, frameRange) => {
        let startFrame = frameRange[0],
            endFrame = frameRange[1];
        let markerWidth;
        var positionEnd = getProgressMarkerPosition(endFrame, frameCount);

        if (positionEnd) {
            markerWidth = startFrame === endFrame ? 0 : positionEnd - left;
        }
        return markerWidth;
    };
    
    return (
        <div
            className="mrnda-video-editor-area-controls-markers--item"
            style={{
                ...marker.style,
                width: getMarkerWidth(marker.style.left, marker.comment.frameRange),                
                pointerEvents:  'none' 
            }}
            title={marker.title}
            >
            <div
                className="mrnda-video-editor-area-controls-markers--line"
                style={getColors(marker,marker.comment.annotations[0])}
                ></div>
            <div
                className="mrnda-video-editor-area-controls-markers--profile"
                style={{...getColors(marker,marker.comment.annotations[0]), pointerEvents: 'auto' }}
                onClick={(e) => onMakerClicked(e, marker.comment)}>
                {marker.userInitial}
            </div>
        </div>
    );
}

export default AnnotationMarker;
