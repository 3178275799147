import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useStateRef from 'react-usestateref';
import { utilService } from '../../../../_helpers';
import { withVideoAnnotationConsumer } from '../Context/VideoAnnotationConsumer';
import PlayMarker from '../PlayMarker';
import { MarkerPosition, getMarkerPosition, createMarkers } from '../videoAnnotationHelper';
import { getProgressMarkerPosition } from '../VideoAnnotationUtil';
import AnnotationMarker from './AnnotationMarker';
import FramePicker from './FramePicker';
import { useScreenSizes } from '../../../responsive/useScreenSizes';

const AnnotationMarkers = ({
    annotationComments,
    videoMetadata,
    seekToTime,
    annotationCommentsRef,
    editingCommentId,
    handleOnRangeSliderChange,
    rangeSelectorValue,
    markers,
    setMarkers,
    markersRef,
    isCommentAddMode,
    currentFrameNumber
}) => {
    const [isMultiLine, setIsMultiLine] = useState(false);
    const timeLineRef = useRef();
    const [isDragging, setIsDragging] = useState(false);
    const { isMobile } = useScreenSizes();
    useEffect(() => {
        if (videoMetadata.frameCount == 0) {
            return;
        }
        console.log('Add listener!');
        window.addEventListener('resize', onWindowResize);

        return () => {
            console.log('Clean up!');
            window.removeEventListener('resize', onWindowResize);
        };
    }, [videoMetadata.frameCount]);

    const onWindowResize = useCallback(() => {        
        updateMarkers();
    }, [videoMetadata.frameCount]);

    useEffect(() => {
        if (annotationComments === null || annotationComments === undefined) {
            return;
        }
        var newMarkers = createMarkers(annotationComments, videoMetadata.frameCount, []);
        setMarkers(newMarkers);
    }, [annotationComments.length]);

    const updateMarkers = () => {
        var modifiedMarkers = markersRef.current.map((marker) => {
            var item = { ...marker };

            var style = getMarkerPosition(
                marker.comment.frameRange,
                item.alignment,
                videoMetadata.frameCount
            );
            item.style = style;
            return item;
        });

        setMarkers(modifiedMarkers);
    };
    // useEffect(()=> {
    //     console.log('width changed!!');
    // }, [timeLineRef.current])

    const onAnnotationClick = (event, annotation) => {
        event.stopPropagation();
        seekToTime(annotation.frameRange[0]);
    };

    return (
        <div className="annotation-markers">
            
            {
                !isMobile && 
                <PlayMarker {...{ isCommentAddMode, frameCount : videoMetadata.frameCount, currentFrameNumber, seekToTime, isDragging, setIsDragging }}
            />
            }
            {markers?.map((marker) => {
                return marker.id === editingCommentId ? (
                    <FramePicker
                        key={marker.id}
                        frameCount={videoMetadata.frameCount}
                        onThumbCLick={() => {}}
                        handleOnRangeSliderChange={handleOnRangeSliderChange}
                        rangeSelectorValue={rangeSelectorValue}
                        title="Drag a slider to select frame"
                        frameRate={videoMetadata.frameRate}
                    />
                ) : (
                    <AnnotationMarker
                        key={marker.id}
                        marker={marker}
                        onMakerClicked={onAnnotationClick}
                        frameCount={videoMetadata.frameCount}
                        frameRate={videoMetadata.frameRate}
                        isDragging={isDragging}
                    />
                );
            })}
        </div>
    );
};

const hoc = withVideoAnnotationConsumer(AnnotationMarkers);
export { hoc as AnnotationMarkers };
