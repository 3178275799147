import React from 'react';
import ColorPicker from '../../_shared/ColorPickerComponent';
import ContributorColorPicker from '../ContributorColorPicker';

const ColorSelector = ({userAnnotationColor, onSelectedColorChanged}) => {
    return (
        <div className="mrnda-video-editor-area-toolbox--mobile">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ContributorColorPicker
                    selectedColor={userAnnotationColor}
                    onSelectedColorChanged={onSelectedColorChanged}
                />
            </div>
        </div>
    );
};

export default ColorSelector;
