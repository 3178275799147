import { authHeader, handleResponse, config } from '../../../_helpers';

export const videoTranscriptionService = {
    getTranscribeText,
    transcribeVideoToText
};

function getTranscribeText(proofId, versionId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(
        `${config.apiUrl}/videoProof/${proofId}/GetTranscribeText/${versionId}`,
        requestOptions
    ).then(handleResponse);
}

function transcribeVideoToText(proofId, proofVersionId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(
        `${config.apiUrl}/videoProof/${proofId}/TranscribeVideoToText/${proofVersionId}`,
        requestOptions
    ).then(handleResponse);
}
