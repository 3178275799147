import { alertConstants } from '../../components';

export function alert(state = {}, action) {    
    switch (action.type) {
        case alertConstants.SUCCESS:
            return {
                ...state,
                type: 'alert-success',
                message: action.message
            };
        case alertConstants.ERROR:
            return {
                ...state,
                type: 'alert-danger',
                message: action.message
            };
        case alertConstants.CLEAR:
            return {
                isHideTopbar: state.isHideTopbar,
                isHideTranscription: state.isHideTranscription,
                isNavActionsDisabled: state.isNavActionsDisabled

            };
        case alertConstants.LOADING:
            return {
                ...state,
                type: 'alert-loading',
                isLoading: action.isLoading
            };
        case alertConstants.HIDE_TOPBAR:
            return {
                ...state,
                type: 'alert-hide-toolbar',
                isHideTopbar: action.isHideTopbar,
                isHideTranscription: action.isHideTranscription
            };
        case alertConstants.DISABLE_NAV_ACTIONS:
                return {
                    ...state,
                    type: 'alert-disable-nav-actions',
                    isNavActionsDisabled: action.isNavActionsDisabled,                    
            };
        case alertConstants.HIDE_RIGHT_NAV_ACTIONS:
            return {
                ...state,
                type: 'alert-hide-right-nav-actions',
                isHideRightNavActions: action.isHideRightNavActions,                    
        };
        case alertConstants.TOGGLE_NAVBAR:
            return {
                ...state,
                type: 'alert-toggle-navbar',
                isCompact: action.isCompact
            };
        default:
            return state;
    }
}
export function alertStatuses(state = {}, action) {
    switch (action.type) {
        case alertConstants.PROOF_EDITOR_DIRTY:
            return {
                type: 'alert-proof-editor-dirty',
                isProofEditorDirty: action.isProofEditorDirty
            };
        default:
            return state;
    }
}
