import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { history, utilService } from '../../../_helpers';

class ProofCompareModal extends React.Component {
    constructor(props) {
        super(props);
        var modifiedProofVersions = props.proofObject.proofVersions.map(el => {
            return Object.assign({}, el, {
                versionLabel: `V${el.versionLabel}`,
            });
        });
        var newElement = {
            proofVersionId: '-1',
            documentName: 'Select',
            versionLabel: 'Select',
            id: '-1',
        };
        var newProofVersions = [newElement].concat(modifiedProofVersions);
        var defaultVersion = newProofVersions.find(
            x => x.id === props.proofObject.proof.defaultVersionId,
        );
        var defaultVersionId = defaultVersion.id;
        this.state = {
            versionIds: [defaultVersionId],
            proofCompareVersions: [defaultVersion],
            canCompare: false,
            proof: props.proofObject.proof,
            proofVersions: newProofVersions,
            currentVersion: props.proofObject.currentVersion,
            defaultVersionId,
        };
    }
    // static getDerivedStateFromProps(nextProps) {
    //     if (nextProps.s3TempInfo && nextProps.s3TempInfo.documentId) {
    //         return {
    //             documentId: nextProps.s3TempInfo.documentId
    //         };
    //     } else return null;
    // }
    onModalClose() {
        this.props.onHide();
    }
    onProofVersionSelectorChange() {
        this.validateCompare();
    }
    async onCompare() {
        var versionA = this.state.proofVersions.find(
            x => x.id === this.state.versionIds[0],
        );
        var versionB = this.state.proofVersions.find(
            x => x.id === this.state.versionIds[1],
        );
        await utilService.setStateAsync.bind(this)({
            proofCompareVersions: [versionA, versionB],
        });
        if (this.state.versionIds && this.state.versionIds.length > 1) {
            history.push({
                pathname: `/proofcompare`,
                // search: `?proofId=${this.state.proof.id}&documentAId=${
                //     this.state.documentIds[0]
                // }&documentBId=${this.state.documentIds[1]}`,
                data: {
                    proof: this.state.proof,
                    proofCompareVersions: this.state.proofCompareVersions,
                },
            });
        }
    }
    validateCompare() {
        var documentAId = this.refs.versionSelectorA.value;
        var documentBId = this.refs.versionSelectorB.value;
        if (documentAId == '-1' || documentBId == '-1' || documentAId === documentBId) {
            // this.props.dispatch(alertActions.warn("Select different versions."))
            this.setState({ canCompare: false });
        } else {
            this.setState({
                canCompare: true,
                versionIds: [documentAId, documentBId],
            });
        }
    }
    isThisVersionDefault(versionId) {
        var result = false;
        if (versionId === this.state.proof.defaultVersionId) result = true;
        return result;
    }
    render() {
        console.log('ProofCompareModal Props: ', this.props);
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'}
                scrollable>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        File Version Compare
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div style={{ float: 'left', marginRight: 50 }}>
                            <p className="label">Choose Side A</p>
                            <div className="float--left">
                                <select
                                    ref="versionSelectorA"
                                    id="versionSelectorA"
                                    className=""
                                    name="versionSelector"
                                    onChange={this.onProofVersionSelectorChange.bind(this)}
                                    defaultValue={this.state.currentVersion.id}
                                    style={{ marginTop: '5px' }}>
                                    {this.state.proofVersions &&
                                        this.state.proofVersions.map(proofVersion => (
                                            <option
                                                key={proofVersion.id}
                                                value={proofVersion.id}>
                                                {this.isThisVersionDefault(proofVersion.id)
                                                    ? `${proofVersion.versionLabel} - Default`
                                                    : proofVersion.versionLabel}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        <div>
                            <p className="label">Choose Side B</p>
                            <div className="float--left">
                                <select
                                    ref="versionSelectorB"
                                    id="versionSelectorB"
                                    className=""
                                    name="versionSelector"
                                    onChange={this.onProofVersionSelectorChange.bind(this)}
                                    defaultValue={'-1'}
                                    style={{ marginTop: '5px' }}>
                                    {this.state.proofVersions &&
                                        this.state.proofVersions.map(proofVersion => (
                                            <option
                                                key={proofVersion.id}
                                                value={proofVersion.id}>
                                                {this.isThisVersionDefault(proofVersion.id)
                                                    ? `${proofVersion.versionLabel} - Default`
                                                    : proofVersion.versionLabel}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant=""
                        disabled={!this.state.canCompare}
                        className="btn mrnda-btn"
                        onClick={this.onCompare.bind(this)}>
                        Compare
                    </Button>
                    <Button className="mrnda-btn--secondary" onClick={this.onModalClose.bind(this)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    console.log('States in CreateProofModal:', state);
    const { s3Info } = state;
    return {
        s3TempInfo: s3Info.s3Info,
    };
}

const connectedCreateProofModel = connect(mapStateToProps)(ProofCompareModal);
export { connectedCreateProofModel as ProofCompareModal };
