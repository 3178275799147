//@ts-check

import React from 'react';

const CloseIcon = ({ height = 16, width = 16, color = 'currentColor' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 14"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="14px/Interaction/Navigation/clear" fill={color}>
                    <rect id="Rectangle" fillOpacity="0" x="0" y="0" width="14" height="14"></rect>
                    <g id="Outlines" transform="translate(3.000000, 3.000000)" fillRule="nonzero">
                        <path
                            d="M0.853553391,0.146446609 L4,3.293 L7.14644661,0.146446609 C7.32001296,-0.0271197425 7.58943736,-0.0464048927 7.7843055,0.0885911588 L7.85355339,0.146446609 C8.04881554,0.341708755 8.04881554,0.658291245 7.85355339,0.853553391 L4.707,4 L7.85355339,7.14644661 C8.04881554,7.34170876 8.04881554,7.65829124 7.85355339,7.85355339 C7.65829124,8.04881554 7.34170876,8.04881554 7.14644661,7.85355339 L4,4.707 L0.853553391,7.85355339 C0.679987039,8.02711974 0.410562638,8.04640489 0.215694497,7.91140884 L0.146446609,7.85355339 C-0.0488155365,7.65829124 -0.0488155365,7.34170876 0.146446609,7.14644661 L3.293,4 L0.146446609,0.853553391 C-0.0488155365,0.658291245 -0.0488155365,0.341708755 0.146446609,0.146446609 C0.341708755,-0.0488155365 0.658291245,-0.0488155365 0.853553391,0.146446609 Z"
                            id="Combined-Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default CloseIcon;
