import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { roleActions, userActions, alertActions, MultiSelect } from '../../components';
import { utilService } from '../../_helpers';
import './SettingsPage.css';
import { rolePermissions, moduleActions } from './role-permission-mock';
import { DataFetcher } from '../_shared/DataFetcher/DataFetcher';
import classNames from 'classnames';

const initialValidationState = {
    name: {
        validated: true,
        message: ''
    },
    description: {
        validated: true,
        message: ''
    }
};

class RoleModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roleObject: this.props.roleObject,
            validations: initialValidationState,
            users: [],
            existingUsers: null,
            canSave: true,
            disabledRole : false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    async fetchData() {
        // const rows = moduleActions.map(item => {
        //     var newItem = { ...item };
        //     var module = rolePermissions.Permissions.find(permission => permission.moduleId === item.id);
        //     var exists = (module && module.actions && module.actions.find(action=> action.key === item.key));
        //     return newItem;
        // });
        // const rowsExistingUsers =
        //     this.state.groupObject.users && this.state.groupObject.users.length > 0
        //         ? rows.filter(item => this.state.groupObject.users.includes(item.id))
        //         : [];
        var moduleActionRequest = {
            id: this.props.operationMode === 1 ? '' : this.props.roleObject.id
        };
        var moduleActionsPermissions = await this.props.dispatch(
            roleActions.getModuleActionPermissions(moduleActionRequest)
        );
        var newRoleObject = {
            ...this.state.roleObject,
            permissions: moduleActionsPermissions
        };

        var isDisabled = newRoleObject.createdBy === "System Generated" && newRoleObject.name === "External User";
        
        await utilService.setStateAsync.bind(this)({
            roleObject: newRoleObject,
            submitted: false,
            disabledRole : isDisabled
        });
    }
    onModalClose() {
        this.props.onClose();
    }
    handleChange(event) {
        var { name, value } = event.target;
        const { roleObject } = this.state;
        if (name === 'isEnabled') {
            value = value == 'false' ? 'Enabled' : 'Disabled';
        }
        this.setState({
            roleObject: {
                ...roleObject,
                [name]: value
            },
            submitted: false,
            canSave: true
        });
    }
    handlePermissionChange(event, module) {
        var { name, checked } = event.target;
        const { roleObject } = this.state;
        const { permissions } = roleObject;
        var modifiedPermissions = permissions.map((item) => {
            var newItem = { ...item };
            if (item.moduleId === module.moduleId) {
                var action = newItem.actions.find((action) => action.key === name);
                action.status = checked;
            }
            return newItem;
        });
        this.setState({
            roleObject: {
                ...roleObject,
                permissions: modifiedPermissions,
                submitted: false
            }
        });
    }
    async onChangeMultiSelect(selectedOptions, addedUserIds, removedUserIds) {
        var selectedUserIds = selectedOptions
            ? selectedOptions.map((item) => {
                  var newItem = { ...item };
                  return newItem.id;
              })
            : [];
        var newAddedUserIds = this.state.roleObject.addedUserIds
            ? [...this.state.roleObject.addedUserIds, ...addedUserIds]
            : addedUserIds;
        var newRemovedUserIds = this.state.roleObject.removedUserIds
            ? [...this.state.roleObject.removedUserIds, ...removedUserIds]
            : removedUserIds;
        var newRoleObject = {
            ...this.state.roleObject,
            users: selectedUserIds,
            addedUserIds: newAddedUserIds,
            removedUserIds: newRemovedUserIds
        };
        this.setState({
            roleObject: newRoleObject,
            existingUsers: selectedOptions
        });
    }
    async validateFields() {
        var globalValidation = true;
        var { validations } = this.state;
        var validationCopy = { ...validations };
        if (
            !this.state.roleObject.name ||
            (this.state.roleObject.name && this.state.roleObject.name.length < 3)
        ) {
            validationCopy.name = {
                validated: false,
                message: 'Name must be more than 2 characters '
            };
            globalValidation = false;
        }
        if (this.state.roleObject.description && this.state.roleObject.description.length > 120) {
            validationCopy.description = {
                validated: false,
                message: 'Description must be less than 120 characters '
            };
            globalValidation = false;
        }
        if (true) {
            if (this.state.roleObject.name) {
                var roleObjectRequest = { ...this.state.roleObject };
                if (
                    roleObjectRequest.isEnabled === 'Enabled'
                        ? (roleObjectRequest.isEnabled = true)
                        : (roleObjectRequest.isEnabled = false)
                );
                // keep provide id existing item unique check
                var response = await this.props.dispatch(
                    roleActions.isNameUnique(roleObjectRequest)
                );
                if (!response.isNameUnique) {
                    validationCopy.name = {
                        validated: false,
                        message: 'Role with the same name already exists'
                    };
                    globalValidation = false;
                }
            }
        }
        await utilService.setStateAsync.bind(this)({
            validations: validationCopy
        });
        return globalValidation;
    }
    async handleSubmit(event) {
        event.preventDefault();

        await utilService.setStateAsync.bind(this)({
            submitted: true,
            validations: initialValidationState,
            canSave: false
        });

        if (!(await this.validateFields())) {
            return;
        }

        const { roleObject } = this.state;
        const { dispatch } = this.props;
        var roleObjectRequest = { ...roleObject };
        if (
            roleObjectRequest.isEnabled === 'Enabled'
                ? (roleObjectRequest.isEnabled = true)
                : (roleObjectRequest.isEnabled = false)
        );
        if (roleObject.name) {
            if (this.props.operationMode === 1) await dispatch(roleActions.add(roleObjectRequest));
            else if (this.props.operationMode === 2)
                await dispatch(roleActions.update(roleObjectRequest));
            else return;
            const data = {
                page: 0,
                pageSize: 20,
                proofSortModel: {
                    sortDirection: 1,
                    sortColumn: null
                },
                proofFilters: []
            };
            await this.props.dispatch(roleActions.getAll(data));
            this.props.dispatch(alertActions.success('Saved !'));
            this.onModalClose();
        }
    }

    render() {
        console.log('roleModal Props: ', this.props);
        const { roleObject, submitted, validations } = this.state;
        const role = roleObject;
        const titleText = this.props.operationMode === 1 ? 'Add Role' : 'Edit Role';
        return (
            <Modal
                //{...this.props}
                show={this.props.show}
                onHide={this.props.onClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'}
                scrollable>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{titleText}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <DataFetcher onFetchData={this.fetchData.bind(this)} height={'520px'}>
                    <form className={classNames({'authorization-disabled' : this.state.disabledRole})} name="form" onSubmit={this.handleSubmit}>
                        <div
                            className={
                                'form-group' + (submitted && !role.firstName ? ' has-error' : '')
                            }>
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                className="form-control mrnda-input-text"
                                name="name"
                                value={role.name || ''}
                                onChange={this.handleChange}
                            />
                            {submitted && !validations.name.validated && (
                                <div className="help-block">{validations.name.message}</div>
                            )}
                        </div>
                        <div
                            className={
                                'form-group' + (submitted && !role.description ? ' has-error' : '')
                            }>
                            <label htmlFor="description">Description</label>
                            <textarea
                                className="form-control mrnda-input-textarea"
                                style={{ resize: 'none' }}
                                name="description"
                                value={role.description || ''}
                                onChange={this.handleChange}
                            />
                            {submitted && !validations.description.validated && (
                                <div className="help-block">{validations.description.message}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="permissions">Permissions</label>
                            <div id="role-permission-container">
                                {this.state.roleObject.permissions &&
                                    this.state.roleObject.permissions.map((module, index) => (
                                        <div key={module.key} className="role-permission-section">
                                            <div className="role-permission-section__header">
                                                <h6>{module.name}</h6>
                                            </div>
                                            <div className="role-permission-section__body">
                                                {module.actions &&
                                                    module.actions.map((action, moduleIndex) => {
                                                        if (action.key === 'proof.editor.comment.edit') {
                                                            return null;
                                                        } else {
                                                            return (
                                                                <div key={action.key}>
                                                                    <label className="check-container">
                                                                        <span className="text--small display--block role-permission-section__body__check-label">
                                                                            <strong>
                                                                                {action.name}
                                                                            </strong>
                                                                        </span>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="ml-2 mr-2"
                                                                            data-toggle="toggle"
                                                                            data-on="Enabled"
                                                                            data-off="Disabled"
                                                                            name={action.key}
                                                                            value={action.status}
                                                                            checked={action.status}
                                                                            onChange={(e) =>
                                                                                this.handlePermissionChange(
                                                                                    e,
                                                                                    module
                                                                                )
                                                                            }
                                                                            key={action.key}
                                                                        />
                                                                        <span className="check-container__checkmark">
                                                                            <i className="fas fa-check" />
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        {this.props.operationMode === 2 && false && (
                            <div className="form-group">
                                <label htmlFor="delete">Delete this role</label>
                                <button
                                    className="btn btn--neg"
                                    style={{ padding: 10, display: 'block' }}
                                    onClick={() => {}}>
                                    <i className="far fa-trash-alt mr-2" />
                                    Delete
                                </button>
                            </div>
                        )}
                    </form>
                    </DataFetcher>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant=""
                        className="btn mrnda-btn"
                        onClick={this.handleSubmit.bind(this)}
                        disabled={!this.state.canSave || this.state.disabledRole}
                        >
                        Save
                    </Button>
                    <Button className="mrnda-btn--secondary" onClick={this.onModalClose.bind(this)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    console.log('States in roleModal:', state);
    const { s3Info } = state;
    return {
        s3TempInfo: s3Info.s3Info
    };
}

const connectedCreateProofModel = connect(mapStateToProps)(RoleModal);
export { connectedCreateProofModel as RoleModal };
