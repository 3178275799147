import React, { useEffect } from 'react';
import cx from 'classnames';
import ProofEditorHeader from './ProofEditorHeader';
import { useScreenSizes } from '../../../responsive/useScreenSizes';
import EditorToolbarActionsMobile from './EditorToolbarActionsMobile';
import { PROOF_TYPE } from '../../proof.constants';
import { volumeHelper } from '../../../../_helpers/volumeHelper';
import { authorizationService, utilService } from '../../../../_helpers';
import { authorizationConstants } from '../../../_shared';

const VideoEditorToolboxMobile = (props) => {
    const { isMobile } = useScreenSizes();    
        
    var isUserPermittedForAdd = authorizationService.isUserPermitted(
        authorizationConstants.ProofEditor.KEY,
        authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_COMMENT_ADD
    );

    const isToolbarAllowed = props.isCommentAddMode 
        || props.proofVersion.isLocked 
        || !isUserPermittedForAdd 
        || props.canvasLimitExceeded === true;
    return (
        <div
            className={cx('mrnda-video-editor-area-toolbox', {
                'mrnda-video-editor-area-toolbox--mobile': isMobile
            })}>
            <div className={`mrnda-video-editor-area-toolbox--mobile__header`} style={{width: 'calc(100% - 168px)'}}>
                <ProofEditorHeader
                    proofVersion={props.proofVersion}
                    isNavActionsDisabled={true}
                    hideCloseButton={true}
                />
            </div>

            <div
                className={cx(`mrnda-video-editor-area-toolbox-shapes`, {
                    'authorization-disabled':
                        isToolbarAllowed || utilService.isWebsiteProof(props.proofVersion)
                })}>
                <EditorToolbarActionsMobile
                    {...props}
                    showMuteButton={props.proofVersion.mediaType === PROOF_TYPE.Video}
                    currentContributor={props.contributors?.find((x) => x.userID === props.userId)}
                />
            </div>          
        </div>
    );
};

export { VideoEditorToolboxMobile };
