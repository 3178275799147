import React, { useEffect, useState } from 'react';

export function PageCommentAnnotation({ annotationObject, page }) {
    return (
        <div className="">
            <div className="comment-header">
                {/* <span className="comment-number">
            {page.pageNumber}
        </span> */}
                <span className="comment-author">{annotationObject.createdBy}</span>
                <span className="comment-created">
                    {new Date(annotationObject.createdAt).toLocaleString()}
                </span>
            </div>
            <div className="comment-resolved">
                {/* <span className="comment-resolved--icon">

                </span> */}
                <span className="comment-resolved--text">{annotationObject.name}</span>
            </div>
            <div className="comment-text">{annotationObject.content}</div>
            <div className="comment-replies">
                {annotationObject.annotationComments.map(reply => (
                    <div key={reply.id} className="comment-reply">
                        <div className="comment-reply-header">
                            <span className="comment-reply-number">
                                {/* {`${page.pageNumber}.${annotationObject.annotationComments.indexOf(reply) + 1}`} */}
                                <i className="fas fa-reply"></i>
                            </span>
                            <span className="comment-reply-header-text">{reply.createdBy}</span>
                            <span className="comment-reply-status statusReview bg-accent-dark-gradient">{reply.status}</span>
                            <span className="comment-reply-created">
                                {new Date(reply.createdAt).toLocaleString()}
                            </span>
                        </div>
                        <div className="comment-reply-text">{reply.content}</div>
                    </div>
                ))}
            </div>
            <hr></hr>
        </div>
    );
}
