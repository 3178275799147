import React, { useEffect } from 'react';
import cx from 'classnames';

const PageBackdrop = ({width, height, isLoading ,onBackdropLoaded}) => {

    useEffect(()=> {            
        if(onBackdropLoaded){
            onBackdropLoaded();
        }
    }, [height,width])

    return (
        <div
            className={cx({ 'backdrop' : true, 'skeleton' : isLoading })}
            style={{
                display: 'flex',
                width: `${width}px`,
                height: `${height}px`,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'var(--tertiary-background-color)', 
            }}>
            
        </div>
    );
};


export { PageBackdrop }