//@ts-check

import React from 'react';

const PauseIcon = ({ height = 32, width = 32, color = 'currentColor' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg" >
            
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="32px/Interaction/Multimedia/pause" fill={color} >
                    <rect id="Rectangle" fillOpacity="0" x="0" y="0" width={width} height={height}></rect>
                    <g id="Outline" transform="translate(3.000000, 3.000000)" fillRule="nonzero">
                        <path
                            d="M8.5,0 C9.32842712,0 10,0.671572875 10,1.5 L10,24.5 C10,25.3284271 9.32842712,26 8.5,26 L1.5,26 C0.671572875,26 0,25.3284271 0,24.5 L0,1.5 C0,0.671572875 0.671572875,0 1.5,0 L8.5,0 Z M8.5,1 L1.5,1 C1.22385763,1 1,1.22385763 1,1.5 L1,24.5 C1,24.7761424 1.22385763,25 1.5,25 L8.5,25 C8.77614237,25 9,24.7761424 9,24.5 L9,1.5 C9,1.22385763 8.77614237,1 8.5,1 Z M24.5,0 C25.3284271,0 26,0.671572875 26,1.5 L26,24.5 C26,25.3284271 25.3284271,26 24.5,26 L17.5,26 C16.6715729,26 16,25.3284271 16,24.5 L16,1.5 C16,0.671572875 16.6715729,0 17.5,0 L24.5,0 Z M24.5,1 L17.5,1 C17.2238576,1 17,1.22385763 17,1.5 L17,24.5 C17,24.7761424 17.2238576,25 17.5,25 L24.5,25 C24.7761424,25 25,24.7761424 25,24.5 L25,1.5 C25,1.22385763 24.7761424,1 24.5,1 Z"
                            id="Combined-Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default PauseIcon;
