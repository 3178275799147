import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const initialState = {
    frameRate: 0
};
export const ConfigureMetadata = (props) => {
    const [metadata, setMetadata] = useState(initialState);
    return (
        <Modal
            show={props.show}
            onHide={() => props.onClose(false)}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={'static'}
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Configure Metadata</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    className={'form-group'}
                    style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="form-group pt-2">
                        <label htmlFor="frameRate">Frame Rate</label>
                        <input
                            style={{ margin: '0px 5px 0px 5px', textAlign: 'center' }}
                            type="number"
                            min="1"
                            max="120"
                            className=""
                            name="frameRate"
                            value={metadata.frameRate}
                            onChange={({ target }) => setMetadata({ frameRate: Number(target.value) })}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="mrnda-btn btn-sm"
                    onClick={() => props.onMetadataSave({ ...metadata })}>
                    Save
                </Button>
                <Button
                    className="mrnda-btn--secondary btn-sm"
                    onClick={() => props.onClose(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
