//@ts-check

import React from 'react';

const AnnotationIcon = ({ width = 14, height = 14, color = 'currentColor' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 14 14"
            fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 2H7.5C7.77614 2 8 2.22386 8 2.5V4.02746C5.91419 4.25804 4.25804 5.91419 4.02746 8H2.5C2.22386 8 2 7.77614 2 7.5V2.5C2 2.22386 2.22386 2 2.5 2ZM4.02746 9H2.5C1.67157 9 1 8.32843 1 7.5V2.5C1 1.67157 1.67157 1 2.5 1H7.5C8.32843 1 9 1.67157 9 2.5V4.02746C11.25 4.27619 13 6.18372 13 8.5C13 10.9853 10.9853 13 8.5 13C6.18372 13 4.27619 11.25 4.02746 9ZM5.03544 8C5.2547 6.46715 6.46715 5.2547 8 5.03544V7.5C8 7.77614 7.77614 8 7.5 8H5.03544ZM9 5.03544V7.5C9 8.32843 8.32843 9 7.5 9H5.03544C5.27806 10.6961 6.73676 12 8.5 12C10.433 12 12 10.433 12 8.5C12 6.73676 10.6961 5.27806 9 5.03544Z"
                fill={color}             
            />
        </svg>
    );
};

export default AnnotationIcon;
