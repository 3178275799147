import {
    authHeader,
    config,
    handleResponse as handleResponseBetter,
    handleResponse
} from '../../_helpers';

export const tenantService = {
    register,
    getAll,
    getById,
    update,
    delete: _delete,
    getCurrentTenant,
    getCurrentTenantData,
    getSetCurrentTenant,
    getCurrentTenantObject,
    getCurrentHostDomain,
    getDataCenters,
    getEmailDomains,
    updateEmailDomains,
    validateEmailDomain,
    isSubdomainValid,
    isSubdomainUnique,
    getProofSettings,
    updateProofSettings,
    validateWebsiteURL,
    updateMarcomboxLoginSettings,
    updateMirandaLoginSettings,
    updateOktaGatewayLoginSettings,
    syncMarcomboxUsers,
    checkDefaultProvider,
    updateOktaLoginSettings,
    isExists,
    getEmailSettings,
    updateEmailSettings,
    getEmailTemplatePreview   
};
function isSubdomainValid(value) {
    var re = /^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$/;
    return re.test(value);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/tenant`, requestOptions).then(handleResponse);
}

async function isSubdomainUnique(subDomain) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(
        `${config.apiUrl}/tenant/isSubdomainUnique/${subDomain}`,
        requestOptions
    ).then(handleResponse);
}


async function isExists(tenantId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(
        `${config.apiUrl}/tenant/isExists/${tenantId}`,
        requestOptions
    ).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/tenants/${id}`, requestOptions).then(handleResponse);
}

function register(tenant) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(tenant)
    };

    return fetch(`${config.apiUrl}/tenant/add`, requestOptions).then(handleResponse);
}

function update(tenant) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(tenant)
    };

    return fetch(`${config.apiUrl}/tenants/${tenant.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(
        `${config.apiUrl}/tenant/delete/${id}`,
        requestOptions
    ).then(handleResponse);
}

function getCurrentTenant() {
    const subdomain = window.location.hostname.split('.');
    return subdomain ? subdomain[0] : null;
}
function getCurrentHostDomain(host) {
    var parts = host.split('.');
    var subdomain = parts.shift();
    var upperleveldomain = parts.join('.');
    return upperleveldomain;
}
async function getCurrentTenantObject() {
    let tenantFromLocalStorage = JSON.parse(await localStorage.getItem('tenant'));
    return tenantFromLocalStorage;
}

async function getSetCurrentTenant() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${config.apiUrl}/tenant/GetCurrent`, requestOptions)
        .then(handleResponseBetter)
        .then((tenant) => {
            localStorage.setItem('tenant', JSON.stringify(tenant));
            return tenant;
        });
}

async function getCurrentTenantData() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${config.apiUrl}/tenant/GetCurrent`, requestOptions).then(handleResponse);
}

function updateMarcomboxLoginSettings(tenantId, mbloginSettingsData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(mbloginSettingsData)
    };

    return fetch(`${config.apiUrl}/tenant/updateMarcomboxLoginSettings/${tenantId}`, requestOptions).then(handleResponse);
}

function updateMirandaLoginSettings(tenantId, isEnable) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(isEnable)
    };

    return fetch(`${config.apiUrl}/tenant/updateMirandaLoginSettings/${tenantId}`, requestOptions).then(handleResponse);
}

function updateOktaGatewayLoginSettings(tenantId, isEnable) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(isEnable)
    };

    return fetch(`${config.apiUrl}/tenant/updateOktaGatewayLoginSettings/${tenantId}`, requestOptions).then(handleResponse);
}

function updateOktaLoginSettings(tenantId, oktaSettingsData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(oktaSettingsData)
    };

    return fetch(`${config.apiUrl}/tenant/updateOktaLoginSettings/${tenantId}`, requestOptions).then(handleResponse);
}

async function getDataCenters() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${config.apiUrl}/tenant/GetDataCenters`, requestOptions).then(handleResponse);
}

async function getEmailDomains() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${config.apiUrl}/tenant/getEmailDomains`, requestOptions).then(handleResponse);
}

async function updateEmailDomains(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/tenant/updateEmailDomains`, requestOptions).then(handleResponse);
}

async function validateEmailDomain(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${config.apiUrl}/tenant/validateEmailDomain/${requestData}`, requestOptions).then(
        handleResponse
    );
}

async function getProofSettings() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${config.apiUrl}/tenant/getProofSettings`, requestOptions).then(handleResponse);
}
async function getEmailSettings() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${config.apiUrl}/tenant/getEmailSettings`, requestOptions).then(handleResponse);
}

async function getEmailTemplatePreview(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/tenant/GetEmailTemplatePreview`, requestOptions).then(handleResponse);
}

async function updateProofSettings(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/tenant/updateProofSettings`, requestOptions).then(handleResponse);
}

async function syncMarcomboxUsers() {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${config.apiUrl}/tenant/syncMarcomboxUsers`, requestOptions).then(handleResponse);
}

async function checkDefaultProvider() {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${config.apiUrl}/user/checkDefaultProvider`, requestOptions).then(handleResponse);
}

function validateWebsiteURL(url) {
    try {
        new URL(url);
      } catch (_) {
        return false;  
      }
    
      return true;
  }

  async function updateEmailSettings(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/tenant/updateEmailSettings`, requestOptions).then(handleResponse);
}
