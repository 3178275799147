import React, { useState, useEffect, useCallback } from 'react';
import cx from 'classnames';
import { utilService, config, colorUtilService } from '../../../../../_helpers';
import { contributorsHelper } from '../../../../../_helpers';
import AttachmentViewer from './AttachmentsViewer';
import MentionLabel from './MentionLabel';
import MirandaMentionInput from './MirandaMentionInput';
import { mentionHelper } from '../../../../../_helpers/mentionHelper';
import { commentHelper } from '../../../../../_helpers/commentHelper';
import { useScreenSizes } from '../../../../responsive/useScreenSizes';
import { useTheme } from '../../../../App/Theme/ThemeContext';
import { secondarySmallTextStyle } from '../../../../_shared/Styles/TextStyles';
import PhoneBreakpoint from '../../../../responsive/PhoneBreakpoint';
import DesktopBreakpoint from '../../../../responsive/DesktopBreakpoint';

const ReplyItem = ({ replyItem, showContextMenu, onReplyDelete, avatarColor, onAttachmentClicked, onAttachmentDelete, showAttachmentDelete, onReplayUpdate, commentId, annotationComments, setAnnotationComments, updateReplyContent, editAllowedReplies, isProofCompare, setSelectedComment, isLast }) => {

    const [replyColor, setReplyColor] = useState(null);
    const [fontColor, setFontColor] = useState(null);
    const [isReplyEditMode, setIsReplyEditMode] = useState(false);
    const [editedReply, setEditedReply] = useState('');
    const [isEditAllowed, setIsEditAllowed] = useState(true);
    const {isMobile} = useScreenSizes();
    const {themeColors} = useTheme();

    useEffect(() => {
        var color = contributorsHelper.convertKeyToColor(replyItem.color);
        var fontCol = contributorsHelper.findFontColorFromKey(replyItem.color);
        setFontColor(fontCol);
        setReplyColor(color);        
    }, [])

    useEffect(() => {
        var exists =  editAllowedReplies.some(x => x === replyItem.id);
        setIsEditAllowed(exists);
    },[editAllowedReplies])

    useEffect(() => {
        if (isReplyEditMode === true) {

            if (replyItem.mentions.length > 0) {
                var converted = mentionHelper.processCommentValueForInput(replyItem.content, replyItem.mentions)
                setEditedReply(converted);
            }
            else {
                setEditedReply(replyItem.content);
            }
        }
    }, [isReplyEditMode]);

    const handleOnUpdateReply = async () => {

        var mentionRes = mentionHelper.processInputValue(editedReply);
        var payLoad = {
            commentId: commentId,
            replyId: replyItem.id,
            content: mentionRes.tValue,
            mentions: mentionRes.mentions
        };

        let response = await updateReplyContent(payLoad);

        setIsReplyEditMode(false);
        onReplyUpdateCompleted(response);
        setSelectedComment(-1);

    }

    const onReplyUpdateCompleted = (updatedReply) => {

        var modified = annotationComments.map((comment) => {
            let modifiedComment = { ...comment };

            if (modifiedComment.id == commentId) {

                var replies = modifiedComment.replies.map((reply) => {
                    return reply.id === updatedReply.id ? updatedReply : reply;
                })

                modifiedComment.replies = replies;
            }

            return modifiedComment;
        });

        setIsReplyEditMode(false);
        setAnnotationComments(modified);
    };

    const canEdit = ()=>{       

        var canEdit = editAllowedReplies.some(x => x === replyItem.id);
        if (!canEdit)
        {
            setIsEditAllowed(canEdit);
            return;
        }

        var currentDate = new Date();
        var createdDate = new Date(replyItem.createdAt);

        var def = currentDate - createdDate;

        var minutes = Math.floor(def / 60000);
    
        if (minutes > config.editThresholdInMinute) {
            setIsEditAllowed(false);
            return;
        }
        
        //setIsEditAllowed(true);
    }


    return (
        <div className="reply" style={ isMobile? { marginTop: '20px' } : {}}>

            <div className="reply__header" style={isMobile ? { margin: '0px 20px 0px 20px' } : {}}>
                <div className="reply__avatar" style={isMobile ? { flex: '0 32px' } : {}}>
                    <span
                        style={
                            isMobile
                                ? {
                                      background: colorUtilService.changeOpacity(
                                          themeColors.primaryTextColor,
                                          0.1
                                      ),
                                      color: 'var(--primary-text-color)',
                                      height: '32px',
                                      width: '32px',
                                      fontWeight: '600',
                                      lineHeight: '18px',
                                      fontSize: '12px'
                                  }
                                : { background: replyColor, color: fontColor }
                        }
                        className="mrnda-avatar--secondary--x-small">
                        {' '}
                        {utilService.getUserInitial(replyItem.createdBy)}
                    </span>
                </div>
                <div className="reply__details" style={isMobile ? { minWidth: '62%', marginLeft: '12px' } : {}}>
                    <div
                        className="reply__author"
                        style={
                            isMobile
                                ? {
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    lineHeight: '16px',
                                    color: 'var(--primary-text-color)'
                                }
                                : {}
                        }
                    >
                        {/* <span className="reply__author-icon-replied" /> */}
                        {replyItem.createdBy}
                    </div>

                    <div 
                        className="reply__date" 
                        style={isMobile ? secondarySmallTextStyle : {}}>
                        <span className="date-field">
                            {new Date(replyItem.createdAt).toLocaleString()}
                        </span>
                        <PhoneBreakpoint>
                            {
                                replyItem.isEdited === true &&
                                <>
                                    <span style={{ marginLeft: '4px' }} className="dot-separator">•</span>
                                    <span className="comment__edit-indicator"> Edited </span>
                                </>
                            }
                        </PhoneBreakpoint>
                    </div>
                </div>
            
                {
                    showContextMenu &&

                    <DesktopBreakpoint>
                        <div style={{ display: 'flex' }}>
                            <span
                                className={cx({
                                    'delete-button': true,
                                    'reply-delete-button': true,
                                    'authorization-disabled': !commentHelper.canDelete(replyItem.createdById)
                                })}
                                title="Delete reply"
                                onClick={() => onReplyDelete(replyItem)}>
                                <i className="far fa-trash-alt"></i>
                            </span>
                            {
                                <span
                                    className={cx({
                                        'delete-button': true,
                                        'reply-delete-button': true,
                                        'authorization-disabled': !commentHelper.showEdit(
                                            replyItem.createdById
                                        ),
                                        'edit-disabled': !isEditAllowed
                                    })}
                                    title={
                                        isEditAllowed === true
                                            ? 'Edit reply'
                                            : 'Editing is not allowed anymore for this reply'
                                    }
                                    onMouseOver={() => {
                                        canEdit();
                                    }}
                                    onClick={() => {
                                        if (isEditAllowed === true) {
                                            setIsReplyEditMode(true);
                                        }
                                    }}>
                                    <i className="fas fa-pen"></i>
                                </span>
                            }
                        </div>
                    </DesktopBreakpoint>
                }

            </div>
            {
                replyItem.isEdited === true &&
                <DesktopBreakpoint>
                    <span style={{ marginLeft: '28px' }} className='comment__edit-indicator' > Edited </span>
                </DesktopBreakpoint>
            }
            <div
                style={
                    isMobile
                        ? {
                              display: 'flex',
                              flexDirection: 'column',
                              margin: '12px 0px 12px 64px'
                          }
                        : { display: 'flex', flexDirection: 'column' }
                } 
                className="reply__body-wrapper" >
                <div 
                    className="reply__body" 
                    style={
                        isReplyEditMode 
                        ? { backgroundColor: 'var(--secondary-background-color)' } 
                        : isMobile
                            ? { backgroundColor: 'transparent', padding: '0'} 
                            : { backgroundColor: 'var(--primary-background-color)' }} 
                    >
                    {
                        isReplyEditMode === true ?
                            <div>
                                <MirandaMentionInput
                                    placeholder={'Enter a response here...'}
                                    aValue={editedReply}
                                    setAValue={setEditedReply}
                                    inputFor='reply'
                                />

                                <div className='reply__body-edit-footer'>

                                    <button
                                        className="button button-comment-form--primary"
                                        onClick={handleOnUpdateReply}
                                    >
                                        <span>Save</span>
                                        <div className="tooltip button-comment-form--primary__popover tooltip--bottom">
                                            <span>Post comment</span>
                                        </div>
                                    </button>
                                    <button
                                        className="button button-comment-form--textual"
                                        onClick={() => {

                                            setIsReplyEditMode(false);

                                        }}>
                                        <span>Cancel</span>
                                    </button>
                                </div>


                            </div>
                            :
                            <>
                                <span className="unread-indicator-wrapper" />
                                <MentionLabel commentText={replyItem.content} mentions={replyItem.mentions} />
                            </>
                    }
                </div>
            </div>

            <div style={isMobile? { margin : '0px 20px 20px 44px' } : { marginBottom: '16px' }}>
                <AttachmentViewer attachments={replyItem.attachments}
                    onAttachmentDelete={onAttachmentDelete}
                    onAttachmentClicked={onAttachmentClicked}
                    attachmentFor='reply'
                    showDelete={showAttachmentDelete}
                />
            </div>
            
            <PhoneBreakpoint>
                <div style={{  margin: isLast? '0px' : '0px 20px', height: '1px', background: colorUtilService.changeOpacity(
                        themeColors.primaryTextColor,
                        0.1
                    )
                }}>

                </div>
            </PhoneBreakpoint>
        </div>
    );
};

export default ReplyItem;
