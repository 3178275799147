export const smallTextStyle = {
    fontSize: '12px',
    fontWeight: '400'
};

export const primarySmallTextStyle = {
    ...smallTextStyle,
    color: 'var(--primary-text-color)'
};

export const secondarySmallTextStyle = {
    ...smallTextStyle,
    color: 'var(--secondary-text-color)'
};


export const baseTextStyle = {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight : '18px'
};


export const primaryBaseTextStyle = {
    ...baseTextStyle,
    color: 'var(--primary-text-color)'
};

export const secondaryBaseTextStyle = {
    ...baseTextStyle,
    color: 'var(--secondary-text-color)'
};