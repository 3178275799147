import React, { useState, useEffect } from 'react';

const ProgressiveImg = ({
    placeholderSrc,
    src,
    variants,
    zoom,
    isVisible,
    pageNo,
    onImageLoaded,
    setPlayer, 
    pageSrc,   
    ...props
}) => {
    const [imgSrc, setImgSrc] = useState(placeholderSrc || src);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [variantName, setVariantName] = useState('');

    useEffect(() => {
        changeSrc('main', true);
    }, [src]);
    
    useEffect(() => {        
        changeSrc('main', true);
    }, []);
    
    useEffect(() => {
        if (isVisible === false) {
            return;
        }
        versionSwitcher();
    }, [zoom]);

    useEffect(() => {
        if (isVisible === true) {
            versionSwitcher();
        }
        else {           
            if(variantName !== 'main') {
                changeSrc('main');
            }
        }
    }, [isVisible]);

    const containsVersions = () => {
        if (variants === undefined || variants === null) {
            return false;
        }
        if (variants.length === 1) {
            return false;
        }

        return true;
    };

    const versionSwitcher = () => {
        if (containsVersions() === false) {
            return;
        }

        if (zoom >= 200 && zoom < 400) {
            changeSrc('large');
        } else if (zoom >= 400 && zoom < 600) {
            changeSrc('x-large');
        } else if (zoom >= 600) {
            changeSrc('xx-large');
        }
    };

    const getSrc = (variantName) => {
        if (variantName === 'main') return pageSrc;

        var variant = variants.find((x) => x.variantName === variantName);
        return variant === undefined ? src : variant.src;
    };
    const changeSrc = (variantName, forceChange = false) => {                    
        let newSrc = getSrc(variantName);
    
        if (newSrc === undefined) {
            return;
        }
        if (imgSrc === newSrc && forceChange === false) {
            return;
        }

        const img = new Image();
        img.crossOrigin = 'anonymous';        
        img.src = newSrc;
        img.onload = () => {
            setImgSrc(newSrc);
            setVariantName(variantName);
        };
    };

    const onLoaded = (e) => {
        //NOTE: we need to call image loaded once as
        //other load event should'nt change the canvas setup
        // if(e.currentTarget.src === placeholderSrc) {
        //     return;
        // }

        if (isFirstLoad === true) {
            onImageLoaded();
            setIsFirstLoad(false);
        }
    };
    const customClass =
        placeholderSrc && imgSrc === placeholderSrc
            ? 'progressive-img-loading'
            : 'progressive-img-loaded';
    return (
        <img
            ref={(node) => setPlayer(node)}                                
            className={`image ${customClass} documentImageViewer`}
            data-mainsrc={pageSrc}
            {...{ src: imgSrc, ...props }}
            onLoad={onLoaded}
            alt={props.alt || ''}
        />
    );
};
export default ProgressiveImg;
