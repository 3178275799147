//@ts-check

import React from 'react';

const AudioMutedIcon = ({ height = 20, width = 20, color = 'currentColor' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="20px/Interaction/Multimedia/audio--muted" fill={color}>
                    <rect id="Rectangle" fillOpacity="0" x="0" y="0" width="20" height="20"></rect>
                    <path
                        d="M13.2128985,0.547590649 C13.4627573,0.665171254 13.56999,0.963039733 13.4524094,1.21289852 L5.45240935,18.2128985 C5.38708679,18.351709 5.26611983,18.446499 5.12865413,18.4831762 L5.04450803,18.4980962 L4.95828123,18.4983927 C4.90052068,18.4935655 4.84262566,18.4785384 4.78710148,18.4524094 C4.5372427,18.3348287 4.43001004,18.0369603 4.54759065,17.7871015 L12.5475906,0.787101482 C12.6651713,0.537242697 12.9630397,0.430010044 13.2128985,0.547590649 Z M15,2.5 L15,17.5 C15,17.9192531 14.5150341,18.1523397 14.1876525,17.8904344 L9.324,14 L8.54,13.9990613 L9.011,12.9990613 L9.5,13 C9.58515181,13 9.66840209,13.0217352 9.74210722,13.0625244 L9.81234752,13.1095656 L14,16.459 L14,3.54 L13.138,4.22806135 L14.105,2.17506135 L14.1876525,2.1095656 C14.5150341,1.84766026 15,2.0807469 15,2.5 Z M8.989,5.99906135 L8.519,6.99906135 L5,7 L5,13 L5.696,12.9990613 L5.225,13.9990613 L4.5,14 C4.25454011,14 4.05039163,13.8231248 4.00805567,13.5898756 L4,13.5 L4,6.5 C4,6.22385763 4.22385763,6 4.5,6 L8.989,5.99906135 Z"
                        id="Combined-Shape"
                        fillRule="nonzero"></path>
                </g>
            </g>
        </svg>
    );
};

export default AudioMutedIcon;
