export const providerConstants = {
    GETALL_REQUEST: 'GETALL_PROVIDER_REQUEST',
    GETALL_SUCCESS: 'GETALL_PROVIDER_SUCCESS',
    GETALL_FAILURE: 'GETALL_PROVIDER_FAILURE',

    PROVIDER_SELECTION_REQUEST: 'PROVIDER_SELECTION_REQUEST',
    PROVIDER_SELECTION_SUCCESS: 'PROVIDER_SELECTION_SUCCESS',
    PROVIDER_SELECTION_FAILURE: 'PROVIDER_SELECTION_FAILURE',  

    GETALL_FILTERS_REQUEST: 'PROVIDER_FILTERS_GETALL_REQUEST',
    GETALL_FILTERS_SUCCESS: 'PROVIDER_FILTERS_GETALL_SUCCESS',
    GETALL_FILTERS_FAILURE: 'PROVIDER_FILTERS_GETALL_FAILURE',    
};
