import React, { useEffect, useState } from 'react';
import { history, store, utilService } from '../../../_helpers';
import { withVideoTranscriptionConsumer } from '../VideoAnnotation/Context/VideoAnnotationConsumer';
import { proofService } from '../proof.service';
import { VideoTranscriptionProvider } from './VideoTranscriptionProvider';
import { ProofVideoTranscriptionList } from './Subs/ProofVideoTranscriptionList';
import { transcriptionsMock } from './transcriptions-mock';
import { ProofVideoPlayer } from '../VideoAnnotation/ProofVideoPlayer';
import { videoAnnotationConstants } from '../VideoAnnotation/Context/videoAnnotation.constants';
import { alertActions } from '../../App';
import { apiAccessActions } from '../../Settings';
import { connect } from 'react-redux';
import { videoProofTranscribeActions } from './proofVideoTranscribe.actions';
import scrollIntoView from 'scroll-into-view-if-needed';
import { ThemeProvider, useTheme } from '../../App/Theme/ThemeContext';
import chroma from 'chroma-js';

const { getTranscribeText } = videoProofTranscribeActions;
const ProofVideoTranscription = (props) => {
    return (
        
            <VideoTranscriptionProvider>
                <ProofVideoTranscriptionWrapperHOC {...props}></ProofVideoTranscriptionWrapperHOC>
            </VideoTranscriptionProvider>
       
    );
};

const ProofVideoTranscriptionWrapper = ({
    setVideoPlayer,
    seekToTime,
    onPlayPauseToggle,
    match,
    setCurrentTimeInSeconds,
    dispatch,
    transcribeData,
    status,
    playerColors,
    setPlayerColors
}) => {
    let initialCounter = 0;
    const [proofVersion, setProofVersion] = useState({});
    const [resizeCounter, setResizeCounter] = useState(initialCounter);
    const [currentCaption, setCurrentCaption] = useState('');
    const [timeMode, setTimeMode] = useState(videoAnnotationConstants.TIME_MODE.TIME_CODE);
    const { theme, setCustomTheme } = useTheme();
    useEffect(() => {
        function handleResize() {
            setResizeCounter(initialCounter++);
        }
        updatePlayerColors();
        const { proofid: proofId, versionid: proofVersionId } = match.params;
        const token = utilService.getQueryStringValue(
            'token',
            window.location.search,
            window.location.href
        );

        if (!proofId || !proofVersionId) {
            history.push('./dashboard/folders');
            return;
        }
        store.dispatch(alertActions.hideTopbar(true));
        async function fetchInitData() {
            const result = await store.dispatch(apiAccessActions.validateToken(token));
            store.dispatch(alertActions.loading(true));
            let proofVersions = await proofService.getProofVersions(proofId);
            if (!proofVersions) {
                history.push('./dashboard/folders');
                return;
            }
            var proofVersionsArr = Object.values(proofVersions);

            let proofVersion = { ...proofVersions[proofVersionId] };
            setProofVersion(proofVersion);
            dispatch(getTranscribeText(proofId, proofVersionId));
            window.addEventListener('resize', handleResize);
            store.dispatch(alertActions.loading(false));
        }
        fetchInitData().then(() => {
            console.log('FETCHED');
        });
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const updatePlayerColors = () => {
        const backgroundColor = utilService.getQueryStringValue(
            'backgroundColor',
            window.location.search,
            window.location.href
        );
        const primaryColor = utilService.getQueryStringValue(
            'primaryColor',
            window.location.search,
            window.location.href
        );
        const primaryTextColor = utilService.getQueryStringValue(
            'primaryTextColor',
            window.location.search,
            window.location.href
        );
        const primaryAccentColor = utilService.getQueryStringValue(
            'primaryAccentColor',
            window.location.search,
            window.location.href
        );

        let updatedPlayerColors = {
            backgroundColor: backgroundColor ? `#${backgroundColor}` : playerColors.backgroundColor,
            primaryColor: primaryColor ? `#${primaryColor}` : playerColors.primaryColor,
            primaryTextColor: primaryTextColor
                ? `#${primaryTextColor}`
                : playerColors.primaryTextColor,
            primaryAccentColor: primaryAccentColor
                ? `#${primaryAccentColor}`
                : playerColors.primaryAccentColor
        };
    
        var themeReq = {
            primaryColor: updatedPlayerColors.primaryAccentColor,
            primaryBackgroundColor: updatedPlayerColors.backgroundColor,
            secondaryBackgroundColor: updatedPlayerColors.primaryColor,
            primaryTextColor: updatedPlayerColors.primaryTextColor,
            secondaryTextColor: updatedPlayerColors.primaryTextColor,
            iconTextColor: updatedPlayerColors.primaryTextColor,           
        }

        if (updatedPlayerColors.primaryColor) {
            const tertiaryBackground = chroma(themeReq.secondaryBackgroundColor).darken(0.25).hex();
            themeReq.tertiaryBackgroundColor =  tertiaryBackground;            
        }
                
        setCustomTheme(themeReq);
    };

    const handleOnPlayerTimeUpdate = (currentTimeInSeconds) => {
        currentTimeInSeconds =Number(Number(currentTimeInSeconds).toFixed(2));
        let currentItem = transcribeData?.find(
            (x) => currentTimeInSeconds >= x.timeRange[0] && currentTimeInSeconds <= x.timeRange[1]
        );
        let currentCaption = currentItem ? currentItem.value : '';
        let currentTimeRangeId = currentItem
            ? `tr-${currentItem.timeRange[0]}-${currentItem.timeRange[1]}`
            : '';
        let currentDOMItem = document.getElementById(currentTimeRangeId);
        if (currentDOMItem) {
            scrollIntoView(currentDOMItem, {
                behavior: 'smooth',
                scrollMode: 'if-needed'
            });
        }

        setCurrentTimeInSeconds(currentTimeInSeconds);
        setCurrentCaption(currentCaption);
    };
    // console.log('transcribe data', transcribeData);
    return (
        <div
            className="mrnda-video-transcription">
            <div className="mrnda-video-transcription__container">
                <div style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
                    <div className="mrnda-video-transcription__player-area">
                        <ProofVideoPlayer
                            version="A"
                            resizeCounter={resizeCounter}
                            proofVersion={proofVersion}
                            setVideoPlayer={(videoPlayer) => {
                                setVideoPlayer(videoPlayer);
                            }}
                            onVideoPlay={onPlayPauseToggle}
                            onVideoPause={onPlayPauseToggle}
                            seekToTime={(frame) =>
                                seekToTime(frame, proofVersion?.fileMetadata?.frameRate)
                            }
                            timeMode={timeMode}
                            onTimeModeChange={setTimeMode}
                            onPlayerTimeUpdate={handleOnPlayerTimeUpdate}
                            showCaption={true}
                            currentCaption={currentCaption}
                            setVideoCanvas={() => {}}
                            playerColors={playerColors}
                            initialVolume={0.30}
                            overrideTheme= {false}
                        />
                    </div>
                    <ProofVideoTranscriptionList
                        proofVersion={proofVersion}
                        transcriptions={transcribeData || []}
                        status={status}
                        onItemClick={(timeRange) => {
                            let frameNumber = timeRange[0] * proofVersion?.fileMetadata?.frameRate;
                            seekToTime(frameNumber, proofVersion?.fileMetadata?.frameRate);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

const ProofVideoTranscriptionWrapperHOC = withVideoTranscriptionConsumer(
    ProofVideoTranscriptionWrapper
);

function mapStateToProps(state) {
    // const { videoComments, proofVersion } = state.videoProofSummaryData;

    // const proofVersionSummary = proofVersion ? { ...proofVersion, versionCount: proofVersion.versionCount } : { proofVersion: {}, versionCount: 0 };
    // const comments = videoComments;
    // const fileMetadata = proofVersion ? proofVersion.proofVersion.fileMetadata : {};
    //console.log('transcribeData', state.videoProofTranscribeData);
    return {
        status: state.videoProofTranscribeData?.transcribeResult?.status,
        transcribeData: Array.isArray(
            state.videoProofTranscribeData.transcribeResult?.transcribeData
        )
            ? state.videoProofTranscribeData.transcribeResult.transcribeData
            : []
    };
}

const proofVideoTranscriptionHOC = connect(mapStateToProps)(ProofVideoTranscription);

export {
    proofVideoTranscriptionHOC as ProofVideoTranscription,
    ProofVideoTranscriptionWrapperHOC as ProofVideoTranscriptionWrapper
};
