import React, { useEffect, useRef, useState } from 'react';
import VideoControl from './VideoControl';
import { store } from '../../../_helpers';
import { alertActions } from '../../App';
import { volumeHelper } from '../../../_helpers/volumeHelper';
import { VideoPlayerShortcuts } from '../VideoAnnotation/Subs/VideoPlayerShortcuts';
import { videoAnnotationConstants } from '../VideoAnnotation/Context/videoAnnotation.constants';

const ProofViewerVideo = ({ proofVersion }) => {
    var { fileMetadata } = proofVersion;
    const [currentTimeInSeconds, setCurrentTimeInSeconds] = useState(0);
    const [volume, setVolume] = useState(0);
    const [isPaused, setIsPaused] = useState(true);
    const player = useRef(null);
    useEffect(() => {
        store.dispatch(alertActions.loading(true))
        setCurrentTimeInSeconds(0);
        setIsPaused(true);
    }, [proofVersion.id]);

    useState(() => {
        volumeHelper.get().then((volume) => {
            console.log('videoVolume :' + volume);
            setVolume(volume);
        });
        
    }, []);
    
    const onLoadedVideoData = () => {
        player.current.volume = volume;
        store.dispatch(alertActions.loading(false))
    };

    const onTimeUpdate = (event) => {
        // var currentTime = Math.round(this.player.currentTime());
        var currentTime = player.current.currentTime;
        setCurrentTimeInSeconds(currentTime);
    };

    const onVolumeChange = (value) => {
        player.current.volume = value;
        volumeHelper.set(value);
        setVolume(value);  

    };

    const onPlayPause = () => {
        if (player.current.paused) {
            player.current.play();
            setIsPaused(false);
        } else {
            player.current.pause();
            setIsPaused(true);
        }
    };
    const onProgressClick = (event) => {
        const currentTimeInSeconds =
            (event.nativeEvent.offsetX / event.currentTarget.offsetWidth) *
            fileMetadata.durationInSeconds;
        player.current.currentTime = currentTimeInSeconds;
        setCurrentTimeInSeconds(currentTimeInSeconds);
    };


    const onLeftArrowPressed  = () => {                    
        changeSeekBarPosition(videoAnnotationConstants.SEEKBAR_DIRECTION.backward);      
    }

    const onRightArrowPressed = () => {        
        changeSeekBarPosition(videoAnnotationConstants.SEEKBAR_DIRECTION.forward);      
    }

    const onSpacePressed = () => {    
        onPlayPause();
    }

    const changeSeekBarPosition = (direction) =>  {
      
        const { TIME, TIME_FRAME, TIME_CODE } = videoAnnotationConstants.TIME_MODE;

        let value = 1;        
        if(direction === videoAnnotationConstants.SEEKBAR_DIRECTION.forward) {
            value = 0.5;
        }
        else {
            value = -0.5;
        }

        var newCurrentFrame = currentTimeInSeconds + value;
        if (value < 0 && newCurrentFrame < 0) {
            newCurrentFrame = 0;          
        }
        setCurrentTimeInSeconds(newCurrentFrame);
        player.current.currentTime = newCurrentFrame;
    }

    return (
        <div           
            className="d-flex flex-column justify-content-between">
            
            <VideoPlayerShortcuts spaceHandler={onSpacePressed} leftArrowHandler = {onLeftArrowPressed} rightArrowHandler = {onRightArrowPressed} />
            
            <div style={{ height: 'calc(100% - 124px)' }} className="d-flex flex-column justify-content-center">
                <video
                    key={fileMetadata.fileURL}
                    ref={player}
                    crossOrigin="anonymous"
                    className="mrnda-video-editor-area-videoplayer-wrapper--video"
                    style={{
                        width: '100%',
                        height: '100%'
                    }}
                    preload="auto"
                    data-setup="{}"
                    onTimeUpdate={onTimeUpdate}
                    onLoadedData={onLoadedVideoData}
                    onPlay={() => setIsPaused(false)}
                    onPause={() => setIsPaused(true)}
                    onEnded={() => setIsPaused(true)}>
                    <source src={fileMetadata.fileURL} type={fileMetadata.mimeType} />
                </video>
            </div>
            <VideoControl                
                fileMetadata={fileMetadata}
                volume={volume}
                onVolumeChange={onVolumeChange}
                isPaused={isPaused}
                currentTimeInSeconds={currentTimeInSeconds}
                onPlayPause={onPlayPause}
                onProgressClick={onProgressClick}                
            />
        </div>
    );
};

export default ProofViewerVideo;
