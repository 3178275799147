import React, { useEffect, useState } from 'react';
import { mentionHelper, COMMENT_TEXT_TYPE } from '../../../../../_helpers/mentionHelper';
import { COMMENT_VIEW_CHAR_LIMIT, COMMENT_VIEW_CHAR_LIMIT_PHONE } from '../../../proof.constants';
import { uuidv4 } from '../../../VideoAnnotation/VideoAnnotationUtil';
import { useScreenSizes } from '../../../../responsive/useScreenSizes';

const MentionLabel = ({ commentText, mentions }) => {
    const [processed, setProcessed] = useState([]);
    const [plainText, setPlainText] = useState('');
    const [collapseText, setCollapseText] = useState(true);
    const {isMobile} = useScreenSizes();
    const collapsedCharacterLength = isMobile? COMMENT_VIEW_CHAR_LIMIT_PHONE : COMMENT_VIEW_CHAR_LIMIT;
    let textLength = 0;
    useEffect(() => {
        var plain = commentText;
        var p = mentionHelper.processLabelValue(plain, mentions);       
        setProcessed(p);
        var plainWithMention = p?.map((x) => x.value)?.reduce((x, y) => x + y) || '';
        setPlainText(plainWithMention);
    }, [commentText]);

    const renderSegment = (value, type) => {
        let spanValue = '';
        let spanStyle = {};

        if (type === COMMENT_TEXT_TYPE.text) {
            spanValue = value;
        } else {
            spanValue = value;
            spanStyle.color = 'var(--primary-color)';
        }
        
        return <span key={uuidv4()} style={spanStyle}>{spanValue}</span>;
    };

    const renderSegments = () => {
        var renderedTextLength = 0;
        var segments = [];
        let showExpandButton = false;

        for (let i = 0; i < processed.length; i++) {
            let item = processed[i];
            if(item.value === null){
                continue;
            }
            if (collapseText && renderedTextLength > collapsedCharacterLength) {
                break;
            }

            let value = item.value;
            let availableSpace = collapsedCharacterLength - renderedTextLength;


            if (value.length > availableSpace) {
                showExpandButton = true;
            } 

            if (value.length > availableSpace && collapseText) {
                value = value.substring(0, availableSpace);                
            }

            var segment = renderSegment(value, item.type, );
            segments.push(segment);
            renderedTextLength += value.length;
        }

        if (showExpandButton === true) {
            segments.push(
                <span
                    key={uuidv4()}
                    onClick={() => {
                        console.log('expand text clicked');
                        setCollapseText(!collapseText);
                    }}
                    style={{
                        background: 'var(--control-border-color)',
                        marginLeft: '6px',
                        color: 'var(--primary-color)'
                    }}>
                    {' '}
                    {collapseText === true? '+' : '-'}{' '}
                </span>
            );
        }
        return segments;
    };
    return <span className="comment-text">{renderSegments()}</span>;
};

export default MentionLabel;
