
const MAX_LIMIT_TITLE = 'Max limit reached';
const maxLimitBody = (max) =>
    `Cannot show more than ${max} statuses. Uncheck another status to enable this one.`;

const MAX_CHAR_LIMIT = 35;

const MIN_STATUS_COUNT = 1;
const MIN_STATUS_TITLE = 'Cannot delete this status';
const MIN_STATUS_BODY = 'At least one status need to be present here.';

const STATUS_EXISTS_TITLE = 'Status Already Exists';
const STATUS_EXISTS_BODY = 'Status with this label already exists!';

const MIN_VISIBLE_COUNT = 1;
const MIN_VISIBLE_TITLE = 'Cannot hide this status';
const MIN_VISIBLE_BODY = 'At least one status need to be visible.';
const MAX_CHAR_LIMIT_TEXT = 'Max 35 characters can be added';
const MIN_WORKFLOW_COUNT = 0;

const statusExistsText = (statusType, status) => `The ${statusType.label.toLowerCase()} status '${status}' already exists in the list.` 
const cannotDeleteTitle = (status) => `Cannot delete ${status.toLowerCase()} status`;
const cannotDeleteBody = (status, section) =>
    `Cannot delete ${status.toLowerCase()} status because the status is already used in ${section}`;

const initialValidationState = {
    inputStatus: {
        validated: true,
        message: ''
    }
};


const Cannot_Delete_Workflow_Title = `Cannot delete workflow`;

const Cannot_Delete_Workflow_Body = 
    `Cannot delete workflow because the workflow is already used in file versions`;


const StatusType = { 
    comment : { type : 0, label : 'Annotation Comment' },
    file :  {type : 1, label : 'Workflow'},    
}

export {
    cannotDeleteTitle,
    cannotDeleteBody,
    Cannot_Delete_Workflow_Body,
    Cannot_Delete_Workflow_Title,
    MAX_LIMIT_TITLE,
    maxLimitBody,
    MAX_CHAR_LIMIT,
    MIN_STATUS_COUNT,
    MIN_STATUS_TITLE,
    MIN_STATUS_BODY,
    initialValidationState,
    STATUS_EXISTS_TITLE,
    STATUS_EXISTS_BODY,
    MIN_VISIBLE_COUNT,
    MIN_VISIBLE_TITLE,
    MIN_VISIBLE_BODY,
    MAX_CHAR_LIMIT_TEXT,
    statusExistsText,
    StatusType,
    MIN_WORKFLOW_COUNT
};
