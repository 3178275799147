import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { LiveHTMLSupportedBrowsers } from '../../Proof';
import { version } from 'less';
import ChromeIcon from '../Icon/BrowserIcons/ChromeIcon';
import EdgeIcon from '../Icon/BrowserIcons/EdgeIcon';
import FirefoxIcon from '../Icon/BrowserIcons/FireFoxIcon';
import ModalHeader from './ModalHeader';

function BrowserSupportModal({ show , onSecondaryClick, onPrimaryClick  }) {
    const renderIcon = (name) => {
        switch (name) {
            case 'Chrome': {
                return <ChromeIcon />
            }
            case 'Edge': {
                return <EdgeIcon />
            }
            case 'FireFox': {
                return <FirefoxIcon />
            }
        }
    }
    return (
        <Modal
            show={show}
            onHide={onSecondaryClick}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered            
            backdrop={'static'}
            scrollable>
            <Modal.Header style={{
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: '12px',
                    paddingBottom: '12px'
                }}>
                <ModalHeader title={'Browser not supported'} onSecondaryClick={onSecondaryClick} />
            </Modal.Header>
            <Modal.Body >
                <div style={{ margin: '4px'}}>
                    The browser version you are currently using is not supported. 
                    <div>
                        Please upgrade your browser to start collaborating.
                        <div style={{ display: 'flex', marginLeft: '-12px', marginRight: '-12px' }}>
                            {
                                LiveHTMLSupportedBrowsers.map((browser, index) =>
                                    <div key={index} style={{
                                        width: '174.667px',
                                        height: '158px', display: 'flex',
                                        marginLeft: '12px', marginRight: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '16px',
                                        backgroundColor: 'var(--secondary-background-color)', flexDirection: 'column'
                                    }}
                                    
                                    >
                                        {renderIcon(browser.name)}
                                        {browser.name}
                                    </div>
                                )

                            }
                        </div>

                    </div>     
                </div>
            </Modal.Body>           
        </Modal>
    );
}

export default BrowserSupportModal;
