//@ts-check
import React, { useState, useEffect, useRef } from 'react';
import { tenantService } from '../Tenant';
import { userService } from '../User/user.service';
import { store } from '../../_helpers';
import { alertActions } from '../App';
import { MultiSelect, TEXT_RECOGNITION_SETTINGS_TYPES } from '..';
import { useLiveUpdate } from '../App/LiveUpdate/LiveUpdateContext';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FILE_SHARE_SETTINGS_TYPES } from '../_shared/fileshare.constants';
import { EMAIL_SETTINGS_TYPES } from '../_shared/email.constants';
import { DataFetcher } from '../_shared/DataFetcher/DataFetcher';
import CustomEmailTemplateBuilder from './Custom-Email/CustomEmailTemplateBuilder';
import CustomEmailTemplate from './Custom-Email/CustomEmailTemplate';
import CustomEmailApiCallBack from './Custom-Email/CustomEmailApiCallBack';
import CustomEmailTemplatePreview from './Custom-Email/CustomEmailTemplatePreview';

import {
   
    ConfirmationModal,
   } from '../../components';

const comparisonTypes = [
    {
        label: 'Customized Email Template',
        value: 0,
        tooltip: 'Compare files of selected folder and its subfolders'
    },
    { label: 'API Callback', value: 1, tooltip: 'Compare versions of selected file' }
];

export const EmailSettingManage = ({ onClose }) => {
    const [emailSettings, setEmailSettings] = useState({
        emailSettingType: 0,
        customizedEmailType: 0,
        customTemplateFooterUrl:
            'https://s3.ap-southeast-1.amazonaws.com/mirandaproof.com/assets/Images/Email/footer.png',
        customTemplateHeaderUrl:
            'https://s3.ap-southeast-1.amazonaws.com/mirandaproof.com/assets/Images/Email/header.png',
        callbackUrl:
            'https://webhook.site/#!/cdf37f5f-0e46-45ab-8ece-92761c04f237/99a0788c-f1a2-4425-b681-7ef29da5cf35/1',
        emailSignature: ''
    });
    const [selectedComparisonType, setSelectedComparisonType] = useState(comparisonTypes[0]);
    const { changeTarget, setSettingsListeners } = useLiveUpdate();
    const [saveAllowed, setSaveAllowed] = useState(false);
    const proofSettingsRef = useRef({});
    const templatesUploaderRef = useRef([]);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showDefaultModal, setShowDefaultModal ] = useState(false);

    useEffect(() => {
        userService.getCurrentUser().then((data) => {
            //liveUpdateConfig(data.authData.roleId, data.authData.id);
        });

        return () => {};
    }, []);

    const onEmailSettingTypeChange = (type) => {
        if(type.value === EMAIL_SETTINGS_TYPES.default.value){            
            if (type.value !== emailSettings.emailSettingType)
            setShowDefaultModal(true);
        }
        
        setEmailSettings({
            ...emailSettings,
            emailSettingType: type.value
        });
    };

    useEffect(() => {
        console.log('emailSettings');
        submitValidation(emailSettings);
    }, [emailSettings]);

    const fetchData = async () => {
        let proofSettings = await tenantService.getEmailSettings();
        proofSettings.fileShareSettingType =
            proofSettings?.fileShareSettingType !== null ||
            proofSettings?.fileShareSettingType === undefined
                ? proofSettings?.fileShareSettingType
                : FILE_SHARE_SETTINGS_TYPES.registered.value;
        setEmailSettings((prev) => {
            return { ...prev, ...proofSettings };
        });
        var selected = comparisonTypes.find((x) => x.value == proofSettings.customizedEmailType);
        setSelectedComparisonType(selected);
    };

    const onSave = async () => {
        store.dispatch(alertActions.loading(true));        
       
        await tenantService.updateEmailSettings(emailSettings);
        await fetchData();
        store.dispatch(alertActions.loading(false));        
        store.dispatch(alertActions.success('Saved !'));        
    };

    const onPreview = async () => {        
        // var settings = {...emailSettings};
        // settings.customTemplateFooterUrl = "https://s3.ap-southeast-1.amazonaws.com/mirandaproof.com/assets/Images/Email/footer.png";
        // settings.customTemplateHeaderUrl = "https://s3.ap-southeast-1.amazonaws.com/mirandaproof.com/assets/Images/Email/header.png";

        // if (emailSettings.emailSettingType === 1 && emailSettings.customizedEmailType === 0) {
        //     let value = getSignatureValue();
        //     settings.emailSignature = value;
        // }
        //var body = await tenantService.getEmailTemplatePreview();
      
        // var tt = templatesUploaderRef;
        // var uploader = tt.current[0];
        // uploader();
        setShowPreviewModal(true);
    };

    const getSignatureValue = () => {
        console.log('getSignatureValue called!');
        return $('#description').val().toString();
    };
    const updateUploadHandlers = (handler) => {
        var newArr = [...templatesUploaderRef.current, handler];
        templatesUploaderRef.current = newArr;
    };

    const validateCustomizedTemplateSettings = (emailSettings) => {
        if (isNullOrEmpty(emailSettings.customTemplateHeaderUrl)) {
            return false;
        }

        if (isNullOrEmpty(emailSettings.customTemplateFooterUrl)) {
            return false;
        }

        if (isNullOrEmpty(emailSettings.emailSignature)) {
            return false;
        }

        return true;
    };

    const validateApiCallback = () => {

        if(emailSettings.callbackInvalid === true) {
            return false;
        }

        return !isNullOrEmpty(emailSettings.callbackUrl);
    };

    const isNullOrEmpty = (value) => {
        return value === undefined || value === null || value === '';
    };

    const submitValidation = (emailSettings) => {
        let allowed = false;

        if (emailSettings.emailSettingType === EMAIL_SETTINGS_TYPES.default.value) {
            allowed = true;
        } else {
            if (emailSettings.customizedEmailType === 0) {
                allowed = validateCustomizedTemplateSettings(emailSettings);
                
            } else if (emailSettings.customizedEmailType === 1) {
                allowed = validateApiCallback();
            }
        }

        console.log('ÍS ALLOWED', allowed);
        setSaveAllowed(allowed);
    };

    const defaultCanceled  = () => {
            
        setEmailSettings({
            ...emailSettings,
            emailSettingType: EMAIL_SETTINGS_TYPES.custom.value
        });

        setShowDefaultModal(false);   

    }

    const defaultSaved  = async () => {
        await onSave();
        setShowDefaultModal(false);   
    }



    return (
        <>
            <div className="settings-view__manage__container">
                <div className="m-2" style={{}}>
                    <div className="settings-view-modal">
                        <div style={{ minHeight: '80%', height: '100%', fontSize: '13px' }}>
                            <button
                                id="editor-close-btn"
                                type="button"
                                className="close modal-close"
                                title="Close Modal"
                                onClick={onClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h3 className="">Manage Email Related Settings</h3>
                            <hr></hr>
                            <DataFetcher height={'476px'} onFetchData={fetchData}>
                                <div className="manage-email-settings">
                                    <div className={`form-group flex-column mb-4 d-flex`}>
                                        <label htmlFor="link">Email Settings</label>
                                        <p className="mb-1">Select email settings</p>
                                        <div style={{ width: '100%', marginTop: '12px' }}>
                                            <ButtonGroup>
                                                {Object.keys(EMAIL_SETTINGS_TYPES).map((key, i) => {
                                                    const type = EMAIL_SETTINGS_TYPES[key];
                                                    return (
                                                        <Button
                                                            key={type.value}
                                                            title={type.tooltip}
                                                            style={{
                                                                backgroundColor:
                                                                    type.value ===
                                                                    emailSettings?.emailSettingType
                                                                        ? 'var(--primary-color)'
                                                                        : 'var(--secondary-background-color)',
                                                                color:
                                                                    type.value ===
                                                                    emailSettings?.emailSettingType
                                                                        ? 'white'
                                                                        : 'var(--primary-text-color)'
                                                            }}
                                                            variant="secondary"
                                                            onClick={() =>

                                                                onEmailSettingTypeChange(type, key)
                                                            }>
                                                            {type.label}
                                                        </Button>
                                                    );
                                                })}
                                            </ButtonGroup>
                                        </div>
                                    </div>

                                    {emailSettings.emailSettingType === 1 && (
                                        <>
                                            <div
                                                style={{ maxWidth: 350, marginTop: '4px' }}
                                                className="form-group">
                                                <label htmlFor="compare"> Customized Type</label>

                                                <div
                                                    style={{ marginTop: '8px' }}
                                                    className="themed-select">
                                                    <select
                                                        id="comparisonTypesSelect"
                                                        className=""
                                                        name="comparisonTypesSelect"
                                                        value={selectedComparisonType.value}
                                                        onChange={(event) => {
                                                            var index =
                                                                event.nativeEvent.target
                                                                    .selectedIndex;
                                                            var selectedOption =
                                                                comparisonTypes[index];
                                                            setEmailSettings({
                                                                ...emailSettings,
                                                                customizedEmailType:
                                                                    selectedOption.value
                                                            });
                                                            setSelectedComparisonType(
                                                                selectedOption
                                                            );
                                                        }}>
                                                        {comparisonTypes.map((c) => (
                                                            <option
                                                                title={c.tooltip}
                                                                key={c.value}
                                                                value={c.value}>
                                                                {c.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            {emailSettings.customizedEmailType === 0 && (
                                                <CustomEmailTemplate
                                                    templatesUploaderRef={updateUploadHandlers}
                                                    setEmailSettings={setEmailSettings}
                                                    emailSettings={emailSettings}
                                                    submitValidation={submitValidation}
                                                />
                                            )}
                                            {emailSettings.customizedEmailType === 1 && (
                                                <CustomEmailApiCallBack
                                                    emailSettings={emailSettings}
                                                    setEmailSettings={setEmailSettings}
                                                />
                                            )}
                                        </>
                                    )}

                                    {
                                        emailSettings.emailSettingType === 1 &&
                                        <div
                                        className="modal-footer justify-content-start pl-0"
                                        style={{ flex: 'auto', alignItems: 'end', border: 'none' }}>
                                        

                                        <button
                                            style={{ marginBottom: '20px' }}
                                            className="btn mrnda-btn"
                                            disabled={!saveAllowed}
                                            onClick={onSave}>
                                            Save
                                        </button>

                                        { emailSettings.customizedEmailType === 0 && 
                                            <button
                                            style={{ marginBottom: '20px' }}
                                            className="mrnda-btn--secondary" 
                                            disabled={!saveAllowed}
                                            onClick={onPreview}>
                                            Preview
                                            </button>
                                        }
                                        
                                       
                                        <button  
                                            style={{ marginBottom: '20px' }}
                                            className="mrnda-btn--secondary" 
                                            onClick={onClose}>
                                            Close
                                        </button>
                                        </div>
                                    }
                                    
                                </div>
                            </DataFetcher>
                        </div>
                    </div>
                </div>
                {showPreviewModal && (                    
                    <CustomEmailTemplatePreview
                        onClose={() => setShowPreviewModal(false)}
                        show={showPreviewModal}
                        emailSettings = {emailSettings}                        
                    />
                )}
                
                {showDefaultModal && (
                    <ConfirmationModal
                        show
                        onHide={defaultCanceled}
                        onPrimaryClick={defaultSaved}
                        onSecondaryClick={defaultCanceled}
                        primaryButtonText="Yes"
                        secondaryButtonText="No"
                        title="Change to Default Email Settings"
                        bodyText="Are you sure you want to switch to the default email settings? It will discard your current custom template."
                    />
                )}
            </div>
        </>
    );
};
