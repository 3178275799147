//@ts-check

import React from 'react';

const RectIcon = ({ height = 60, width = 60, color = 'currentColor' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 56 64"
            fill="none">
            <path
                d="M36.5714 22.5714C37.0429 22.5714 37.4286 22.9571 37.4286 23.4286V40.5714C37.4286 41.0429 37.0429 41.4286 36.5714 41.4286H19.4286C18.9571 41.4286 18.5714 41.0429 18.5714 40.5714V23.4286C18.5714 22.9571 18.9571 22.5714 19.4286 22.5714H36.5714ZM19.4286 20C17.5375 20 16 21.5375 16 23.4286V40.5714C16 42.4625 17.5375 44 19.4286 44H36.5714C38.4625 44 40 42.4625 40 40.5714V23.4286C40 21.5375 38.4625 20 36.5714 20H19.4286Z"
                fill={color}                
            />
        </svg>
    );
};

export default RectIcon;
