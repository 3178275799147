import React, { useState, useRef } from 'react';
import { useTheme } from '../../App/Theme/ThemeContext';
import AudioLoudIcon from '../../_shared/Icon/AudioLoudIcon';
import AudioMutedIcon from '../../_shared/Icon/AudioMutedIcon';
import AudioQuietIcon from '../../_shared/Icon/AudioQuietIcon';
import PauseIcon from '../../_shared/Icon/PauseIcon';
import PlayIcon from '../../_shared/Icon/PlayIcon';
import { readableTime } from '../VideoAnnotation/VideoAnnotationUtil';


const VideoControl = ({
    fileMetadata,
    volume,
    onVolumeChange,
    onPlayPause,
    isPaused,
    currentTimeInSeconds,
    onProgressClick
    
}) => {
    const progressBar = useRef(null);
    const [displayToolTip, setDisplayToolTip] = useState(false);
    const [toolTipLeftPosition, setToolTipLeftPosition] = useState('0px');
    const [toolTipTimeInText, setToolTipTimeInText] = useState('00:00:00');
    const {theme} = useTheme();
    const bubblePop = (e) => {
        var cursorPos =
            (e.nativeEvent.clientX - progressBar.current.getBoundingClientRect().left) /
            progressBar.current.offsetWidth;
        var seekTime = cursorPos * fileMetadata?.durationInSeconds;

        if (seekTime) {
            var innerHTML = readableTime(seekTime);
            var styleleft =
                e.nativeEvent.clientX - progressBar.current.getBoundingClientRect().left + 0 + 'px';

            setDisplayToolTip(true);
            setToolTipLeftPosition(styleleft);
            setToolTipTimeInText(innerHTML);
        }
    };
    let volumeIconClass = 'fas fa-volume-up';
    if (volume < 0.5 && volume > 0) {
        volumeIconClass = 'fas fa-volume-down';
    } else if (volume < 0.01) {
        volumeIconClass = 'fas fa-volume-mute';
    } else {
        volumeIconClass = 'fas fa-volume-up';
    }
    return (
        <div className="mrnda-video-editor-area-controls"             
            >
            <div className="mrnda-video-viewer-area-timeline">
                <progress
                    ref={progressBar}                    
                    className={theme === 'light'? "mrnda-video-viewer-area-controls-seekbar-light" :  "mrnda-video-viewer-area-controls-seekbar"}
                    max={fileMetadata?.durationInSeconds}
                    value={currentTimeInSeconds}
                    onClick={onProgressClick}
                    onMouseMove={bubblePop}
                    onMouseOut={() => {
                        setDisplayToolTip(false);
                    }}
                />
                <div
                    className="mrnda-video-editor-area-controls-seekbar-tooltip"
                    id="video-player-tooltip"
                    style={{
                        left: toolTipLeftPosition,
                        display: displayToolTip ? 'block' : 'none'
                    }}>
                    {toolTipTimeInText}
                </div>
            </div>
            <div style={{border : 'none', padding: 0, marginBottom: 10}} className="mrnda-video-editor-area-controls-buttons-wrapper">
                <div className="mrnda-video-editor-area-controls-button--timers">
                    <label
                        style={{                            
                            fontFamily: 'Consolas',
                            fontSize: 14,
                            width: '100%',
                            letterSpacing: '-.7px'
                        }}>
                        {`${readableTime(currentTimeInSeconds)} / ${readableTime(
                            fileMetadata?.durationInSeconds
                        )}`}
                    </label>
                </div>
                <div
                    className="mrnda-video-editor-area-controls-buttons"
                    style={{ display: 'flex' }}>
                    <span
                        className={'mrnda-video-editor-area-controls-buttons--play-pause'}                        
                        onClick={() => onPlayPause()}>                            
                        {isPaused ? <PlayIcon/> : <PauseIcon/>}
                    </span>
                </div>
                <div className="mrnda-video-editor-area-controls-sides">
                    <span
                        className='mrnda-video-editor-area-controls-sides--volume-icon'
                        
                        >
                        {
                            volume == 0 ? <AudioMutedIcon/> : volume < 0.5 ? <AudioQuietIcon/> : <AudioLoudIcon/>                        
                        }
                    </span>                    
                    <span className="d-flex flex-column">
                        <input
                            type="range"
                            name="volume"
                            className="mrnda-video-editor-area-controls-sides--volume"
                            min="0"
                            max="1"
                            step="0.05"
                            value={volume}
                            onChange={(event) => onVolumeChange(event.currentTarget.value)}
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default VideoControl;
