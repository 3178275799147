import React, { useEffect, useState } from 'react';
import { EditorToolbarActionButton } from './EditorToolbarActionButton';
import AudioMutedIcon from '../../../_shared/Icon/AudioMutedIcon';
import AudioLoudIcon from '../../../_shared/Icon/AudioLoudIcon';
import { volumeHelper } from '../../../../_helpers/volumeHelper';

export const EditorToolbarMuteButton = ({videoPlayer}) => {
    const [isMuted, setIsMuted] = useState();    
    const handleAudioClicked = () => {
       
        if(videoPlayer === undefined || videoPlayer === null) {
            return;
        }

        let toggled = !isMuted;
        volumeHelper.setIsMuted(toggled);
        setIsMuted(toggled);
        videoPlayer.muted = toggled;
    };
    useEffect(() => {
        if (videoPlayer ===  undefined || videoPlayer === null) {
            return;
        }

        initButton();
        
    }, [videoPlayer]); 
    

    const initButton = async () => {     
        
        let isMuted = await volumeHelper.getIsMuted();
        
        let volume = await volumeHelper.get();
        if (volume == 0) {
            isMuted = true;
        }

        setIsMuted(isMuted);
        videoPlayer.muted = isMuted;
    };

    return (                               
        <EditorToolbarActionButton
            handleClicked={handleAudioClicked}
            icon={isMuted === true ? <AudioMutedIcon /> : <AudioLoudIcon />}
        />
    );
};
