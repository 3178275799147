import {
    authHeader,
    config,
    handleResponse
} from '../../../../_helpers';

export const workflowStatusService = {    
    getAllStatus,
    addStatus,
    updateStatus,
    updateStatusPriority,
    deleteStatus,
    canDeleteStatus,
    getAllStatusByWorkflow,
    updateVisibility,
    updateWorkflowStatusNotificationGroup
};

async function getAllStatus(includeHidden) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${config.apiUrl}/workflowStatus/getAllStatus?includeHidden=${includeHidden}`, requestOptions).then(handleResponse);
}

async function addStatus(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/workflowStatus/AddStatus`, requestOptions).then(handleResponse);
}

async function updateStatus(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/workflowStatus/updateStatus`, requestOptions).then(handleResponse);
}


async function updateVisibility(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/workflowStatus/updateVisibility`, requestOptions).then(handleResponse);
}



async function updateStatusPriority(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/workflowStatus/updateStatusPriority`, requestOptions).then(handleResponse);
}

async function deleteStatus(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/workflowStatus/deleteStatus`, requestOptions).then(handleResponse);
}

async function canDeleteStatus(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/workflowStatus/canDeleteStatus`, requestOptions).then(handleResponse);
}

async function getAllStatusByWorkflow(workflowId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${config.apiUrl}/workflowStatus/GetAllStatusByWorkflow?workflowId=${workflowId}`, requestOptions).then(handleResponse);
}

async function updateWorkflowStatusNotificationGroup(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/workflowStatus/updateWorkflowStatusNotificationGroup`, requestOptions).then(handleResponse);
}