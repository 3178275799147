import React from 'react';
import { connect } from 'react-redux';
import {
    UserManage,
    GroupManage,
    RoleManage,
    DirectoryPermissionManage,
    APIAccessManage,
    EmailDomainManage
} from '../../components';
import './SettingsPage.css';
import { ProofSettingManage } from './ProofSettingManage';
import { LoginSettingManage } from './LoginSettingManage';
import SettingsHeader from './SettingsHeader';
import { EmailSettingManage } from './EmailSettingManage';
import { StatusManage } from './CustomStatus/StatusManage';
import { CommentStatusManageWrapper } from './CustomStatus/Context/CommentStatusManageWrapper';
import { CustomizedStatusManage } from './CustomizedStatusManage';
import { WorkflowSettingsManage } from './WorkflowSettingsManage';
import { LucidLinkSettingManage } from './LucidLinkSettingManage';
import { WrappedInsidePrimaryNav } from '../_shared/Nav/withPrimaryNav';
import { isCompactNav } from '../../_helpers/navbarUtil';



class SettingsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showUserConfigModal: false,
            showLoginSettingsModal: false,
            showGroupConfigModal: false,
            showRoleConfigModal: false,
            showDirectoryPermissionModal: false,
            showAPIAccessManageModal: false,
            showEmailDomainManageModal: false,
            showProofSettingsModal: false,
            showEmailSettingsModal: false,
            showCustomizedStatus: false,
            showWorkflowSettings: false,
            showLucidLinkSettings: false
        };
    }
    componentDidMount() {
        document.title = 'Brandshare Collaboration - Dashboard';
        console.log('Setting page has mounted!');
    }

    render() {
        return (
            <>
                {
                    isCompactNav(this.props.navBarConfig) === true  && <WrappedInsidePrimaryNav/>
                }
                
                <div id="tab_view18_view98" className="tab-pane" style={{ height: '100%'}}>                    
                    <div className="form-modify main-settings-page">
                        <div className="ContentPanel">
                            {/* <div className="clearfix">
                                 
                                <h3 className="form-label-blue element-header">
                                    Manage Tenant Settings
                                </h3> 
                            </div>  */}
                            <div className="_s_Content settings-view">
                                <div className="_s_SettingsListContainer settings-list-container">
                                    <div
                                        className="_s_TenantSettings setting-details hide"
                                        style={{ display: 'block' }}>
                                        <div className="SubContentPanel">
                                            <div className="clearfix">
                                                <h3 className="form-label-blue element-header">
                                                    Tenant Settings
                                                </h3>
                                            </div>
                                            <div className="setting-list">
                                                
                                                <SettingsHeader
                                                    label="Manage Users"
                                                    detail="Manager Application Users"
                                                    onConfigureClicked={() =>
                                                        this.setState({
                                                            showUserConfigModal: true,
                                                        })} />
                                                <SettingsHeader
                                                    label="Manage Login"
                                                    detail="Manager Application Settings"
                                                    onConfigureClicked={() =>
                                                        this.setState({
                                                            showLoginSettingsModal: true,
                                                        })} />
                                                <SettingsHeader
                                                    label="Manage Groups"
                                                    detail="Manage Application User Groups"
                                                    onConfigureClicked={() =>
                                                        this.setState({
                                                            showGroupConfigModal: true,
                                                        })} />
                                                <SettingsHeader
                                                    label="Manage Roles"
                                                    detail="Manage Application User Roles"
                                                    onConfigureClicked={() =>
                                                        this.setState({
                                                            showRoleConfigModal: true,
                                                        })} />
                                                <SettingsHeader
                                                    label="Manage Directory Permissions"
                                                    detail="Manage Directory Permissions Across
                                                    Tenant"
                                                    onConfigureClicked={() =>
                                                        this.setState({
                                                            showDirectoryPermissionModal: true,
                                                        })} />
                                                <SettingsHeader
                                                    label="Manage API Access"
                                                    detail="Manage API access through keys"
                                                    onConfigureClicked={() =>
                                                        this.setState({
                                                            showAPIAccessManageModal: true,
                                                        })} />
                                                <SettingsHeader
                                                    label="Manage Domain Whitelist"
                                                    detail="Manage Email Domain Whitelist for User Registration"
                                                    onConfigureClicked={() =>
                                                        this.setState({
                                                            showEmailDomainManageModal: true,
                                                        })} />
                                                <SettingsHeader
                                                    label="Manage File Related Settings"
                                                    detail="Manage File Share and Video Transcription Options"
                                                    onConfigureClicked={() =>
                                                        this.setState({
                                                            showProofSettingsModal: true,
                                                        })} />
                                                <SettingsHeader
                                                    label="Manage Email Settings"
                                                    detail="Manage Custom Email Templates"
                                                    onConfigureClicked={() =>
                                                        this.setState({
                                                            showEmailSettingsModal: true,
                                                        })} />
                                                <SettingsHeader
                                                    label="Manage Comment Status"
                                                    detail="Manage Customized  Comments Status"
                                                    onConfigureClicked={() =>
                                                        this.setState({
                                                            showCustomizedStatus: true,
                                                        })} />
                                                <SettingsHeader
                                                    label="Manage Workflow Settings"
                                                    detail="Manage Workflow Status and Notifications"
                                                    onConfigureClicked={() =>
                                                        this.setState({
                                                            showWorkflowSettings: true,
                                                        })} />
                                                <SettingsHeader
                                                    label="Manage LucidLink Settings"
                                                    detail="Manage LucidLink Filespace and Credentials"
                                                    onConfigureClicked={() =>
                                                        this.setState({
                                                            showLucidLinkSettings: true,
                                                        })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showUserConfigModal && (
                    <UserManage
                        onClose={() => this.setState({ showUserConfigModal: false })}></UserManage>
                )}
                {this.state.showGroupConfigModal && (
                    <GroupManage
                        onClose={() =>
                            this.setState({ showGroupConfigModal: false })
                        }></GroupManage>
                )}
                {this.state.showLoginSettingsModal && (
                    <LoginSettingManage
                        onClose={() =>
                            this.setState({ showLoginSettingsModal: false })
                        }></LoginSettingManage>
                )}
                {this.state.showRoleConfigModal && (
                    <RoleManage
                        onClose={() => this.setState({ showRoleConfigModal: false })}></RoleManage>
                )}
                {this.state.showDirectoryPermissionModal && (
                    <DirectoryPermissionManage
                        onClose={() =>
                            this.setState({ showDirectoryPermissionModal: false })
                        }></DirectoryPermissionManage>
                )}
                {this.state.showAPIAccessManageModal && (
                    <APIAccessManage
                        onClose={() =>
                            this.setState({ showAPIAccessManageModal: false })
                        }></APIAccessManage>
                )}
                {this.state.showEmailDomainManageModal && (
                    <EmailDomainManage
                        onClose={() =>
                            this.setState({ showEmailDomainManageModal: false })
                        }></EmailDomainManage>
                )}
                {this.state.showProofSettingsModal && (
                    <ProofSettingManage
                        onClose={() =>
                            this.setState({ showProofSettingsModal: false })
                        }></ProofSettingManage>
                )}
                {this.state.showEmailSettingsModal && (
                    <EmailSettingManage
                        onClose={() =>
                            this.setState({ showEmailSettingsModal: false })
                        }></EmailSettingManage>
                )}
                {this.state.showCustomizedStatus && (        
                    <CustomizedStatusManage            
                        onClose={() =>
                            this.setState({ showCustomizedStatus: false })
                        }></CustomizedStatusManage>
                )}
                {this.state.showWorkflowSettings && (        
                    <WorkflowSettingsManage            
                        onClose={() =>
                            this.setState({ showWorkflowSettings: false })
                        }></WorkflowSettingsManage>
                )}
                {this.state.showLucidLinkSettings && (        
                    <LucidLinkSettingManage            
                        onClose={() =>
                            this.setState({ showLucidLinkSettings: false })
                        }></LucidLinkSettingManage>
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    const { proofs } = state;
    return {
        proofs,
    };
}

const connectedSettingsPage = connect(mapStateToProps)(SettingsPage);
export { connectedSettingsPage as SettingsPage };
