//@ts-check
import React, { useState, useEffect, useRef } from 'react';
import { tenantService } from '../Tenant';
import { userService } from '../User/user.service';
import { store } from '../../_helpers';
import { alertActions } from '../App';
import { MultiSelect, TEXT_RECOGNITION_SETTINGS_TYPES } from '../../components';
import { useLiveUpdate } from '../App/LiveUpdate/LiveUpdateContext';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FILE_SHARE_SETTINGS_TYPES } from '../_shared/fileshare.constants';
import { DataFetcher } from '../_shared/DataFetcher/DataFetcher';
const comparisonTypes = [
    {
        label: 'All folders and subfolders',
        value: 0,
        tooltip: 'Compare all files from all folders and subfolders'
    },
    {
        label: 'Selected folder and its subfolders',
        value: 1,
        tooltip: 'Compare files of selected folder and its subfolders'
    },
    { label: "Selected file's versions", value: 2, tooltip: 'Compare versions of selected file' }
];

export const ProofSettingManage = ({ onClose }) => {
    const [proofSettings, setProofSettings] = useState({
        fileShareSettingType: 2,
        textRecognitionSettingType: 0,
        defaultFileShareType: 4,
        transcribeVideo: false,
        requireOTP: false,
        annotationShareEnabled: true
    });
    const [selectedComparisonType, setSelectedComparisonType] = useState(comparisonTypes[0]);
    const { changeTarget, setSettingsListeners } = useLiveUpdate();
    const proofSettingsRef = useRef({});

    useEffect(() => {
        proofSettingsRef.current = proofSettings;
    }, [proofSettings]);

    useEffect(() => {
        userService.getCurrentUser().then((data) => {
            liveUpdateConfig(data.authData.roleId, data.authData.id);
        });

        return () => {};
    }, []);

    const isRequireOTPEnable = () =>
        proofSettings?.fileShareSettingType === FILE_SHARE_SETTINGS_TYPES.everyone.value ||
        (proofSettings?.fileShareSettingType === FILE_SHARE_SETTINGS_TYPES.registered.value &&
            proofSettings?.defaultFileShareType === 1);

    const onFileShareSettingTypeChange = (type) => {
        setProofSettings({
            ...proofSettings,
            fileShareSettingType: type.value,
            defaultFileShareType:
                type.value === FILE_SHARE_SETTINGS_TYPES.everyone.value
                    ? 1
                    : proofSettings.defaultFileShareType,
            requireOTP: false
        });
    };

    const onDefaultFileShareLinkTypeChange = () => {
        setProofSettings({
            ...proofSettings,
            defaultFileShareType: isFileDownload ? 4 : 1,
            requireOTP: false
        });
    };

    const onRequireOTPChange = (event) => {
        const { checked } = event.target;
        setProofSettings({
            ...proofSettings,
            requireOTP: !!checked
        });
    };

    const onAnnotationShareEnabledChanged = (event) => {
        const { checked } = event.target;
        setProofSettings({
            ...proofSettings,
            annotationShareEnabled: !!checked
        });
    };

    const liveUpdateConfig = (roleId, userId) => {
        const targets = [
            {
                key: 'Settings',
                value: 'Settings/ProofSettings'
            }
        ];
        changeTarget(targets);
        setSettingsListeners(hanldeProofSettingsUpdate);
    };

    const hanldeProofSettingsUpdate = (data) => {
        setProofSettings(data);
        setSelectedComparisonType(comparisonTypes.find((x) => x.value == data.comparisonType));
    };

    const fetchData = async () => {

        let proofSettings = await tenantService.getProofSettings();
        proofSettings.fileShareSettingType =
            proofSettings?.fileShareSettingType !== null ||
            proofSettings?.fileShareSettingType === undefined
                ? proofSettings?.fileShareSettingType
                : FILE_SHARE_SETTINGS_TYPES.registered.value;
        setProofSettings((prev) => {
            return { ...prev, ...proofSettings };
        });
        var selected = comparisonTypes.find((x) => x.value == proofSettings.comparisonType);
        setSelectedComparisonType(selected);
    };
    const onSave = async () => {
        proofSettings.defaultFileShareType =
            proofSettings?.fileShareSettingType === FILE_SHARE_SETTINGS_TYPES.everyone.value ||
            proofSettings?.fileShareSettingType === FILE_SHARE_SETTINGS_TYPES.none.value
                ? 1
                : proofSettings.defaultFileShareType;
        await tenantService.updateProofSettings(proofSettings);
        await fetchData();
        store.dispatch(alertActions.success('Saved !'));
    };

    const { requireOTP } = proofSettings;
    const { annotationShareEnabled } = proofSettings;

    const isFileDownload = proofSettings?.defaultFileShareType === 1 ? true : false;
    const disableLinkType =
        proofSettings?.fileShareSettingType === FILE_SHARE_SETTINGS_TYPES.everyone.value ||
        proofSettings?.fileShareSettingType === FILE_SHARE_SETTINGS_TYPES.none.value;
    const isFileShareSettingNone =
        proofSettings?.fileShareSettingType === FILE_SHARE_SETTINGS_TYPES.none.value;

    return (
        <>
            <div className="settings-view__manage__container">
                <div className="m-2" style={{}}>
                    <div className="settings-view-modal">
                        <div style={{ minHeight: '80%', fontSize: '13px' }}>
                            <button
                                id="editor-close-btn"
                                type="button"
                                className="close modal-close"
                                title="Close Modal"
                                onClick={onClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h3 className="">Manage File Related Settings</h3>
                            <hr></hr>
                            <DataFetcher height={'476px'} onFetchData={fetchData}>
                                <>
                                    <div className={`form-group flex-column mb-4 d-flex}`}>
                                        <label htmlFor="link">File Share settings</label>
                                        <p className="mb-1">
                                            Select file share option for this tenant
                                        </p>
                                        <div style={{ width: '100%' }}>
                                            <ButtonGroup>
                                                {Object.keys(FILE_SHARE_SETTINGS_TYPES).map(
                                                    (key, i) => {
                                                        const type = FILE_SHARE_SETTINGS_TYPES[key];
                                                        return (
                                                            <Button
                                                                key={type.value}
                                                                title={type.tooltip}
                                                                style={{
                                                                    backgroundColor:
                                                                        type.value ===
                                                                        proofSettings?.fileShareSettingType
                                                                            ? 'var(--primary-color)'
                                                                            : 'var(--secondary-background-color)',
                                                                    color:
                                                                        type.value ===
                                                                        proofSettings?.fileShareSettingType
                                                                            ? 'white'
                                                                            : 'var(--primary-text-color)'
                                                                }}
                                                                variant="secondary"
                                                                onClick={() =>
                                                                    onFileShareSettingTypeChange(
                                                                        type,
                                                                        key
                                                                    )
                                                                }>
                                                                {type.label}
                                                            </Button>
                                                        );
                                                    }
                                                )}
                                            </ButtonGroup>
                                        </div>
                                        <label
                                            style={{
                                                margin: '12px 0px 0 0',
                                                fontSize: 13,
                                                opacity: isRequireOTPEnable() ? 1 : 0.5
                                            }}
                                            className="check-container">
                                            <p style={{ fontWeight: 'normal' }} className="mb-1">
                                                Require one-time password
                                            </p>
                                            <input
                                                type="checkbox"
                                                className="ml-2 mr-2"
                                                data-toggle="toggle"
                                                data-on="Enabled"
                                                data-off="Disabled"
                                                name={'okta'}
                                                value={'enabled'}
                                                checked={requireOTP}
                                                disabled={!isRequireOTPEnable()}
                                                onChange={onRequireOTPChange}
                                            />
                                            <span className="check-container__checkmark">
                                                <i className="fas fa-check" />
                                            </span>
                                        </label>
                                    </div>
                                    <div className={`form-group flex-column mb-4 d-flex}`}>
                                        <label htmlFor="link">Text Recognition settings</label>
                                        <p className="mb-1">
                                            Select text recognition processing behaviour
                                        </p>
                                        <div style={{ width: '100%' }}>
                                            <ButtonGroup>
                                                {Object.keys(TEXT_RECOGNITION_SETTINGS_TYPES).map(
                                                    (key, i) => {
                                                        const type =
                                                            TEXT_RECOGNITION_SETTINGS_TYPES[key];
                                                        return (
                                                            <Button
                                                                key={type.value}
                                                                title={type.tooltip}
                                                                style={{
                                                                    backgroundColor:
                                                                        type.value ===
                                                                        proofSettings?.textRecognitionSettingType
                                                                            ? 'var(--primary-color)'
                                                                            : 'var(--secondary-background-color)',
                                                                    color:
                                                                        type.value ===
                                                                        proofSettings?.textRecognitionSettingType
                                                                            ? 'white'
                                                                            : 'var(--primary-text-color)'
                                                                }}
                                                                variant="secondary"
                                                                onClick={() => {
                                                                    setProofSettings({
                                                                        ...proofSettings,
                                                                        textRecognitionSettingType:
                                                                            type.value
                                                                    });
                                                                }}>
                                                                {type.label}
                                                            </Button>
                                                        );
                                                    }
                                                )}
                                            </ButtonGroup>
                                        </div>
                                    </div>

                                        <div className={`form-group flex-column mb-4 d-flex}`}>
                                        <label htmlFor="compare">File Annotations Link Share Settings</label>
                                        <label  
                                            style={{ marginTop: '6px' }}                                          
                                            className="check-container">
                                            <p style={{ fontWeight: 'normal' }} className="mb-1">
                                            Share File Annotations Link with External User(s)
                                            </p>
                                            <input
                                                type="checkbox"
                                                className="ml-2 mr-2"
                                                data-toggle="toggle"
                                                data-on="Enabled"
                                                data-off="Disabled"
                                                name={'okta'}
                                                value={'enabled'}
                                                checked={annotationShareEnabled}                                                
                                                onChange={onAnnotationShareEnabledChanged}
                                            />
                                            <span className="check-container__checkmark">
                                                <i className="fas fa-check" />
                                            </span>
                                        </label>

                                        </div>
                                                              
                                    <div
                                        className={`form-group flex-column ${
                                            isFileShareSettingNone ? 'd-none' : 'd-flex'
                                        }`}>
                                        <label htmlFor="link">Default File Share Link Type</label>
                                        <div
                                            style={{
                                                marginLeft: '4px',
                                                marginBottom: '15px',
                                                opacity: disableLinkType ? 0.6 : 1,
                                                display: 'inline-flex'
                                            }}>
                                            <label className="mt-1">Online Link</label>
                                            <div
                                                style={{
                                                    marginRight: '8px',
                                                    marginLeft: '16px',
                                                    marginTop: '4px',
                                                    transform: 'scale(1.5)'
                                                }}
                                                className="custom-control custom-switch">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="customSwitch1"
                                                    disabled={disableLinkType}
                                                    checked={isFileDownload}
                                                    onChange={onDefaultFileShareLinkTypeChange}
                                                />
                                                <label
                                                    className="custom-control-label toggle"
                                                    htmlFor="customSwitch1"></label>
                                            </div>
                                            <label className="mt-1">Download</label>
                                        </div>
                                    </div>
                                    <div className="form-group d-flex flex-column">
                                        <label htmlFor="link">Video Transcription</label>
                                        <div
                                            style={{
                                                marginLeft: '8px',
                                                marginBottom: '15px',
                                                display: 'inline-flex'
                                            }}>
                                            <label
                                                title="Transcription will generate only if requested"
                                                className="mt-1">
                                                On Demand
                                            </label>
                                            <div
                                                style={{
                                                    marginRight: '8px',
                                                    marginLeft: '16px',
                                                    marginTop: '4px',
                                                    transform: 'scale(1.5)'
                                                }}
                                                className="custom-control custom-switch">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="customSwitch2"
                                                    checked={
                                                        proofSettings?.transcribeVideo || false
                                                    }
                                                    onChange={() => {
                                                        setProofSettings({
                                                            ...proofSettings,
                                                            transcribeVideo:
                                                                !proofSettings?.transcribeVideo
                                                        });
                                                    }}
                                                />
                                                <label
                                                    className="custom-control-label toggle"
                                                    htmlFor="customSwitch2"></label>
                                            </div>
                                            <label
                                                title="Transcription will auto generate after every upload"
                                                className="mt-1">
                                                Auto
                                            </label>
                                        </div>
                                    </div>
                                    <div style={{ maxWidth: 350 }} className="form-group">
                                        <label htmlFor="compare">File Compare Mode</label>

                                        <div className="belect">
                                            <select
                                                id="comparisonTypesSelect"
                                                className=""
                                                name="comparisonTypesSelect"
                                                value={selectedComparisonType.value}
                                                onChange={(event) => {
                                                    var index =
                                                        event.nativeEvent.target.selectedIndex;
                                                    var selectedOption = comparisonTypes[index];
                                                    setProofSettings({
                                                        ...proofSettings,
                                                        comparisonType: selectedOption.value
                                                    });
                                                    setSelectedComparisonType(selectedOption);
                                                }}>
                                                {comparisonTypes.map((c) => (
                                                    <option
                                                        title={c.tooltip}
                                                        key={c.value}
                                                        value={c.value}>
                                                        {c.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-start pl-0">
                                        <button className="btn mrnda-btn" onClick={onSave}>
                                            Save
                                        </button>
                                        <button className="mrnda-btn--secondary" onClick={onClose}>
                                            Close
                                        </button>
                                    </div>
                                </>
                            </DataFetcher>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
