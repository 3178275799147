//@ts-check

import React from 'react';

const CommentStampIcon = ({ height = 100, width = 100, color = 'currentColor' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={height}
            height={width}
            viewBox="0 0 84 84"
            fill="none">            
            <mask id="path-2-inside-1_202_1428" fill={color}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32 29C29.7909 29 28 30.7909 28 33V47C28 49.2091 29.7909 51 32 51H33.5V54.5858C33.5 55.4767 34.5771 55.9229 35.2071 55.2929L39.5 51H52C54.2091 51 56 49.2091 56 47V33C56 30.7909 54.2091 29 52 29H32Z"
                />
            </mask>
            <path
                d="M33.5 51H35.5V49H33.5V51ZM35.2071 55.2929L33.7929 53.8787L35.2071 55.2929ZM39.5 51V49H38.6716L38.0858 49.5858L39.5 51ZM30 33C30 31.8954 30.8954 31 32 31V27C28.6863 27 26 29.6863 26 33H30ZM30 47V33H26V47H30ZM32 49C30.8954 49 30 48.1046 30 47H26C26 50.3137 28.6863 53 32 53V49ZM33.5 49H32V53H33.5V49ZM35.5 54.5858V51H31.5V54.5858H35.5ZM33.7929 53.8787C34.4229 53.2487 35.5 53.6949 35.5 54.5858H31.5C31.5 57.2585 34.7314 58.597 36.6213 56.7071L33.7929 53.8787ZM38.0858 49.5858L33.7929 53.8787L36.6213 56.7071L40.9142 52.4142L38.0858 49.5858ZM52 49H39.5V53H52V49ZM54 47C54 48.1046 53.1046 49 52 49V53C55.3137 53 58 50.3137 58 47H54ZM54 33V47H58V33H54ZM52 31C53.1046 31 54 31.8954 54 33H58C58 29.6863 55.3137 27 52 27V31ZM32 31H52V27H32V31Z"
                fill={color}                
                mask="url(#path-2-inside-1_202_1428)"
            />
            <rect x="35" y="35" width="14" height="2" rx="1" fill={color} />
            <rect x="35" y="39" width="14" height="2" rx="1" fill={color} />
            <rect x="35" y="43" width="14" height="2" rx="1" fill={color} />
        </svg>
    );
};

export default CommentStampIcon;
