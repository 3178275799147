import React, { useEffect, createContext, useState, useContext, useRef } from 'react';
import { liveUpdateService } from './liveupdate.service';
import { tenantService } from '../../Tenant';
import { websocketService } from './websocket.service';
import { userService } from '../../User';
import { ConnectionStatus } from './liveUpdate.constants';
import { liveUpdateGlobalHelper } from './liveupdate.globalhelpers';

const LiveUpdateContext = createContext();

export const Tragets = {
    dashBoard: {
        key: 'Dashboard',
        value: 'dashboard/Thumbnail'
    },

    folderAdded: {
        key: 'Dashboard',
        value: 'dashboard/FolderAdded'
    },

    comment: {
        key: 'Editor',
        value: '{prooId}/{proofVersion}/Comments'
    }
};

export const LiveUpdateProvider = ({ children }) => {
    const [connectionStatus, setConnectionStatus] = useState(ConnectionStatus.Disconnected);
    const [connectionId, setConnectionId] = useState();
    const targetProof = useRef(undefined);
    const tenantId = useRef(undefined);
    const onCommentUpdated = useRef(undefined);
    const onCommentDeleted = useRef(undefined);
    const onCommentReply = useRef(undefined);
    const onCommentReplyDeleted = useRef(undefined);
    const onCommentReplyUpdated = useRef(undefined);
    const onCommentStatus = useRef(undefined);
    const onCommentAnnotationUpdate = useRef(undefined);
    const onCommentUpdate = useRef(undefined);
    const onProofEditingEnded = useRef(undefined);
    const onTransciptionCompleted = useRef(undefined);
    const onTransciptionStarted = useRef(undefined);
    const onFolderAdded = useRef(undefined);
    const onFolderDeleted = useRef(undefined);
    const onFolderMoved = useRef(undefined);
    const onFolderUpdated = useRef(undefined);

    const onProofAdded = useRef(undefined);
    const onProofDeleted = useRef(undefined);
    const onThumbnailUpdated = useRef(undefined);
    const onProcessingCompleted = useRef(undefined);
    const onProofSettingsChanged = useRef(undefined);
    const onHTMLDynamicPreview = useRef(undefined);
    const onHTMLZipValidation = useRef(undefined);
    const onTextExtractCompleted = useRef(undefined);    
    const onCommentAttachmentDeleted = useRef(undefined);
    const onCommentStatusListUpdated = useRef(undefined);
    const onWorkflowUpdated = useRef(undefined);

    async function getTenantId() {
        var tenant = await tenantService.getCurrentTenantObject();
        tenantId.current = tenant.id;
    }

    const echo = (params, target) => {
        var data = JSON.parse(params.data);
        var statusCode = data.statusCode;
        var connectionId = data.connectionId;
        setConnectionId(connectionId);

        if (statusCode === 200) {
            console.log('echo was successfull');
            var body = {
                tenantId: tenantId.current,
                connectionId: connectionId,
                targets: target
            };
        }

        liveUpdateService.setTarget(body);
        userService.getCurrentUser().then(({ authData: userFromService }) => {
            let userSetPayload = {
                connectionId: connectionId,
                tenantId: tenantId.current,
                user: {
                    id: userFromService.id,
                    fullName: userFromService.fullName,
                    email: userFromService.email
                }
            };
            liveUpdateService.setConnectionData(userSetPayload);
        });
        echoCompleted();
    };

    const echoAsync = async (params, target) => {
        var data = JSON.parse(params.data);
        var statusCode = data.statusCode;
        var connectionId = data.connectionId;
        setConnectionId(connectionId);

        if (statusCode === 200) {
            console.log('echo was successfull');
            var body = {
                tenantId: tenantId.current,
                connectionId: connectionId,
                targets: target
            };
        }
        await liveUpdateService.setTarget(body);
        let { authData: userFromService } = await userService.getCurrentUser();
        let userSetPayload = {
            connectionId: connectionId,
            tenantId: tenantId.current,
            user: {
                id: userFromService.id,
                fullName: userFromService.fullName,
                email: userFromService.email
            }
        };
        await liveUpdateService.setConnectionData(userSetPayload);
        echoCompleted();
        return connectionId;
    };

    const echoCompleted = () => {
        setConnectionStatus(ConnectionStatus.Connected);
        console.log('Socket configuration completed');
    };

    const onSocketClosed = async (params) => {
        console.log('Web socket connection closed');
        let connectionId = await configureSocket(targetProof.current);
        console.log(`Web socket reconnected: ${connectionId}`);
    };

    const onNotification = (params) => {
        console.log('Live update notification recieved');
        var data = JSON.parse(params.data);
        var { payload, target } = data;

        if (target.key === 'Dashboard') {
            // if (target.key === Tragets.dashBoard.key && target.value === Tragets.dashBoard.value) {
            //     onThumbnailUpdated(payload);
            // }
            targetProof.current.forEach(function (item) {
                if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('Thumbnail')
                ) {
                    onThumbnailUpdated.current(payload);
                } 
                else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('ProofProcessingCompleted')
                ) {
                    onProcessingCompleted.current(payload);
                }
                
                else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('FolderAdded')
                ) {
                    onFolderAdded.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('FolderDeleted')
                ) {
                    onFolderDeleted.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('FolderMoved')
                ) {
                    onFolderMoved.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('FolderUpdate')
                ) {
                    onFolderUpdated.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('ProofAdded')
                ) {
                    onProofAdded.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('ProofDeleted')
                ) {
                    onProofDeleted.current(payload);
                }else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('HTMLDynamicPreview')
                ){
                    onHTMLDynamicPreview.current(payload)
                }
                else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('ValidateHTMLZip')
                ){
                    onHTMLZipValidation.current(payload)
                }

            });
        } else if (target.key === 'Editor') {
            targetProof.current.forEach(function (item) {
                if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('CommentAdded')
                ) {
                    onCommentUpdated.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('CommentDeleted')
                ) {
                    onCommentDeleted.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('CommentReply')
                ) {
                    onCommentReply.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('CommentReplyDeleted')
                ) {
                    onCommentReplyDeleted.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('CommentReplyUpdated')
                ) {
                    onCommentReplyUpdated.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('CommentStatus')
                ) {
                    onCommentStatus.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('CommentAnnotationUpdate')
                ) {
                    onCommentAnnotationUpdate.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('CommentUpdate')
                ) {
                    onCommentUpdate.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('TranscriptionCompleted')
                ) {
                    onTransciptionCompleted.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('TranscriptionStarted')
                ) {
                    onTransciptionStarted.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('CommentAttachmentDeleted')
                ) {
                    onCommentAttachmentDeleted.current(payload);
                }  else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('TextExtractCompleted')
                ) {
                    onTextExtractCompleted.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('CommentStatusListUpdated')
                ) {                    
                    onCommentStatusListUpdated.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('WorkflowUpdated')
                ) {  
                    onWorkflowUpdated.current?.(payload);
                }
            });
        } 
        // NOTE: Leadtools remove
        else if (target.key === 'LeadToolsEditor') {
            targetProof.current.forEach(function (item) {
                if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('PipeLine')
                )
                    onProofEditingEnded.current(payload);
            });
        } else if (target.key === 'Settings') {
            targetProof.current.forEach(function (item) {
                if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('ProofSettings')
                ) {
                    onProofSettingsChanged.current(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('RoleUpdate')
                ) {
                    var tt = connectionId;
                    liveUpdateGlobalHelper.hanldeRoleUpdate(payload);
                } else if (
                    target.key === item.key &&
                    target.value === item.value &&
                    item.value.endsWith('UserRoleChanged')
                ) {
                    liveUpdateGlobalHelper.updateCurrentTargetsWithNewRole(payload, targetProof.current).then((targets) => {
                        targetProof.current = targets;
                        changeTarget(null).then(() => {
                            liveUpdateGlobalHelper.handleUserRoleChanged(payload);
                        });
                    });
                }
            });
        }
    };

    async function configureSocket(initialTargets) {
        console.log('Trying to connect websocket');
        await getTenantId();
        if (initialTargets == undefined) {
            initialTargets = [Tragets.dashBoard];
        }

        let { params, targets } = await websocketService.createClientAsync(
            onNotification,
            onSocketClosed,
            initialTargets
        );
        let connectionId = await echoAsync(params, targets);
        return connectionId;
    }

    const setThumbnailUpdateListener = (callback) => {
        onThumbnailUpdated.current = callback;
    };

    const setProcessingCompletedListener = (callback) => {
        onProcessingCompleted.current = callback;
    };
    



    const setEditorCommentListeners = (
        hanldeCommentUpdated,
        handleCommentReplyDeleted,
        handleCommentReplyUpdated,
        targetedProof
    ) => {
        targetProof.current = targetedProof;
        onCommentUpdated.current = hanldeCommentUpdated;
        onCommentReplyDeleted.current = handleCommentReplyDeleted;
        onCommentReplyUpdated.current = handleCommentReplyUpdated;
    };

    

    const setEditorAttachmentListeners = (
        handleCommentAttachmentDeleted,
        targetedProof        
    ) => {
        targetProof.current = targetedProof;
        onCommentAttachmentDeleted.current = handleCommentAttachmentDeleted;
    };

    const setCommentDeleteListener = (callback, targetedProof) => {
        targetProof.current = targetedProof;
        onCommentDeleted.current = callback;
    };

    const setCommentReplyListener = (callback, targetedProof) => {
        targetProof.current = targetedProof;
        onCommentReply.current = callback;
    };

    const setCommentStatusListener = (callback, targetedProof) => {
        targetProof.current = targetedProof;
        onCommentStatus.current = callback;
    };

    const setCommentAnnotationUpdateListener = (callback, targetedProof) => {
        targetProof.current = targetedProof;
        onCommentAnnotationUpdate.current = callback;
    };
    const setCommentUpdateListener = (callback, targetedProof) => {
        targetProof.current = targetedProof;
        onCommentUpdate.current = callback;
    };

    const setTranscriptionListeners = (
        transciptionCompletedHandler,
        transciptionStartedHandler,
        targetedProof
    ) => {
        targetProof.current = targetedProof;
        onTransciptionCompleted.current = transciptionCompletedHandler;
        onTransciptionStarted.current = transciptionStartedHandler;
    };

    const setProofEditingEndedListener = (callback, targetedProof) => {
        targetProof.current = targetedProof;
        onProofEditingEnded.current = callback;
    };

    const setFolderListeners = (
        folderAddedHandler,
        folderDeletedHandler,
        folderMovedHandler,
        folderUpdatedHandler
    ) => {
        onFolderAdded.current = folderAddedHandler;
        onFolderDeleted.current = folderDeletedHandler;
        onFolderMoved.current = folderMovedHandler;
        onFolderUpdated.current = folderUpdatedHandler;
    };

    const setProofListeners = (proofAddedHandler, proofDeletedHandler) => {
        onProofAdded.current = proofAddedHandler;
        onProofDeleted.current = proofDeletedHandler;
    };

    const setSettingsListeners = (proofSettingsChangedHanlder) => {
        onProofSettingsChanged.current = proofSettingsChangedHanlder;
    };

    const setHTMLDynamicPreviewListeners = (
        targeted
    ) => {
        onHTMLDynamicPreview.current = targeted;
    };

    const setHTMLZipValidationListeners = (
        targeted
    ) => {
        onHTMLZipValidation.current = targeted;
    };

    const setTextExtractListeners = (
        targeted
    ) => {
        onTextExtractCompleted.current = targeted;
    };


    const setCommentStatusListeners = (
        targeted
    ) => {
        onCommentStatusListUpdated.current = targeted;
    };

    const setWorkflowListeners = (
        handler,
        getTarget
    ) => {

        
        
        onWorkflowUpdated.current = handler;
    
        if (targetProof.current === undefined) {
            return;
        }

        if (getTarget) {
            let target = getTarget(tenantId.current);        
            let contains =  targetProof.current.some(x => x.key === target.key && x.value === target.value);
            if (contains !== true) {
                var newTargets = [...targetProof.current, target];                
                changeTarget(newTargets);
            }
        }
    };

    

    async function changeTarget(newTargets) {
        if (newTargets != null) {
            var globalTargets = await liveUpdateGlobalHelper.GetRoleGlobalTragets();
            targetProof.current = [...newTargets, ...globalTargets];
        }

        if (connectionStatus === ConnectionStatus.Disconnected) {
            let connectionId = await configureSocket(targetProof.current);
            return connectionId;
        }

        var req = {
            connectionId: connectionId,
            targets: targetProof.current
        };
        await liveUpdateService.changeTarget(req);
        
        return connectionId;
    }

    return (
        <LiveUpdateContext.Provider
            value={{
                connectionId,
                connectionStatus,
                changeTarget,
                setConnectionStatus,
                setThumbnailUpdateListener,
                setProcessingCompletedListener,
                setEditorCommentListeners,
                setEditorAttachmentListeners,
                setCommentDeleteListener,
                setCommentReplyListener,
                setCommentStatusListener,
                setCommentAnnotationUpdateListener,
                setCommentUpdateListener,
                setProofEditingEndedListener,
                setTranscriptionListeners,
                setFolderListeners,
                setProofListeners,
                setSettingsListeners,
                setHTMLDynamicPreviewListeners,
                setHTMLZipValidationListeners,
                setTextExtractListeners,
                setCommentStatusListeners,
                setWorkflowListeners
            }}>
            {children}
        </LiveUpdateContext.Provider>
    );
};

export const useLiveUpdate = () => useContext(LiveUpdateContext);
