import React from 'react';
import MediaQuery from 'react-responsive';
import breakpoints from './breakpoints';

export default function PhoneBreakpoint(props) {
    return (
        <MediaQuery query={`(max-width: ${breakpoints.tabletMobile}px)`}>
            {props.children}
        </MediaQuery>
    );
}
