import ChromeIcon from "../_shared/Icon/BrowserIcons/ChromeIcon";
import EdgeIcon from "../_shared/Icon/BrowserIcons/EdgeIcon";
import FirefoxIcon from "../_shared/Icon/BrowserIcons/FireFoxIcon";

export const proofConstants = {
    ADD_REQUEST: 'PROOFS_ADD_REQUEST',
    ADD_SUCCESS: 'PROOFS_ADD_SUCCESS',
    ADD_FAILURE: 'PROOFS_ADD_FAILURE',

    GET_REQUEST: 'PROOFS_GET_REQUEST',
    GET_SUCCESS: 'PROOFS_GET_SUCCESS',
    GET_FAILURE: 'PROOFS_GET_FAILURE',

    GETALL_REQUEST: 'PROOFS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PROOFS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PROOFS_GETALL_FAILURE',

    GETALL_BY_DIRECTORY_REQUEST: 'PROOFS_GETALL_BY_DIRECTORY_REQUEST',
    GETALL_BY_DIRECTORY_SUCCESS: 'PROOFS_GETALL_BY_DIRECTORY_SUCCESS',
    GETALL_BY_DIRECTORY_FAILURE: 'PROOFS_GETALL_BY_DIRECTORY_FAILURE',

    GETALL_PROOFS_COUNT_REQUEST: 'PROOFS_GETALL_COUNT_REQUEST',
    GETALL_PROOFS_COUNT_SUCCESS: 'PROOFS_GETALL_COUNT_SUCCESS',
    GETALL_PROOFS_COUNT_FAILURE: 'PROOFS_GETALL_COUNT_FAILURE',

    GETALL_FILTERS_REQUEST: 'PROOFS_FILTERS_GETALL_REQUEST',
    GETALL_FILTERS_SUCCESS: 'PROOFS_FILTERS_GETALL_SUCCESS',
    GETALL_FILTERS_FAILURE: 'PROOFS_FILTERS_GETALL_FAILURE',

    DELETE_REQUEST: 'PROOFS_DELETE_REQUEST',
    DELETE_SUCCESS: 'PROOFS_DELETE_SUCCESS',
    DELETE_FAILURE: 'PROOFS_DELETE_FAILURE',

    INGEST_REQUEST: 'PROOFS_INGEST_REQUEST',
    INGEST_SUCCESS: 'PROOFS_INGEST_SUCCESS',
    INGEST_FAILURE: 'PROOFS_INGEST_FAILURE',

    PROOFVERSION_GETALL_REQUEST: 'PROOFVERSION_GETALL_REQUEST',
    PROOFVERSION_GETALL_SUCCESS: 'PROOFVERSION_GETALL_SUCCESS',
    PROOFVERSION_GETALL_FAILURE: 'PROOFVERSION_GETALL_FAILURE',



    PROOFVERSION_DOWNLOAD_REQUEST: 'PROOFVERSION_DOWNLOAD_REQUEST',
    PROOFVERSION_DOWNLOAD_SUCCESS: 'PROOFVERSION_DOWNLOAD_SUCCESS',
    PROOFVERSION_DOWNLOAD_FAILURE: 'PROOFVERSION_DOWNLOAD_FAILURE',
};

export var DOWNLOAD_JOB_DATA = {
    jobErrorMode: 1,
    pageNumberingTemplate: '##name##_Page(##page##).##extension##',
    enableSvgConversion: true,
    svgImagesRecognitionMode: 0,
    emptyPageMode: 0,
    preprocessorDeskew: false,
    preprocessorOrient: false,
    preprocessorInvert: false,
    inputDocumentFirstPageNumber: 1,
    inputDocumentLastPageNumber: 0,
    documentFormat: -1,
    documentOptions: null,
    rasterImageFormat: 151,
    rasterImageBitsPerPixel: 24,
    jobName: 'My Job',
    annotationsMode: 2,
    documentName: null,
    annotations: null,
};

export const CREATE_PROOF_MODAL_VIEW = {
    FILE: 'FILE',
    WEB: 'WEB',
    HTML_DYNAMIC: 'HTML_DYNAMIC'
};


export const CREATE_PROOF_MODE = {
    PROOF: 'PROOF',
    VERSION: 'PROOF_VERSION'
};

export const PROOF_EDIT_LOCK_STATUS = {
    FREED: 'Freed',
    ACQUIRED: 'Acquired'
};

export const TEXT_EXTRACT_STATUS = {
    NOT_REQUESTED: 0,
    IN_PROGRESS: 1,
    READY: 2,
    ERROR: 999
}

export const TEXT_RECOGNITION_SETTINGS_TYPES = {
    auto: {
        value: 1,
        label: 'Auto',
        tooltip: 'Texts from documents will be pre extracted and will be ready for selection'
    },
    ondemand: {
        value: 2,
        label: 'On Demand',
        tooltip: 'Text selection will be available on demand, on the go.'
    },
    none: { value: 0, label: 'Disabled', tooltip: 'Text selection feature will be disabled' }
};

export const COMMENT_CHAR_LIMIT = 8000;
export const COMMENT_VIEW_CHAR_LIMIT = 512;
export const COMMENT_VIEW_CHAR_LIMIT_PHONE = 128;

export const PROOF_OPEN_MODE =  {
    Editor : "Editor",
    Viewer : "Viewer",
    Compare : "Compare"
}

export const PROOF_TYPE = {
    Document : 1,
    Video : 2,
    HTMLStatic : 3,
    HTMLDynamic : 4,
    HTMLLive : 5,
    HTMLDynamicLive: 6
}
//'Chrome',
export const LiveHTMLSupportedBrowsers = [{ name: 'Chrome', link : 'https://chromewebstore.google.com/' }, { name: 'Edge', link : 'https://microsoftedge.microsoft.com/addons/' }, { name: 'FireFox', link : 'https://addons.mozilla.org/' }];
export const EditorType  = {
    Scrollable : 0,
    Paged :  1
}