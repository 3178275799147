import React from 'react';
import useKeyboardShortcut from 'use-keyboard-shortcut';

export const VideoPlayerShortcuts = ({spaceHandler, leftArrowHandler, rightArrowHandler}) => {
    const { } = useKeyboardShortcut(
        [' '],
        (shortcutKeys) => {            
            spaceHandler();
        },
        {
            overrideSystem: true,
            ignoreInputFields: true,
            repeatOnHold: false
        }
    );

    const {  } = useKeyboardShortcut(
        ['ArrowRight'],
        (shortcutKeys) => {            
            rightArrowHandler();            
        },
        {
            overrideSystem: true,
            ignoreInputFields: true,
            repeatOnHold: false
        }
    );

    const {  } = useKeyboardShortcut(
        ['ArrowLeft'],
        (shortcutKeys) => {            
            leftArrowHandler();
        },
        {
            overrideSystem: true,
            ignoreInputFields: true,
            repeatOnHold: false
        }
    );
    
    return <div id="video-player-shortcuts"></div>;
};
