import React, { Component } from 'react';
import { ProgressBar } from 'react-bootstrap';

class ProgressBarComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            progressNow: 0,
            showLabel: this.props.showLabel
        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handlePageLimitChange = this.handlePageLimitChange.bind(this);
    }

    componentDidMount() {}

    componentWillReceiveProps(nextProps) {
        this.setState({ progressNow: nextProps.progressNow });
    }

    handlePageClick(data) {
        const { onPageChanged = (f) => f } = this.props;
        onPageChanged(data);
    }
    handlePageLimitChange(event) {
        const { onPageLimitChange = (f) => f } = this.props;
        onPageLimitChange(event);
    }
    render() {
        return (
            <>
                <ProgressBar
                    className={this.props.className}
                    animated={this.state.progressNow < 100}
                    striped={this.state.progressNow < 100}
                    now={this.state.progressNow}
                    label={this.state.showLabel ? `${this.state.progressNow}%` : ''}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export { ProgressBarComponent };
