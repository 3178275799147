//@ts-check

import React from 'react';

const AudioLoudIcon = ({ height = 20, width = 20, color = 'currentColor' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="20px/Interaction/Multimedia/audio" fill={color}>
                    <rect id="Rectangle" fillOpacity="0" x="0" y="0" width="20" height="20"></rect>
                    <g id="Outline" transform="translate(1.000000, 1.999061)" fillRule="nonzero">
                        <path
                            d="M10.1876525,0.110504246 C10.5150341,-0.151401092 11,0.0816855464 11,0.500938651 L11,15.5009387 C11,15.9201918 10.5150341,16.1532784 10.1876525,15.8913731 L5.324,12.0009387 L0.5,12.0009387 C0.254540111,12.0009387 0.0503916296,11.8240635 0.00805566941,11.5908143 L0,11.5009387 L0,4.50093865 C0,4.22479628 0.223857625,4.00093865 0.5,4.00093865 L5.323,4.00093865 L10.1876525,0.110504246 Z M10,1.54093865 L5.81234752,4.89137306 C5.723691,4.96229827 5.61353575,5.00093865 5.5,5.00093865 L1,5.00093865 L1,11.0009387 L5.5,11.0009387 C5.58515181,11.0009387 5.66840209,11.0226739 5.74210722,11.063463 L5.81234752,11.1105042 L10,14.4599387 L10,1.54093865 Z M15.9160251,1.72358855 C17.3017065,3.80211053 18,5.89699113 18,8.00093865 C18,10.1048862 17.3017065,12.1997668 15.9160251,14.2782887 C15.7628489,14.5080531 15.4524142,14.57014 15.2226499,14.4169638 C14.9928856,14.2637876 14.9307986,13.9533529 15.0839749,13.7235886 C16.3649602,11.8021105 17,9.89699113 17,8.00093865 C17,6.10488617 16.3649602,4.19976677 15.0839749,2.27828875 C14.9307986,2.0485244 14.9928856,1.73808973 15.2226499,1.5849135 C15.4524142,1.43173727 15.7628489,1.49382421 15.9160251,1.72358855 Z M13.9341216,4.25286918 C14.6424792,5.49249502 15,6.74381785 15,8.00093865 C15,9.25805945 14.6424792,10.5093823 13.9341216,11.7490081 C13.7971166,11.9887668 13.4916893,12.0720652 13.2519305,11.9350602 C13.0121718,11.7980552 12.9288734,11.4926279 13.0658784,11.2528692 C13.6908541,10.1591617 14,9.07715118 14,8.00093865 C14,6.92472612 13.6908541,5.84271562 13.0658784,4.74900812 C12.9288734,4.5092494 13.0121718,4.20382206 13.2519305,4.06681708 C13.4916893,3.92981209 13.7971166,4.01311046 13.9341216,4.25286918 Z"
                            id="Combined-Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default AudioLoudIcon;
