import React, { useState } from 'react';
import { history } from '../../../_helpers';
import cx from 'classnames';
import { withVideoCompareConsumer } from '../VideoAnnotation/Context/VideoAnnotationConsumer';
import { documentAnnotationConstants } from '../DocumentAnnotation/Context/documentAnnotation.constants';
import { isCompactNav } from '../../../_helpers/navbarUtil';
import { WrappedInsidePrimaryNav } from '../../_shared/Nav/withPrimaryNav';


const ProofVideoCompareToolbar = ({
    proofVersionA,
    proofVersionB,
    isSynced,
    setIsSynced,
    isAutoCompare,
    setIsAutoCompare,
    onResetToSync,
    compareColor,
    onCompareColorChange,
    compareOpacity,
    onCompareOpacityChange,
    compareSide,
    setCompareSide,
    showComments,
    setShowComments,
    disableComments,
    compactNavbar
}) => {
    const [showOpacityColorPicker, setShowOpacityColorPicker] = useState(false);
    return (
        <div  style={compactNavbar === true? { border: 'none'}: {}} className="mrnda-video-compare__toolbar">
            <div
                className="mrnda-video-compare__toolbar__header text-truncate"
                title={`${proofVersionA.name} - v${proofVersionA.versionNumber}`}>
                <span className="mrnda-video-compare__toolbar__header--text text-truncate">
                    {proofVersionA.name}
                </span>{' '}
                <span className="mrnda-video-compare__toolbar__header--version">
                    - v{proofVersionA.versionNumber}
                </span>
            </div>
            <div className="mrnda-video-compare__toolbar__settings">
                
                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                         active: false, 
                        'authorization-disabled': disableComments                      
                    })}
                    onClick={()=>{
                        setShowComments(!showComments)
                    }}
                    title={'Show/Hide comments'}>                    
                    {/* <i className="far fa-comment-alt"></i>                     */}
                    {/* <i className="fas fa-slash fa-stack-1x" style={{ textShadow: '0.1em 0.15em white' }}></i> */}
                            
                    <span className="fa-stack mrnda-document-compare-icon-stack" >
                        <i className="far fa-comment-alt fa-stack-1x"></i>
                        {
                            showComments && 
                            <i className="fas fa-slash fa-stack-1x" style={{ textShadow: '0.1em 0.15em var(--secondary-background-color)' }}></i>
                        }
                    </span>
                   

                </div>
                
                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                        active: isSynced
                    })}
                    onClick={() => setIsSynced(!isSynced)}
                    title={'Sync'}>
                    <i className="fas fa-link"></i>
                </div>
                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                        active: false
                    })}
                    onClick={onResetToSync}
                    title={'Reset'}>
                    <i className="fas fa-sync-alt"></i>
                </div>
                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                        active: true
                    })}
                    // onClick={() => setIsAutoCompare(!isAutoCompare)}
                    title={'Color and Opacity'}>
                    <span>{' | '}</span>
                </div>
                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                        active: isAutoCompare
                    })}
                    onClick={() => setIsAutoCompare(!isAutoCompare)}
                    title={'Auto compare'}>
                    <i className="far fa-clone"></i>
                </div>



                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                        active: true,
                        'd-none': !isAutoCompare
                    })}
                    // onClick={() => setIsAutoCompare(!isAutoCompare)}
                    title={'Color and Opacity'}>
                    <div
                        className={cx({
                            'color-palette__color-outer': true,
                            selected: showOpacityColorPicker
                        })}
                        onClick={() => setShowOpacityColorPicker(!showOpacityColorPicker)}>
                        <div
                            className="color-palette__color-inner"
                            style={{ backgroundColor: compareColor }}
                        />
                    </div>
                </div>

                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                        'd-none': !isAutoCompare
                    })}
                    onClick = {() => {
                        console.log('switch side clicked !');
                        setCompareSide(compareSide === 'right' ? 'left' : 'right');
                    }}
                    title={'Switch side'}>
                    <i className="fas fa-exchange-alt"></i>
                </div>

                {isAutoCompare && showOpacityColorPicker && (
                    <div
                        className="mrnda-video-compare__toolbar__settings__auto-compare"
                        tabIndex="0"
                        onBlur={() => setShowOpacityColorPicker(false)}>
                        <div className="mrnda-video-compare__toolbar__settings__auto-compare__opacity">
                            <div className="mrnda-video-compare__toolbar__settings__auto-compare__opacity--header">
                                <span>Opacity</span>
                            </div>
                            <div className="">
                                <input
                                    type="range"
                                    name="volume"
                                    className="mrnda-video-compare__toolbar__settings__auto-compare__opacity--range"
                                    min="0"
                                    max="1"
                                    step="0.1"
                                    value={compareOpacity}
                                    onChange={(event) => {
                                        onCompareOpacityChange(event.currentTarget.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mrnda-video-compare__toolbar__settings__auto-compare__colors">
                            <div className="color-palette">
                                {/* <div className="color-palette__color-outer">
                                        <div
                                            className="color-palette__color-inner"
                                            style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                                        />
                                    </div> */}
                                <div
                                    className={cx({
                                        'color-palette__color-outer': true,
                                        selected: '#ffffff' === compareColor
                                    })}
                                    title={'No color'}
                                    onClick={() => onCompareColorChange('#ffffff')}>
                                    <div
                                        className="color-palette__color-inner"
                                        style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                                    />
                                </div>
                                <div style={{ margin: '2px 4px' }}>
                                    <span>{' | '}</span>
                                </div>
                                <div
                                    className={cx({
                                        'color-palette__color-outer': true,
                                        selected: '#ffff00' === compareColor
                                    })}
                                    onClick={() => onCompareColorChange('#ffff00')}>
                                    <div
                                        className="color-palette__color-inner"
                                        style={{ backgroundColor: 'rgb(255, 255, 0)' }}
                                    />
                                </div>
                                <div
                                    className={cx({
                                        'color-palette__color-outer': true,
                                        selected: '#ff9b00' === compareColor
                                    })}
                                    onClick={() => onCompareColorChange('#ff9b00')}>
                                    <div
                                        className="color-palette__color-inner"
                                        style={{ backgroundColor: 'rgb(255, 155, 0)' }}
                                    />
                                </div>
                                <div
                                    className={cx({
                                        'color-palette__color-outer': true,
                                        selected: '#ff0000' === compareColor
                                    })}
                                    onClick={() => onCompareColorChange('#ff0000')}>
                                    <div
                                        className="color-palette__color-inner"
                                        style={{ backgroundColor: 'rgb(255, 0, 0)' }}
                                    />
                                </div>
                                <div
                                    className={cx({
                                        'color-palette__color-outer': true,
                                        selected: '#00ff00' === compareColor
                                    })}
                                    onClick={() => onCompareColorChange('#00ff00')}>
                                    <div
                                        className="color-palette__color-inner"
                                        style={{ backgroundColor: 'rgb(0, 255, 0)' }}
                                    />
                                </div>
                                <div
                                    className={cx({
                                        'color-palette__color-outer': true,
                                        selected: '#00ffff' === compareColor
                                    })}
                                    onClick={() => onCompareColorChange('#00ffff')}>
                                    <div
                                        className="color-palette__color-inner"
                                        style={{ backgroundColor: 'rgb(0, 255, 255)' }}
                                    />
                                </div>
                                <div
                                    className={cx({
                                        'color-palette__color-outer': true,
                                        selected: '#0000ff' === compareColor
                                    })}
                                    onClick={() => onCompareColorChange('#0000ff')}>
                                    <div
                                        className="color-palette__color-inner"
                                        style={{ backgroundColor: 'rgb(0, 0, 255)' }}
                                    />
                                </div>
                                {/* <div className="color-palette__color-outer">
                                        <div
                                            className="color-palette__color-inner"
                                            style={{ backgroundColor: 'rgb(0, 0, 0)' }}
                                        />
                                    </div> */}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div
                style={{marginRight : '48px'}}
                className="mrnda-video-compare__toolbar__header text-truncate"
                title={`${proofVersionB.name} - v${proofVersionB.versionNumber}`}>
                <span className="mrnda-video-compare__toolbar__header--text text-truncate">
                    {proofVersionB.name}
                </span>{' '}
                <span className="mrnda-video-compare__toolbar__header--version">
                    - v{proofVersionB.versionNumber}
                </span>
            </div>
            <button
                title="Close Proof"
                style={ compactNavbar === true? { top: '74px' } : {}}
                onClick={() => history.goBack()}
                className="mrnda-video-compare__toolbar__close mrnda-icon-btn">
                <i className="fas fa-times fa-2x"></i>
            </button>
        </div>
    );
};


const VideoCompareToolbar = (props) => {     
    const compactNavbar = isCompactNav();

    return (
        <>
            {                
                compactNavbar === true 
                ? <WrappedInsidePrimaryNav 
                        WrappedComponent={ProofVideoCompareToolbarHOC}
                        showRightActions = {true}
                        style = {{ backgroundColor: 'var(--secondary-background-color)' }}
                        primaryActionsStyle = {{ marginRight: '0px' }}
                        pageActionsStyle = {{ paddingLeft: '0px', width : 'calc(100% - 240px)' }} 
                        secondaryActionsStyle = {{ marginLeft  : '0px'}}                                                      
                        />

                : <ProofVideoCompareToolbarHOC/>
            }
        </>
    );
}


const ProofVideoCompareToolbarHOC = withVideoCompareConsumer(ProofVideoCompareToolbar);
export { ProofVideoCompareToolbarHOC, ProofVideoCompareToolbar, VideoCompareToolbar };
