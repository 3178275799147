import React from 'react';

export const PIN_ICON_PATH_DATA =
    'M44.5,15c0-8.271-6.729-15-15-15s-15,6.729-15,15c0,7.934,6.195,14.431,14,14.949V58c0,0.553,0.448,1,1,1s1-0.447,1-1  V29.949C38.305,29.431,44.5,22.934,44.5,15z M24.5,15c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S26.706,15,24.5,15z';

const PinIcon = ({ height = 24, width = 24, color = 'currentColor' }) => {
    return (
        <svg
            version="1.1"
            width={width}
            height={height}
            x="0"
            y="0"
            viewBox="0 0 64 64"
            className="">
            <g>
                <path d={PIN_ICON_PATH_DATA} fill={color} className=""></path>
            </g>
        </svg>
    );
};

export default PinIcon;
