import React from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';

//FontAwesome
import { history } from '../../_helpers';
import { alertActions } from '../../components';

import SubdomainRoute from './SubdomainRoute';

import './app.scss';

console.log('App Version :', process.env.APP_VERSION);
console.log('API URL :', process.env.API_URL);
class App extends React.Component {
    constructor(props) {
        super(props);

        const { dispatch } = this.props;
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    }
    componentDidMount() {
        const ele = document.getElementById('ipl-progress-indicator');
        if (ele) {
            // fade out
            ele.classList.add('available');
            ele.outerHTML = '';
        }
    }

    render() {
        return (
            <Router history={history}>
                <Route path="/" component={SubdomainRoute} />
            </Router>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert,
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
