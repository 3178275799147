export const authorizationConstants = {
    Dashboard: {
        KEY: 'dashboard',
        ACTION_DASHBOARD_VIEW: 'dashboard.view',
        ACTION_DASHBOARD_PROOF_CREATE: 'dashboard.proof.create',
        ACTION_DASHBOARD_PROOF_VIEW: 'dashboard.proof.view',
        ACTION_DASHBOARD_PROOF_DIRECTORY_EDIT: 'dashboard.proof.directory.edit',

    },
    ProofViewer: {
        KEY: 'proof.viewer',
        ACTION_PROOF_VIEWER_EDIT: 'proof.viewer.edit',
        ACTION_PROOF_VIEWER_DELETE: 'proof.viewer.delete',
        ACTION_PROOF_VIEWER_COMPARE: 'proof.viewer.compare',
        ACTION_PROOF_VIEWER_COPY: 'proof.viewer.copy',
        ACTION_PROOF_VIEWER_CREATE_VERSION: 'proof.viewer.create.version',
        ACTION_PROOF_VIEWER_DELETE_VERSION: 'proof.viewer.delete.version',
        ACTION_PROOF_VIEWER_LOCKUNLOCK_VERSION: 'proof.viewer.lock-unlock.version',
        ACTION_PROOF_VIEWER_SHARE_FILE_VERSION: 'proof.viewer.share-file.version',
        ACTION_PROOF_VIEWER_DOWNLOAD_FILE_VERSION: 'proof.viewer.download-file.version',
    },
    ProofEditor: {
        KEY: 'proof.editor',
        ACTION_PROOF_EDITOR_COMMENT_VIEW: 'proof.editor.comment.view',
        ACTION_PROOF_EDITOR_COMMENT_ADD: 'proof.editor.comment.add',
        ACTION_PROOF_EDITOR_COMMENT_EDIT: 'proof.editor.comment.edit',
        ACTION_PROOF_EDITOR_COMMENT_DELETE: 'proof.editor.comment.delete',
        ACTION_PROOF_EDITOR_COMMENT_DELETE_ANY: 'proof.editor.comment.delete.all',
        ACTION_PROOF_EDITOR_ANNOTATION_MODIFY_ANY: 'proof.editor.annotation.edit.all',
    },
    Settings: {
        KEY: 'settings',
        ACTION_SETTINGS_VIEW: 'settings.view',
    },
};
