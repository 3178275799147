import React from 'react';
import { Link } from 'react-router-dom';

export const SupportContactArea = () => {
    return (
        <div className="sender">
            <Link
                to="/newsupportrequest"
                target="_blank"
                rel="noopener noreferrer"
                title="Brandshare Collaboration"
                style={{color: 'inherit'}}
                onClick={(e) => {
                    // history.push({
                    //     pathname: `/newsupportrequest​`,
                    // });
                }}>            
                <p className="typography--small typography--muted typography--center" >
                    Need help? Click for support
                </p>
            </Link>
        </div>
    );
};