import localStorage from 'local-storage';
import { userService } from '../components/User';

const VOLUME_SETTINGS_KEY = 'volume-settings';

export const volumeHelper = {
    set,
    get,
    getIsMuted,
    setIsMuted
};

async function getUserId() {
    let { authData: currentUser } = await userService.getCurrentUser();
    return currentUser.id;
}

async function set(volume) {
    var newItem = {
        userId: await getUserId(),
        volume: volume
    };

    let currentItems = localStorage.get(VOLUME_SETTINGS_KEY);
    if (currentItems === null) {
        localStorage.set(VOLUME_SETTINGS_KEY, [newItem]);
    } else {
        var exist = currentItems.find((x) => x.userId === newItem.userId);
        if (exist === undefined || exist === null) {
            localStorage.set(VOLUME_SETTINGS_KEY, [...currentItems, newItem]);
        } else {
            var index = currentItems.findIndex((x) => x.userId === newItem.userId);
            currentItems.splice(index, 1);
            localStorage.set(VOLUME_SETTINGS_KEY, [...currentItems, newItem]);
        }
    }
}

async function get() {
    let userId = await getUserId();    
    var volumeSettings = localStorage.get(VOLUME_SETTINGS_KEY);
    if (!volumeSettings) {
        return 0;
    }

    var found = volumeSettings.find((x) => x.userId === userId);
    if (!found || !found.volume) {
        return 0;
    } else {
        return found.volume;
    }
}


async function getIsMuted() {
    let userId = await getUserId();
    var volumeSettings = localStorage.get(VOLUME_SETTINGS_KEY);
    if (!volumeSettings) {
        return 0;
    }

    var found = volumeSettings.find((x) => x.userId === userId);
    if (!found) {
        return 0;
    } else {
        return found.isMuted;
    }
}


async function setIsMuted(isMuted) {
    var newItem = {
        userId: await getUserId(),
        isMuted : isMuted
    };

    let currentItems = localStorage.get(VOLUME_SETTINGS_KEY);
    if (currentItems === null) {
        localStorage.set(VOLUME_SETTINGS_KEY, [newItem]);
    } else {
        var exist = currentItems.find((x) => x.userId === newItem.userId);
        if (exist === undefined || exist === null) {
            localStorage.set(VOLUME_SETTINGS_KEY, [...currentItems, newItem]);
        } else {
            var index = currentItems.findIndex((x) => x.userId === newItem.userId);
            currentItems.splice(index, 1);
            newItem.volume = exist.volume;
            localStorage.set(VOLUME_SETTINGS_KEY, [...currentItems, newItem]);
        }
    }
}