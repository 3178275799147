// calculates the overlap percentage of range y into range x 
function overlapPercentage (xArr, yArr) {
    let min1 = Math.min(...xArr);
    let max1 = Math.max(...xArr);
    let min2 = Math.min(...yArr);
    let max2 = Math.max(...yArr);

    let overlapAr = Math.max(0, Math.min(max1, max2) - Math.max(min1, min2));        
    let lengthy = max2-min2;
            
    return (overlapAr/lengthy)*100;
}

function isScrolledIntoView (elem, scrollingView, index, containerZoom) 
{   
    const overlapThreshold = 1;

    var scale = containerZoom / 100 ;

    var docViewTop = scrollingView.scrollTop;
    var docViewBottom = docViewTop + scrollingView.offsetHeight;

    var elemTop = elem.offsetTop * scale;
    var elemBottom = elemTop + elem.offsetHeight * scale;        
    var some = overlapPercentage([docViewTop, docViewBottom], [elemTop, elemBottom]);
    index++;    
   
    return some > overlapThreshold;
}


export {isScrolledIntoView, overlapPercentage};