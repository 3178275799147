import React from 'react';
import { AnnotationContext, DocumentAnnotationContext, DocumentCompareContext, DocumentCompareContextV } from './DocumentAnnotationContext';

const withDocumentAnnotationConsumer = (Component) => {
    return function ContextualComponent(props) {
        return (
            <DocumentAnnotationContext.Consumer>
                {(consumer) => <Component {...consumer} {...props} />}
            </DocumentAnnotationContext.Consumer>
        );
    };
};


const withDocumentCompareConsumer = (Component) => {
    return function ContextualComponent(props) {
        return (
            <DocumentCompareContext.Consumer>
                {(consumer) => <Component {...consumer} {...props} />}
            </DocumentCompareContext.Consumer>
        );
    };
};



export { withDocumentAnnotationConsumer, withDocumentCompareConsumer };
