//@ts-check
import React, { useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Modal, Spinner } from 'react-bootstrap';
import { sha256 as SHA256 } from 'js-sha256';
import MD5 from 'js-md5';
import cx from 'classnames';
import { fileUploadConstants, ProgressBarComponent, s3InfoActions, s3Service } from '../_shared';
import { authHeader, config, store, utilService, colorUtilService } from '../../_helpers';
import { alertActions, directoryService, proofService } from '..';
import { CREATE_PROOF_MODE, Display_Name_length } from '.';
import CreateProofHTMLDynamicGuide from './CreateProofHTMLDynamicGuide';
import CreateProofHTMLDynamicPreview from './CreateProofHTMLDynamicPreview';
import { useLiveUpdate } from '../App/LiveUpdate/LiveUpdateContext';
import cloneDeep from 'lodash.clonedeep';
import { CustomInput } from '../_shared/CustomInput';
import { useTheme } from '../App/Theme/ThemeContext';
import useStateRef from 'react-usestateref';

const PROOF_INIT = {
    proofId: '',
    proofVersionId: '',
    uploadPath: '',
    bucket: '',
    region: '',
    accessKey: ''
};

const SELECTION_INIT = {
    x: 0,
    y: 0,
    width: 0,
    height: 0
};

const initialFileObject = {
    fileName: {
        value: '',
        validated: true,
        message: 'Provide a valid file display name'
    },
    duration: {
        value: null,
        validated: true,
        message: 'Provide a valid duration between 5 and 300 seconds'
    },
    width: {
        value: 0,
        validated: true,
        message: 'Width must be between 10px and 1920px'
    },
    height: {
        value: 0,
        validated: true,
        message: 'Height must be between 10px and 3000px'
    },
    file: {
        value: null,
        validated: true,
        message: 'Provide a zip package file'
    },
    entryFileName: {
        value: 'index.html',
        validated: true,
        message: 'Provide a valid file name'
    },
    submitted: false,
    canSave: true,
    canPreview: false
};

const HTML_DYNAMIC_PROOF_TYPES = {
    static: {
        value: 0,
        label: 'Video',
        tooltip: 'The site will be video'
    },
    live: {
        value: 1,
        label: 'Live',
        tooltip: 'The site will be interactive'
    }
};

const md5 = (x) => {
    const o = MD5.create();
    o.update(x);
    return o.base64();
};
const sha256 = (x) => {
    const o = SHA256.create();
    o.update(x);
    return o.hex();
};

const CreateProofHTMLDynamicView = ({
    isVisible = false,
    onClose,
    onUploadStart,
    onSave,
    proofId = null,
    directoryNodeId = null,
    createMode,
    htmlDynamicType,
    setHtmlDynamicType
}) => {
    const { dispatch } = store;
    const fileInputRef = useRef(null);
    const [fileUploadObject, setFileUploadObject, fileUploadObjectRef] = useStateRef(cloneDeep(initialFileObject));
    const proofInfo = useRef({ ...PROOF_INIT, proofId: proofId });
    const [progressNow, setProgressNow] = useState(0);
    const [fileUploadTimeLeft, setFileUploadTimeLeft] = useState(0);
    const [attachment, setAttachment, attachmentRef] = useStateRef(null);
    const [fileUploadStatus, setFileUploadStatus] = useState(fileUploadConstants.NONE);
    const [showGuide, setShowGuide] = useState(false);
    const [showDimentionSelector, setShowDimentionSelector] = useState(false);
    const [imagePreviewURL, setImagePreviewURL] = useState({ url: '', errorMessage: '' });
    const [selectedDimension, setSelectedDimension] = useState(SELECTION_INIT);
    const [isInvalidDynamicLiveZip, setIsInvalidDynamicLiveZip] = useState(false);
    const [entryFileDisableMode, setEntryFileDisableMode] = useState(false);



    const { connectionStatus, changeTarget, setHTMLDynamicPreviewListeners, setHTMLZipValidationListeners } = useLiveUpdate();

    useEffect(() => {
        changeLiveUpdateTarget();
        return () => {
            setFileUploadObject(initialFileObject);
        };
    }, []);

    const changeLiveUpdateTarget = () => {
        const targets = [
            {
                key: 'Dashboard',
                value: 'dashboard/HTMLDynamicPreview'
            },
            {
                key: 'Dashboard',
                value: 'dashboard/ValidateHTMLZip'
            }
        ];
        changeTarget(targets);
        setHTMLDynamicPreviewListeners((payload) => {
            if (payload.proofVersionId === proofInfo?.current?.proofVersionId) {
                setImagePreviewURL({ url: payload.url, errorMessage: payload?.errorMessage });
            }
        }, targets);

        setHTMLZipValidationListeners((payload) => {

            if (payload.proofVersionId === proofInfo?.current?.proofVersionId) {
                let updated = {
                    ...cloneDeep(fileUploadObjectRef?.current),
                    submitted: false,
                    canSave: payload?.isValidZip,
                    canPreview: true
                };
    
                setFileUploadObject({ ...updated });
                setIsInvalidDynamicLiveZip(!payload?.isValidZip);
            }

        }, targets);

    };

    const getS3UploadInfo = () => {
        var s3TempInfo = {
            signerUrl: `${config.apiUrl}/s3`,
            awsSignatureVersion: '4',
            progressIntervalMS: 200,
            signHeaders: { ...authHeader() },
            computeContentMd5: true,
            cryptoMd5Method: (_) => md5(_),
            cryptoHexEncodedHash256: (_) => sha256(_),
            bucket: proofInfo.current.bucket,
            awsRegion: proofInfo.current.region,
            aws_key: proofInfo.current.accessKey
        };
        return s3TempInfo;
    };
    const handleTextChange = async (event) => {
        var { name, value } = event.target;
        if (name === 'entryFileName') {
            onFileReset();
        }

        let updated = {
            ...cloneDeep(fileUploadObject),
            submitted: false,
            // canSave: true,
            canPreview: true
        };

        if (htmlDynamicType !== HTML_DYNAMIC_PROOF_TYPES.live.value || entryFileDisableMode) {
            updated.canSave = true;
        }


        updated[name].value = value;
        updated[name].validated = true;
        // let canSave = await validateFields(updated);
        setFileUploadObject({ ...updated });


    };
    const onFileChange = async (e) => {
        if (!!!e?.target?.files || e.target.files.length < 1) return;
        var file = e.target.files[0];
        var fileExtention = utilService.getFileExtention(file.name);
        if (fileExtention.toLocaleLowerCase() !== 'zip') {
            dispatch(alertActions.warn('Only zip files allowed!'));
            return;
        }

        setAttachment(file);
        onFileClear();
        let updated = { ...fileUploadObject, submitted: false, canSave: (htmlDynamicType === HTML_DYNAMIC_PROOF_TYPES.static.value), canPreview: true };
        setFileUploadObject(updated);

        var s3TempInfoPayload = {
            name: file.name,
            proofId: proofInfo.current?.proofId
        };
        var s3TempInfoResponse = await dispatch(s3InfoActions.getS3TempInfo(s3TempInfoPayload));
        proofInfo.current = {
            proofId: s3TempInfoResponse.proofId,
            proofVersionId: s3TempInfoResponse.proofVersionId,
            uploadPath: s3TempInfoResponse.uploadPath,
            bucket: s3TempInfoResponse.bucket,
            region: s3TempInfoResponse.region,
            accessKey: s3TempInfoResponse.accessKey
        };

        if (htmlDynamicType === HTML_DYNAMIC_PROOF_TYPES.live.value) {

            console.log("[HTML Dynamic Live] Upload File");

            if (!!!attachmentRef) {
                return;
            }

            onUploadStart && onUploadStart();

            if (fileUploadStatus === fileUploadConstants.COMPLETED) {
                await onFileUploadCompleted();
            } else {
                // startUploading();
                startUploadingForZipLive();
            }

        }
    };

    const onFileClear = () => {
        proofInfo.current = {
            ...PROOF_INIT,
            proofId: proofId
        };
        onFileReset();
        handleSetDimension(SELECTION_INIT);

        if (htmlDynamicType === HTML_DYNAMIC_PROOF_TYPES.live.value) {
            setEntryFileDisableMode(false);
        }
    };

    const onFileReset = () => {
        setFileUploadStatus(fileUploadConstants.NONE);
        setImagePreviewURL({ url: '', errorMessage: '' });
        setProgressNow(0);
    };

    const startUploading = async (callbacks = {}) => {
        var s3TempInfo = getS3UploadInfo();
        attachment && uploadToS3(attachment, s3TempInfo, proofInfo.current.uploadPath, callbacks);
    };

    const startUploadingForZipLive = async (callbacks = {}) => {
        var s3TempInfo = getS3UploadInfo();
        attachmentRef && uploadToS3(attachmentRef?.current, s3TempInfo, proofInfo.current.uploadPath, callbacks);
    };

    const onFileUploadInit = (fileUploadPromise) => {
        dispatch(alertActions.loading(true));
    };

    const onProgressChange = (obj) => {
        console.log(obj);
        setProgressNow(obj.progressNow);
        setFileUploadStatus(fileUploadConstants.STARTED);
        setFileUploadTimeLeft(obj.stats.secondsLeft);
    };
    const onFileUploadCompleted = async (obj) => {
        dispatch(alertActions.loading(true));
        setFileUploadStatus(fileUploadConstants.COMPLETED);

        console.log(htmlDynamicType);

        if (htmlDynamicType === HTML_DYNAMIC_PROOF_TYPES.static.value) {

            let payload = {
                proofId: proofInfo.current.proofId,
                proofVersionId: proofInfo.current.proofVersionId,
                name: fileUploadObject.fileName.value,
                width: fileUploadObject.width.value,
                height: fileUploadObject.height.value,
                duration: fileUploadObject.duration.value,
                entryFileName: fileUploadObject.entryFileName.value,
                directoryNodeId: directoryNodeId || null
            };

            if (createMode === CREATE_PROOF_MODE.PROOF) {
                await proofService.createHTMLDynamicProof(payload);
                onSave(null);
            } else {
                var response = await proofService.addHTMLDynamicProofVersion(payload);
                onSave(response.proofVersion);
            }

        } else if (htmlDynamicType === HTML_DYNAMIC_PROOF_TYPES.live.value) {

            setEntryFileDisableMode(true);
            console.log("[HTML Zip Validation] Calling HTML Zip Validation............");

            var htmlZipValidatorPayload = {
                awsObjectKey: obj.awsObjectKey,
                proofId: proofInfo.current.proofId,
                proofVersionId: proofInfo.current.proofVersionId,
                entryFileName: fileUploadObject.entryFileName.value,
            }

            await proofService.createHTMLZipValidationRequest(htmlZipValidatorPayload);
        }
    };
    const uploadToS3 = (file, s3TempInfo, uploadPath, callbacks = {}) => {
        var callbacksCombined = {
            onFileUploadInit: onFileUploadInit,
            onProgressChange: onProgressChange,
            onFileUploadCompleted: onFileUploadCompleted,
            ...callbacks
        };
        s3Service.uploadToS3(file, s3TempInfo, callbacksCombined, uploadPath);
    };

    const renderTimeLeft = () => {
        var timeLeftInMinutes = Number((fileUploadTimeLeft / 60).toFixed(2));
        if (timeLeftInMinutes < 1) return <label className="ml-1">{fileUploadTimeLeft}s</label>;
        else return <label className="ml-1">{Math.ceil(timeLeftInMinutes)}min</label>;
    };

    const validateFields = async (fileUploadObject) => {
        var globalValidation = true;
        var validationCopy = { ...fileUploadObject };
        var { fileName, duration, width, height, entryFileName } = fileUploadObject;

        var validationResult = directoryService.validateWebsiteDisplayName(fileName.value);
        let fileNameValidated = validationResult.isValid && fileName.value?.length < Display_Name_length;

        let message = validationResult.reason !== undefined
            ? `${initialFileObject.fileName.message}.(${validationResult.reason})`
            : fileName.length < Display_Name_length
                ? `${initialFileObject.fileName.message}`
                : 'Max character limit reached';

        validationCopy.fileName = {
            ...validationCopy.fileName,
            validated: fileNameValidated,
            message: message
        };
        globalValidation = fileNameValidated;

        if (htmlDynamicType === HTML_DYNAMIC_PROOF_TYPES.static.value) {
            if (!duration.value || duration.value < 5 || duration.value > 300) {
                validationCopy.duration = {
                    ...duration,
                    validated: false
                };
                globalValidation = false;
            }

            if (!width.value || width.value < 10 || width.value > 1920) {
                validationCopy.width = {
                    ...width,
                    validated: false
                };
                globalValidation = false;
            }

            if (!height.value || height.value < 10 || height.value > 3000) {
                validationCopy.height = {
                    ...height,
                    validated: false
                };
                globalValidation = false;
            }
        }

        let entryFileNameValidated =
            directoryService.validateFilnameWithExtention(entryFileName.value || '') &&
            entryFileName.value?.length < 500;

        if (!entryFileName.value || !entryFileNameValidated) {
            validationCopy.entryFileName = {
                ...entryFileName,
                validated: false
            };
            globalValidation = false;
        }

        setFileUploadObject({
            ...validationCopy,
            submitted: true,
            canSave: false
        });
        return globalValidation;
    };

    const validatePreviewFields = async (fileUploadObject) => {
        var globalValidation = true;
        var validationCopy = { ...fileUploadObject };
        var { entryFileName } = fileUploadObject;

        let entryFileNameValidated =
            directoryService.validateFilnameWithExtention(entryFileName.value || '') &&
            entryFileName.value?.length < 500;

        if (!entryFileName.value || !entryFileNameValidated) {
            validationCopy.entryFileName = {
                ...entryFileName,
                validated: false
            };
            globalValidation = false;
        }

        setFileUploadObject({
            ...validationCopy,
            submitted: true,
            canSave: false,
            canPreview: false
        });
        return globalValidation;
    };

    const handleSave = async () => {
        if (!(await validateFields(fileUploadObject)) || !!!attachment) {
            return;
        }

        if (htmlDynamicType === HTML_DYNAMIC_PROOF_TYPES.static.value) {
            onUploadStart && onUploadStart();

            if (fileUploadStatus === fileUploadConstants.COMPLETED) {
                await onFileUploadCompleted();
            } else {
                startUploading();
            }
        } else if (htmlDynamicType === HTML_DYNAMIC_PROOF_TYPES.live.value) {

            let payload = {
                proofId: proofInfo.current.proofId,
                proofVersionId: proofInfo.current.proofVersionId,
                name: fileUploadObject.fileName.value,
                entryFileName: fileUploadObject.entryFileName.value,
                directoryNodeId: directoryNodeId || null
            };

            if (createMode === CREATE_PROOF_MODE.PROOF) {
                await proofService.createHTMLDynamicLiveProof(payload);
                onSave(null);
            } else {
                var response = await proofService.addHTMLDynamicLiveProofVersion(payload);
                onSave(response.proofVersion);
            }
        }
    };

    const handleClose = () => {
        onClose();
    };

    const onDimensionGet = async () => {
        if (!(await validatePreviewFields(fileUploadObject)) || !!!attachment) {
            return;
        }

        if (imagePreviewURL?.url || imagePreviewURL?.errorMessage) {
            setShowDimentionSelector(true);
        } else {
            let callbacks = {
                onFileUploadCompleted: async () => {
                    dispatch(alertActions.loading(false));
                    setProgressNow(100);
                    setFileUploadStatus(fileUploadConstants.COMPLETED);
                    let payload = {
                        proofId: proofInfo.current.proofId,
                        proofVersionId: proofInfo.current.proofVersionId,
                        name: attachment.name,
                        entryFileName: fileUploadObject.entryFileName.value,
                        directoryNodeId: directoryNodeId || null
                    };
                    await proofService.requestHTMLDynamicPreview(payload);

                    setShowDimentionSelector(true);
                }
            };
            await startUploading(callbacks);
        }
    };

    const handleSetDimension = (dimension) => {
        let updated = { ...fileUploadObject, submitted: false, canSave: true };
        updated.width.value = dimension.width;
        updated.height.value = dimension.height;
        updated.width.validated = true;
        updated.height.validated = true;
        // let canSave = await validateFields(updated);
        setSelectedDimension({ ...selectedDimension, ...dimension });
        setFileUploadObject({ ...updated });
        setShowDimentionSelector(false);
    };

    const onCloseDimensionPreview = () => {
        let updated = { ...fileUploadObject, submitted: false, canSave: true };
        setFileUploadObject({ ...updated });
        setShowDimentionSelector(false);
    };

    const {
        fileName: { value: fileName, validated: fileNameValidated, message: fileNameMessage },
        duration: { value: duration, validated: durationValidated, message: durationMessage },
        width: { value: width, validated: widthValidated, message: widthMessage },
        height: { value: height, validated: heightValidated, message: heightMessage },
        entryFileName: {
            value: entryFileName,
            validated: entryFileNameValidated,
            message: entryFileNameMessage
        },
        submitted,
        canSave,
        canPreview
    } = fileUploadObject;

    const isFileUploading =
        fileUploadStatus === fileUploadConstants.STARTED ||
        fileUploadStatus === fileUploadConstants.INITIATED ||
        fileUploadStatus === fileUploadConstants.FINALIZING;

    const enableActionButton = canSave && !!attachment
    const disableFileUpload = isFileUploading;

    const enablePreviewButton = attachment && !isFileUploading;
    const inputsRightComponentStyle = { paddingLeft: '12px', paddingRight: '12px', minWidth: '56px', textAlign: 'center' };
    // || fileUploadStatus == fileUploadConstants.COMPLETED;

    const { theme, themeColors } = useTheme();

    return (
        <div
            style={{
                display: isVisible ? 'block' : 'none'
            }}>
            {showGuide && <CreateProofHTMLDynamicGuide onClose={() => setShowGuide(false)} />}
            {showDimentionSelector && (
                <CreateProofHTMLDynamicPreview
                    initialDimension={{
                        ...selectedDimension,
                        height: !height || height < 10 ? 200 : height,
                        width: !width || width < 10 ? 200 : width
                    }}
                    imagePreviewURL={imagePreviewURL}
                    onClose={onCloseDimensionPreview}
                    onConfirm={handleSetDimension}
                />
            )}
            <div
                style={{
                    overflow: 'hidden auto',
                    flexDirection: 'row',
                    flex: 1,
                    justifyContent: 'space-evenly',
                    padding: '0px 34px',
                    minHeight: '400px',
                    alignItems: 'center'
                }}>

                <div style={{
                    overflow: 'hidden auto',
                    flexDirection: 'row',
                    flex: 1,
                    justifyContent: 'space-evenly',
                    padding: '30px 34px 20px 34px',                    
                    alignItems: 'center'
                }}>
                    <div style={{
                        display: 'table-row',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                    }}>

                        <label htmlFor="link">Select type</label>
                        <p className="mb-1">Select the type of you website</p>

                    </div>

                    <div className={`form-group flex-column mb-4 d-flex`} style={{}}>
                        <div style={{ width: '100%', marginTop: '12px' }}>
                            <ButtonGroup>


                                {Object.keys(HTML_DYNAMIC_PROOF_TYPES).map((key, i) => {

                                    const type = HTML_DYNAMIC_PROOF_TYPES[key];
                                    let isSelected = type.value === htmlDynamicType;


                                    return (
                                        <Button
                                            key={type.value}
                                            title={type.tooltip}
                                            style={{
                                                width: 'auto',
                                                height: 'auto',
                                                padding: '12px',
                                                borderRadius: '4px',
                                                border: `solid 1px ${colorUtilService.changeOpacity(themeColors.primaryTextColor, 0.05)}`,
                                                marginRight: '12px',
                                                backgroundColor: 'transparent',
                                                color: 'var(--primary-text-color)'
                                            }}
                                            variant="secondary"
                                            onClick={() => {
                                                onFileClear();
                                                setFileUploadObject(initialFileObject);
                                                setHtmlDynamicType(type.value);
                                            }}>
                                            <svg style={{ marginRight: '8px' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <rect width="20" height="20" rx="10" fill={isSelected === true ? 'var(--primary-color)' : colorUtilService.changeOpacity(themeColors.primaryTextColor, 0.05)} />
                                                <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="white" stroke-opacity="0.2" />
                                                {isSelected &&
                                                    <circle cx="10" cy="10" r="4" fill={'var(--primary-text-color)'} />
                                                }
                                            </svg>
                                            {type.label}
                                        </Button>
                                    );
                                })}

                            </ButtonGroup>
                        </div>
                    </div>

                </div>

                {showGuide && <CreateProofHTMLDynamicGuide onClose={() => setShowGuide(false)} />}

                {showDimentionSelector && (
                    <CreateProofHTMLDynamicPreview
                        initialDimension={{
                            ...selectedDimension,
                            height: !height || height < 10 ? 200 : height,
                            width: !width || width < 10 ? 200 : width
                        }}
                        imagePreviewURL={imagePreviewURL}
                        onClose={onCloseDimensionPreview}
                        onConfirm={handleSetDimension}
                    />
                )}

                <div
                    style={{
                        overflow: 'hidden auto',
                        flexDirection: 'row',
                        flex: 1,
                        justifyContent: 'space-evenly',
                        padding: '30px 20px 20px 20px',
                        minHeight: '400px',
                        alignItems: 'center'
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            paddingRight: htmlDynamicType === HTML_DYNAMIC_PROOF_TYPES.static.value ? '0px' : '50%'
                        }}>
                        <div className={'form-group create-proof-input-group margin-_5x--bottom'}>
                            <label htmlFor="fileName" className="create-proof-input-label">
                                Display Name
                            </label>
                            <p
                                style={{
                                    opacity: '.5',
                                    fontSize: '11px',
                                    fontStyle: 'italic',
                                    width: '310px',
                                    marginBottom: '10px'
                                }}>
                                Valid alphanumeric file name
                            </p>

                            <CustomInput
                                name='fileName'
                                handleTextChange={handleTextChange}
                                RightComponent={<div style={inputsRightComponentStyle}  >.html</div>}
                            />
                            <div
                                className={'help-block'}
                                style={{ width: '310px', opacity: !fileNameValidated ? 1 : 0 }}>
                                {fileNameMessage}
                            </div>
                        </div>

                        {
                            htmlDynamicType === HTML_DYNAMIC_PROOF_TYPES.static.value &&

                            <div className={'form-group create-proof-input-group margin-_5x--bottom'}>
                                <label htmlFor="fileName" className="create-proof-input-label">
                                    Duration
                                </label>
                                <p
                                    style={{
                                        opacity: '.5',
                                        fontSize: '11px',
                                        fontStyle: 'italic',
                                        width: '310px',
                                        marginBottom: '10px'
                                    }}>
                                    Capture duration between 5 and 300 seconds
                                </p>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CustomInput
                                        style={{ width: '100%', }}
                                        type="number"
                                        name="duration"
                                        handleTextChange={handleTextChange}
                                        RightComponent={<div style={inputsRightComponentStyle}  >sec</div>}
                                    />
                                </div>
                                <div
                                    className={'help-block'}
                                    style={{ opacity: !durationValidated ? 1 : 0 }}>
                                    {durationMessage}
                                </div>
                            </div>

                        }

                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center'
                        }}>
                        <div className={'form-group create-proof-input-group margin-_5x--bottom'}>
                            <label htmlFor="fileName" className="create-proof-input-label">
                                File
                            </label>
                            <p
                                style={{
                                    opacity: '.5',
                                    fontSize: '11px',
                                    fontStyle: 'italic',
                                    width: '310px',
                                    marginBottom: '4px'
                                }}>
                                Valid HTML Package zip file.{' '}
                                <a
                                    href="javascript:void(0)"
                                    tabIndex={1}
                                    className="typography--break"
                                    onClick={() => setShowGuide(true)}>
                                    Show me
                                </a>
                            </p>
                            <input
                                type="file"
                                ref={fileInputRef}
                                id="file-dropzone"
                                accept=".zip"
                                className="file__input js-file__input mr-2 mb-2"
                                onChange={onFileChange}
                            />
                            {!!!attachment ? (
                                <div style={{ marginTop: '6px' }}>
                                    <button
                                        className="mrnda-btn--secondary mrnda-btn--full"
                                        style={{ padding: 10, display: 'block' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            fileInputRef?.current?.click();
                                        }}>
                                        <i className="fa fa-paperclip mr-2" />
                                        Choose file
                                    </button>
                                </div>
                            ) : (

                                <>
                                    <div
                                        style={{ cursor: 'pointer', maxWidth: 312, margin: 0 }}
                                        className={cx({
                                            'attachment-details__container': true,
                                            'd-none': !attachment
                                        })}
                                        onClick={() => {
                                            !disableFileUpload && fileInputRef?.current?.click();
                                        }}>
                                        <div className="attachment-output__list-item-container">
                                            <div className="attachment-output__list-item-header">
                                                <i className="fas fa-paperclip" />
                                                <span className="attachment-output__list-item-name text-truncate">
                                                    {attachment?.name}
                                                </span>
                                                {!disableFileUpload && (
                                                    <span
                                                        className="attachment-close"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            fileInputRef.current.value = null;
                                                            setIsInvalidDynamicLiveZip(false);
                                                            setEntryFileDisableMode(false);
                                                            setAttachment(null);
                                                        }}>
                                                        <i className="fas fa-times" />
                                                    </span>
                                                )}
                                            </div>
                                            {attachment && (
                                                <div className="attachment-output__list-item-footer">
                                                    <div className="attachment-output__list-item-footer__size">
                                                        <label className="">
                                                            {(attachment.size / 1048576).toFixed(2)} mb
                                                        </label>
                                                    </div>
                                                    {fileUploadStatus === fileUploadConstants.STARTED ||
                                                        (fileUploadStatus ===
                                                            fileUploadConstants.COMPLETED && (
                                                                <>
                                                                    {fileUploadStatus ===
                                                                        fileUploadConstants.STARTED && (
                                                                            <Spinner
                                                                                className="upload-spinner"
                                                                                animation="grow"
                                                                                variant="dark"
                                                                                size="sm"
                                                                            />
                                                                        )}

                                                                    <label className="mr-1 file-output__list-item-status__label">
                                                                        {fileUploadStatus ===
                                                                            fileUploadConstants.STARTED
                                                                            ? 'Uploading...'
                                                                            : 'Upload Done'}
                                                                    </label>

                                                                </>
                                                            ))}
                                                    {fileUploadStatus === fileUploadConstants.STARTED &&
                                                        progressNow > 0 &&
                                                        progressNow < 100 && (
                                                            <div className="attachment-output__list-item-footer__time">
                                                                {renderTimeLeft()}
                                                            </div>
                                                        )}
                                                </div>
                                            )}
                                        </div>

                                        <div className="attachment-output__list-item-middle-area">
                                            <ProgressBarComponent
                                                className="progress-bar-attachment"
                                                progressNow={progressNow}
                                            />
                                        </div>
                                    </div>

                                    {(htmlDynamicType === HTML_DYNAMIC_PROOF_TYPES.live.value && isInvalidDynamicLiveZip) && (
                                        <div
                                            className={'help-block'}
                                            style={{ width: '310px', opacity: 1 }}>
                                            {'Invalid Zip'}
                                        </div>
                                    )}



                                </>
                            )}
                            <div
                                className={'help-block'}
                                style={{ opacity: submitted && !!!attachment ? 1 : 0 }}>
                                {'Please select a valid zip file'}
                            </div>
                        </div>
                        <div className={'form-group create-proof-input-group margin-_5x--bottom'}>
                            <label htmlFor="fileName" className="create-proof-input-label">
                                Entry File Name
                            </label>
                            <p
                                style={{
                                    opacity: '.5',
                                    fontSize: '11px',
                                    fontStyle: 'italic',
                                    width: '310px',
                                    marginBottom: '10px'
                                }}>
                                Index file for the HTML Package. Default is index.html
                            </p>
                            <input
                                type="text"
                                className="form-control"
                                name="entryFileName"
                                value={entryFileName || ''}
                                onChange={handleTextChange}
                                autoComplete="off"
                                disabled={entryFileDisableMode}
                            />
                            <div
                                className={'help-block'}
                                style={{ opacity: !entryFileNameValidated ? 1 : 0 }}>
                                {entryFileNameMessage}
                            </div>
                        </div>
                    </div>

                    {
                        htmlDynamicType === HTML_DYNAMIC_PROOF_TYPES.static.value &&

                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: 16
                                }}>
                                <p
                                    style={{
                                        fontSize: '17px',
                                        textAlign: 'center'
                                    }}>
                                    {'Set Width & Height'}
                                </p>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    position: 'relative'
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        padding: '16px 24px'
                                    }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            flex: 1,
                                            borderRadius: 20,
                                            background: 'var(--block-background-color)'
                                        }}>
                                        <p
                                            style={{
                                                fontSize: '18px',
                                                marginBottom: '16px',
                                                textAlign: 'center'
                                            }}>
                                            {'From File'}
                                        </p>
                                        <Button
                                            //@ts-ignore
                                            variant=""
                                            disabled={!enablePreviewButton}
                                            className="mrnda-btn btn-sm"
                                            onClick={onDimensionGet}>
                                            Get Dimensions
                                        </Button>
                                    </div>
                                </div>
                                <p
                                    style={{
                                        fontSize: '16px',
                                        marginBottom: '10px',
                                        position: 'absolute',
                                        top: '42%',
                                        left: '48%',
                                        background: 'var(--primary-background-color)',
                                        padding: '20px 0px'
                                    }}>
                                    {'Or'}
                                </p>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        flex: 1,
                                        borderLeft: '1px solid var(--control-border-color)',
                                        padding: '16px 36px 0px 36px',
                                        marginLeft: 12
                                    }}>
                                    <p
                                        style={{
                                            fontSize: '16px',
                                            marginBottom: '18px'
                                        }}>
                                        {'Manually'}
                                    </p>
                                    <div className={'form-group create-proof-input-group margin-_5x--bottom'}>
                                        <label
                                            style={{ fontSize: 13, marginBottom: 0 }}
                                            htmlFor="fileName"
                                            className="create-proof-input-label">
                                            Width
                                        </label>
                                        <p
                                            style={{
                                                opacity: '.5',
                                                fontSize: '10px',
                                                fontStyle: 'italic',
                                                marginBottom: '4px'
                                            }}>
                                            Captured area width between 10px and 1920px
                                        </p>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <CustomInput
                                                style={{ width: '100%' }}
                                                type="number"
                                                name="width"
                                                value={width}
                                                handleTextChange={handleTextChange}
                                                RightComponent={<div style={inputsRightComponentStyle}>px</div>}
                                            />
                                        </div>

                                        <div
                                            className={'help-block'}
                                            style={{ opacity: !widthValidated ? 1 : 0 }}>
                                            {widthMessage}
                                        </div>
                                    </div>
                                    <div className={'form-group create-proof-input-group margin-_5x--bottom'}>
                                        <label
                                            style={{ fontSize: 13, marginBottom: 0 }}
                                            htmlFor="fileName"
                                            className="create-proof-input-label">
                                            Height
                                        </label>
                                        <p
                                            style={{
                                                opacity: '.5',
                                                fontSize: '10px',
                                                fontStyle: 'italic',
                                                // width: '310px',
                                                marginBottom: '4px'
                                            }}>
                                            Capture area Height between 10px and 3000px
                                        </p>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <CustomInput
                                                style={{ width: '100%' }}
                                                type="number"
                                                name="height"
                                                value={height}
                                                handleTextChange={handleTextChange}
                                                RightComponent={<div style={inputsRightComponentStyle}>px</div>}
                                            />
                                        </div>
                                        <div
                                            className={'help-block'}
                                            style={{ opacity: !heightValidated ? 1 : 0 }}>
                                            {heightMessage}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }
                </div>


            </div>
            <Modal.Footer
                style={{
                    borderTop: '1px solid var(--border-color)',
                    backgroundColor: 'var(--primary-background-color)'
                }}>
                <Button
                    //@ts-ignore
                    style={{ width: 72 }}
                    variant=""
                    disabled={!enableActionButton}
                    className="mrnda-btn btn-sm"
                    onClick={handleSave}>
                    Save
                </Button>
                <Button
                    //@ts-ignore
                    variant=""
                    className="mrnda-btn--secondary btn-sm"
                    disabled={disableFileUpload}
                    onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </div>
    );
};

export default CreateProofHTMLDynamicView;
