import { utilService } from '../../../_helpers';
import { getProgressMarkerPosition } from './VideoAnnotationUtil';

const MAX_MARKER_IN_FRAME = 2;
const FRAME_DIFFERENCE_THRESHOLD = 5;

const MarkerPosition = {
    START: 'start',
    CENTER: 'center',
    END: 'end'
};

const getAlignment = (totalAnnotationsInFrame, index) => {
    let position;
    let hasMultiple = false;
    if (totalAnnotationsInFrame === 1) {
        position = MarkerPosition.CENTER;
    } else {
        if (index == 0) {
            position = MarkerPosition.START;
        } else {
            hasMultiple = true;
            position = MarkerPosition.END;
        }
    }
    return { position, hasMultiple };
};

const inFrameRange = (elementFrame, targetFrame) => {
    var targetStart = targetFrame - FRAME_DIFFERENCE_THRESHOLD;
    var targetEnd = targetFrame + FRAME_DIFFERENCE_THRESHOLD;

    return elementFrame >= targetStart && elementFrame <= targetEnd;
};

const getMarkerPosition = (frames, position = 'top', frameCount) => {    
    let markerPosition = {};
    var startFrame = frames[0];
    var endFrame = frames[1];
    let top = 18;

    switch (position) {
        case MarkerPosition.start:
            top = top + 0;
            break;
        case MarkerPosition.CENTER:
            top = top + 10;
            break;
        case MarkerPosition.END:
            top = top + 20;
            break;
    }

    var position = getProgressMarkerPosition(startFrame, frameCount);

    markerPosition.left = position;

    if (top) {
        markerPosition.top = top;
    }

    return markerPosition;
};

const createMarker = (start, comment, position, hasMultiple, allCommentsInTheFrame, frameCount) => {
    var style = getMarkerPosition(comment.frameRange, position, frameCount);
    var marker = {
        comment: comment,
        id: comment.id,
        style,
        startFrame: start,
        userInitial: utilService.getUserInitial(comment.createdBy),
        title: comment.content,
        alignment: position        
    };
    if (hasMultiple === true && allCommentsInTheFrame.length > 2) {
        marker.userInitial = `+${allCommentsInTheFrame.length - 1}`;
        marker.isShortcut = true;
        marker.title = `${allCommentsInTheFrame.length - 1} more annotations`;
    }

    return marker;
};

const createMarkers = (comments, frameCount, current) => {
    comments.forEach((element) => {
        var start = element.frameRange[0];

        var shouldIgnore = current.some((x) => x.startFrame === start);
        if (shouldIgnore === true) {
            return current;
        }

        var allCommentsInTheFrame = comments.filter((x) => inFrameRange(x.frameRange[0], start));

        if (allCommentsInTheFrame.length > 1) {
            //setIsMultiLine(true);
        }

        let markers = [];

        allCommentsInTheFrame.forEach((comment, index) => {
            let { position, hasMultiple } = getAlignment(allCommentsInTheFrame.length, index);
            let marker = createMarker(
                start,
                comment,
                position,
                hasMultiple,
                allCommentsInTheFrame,
                frameCount
            );
            markers = [...markers, marker];
            if (index === MAX_MARKER_IN_FRAME) {
                return;
            }
        });

        current = [...current, ...markers];
    });

    return current;
};

export { getAlignment, createMarkers, MarkerPosition, getMarkerPosition };
