import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
class ConfirmationModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            canPressPrimary: true
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.show !== prevState.show) {
            return { show: nextProps.show };
        } else {
            return null;
        }
    }
    onPrimaryClick() {
        this.setState({ show: false, canPressPrimary: false });
        this.props.onPrimaryClick();
    }
    onSecondaryClick() {
        this.setState({ show: false });
        this.props.onSecondaryClick();
    }
    render() {
        // console.log('ConfirmationModal Props: ', this.props);
        return (
            <Modal
                //{...this.props}
                show={this.props.show}
                onHide={this.onSecondaryClick.bind(this)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'}
                scrollable>
                <Modal.Header closeButton={!this.props.disableClose}>
                    {/* <Modal.Title id="contained-modal-title-vcenter">
                        {this.props.title}
                    </Modal.Title> */}
                    <h5>{this.props.title}</h5>
                </Modal.Header>
                <Modal.Body>
                   {this.props.body ? (this.props.body) : (<h6>{this.props.bodyText}</h6>)}
                </Modal.Body>
                <Modal.Footer>
                {this.props.primaryButtonText && (
                    <Button
                        variant=""
                        className="mrnda-btn btn-sm"
                        onClick={this.onPrimaryClick.bind(this)}
                        style={{ minWidth: 60 }}
                        // disabled={this.props.isProcessing}
                        disabled={!this.state.canPressPrimary}>
                        {this.props.primaryButtonText}
                    </Button>
                    )}
                    {this.props.secondaryButtonText && (
                        <Button
                            className="btn-sm mrnda-btn--secondary"
                            onClick={this.onSecondaryClick.bind(this)}
                            style={{ minWidth: 60 }}>
                            {this.props.secondaryButtonText}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    // console.log('States in CreateProofModal:', state);
    const { alert } = state;
    return {
        isProcessing: alert && alert.isLoading ? true : false
    };
}

const connectedCreateProofModel = connect(mapStateToProps)(ConfirmationModal);
export { connectedCreateProofModel as ConfirmationModal };
