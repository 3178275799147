import React from 'react';

export function PasswordInputs({
    submitted,
    validations,
    passwordValue,
    confirmPasswordValue,
    onChange,
}) {
    return (
        <>
            <p
                style={{
                    opacity: '.5',
                    fontSize: '11px',
                    fontStyle: 'italic',
                    width: '310px',
                    marginBottom: '10px',
                }}>
                The password must consist of at least 8 characters and a mix of numbers and letters
                both uppercase and lowercase.
            </p>
            {submitted && !validations.password.validated && (
                <div className="help-block">{validations.password.message}</div>
            )}
            <input
                type="password"
                id="password"
                name="password"
                value={passwordValue}
                onChange={(e) => onChange(e)}
                className="mrnda-input-text margin-2x--bottom"
                placeholder="Password"
                autoFocus=""
                required
            />
            {submitted && !validations.confirmPassword.validated && (
                <div className="help-block">{validations.confirmPassword.message}</div>
            )}
            <input
                type="password"
                id="confirmPassword"
                className="mrnda-input-text margin-2x--bottom"
                name="confirmPassword"
                value={confirmPasswordValue}
                onChange={(e) => onChange(e)}
                placeholder="Confirm Password"
                required
            />
        </>
    );
}
