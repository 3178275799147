import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { history, utilService } from '../../../_helpers';
import { ListSelector } from '../../_shared';

class ProofVersionPicker extends React.Component {
    constructor(props) {
        super(props);
    }
    // static getDerivedStateFromProps(nextProps) {
    //     if (nextProps.s3TempInfo && nextProps.s3TempInfo.documentId) {
    //         return {
    //             documentId: nextProps.s3TempInfo.documentId
    //         };
    //     } else return null;
    // }
    componentDidMount() {
        console.log('Bing');
    }
    onModalClose() {
        this.props.onHide();
    }
    onProofVersionSelectorChange() {
        this.validateCompare();
    }
    async onCompare() {
        var versionA = this.state.proofVersions.find(x => x.id === this.state.versionIds[0]);
        var versionB = this.state.proofVersions.find(x => x.id === this.state.versionIds[1]);
        await utilService.setStateAsync.bind(this)({
            proofCompareVersions: [versionA, versionB],
        });
        if (this.state.versionIds && this.state.versionIds.length > 1) {
            history.push({
                pathname: `/proofcompare`,
                // search: `?proofId=${this.state.proof.id}&documentAId=${
                //     this.state.documentIds[0]
                // }&documentBId=${this.state.documentIds[1]}`,
                data: {
                    proof: this.state.proof,
                    proofCompareVersions: this.state.proofCompareVersions,
                },
            });
        }
    }
    validateCompare() {
        var documentAId = this.refs.versionSelectorA.value;
        var documentBId = this.refs.versionSelectorB.value;
        if (documentAId == '-1' || documentBId == '-1' || documentAId === documentBId) {
            // this.props.dispatch(alertActions.warn("Select different versions."))
            this.setState({ canCompare: false });
        } else {
            this.setState({
                canCompare: true,
                versionIds: [documentAId, documentBId],
            });
        }
    }
    isThisVersionDefault(versionId) {
        var result = false;
        if (versionId === this.state.proof.defaultVersionId) result = true;
        return result;
    }
    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'}
                scrollable>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        File Version Compare
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <ListSelector></ListSelector>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="mrnda-btn--secondary" onClick={this.onModalClose.bind(this)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    return {};
}

const connectedCreateProofModel = connect(mapStateToProps)(ProofVersionPicker);
export { connectedCreateProofModel as ProofVersionPicker };
