import React from 'react';
import { Link } from 'react-router-dom';

export function NavMiddleArea(props) {
    return (
        <ul className="mrnda-primary-navigation" style={{flex:'1'}}>
            <li className="mrnda-primary-navigation__item mrnda-primary-navigation__item--active d-none">
                <Link to="/" className="mrnda-primary-navigation__link">
                    Home <span className="sr-only">(current)</span>
                </Link>
            </li>
            {props.authentication && Object.getOwnPropertyNames(props.authentication).length !== 0 && (
                <li className="mrnda-primary-navigation__item d-none">
                    <Link to="/dashboard/folders" className="mrnda-primary-navigation__link">
                        Dashboard
                    </Link>
                </li>
            )}
        </ul>
    );
}
