import React from 'react';
import { connect } from 'react-redux';
import { utilService } from '../../_helpers';
import { userActions, roleActions, groupActions, Grid, providerActions } from '../../components';
import { Filters } from 'react-data-grid-addons';
import { UserModal } from './UserModal';
import { AUTH_PROVIDER_MAP, userService } from '../User';
import { DataFetcher } from '../_shared/DataFetcher/DataFetcher';
import SearchComponent from './SearchComponent';

const columns = [
    // { key: 'documentId', name: 'Id' ,  formatter: viewButtonFormatter},
    // {
    //     key: 'firstName',
    //     name: 'FirstName',
    //     // filterRenderer: AutoCompleteFilter,
    // },
    { key: 'fullName', name: 'Name', width: 200 },
    { key: 'isEnabled', name: 'Status' },
    { key: 'lastLogin', name: 'Last Login', width: 200 },
    { key: 'registrationStatus', name: 'Reg.' },
    { key: 'email', name: 'Email', width: 250 },
    { key: 'authProviderName', name: 'Login Provider', width: 190},
    { key: 'roleName', name: 'Role' },
    { key: 'groupsNames', name: 'Groups', width: 400 },
];
const defaultColumnProperties = {
    sortable: true,
    filterable: true,
    resizable: true,
};
class UserManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            groups: [],
            roles: [],
            showUserModal: false,
            columns: columns.map(c => ({
                ...c,
                ...defaultColumnProperties,
            })),
            selectedRow: null,
            gridView: 'folderView',
            pageSize: 20,
            pageIndex: 0,
            totalPages: 1,
            totalCount: 0,
            sortColumn: null,
            sortDirection: 'NONE',
            filtersParam: [],
            proofsFilters: {},
            selectedUser: {},
            operationMode: 0,
            providers: [], 
            dataLoaded: false,                    
        };
    }

    async fetchData () {

        await this.loadDataFromServer();            
        // await this.loadGroupDataFromServer();
        // await this.loadRoleDataFromServer();                
        // await this.loadProviderDataFromServer();

        this.setState({
            dataLoaded: true
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.users) {
            // var groupsNames = nextProps.groups
            //     ? nextProps.groups.map(item => item.name).join()
            //     : 'Not Assigned';
            var rowsTemp = [...nextProps.users];
            const rows = rowsTemp.map(userItem => {
                var newUserItem = { ...userItem };
                userItem.isEnabled === true
                    ? (newUserItem.isEnabled = 'Enabled')
                    : (newUserItem.isEnabled = 'Disabled');
                if (new Date(userItem.lastLogin) > new Date('1971-03-26T00:00:00.001Z')) {
                    newUserItem.lastLogin = new Date(userItem.lastLogin).toLocaleString();
                } else {
                    newUserItem.lastLogin = 'Not yet';
                }
                newUserItem.loginProvider = 'Local';

                userItem.authProvider > 0
                    ? (newUserItem.registrationStatus = 'Yes')
                    : (newUserItem.registrationStatus = 'No');

                newUserItem.authProviderName = AUTH_PROVIDER_MAP[userItem.authProvider];
                // var groupsNames =
                //     nextProps.groups && userItem.groups && userItem.groups.length > 0
                //         ? nextProps.groups
                //               .filter(groupsItem => userItem.groups.includes(groupsItem.id))
                //               .map(filterredItem => filterredItem.name)
                //               .join(', ')
                //         : 'Not Assigned';

                newUserItem.groupsNames = newUserItem.groupNames;
                // var role =
                //     nextProps.roles &&
                //     userItem.roleId &&
                //     nextProps.roles.find(roleItem => roleItem.id == userItem.roleId);
                // var roleName = role ? role.name : 'Not Assigned';
                // newUserItem.roleName = roleName;
                return newUserItem;
            });

            return {
                users: [...rows],
                groups: nextProps.groups ? nextProps.groups : prevState.groups,
                roles: nextProps.roles ? nextProps.roles : prevState.roles,
                providers: nextProps.providers ? nextProps.providers : prevState.providers,
                // pageSize: nextProps.pageSize,
                // pageIndex: nextProps.pageIndex,
                totalPages: nextProps.totalPages,
                totalCount: nextProps.totalCount,
                usersFilters: nextProps.usersFilters
                    ? nextProps.usersFilters
                    : prevState.usersFilters,
            };
        } else {
            return null;
        }
    }
    async loadDataFromServer() {
        const sortMapper = {
            ['ASC']: 1,
            ['DESC']: -1,
            ['NONE']: 1,
        };
        const data = {
            page: this.state.pageIndex,
            pageSize: this.state.pageSize,
            proofSortModel: {
                sortDirection: sortMapper[this.state.sortDirection],
                sortColumn: this.state.sortColumn,
            },
            proofFilters: this.state.filtersParam,
        };
        
        await this.props.dispatch(userActions.getAllV2(data));
        
    }

    async loadFilterDataFromServer() {
        await this.props.dispatch(userActions.getAllUserFilters());
    }
    handleGridSort(sCol, sDir) {
        var column = sCol === 'fullName' ? 'firstName' : sCol;
        this.setState({ pageIndex: 0, sortColumn: column, sortDirection: sDir }, () => {
            this.loadDataFromServer();
        });
    }
    handlePageIndexChange(pageIndex) {
        this.setState({ pageIndex: pageIndex }, () => {
            this.loadDataFromServer();
        });
    }
    handlePageSizeChange(psize) {
        this.setState({ pageSize: psize, pageIndex: 0 }, () => {
            this.loadDataFromServer();
        });
    }
    handleFilterChange(filtersParam) {
        console.log('FiltersParam', filtersParam);
        var filtersPayload = [];
        for (var key in filtersParam) {
            if (filtersParam.hasOwnProperty(key)) {
                if (Array.isArray(filtersParam[key].filterTerm)) {
                    filtersParam[key].filterTerm.forEach(function(item) {
                        const filterItem = {
                            filterColumn: filtersParam[key].column.key,                               
                            filterValue: item.value,
                        };
                        filtersPayload.push(filterItem);
                    });
                } else {
                    const filterItem = {
                        filterColumn: filtersParam[key].column.key,
                        filterValue: filtersParam[key].filterTerm,
                    };
                    filtersPayload.push(filterItem);
                }
            }
        }
        this.setState({ pageIndex: 0, filtersParam: filtersPayload }, () => {
            this.loadDataFromServer();
        });
    }
    async onRowSelected(row) {
        await utilService.setStateAsync.bind(this)({ selectedUser: row, operationMode: 2 });
        await utilService.setStateAsync.bind(this)({ showUserModal: true });
    }
    async onNewUserClick() {
        await utilService.setStateAsync.bind(this)({ selectedUser: {}, operationMode: 1 });
        await utilService.setStateAsync.bind(this)({ showUserModal: true });
    }

    async handleSearchClick(value) {
        var paramObj = {
            page: 0,
            pageSize: 20,
            proofFilters: value ? [{ filterColumn: 'SearchText', filterValue: value }] : [],
            proofSortModel: { sortDirection: 1, sortColumn: null },
        };        
        this.props.dispatch(userActions.searchUsers(paramObj));       
    }



    

    async handleSearchClearClick(value) {
        //NOTE: Try to add a way to keep and show the users that we in view before search was initiating.
        var paramObj = {
            page: 0,
            pageSize: 20,
            proofFilters: value ? [{ filterColumn: 'SearchText', filterValue: value }] : [],
            proofSortModel: { sortDirection: 1, sortColumn: null },
        };        
        this.props.dispatch(userActions.searchUsers(paramObj));       
    }
    render() {
        return (
            <>
                <div className="settings-view__manage__container">
                    <div className="m-2">
                        <div className="settings-view-modal">
                            <button
                                id="editor-close-btn"
                                type="button"
                                className="close modal-close"
                                title="Close Modal"
                                onClick={this.props.onClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h1>User</h1>
                            <div className="action-panel">
                                <div className="_s_LeftPanel pull-left">
                                    <div className="actionpanel-element">
                                        <div className='user-manage-header'>
                                            <button
                                                type="button"
                                                id=""
                                                className="btn mrnda-btn new-item-btn"
                                                onClick={this.onNewUserClick.bind(this)}
                                                style={{ opacity : this.state.dataLoaded === true?  '1' : 0}}>
                                                <i className="fa fa-plus-circle"></i>
                                                <span className="btn-text _s_NewUserBtn ml-2" >
                                                    New User
                                                </span>
                                            </button>                                           
                                            <SearchComponent clearEnabled={true} handleSearch={this.handleSearchClick.bind(this)} handleCleared={this.handleSearchClearClick.bind(this)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-manage-body">
                                <DataFetcher
                                    onFetchData={this.fetchData.bind(this)}
                                    height="476px">
                                    <Grid
                                        columns={this.state.columns}
                                        rows={this.state.users}
                                        enableFilter={false}
                                        minHeight={420}
                                        proofsFilters={this.state.usersFilters}
                                        handleGridSort={this.handleGridSort.bind(this)}
                                        totalPages={this.state.totalPages}
                                        handlePageIndexChange={this.handlePageIndexChange.bind(
                                            this
                                        )}
                                        handlePageSizeChange={this.handlePageSizeChange.bind(this)}
                                        totalCount={this.state.totalCount}
                                        handleFilterChange={this.handleFilterChange.bind(this)}
                                        onRowSelected={(row) => {
                                            this.onRowSelected(row);
                                        }}
                                    />
                                </DataFetcher>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showUserModal && (
                    <UserModal
                        onClose={() => this.setState({ showUserModal: false })}
                        show={this.state.showUserModal}
                        userObject={this.state.selectedUser}
                        groups={this.state.groups}
                        roles={this.state.roles}
                        providers={this.state.providers}
                        operationMode={this.state.operationMode}
                    />
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    const { usersData, usersFilters, groupsData, rolesData, providersData} = state;
    const { rows: rowsUsers, pageIndex, pageSize, totalCount, totalPages } = usersData;
    const { rows: rowsGroups } = groupsData;
    const { rows: rowsRoles } = rolesData;
    const { rows: rowsProviders } = providersData;
    return {
        users: rowsUsers,
        groups: rowsGroups,
        roles: rowsRoles,
        pageIndex,
        pageSize,
        totalCount,
        totalPages,
        usersFilters: usersFilters.usersFilters,
        providers: rowsProviders,
    };
}

const connectedSettingsPage = connect(mapStateToProps)(UserManage);
export { connectedSettingsPage as UserManage };
