import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { config } from '../../../_helpers';

var globalClient;

function createClient(onEcho, onNotify, targets) {
    const client = new W3CWebSocket(config.liveUpdateWSURL);
    globalClient = client;
    client.onopen = () => {
        client.onmessage = (params) => {
            onEcho(params, targets);
            client.onmessage = onNotify;
        };
        console.log('WebSocket Client Connected');
        client.send(
            JSON.stringify({
                action: 'echo',
                reason: 'Confirming the connection'
            })
        );
    };
    
    client.onclose = onClose;

    return;
}

async function createClientAsync(onNotify, onClose, targets) {
    return new Promise((resolve, reject) => {
        try {
            if (config.liveUpdateWSURL === ''){
                reject('Url is empty!');
            }
            const client = new W3CWebSocket(config.liveUpdateWSURL);
            globalClient = client;
            client.onopen = () => {
                client.onmessage = (params) => {
                    // onEcho(params, targets);
                    resolve({ params, targets });
                    client.onmessage = onNotify;
                };
                console.log('WebSocket Client Connected');
                client.send(
                    JSON.stringify({
                        action: 'echo',
                        reason: 'Confirming the connection'
                    })
                );
            };
            client.onerror = (error) => {
                reject(error);
            };
            client.onclose = onClose;
        } catch (error) {
            reject(error);
        }
    });
}

function closeClient() {
    globalClient && globalClient.close();
    console.log('WebSocket Client force disconnected');
}

export const websocketService = {
    createClient,
    createClientAsync,
    closeClient
};
