import React, { useState } from 'react';
import { MAX_CHAR_LIMIT, MAX_CHAR_LIMIT_TEXT } from './helpers/statusConstants';
import cx from "classnames";

const StatusEdit = ({
    commentStatus,
    handleCommentStatusUpdate,    
    setEditingComment,
    hiddenCommentStatuses, 
    visibleCommentStatuses}) => {


    const [isValueChanged, setIsValueChanged] = useState(false);
    const [info, setInfo] = useState({
        show: false,
        message: ''
    });

    const handleCancelStatusEdit = () => {
        setEditingComment(undefined);
        setIsValueChanged(false);
    };

    const onCommentStatusLabelChanged = (e) => {
        var { value } = e.target;
        console.log('ON CHANGE :', value);
        
        const commentStatuses =
            commentStatus.isVisible === true
                ? [...visibleCommentStatuses]
                : [...hiddenCommentStatuses];

        var copied = commentStatuses.map((status) => {
            var statusCopy = { ...status };

            if (status.id === commentStatus.id) {
                //make some change
                if ( statusCopy.label !== value){
                    setIsValueChanged(true);
                }
                statusCopy.label = value;                
                setEditingComment(statusCopy);
            }
            return statusCopy;
        });
    };

    const handleInputKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleCommentStatusUpdate();
        }
    };

    return (
        <div style={{width:"100%", marginTop:'8px', marginBottom:'8px' }}>
        <div className="customized-status-edit">            
                        
                        <input
                            defaultValue={commentStatus.label}
                            className="form-control mrnda-input-text"
                            maxLength={MAX_CHAR_LIMIT}
                            style={{ paddingLeft: '8px', paddingRight: '8px', marginLeft: '8px' }}
                            onKeyPress={handleInputKeyPress}
                            onChange={(e) => {
                                const { value } = e.target;
            
                                if (value.length >= MAX_CHAR_LIMIT) {
                                    setInfo({
                                        show: true,
                                        message: MAX_CHAR_LIMIT_TEXT
                                    });
                                } else {
                                    setInfo({
                                        show: false,
                                        message: ''
                                    });
                                }
                                onCommentStatusLabelChanged(e);
                            }}
                        />
            
            
                        <span
                            className={cx("mrnda-icon-btn customized-status-edit-save", { "authorization-disabled" : !isValueChanged })}
                            onClick={handleCommentStatusUpdate}>
                            <i className="fa fa-check"></i>
                        </span>
            
                        <span
                            className={"mrnda-icon-btn customized-status-edit-cancel"}
                            onClick={handleCancelStatusEdit}>
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                    {info.show === true && (
                        <div style={{ flex: '3 0 100%', marginLeft: '16px' }} className="help-block">
                            {info.message}
                        </div>
                    )}
        </div>
    );
};

export default StatusEdit;
