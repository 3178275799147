import { authHeader, handleResponse, config } from '../../../_helpers';

export const videoCommentService = {
    addVideoComment,
    addVideoCommentReply,
    updateVideoAnnotation,
    getVideoComments,
    deleteVideoComment,
    setVideoCommentStatus,
    deleteVideoCommentReply,
    getAttachment,
    deleteAttachment,
    updateCommentContent,
    updateReplyContent,
    addAnnotation
};

function getVideoComments(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/AnnotationComment/getVideoComments`, requestOptions).then(
        handleResponse
    );
}

function addVideoComment(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/AnnotationComment/AddVideoComment`, requestOptions).then(
        handleResponse
    );
}

function addAnnotation(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/AnnotationComment/AddVideoAnnotation`, requestOptions).then(
        handleResponse
    );
}

function getAttachment(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/AnnotationComment/GetAttachment`, requestOptions).then(
        handleResponse
    );
}

function deleteAttachment(request) {

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(
        `${config.apiUrl}/AnnotationComment/deleteAttachment`,
        requestOptions
    ).then(handleResponse);
}


function updateVideoAnnotation(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/AnnotationComment/UpdateVideoAnnotation`, requestOptions).then(
        handleResponse
    );
}

function deleteVideoComment(id) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
        // body: JSON.stringify(request)
    };
    return fetch(
        `${config.apiUrl}/AnnotationComment/deleteVideoComment/${id}`,
        requestOptions
    ).then(handleResponse);
}

function deleteVideoCommentReply(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(
        `${config.apiUrl}/AnnotationComment/deleteVideoCommentReply`,
        requestOptions
    ).then(handleResponse);
}

function setVideoCommentStatus(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/AnnotationComment/setVideoCommentStatus`, requestOptions).then(
        handleResponse
    );
}

function addVideoCommentReply(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/AnnotationComment/AddVideoCommentReply`, requestOptions).then(
        handleResponse
    );
}
function updateCommentContent(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/AnnotationComment/UpdateVideoCommentContent`, requestOptions).then(
        handleResponse
    );
}
function updateReplyContent(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    return fetch(`${config.apiUrl}/AnnotationComment/updateVideoReplyContent`, requestOptions).then(
        handleResponse
    );
}