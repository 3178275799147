export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    WARNING: 'ALERT_WARNING',
    CLEAR: 'ALERT_CLEAR',
    LOADING: 'ALERT_LOADING',
    PROOF_EDITOR_DIRTY: 'PROOF_EDITOR_DIRTY',
    HIDE_TOPBAR: 'HIDE_TOPBAR',
    DISABLE_NAV_ACTIONS: 'DISABLE_NAV_ACTIONS',
    HIDE_RIGHT_NAV_ACTIONS: 'HIDE_RIGHT_NAV_ACTIONS', 
    TOGGLE_NAVBAR: 'TOGGLE_NAVBAR',
};
