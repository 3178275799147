import React from 'react';
import { commentHelper } from '../../../../../_helpers/commentHelper';
import cx from 'classnames';
import { AttachmentPicker } from './AttachmentPicker';
import Button from '../../../../_shared/Button';

const CommentItemFooter = ({
    commentItem,
    commentReply,
    attachments,
    setAttachments,
    editAllowed,
    setSelectedComment,
    setRangeSelectorValue,
    handleOnCommentReply,
    onCommentDelete,
    canEdit,
    setEditMode,
    editMode,
    setEditingCommentId,
    ...rest
}) => {
    return (
        <div className="reply-form__footer">
            <span
                className={cx({
                    'delete-button': true,
                    'comment-delete-button': true,
                    'authorization-disabled': !commentHelper.canDelete(commentItem.createdById)
                })}
                title="Delete comment"
                onClick={() => onCommentDelete(commentItem)}>
                <i className="far fa-trash-alt"></i>
            </span>
            <AttachmentPicker attachments={attachments} setAttachments={setAttachments} />
            <span
                className={cx({
                    'delete-button': true,
                    'comment-edit-button': true,
                    'authorization-disabled': !commentHelper.showEdit(commentItem.createdById),
                    'edit-disabled': !editAllowed
                })}
                title={
                    editAllowed ? 'Edit comment' : 'Editing is not allowed anymore for this comment'
                }
                onMouseOver={() => {
                    canEdit();
                }}
                onClick={() => {
                    if (editAllowed === true) {
                        setEditMode(!editMode);
                        setEditingCommentId(commentItem.id);
                        if (setRangeSelectorValue) {
                            setRangeSelectorValue(commentItem.frameRange);
                        }
                    }
                }}>
                <i className="fas fa-pen"></i>
            </span>

            <button
                style={{ marginLeft: '16px' }}
                className="button button-comment-form--primary"
                onClick={handleOnCommentReply}
                disabled={commentReply === '' || commentReply === undefined}
                // disabled={props.canvasTextEditEntered}
            >
                <span>Reply</span>
            </button>
        </div>
    );
};

export default CommentItemFooter;
