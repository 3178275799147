//@ts-check
import React from 'react';
import { DrawingCanvas, ShapeAction, drawingConstants } from '@wunderman-thompson/miranda-drawing';
import { trimAnnotationObject } from '../DocumentAnnotationUtil';
import { withDocumentAnnotationConsumer } from '../Context/DocumentAnnotationConsumer';
import { alertActions } from '../../../App';
import { store, utilService } from '../../../../_helpers';
import { contributorsHelper } from '../../../../_helpers';
import { documentCommentService } from '../documentComment.service';
import { s3Service } from '../../../_shared';
import cx from 'classnames';
const { ADD } = ShapeAction;
import { calculateDocumentAnnotationCenterPoint } from '../../../../_helpers/annotationArrowHelper';
import { extractText, findWordsInSelection, handleCanvasObjectUpdate } from '../../canvasHelper';
import { PageBackdrop } from './PageBackdrop';
import { proofService } from '../../proof.service';
import { COMMENT_CHAR_LIMIT, EditorType, PROOF_TYPE, TEXT_EXTRACT_STATUS, TEXT_RECOGNITION_SETTINGS_TYPES } from '../../proof.constants';
import TextHighlightIcon from '../../../_shared/Icon/TextHighlightIcon';
import ProgressiveImg from './ProgressiveImg';
import WebsiteViewer from './WebsiteViewer';
import html2canvas from "html2canvas";
import DesktopBreakpoint from '../../../responsive/DesktopBreakpoint';


const ScreenshotRequest = "miranda-screenshot-request"

class DocumentPlayerWithCanvas extends React.Component {
    constructor(props) {
        super(props);
        this.player = null;
        this.state = {
            canvas: null,
            showConfigureMetadata: false,
            transformContainer: {},
            playerLoaded: false,
            isImageLoaded: false
        };
        this.mainDrawingCanvas = null;
        this.canvasContainer = null;
        this.onWindowResize = this.onWindowResize.bind(this);
        this.resizeTimer = null;
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        store.dispatch(alertActions.loading(true));
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        var isTextractAuto =
            this.props.proofSettings?.textRecognitionSettingType ===
            TEXT_RECOGNITION_SETTINGS_TYPES.auto.value;
        var page = this.props?.pages?.find((x) => x.pageNumber == this.props.index + 1);
        var pageNumber = this.props.index + 1;
        if (
            isTextractAuto &&
            !this.isDrawingMode() &&
            !this.props.side &&
            this.props.shouldLoad &&
            page &&
            page.textExtractFetchStatus !== TEXT_EXTRACT_STATUS.IN_PROGRESS &&
            page.textExtractFetchStatus !== TEXT_EXTRACT_STATUS.READY &&
            this.props?.proofVersion?.status?.textExtractStatus !== TEXT_EXTRACT_STATUS.IN_PROGRESS
        ) {
            this.updatePagesProperty(
                [
                    {
                        update: 'textExtractFetchStatus',
                        value: TEXT_EXTRACT_STATUS.IN_PROGRESS
                    }
                ],
                pageNumber
            );
            this.invokeTextExtractServiceRequest(pageNumber, (_, textContent) => {
                var targetObject = this.props?.selectedAnnotationRef?.current?.find(
                    (x) => x.uuid === this.props?.extractedTextRef?.current?.uuid
                );
                if (
                    targetObject &&
                    this.props?.extractedTextRef?.current?.pageNumber === pageNumber
                ) {
                    this.invokeExtractText(
                        this.props?.extractedTextRef?.current?.uuid,
                        textContent
                    );
                }
                this.updatePagesProperty(
                    [
                        {
                            update: 'textExtractFetchStatus',
                            value: TEXT_EXTRACT_STATUS.READY
                        },
                        {
                            update: 'textContent',
                            value: textContent
                        }
                    ],
                    pageNumber
                );
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize() {
        this.props.selectAnnotationOnCanvas();
        var mutatedAnnotations = this.props.annotationComments.map((item) => {
            var newItem = { ...item };
            return newItem;
        });
        this.props.setAnnotationComments(mutatedAnnotations);

        this.props.setDisableArrow(true);
        if (this.resizeTimer !== null) {
            clearTimeout(this.resizeTimer);
        }

        this.resizeTimer = setTimeout(() => {
            if (this.props.selectedComment === -1) {
                return;
            }
            this.props.redrawAnnotationCommentArrow();
            this.props.calculateCommentArrowPoint(this.props.selectedComment, false);
            this.props.setDisableArrow(false);
        }, 600);
    }
    debouncedWindowResized = () => {
        this.debounce(this.onWindowResize, 250);
    };

    debounce = (fn, ms, ...rest) => {
        let timer;
        return (_) => {
            clearTimeout(timer);
            timer = setTimeout((_) => {
                timer = null;
                fn.apply(this, rest);
            }, ms);
        };
    };

    onObjectSelectionChanged(object) {
        var selected = object.target.uuid_parent;

        var selectedIndex = this.props.annotationComments.findIndex((x) => x.id === selected);

        if (this.props.selectedComment === selectedIndex) {
            //Selection is similar
        } else {
            //change selected comment
            if (selectedIndex !== -1) {
                this.props.setSelectedComment(selectedIndex);
            }
        }

        this.props.onAnnotationSelectionChanged(object.selected[0]);
    }

    onLoadedImageMetadata(event) {
        this.createCanvas();
        this.setState({ isImageLoaded: true });

        var copy = this.props.loadablePages.map((p) => {
            if (p.page === this.props.index + 1) {
                let pModified = { ...p };
                pModified.isLoaded = true;
                return pModified;
            }

            return p;
        });

        this.props.setLoadablePages(copy);
    }

    createPages() {
        var copiedPages = this.props.pagesContainer.map((page) => {
            return page;
        });

        copiedPages[this.props.index] = this.canvasContainer;
        this.props.setPagesContainer(copiedPages);

        var copiedContainers = this.props.canvasContainers.map((container) => {
            return container;
        });
        copiedContainers[this.props.index] = this.canvasContainer;
        this.props.setCanvasContainers((current) => {
            return [...current, this.canvasContainer];
        });
    }

    createCanvas() {
        if (this.isDrawingMode() === true) {
            return;
        }

        let canvasEvents = {
            onCanvasObjectUpdated: this.onCanvasObjectUpdated.bind(this),
            onCanvasTextEditEntered: this.onCanvasTextEditEntered.bind(this)
        };
        this.canvas.width = this.props.width;
        this.canvas.height = this.props.height;

        this.mainDrawingCanvas = new DrawingCanvas(
            this.canvas, 
            new fabric.Canvas(this.canvas, { selection: false }),           
            canvasEvents,
            this.onObjectSelectionChanged.bind(this),
            this.onSelectionCleared.bind(this),
            this.onObjectModifying.bind(this)
        );
        //this.props.setMainDrawingCanvas(copied);
        this.props.updateMainDrawingCanvases(this.mainDrawingCanvas, this.props.index);
    }

    onSelectionCleared(obj) {
        if (obj.e === undefined) {
            return;
        }

        this.props.releaseCommentSelection();
    }

    onObjectModifying() {
        if (this.props.disableArrow) {
            return;
        }

        this.props.setDisableArrow(true);
    }

    async onCanvasObjectUpdated(event) {
        await handleCanvasObjectUpdate(
            this.props,
            event,
            this.canvasContainer,
            documentCommentService.updateAnnotation,
            this.props.documentMetadata,
            'documentCommentId',
            'document'
        );
        if (event?.target?.type == drawingConstants.ANNOTATION_TYPE.textHighlight.typeName) {
            var { tl, width, height } = event.target.aCoords;
            var { width, height, scaleX, scaleY } = event.target;
            var pageTexts = this.props.pages[this.props.index];
            this.props.setExtractedText((prev) => {
                return {
                    ...prev,
                    uuid: event?.target?.uuid
                };
            });
            var targetObject = this.props.selectedAnnotation?.[0];
            var { annotationObject } = targetObject;
            if (
                pageTexts?.textContent 
                // && annotationObject.type == drawingConstants.ANNOTATION_TYPE.textHighlight.typeName
            ) {
                var text = extractText(
                    tl.y,
                    tl.x,
                    width,
                    height,
                    scaleX,
                    scaleY,
                    this.props.width,
                    this.props.height,
                    pageTexts?.textContent
                );
                this.props.setCommentText((prevText) => {
                    return text ? `"${text}"\n${''}` : '';
                });
            }
        }
    }

    getHeightScaled(value) {
        return (value / this.props.height) * 2;
    }
    getWidthScaled(value) {
        return (value / this.props.width) * 2;
    }

    onCanvasTextEditEntered(value, hasAnyText) {
        this.props.setCanvasTextEditEntered(value || hasAnyText);
    }

    onMetadataSave(metadata) {
        var changedDocumentMetadata = {
            ...this.props.documentMetadata,
            frameRate: metadata.frameRate
        };
        this.props.setDocumentMetadata(changedDocumentMetadata);
        this.setState({ showConfigureMetadata: false });
    }

    onClicked = () => {
        this.props.changeSelectedCanvas(this.props.index, true);
    };

    onMouseEntered = () => {
        if (!this.props.initDrawing) {
            return;
        }
        let isFreehand = this.props.selectedTool === drawingConstants.ANNOTATION_TYPE.path.typeName;
        this.props.startDrawing(
            this.props.selectedTool,
            this.props.index,
            this.onShapeDrawComplete.bind(this),
            isFreehand
        );
    };

    onMouseLeave = () => {
        if (!this.props.initDrawing) {
            return;
        }
        console.log('Mouse Exiting....');
        console.log(`Current uuid for drawing - ${this.props.currentUuid}`);
        this.props.mainDrawingCanvas[this.props.index].removeObjectById(this.props.currentUuid);
        this.props.mainDrawingCanvas[this.props.index].removeEvents();
        console.log(`Object ${this.props.currentUuid} has been removed from canvas`);
        this.props.setCurrentUuid(null);
    };

    onShapeDrawComplete = (shape) => {

        //Current page of the iframe 
        // if (this.props.proofVersion.mediaType === PROOF_TYPE.HTMLLive){        
        //     var currentPage =  this.player.contentWindow.location.href;
        //     console.log("Drawing completed on page: ",  currentPage);               
        // }

        var uuid = '';
        if (this.props.isComposite === true && this.props.selectedComment !== -1) {
            //New drawing flow
            console.log('shape draw complete.', shape);
            if (shape && shape.action === ShapeAction.ADD) {
                uuid = this.props.onAnnotationMerge(shape, this.canvasContainer, this.props.index);
                this.props.toggleDrawingMode(false, this.props.index);
            }
            this.props.setSelectedTool('');
            this.props.setInitDrawing(false);
        } else {
            console.log('shape draw complete.', shape);
            if (shape && shape.action === ShapeAction.ADD) {
                this.props.setIsCommentAddMode(true);
                uuid = this.props.onCommentAdd(shape, this.canvasContainer, this.props.index);
                this.props.toggleDrawingMode(false, this.props.index);
            }
            this.props.setSelectedTool('');
            this.props.setInitDrawing(false);
        }

        var targetPage = this.props.pages?.[this.props.index];
        var pageNumber = this.props.index + 1;
        if (shape && shape.type == drawingConstants.ANNOTATION_TYPE.textHighlight.typeName) {
            switch (targetPage?.textExtractFetchStatus) {
                default:
                case TEXT_EXTRACT_STATUS.NOT_REQUESTED:
                    {
                        this.updatePagesProperty(
                            [
                                {
                                    update: 'textExtractFetchStatus',
                                    value: TEXT_EXTRACT_STATUS.IN_PROGRESS
                                }
                            ],
                            pageNumber
                        );
                        this.props.setExtractedText({
                            uuid,
                            pageNumber,
                            status: TEXT_EXTRACT_STATUS.IN_PROGRESS
                        });
                        this.invokeTextExtractServiceRequest(this.props.index + 1, (_, textContent) => {
                            this.invokeExtractText(uuid, textContent);
                            this.updatePagesProperty(
                                [
                                    {
                                        update: 'textExtractFetchStatus',
                                        value: TEXT_EXTRACT_STATUS.READY
                                    },
                                    {
                                        update: 'textContent',
                                        value: textContent
                                    }
                                ],
                                pageNumber
                            );
                        });
                    }
                    break;
                case TEXT_EXTRACT_STATUS.READY:
                    {
                        this.invokeExtractText(uuid, targetPage?.textContent || '');
                    }
                    break;
                case TEXT_EXTRACT_STATUS.IN_PROGRESS:
                    {
                        this.props.setExtractedText({
                            uuid,
                            pageNumber,
                            status: TEXT_EXTRACT_STATUS.IN_PROGRESS
                        });
                    }
                    break;
            }
        }   
    };

    createBlob = async (tmpCanvas) => {    
        return new Promise((resolve, reject) => {
            function callBackAfterAll(blob) {
                var file = new File([blob], 'captureAll.png');
                let currentViewport = blob;
                console.log(file);
                resolve(currentViewport);
            }
            tmpCanvas.toBlob(callBackAfterAll, 'image/png', 1);
        });
    };

    captureScreenshot = (iframe) => {    
        window.postMessage({
            type: ScreenshotRequest
        }, "*")
    } 

    invokeExtractText = (uuid, textContent) => {
        var targetObject = this.props.selectedAnnotation?.find((x) => x.uuid === uuid);
        var firstObject = this.props.selectedAnnotation?.[0];
        var text = '';
        if (
            targetObject?.annotationObject 
            // && firstObject?.annotationObject.type == drawingConstants.ANNOTATION_TYPE.textHighlight.typeName
        ) {
            var { annotationObject } = targetObject;
            text = extractText(
                annotationObject.top,
                annotationObject.left,
                annotationObject.width,
                annotationObject.height,
                annotationObject.scaleX,
                annotationObject.scaleY,
                this.props.width,
                this.props.height,
                textContent
            );
            var santizedText = text ? text.substring(0, COMMENT_CHAR_LIMIT) : '';
            this.props.setCommentText((prevText) => {
                return santizedText ? `"${santizedText}"\n${''}` : '';
            });
        }
        this.props.setExtractedText({
            uuid: '',
            pageNumber: 0,
            status: TEXT_EXTRACT_STATUS.READY
        });
    };

    invokeTextExtractServiceRequest = (pageNumber, onComplete) => {
        proofService
            .getPageText(this.props.documentMetadata.proofVersionId, pageNumber)
            .then((data) => {
                var textContent = '';
                try {
                    textContent = data?.content ? JSON.parse(data.content) : '';
                } catch (error) {
                    textContent = '';
                }
                onComplete(pageNumber, textContent);
            });
    };

    setPlayer = (ref) => { this.player = ref; }
        
    updatePagesProperty = (updates, pageNumber) => {
        this.props.setPages((pages) => {
            var updatedPages = pages.map((page) => {
                var updatedPage = { ...page };
                if (page.pageNumber === pageNumber) {
                    updates.forEach(({ update, value }) => {
                        updatedPage[update] = value;
                    });
                }
                return updatedPage;
            });
            return updatedPages;
        });
    };



    isDrawingMode = () => {
        return this.props.hideAnnotations;
    }

    isInterActiveMode = () => {
        
        if (this.props.proofVersion.mediaType !== PROOF_TYPE.HTMLLive && this.props.proofVersion.mediaType !== PROOF_TYPE.HTMLDynamicLive) { return false }
        let isInterActive = !(this.props.selectedTool !== '' || this.props.isCommentAddMode || this.props.selectedComment !== -1);        
        return isInterActive;                                 
    }

    render() {
        const { documentMetadata, pages, index, proofSettings, pageCount, pageNumber } = this.props;
        const {} = documentMetadata;
        const page = pages?.[index];
        const isTextExractDisabled =
            proofSettings?.textRecognitionSettingType ===
            TEXT_RECOGNITION_SETTINGS_TYPES.none.value;
        
        return (
            <div className="new-host" style={{ display: 'flex',  paddingBottom: this.props.editorType !== EditorType.Paged && pageCount > 1 && pageCount !== pageNumber ? '36px' : '0px' }}>
                <div className="editor-host" style={{}}>
                    <div>                       
                        <div
                            ref={(node) => {
                                this.canvasContainer = node;
                            }}
                            className={cx({
                                'document-canvas-selected':
                                    this.props.index === this.props.selectedCanvas,
                                'document-canvas': true
                            })}
                            style={{
                                display: 'flex',
                                width: '100%',
                                height: '100%',
                                marginBottom: (utilService.isWebsiteProof(this.props.proofVersion) || this.props.editorType === EditorType.Paged) ? 0 : 36,
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative'
                            }}
                            onMouseDown={this.onClicked.bind(this)}
                            onMouseEnter={this.onMouseEntered.bind(this)}
                            onMouseLeave={this.onMouseLeave.bind(this)}>
                            <PageBackdrop
                                width={this.props.width}
                                height={this.props.height}
                                isLoading={this.props.shouldLoad && !this.state.isImageLoaded}
                                onBackdropLoaded={() => {
                                    //this.createCanvas();
                                    this.createPages();
                                    this.props.setLoadedPageCount(this.props.index + 1);
                                    store.dispatch(alertActions.loading(false));
                                    //this.props.canvasLoaded();
                                    this.setState({ playerLoaded: true });
                                }}></PageBackdrop>

                            {/* {this.props.shouldLoad === true && (
                                <img
                                    className="documentImageViewer"
                                    crossOrigin="anonymous"
                                    ref={(node) => (this.player = node)}
                                    style={{
                                        display: 'flex',
                                        width: `${this.props.width}px`,
                                        height: `${this.props.height}px`,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        opacity: this.state.isImageLoaded === true ? '1' : '0'
                                    }}
                                    src={this.props.src}
                                    onLoad={this.onLoadedImageMetadata.bind(this)}></img>
                            )} */}


                            {
                                this.props.shouldLoad === true && (
                                    (this.props.proofVersion.mediaType !== PROOF_TYPE.HTMLLive && this.props.proofVersion.mediaType !== PROOF_TYPE.HTMLDynamicLive) ?
                                        <ProgressiveImg
                                            setPlayer={this.setPlayer.bind(this)}
                                            zoom={this.props.containerZoom}
                                            variants={this.props.variants}
                                            pageSrc={this.props.src}
                                            className="documentImageViewer"
                                            crossOrigin="anonymous"
                                            pageNo={this.props.index + 1}
                                            isVisible={this.props.visiblePages.some(p => p.page === this.props.index + 1)}
                                            style={{
                                                display: 'flex',
                                                width: `${this.props.width}px`,
                                                height: `${this.props.height}px`,                                                
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                position: 'absolute',
                                                opacity: this.state.isImageLoaded === true ? '1' : '0'
                                            }}
                                            onImageLoaded={this.onLoadedImageMetadata.bind(this)}
                                        />
                                        : <WebsiteViewerHOC                                                                                                                                                                                                                         
                                            className="documentImageViewer"
                                            width = {`${this.props.width}px`}
                                            height = {`${this.props.height}px`}                                            
                                            websiteURL={this.props.proofVersion.fileMetadata.websiteURL}                                            
                                            iFrameRef={this.props.iFrameRef}                                                                                        
                                            onInitialLoaded={this.onLoadedImageMetadata.bind(this)}
                                            versionId={this.props.proofVersion.id}
                                            isInterActiveMode={this.isInterActiveMode()}
                                            makeProofReadonly = { () => {
                                                var currentVersion = {...this.props.proofVersion}
                                                currentVersion.isLocked = true;
                                                this.props.setProofVersion(currentVersion);
                                            } }
                                        />
                                )
                            }

                            {this.isDrawingMode() !== true && (                                
                               <div style={{
                                position: 'absolute',
                                left: 0,
                                top: 0,                                        
                                width: `${this.props.width}px`,
                                height: `${this.props.height}px`,
                                display: this.isInterActiveMode() === true? 'none' : 'initial'                                
                                }}>
                                <canvas
                                    ref={(node) => (this.canvas = node)}
                                    id="canvas"
                                    className={cx({                                                                                
                                        'main-drawing-canvas': true,
                                        'd-transparent':
                                            this.props.isAutoCompare || this.props.isOverlayCompare
                                    })}
                                    style={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,                                        
                                        width: `${this.props.width}px`,
                                        height: `${this.props.height}px`
                                    }}></canvas>

                               </div>
                            )}
                            <DesktopBreakpoint>
                                {!this.isDrawingMode() &&
                                    !this.props.side &&
                                    !isTextExractDisabled &&
                                    !utilService.isWebsiteProof(this.props.proofVersion) && (
                                        <div
                                            style={{
                                                marginTop: 2,
                                                position: 'absolute',
                                                right: -40,
                                                top: 16,
                                                transform: 'scale(2)',
                                                opacity:
                                                    page?.textExtractFetchStatus ===
                                                        TEXT_EXTRACT_STATUS.READY
                                                        ? 1
                                                        : 0.2
                                            }}
                                            title={
                                                page?.textExtractFetchStatus ===
                                                    TEXT_EXTRACT_STATUS.READY
                                                    ? 'Text recognition is ready'
                                                    : 'Text recognition is not available yet'
                                            }>
                                            <TextHighlightIcon />
                                        </div>
                                    )}
                            </DesktopBreakpoint>
                        </div>
                    </div>
                </div>

                {/* auto compare div */}
                <div
                    className={cx({ 'compare-host': true, 'd-none': !this.props.isAutoCompare })}
                    onClick={this.onClicked.bind(this)}
                    style={{ position: 'absolute', display: 'flex' }}>
                    {this.props.isProofCompare && this.props.index === this.props.selectedCanvas && (
                        <>
                            {React.cloneElement(this.props.compareCanvas, {
                                pageSrc: this.props.src,
                                index: this.props.index,
                                compareIndex: this.props.selectedCanvas,
                                viewer: this.player,
                                setCompareDocumentViewer: this.props.setCompareDocumentViewer,
                                viewerLoaded: this.state.playerLoaded
                            })}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const WebsiteViewerHOC = withDocumentAnnotationConsumer(WebsiteViewer);
const hoc = withDocumentAnnotationConsumer(DocumentPlayerWithCanvas);
export { hoc as DocumentPlayerWithCanvas };