//@ts-check
import React from 'react';
import { CommentStatusManageWrapper } from './CustomStatus/Context/CommentStatusManageWrapper';

export const CustomizedStatusManage = ({ onClose }) => {
    return (
        <>
            <div className="settings-view__manage__container">
                <div className="m-2">
                    <div className="settings-view-modal">
                        <div style={{ minHeight: '80%', fontSize: '13px', height: 'calc(100% - 30px)' }}>
                            <button
                                id="editor-close-btn"
                                type="button"
                                className="close modal-close"
                                title="Close Modal"
                                style={{ zIndex: 4 }}
                                onClick={onClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>

                            <h3 className="">Annotation Comment Status</h3>
                            <hr></hr>

                            <CommentStatusManageWrapper/>
                            
                        </div>                        
                    </div>
                </div>
            </div>
        </>
    );
};


