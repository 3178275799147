import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import {
    fileUploadConstants,
    groupActions,
    userActions,
    alertActions,
    MultiSelect,
} from '../../components';
import { usersMock } from '../Settings/users-mock';
import { utilService } from '../../_helpers';
import { ConfirmationModal } from '../_shared';
import { groupService } from '../User';
import { DataFetcher } from '../_shared/DataFetcher/DataFetcher';

const initialValidationState = {
    name: {
        validated: true,
        message: '',
    },
    description: {
        validated: true,
        message: '',
    },
};

class GroupModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupObject: this.props.groupObject,
            validations: initialValidationState,
            users: [],
            existingUsers: null,
            showDeleteConfirm: false,
            canSave: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    async fetchData() {
        const data = {
            page: 0,
            pageSize: 2147483647,
            proofSortModel: {
                sortDirection: 1,
                sortColumn: 'firstName',
            },
            proofFilters: [],
        };
        var usersData = await this.props.dispatch(userActions.getAll(data));
        var rowsTemp = [...usersData.list];
        const rows = rowsTemp.map(item => {
            var newItem = { ...item };
            newItem.value = item.id;
            newItem.label = item.fullName;
            return newItem;
        });
        const rowsExistingUsers =
            this.state.groupObject.users && this.state.groupObject.users.length > 0
                ? rows.filter(item => this.state.groupObject.users.includes(item.id))
                : [];

        await utilService.setStateAsync.bind(this)({ existingUsers: rowsExistingUsers });
        await utilService.setStateAsync.bind(this)({ users: rows });
    }
    onModalClose() {
        this.props.onClose();
    }
    handleChange(event) {
        var { name, value } = event.target;
        const { groupObject } = this.state;
        if (name === 'isEnabled') {
            value = value == 'false' ? 'Enabled' : 'Disabled';
        }
        this.setState({
            groupObject: {
                ...groupObject,
                [name]: value,
            },
            submitted: false,
            canSave: true
        });
    }
    async onChangeMultiSelect(selectedOptions, addedUserIds, removedUserIds) {
        var selectedUserIds = selectedOptions
            ? selectedOptions.map(item => {
                  var newItem = { ...item };
                  return newItem.id;
              })
            : [];
        var newAddedUserIds = this.state.groupObject.addedUserIds
            ? [...this.state.groupObject.addedUserIds, ...addedUserIds]
            : addedUserIds;
        var newRemovedUserIds = this.state.groupObject.removedUserIds
            ? [...this.state.groupObject.removedUserIds, ...removedUserIds]
            : removedUserIds;
        var newGroupObject = {
            ...this.state.groupObject,
            users: selectedUserIds,
            addedUserIds: newAddedUserIds,
            removedUserIds: newRemovedUserIds,
        };
        this.setState({
            groupObject: newGroupObject,
            existingUsers: selectedOptions,
        });
    }
    async validateFields() {
        var globalValidation = true;
        var { validations } = this.state;
        var validationCopy = { ...validations };
        if (
            !this.state.groupObject.name ||
            (this.state.groupObject.name && this.state.groupObject.name.length < 3)
        ) {
            validationCopy.name = {
                validated: false,
                message: 'Name must be more than 2 characters ',
            };
            globalValidation = false;
        }
        if (this.state.groupObject.description && this.state.groupObject.description.length > 120) {
            validationCopy.description = {
                validated: false,
                message: 'Description must be less than 120 characters ',
            };
            globalValidation = false;
        }
        if (true) {
            if (this.state.groupObject.name) {
                var groupObjectRequest = { ...this.state.groupObject };
                if (
                    groupObjectRequest.isEnabled === 'Enabled'
                        ? (groupObjectRequest.isEnabled = true)
                        : (groupObjectRequest.isEnabled = false)
                );
                // keep provide id existing item unique check
                var response = await this.props.dispatch(
                    groupActions.isNameUnique(groupObjectRequest),
                );
                if (!response.isNameUnique) {
                    validationCopy.name = {
                        validated: false,
                        message: 'Group with the same name already exists',
                    };
                    globalValidation = false;
                }
            }
        }
        await utilService.setStateAsync.bind(this)({
            validations: validationCopy,
        });
        return globalValidation;
    }
    async handleSubmit(event) {
        event.preventDefault();

        await utilService.setStateAsync.bind(this)({
            submitted: true,
            validations: initialValidationState,
            canSave: false
        });

        if (!(await this.validateFields())) {
            return;
        }

        const { groupObject } = this.state;
        const { dispatch } = this.props;
        var groupObjectRequest = { ...groupObject };
        if (
            groupObjectRequest.isEnabled === 'Enabled'
                ? (groupObjectRequest.isEnabled = true)
                : (groupObjectRequest.isEnabled = false)
        );
        if (groupObject.name) {
            if (this.props.operationMode === 1)
                await dispatch(groupActions.add(groupObjectRequest));
            else if (this.props.operationMode === 2)
                await dispatch(groupActions.update(groupObjectRequest));
            else return;
            await this.refetchGroups();
            this.props.dispatch(alertActions.success('Saved !'));
            this.onModalClose();
        }
    }

    async refetchGroups() {
        const data = {
            page: 0,
            pageSize: 20,
            proofSortModel: {
                sortDirection: 1,
                sortColumn: null,
            },
            proofFilters: [],
        };
        await this.props.dispatch(groupActions.getAll(data));
    }

    onDeleteClick(e){
        e.preventDefault();
        this.setState({ showDeleteConfirm: true });
    }
    async handleDelete(){
        await groupService.delete(this.state.groupObject.id)
        this.props.dispatch(alertActions.success("Group deleted !"));
        await this.refetchGroups();
        this.onModalClose();
    }

    render() {
        console.log('groupModal Props: ', this.props);
        const { registering } = this.props;
        const { groupObject, submitted, validations } = this.state;
        const group = groupObject;
        const titleText = this.props.operationMode === 1 ? 'Add group' : 'Edit group';
        return (
            <Modal
                //{...this.props}
                show={this.props.show}
                onHide={this.props.onClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'}
                scrollable>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{titleText}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ minHeight: '400px' }}>
                        <DataFetcher onFetchData={this.fetchData.bind(this)} height = '400px'>
                        <form name="form" onSubmit={this.handleSubmit}>
                            <div
                                className={
                                    'form-group' +
                                    (submitted && !group.firstName ? ' has-error' : '')
                                }>
                                <label htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    className="form-control mrnda-input-text"
                                    name="name"
                                    value={group.name || ''}
                                    onChange={this.handleChange}
                                />
                                {submitted && !validations.name.validated && (
                                    <div className="help-block">{validations.name.message}</div>
                                )}
                            </div>
                            <div
                                className={
                                    'form-group' +
                                    (submitted && !group.description ? ' has-error' : '')
                                }>
                                <label htmlFor="description">Description</label>
                                <textarea
                                    className="form-control mrnda-input-textarea"
                                    style={{ resize: 'none' }}
                                    name="description"
                                    value={group.description || ''}
                                    onChange={this.handleChange}
                                />
                                {submitted && !validations.description.validated && (
                                    <div className="help-block">
                                        {validations.description.message}
                                    </div>
                                )}
                            </div>
                            <div
                                className={
                                    'form-group' +
                                    (submitted && !group.description ? ' has-error' : '')
                                }>
                                <label htmlFor="description">Select Users</label>
                                {true /*this.state.existingUsers.length > 0*/ && (
                                    <MultiSelect
                                        isMulti={true}
                                        options={this.state.users}
                                        selectedOptions={this.state.existingUsers}
                                        onChange={this.onChangeMultiSelect.bind(this)}
                                        closeMenuOnSelect={true}
                                    />
                                )}
                            </div>
                            <div
                                className={
                                    'form-group d-none' +
                                    (submitted && !group.firstName ? ' has-error' : '')
                                }>
                                <label htmlFor="status">Enabled</label>
                                <input
                                    type="checkbox"
                                    className="ml-2"
                                    data-toggle="toggle"
                                    data-on="Enabled"
                                    data-off="Disabled"
                                    name="isEnabled"
                                    value={group.isEnabled === 'Enabled' ? true : false}
                                    checked={group.isEnabled === 'Enabled' ? true : false}
                                    onChange={this.handleChange}
                                />
                            </div>
                            {this.props.operationMode === 2 && (
                                <div className="form-group">
                                    <label htmlFor="delete">Delete this group</label>
                                    <button
                                         className="mrnda-btn--caution"
                                        style={{ padding: 10, display: 'block' }}
                                        onClick={this.onDeleteClick.bind(this)}>
                                        <i className="far fa-trash-alt mr-2" />
                                        Delete
                                    </button>
                                </div>
                            )}
                        </form>
                        </DataFetcher>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant=""
                        // disabled={
                        //     this.state.fileUploadStatus === fileUploadConstants.FILE_LOADED
                        //         ? false
                        //         : true
                        // }
                        className="btn mrnda-btn"
                        onClick={this.handleSubmit.bind(this)}
                        disabled={!this.state.canSave}
                        >
                        Save
                    </Button>
                    <Button className="mrnda-btn--secondary" onClick={this.onModalClose.bind(this)}>
                        Close
                    </Button>
                </Modal.Footer>
                <ConfirmationModal
                    show={this.state.showDeleteConfirm}
                    onHide={() => this.setState({ showDeleteConfirm: false })}
                    onPrimaryClick={this.handleDelete.bind(this)}
                    onSecondaryClick={() => this.setState({ showDeleteConfirm: false })}
                    primaryButtonText="Yes"
                    secondaryButtonText="No"
                    title={`Delete "${this.state.groupObject?.name}"`}
                    bodyText="Are you sure you want to delete this group ?"
                />
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    console.log('States in groupModal:', state);
    const { s3Info } = state;
    return {
        s3TempInfo: s3Info.s3Info,
    };
}

const connectedCreateProofModel = connect(mapStateToProps)(GroupModal);
export { connectedCreateProofModel as GroupModal };
