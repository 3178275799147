import React from 'react';
import PinIcon from '../../_shared/PinIcon';
import RectIcon from '../../_shared/Icon/RectIcon';
import CircleIcon from '../../_shared/Icon/CircleIcon';
import CommentStampIcon from '../../_shared/Icon/CommentStampIcon';
import { ShapeAction, drawingConstants } from '@wunderman-thompson/miranda-drawing';

const ShapeSelectorItem = ({ icon, ...rest }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '84px',
                width: '84px',
                marginRight: '16px',
                marginLeft: '16px',
                background: 'var(--secondary-background-color)',
                borderRadius: '4px'
            }}
            {...rest}>
            {icon}
        </div>
    );
};

const ShapeSelector = ({ onDrawingToolClicked, setSelectedToolbarAction }) => {
    const { ANNOTATION_TYPE } = drawingConstants;

    const {
        rect,
        circle,
        commentStamp,
        pinPointer,
    } = ANNOTATION_TYPE;
    const handleShapeSelectorClicked = (selectedShape) => {
        return () => {
            setSelectedToolbarAction('None');
            onDrawingToolClicked(selectedShape);
        };
    };

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '16px'
                }}>
                {/* <div className={getItemClassnames(rect.typeName)} title={rect.name}>
                        <i onClick={() => onDrawingToolClicked(rect)} className={rect.icon}></i>
                    </div> */}
                <ShapeSelectorItem onClick={handleShapeSelectorClicked(rect)} icon={<RectIcon />} />
                <ShapeSelectorItem
                    onClick={handleShapeSelectorClicked(circle)}
                    icon={<CircleIcon />}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '16px'
                }}>
                <ShapeSelectorItem
                    onClick={handleShapeSelectorClicked(commentStamp)}
                    icon={<CommentStampIcon />}
                />
                <ShapeSelectorItem
                    onClick={handleShapeSelectorClicked(pinPointer)}
                    icon={<PinIcon />}
                />
            </div>
        </div>
    );
};

export default ShapeSelector;
