import { videoTranscriptionService } from './ProofVideoTranscription.service';

import { proofVideoTranscribeConstants } from './proofVideoTranscibe.constants';
import { alertConstants } from '../../App/alert.constants';
import { alertActions } from '../../App/alert.actions'

import { utilService } from '../../../_helpers';
const {
    PROOFVIDEO_GETTRANSCRIBETEXT_REQUEST,
    PROOFVIDEO_GETTRANSCRIBETEXT_SUCCESS,
    PROOFVIDEO_GETTRANSCRIBETEXT_FAILURE,

} = proofVideoTranscribeConstants;

export const videoProofTranscribeActions = {
    getTranscribeText


};





function getTranscribeText(proofId, versionId) {

    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            videoTranscriptionService.getTranscribeText(proofId, versionId).then(
                proofVideoTranscribeData => {
                    dispatch(success(proofVideoTranscribeData));
                    dispatch(loading(false));
                    resolve(proofVideoTranscribeData);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: PROOFVIDEO_GETTRANSCRIBETEXT_REQUEST };
    }
    function success(transcribeResult) {
        return {
            type: PROOFVIDEO_GETTRANSCRIBETEXT_SUCCESS,
            transcribeResult,
        };
    }
    function failure(error) {
        return { type: PROOFVIDEO_GETTRANSCRIBETEXT_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}







