import React from 'react';

export function CustomInput(props) {
    const componentStyle = {
        border: `1px solid var(--control-border-color)`,
        display: 'flex',
        borderRadius: '.25rem'
    };
    const inputStyle = {
        border: 'none',
        borderTopLeftRadius: props.LeftComponent ? '0' : '.25rem',
        borderBottomLeftRadius: props.LeftComponent ? '0' : '.25rem',
        borderTopRightRadius: props.RightComponent ? '0' : '.25rem',
        borderBottomRightRadius: props.RightComponent ? '0' : '.25rem',
        zIndex: '1'
    };

    const leftComponentStyle = {
        margin: '0px',
        background: 'var(--tertiary-background-color)',
        display: 'flex',
        alignItems: 'center'
    };
    const rightComponentStyle = {
        margin: '0px',
        background: 'var(--tertiary-background-color)',
        display: 'flex',
        alignItems: 'center'
    };

    return (
        <div style={{ ...componentStyle, ...props.style }}>
            {props.LeftComponent && <div style={leftComponentStyle}>{props.LeftComponent}</div>}
            <input
                type={props.type}
                className="form-control"
                name={props.name}
                value={props.value}
                onChange={props.handleTextChange}
                autoComplete="off"
                style={{ ...inputStyle, ...props.inputStyle }}
            />
            {props.RightComponent && <div style={rightComponentStyle}>{props.RightComponent}</div>}
        </div>
    );
}
