import React, { useState } from 'react';
import './TabView.scss';
import cx from 'classnames';

function TabView({ tabItems }) {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const onTabHeaderClicked = (index) => {
        setSelectedTabIndex(index);
    };
    return (
        <div className="tab-host" style={{height: 'calc(100% - 44px)', display: 'flex' }}>
            <div className="tab-header-container">
                {tabItems.map((item, index) => (
                    <TabHeader
                        isSelected={index === selectedTabIndex}
                        index={index}
                        key={index}
                        header={item.title}
                        onTabHeaderClicked={onTabHeaderClicked}
                    />
                ))}
            </div>
            <hr style={{ width: '100%', height: '1px', zIndex: '20' }}></hr>
            <TabContent   content={tabItems[selectedTabIndex].content} />
        </div>
    );
}
export default TabView;

function TabHeader({ header, isSelected, onTabHeaderClicked, index }) {
    return (
        <div >
            <div
                className={cx('tab-header', { 'tab-header-selected': isSelected === true })}
                style={{ fontSize: '1rem', marginRight: '1.75rem' }}
                onClick={() => {
                    onTabHeaderClicked(index);
                }}>
                {header}
            </div>
        </div>
    );
}

function TabContent({ content: Content }) {
    return (
        <div style={{height: '100%'}}>
            <Content />
        </div>
    );
}
