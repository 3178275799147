//@ts-check

import React from 'react';

const BrandIcon = ({ height = 18, width = 22, color = 'currentColor' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.4832 0.00047893L1.18572 0C0.530863 0 0 0.546259 0 1.2201V20.2537L0.000965722 20.3048C0.0381678 21.285 1.14213 21.8275 1.90866 21.2208L5.59658 18.3015H12.3314L12.4205 18.3008C15.4017 18.2456 17.7857 15.7504 17.7857 12.6891L17.7848 12.5851C17.7626 11.339 17.3425 10.1649 16.6195 9.21964L16.5656 9.15053L16.6194 9.08191C17.3626 8.11042 17.7857 6.89719 17.7857 5.61247C17.7857 2.57131 15.4319 0.085518 12.4832 0.00047893ZM2.37143 2.44021H12.4289L12.4168 2.43973C14.0832 2.48779 15.4143 3.89349 15.4143 5.61247C15.4143 6.60431 14.9702 7.51784 14.2262 8.11509L14.1578 8.16851C13.5294 8.64504 13.5151 9.60049 14.1149 10.0988L14.1577 10.1329C14.9429 10.7284 15.4143 11.6672 15.4143 12.6891C15.4143 14.4193 14.0667 15.8297 12.3885 15.8608L12.3208 15.8614L5.19344 15.8613L5.13324 15.8629C4.89311 15.8755 4.66182 15.9629 4.47049 16.1144L2.37143 17.7754V2.44021Z"
                fill={color}
            />
        </svg>
    );
};

export default BrandIcon;
