import React from 'react';
import { useTheme } from '../../App/Theme/ThemeContext';
import { isCompactNav } from '../../../_helpers/navbarUtil';
import { LoginPageTitleBar } from '../../User/LoginRegistration/LoginPageTitleBar';

const withNoAuthNav = (WrappedComponent) => {
    return (props) => (
        <>
            <LoginPageTitleBar/>
            {WrappedComponent && <WrappedComponent {...props} />}
        </>
    );
};

export default withNoAuthNav;
