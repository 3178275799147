import React from 'react';
import { PageCommentAnnotation } from './PageCommentAnnotation';
import { PageCommentPreview } from './PageCommentPreview'
export function PageSummary({ page }) {
    return (
        <div className="comment display-thumbnail">
            <div className="comment-content">
                {page.annotationObjects.map(
                    annotationObject => (
                        <PageCommentAnnotation key={annotationObject.id} annotationObject={annotationObject} page={page}></PageCommentAnnotation>
                    ),
                )}
            </div>
            <div className="comment-preview">
                <div className="css-1fmc5ej e17exdub0">
                    <div className="comment-preview-item">
                        <PageCommentPreview  key={page.pageNumber} page={page}></PageCommentPreview>
                    </div>
                </div>
            </div>

        </div>

    );
}