//@ts-check
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const CreateProofHTMLDynamicPreview = ({
    initialDimension,
    onConfirm,
    onClose,
    imagePreviewURL = { url: '', errorMessage: '' }
}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0, width: 200, height: 200, unit: 'px' });
    const imgRef = useRef(null);

    const onLoad = (event) => {
        imgRef.current = event.target;
        var cropWidth = Number(
            Number(
                initialDimension.width /
                    (Number(imgRef.current.naturalWidth) / Number(imgRef.current.width))
            )
        );
        var cropHeight = Number(
            Number(
                initialDimension.height /
                    (Number(imgRef.current.naturalHeight) / Number(imgRef.current.height))
            )
        );

        var updated = {
            ...initialDimension,
            width: cropWidth,
            height: cropHeight
        };
        onChange(updated);
    };

    const onChange = (crop) => {
        setCrop((prev) => {
            return { ...prev, ...crop };
        });
    };
    const handleConfirm = () => {
        var selectedWidth = Number(
            (Number(imgRef.current.naturalWidth) / Number(imgRef.current.width)) * crop.width
        ).toFixed(0);
        var selectedHeight = Number(
            (Number(imgRef.current.naturalHeight) / Number(imgRef.current.height)) * crop.height
        ).toFixed(0);

        onConfirm({
            ...crop,
            width: Number(selectedWidth),
            height: Number(selectedHeight)
        });
    };

    const selectedWidth = Number(
        (Number(imgRef?.current?.naturalWidth || 0) / Number(imgRef?.current?.width || 0)) *
            crop.width
    ).toFixed(0);
    const selectedHeight = Number(
        (Number(imgRef?.current?.naturalHeight || 0) / Number(imgRef?.current?.height || 0)) *
            crop.height
    ).toFixed(0);

    const headerText = `Set Dimension ${
        imgRef?.current ? ` - ${selectedWidth}x${selectedHeight}` : ''
    }`;

    return (
        <Modal
            show={true}
            onHide={onClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={'static'}
            style={{
                minWidth: 'inherit',
                zIndex: 9999
            }}
            className={'mrnda-fullscreen-modal'}>
            <Modal.Header closeButton={true}>
                <Modal.Title className="h5" id="contained-modal-title-vcenter">
                    {headerText}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                className={'p-0'}
                style={{ backgroundColor: 'var(--primary-background-color)', maxHeight: '100%' }}>
                {imagePreviewURL.url ? (
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        className="padding-2x">
                        <ReactCrop
                            crop={crop}
                            keepSelection={true}
                            onChange={(c) => onChange({ ...c, y: 0 })}
                            onComplete={(c) => {
                                console.log(c);
                            }}
                            imageStyle={{ maxHeight: 650 }}>
                            <img
                                alt="Crop me"
                                src={imagePreviewURL?.url}
                                style={{
                                    transform: `scale(${1}) rotate(${0}deg)`,
                                    maxHeight: '76vh',
                                    objectFit: 'contain'
                                }}
                                onLoad={onLoad}
                            />
                        </ReactCrop>
                    </div>
                ) : imagePreviewURL?.errorMessage ? (
                    <div
                        style={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>
                        <div className="mrnda-empty-state-wrapper">
                            <div className="mrnda-empty-state">
                                <i
                                    style={{ fontSize: '40px', marginBottom: '12px' }}
                                    className="fas fa-exclamation-circle typography--muted text-danger"></i>
                                <p className="typography--h1 typography--light typography--muted margin-1x--bottom">
                                    Something went wrong
                                </p>
                                <p className="typography--h2 typography--light typography--muted">
                                    {imagePreviewURL?.errorMessage || ''}
                                </p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                        className="padding-2x">
                        <Spinner className="upload-spinner" animation="grow" variant="dark" />
                        <p className="typography--h1 typography--light typography--muted">
                            Please wait
                        </p>
                        <p className="typography--h2 typography--light typography--muted">
                            The preview is being generated...
                        </p>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer
                style={{
                    borderTop: '1px solid var(--border-color)',
                    backgroundColor: 'var(--primary-background-color)'
                }}>
                <Button
                    //@ts-ignore
                    variant=""
                    disabled={!imagePreviewURL?.url}
                    className="mrnda-btn btn-sm"
                    onClick={handleConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateProofHTMLDynamicPreview;
