import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Spinner } from 'react-bootstrap';
import {
    ProgressBarComponent,
    FileUploader,
    fileUploadConstants,
    proofActions,
    s3InfoActions,
    alertActions,
    tenantService,
    s3Service,
    CREATE_PROOF_MODAL_VIEW,
    CREATE_PROOF_MODE
} from '../../components';
import { utilService } from '../../_helpers';
import cx from 'classnames';
import { proofService } from './proof.service';
import { directoryService } from './directory.service';
import ContextTreeNode from 'rc-tree/lib/TreeNode';
import { element } from 'prop-types';
import { uuidv4 } from '../Proof/DocumentAnnotation/DocumentAnnotationUtil';
import CreateProofHTMLDynamicView from './CreateProofHTMLDynamicView';
import CreateProofViewHTML from './CreateProofViewHTML';

const initialValidationState = {
    fileName: {
        validated: true,
        message: 'Provide a valid file name'
    },
    websiteURL: {
        validated: true,
        message: 'Provide a valid website URL'
    }
};
export const Display_Name_length = 121;
class CreateProofModal extends React.Component {
    constructor(props) {
        super(props);
        this.THIS_NO_FILE_PLACEHOLDER = '--| No File Selected |--';
        this.state = {
            allowedFileExtentions: [],
            proofId: this.props.proofId || '',
            proofVersionId: '',
            tenantName: tenantService.getCurrentTenant(),
            uploadPath: ``,
            uploadPathThumb: '',
            proof: {},
            currentDirectoryNodeId: this.props.currentDirectoryNodeId,
            modalViewType: CREATE_PROOF_MODAL_VIEW.FILE,

            fileUploadObject: { fileName: '', websiteURL: '' },
            validations: initialValidationState,

            webProofInputValidated: false,
            pickedFiles: [],
            isUploadButtonEnabled: false,
            currentIndex: 0,
            websiteType: 0,
            dynamicHtmlType: 0
        };
        this.fileUploaderComponent = React.createRef();
    }
    async componentDidMount() {
        var fileExtentions = await s3Service.getFileExtentions();
        this.setState({ allowedFileExtentions: fileExtentions });
    }
    static getDerivedStateFromProps(nextProps, prevStates) {
        if (nextProps.currentDirectoryNodeId != prevStates.currentDirectoryNodeId) {
            return {
                currentDirectoryNodeId: nextProps.currentDirectoryNodeId
            };
        } else return null;
    }
    async handleTextChange(event) {
        var { name, value } = event.target;
        const { fileUploadObject } = this.state;

        let changedFileUploadObject = {
            ...fileUploadObject,
            [name]: value
        };
        let canSave = await this.validateFields(changedFileUploadObject);
        this.setState({
            fileUploadObject: changedFileUploadObject,
            submitted: false,
            webProofInputValidated: canSave
        });
    }
    async validateFields(fileUploadObject) {
        var globalValidation = true;
        var { validations } = this.state;
        var validationCopy = { ...validations };
        let { websiteURL, fileName } = fileUploadObject;

        let websiteURLValidated =
            tenantService.validateWebsiteURL(websiteURL) && websiteURL.length < 600;
        validationCopy.websiteURL = {
            ...validationCopy.websiteURL,
            validated: websiteURLValidated
        };
        globalValidation = websiteURLValidated;

        var validationResult = directoryService.validateWebsiteDisplayName(fileName);
        let fileNameValidate = validationResult.isValid && fileName.length < Display_Name_length;
        
        let message = validationResult.reason !== undefined 
                    ? `${initialValidationState.fileName.message}.(${validationResult.reason})` 
                    : fileName.length < Display_Name_length 
                    ? `${initialValidationState.fileName.message}` 
                    : 'Max character limit reached' ;
        
        validationCopy.fileName = {
            validated: fileNameValidate,
            message: message
        };
        
        globalValidation = fileNameValidate && websiteURLValidated;

        await utilService.setStateAsync.bind(this)({
            validations: validationCopy
        });
        return globalValidation;
    }

    onFileChange(files) {
        return new Promise((resolve) => {
            //NOTE: Must need to know the logic here.
            //this.filUploadCancel(file);

            var picked = [];

            files.forEach((file)=>{
                var name = file ? file.name : this.THIS_NO_FILE_PLACEHOLDER;
                var newName = `${utilService.getFileName(name)}.${utilService.getFileExtention(name).toLowerCase()}`;
                picked.push({                        
                        progressNow: 0,
                        completed: false,
                        fileUploadStatus: file
                            ? fileUploadConstants.FILE_LOADED
                            : fileUploadConstants.NONE,
                        fileUploadPromise: null,
                        fileUploadTimeLeft: 0,
                        fileName: newName,
                        file,
                        guid: uuidv4()
                    });
            });

            this.setState(
                { pickedFiles : picked },
                () => {
                    resolve();
                }
            );

            var isEnabled = false;
            picked.forEach((file) => {
                isEnabled = file.fileUploadStatus === fileUploadConstants.FILE_LOADED;
            });

            this.setState({
                isUploadButtonEnabled: isEnabled
            });
        });
    }
    onFileUploadInit(fileUploadPromise) {
        this.props.dispatch(alertActions.loading(true));
        this.setState({
            fileUploadPromise
        });        
        this.pickedFilesChangeHelper((file) => {
            file.disableRemove = true;
        });
    }
    
    onFileUploadStarted() {
        var file = this.state.pickedFiles[this.state.currentIndex];
        // return file
        //     ? this.props.dispatch(s3InfoActions.getS3MainInfo())
        //     : Promise.resolve('success');

        // just return success promise as no work is done here;
        return Promise.resolve('success');
    }
    onProgressChange(obj) {

        var updated = this.state.pickedFiles.map((element, index)=>{

            var file = {...element};

            if(index === this.state.currentIndex) {
                file.progressNow = obj.progressNow,
                //file.fileName = obj.fileName,
                file.fileUploadStatus = fileUploadConstants.STARTED,
                file.fileUploadTimeLeft = obj.stats.secondsLeft
            }

            return file;
        });

        console.log(obj);
        this.setState({
            pickedFiles: updated,
                fileUploadStatus: fileUploadConstants.STARTED
        });
    }

    async onCreateProof() {
        var targetFile = this.state.pickedFiles[this.state.currentIndex];

        var s3TempInfoPayload = {
            name: targetFile.fileName
        };
        var s3TempInfoResponse = await this.props.dispatch(
            s3InfoActions.getS3TempInfo(s3TempInfoPayload)
        );
        await utilService.setStateAsync.bind(this)({
            proofId: s3TempInfoResponse.proofId,
            proofVersionId: s3TempInfoResponse.proofVersionId
        });
        var proofObj = {
            id: s3TempInfoResponse.proofId,
            defaultVersionId: s3TempInfoResponse.proofVersionId,
            status: 1
        };
        await utilService.setStateAsync.bind(this)({
            proof: proofObj,
            uploadPath: s3TempInfoResponse.uploadPath,
            uploadPathThumb: s3TempInfoResponse.uploadPathThumb
        });
        this.fileUploaderComponent.current.onFileUpload(this.state.currentIndex);
    }
    async onCreateProofVersion() {
        var s3TempInfoPayload = {
            name: this.state.pickedFiles[0].fileName,
            proofId: this.state.proofId
        };
        var s3TempInfoResponse = await this.props.dispatch(
            s3InfoActions.getS3TempInfo(s3TempInfoPayload)
        );
        await utilService.setStateAsync.bind(this)({
            proofVersionId: s3TempInfoResponse.proofVersionId
        });
        await utilService.setStateAsync.bind(this)({
            uploadPath: s3TempInfoResponse.uploadPath,
            uploadPathThumb: s3TempInfoResponse.uploadPathThumb
        });
        this.fileUploaderComponent.current.onFileUpload(0);
    }
    async AddProof() {
        var proofObjResponse = await this.props.dispatch(
            proofActions.add({
                Id: this.state.proofId,
                DefaultVersionId: this.state.proofVersionId,
                DocumentName: this.state.pickedFiles[this.state.currentIndex].fileName,
                DirectoryNodeId: this.state.currentDirectoryNodeId
            })
        );
        var proofObj = {
            id: this.state.proofId,
            defaultVersionId: proofObjResponse.defaultVersionId
        };
        await this.props.dispatch(proofActions.ingestProof(proofObj));
    }

    async AddProofVersion() {
        var proofVersionRequest = {
            proofId: this.state.proofId,
            proofVersionId: this.state.proofVersionId,
            name: this.state.pickedFiles[0].fileName
        };
        var proofObjResponse = await this.props.dispatch(
            proofActions.addPoofVersion(proofVersionRequest)
        );
        var proofVersionResponseObj = {
            id: this.state.proofId,
            defaultVersionId: this.state.proofVersionId
        };
        await this.props.dispatch(proofActions.ingestProof(proofVersionResponseObj));
        await utilService.setStateAsync.bind(this)({
            proofVersion: proofObjResponse.proofVersion
        });
    }

    async pickedFilesChangeHelper(func) {
        let updated = this.state.pickedFiles.map((element, index) => {
            var file = { ...element };

            if (index === this.state.currentIndex) {
                func(file);
            }

            return file;
        });

        await utilService.setStateAsync.bind(this)({
            pickedFiles: updated
        });
    }

    async onFileUploadCompletedProof(obj) {
        this.props.dispatch(alertActions.loading(true));

        await this.pickedFilesChangeHelper((file) => {
            file.fileUploadStatus = fileUploadConstants.FINALIZING;
        });

        await this.AddProof();

        this.props.dispatch(alertActions.success('Saved !'));

        await this.pickedFilesChangeHelper((file) => {
            file.progressNow = obj.progressNow;
            file.completed = obj.completed;
            file.fileUploadStatus = fileUploadConstants.COMPLETED;
        });

        this.props.dispatch(alertActions.loading(false));

        await utilService.setStateAsync.bind(this)({
            currentIndex: this.state.currentIndex + 1
        });

        if (this.state.currentIndex < this.state.pickedFiles.length) {
            await this.onCreateProof();
        } else {
            this.onModalClose();
        }
    }
    async onFileUploadCompletedVersion(obj) {
        var proofVersionRequest = {
            id: this.state.proofId
        };

        this.props.dispatch(alertActions.loading(true));
        await utilService.setStateAsync.bind(this)({
            fileUploadStatus: fileUploadConstants.FINALIZING
        });
        await this.AddProofVersion();
        await this.props.dispatch(proofActions.get(proofVersionRequest.id));
        await this.props.dispatch(proofActions.getProofVersions(proofVersionRequest.id));
        this.props.dispatch(alertActions.success('Saved !'));
        this.props.dispatch(alertActions.loading(false));
        await utilService.setStateAsync.bind(this)({
            progressNow: obj.progressNow,
            completed: obj.completed,
            fileUploadStatus: fileUploadConstants.COMPLETED
        });
        this.props.onFileUploadCompleted(this.state.proofVersion);
        this.onModalClose();
    }

    filUploadCancel(file) {
        if (this.state.fileUploadPromise && !this.state.completed) {
            this.state.fileUploadPromise.cancel().then(() => {
                this.props.dispatch(alertActions.loading(false));
                this.setState({
                    progressNow: file ? 1 : 0,
                    completed: false,
                    fileUploadStatus: file
                        ? fileUploadConstants.INITIATED
                        : fileUploadConstants.NONE,
                    fileUploadPromise: null,
                    fileUploadTimeLeft: 0,
                    fileName: file ? file.name : null,
                    file,
                    disableRemove: false
                });
            });
        }
    }

    async onCreateHTMLStaticProof() {
        this.props.dispatch(alertActions.loading(true));
        this.setState({ webProofInputValidated: false });
        var s3TempInfoPayload = {
            name: this.state.fileUploadObject.fileName
        };
        var s3TempInfoResponse = await this.props.dispatch(
            s3InfoActions.getS3TempInfo(s3TempInfoPayload)
        );
        await utilService.setStateAsync.bind(this)({
            proofId: s3TempInfoResponse.proofId,
            proofVersionId: s3TempInfoResponse.proofVersionId
        });

        let payload = {
            proofId: s3TempInfoResponse.proofId,
            proofVersionId: s3TempInfoResponse.proofVersionId,
            name: this.state.fileUploadObject.fileName,
            websiteURL: this.state.fileUploadObject.websiteURL,
            directoryNodeId: this.state.currentDirectoryNodeId
        };

        await proofService.createHTMLStaticProof(payload);
        this.props.dispatch(alertActions.success('Saved !'));
        this.props.dispatch(alertActions.loading(false));
        this.onModalClose();
    }
    async onCreateHTMLStaticVersion() {
        this.props.dispatch(alertActions.loading(true));
        this.setState({ webProofInputValidated: false });
        var s3TempInfoPayload = {
            name: this.state.fileUploadObject.fileName,
            proofId: this.state.proofId
        };
        var s3TempInfoResponse = await this.props.dispatch(
            s3InfoActions.getS3TempInfo(s3TempInfoPayload)
        );
        await utilService.setStateAsync.bind(this)({
            proofVersionId: s3TempInfoResponse.proofVersionId,
            uploadPath: s3TempInfoResponse.uploadPath,
            uploadPathThumb: s3TempInfoResponse.uploadPathThumb
        });

        let payload = {
            proofId: s3TempInfoResponse.proofId,
            proofVersionId: s3TempInfoResponse.proofVersionId,
            name: this.state.fileUploadObject.fileName,
            websiteURL: this.state.fileUploadObject.websiteURL,
            directoryNodeId: this.state.currentDirectoryNodeId
        };
        var response = await proofService.addHTMLStaticProofVersion(payload);

        await this.props.dispatch(proofActions.get(payload.proofId));
        await this.props.dispatch(proofActions.getProofVersions(payload.proofId));

        this.props.dispatch(alertActions.success('Saved !'));
        this.props.dispatch(alertActions.loading(false));

        this.props.onFileUploadCompleted(response.proofVersion);
        this.onModalClose();
    }

    async onCreateHTMLLiveProof() {        
        this.props.dispatch(alertActions.loading(true));
        this.setState({ webProofInputValidated: false });
        var s3TempInfoPayload = {
            name: this.state.fileUploadObject.fileName
        };
        var s3TempInfoResponse = await this.props.dispatch(
            s3InfoActions.getS3TempInfo(s3TempInfoPayload)
        );
        await utilService.setStateAsync.bind(this)({
            proofId: s3TempInfoResponse.proofId,
            proofVersionId: s3TempInfoResponse.proofVersionId
        });

        let payload = {
            proofId: s3TempInfoResponse.proofId,
            proofVersionId: s3TempInfoResponse.proofVersionId,
            name: this.state.fileUploadObject.fileName,
            websiteURL: this.state.fileUploadObject.websiteURL,
            directoryNodeId: this.state.currentDirectoryNodeId,
            HTMLProofType : 5 
        };

        await proofService.createHTMLLiveProof(payload);
        this.props.dispatch(alertActions.success('Saved !'));
        this.props.dispatch(alertActions.loading(false));
        this.onModalClose();
    }
    async onCreateHTMLLiveVersion() {
        this.props.dispatch(alertActions.loading(true));
        this.setState({ webProofInputValidated: false });
        var s3TempInfoPayload = {
            name: this.state.fileUploadObject.fileName,
            proofId: this.state.proofId
        };
        var s3TempInfoResponse = await this.props.dispatch(
            s3InfoActions.getS3TempInfo(s3TempInfoPayload)
        );
        await utilService.setStateAsync.bind(this)({
            proofVersionId: s3TempInfoResponse.proofVersionId,
            uploadPath: s3TempInfoResponse.uploadPath,
            uploadPathThumb: s3TempInfoResponse.uploadPathThumb
        });

        let payload = {
            proofId: s3TempInfoResponse.proofId,
            proofVersionId: s3TempInfoResponse.proofVersionId,
            name: this.state.fileUploadObject.fileName,
            websiteURL: this.state.fileUploadObject.websiteURL,
            directoryNodeId: this.state.currentDirectoryNodeId
        };
        var response = await proofService.addHTMLLiveProofVersion(payload);

        await this.props.dispatch(proofActions.get(payload.proofId));
        await this.props.dispatch(proofActions.getProofVersions(payload.proofId));

        this.props.dispatch(alertActions.success('Saved !'));
        this.props.dispatch(alertActions.loading(false));

        this.props.onFileUploadCompleted(response.proofVersion);
        this.onModalClose();
    }

    async onUploadAndSaveClicked() {
        this.setState({ isUploadButtonEnabled: false });

        if (this.props.createMode === CREATE_PROOF_MODE.VERSION) {
            await this.onCreateProofVersion();
        } else {
            await this.onCreateProof();
        }
    }

    removePickedFile(id) {
        var array = this.state.pickedFiles.map((f) => f);

        var index = array.findIndex((x) => x.guid === id);
        array.splice(index, 1);

        this.setState({
            pickedFiles: array
        });
    }
    onModalClose() {
        this.filUploadCancel(this.state.file);
        this.props.onHide();
    }

    setWebsiteType(value) {
        this.setState({
            websiteType : value
        });
    }

    setDynamicHtmlType(value) {
        this.setState({
            dynamicHtmlType : value
        });
    }

    renderTimeLeft() {
        var timeLeftInMinutes = (
            this.state.pickedFiles[this.state.currentIndex]?.fileUploadTimeLeft / 60
        ).toFixed(2);
        if (timeLeftInMinutes < 1)
            return (
                <label className="ml-1">
                    {this.state.pickedFiles[this.state.currentIndex]?.fileUploadTimeLeft}s
                </label>
            );
        else return <label className="ml-1">{Math.ceil(timeLeftInMinutes)}min</label>;
    }
    renderFileActionText(pickedFile) {
        switch (pickedFile.fileUploadStatus) {
            case fileUploadConstants.NONE: {
                return (
                    <>
                        {/* <FontAwesomeIcon icon="cloud-upload-alt" size="lg" />
                        <label className="ml-1">Upload</label> */}
                    </>
                );
            }
            case fileUploadConstants.FILE_LOADED: {
                return (
                    <>
                        {/* <FontAwesomeIcon icon="cloud-upload-alt" size="lg" />
                        <label className="ml-2">Ready to Upload</label> */}
                    </>
                );
            }
            case fileUploadConstants.INITIATED: {
                return (
                    <>
                        <Spinner animation="grow" variant="dark" size="sm" />
                        {/* <label className="ml-2">Preparing File Upload ... !</label> */}
                    </>
                );
            }
            case fileUploadConstants.STARTED: {
                return (
                    <>
                        {/* <FontAwesomeIcon icon="hourglass-half" size="lg" /> */}
                        <label className="mr-1 file-output__list-item-status__label">
                            Uploading
                        </label>
                        <Spinner
                            style={{ backgroundColor: 'var(--icon-text-color)' }}
                            animation="grow"
                            variant="dark"
                            size="sm"
                        />
                    </>
                );
            }
            case fileUploadConstants.FINALIZING: {
                return (
                    <>
                        <label className="mr-1 file-output__list-item-status__label">
                            Finalizing Upload
                        </label>
                        <Spinner animation="grow" variant="dark" size="sm" />
                    </>
                );
            }
            case fileUploadConstants.COMPLETED: {
                return (
                    <>
                        <label className="mr-1 file-output__list-item-status__label">
                            File Uploaded
                        </label>
                        <i className="fa fa-check check" />
                    </>
                );
            }
            default:
                break;
        }
    }
    renderSaveButton() {
        switch (this.state.modalViewType) {
            case CREATE_PROOF_MODAL_VIEW.FILE:
                return (
                    <Button
                        variant=""
                        disabled={!this.state.isUploadButtonEnabled}
                        className="mrnda-btn btn-sm"
                        onClick={this.onUploadAndSaveClicked.bind(this)}>
                        Upload & Save
                    </Button>
                );
            case CREATE_PROOF_MODAL_VIEW.WEB:
                return (
                    <Button
                        style={{ width: 72}}
                        variant=""
                        disabled={!this.state.webProofInputValidated}
                        className="mrnda-btn btn-sm"
                        onClick={async () => {
                            if( this.state.websiteType === 0){
                                this.props.createMode === CREATE_PROOF_MODE.PROOF
                                ? await this.onCreateHTMLStaticProof()
                                : await this.onCreateHTMLStaticVersion();
                            } 
                            else {
                                this.props.createMode === CREATE_PROOF_MODE.PROOF
                                ? await this.onCreateHTMLLiveProof()
                                : await this.onCreateHTMLLiveVersion();
                            }
                        }}>
                        Save
                    </Button>
                );

            default:
                return null;
        }
    }
    render() {
        const { modalViewType, fileUploadObject, validations, websiteType, dynamicHtmlType } = this.state;
        const { createMode } = this.props;
        const disableFileUpload =
            this.state.fileUploadStatus === fileUploadConstants.STARTED ||
            this.state.fileUploadStatus == fileUploadConstants.INITIATED ||
            this.state.fileUploadStatus == fileUploadConstants.FINALIZING;
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'}
                scrollable>
                {/* <Modal.Header closeButton={false}>
                    <Modal.Title className="h5" id="contained-modal-title-vcenter">
                        New File
                    </Modal.Title>
                </Modal.Header> */}
                <Modal.Body
                    className={'p-0'}
                    style={{ backgroundColor: 'var(--primary-background-color)' }}>
                    <div
                        style={{
                            overflow: 'hidden auto',
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1
                        }}>
                        <div className="create-proof-modal-header">
                            <div className="mrnda-tabs create-proof-tabs">
                                <div
                                    className={cx({
                                        'mrnda-tabs__item': true,
                                        'create-proof-tab-selector': true,
                                        'mrnda-tabs__item--active':
                                            modalViewType === CREATE_PROOF_MODAL_VIEW.FILE
                                    })}
                                    onClick={async () => {
                                        this.setState({
                                            modalViewType: CREATE_PROOF_MODAL_VIEW.FILE
                                        });
                                    }}>
                                    FILE
                                </div>
                                <div
                                    style={{ pointerEvents: disableFileUpload ? 'none' : 'auto' }}
                                    className={cx({
                                        'mrnda-tabs__item': true,
                                        'create-proof-tab-selector': true,
                                        'mrnda-tabs__item--active':
                                            modalViewType === CREATE_PROOF_MODAL_VIEW.WEB
                                    })}
                                    onClick={async () => {
                                        this.setState({
                                            modalViewType: CREATE_PROOF_MODAL_VIEW.WEB
                                        });
                                    }}>
                                    WEB
                                </div>
                                <div
                                    style={{ pointerEvents: disableFileUpload ? 'none' : 'auto' }}
                                    className={cx({
                                        'mrnda-tabs__item': true,
                                        'create-proof-tab-selector': true,
                                        'mrnda-tabs__item--active':
                                            modalViewType === CREATE_PROOF_MODAL_VIEW.HTML_DYNAMIC
                                    })}
                                    onClick={async () => {
                                        this.setState({
                                            modalViewType: CREATE_PROOF_MODAL_VIEW.HTML_DYNAMIC
                                        });
                                    }}>
                                    HTML Dynamic
                                </div>
                            </div>
                        </div>
                        <div
                            className="mrnda-tabs-content"
                            style={{
                                display:
                                    modalViewType === CREATE_PROOF_MODAL_VIEW.FILE
                                        ? 'flex'
                                        : 'none',
                                alignItems: 'center',
                                flexDirection: 'column',
                                padding: '20px 0px',
                                minHeight: '400px',
                                justifyContent: 'center'
                            }}>
                            <FileUploader
                                ref={this.fileUploaderComponent}
                                onProgressChange={this.onProgressChange.bind(this)}
                                onFileUploadCompleted={async (obj) => {
                                    createMode === CREATE_PROOF_MODE.PROOF
                                        ? await this.onFileUploadCompletedProof(obj)
                                        : await this.onFileUploadCompletedVersion(obj);
                                }}
                                onFileUploadInit={this.onFileUploadInit.bind(this)}
                                onFileUploadStarted={this.onFileUploadStarted.bind(this)}
                                onFileChange={this.onFileChange.bind(this)}
                                files={this.state.pickedFiles}
                                allowedFileExtentions={[...this.state.allowedFileExtentions]
                                    .map((x) => `.${x}`)
                                    .toString()}
                                s3TempInfo={this.props.s3TempInfo}
                                uploadPath={this.state.uploadPath}
                                createMode={this.props.createMode}
                            />

                            {this.state.pickedFiles.map((pickedFile, index) => {
                                return (
                                    <div
                                        key={pickedFile.guid}
                                        className={cx({
                                            'file-details__container': true,
                                            'd-none': !pickedFile.file
                                        })}>
                                        <span className="file-output__list-item-image">
                                            <i className="fas fa-copy fa-3x" />
                                        </span>
                                        <div className="file-output__list-item-header">
                                            <span className="file-output__list-item-name text-truncate">
                                                {pickedFile.fileName}
                                            </span>
                                            <span className="file-output__list-item-status">
                                                {this.renderFileActionText(pickedFile)}
                                            </span>
                                            {this.props.createMode === CREATE_PROOF_MODE.PROOF &&
                                                !pickedFile.disableRemove && (
                                                    <span
                                                        className="file-output__list-item-close"
                                                        onClick={() =>
                                                            this.removePickedFile(pickedFile.guid)
                                                        }>
                                                        <i className="fas fa-times" />
                                                    </span>
                                                )}
                                        </div>
                                        <div className="file-output__list-item-middle-area">
                                            <ProgressBarComponent
                                                progressNow={pickedFile.progressNow}
                                            />
                                        </div>
                                        {pickedFile.file && (
                                            <div className="file-output__list-item-footer">
                                                <div className="file-output__list-item-footer__size">
                                                    <label className="">
                                                        {(pickedFile.file.size / 1048576).toFixed(
                                                            2
                                                        )}{' '}
                                                        mb
                                                    </label>
                                                </div>
                                                {pickedFile.fileUploadStatus ===
                                                    fileUploadConstants.STARTED &&
                                                    pickedFile.progressNow > 0 && (
                                                        <div className="file-output__list-item-footer__time">
                                                            {/* <FontAwesomeIcon icon="stopwatch" size="lg" /> */}
                                                            {this.renderTimeLeft()}
                                                        </div>
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                        
                        {modalViewType === CREATE_PROOF_MODAL_VIEW.WEB &&
                            <CreateProofViewHTML 
                                modalViewType = {modalViewType} 
                                validations ={validations} 
                                handleTextChange = {this.handleTextChange.bind(this)} 
                                fileUploadObject = {fileUploadObject}
                                websiteType = {websiteType} 
                                setWebsiteType = {this.setWebsiteType.bind(this)}
                                />
                        }
                        
                        <CreateProofHTMLDynamicView
                            isVisible={modalViewType === CREATE_PROOF_MODAL_VIEW.HTML_DYNAMIC}
                            proofId={this.state.proofId || null}
                            directoryNodeId={this.state.currentDirectoryNodeId || null}
                            createMode={this.props.createMode}
                            onClose={this.onModalClose.bind(this)}
                            onUploadStart={() => {
                                this.setState({
                                    fileUploadStatus: fileUploadConstants.STARTED
                                });
                            }}
                            onSave={async (proofVersion) => {
                                if (this.props.createMode === CREATE_PROOF_MODE.PROOF) {
                                    this.props.dispatch(alertActions.success('Saved !'));
                                    this.props.dispatch(alertActions.loading(false));
                                    this.onModalClose();
                                } else if (this.props.createMode === CREATE_PROOF_MODE.VERSION) {
                                    await this.props.dispatch(proofActions.get(this.state.proofId));
                                    await this.props.dispatch(
                                        proofActions.getProofVersions(this.state.proofId)
                                    );

                                    this.props.dispatch(alertActions.success('Saved !'));
                                    this.props.dispatch(alertActions.loading(false));

                                    this.props.onFileUploadCompleted(proofVersion);
                                    this.onModalClose();
                                }
                            }}
                            htmlDynamicType={dynamicHtmlType}
                            setHtmlDynamicType={this.setDynamicHtmlType.bind(this)}
                        />
                    </div>
                </Modal.Body>
                {modalViewType !== CREATE_PROOF_MODAL_VIEW.HTML_DYNAMIC && (
                    <Modal.Footer
                        style={{
                            borderTop: '1px solid var(--border-color)',
                            backgroundColor: 'var(--primary-background-color)'
                        }}>
                        {this.renderSaveButton()}
                        <Button
                            className="mrnda-btn--secondary btn-sm"
                            disabled={disableFileUpload ? true : false}
                            onClick={this.onModalClose.bind(this)}>
                            Close
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    const { s3Info } = state;
    return {
        s3TempInfo: s3Info.s3Info
    };
}

const connectedCreateProofModel = connect(mapStateToProps)(CreateProofModal);
export { connectedCreateProofModel as CreateProofModal };
