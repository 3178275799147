export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    GETALL_FILTERS_REQUEST: 'USERS_FILTERS_GETALL_REQUEST',
    GETALL_FILTERS_SUCCESS: 'USERS_FILTERS_GETALL_SUCCESS',
    GETALL_FILTERS_FAILURE: 'USERS_FILTERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    ISEMAILUNIQUE_REQUEST: 'USERS_ISEMAILUNIQUE_REQUEST',
    ISEMAILUNIQUE_SUCCESS: 'USERS_ISEMAILUNIQUE_SUCCESS',
    ISEMAILUNIQUE_FAILURE: 'USERS_ISEMAILUNIQUE_FAILURE',   
    
    PROVIDER_SELECTION_REQUEST: 'PROVIDER_SELECTION_REQUEST',
    PROVIDER_SELECTION_SUCCESS: 'PROVIDER_SELECTION_SUCCESS',
    PROVIDER_SELECTION_FAILURE: 'PROVIDER_SELECTION_FAILURE',
};

export const AUTH_PROVIDER_MAP = {
    0: "TBD",
    1: "Brandshare Collaboration",
    2: "Brandshare DAM",
    3: "Okta Gateway",
    4: "Okta",
}
