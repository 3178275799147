import { roleConstants } from '../../components';

export function rolesData(state = {}, action) {
    switch (action.type) {
        case roleConstants.GETALL_REQUEST:
            return {
                loading: true,
            };
        case roleConstants.GETALL_SUCCESS:
            return {
                rows: action.data.list,
                pageIndex: action.data.pageIndex,
                pageSize: action.data.pageSize,
                totalCount: action.data.totalCount,
                totalPages: action.data.totalPages,
            };
        case roleConstants.GETALL_FAILURE:
            return {
                error: action.error,
            };
        case roleConstants.DELETE_REQUEST:
            // add 'deleting:true' property to role being deleted
            return {
                ...state,
                items: state.items.map(role =>
                    role.id === action.id ? { ...role, deleting: true } : role,
                ),
            };
        case roleConstants.DELETE_SUCCESS:
            // remove deleted role from state
            return {
                items: state.items.filter(role => role.id !== action.id),
            };
        case roleConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to role
            return {
                ...state,
                items: state.items.map(role => {
                    if (role.id === action.id) {
                        // make copy of role without 'deleting:true' property
                        const { deleting, ...roleCopy } = role;
                        // return copy of role with 'deleteError:[error]' property
                        return { ...roleCopy, deleteError: action.error };
                    }

                    return role;
                }),
            };
        default:
            return state;
    }
}

export function rolesFilters(state = {}, action) {
    switch (action.type) {
        case roleConstants.GETALL_FILTERS_REQUEST:
            return {
                loading: true,
            };
        case roleConstants.GETALL_FILTERS_SUCCESS:
            return {
                rolesFilters: action.rolesFilters,
            };
        case roleConstants.GETALL_FILTERS_FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}

// export function moduleActionPerissions(state = {}, action) {
//     switch (action.type) {
//         case roleConstants.GET_MODULE_PERMISSION_REQUEST:
//             return {
//                 loading: true,
//             };
//         case roleConstants.GET_MODULE_PERMISSION_SUCCESS:
//             return {
//                 moduleActionPermissions: action.moduleActionPermissions,
//             };
//         case roleConstants.GET_MODULE_PERMISSION_FAILURE:
//             return {
//                 error: action.error,
//             };
//         default:
//             return state;
//     }
// }
