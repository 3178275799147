//@ts-check

import React from 'react';

const ColorIcon = ({ height = 20, width = 20, color = 'currentColor' }) => {
    return (
        <div style={{ background: color, width: width, height: height, borderRadius: 10, transition : 'all 0.3s' }}></div>
    );
};

export default ColorIcon;
