//@ts-check

import MD5 from 'js-md5';
import { sha256 as SHA256 } from 'js-sha256';
import { authHeader } from '../../_helpers';

const md5 = (x) => {
    const o = MD5.create();
    o.update(x);
    return o.base64();
};
const sha256 = (x) => {
    const o = SHA256.create();
    o.update(x);
    return o.hex();
};

const s3InfoConstants = {
    GET_REQUEST: 'S3INFO_GET_REQUEST',
    GET_SUCCESS: 'S3INFO_GET_SUCCESS',
    GET_FAILURE: 'S3INFO_GET_FAILURE'
};

const evaporateS3Config = {
    bucket: '',
    awsRegion: '',
    aws_key: '',
    signerUrl: ``,
    awsSignatureVersion: '4',
    progressIntervalMS: 200,
    signHeaders: {},
    computeContentMd5: true,
    cryptoMd5Method: (_) => md5(_),
    cryptoHexEncodedHash256: (_) => sha256(_)
};
export { s3InfoConstants, evaporateS3Config };
