import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { history, utilService } from '../../../_helpers';
import { proofActions, ListSelector, ProofVersionPicker } from '../../../components';

class ProofCompareGlobalModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            versionA: {},
            versionB: {},
            inFocus: 'A',
            canCompare: false,
            showVersionPicker: false,
            focus: false
        };
        this.inputs = [];
    }
    // static getDerivedStateFromProps(nextProps) {
    //     if (nextProps.s3TempInfo && nextProps.s3TempInfo.documentId) {
    //         return {
    //             documentId: nextProps.s3TempInfo.documentId
    //         };
    //     } else return null;
    // }
    async componentDidMount() {
        if(this.props.proofObject?.currentVersionId) {
            this.setState({
                showVersionPicker: true,               
            })
        }
    }
    onModalClose() {
        this.props.onHide();
    }
    onProofVersionSelectorChange() {
        this.validateCompare();
    }
    async onCompare() {
        const { versionA, versionB } = this.state;
        if (
            (versionA.mediaType === 2 && versionB.mediaType === 2) ||
            (versionA.mediaType === 2 && versionB.mediaType === 4) ||
            (versionA.mediaType === 4 && versionB.mediaType === 2) ||
            (versionA.mediaType === 4 && versionB.mediaType === 4)
        ) {
            history.push({
                pathname: `/proofvideocompare`,
                data: {
                    proof: this.state.proof,
                    proofCompareVersions: [this.state.versionA, this.state.versionB]
                }
            });
        } else if (
            (versionA.mediaType === 1 && versionB.mediaType === 1) ||
            (versionA.mediaType === 1 && versionB.mediaType === 3) ||
            (versionA.mediaType === 3 && versionB.mediaType === 1) ||
            (versionA.mediaType === 3 && versionB.mediaType === 3)
        ) {
            history.push({
                // pathname: `/proofcompare`,
                pathname: `/proofdocumentcompare`,
                data: {
                    proof: this.state.proof,
                    proofCompareVersions: [this.state.versionA, this.state.versionB]
                }
            });
        }
    }
    validateCompare() {
        var { versionA } = this.state;
        var { versionB } = this.state;
        // if (versionA.id && versionB.id && versionA.id != versionB.id && (versionA.mediaType === 2 && versionB.mediaType ===2)) {
        //     // this.props.dispatch(alertActions.warn("Select different versions."))
        //     this.setState({
        //         canCompare: true,
        //         versionIds: [versionA.id, versionB.id]
        //     });
        // } else {
        //     this.setState({ canCompare: false });
        // }

        if (
            !versionA.id ||
            !versionB.id ||
            versionA.id === versionB.id ||
            // Document with Video
            (versionA.mediaType === 2 && versionB.mediaType === 1) ||
            (versionA.mediaType === 1 && versionB.mediaType === 2) ||
            // HTMLStatic with Video
            (versionA.mediaType === 3 && versionB.mediaType === 2) ||
            (versionA.mediaType === 2 && versionB.mediaType === 3) ||
            // HTMLDynamic with Document
            (versionA.mediaType === 4 && versionB.mediaType === 1) ||
            (versionA.mediaType === 1 && versionB.mediaType === 4) ||
            // HTMLDynamic with HTMLStatic
            (versionA.mediaType === 4 && versionB.mediaType === 3) ||
            (versionA.mediaType === 3 && versionB.mediaType === 4)
        ) {
            // this.props.dispatch(alertActions.warn("Select different versions."))
            this.setState({ canCompare: false });
        } else {
            this.setState({
                canCompare: true,
                versionIds: [versionA.id, versionB.id]
            });
        }
    }
    isThisVersionDefault(versionId) {
        var result = false;
        if (versionId === this.state.proof.defaultVersionId) result = true;
        return result;
    }
    onVersionSelect(selectedRow) {
        if (this.state.inFocus === 'B') {
            this.setState({ versionB: selectedRow, inFocus: 'A' }, () => this.validateCompare());
            this.inputs[0].focus();
        } else {
            this.setState({ versionA: selectedRow, inFocus: 'B' }, () => this.validateCompare());
            this.inputs[1].focus();
        }
    }
    _addInput(index) {
        return (input) => {
            this.inputs[index] = input;
        };
    }
    render() {
        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={this.props.onHide}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop={'static'}
                    scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">File Compare</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    padding: '10px'
                                }}>
                                <div>
                                    <div style={{}}>
                                        <p className="label">Choose Side A</p>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                className="form-control mrnda-input-text"
                                                style={{ minWidth: '250px' }}
                                                value={this.state.versionA.name || ''}
                                                onClick={() =>
                                                    this.setState({
                                                        showVersionPicker: true,
                                                        inFocus: 'A'
                                                    })
                                                }
                                                onChange={() => {}}
                                                ref={this._addInput.bind(this)(0)}></input>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className="label">Choose Side B</p>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            className="form-control mrnda-input-text"
                                            style={{ minWidth: '250px' }}
                                            value={this.state.versionB.name || ''}
                                            onClick={() =>
                                                this.setState({
                                                    showVersionPicker: true,
                                                    inFocus: 'B'
                                                })
                                            }
                                            ref={this._addInput.bind(this)(1)}
                                            onChange={() => {}}></input>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="mt-2">                                
                                {this.state.showVersionPicker && (
                                    <ListSelector
                                        currentDirectoryNodeId={this.props.currentDirectoryNodeId}
                                        proofId={this.props.proofId}
                                        defaultSelectedId = {this.props.proofObject?.currentVersionId}
                                        defaultSelectedProofId = {this.props.proofObject?.currentVersionProofId}                                      
                                        onRowSelected={(selectedRow) =>
                                            this.onVersionSelect(selectedRow)
                                        }></ListSelector>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant=""
                            disabled={!this.state.canCompare}
                            className="btn mrnda-btn"
                            onClick={this.onCompare.bind(this)}>
                            Compare
                        </Button>
                        <Button
                            className="mrnda-btn--secondary"
                            onClick={this.onModalClose.bind(this)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
function mapStateToProps(state) {
    console.log('States in CreateProofModal:', state);
    const { s3Info } = state;
    return {
        s3TempInfo: s3Info.s3Info
    };
}

const connectedCreateProofModel = connect(mapStateToProps)(ProofCompareGlobalModal);
export { connectedCreateProofModel as ProofCompareGlobalModal };
