// import 'rc-tree/assets/index.css';
import React, { Component } from 'react';
import Tree, { TreeNode } from 'rc-tree';
import './TreeView.css';
import { utilService } from '../../../_helpers';
import LucidLinkIcon from '../Icon/LucidLinkIcon';

function generateTreeNodes(treeNode) {
    const arr = [];
    const key = treeNode.props.eventKey;
    for (let i = 0; i < 3; i++) {
        arr.push({ name: `leaf ${key}-${i}`, key: `${key}-${i}` });
    }
    return arr;
}

function setLeaf(treeData, curKey, level) {
    const loopLeaf = (data, lev) => {
        const l = lev - 1;
        data.forEach(item => {
            if (
                item.key.length > curKey.length
                    ? item.key.indexOf(curKey) !== 0
                    : curKey.indexOf(item.key) !== 0
            ) {
                return;
            }
            if (item.children) {
                loopLeaf(item.children, l);
            } else if (l < 1) {
                item.isLeaf = true;
            }
        });
    };
    loopLeaf(treeData, level + 1);
}

function getNewTreeData(treeData, curKey, child, level) {
    const loop = data => {
        if (level < 1 || curKey.length - 3 > level * 2) return;
        data.forEach(item => {
            if (curKey.indexOf(item.key) === 0) {
                if (item.children) {
                    loop(item.children);
                } else {
                    item.children = child;
                }
            }
        });
    };
    loop(treeData);
    setLeaf(treeData, curKey, level);
}
class TreeView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            treeData: this.props.treeData,
            checkedKeys: [],
            selectedKeys: this.props.selectedKeys,
            expandedKeys: this.props.expandedKeys
        };
    }

    componentDidMount() {
        // setTimeout(() => {
        //     this.setState({
        //         treeData: [
        //             { name: 'Folder 01', key: '0-0' },
        //             { name: 'Folder 02', key: '0-1' },
        //             { name: 'Folder 03', key: '0-2', isLeaf: true },
        //         ],
        //         checkedKeys: ['0-0'],
        //     });
        // }, 100);
    }
    // static getDerivedStateFromProps(nextProps, prevState) {
    //     if (true) {
    //         return {
    //             expandedKeys: nextProps.expandedKeys,
    //         };
    //     } else {
    //         return null;
    //     }
    // }
    onSelect(selectedKeys, info) {
        if (selectedKeys && selectedKeys.length > 0) {
            var node = utilService.searchTree(this.props.treeData[0], selectedKeys[0], 'key');
            this.props.onTreeNodeSelect(node);
            this.setState({ selectedKeys });
        }
    }
    onCheck(checkedKeys) {
        console.log(checkedKeys);
        this.setState({
            checkedKeys,
        });
    }
    onLoadData(treeNode) {
        return new Promise(resolve => {
            setTimeout(() => {
                const treeData = [...this.state.treeData];
                getNewTreeData(treeData, treeNode.props.eventKey, generateTreeNodes(treeNode), 2);
                this.setState({ treeData });
                resolve();
            }, 500);
        });
    }
    async onExpand(expandedKeys) {
        await utilService.setStateAsync.bind(this)({ expandedKeys: expandedKeys})
        this.props.onTreeNodeExpand(expandedKeys);
    }

    renderIcon(treeNode) {
        return treeNode.eventKey === this.props.lucidLinkedDirectory ? (
            <div className='tree-node-icon-container' style= {{ marginRight:"7px", marginLeft:'2px', opacity:'0.8' }}>
               <LucidLinkIcon height={18} width={18}/>
            </div>
        ) : (
            <i className="rc-tree-node-content__icon icon-mask" />
        );
    }
    
    render() {        
        return (
            <div className="treeview__container">
                <Tree
                    // defaultExpandAll
                    onSelect={this.onSelect.bind(this)}
                    // checkedKeys={this.state.checkedKeys}
                    // loadData={this.onLoadData.bind(this)}
                    // autoExpandParent={true}
                    onExpand={this.onExpand.bind(this)}
                    treeData={this.props.treeData}
                    selectedKeys={this.props.selectedKeys}
                    expandedKeys={this.props.expandedKeys}
                    showLine={true}
                    icon={this.renderIcon.bind(this)}
                />
            </div>
        );
    }
}

export { TreeView };
