import {
    groupService,
    groupConstants,
    alertConstants,
    alertActions,
    tenantService,
} from '../../components';
import { history } from '../../_helpers';

export const groupActions = {
    login,
    logout,
    add,
    update,
    getAll,
    getAllGroupFilters,
    getAllMembers,
    isNameUnique,
    delete: _delete,
};

function login(Groupname, password, subdomain = '') {
    return dp => {
        dp(request({ Groupname }));
        dp(loading(true));
        console.log('subdomain: ' + subdomain[0]);
        // var route = '/tenantdashboard';
        // if (subdomain && subdomain.length > 1 && subdomain[0] == "admin")
        //     route = '/admindashboard';
        GroupService.login(Groupname, password).then(
            Group => {
                tenantService.getSetCurrentTenant().then(tenant => {
                    dp(success(Group));
                    dp(loading(false));
                    history.push('/dashboard');
                });
            },
            error => {
                dp(failure(error.toString()));
                // dp(alertActions.error(error.toString()));
                dp(loading(false));
            },
        );
    };

    function request(group) {
        return { type: groupConstants.LOGIN_REQUEST, group };
    }
    function success(group) {
        return { type: groupConstants.LOGIN_SUCCESS, group };
    }
    function failure(group) {
        return { type: groupConstants.LOGIN_FAILURE, group };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function logout() {
    groupService.logout();
    history.push('/dashboard');
    return { type: groupConstants.LOGOUT };
}

function add(group) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(group));
            dispatch(loading(true));
            groupService.add(group).then(
                group => {
                    dispatch(success(group));
                    dispatch(loading(false));
                    resolve(group);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };
    function request(group) {
        return { type: groupConstants.ADD_REQUEST, group };
    }
    function success(group) {
        return { type: groupConstants.ADD_SUCCESS, group };
    }
    function failure(error) {
        return { type: groupConstants.ADD_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function update(group) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(group));
            dispatch(loading(true));
            groupService.update(group).then(
                group => {
                    dispatch(success(group));
                    dispatch(loading(false));
                    resolve(group);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };
    function request(group) {
        return { type: groupConstants.ADD_REQUEST, group };
    }
    function success(group) {
        return { type: groupConstants.ADD_SUCCESS, group };
    }
    function failure(error) {
        return { type: groupConstants.ADD_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function getAll(paramsObj) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            groupService.getAll(paramsObj).then(
                async paginatedProofData => {
                    dispatch(success(paginatedProofData));
                    dispatch(loading(false));
                    resolve(paginatedProofData);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: groupConstants.GETALL_REQUEST };
    }
    function success(data) {
        return { type: groupConstants.GETALL_SUCCESS, data };
    }
    function failure(error) {
        return { type: groupConstants.GETALL_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function getAllGroupFilters() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            groupService.getAllGroupFilters().then(
                groupsFilters => {
                    dispatch(success(groupsFilters));
                    dispatch(loading(false));
                    resolve();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: groupConstants.GETALL_FILTERS_REQUEST };
    }
    function success(groupsFilters) {
        return { type: groupConstants.GETALL_FILTERS_SUCCESS, groupsFilters };
    }
    function failure(error) {
        return { type: groupConstants.GETALL_FILTERS_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function getAllMembers(paramsObj) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            groupService.getAllMembers(paramsObj).then(
                async paginatedProofData => {
                    dispatch(success(paginatedProofData));
                    dispatch(loading(false));
                    resolve(paginatedProofData);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: groupConstants.GETALL_REQUEST };
    }
    function success(data) {
        return { type: groupConstants.GETALL_SUCCESS, data };
    }
    function failure(error) {
        return { type: groupConstants.GETALL_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function isNameUnique(requestObject) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            groupService.isNameUnique(requestObject).then(
                response => {
                    dispatch(success(response));
                    dispatch(loading(false));
                    resolve(response);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: groupConstants.IS_NAME_UNIQUE_REQUEST };
    }
    function success(IsGroupUnique) {
        return { type: groupConstants.IS_NAME_UNIQUE_SUCCESS, IsGroupUnique };
    }
    function failure(error) {
        return { type: groupConstants.IS_NAME_UNIQUE_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        groupService
            .delete(id)
            .then(group => dispatch(success(id)), error => dispatch(failure(id, error.toString())));
    };

    function request(id) {
        return { type: groupConstants.DELETE_REQUEST, id };
    }
    function success(id) {
        return { type: groupConstants.DELETE_SUCCESS, id };
    }
    function failure(id, error) {
        return { type: groupConstants.DELETE_FAILURE, id, error };
    }
}
