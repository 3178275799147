import {drawingConstants} from '@wunderman-thompson/miranda-drawing';
import { contributorsHelper } from '../../_helpers';
import { commentHelper } from '../../_helpers/commentHelper';
export {
    modifyAnnotationColor,
    getPreferredColor,
    additionalProps,
    modifyShapeColor,
    getColorPropName,
    drawSelectionHighlightEffect,
    clearSelectionEffect,
    cleanUpCacheRelatedValues
};
const selectionHighlightGroupId = 'selection-overlay';
function modifyAnnotationColor(shape, userId, contributors, color = null, isCurrentUser = undefined) {
    
    
    var icCommentStamp = shape.type === drawingConstants.ANNOTATION_TYPE.commentStamp.typeName? true : false;
    
    var prefColor = null;
    
    //NOTE: Leadtools remove
    //check properly    
    var currentCol  = icCommentStamp? shape.tintColor: shape.stroke;


    if (currentCol && currentCol !== ''){
        prefColor = contributorsHelper.convertKeyToColor(icCommentStamp? shape.tintColor:shape.stroke);        
    }
    
    else {        
        prefColor = getPreferredColor(userId, contributors, color);
    }

    if (contributors === null || contributors === undefined) return;

    //var prefColor = getPreferredColor(userId, contributors, color);
    
    if (icCommentStamp) {
        shape.tintColor = prefColor;
    } else {
        shape.stroke = prefColor;
    }
}
function getPreferredColor(userId, contributors, color = null) {
    
    var userPreference = contributors.find((x) => x.userID === userId);
    

    if (color !== null) {
       return color;
    }
    else if (userPreference !== null && userPreference !== undefined) {
        return userPreference.color;
    }    
}
function additionalProps  (shape, createdById) {       
    
    var hasPermissionToModify  = commentHelper.canModifyAnnotation(createdById);
        
    if (hasPermissionToModify === false  || (shape.type === drawingConstants.ANNOTATION_TYPE.textHighlight.typeName && shape.action !== 'start')) {
        shape.hasControls = false; 
        shape.lockMovementX = true;
        shape.lockMovementY = true;
    }
    else 
    {
        shape.hasControls = true; 
        shape.lockMovementX = false;
        shape.lockMovementY = false;
    }
        
    if (shape.type === drawingConstants.ANNOTATION_TYPE.textbox.typeName) {
        // shape.editable = false;
        // shape.doubleClicked = textDoubleClicked;
    }
    else {
        shape.strokeWidth = 4;
    }

    if (shape.type === drawingConstants.ANNOTATION_TYPE.commentStamp.typeName) {
        shape.makeSelectable = true;
    }
    cleanUpCacheRelatedValues(shape);
}
function modifyShapeColor(shape, overrideColor) {
    var icCommentStamp =
        shape.type === drawingConstants.ANNOTATION_TYPE.commentStamp.typeName ? true : false;

    var prefColor = null;
    if (overrideColor !== undefined) {
        prefColor = overrideColor;
    } else {
        var currentCol = icCommentStamp ? shape.tintColor : shape.stroke;

        if (currentCol && currentCol !== '') {
            prefColor = contributorsHelper.convertKeyToColor(
                icCommentStamp ? shape.tintColor : shape.stroke
            );
        }
    }

    if (icCommentStamp) {
        shape.tintColor = prefColor;
    } else {
        shape.stroke = prefColor;
    }
}
function getColorPropName(shape) {
    if (shape === undefined) {  return; }
    
    var propName = 'stroke';

    if (shape.type === drawingConstants.ANNOTATION_TYPE.commentStamp.typeName) {
        propName = 'tintColor';
    }
    return propName;
};
function drawSelectionHighlightEffect(mainDrawingCanvas, selectedComment) {
    const overlayColor = 'rgba(0, 0, 0, 0.50)';
    const clonedShapeColor = '#ffffff';
    let canvas = mainDrawingCanvas.fabricCanvas;

    let overlayRect = new fabric.Rect({
        width: canvas.width,
        height: canvas.height,
        fill: overlayColor,
        selectable: false,
        evented: false,
        uuid: selectionHighlightGroupId
    });

    canvas.add(overlayRect);

    var annotations = selectedComment.annotations;

    annotations.forEach((annotation) => {
        var shape = mainDrawingCanvas.getObjectById(annotation.id);
        shape.clone((cloned) => {
            cloned.set('fill', clonedShapeColor);
            cloned.set('uuid', selectionHighlightGroupId);
            cloned.set('globalCompositeOperation', 'destination-out');
            cloned.set('selectable', false);
            cloned.set('evented', false);
            canvas.add(cloned);
            canvas.bringToFront(shape);
        });      
    });    
    canvas.renderAll(); 
}
function clearSelectionEffect(mainDrawingCanvas) {
    let canvas = mainDrawingCanvas.fabricCanvas;
    const objectsWithUUID = canvas.getObjects().filter(obj => obj.uuid === selectionHighlightGroupId);
    if (objectsWithUUID.length <= 0) {
        return;
    }
    canvas.remove(...objectsWithUUID);
    //canvas.renderAll();
};
function cleanUpCacheRelatedValues (shape) {
    delete shape.ownMatrixCache;
    delete shape.ownCaching;
    delete shape.cacheTranslationX;
    delete shape.cacheTranslationY;
    delete shape.cacheWidth;
    delete shape.cacheHeight;
    delete shape._cacheProperties;
    delete shape._cacheCanvas;
    delete shape._cacheContext;    
}