import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { userActions, SupportContactArea } from '../..';
import { utilService, history } from '../../../_helpers';
import './UserLoginPage.scss';
import { tenantService } from '../../Tenant';
import withLiveUpdateHook from '../../Proof/LiveUpdateWrapper';
import { withRouter } from 'react-router-dom';
import withNoAuthNav from '../../_shared/Nav/withNoAuthNav';

class OktaAuthLanding extends React.Component {
    constructor(props) {
        super(props);
        const subdomain = window.location.host.split('.')[0];
        this.state = {
            username: ''
        };           
    }

    async componentDidMount() {
        const strUrl = window.location.href;
        if(strUrl.includes("AutheToken")!= -1){
            const params = strUrl.split("AutheToken=");
            const autheToken =  params.length > 1? params[1]: undefined;        
            
            if(autheToken != undefined){
                //var user = await userActions.redirectToOktaLogin(autheToken); 
                var user = await this.props.dispatch(
                    userActions.redirectToOktaLogin(autheToken)
                );
                
                if (user && user.success) {
                    localStorage.setItem('user', JSON.stringify(user));                                        
                    var { from } = this.props.location.state || {
                        from: { pathname: '/dashboard/folders' }
                    };
                    history.push({ ...from, hasBackRoute: false });
                }           
            }
        }     
    }

    
    render() {
        const {            
            username
        } = this.state;
        return (
            <div className="mrnda-main-content padding-2x">                
            </div>
        );
    }
}

function mapStateToProps(state) {
    console.log('IN Login:');
    console.log(state);
    const { authentication } = state;
    return {
        authentication: authentication.user ? authentication.user.authData : {}
    };
}

const withLiveUpdate = withLiveUpdateHook(OktaAuthLanding);
const connectedUserLandingPage = withNoAuthNav(connect(mapStateToProps)(withRouter(withLiveUpdate)));
export { connectedUserLandingPage as OktaAuthLanding };
