import { proofActions, directoryService, directoryConstants, alertConstants } from '../../components';
import { history } from '../../_helpers';

export const directoryActions = {
    add,
    update,
    delete: _delete,
    move,
    getAll,
    getPermittedAll,
    isNameUnique,
    getDirectoryPermissions,
    setDirectoryPermission,
    navigateToDirectory,
};

function add(directory) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(directory));
            dispatch(loading(true));
            directoryService.add(directory).then(
                directory => {
                    dispatch(success(directory));
                    dispatch(loading(false));
                    resolve(directory);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };
    function request(directory) {
        return { type: directoryConstants.ADD_REQUEST, directory };
    }
    function success(directory) {
        return { type: directoryConstants.ADD_SUCCESS, directory };
    }
    function failure(error) {
        return { type: directoryConstants.ADD_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function update(directory) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(directory));
            dispatch(loading(true));
            directoryService.update(directory).then(
                directory => {
                    dispatch(success(directory));
                    dispatch(loading(false));
                    resolve(directory);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };
    function request(directory) {
        return { type: directoryConstants.ADD_REQUEST, directory };
    }
    function success(directory) {
        return { type: directoryConstants.ADD_SUCCESS, directory };
    }
    function failure(error) {
        return { type: directoryConstants.ADD_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function _delete(directory) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(directory));
            dispatch(loading(true));
            directoryService.delete(directory).then(
                directory => {
                    dispatch(success(directory));
                    dispatch(loading(false));
                    resolve(directory);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };
    function request(directory) {
        return { type: directoryConstants.DELETE_REQUEST, directory };
    }
    function success(directory) {
        return { type: directoryConstants.DELETE_SUCCESS, directory };
    }
    function failure(error) {
        return { type: directoryConstants.DELETE_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function move(directory) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(directory));
            dispatch(loading(true));
            directoryService.move(directory).then(
                directory => {
                    dispatch(success(directory));
                    dispatch(loading(false));
                    resolve(directory);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };
    function request(directory) {
        return { type: directoryConstants.ADD_REQUEST, directory };
    }
    function success(directory) {
        return { type: directoryConstants.ADD_SUCCESS, directory };
    }
    function failure(error) {
        return { type: directoryConstants.ADD_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function getAll(paramsObj) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            directoryService.getAll(paramsObj).then(
                async responseData => {
                    dispatch(success(responseData));
                    dispatch(loading(false));
                    resolve(responseData);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: directoryConstants.GETALL_REQUEST };
    }
    function success(directories) {
        return { type: directoryConstants.GETALL_SUCCESS, directories };
    }
    function failure(error) {
        return { type: directoryConstants.GETALL_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function getPermittedAll(paramsObj) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            directoryService.getPermittedAll(paramsObj).then(
                async responseData => {
                    dispatch(success(responseData));
                    dispatch(loading(false));
                    resolve(responseData);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: directoryConstants.GETALL_REQUEST };
    }
    function success(directories) {
        return { type: directoryConstants.GETALL_SUCCESS, directories };
    }
    function failure(error) {
        return { type: directoryConstants.GETALL_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function isNameUnique(requestObject) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            directoryService.isNameUnique(requestObject).then(
                response => {
                    dispatch(success(response));
                    dispatch(loading(false));
                    resolve(response);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: directoryConstants.IS_NAME_UNIQUE_REQUEST };
    }
    function success(IsDirectoryUnique) {
        return { type: directoryConstants.IS_NAME_UNIQUE_SUCCESS, IsDirectoryUnique };
    }
    function failure(error) {
        return { type: directoryConstants.IS_NAME_UNIQUE_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function getDirectoryPermissions(paramsObj) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            directoryService.getDirectoryPermissions(paramsObj).then(
                async responseData => {
                    dispatch(success(responseData));
                    dispatch(loading(false));
                    resolve(responseData);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: directoryConstants.GETALL_REQUEST };
    }
    function success(directories) {
        return { type: directoryConstants.GETALL_SUCCESS, directories };
    }
    function failure(error) {
        return { type: directoryConstants.GETALL_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function setDirectoryPermission(directoryPermissions) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(directoryPermissions));
            dispatch(loading(true));
            directoryService.setDirectoryPermissions(directoryPermissions).then(
                directoryPermissions => {
                    dispatch(success(directoryPermissions));
                    dispatch(loading(false));
                    resolve(directoryPermissions);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };
    function request(directoryPermissions) {
        return { type: directoryConstants.ADD_REQUEST, directoryPermissions };
    }
    function success(directoryPermissions) {
        return { type: directoryConstants.ADD_SUCCESS, directoryPermissions };
    }
    function failure(error) {
        return { type: directoryConstants.ADD_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function navigateToDirectory(directoryId) {
    return async dispatch => {
        await dispatch(proofActions.clearProofs());
        history.push({
            pathname:
                directoryId === directoryConstants.ROOT_DIRECTORY_ID
                    ? `/dashboard/folders`
                    : `/dashboard/folders/${directoryId}`,
        });
    };
}
