import { annotationReportConstants } from './annotationReport.constants';
const {
    PROOFSUMMARY_GETVIDEOPROOFVERION_REQUEST,
    PROOFSUMMARY_GETVIDEOPROOFVERION_SUCCESS,
    PROOFSUMMARY_GETVIDEOPROOFVERION_FAILURE,
    PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_REQUEST,
    PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_SUCCESS,
    PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_FAILURE,
    PROOFSUMMARY_DOWNLOADPAGE_REQUEST,
    PROOFSUMMARY_DOWNLOADPAGE_SUCCESS,
    PROOFSUMMARY_DOWNLOADPAGE_FAILURE,
} = annotationReportConstants;
export function annotationReportData(state = {}, action) {

    switch (action.type) {
        case PROOFSUMMARY_DOWNLOADPAGE_REQUEST:
        case PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_REQUEST:
        case PROOFSUMMARY_GETVIDEOPROOFVERION_REQUEST:
            return {
                ...state
            };
        case PROOFSUMMARY_GETVIDEOPROOFVERION_SUCCESS:
            return {
                ...state,
                proofVersion: action.proofVersion
            };

        case PROOFSUMMARY_DOWNLOADPAGE_SUCCESS:

            return {
                ...state,
                isCached: action.isCached,
                cachedDocumentId: action.cacheDocumentId
            };
        case PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_SUCCESS:

            return {
                ...state,
                videoComments: action.videoComments,
            };
        case PROOFSUMMARY_DOWNLOADPAGE_FAILURE:
        case PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_FAILURE:
        case PROOFSUMMARY_GETVIDEOPROOFVERION_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        default:
            return state;
    }
}