import { store } from './';
export const authorizationService = {
    isUserPermitted
};
function isUserPermitted(module, action) {
    const state = store.getState();
    const { authentication } = state;
    var existedModule =
        authentication && authentication.user &&
        authentication.user.rolePermission &&
        authentication.user.rolePermission.find(x => x.key === module);
    var exists = existedModule && existedModule.actions.some(y => y.key == action);
    return exists;
}
