//@ts-check
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { history, utilService } from '../../_helpers';
import { alertActions, proofService, apiAccessActions } from '../../components';
import { useTheme } from '../App/Theme/ThemeContext';
import { useQuery } from '../../_helpers/hooks/useQuery';
import chroma from 'chroma-js';

const ProofEditRequest = (props) => {
    const [message, setMessage] = useState('Loading...');
    const { theme, setTheme, setCustomTheme } = useTheme();
    const query = useQuery();

    useEffect(() => {
        (async function () {
            const hideNavigation = query.get('hideNavigation');
            const hideTranscription = query.get('hideTranscription');
            props.dispatch(
                alertActions.hideTopbar(
                    hideNavigation?.toLocaleLowerCase() === 'true',
                    hideTranscription?.toLocaleLowerCase() === 'true'
                )
            );
            var token = query.get('token');
            var darkMode = query.get('darkMode');
            var mentionURL = query.get('mentionWebhookURL');

            try {
                mentionURL = mentionURL ? decodeURIComponent(mentionURL) : null;
            } catch (error) {
                mentionURL = null;
            }

            updatePlayerColors(darkMode && darkMode?.toLocaleLowerCase() === 'true' ? true : null);

            var proofId = props.match && props.match.params ? props.match.params.proofid : null;
            var versionId = props.match && props.match.params ? props.match.params.versionid : null;
            if (!proofId || !versionId) {
                setMessage('Bad parameters !');
                return;
            }
            var t = await props.dispatch(apiAccessActions.validateToken(token));
            let proofVersion = await proofService.getProofVersion(proofId, versionId);
            if (proofVersion && !proofVersion.id) {
                setMessage('File not found !');
                return;
            }
            let baseRoute =
                proofVersion.mediaType === 2 || proofVersion.mediaType === 4
                    ? '/proofrequest/video'
                    : '/proofrequest/document';
            history.replace({
                pathname: `${baseRoute}/${proofId}/${versionId}`,
                state: { mentionURL }
            });
        })();
    }, []);

    const updatePlayerColors = (isDarkMode) => {
        if (!isDarkMode) {
            setTheme('light');

            const backgroundColor = utilService.getQueryStringValue(
                'backgroundColor',
                window.location.search,
                window.location.href
            );
            const primaryColor = utilService.getQueryStringValue(
                'primaryColor',
                window.location.search,
                window.location.href
            );
            const primaryTextColor = utilService.getQueryStringValue(
                'primaryTextColor',
                window.location.search,
                window.location.href
            );
            const primaryAccentColor = utilService.getQueryStringValue(
                'primaryAccentColor',
                window.location.search,
                window.location.href
            );

            let updatedPlayerColors = {
                backgroundColor: backgroundColor ? `#${backgroundColor}` : theme.backgroundColor,
                primaryColor: primaryColor ? `#${primaryColor}` : theme.primaryColor,
                primaryTextColor: primaryTextColor
                    ? `#${primaryTextColor}`
                    : theme.primaryTextColor,
                primaryAccentColor: primaryAccentColor
                    ? `#${primaryAccentColor}`
                    : theme.primaryAccentColor
            };

            var themeReq = {
                primaryColor: updatedPlayerColors.primaryAccentColor,
                primaryBackgroundColor: updatedPlayerColors.backgroundColor,
                secondaryBackgroundColor: updatedPlayerColors.primaryColor,
                primaryTextColor: updatedPlayerColors.primaryTextColor,
                secondaryTextColor: updatedPlayerColors.primaryTextColor,
                iconTextColor: updatedPlayerColors.primaryTextColor,                
            };

            if (updatedPlayerColors.primaryColor) {
                const tertiaryBackground = chroma(themeReq.secondaryBackgroundColor).darken(0.25).hex();
                themeReq.tertiaryBackgroundColor =  tertiaryBackground;
            }
            setCustomTheme(themeReq);
        } else {
            setTheme('dark');
        }
    };

    return (
        <div
            className="mrnda-main-content d-flex justify-content-center align-items-center"
            style={{}}>
            <h1>{message}</h1>
        </div>
    );
};

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedRegisterPage = connect(mapStateToProps)(withRouter(ProofEditRequest));
export { connectedRegisterPage as ProofEditRequest };
