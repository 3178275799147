export const FILE_SHARE_SETTINGS_TYPES = {
    everyone: {
        value: 1,
        label: 'Everyone',
        tooltip: 'Both registered and external users can be invited only for downloading a file'
    },
    registered: {
        value: 2,
        label: 'Registered User',
        tooltip: 'Only registered users can be invited for both downloading and annotating a file'
    },
    none: { value: 0, label: 'Disabled', tooltip: 'File sharing will be off' }
};

export const FILE_SHARE_RESULT = {
    OTPRequired: 0,
    Success: 1,
    NotFound: 2,
    ExpiredLink: 3,
    NeedLogin: 4,
    OTPInvalid: 5,
    OTPExpired: 6
};

export const FILE_SHARE_MESSAGE_LIMIT = 5000;
