import React from 'react';
import { connect } from 'react-redux';
import { utilService } from '../../_helpers';
import { alertActions, userService, tenantService, ConfirmationModal } from '../../components';
import { DataFetcher } from '../_shared/DataFetcher/DataFetcher';
const initialValidationState = {
    inputDomain: {
        validated: true,
        message: ''
    }
};
class EmailDomainManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailDomains: [],
            validations: initialValidationState,
            inputDomain: '',
            submitted: false            
        };
    }

    async fetchData() {
        var emailDomains = await tenantService.getEmailDomains();
        await utilService.setStateAsync.bind(this)({
            emailDomains: emailDomains           
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value, submitted: false });
    }

    async validateFields() {
        var globalValidation = true;
        var { validations } = this.state;
        var validationCopy = { ...validations };
        var inputDomain = this.state.inputDomain.toLowerCase();
        var emailDomains = [...this.state.emailDomains];

        if (!inputDomain || inputDomain.length < 1 || userService.validateDomain(inputDomain)) {
            validationCopy.inputDomain = {
                validated: false,
                message: 'Enter a valid domain'
            };
            globalValidation = false;
        } else {
            var hasAny =
                emailDomains &&
                emailDomains.length > 0 &&
                emailDomains.some((x) => x == inputDomain);
            if (hasAny) {
                validationCopy.inputDomain = {
                    validated: false,
                    message: 'Domain already exists in the list'
                };
                globalValidation = false;
            }
        }

        await utilService.setStateAsync.bind(this)({
            validations: validationCopy
        });
        return globalValidation;
    }

    handleInputKeyPress(event) {
        if (event.key === 'Enter') {
            this.onDomainAdd();
        }
    }

    async onDomainAdd() {
        event.preventDefault();
        await utilService.setStateAsync.bind(this)({
            submitted: true,
            validations: initialValidationState
        });
        var allValidated = await this.validateFields();
        if (!allValidated) {
            return;
        }
        this.props.dispatch(alertActions.loading(true));
        var emailDomains = [...this.state.emailDomains];
        var addedDomain = this.state.inputDomain.toLowerCase();        
        var exists = emailDomains.some(x => x === addedDomain);
        if (exists === true) {
            this.props.dispatch(alertActions.loading(false));
            return;
        }
        emailDomains.push(addedDomain);

        await tenantService.updateEmailDomains(emailDomains);

        await utilService.setStateAsync.bind(this)({
            emailDomains: emailDomains,
            inputDomain: ''
        });
        this.props.dispatch(alertActions.success('Added !'));
        this.props.dispatch(alertActions.loading(false));
    }

    async onDomainDelete(inputDomain) {
        this.setState({ selectedDomain: inputDomain, showDeleteConfirm: true });       
    }

    async handleOnDomainDelete() {
        this.props.dispatch(alertActions.loading(true));
        var emailDomains = [...this.state.emailDomains];

        emailDomains = emailDomains.filter((x) => x !== this.state.selectedDomain);

        await tenantService.updateEmailDomains(emailDomains);

        await utilService.setStateAsync.bind(this)({
            emailDomains: emailDomains
        });

        this.props.dispatch(alertActions.success('Deleted !'));
        this.props.dispatch(alertActions.loading(false));

        this.setState({ selectedDomain: undefined, showDeleteConfirm: false });       
    }


    render() {
        const { submitted, validations } = this.state;
        return (
            <>
                <div className="settings-view__manage__container">
                    <div className="m-2">
                        <div className="settings-view-modal">
                            <div style={{ minHeight: '80%', fontSize: '13px' }}>
                                <button
                                    id="editor-close-btn"
                                    type="button"
                                    className="close modal-close"
                                    title="Close Modal"
                                    onClick={this.props.onClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h3 className="">Email Domain Whitelist</h3>
                                <hr></hr>
                               <DataFetcher height={'476px'} onFetchData={this.fetchData.bind(this)}>
                                    <div>
                                        <div style={{}}>
                                            <label>Add Valid Domain</label>
                                        </div>
                                        <div
                                            style={{
                                                maxWidth: '300px',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap'
                                            }}
                                            className="d-flex">
                                            <input
                                                style={{ flex: '3 0 50%' }}
                                                type="text"
                                                name="inputDomain"
                                                value={this.state.inputDomain}
                                                onChange={this.handleChange.bind(this)}
                                                onKeyPress={this.handleInputKeyPress.bind(this)}
                                                className="mrnda-input-text margin-1x--bottom margin-1x--right"
                                                placeholder="Domain"
                                                autoFocus=""
                                                autoComplete="off"
                                                required
                                            />
                                            <button
                                                style={{ flex: '0 0 5%' }}
                                                className="mrnda-btn btn-sm"
                                                disabled={this.state.currentNode === null}
                                                onClick={this.onDomainAdd.bind(this)}>
                                                Add
                                            </button>
                                            {submitted && !validations.inputDomain.validated && (
                                                <div
                                                    style={{ flex: '3 0 100%' }}
                                                    className="help-block">
                                                    {validations.inputDomain.message}
                                                </div>
                                            )}
                                        </div>
                                        <hr></hr>
                                        <div className="m-2">
                                            <table
                                                id="FormContainerTable"
                                                className="table table-striped">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>Domain</th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ paddingTop: 16 }}>
                                                    {this.state.emailDomains.map((emailDomain) => (
                                                        <tr
                                                            caption={emailDomain}
                                                            fieldtype="UserSelectorField"
                                                            className="element UserSelectorField permission-folder-row"
                                                            key={emailDomain}>
                                                            <td>
                                                                <label
                                                                    className="span3 control-label"
                                                                    data-value="59c3a2488986220f3466afb2"
                                                                    title={emailDomain}>
                                                                    {emailDomain}
                                                                </label>
                                                            </td>
                                                            <td
                                                                className="_s_DeleteIcon node-delete-icon hide"
                                                                style={{
                                                                    display: 'table-cell'
                                                                }}>
                                                                <a
                                                                    data-value="59c3a2488986220f3466afb2"
                                                                    className="_s_DeleteTreeItems folder-node-delete"
                                                                    title="Delete"
                                                                    onClick={() =>
                                                                        this.onDomainDelete(emailDomain)
                                                                    }>
                                                                    <i className="fa fa-trash fa-margin" />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                               </DataFetcher>
                            </div>
                            <div className="modal-footer"></div>
                        </div>
                    </div>
                </div>
                
                {this.state.showDeleteConfirm && <ConfirmationModal
                    show={this.state.showDeleteConfirm}
                    onHide={() => this.setState({ showDeleteConfirm: false })}
                    onPrimaryClick={this.handleOnDomainDelete.bind(this)}
                    onSecondaryClick={() => this.setState({ showDeleteConfirm: false })}
                    primaryButtonText="Yes"
                    secondaryButtonText="No"
                    title={`Delete domain "${this.state.selectedDomain}"`}
                    bodyText="Are you sure you want to delete this domain ?"
                />}
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

const connectedSettingsPage = connect(mapStateToProps)(EmailDomainManage);
export { connectedSettingsPage as EmailDomainManage };
