export const RichTextConfig = {
    imageUpload: false,
    fileUpload: false,

    // media
    videoEmbed: false,
    urls: false,

    // tables
    table: false,

    // code
    removeStyles: false,
    code: false,
    bold: false,
    italic: false,
    underline: false,

    // text alignment
    leftAlign: false,
    centerAlign: false,
    rightAlign: false,
    justify: false,

    // lists
    ol: false,
    ul: false,

    // title
    heading: false,

    // fonts
    fonts: false,
    fontColor: false,
    fontSize: false
};

export const ParallelUploadCount = 10;