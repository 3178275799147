import React, { useState } from 'react';
import { CREATE_PROOF_MODAL_VIEW } from '../../components';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useTheme } from '../App/Theme/ThemeContext';
import { colorUtilService } from '../../_helpers';
import { CustomInput } from '../_shared/CustomInput';

const WEB_PROOF_TYPES = {
    static: {
        value: 0,
        label: 'Static',
        tooltip: 'The site will be static'
    },
    live: {
        value: 1,
        label: 'Live',
        tooltip: 'The site will be static interactive'
    }
};

const CreateProofViewHTML = ({
    modalViewType,
    fileUploadObject,
    handleTextChange,
    validations,
    websiteType,
    setWebsiteType
}) => {
    const { theme, themeColors }  = useTheme();
    return (
        <div
            style={{
                display: modalViewType === CREATE_PROOF_MODAL_VIEW.WEB ? 'flex' : 'none',
                flexDirection: 'column',
                minHeight: '400px',
                justifyContent: 'center',
                padding: '40px'
            }}>
            <div className={`form-group flex-column mb-4 d-flex`} style={{}}>
                <label htmlFor="link">Select type</label>
                <p className="mb-1">Select the type of you website</p>
                <div style={{ width: '100%', marginTop: '12px' }}>
                    <ButtonGroup>
                        {Object.keys(WEB_PROOF_TYPES).map((key, i) => {
                            const type = WEB_PROOF_TYPES[key];
                            let isSelected = type.value === websiteType;

                            return (
                                <Button
                                    key={type.value}
                                    title={type.tooltip}
                                    style={{
                                        width: 'auto',
                                        height: 'auto',
                                        padding: '12px',
                                        borderRadius: '4px',
                                        border:`solid 1px ${colorUtilService.changeOpacity( themeColors.primaryTextColor , 0.05)}`,
                                        marginRight: '12px',                                        
                                        backgroundColor: 'transparent',
                                        color: 'var(--primary-text-color)'
                                    }}
                                    variant="secondary"
                                    onClick={() => setWebsiteType(type.value)}>                                       
                                    <svg style={{marginRight : '8px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <rect width="20" height="20" rx="10" fill={ isSelected === true ? 'var(--primary-color)' :  colorUtilService.changeOpacity( themeColors.primaryTextColor , 0.05) } />
                                        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="white" stroke-opacity="0.2" />
                                        {   isSelected && 
                                            <circle cx="10" cy="10" r="4" fill={'var(--primary-text-color)'} />
                                        }
                                    </svg>
                                    {type.label}
                                </Button>
                            );
                        })}
                    </ButtonGroup>
                </div>
                <div style={{ marginTop: '32px', marginBottom: '32px', height: '1px', width: '100%', backgroundColor: colorUtilService.changeOpacity( themeColors.primaryTextColor , 0.10)}} ></div>
                <div
                    className={
                        'form-group create-proof-input-group' +
                        (!fileUploadObject.fileName ? ' has-error' : '')
                    }>
                    <label htmlFor="fileName" className="create-proof-input-label">
                        Display Name
                    </label>

                    <CustomInput
                        type="text"
                        name = 'fileName'
                        inputStyle= {{height: '40px'}}
                        handleTextChange={handleTextChange}
                        RightComponent={<div style={{marginLeft : '12px', marginRight: '12px'}}  >.html</div>}></CustomInput>

                    <div
                        className={'help-block'}
                        style={{ opacity: !validations.fileName.validated ? 1 : 0 }}>
                        {validations.fileName.message}
                    </div>
                </div>
                <div
                    className={
                        'form-group create-proof-input-group' +
                        (!fileUploadObject.websiteURL ? ' has-error' : '')
                    }
                    style={{ margin: '0px' }}
                    title={'Valid browsable full URL i.e: http://*, https://*'}>
                    <label htmlFor="websiteURL" className="create-proof-input-label">
                        Website URL
                    </label>
                    <p
                        style={{
                            opacity: '.5',
                            fontSize: '11px',
                            fontStyle: 'italic',
                            width: '310px',
                            marginBottom: '10px'
                        }}>
                        {'Valid browsable full URL -> http://*, https://*, ftp://*'}
                    </p>
                    <input
                        type="text"
                        className="form-control"
                        name="websiteURL"
                        // value={fileUploadObject.websiteURL}
                        onChange={handleTextChange}
                        style={{ height: '40px' }}
                        autoComplete="off"
                    />
                    <div
                        className="help-block"
                        style={{ opacity: !validations.websiteURL.validated ? 1 : 0 }}>
                        {validations.websiteURL.message}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateProofViewHTML;
