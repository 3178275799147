import react from 'react';
import React, { useState } from 'react';
import PlayMarkerIcon from '../../_shared/Icon/PlayMarkerIcon';

function PlayMarker({
    currentFrameNumber,
    frameCount,
    seekToTime,
    isCommentAddMode,
    isDragging,
    setIsDragging,
    isStaticMarker 
}) {
    const [isMouseDown, setIsMouseDown] = useState(false);

    const mouseUpHandler = () => {
        stopDragging();
    };
    const stopDragging = () => {
        setIsDragging(false);
        setIsMouseDown(false);
    };
    const mouseLeaveHandler = (e) => {
        stopDragging();
    };
    const mouseMoveHandler = (e) => {
        if (isMouseDown !== true) {
            return;
        }

        if (isCommentAddMode) {
            return;
        }
        e.stopPropagation();
        let clientX = e.clientX - 20;       
        console.log('dragging x... :' + clientX);
        console.log(e.currentTarget.getBoundingClientRect());
        setIsDragging(true);

        const frameNumber = (clientX / e.currentTarget.offsetWidth) * frameCount;
        console.log('frame number : ' + frameNumber);
        console.log('offset width : ' + e.currentTarget.offsetWidth);
        if (frameNumber > frameCount) {
            return;
        }
        seekToTime(frameNumber);
    };

    const isInside = function (rect, x, y) {
        return rect.x <= x && x <= rect.x + rect.width && rect.y <= y && y <= rect.y + rect.bottom;
    };

    return (
        <div
            style={{
                position: 'absolute',                
                height: '100%',
                width: '100%',
                zIndex: isMouseDown === true ? 1 : 0,               
            }}            
            onMouseUp={mouseUpHandler}
            onMouseMove={mouseMoveHandler}
            onMouseLeave={mouseLeaveHandler}>
            <div
                style={{
                    width: isStaticMarker?  0 : `${(currentFrameNumber / frameCount) * 100}%`,
                    backgroundColor: 'transparent',
                    pointerEvents: isDragging === true ? 'none' : 'auto'
                }}
                className="mrnda-video-editor-area-controls-seekbar-progress">
                <div
                    onMouseDown={() => {
                        console.log('mouse down');
                        setIsMouseDown(true);
                    }}
                    onMouseUp={mouseUpHandler}
                    className="mrnda-video-editor-area-controls-seekbar-frame-marker">
                    <PlayMarkerIcon isStaticMarker = {isStaticMarker} isFocused={isDragging} />
                </div>
            </div>
        </div>
    );
}

export default PlayMarker;
