//@ts-check

import React from 'react';

const PlayIcon = ({ height = 32, width = 32, color = 'currentColor' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="32px/Interaction/Multimedia/play" fill={color}>
                    <rect
                        id="Rectangle"
                        fillOpacity="0"
                        x="0"
                        y="0"
                        width={width}
                        height={height}></rect>
                    <g id="Outline" transform="translate(6.115385, 1.960715)" fillRule="nonzero">
                        <path
                            d="M0,0.500823126 C0,0.104381874 0.439318628,-0.134380335 0.771977954,0.0812662256 L21.6565933,13.6197278 C21.9606227,13.816815 21.9606227,14.2617544 21.6565933,14.4588416 L0.771977954,27.9973031 C0.439318628,28.2129497 0,27.9741875 0,27.5777462 L0,0.500823126 Z M1,1.41982313 L1,26.6578231 L20.464,14.0388231 L1,1.41982313 Z"
                            id="Path-9"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default PlayIcon;
