import { s3InfoConstants } from '../../components';

export function s3Info(state = {}, action) {
  switch (action.type) {
    case s3InfoConstants.GET_REQUEST:
      return {
        loading: true
      };
    case s3InfoConstants.GET_SUCCESS:
      return {
        s3Info: action.s3Info
      };
    case s3InfoConstants.GET_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}