export const statusUtil = {
    filterOutStatus,
    sortItemsAlphabetically
};

function filterOutStatus(status, statusList) {
    var filtered = statusList.filter((x) => x.id !== status.id);
    return filtered;
}

function sortItemsAlphabetically(items) {
    return items.sort((a, b) => a.label.localeCompare(b.label));
}    