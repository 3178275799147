import React, { useState } from 'react';
import { withDocumentAnnotationConsumer } from '../Context/DocumentAnnotationConsumer';
import classNames from 'classnames';
import { PageBackdrop } from './PageBackdrop';

const MAX_WIDTH = 130;

const DocumentEditorThumbnails = ({pages, selectedCanvas, changeSelectedCanvas}) => {
    
    const [isLoaded, setIsLoaded] = useState(false);    
    
    return (
        <div className={'mrnda-document-editor-area-body-thumbnail-area'}>
            <div>
                {pages.map((page, index) => {
                    var height = (page.height / page.width) * MAX_WIDTH;
                    return (
                        <div
                            className={classNames({
                                'mrnda-document-editor-area-body-thumbnail-area-thumbnail__selected':
                                    index === selectedCanvas,
                                'mrnda-document-editor-area-body-thumbnail-area-thumbnail': true
                            })}
                            key={index}
                            onClick={() => {
                                var marginTop = 20;
                                console.log(`Page-${index} is selected !`);
                                changeSelectedCanvas(index);
                            }}>
                            <div style={{ position: 'relative', width: MAX_WIDTH, height: height }}>
                                

                                <img
                                    crossOrigin="anonymous"
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        height: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        transition: 'opacity .7s',
                                        opacity: isLoaded === true ? '1' : '0',
                                        position: 'absolute',
                                    }}
                                    src={page.thumbnail}
                                    onLoad={(e) => {
                                        setIsLoaded(true);
                                    }}
                                />

                                
                                <PageBackdrop width={MAX_WIDTH} height={height} isLoading = {!isLoaded} ></PageBackdrop>
                                
                            </div>
                            <span>{`Page ${page.pageNumber}`}</span>                            

                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const hoc = withDocumentAnnotationConsumer(DocumentEditorThumbnails);
export { hoc as DocumentEditorThumbnails };