import React from 'react';
import { connect } from 'react-redux';
import { utilService } from '../../_helpers';
import {
    TreeSelectView,
    directoryActions,
    MultiSelect,
    userActions,
    alertActions,
    ConfirmationModal
} from '../../components';
import { groupActions } from '../User';
import { DataFetcher } from '../_shared/DataFetcher/DataFetcher';
class DirectoryPermissionManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredTreeData: [],
            originalTreeData: [],
            currentNode: null,
            treeNodeSelectedValue: { label: '', value: '' },
            permissions: [],

            originalCombinedMembers: [],
            originalMembers: [],
            filteredMembers: [],

            permissionEnabled: false,
            canSave: true
        };
    }


    async loadAllData() {
        await this.loadDirectoryDataFromServer();
        await this.LoadMembersData();
        await this.loadDirectoryPermissionDataFromServer();
    }
    async loadDirectoryPermissionDataFromServer() {
        var directoryPermissions = await this.props.dispatch(
            directoryActions.getDirectoryPermissions({})
        );
        var permissionEnabled = directoryPermissions && directoryPermissions.length > 0;
        this.setState(
            {
                permissions: directoryPermissions,
                permissionEnabled
            },
            () => this.updateTree()
        );
    }

    async loadDirectoryDataFromServer() {
        var directories = await this.props.dispatch(directoryActions.getAll({}));
        this.setState({
            filteredTreeData: directories[0].children,
            originalTreeData: directories
        });
    }

    async LoadMembersData() {
        var membersData = await this.props.dispatch(groupActions.getAllMembers({}));
        var rowsTemp = [...membersData];
        const rows = rowsTemp.map((item) => {
            var newItem = { ...item };
            return newItem;
        });

        var users = rows.filter((x) => x.memberType === 1);
        var groups = rows.filter((x) => x.memberType === 2);
        var groupedOptions = [];
        if (users && users.length > 0) {
            groupedOptions.push({
                label: 'Users',
                options: users
            });
        }
        if (groups && groups.length > 0) {
            groupedOptions.push({
                label: 'Groups',
                options: groups
            });
        }
        await utilService.setStateAsync.bind(this)({
            originalCombinedMembers: membersData,
            originalMembers: groupedOptions,
            filteredMembers: groupedOptions
        });
    }

    onNodeAdded() {
        var { permissions } = this.state;
        var node = utilService.searchTree(
            this.state.originalTreeData[0],
            this.state.currentNode.value,
            'key'
        );
        var nodeExist = permissions
            .filter((x) => x.operationStatus !== 3 && x.operationStatus !== -1)
            .some((v) => this.state.currentNode.value === v.value);
        if (nodeExist) {
            this.props.dispatch(alertActions.warn('Directory already exist'));
            return;
        }
        var childrenIds = utilService.findChildrenArrayIds(node);

        var childrenExist = permissions
            .filter((x) => x.operationStatus !== 3 && x.operationStatus !== -1)
            .some((v) => childrenIds.includes(v.value));

        if (childrenExist) {
            this.props.dispatch(alertActions.warn('Please remove the children first'));
            return;
        }
        permissions.push(this.state.currentNode);
        this.setState({ permissions, treeNodeSelectedValue: null, currentNode: null }, () =>
            this.updateTree()
        );
    }

    onNodeSelect(node) {
        node.operationStatus = 1;
        node.isApplyToChild = true;
        this.setState({ currentNode: node, treeNodeSelectedValue: node });
    }

    onPermissionDelete(permissionKey) {
        var mutatedPermissions = this.state.permissions.map((item) => {
            var newItem = { ...item };
            if (newItem.value === permissionKey) {
                newItem.operationStatus = newItem.operationStatus === 1 ? -1 : 3;
            }
            return newItem;
        });
        this.setState({ permissions: mutatedPermissions }, () => this.updateTree());
    }

    onIsApplyToChildCheckChange(permissionKey, event) {
        var { name, checked } = event.target;
        var { permissions } = this.state;
        var node = utilService.searchTree(this.state.originalTreeData[0], permissionKey, 'key');
        var childrenIds = utilService.findChildrenArrayIds(node);

        var childrenExist = permissions
            .filter((x) => x.operationStatus !== 3 && x.operationStatus !== -1)
            .some((v) => childrenIds.includes(v.value));
        if (childrenExist) {
            this.props.dispatch(alertActions.warn('Please remove the children first'));
            return;
        }
        var permissions = this.state.permissions.map((item) => {
            var newItem = { ...item };
            return newItem;
        });
        var targetPermission = permissions
            .filter((x) => x.operationStatus !== 3 && x.operationStatus !== -1)
            .find((item) => item.value == permissionKey);
        targetPermission.isApplyToChild = checked;
        targetPermission.operationStatus = targetPermission.operationStatus === 1 ? 1 : 2;
        this.setState(
            {
                permissions: permissions
            },
            () => this.updateTree()
        );
    }

    async onChangeUsersMultiSelect(permissionKey, selectedOptions, addedUserIds, removedUserIds) {
        var permissions = this.state.permissions.map((item) => {
            var newItem = { ...item };
            return newItem;
        });
        var targetPermission = permissions
            .filter((x) => x.operationStatus !== 3 && x.operationStatus !== -1)
            .find((item) => item.value == permissionKey);
        targetPermission.members = selectedOptions;
        targetPermission.operationStatus = targetPermission.operationStatus === 1 ? 1 : 2;
        this.setState({
            permissions: permissions
        });
    }

    onMembersSelectFocus(permissionKey) {
        var parentIds = utilService.findParentsArrayIds(
            this.state.originalTreeData,
            permissionKey,
            'key',
            'title',
            'children'
        );
        var clonedMembers = [...this.state.originalCombinedMembers];
        var clonedPermissions = this.state.permissions
            .map((item) => {
                var newItem = { ...item };
                return newItem;
            })
            .filter((x) => x.operationStatus !== 3 && x.operationStatus !== -1);
        var mutatedMembers = clonedMembers.map((item) => {
            var newItem = { ...item };
            return newItem;
        });

        var targetParentId = null;
        if (parentIds.length > 2) {
            //slice for removing root and self, reverse for last parent selection
            targetParentId = parentIds
                .slice(1, -1)
                .reverse()
                .find((parentId) => {
                    return clonedPermissions.map((y) => y.value).includes(parentId);
                });
        }

        if (targetParentId) {
            var parentPermission = clonedPermissions.find((x) => x.value === targetParentId);
            var parentUserMemberIds = parentPermission.members
                .filter((y) => y.memberType === 1)
                .map((z) => z.value);
            var parentGroups = parentPermission.members.filter((y) => y.memberType === 2);
            var parentGroupMemberIds = parentPermission.members
                .filter((y) => y.memberType === 2)
                .map((z) => z.value);
            var usersOnlyMembers = mutatedMembers.filter((x) => x.memberType === 1);
            var compinedMembers = [];
            var validatedUsers = [];
            var validatedGroups = [];
            parentGroups && validatedGroups.push(...parentGroups);

            parentGroupMemberIds.forEach((groupId) => {
                var usersInGroup = usersOnlyMembers.filter((u) => u.groupIds.includes(groupId));
                var uniqueUsers = usersInGroup.filter(
                    (x) => !validatedUsers.map((y) => y.id).includes(x.id)
                );
                uniqueUsers && validatedUsers.push(...uniqueUsers);
            });

            parentPermission.members
                .filter((y) => y.memberType === 1)
                .forEach((user) => {
                    var exists = validatedUsers.some((x) => x.value === user.value);
                    !exists && validatedUsers.push(user);
                });

            compinedMembers = [...validatedUsers, ...validatedGroups];

            var users = compinedMembers.filter((x) => x.memberType === 1);
            var groups = compinedMembers.filter((x) => x.memberType === 2);
            var groupedOptions = [];
            if (users && users.length > 0) {
                groupedOptions.push({
                    label: 'Users',
                    options: users
                });
            }
            if (groups && groups.length > 0) {
                groupedOptions.push({
                    label: 'Groups',
                    options: groups
                });
            }
            this.setState({ filteredMembers: groupedOptions });
        } else {
            var originalMembers = [...this.state.originalMembers];
            this.setState({ filteredMembers: originalMembers });
        }
    }

    updateTree() {
        var clonedTreedata = [...this.state.originalTreeData];
        var mutatedTreedata = clonedTreedata[0].children.map((item) => {
            var newItem = { ...item };
            return newItem;
        });

        this.state.permissions
            .filter(
                (x) =>
                    x.operationStatus !== 3 && x.operationStatus !== -1 && x.isApplyToChild === true
            )
            .forEach((permission) => {
                mutatedTreedata = utilService.deleteNode(
                    { children: mutatedTreedata },
                    'children',
                    permission.value,
                    'key',
                    'children',
                    []
                );
                mutatedTreedata = mutatedTreedata.children;
            });

        this.setState({ filteredTreeData: mutatedTreedata ? mutatedTreedata : [] });
    }

    async onSave() {
        if (!this.isValid()) {
            this.props.dispatch(alertActions.warn("Can't have empty user/group"));
            return;
        }
        this.setState({ canSave: false });
        var results = await this.props.dispatch(
            directoryActions.setDirectoryPermission(this.state.permissions)
        );
        await this.loadAllData();
        this.props.dispatch(alertActions.success('Saved !'));
        this.setState({ canSave: true });
    }

    isValid() {
        var result = this.state.permissions
            .filter((x) => x.operationStatus !== 3 && x.operationStatus !== -1)
            .find((permission) => {
                return !(permission.members && permission.members.length > 0);
            });
        return result === undefined || result === null;
    }

    toggleOnChange(event) {
        var clonedPermissions = this.state.permissions.map((item) => {
            var newItem = { ...item };
            newItem.operationStatus = newItem.operationStatus === 1 ? -1 : 3;
            return newItem;
        });

        this.setState({ permissionEnabled: event.target.checked, permissions: clonedPermissions });
    }
    handleDeleteDirectory() {
        this.onPermissionDelete(
            this.state.selectedPermission.value
        )
        this.setState({ selectedPermission: undefined, showDeleteConfirm: false });        

    }
    handlePermissionDeleteClicked (permission) {
        this.setState({ selectedPermission: permission, showDeleteConfirm: true });        
    }

    render() {
        return (
            <>
                <div className="settings-view__manage__container">
                    <div className="m-2">
                        <div className="settings-view-modal">
                            <div style={{ minHeight: '80%', fontSize: '13px' }}>
                                <button
                                    id="editor-close-btn"
                                    type="button"
                                    className="close modal-close"
                                    title="Close Modal"
                                    onClick={this.props.onClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h3 className="">Directory Permission</h3>
                                <hr></hr>
                                <DataFetcher onFetchData={this.loadAllData.bind(this)} height={'476px'}>

                                    <>
                                        <div
                                            style={{
                                                marginLeft: '8px',
                                                marginBottom: '15px',
                                                display: 'inline-flex'
                                            }}>
                                            <label className="mt-1">Disabled</label>
                                            <div
                                                style={{ marginRight: '10px', marginLeft: '10px', marginTop:'4px' }}
                                                className="custom-control custom-switch">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="customSwitch1"
                                                    checked={this.state.permissionEnabled}
                                                    onChange={this.toggleOnChange.bind(this)}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="customSwitch1"></label>
                                            </div>
                                            <label className="mt-1">Enabled</label>
                                        </div>
                                        {this.state.permissionEnabled && (
                                            <div>
                                                <div style={{ marginLeft: '8px' }}>
                                                    <label>Select & add directory node</label>
                                                </div>
                                                <div
                                                    style={{
                                                        maxWidth: '300px',
                                                        display: 'inline-flex',
                                                        marginLeft: '8px'
                                                    }}>
                                                    <TreeSelectView
                                                        treeData={this.state.filteredTreeData}
                                                        selectedValue={this.state.treeNodeSelectedValue}
                                                        onNodeSelect={this.onNodeSelect.bind(this)}
                                                        placeholder={'Select directory'}
                                                    />
                                                    <button
                                                        style={{ marginLeft: '8px' }}
                                                        className="btn mrnda-btn"
                                                        disabled={this.state.currentNode === null}
                                                        onClick={this.onNodeAdded.bind(this)}>
                                                        Add
                                                    </button>
                                                </div>
                                                <hr></hr>
                                                <div className="m-2">
                                                    <table
                                                        id="FormContainerTable"
                                                        className="table table-striped">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th>Delete</th>
                                                                <th>Directory Name</th>
                                                                <th>Apply To Children</th>
                                                                <th>Members</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ paddingTop: 16 }}>
                                                            {this.state.permissions
                                                                .filter(
                                                                    (permission) =>
                                                                        permission.operationStatus !== 3 &&
                                                                        permission.operationStatus !== -1
                                                                )
                                                                .map((permission, index) => (
                                                                    <tr
                                                                        caption={permission.path}
                                                                        fieldtype="UserSelectorField"
                                                                        className="element UserSelectorField permission-folder-row"
                                                                        key={permission.value}>
                                                                        <td
                                                                            className="_s_DeleteIcon node-delete-icon hide"
                                                                            style={{
                                                                                display: 'table-cell'
                                                                            }}>
                                                                            <a
                                                                                data-value="59c3a2488986220f3466afb2"
                                                                                className="_s_DeleteTreeItems folder-node-delete"
                                                                                title="Delete"
                                                                                onClick={() => {
                                                                                    this.handlePermissionDeleteClicked(permission);
                                                                                }}>
                                                                                <i className="fa fa-trash fa-margin" />
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            <label
                                                                                className="span3 control-label"
                                                                                data-value="59c3a2488986220f3466afb2"
                                                                                title={permission.path}>
                                                                                {permission.label}
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <label className="check-container">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    style={{
                                                                                        position: 'unset'
                                                                                    }}
                                                                                    className="ml-2 mr-2"
                                                                                    data-toggle="toggle"
                                                                                    data-on="Enabled"
                                                                                    data-off="Disabled"
                                                                                    name="isEnabled"
                                                                                    value={
                                                                                        permission.isApplyToChild ||
                                                                                        false
                                                                                    }
                                                                                    checked={
                                                                                        permission.isApplyToChild ||
                                                                                        false
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        this.onIsApplyToChildCheckChange(
                                                                                            permission.value,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                />
                                                                                <span className="check-container__checkmark">
                                                                                    <i className="fas fa-check" />
                                                                                </span>
                                                                            </label>
                                                                        </td>
                                                                        <td className="_s_NodePermissionIcon node-permission-icon ">
                                                                            <span>
                                                                                <MultiSelect
                                                                                    isMulti={true}
                                                                                    options={
                                                                                        this.state
                                                                                            .filteredMembers
                                                                                    }
                                                                                    selectedOptions={
                                                                                        permission.members
                                                                                    }
                                                                                    onChange={(
                                                                                        selectedOptions,
                                                                                        addedUserIds,
                                                                                        removedUserIds
                                                                                    ) =>
                                                                                        this.onChangeUsersMultiSelect(
                                                                                            permission.value,
                                                                                            selectedOptions,
                                                                                            addedUserIds,
                                                                                            removedUserIds
                                                                                        )
                                                                                    }
                                                                                    onFocus={() =>
                                                                                        this.onMembersSelectFocus(
                                                                                            permission.value
                                                                                        )
                                                                                    }
                                                                                    closeMenuOnSelect={true}
                                                                                />
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}

                                    </>
                                </DataFetcher>
                            </div>
                            
                            <div className="modal-footer">
                                <button
                                    className="btn mrnda-btn"
                                    onClick={this.onSave.bind(this)}
                                    disabled={!this.state.canSave}>
                                    Save
                                </button>
                                <button
                                    className="mrnda-btn--secondary"
                                    onClick={this.props.onClose}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
                {this.state.showDeleteConfirm && <ConfirmationModal
                    show={this.state.showDeleteConfirm}
                    onHide={() => this.setState({ showDeleteConfirm: false })}
                    onPrimaryClick={this.handleDeleteDirectory.bind(this)}
                    onSecondaryClick={() => this.setState({ showDeleteConfirm: false })}
                    primaryButtonText="Yes"
                    secondaryButtonText="No"
                    title={`Delete permission for "${this.state.selectedPermission.label}"`}
                    bodyText="Are you sure you want to delete this permission ?"
                />}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedSettingsPage = connect(mapStateToProps)(DirectoryPermissionManage);
export { connectedSettingsPage as DirectoryPermissionManage };
