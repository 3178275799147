import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { withDocumentCompareConsumer } from '../DocumentAnnotation/Context/DocumentAnnotationConsumer';
import { DocumentCompareProvider } from './DocumentCompareProvider';
import { proofService } from '../proof.service';
import { ProofVideoPlayer } from '../VideoAnnotation/ProofVideoPlayer';
import { colorUtilService, history } from '../../../_helpers';
import { DocumentCompareToolbar, ProofVideoCompareToolbarHOC } from './ProofDocumentCompareToolbar';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { proofVersionDownload, proofVersions } from '../proofs.reducer';
import { ProofDocumentEditor } from '../DocumentAnnotation/ProofDocumentEditor';

import {
    convertZoomToScale,
    calculateNewZoom,
    bringToView
} from '../DocumentAnnotation/DocumentAnnotationUtil';

const pages = [
    { page: 1, width: 1275, height: 1650, src: 'https://i.imgur.com/uOlQynF.png' },
    { page: 2, width: 1275, height: 1650, src: 'https://i.imgur.com/yueerJX.png' }
];

const ProofDocumentCompare = (props) => {
    return (
        <DocumentCompareProvider>
            <ProofDocumentCompareWrapperHOC {...props}></ProofDocumentCompareWrapperHOC>
        </DocumentCompareProvider>
    );
};

const CompareCanvas = ({
    isCompareTarget,
    compareIndex,
    index,    
    viewer,
    setCompareDocumentViewer,
    viewerLoaded
}) => {
    useEffect(() => {
        if (viewer) {
            setCompareDocumentViewer(viewer);
        }
        console.log('compare index : ' + compareIndex);
        console.log('viewer : ' + viewer);
    }, [compareIndex]);

    useEffect(() => {
        if (viewer) {
            setCompareDocumentViewer(viewer);
        }       
    }, [viewer]);

    useEffect(() => {
        if (index === compareIndex && viewer !== null) {
            setCompareDocumentViewer(viewer);
        }
    }, [viewerLoaded]);

    return (
        <>
            {isCompareTarget && compareIndex === index && (
                <canvas
                    id="compare-output-canvas"
                    style={{
                        width: '100%',
                        height: '100%',
                        zIndex: 2                        
                    }}></canvas>
            )}
        </>
    );
};

const DocumentCompareViewer = ({
    proofVersion,
    isAutoComparable,
    compareIndex,
    isCompareTarget,
    compareImageSource,
    onViewerLoaded,
    showComments,
    showThumbnails,
    isAutoCompare,
    side,
    setCompareIndex,
    onScrolled,
    setEditorRef,
    compareContainerZoom,
    setCompareContainerZoom,
    setDocumentViewer,
    isOverlayCompare,
    isSynced,
    isCompareHandToolSelected
}) => {
    return (
        <div
            style={{
                width: '100%'
            }}
            className="document-compare-viewer-pages-container">
            {proofVersion.pages && (
                <ProofDocumentEditor
                    compareVersion={proofVersion}
                    showComments={showComments}
                    showThumbnails={showThumbnails}
                    isAutoCompare={isAutoCompare}
                    isOverlayCompare ={isOverlayCompare}
                    side={side}
                    onScrolled={isSynced === true ? onScrolled : () => { }}
                    compareIndex={compareIndex}
                    setCompareIndex={setCompareIndex}
                    setEditorRef={setEditorRef}
                    compareContainerZoom={compareContainerZoom}
                    isCompareHandToolSelected={isCompareHandToolSelected}
                    setCompareContainerZoom={setCompareContainerZoom}
                    setCompareDocumentViewer={setDocumentViewer}                    
                    compareCanvas={
                        <CompareCanvas
                            isCompareTarget={isCompareTarget}
                            isAutoComparable={isAutoComparable}
                            onViewerLoaded={onViewerLoaded}
                            compareImageSource={compareImageSource}
                        />
                    }
                />
            )}
        </div>
    );
};

class ProofDocumentCompareWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.threshold = 0.05;
        this.contrast = 0.2;
        this.left = null;
        this.right = null;
        this.state = {
            proof: {},
            proofCompareVersions: [{ name: 'Side A' }, { name: 'Side B' }]
        };
        this.isSyncingLeftScroll = false;
        this.isSyncingRightScroll = false;
    }

    async componentDidMount() {
        var pp = this.proofVersionA;
        const { data } = this.props.location;
        if (!data) {
            history.goBack();
            return;
        }
        if (!data.proofCompareVersions) {
            history.goBack();
            return;
        }
        var [proofA, proofB] = data.proofCompareVersions;

        var versionA = await proofService.getProofVersion(proofA.proofId, proofA.id);
        var versionB = await proofService.getProofVersion(proofB.proofId, proofB.id);

        this.props.setProofVersionA(versionA);
        this.props.setProofVersionB(versionB);

        document.title = 'Brandshare Collaboration - Compare';

        this.props.setStartAutoCompare(this.startCompare.bind(this));
        this.props.setClearCanvas(this.clearCanvas.bind(this));
    }

    setEditorRef(ref, side) {
        if (side === 'left') {
            this.left = ref;
        } else {
            this.right = ref;
        }
    }

    clearCanvas() {
        if (this.ctx) {
            this.ctx.clearColor(0, 0, 0, 0);
            this.ctx.clear(this.ctx.COLOR_BUFFER_BIT);
        }
    }

    regenerateCanvas = () => {
        //var z = this.props.containerZoom;
        var z = 100;

        var scale = convertZoomToScale(z);

        //Find a new way to get height and width
        var width = this.secondaryChild.offsetWidth * scale;
        var height = this.secondaryChild.offsetHeight * scale;
        if (
            ((this.ctx_shader = null),
            (this.ctx_vx_ptr = null),
            (this.ctx_vx = null),
            (this.ctx_ix = null),
            (this.ctx_tex1 = null),
            (this.ctx_tex2 = null),
            (this.ctx = null),
            this.outputCanvas)
        ) {
            (this.outputCanvas.width = width),
                (this.outputCanvas.height = height),
                (this.ctx = (function (t) {
                    for (var e = 0; e < 4; e++) {
                        var i = t.getContext(
                            ['webgl', 'experimental-webgl', 'moz-webgl', 'webkit-3d'][e]
                        );
                        if (i) return i;
                    }
                    return null;
                })(this.outputCanvas)),
                this.ctx.clearColor(0, 0, 0, 0),
                this.ctx.clear(this.ctx.COLOR_BUFFER_BIT);
            var t = this.ctx.createShader(this.ctx.VERTEX_SHADER);
            this.ctx.shaderSource(
                t,
                '\n\t\t\tattribute vec2 vx;\n\t\t\tvarying vec2 tx;\n\t\t\tvoid main(){\n\t\t\t\tgl_Position=vec4(vx.x*2.0-1.0,1.0-vx.y*2.0,0,1);\n\t\t\t\ttx=vx;\n\t\t\t}\n\t\t'
            ),
                this.ctx.compileShader(t);
            var e = this.ctx.createShader(this.ctx.FRAGMENT_SHADER);
            if (
                (this.ctx.shaderSource(
                    e,
                    '\n\t\t\tprecision lowp float;\n\t\t\tuniform sampler2D sm;\n\t\t\tuniform sampler2D sm2;\n\t\t\tuniform float threshold;\n\t\t\tuniform float contrast;\n\t\t\tuniform vec4 highlightColor;\n\t\t\tvarying vec2 tx;\n\t\t\tfloat sigmoid(float x){\n\t\t\t\treturn 1.0/(1.0+exp2(-(x-0.5)*contrast*contrast*100.0));\n\t\t\t}\n\t\t\tvoid main(){\n\t\t\t\tvec4 c1 = texture2D(sm,tx);\n\t\t\t\tvec4 c2 = texture2D(sm2,tx);\n\t\t\t\tfloat sums = 0.0;\n\t\t\t\tsums += abs(c1.r-c2.r);\n\t\t\t\tsums += abs(c1.g-c2.g);\n\t\t\t\tsums += abs(c1.b-c2.b);\n\t\t\t\tfloat diff = clamp(sums/3.0, 0.0, 1.0);\n\t\t\t\tif(c1.a > 0.0 && c2.a > 0.0 && diff>threshold){\n\t\t\t\t\tfloat midR = sigmoid((c2.r+c2.g+c2.b)/3.0);\n\t\t\t\t\tvec3 diffMask = vec3(\n\t\t\t\t\t\tc1.r * ((highlightColor.r) + midR * (1.0-highlightColor.r)),\n\t\t\t\t\t\tc1.g * ((highlightColor.g) + midR * (1.0-highlightColor.g)),\n\t\t\t\t\t\tc1.b * ((highlightColor.b) + midR * (1.0-highlightColor.b))\n\t\t\t\t\t);\n\t\t\t\t\tc1.r = c1.r * (1.0-highlightColor.a) + diffMask.r * highlightColor.a;\n\t\t\t\t\tc1.g = c1.g * (1.0-highlightColor.a) + diffMask.g * highlightColor.a;\n\t\t\t\t\tc1.b = c1.b * (1.0-highlightColor.a) + diffMask.b * highlightColor.a;\n\t\t\t\t\tgl_FragColor=c1;\n\t\t\t\t}else{\n\t\t\t\t\tdiscard;\n\t\t\t\t}\n\n\t\t\t}\n\t\t'
                ),
                this.ctx.compileShader(e),
                !this.ctx.getShaderParameter(e, this.ctx.COMPILE_STATUS))
            ) {
                var i = this.ctx.getShaderInfoLog(e);
                console.error('Shader compiler log: ' + i);
            }
            (this.ctx_shader = this.ctx.createProgram()),
                this.ctx.attachShader(this.ctx_shader, t),
                this.ctx.attachShader(this.ctx_shader, e),
                this.ctx.linkProgram(this.ctx_shader),
                this.ctx.useProgram(this.ctx_shader),
                (this.ctx_vx_ptr = this.ctx.getAttribLocation(this.ctx_shader, 'vx')),
                this.ctx.enableVertexAttribArray(this.ctx_vx_ptr),
                this.ctx.uniform1i(this.ctx.getUniformLocation(this.ctx_shader, 'sm'), 0),
                this.ctx.uniform1i(this.ctx.getUniformLocation(this.ctx_shader, 'sm2'), 1),
                (this.ctx_vx = this.ctx.createBuffer()),
                this.ctx.bindBuffer(this.ctx.ARRAY_BUFFER, this.ctx_vx),
                this.ctx.bufferData(
                    this.ctx.ARRAY_BUFFER,
                    new Float32Array([0, 0, 1, 0, 1, 1, 0, 1]),
                    this.ctx.STATIC_DRAW
                ),
                (this.ctx_ix = this.ctx.createBuffer()),
                this.ctx.bindBuffer(this.ctx.ELEMENT_ARRAY_BUFFER, this.ctx_ix),
                this.ctx.bufferData(
                    this.ctx.ELEMENT_ARRAY_BUFFER,
                    new Uint16Array([0, 1, 2, 0, 2, 3]),
                    this.ctx.STATIC_DRAW
                ),
                (this.ctx_tex1 = this.ctx.createTexture()),
                (this.ctx_tex2 = this.ctx.createTexture()),
                this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.ctx_tex1),
                this.ctx.texParameteri(
                    this.ctx.TEXTURE_2D,
                    this.ctx.TEXTURE_WRAP_T,
                    this.ctx.CLAMP_TO_EDGE
                ),
                this.ctx.texParameteri(
                    this.ctx.TEXTURE_2D,
                    this.ctx.TEXTURE_WRAP_S,
                    this.ctx.CLAMP_TO_EDGE
                ),
                this.ctx.texParameteri(
                    this.ctx.TEXTURE_2D,
                    this.ctx.TEXTURE_MAG_FILTER,
                    this.ctx.LINEAR
                ),
                this.ctx.texParameteri(
                    this.ctx.TEXTURE_2D,
                    this.ctx.TEXTURE_MIN_FILTER,
                    this.ctx.LINEAR
                ),
                this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.ctx_tex2),
                this.ctx.texParameteri(
                    this.ctx.TEXTURE_2D,
                    this.ctx.TEXTURE_WRAP_T,
                    this.ctx.CLAMP_TO_EDGE
                ),
                this.ctx.texParameteri(
                    this.ctx.TEXTURE_2D,
                    this.ctx.TEXTURE_WRAP_S,
                    this.ctx.CLAMP_TO_EDGE
                ),
                this.ctx.texParameteri(
                    this.ctx.TEXTURE_2D,
                    this.ctx.TEXTURE_MAG_FILTER,
                    this.ctx.LINEAR
                ),
                this.ctx.texParameteri(
                    this.ctx.TEXTURE_2D,
                    this.ctx.TEXTURE_MIN_FILTER,
                    this.ctx.LINEAR
                ),
                (this.canvas_2d_1 = null),
                (this.canvas_2d_2 = null),
                (this.canvas_2d_1 = document.createElement('canvas')),
                (this.canvas_2d_1.width = this.outputCanvas.width),
                (this.canvas_2d_1.height = this.outputCanvas.height),
                (this.context_2d_1 = this.canvas_2d_1.getContext('2d')),
                (this.canvas_2d_2 = document.createElement('canvas')),
                (this.canvas_2d_2.width = this.outputCanvas.width),
                (this.canvas_2d_2.height = this.outputCanvas.height),
                (this.context_2d_2 = this.canvas_2d_2.getContext('2d'));
        }
    };

    drawInstant = (color, opacity) => {
        var e = this;

        var outputCanvasWidth = this.outputCanvas.width;
        var outputCanvasHeight = this.outputCanvas.height;

        if ((this.ctx.clear(this.ctx.COLOR_BUFFER_BIT), this.outputCanvas)) {
            // var i = this.outputCanvas.width / 512,
            //     o = this.outputCanvas.height / 512,
            //     r = 90 === 0 || 270 === 0;

            var i = 1,
                o = 1,
                r = 90 === 0 || 270 === 0;

            var l = this.outputCanvas.getBoundingClientRect();
            if (
                (this.context_2d_1.clearRect(0, 0, this.canvas_2d_1.width, this.canvas_2d_1.height),
                this.context_2d_2.clearRect(0, 0, this.canvas_2d_2.width, this.canvas_2d_2.height),
                this.primaryChild)
            ) {
                var a = ReactDOM.findDOMNode(this.primaryChild);
                var n = a.getBoundingClientRect();

                for (
                    var s = [this.primaryChild],
                        p = function (t) {
                            var a = s[t];
                            var dx = 0,
                                dy = 0,
                                dw = a.width,
                                dh = a.height;

                            e.context_2d_1.save(),
                                e.context_2d_1.drawImage(a, dx, dy, dw, dh),
                                e.context_2d_1.restore();
                        },
                        h = 0;
                    h < s.length;
                    h++
                )
                    p(h);
            }
            //need a different checker
            if (true) {
                var u = 90 === 0 || 270 === 0,
                    c = ReactDOM.findDOMNode(this.secondaryChild);
                if (c) {
                    var d = [this.secondaryChild],
                        f = this.outputCanvas.getBoundingClientRect(),
                        g = function (t) {
                            var r = d[t];
                            if (true) {
                                var a = r.getBoundingClientRect();
                                var aw = r.width;
                                var ah = r.height;

                                e.context_2d_2.save(),
                                    e.context_2d_2.translate(
                                        a.left - f.left + aw / 2,
                                        a.top - f.top + ah / 2
                                    ),
                                    e.context_2d_2.drawImage(d[0], -aw / 2, -ah / 2, aw, ah * o),
                                    e.context_2d_2.restore();
                            }
                        };
                    for (h = 0; h < d.length; h++) g(h);
                }
            }

            this.ctx.uniform1f(
                this.ctx.getUniformLocation(this.ctx_shader, 'threshold'),
                this.threshold
            ),
                this.ctx.uniform1f(
                    this.ctx.getUniformLocation(this.ctx_shader, 'contrast'),
                    this.contrast
                ),
                this.ctx.uniform4f(
                    this.ctx.getUniformLocation(this.ctx_shader, 'highlightColor'),
                    ((16711680 & color) >> 16) / 255,
                    ((65280 & color) >> 8) / 255,
                    (255 & color) / 255,
                    opacity
                ),
                this.ctx.clear(this.ctx.COLOR_BUFFER_BIT),
                this.ctx.activeTexture(this.ctx.TEXTURE0),
                this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.ctx_tex1),
                this.ctx.texImage2D(
                    this.ctx.TEXTURE_2D,
                    0,
                    this.ctx.RGBA,
                    this.ctx.RGBA,
                    this.ctx.UNSIGNED_BYTE,
                    this.canvas_2d_1
                ),
                this.ctx.activeTexture(this.ctx.TEXTURE1),
                this.ctx.bindTexture(this.ctx.TEXTURE_2D, this.ctx_tex2),
                this.ctx.texImage2D(
                    this.ctx.TEXTURE_2D,
                    0,
                    this.ctx.RGBA,
                    this.ctx.RGBA,
                    this.ctx.UNSIGNED_BYTE,
                    this.canvas_2d_2
                ),
                this.ctx.bindBuffer(this.ctx.ARRAY_BUFFER, this.ctx_vx),
                this.ctx.vertexAttribPointer(this.ctx_vx_ptr, 2, this.ctx.FLOAT, !1, 0, 0),
                this.ctx.bindBuffer(this.ctx.ELEMENT_ARRAY_BUFFER, this.ctx_ix),
                this.ctx.drawElements(this.ctx.TRIANGLES, 6, this.ctx.UNSIGNED_SHORT, 0);
        }
    };

    startCompare = (color, opacity) => {
        this.outputCanvas = document.getElementById('compare-output-canvas');

        this.primaryChild =
            this.props.compareSide === 'right'
                ? this.props.documentViewerBRef.current
                : this.props.documentViewerARef.current;
        this.secondaryChild =
            this.props.compareSide === 'right'
                ? this.props.documentViewerARef.current
                : this.props.documentViewerBRef.current;

        this.regenerateCanvas();
        this.drawInstant(color, opacity);
    };

    onDocumentViewerClicked = (index) => {
        console.log('DocumentCompareViewer Clicked at' + index);
        if (
            this.props.proofVersionA.pages[index] !== undefined &&
            this.props.proofVersionB.pages[index] !== undefined
        ) {
            this.props.setCompareIndex(index);
        } else {
            console.log('Not equivalent page to select');
        }
    };

    leftAreaOnScrolled(e) {
        var element = e.currentTarget;

        if (!this.isSyncingLeftScroll) {
            this.isSyncingRightScroll = true;
            this.right.scrollTop = element.scrollTop;
            this.right.scrollLeft = element.scrollLeft;

        }
        this.isSyncingLeftScroll = false;
    }

    rightAreaOnScrolled(e) {
        var element = e.currentTarget;

        if (!this.isSyncingRightScroll) {
            this.isSyncingLeftScroll = true;
            this.left.scrollTop = element.scrollTop;
            this.left.scrollLeft = element.scrollLeft;
        }
        this.isSyncingRightScroll = false;
    }

    onScrolled(scrollSide, event) {
        if (scrollSide === 'left') {
            this.leftAreaOnScrolled(event);
        } else {
            this.rightAreaOnScrolled(event);
        }
    }

    render() {
        let heightStyle = {};
        if (this.props.alert?.isHideTopbar) {
            heightStyle = {
                top: 0
            };
        }

        const params = { proofId: '', versionId: '' };
        return (
            <div className="mrnda-document-compare">
                <div style={heightStyle} className="mrnda-document-compare__container">
                    <div
                        className=""
                        className={cx({
                            'mrnda-document-compare__viewer-space': true,
                            'd-none': this.props.isOverlayCompare === true
                        })}></div>
                    <DocumentCompareToolbar />

                    <div
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            overflow: 'auto',
                            justifyContent: 'center',
                            position: 'relative',
                            height: '100%'
                        }}>
                        <div id="left" className="mrnda-document-compare__viewer-area">
                            <DocumentCompareViewer                                                             
                                side="left"
                                containerStyle={this.props.containerStyle}                                
                                isCompareTarget={this.props.compareSide === 'left'}
                                compareIndex={this.props.compareIndex}
                                proofVersion={this.props.proofVersionA}
                                isOverlayCompare={this.props.isOverlayCompare}
                                showComments={this.props.showComments}
                                showThumbnails={this.props.showThumbnails}
                                isAutoCompare={this.props.isAutoCompare}
                                setCompareIndex={this.props.setCompareIndex}
                                onScrolled={this.onScrolled.bind(this)}
                                setEditorRef={this.setEditorRef.bind(this)}
                                compareContainerZoom={this.props.compareContainerZoom}
                                setCompareContainerZoom={this.props.setCompareContainerZoom}
                                onClicked={this.onDocumentViewerClicked.bind(this)}
                                setDocumentViewer={this.props.setDocumentViewerB}
                                isSynced = {this.props.isSynced}
                                isCompareHandToolSelected = {this.props.compareHandTool}
                            />
                        </div>

                        <div
                            id="right"
                            style={{
                                opacity: this.props.isOverlayCompare ? this.props.overlayOpacity : 1
                            }}
                            className={cx({
                                'mrnda-document-compare__viewer-area': true,
                                'overlay-area': this.props.isOverlayCompare === true
                            })}>
                            <DocumentCompareViewer
                                side="right"                                
                                containerStyle={this.props.containerStyle}                                
                                isCompareTarget={this.props.compareSide === 'right'}
                                compareIndex={this.props.compareIndex}
                                proofVersion={this.props.proofVersionB}
                                isOverlayCompare={this.props.isOverlayCompare}
                                showComments={this.props.showComments}
                                showThumbnails={this.props.showThumbnails}
                                setCompareIndex={this.props.setCompareIndex}
                                onScrolled={this.onScrolled.bind(this)}
                                setEditorRef={this.setEditorRef.bind(this)}
                                compareContainerZoom={this.props.compareContainerZoom}
                                setCompareContainerZoom={this.props.setCompareContainerZoom}
                                isSynced = {this.props.isSynced}
                                onViewerLoaded={() => {
                                    console.log(
                                        '======================================================'
                                    );
                                    console.log('right Viewer loaded');
                                }}
                                isAutoCompare={this.props.isAutoCompare}
                                onClicked={this.onDocumentViewerClicked.bind(this)}
                                setDocumentViewer={this.props.setDocumentViewerA}
                                isCompareHandToolSelected={this.props.compareHandTool}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

// ProofVideoEditorWrapper.contextType = VideoAnnotationContext;
const ProofDocumentCompareWrapperHOC = connect(mapStateToProps)(
    withDocumentCompareConsumer(ProofDocumentCompareWrapper)
);
//const ProofVideoPlayerHOC = withVideoCompareConsumer(ProofVideoPlayer);
export { ProofDocumentCompare, ProofDocumentCompareWrapperHOC as ProofDocumentCompareWrapper };
