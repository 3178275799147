import React, { createContext, useState } from 'react';
import { alertActions } from '../../..';
import { store } from '../../../../_helpers';
import {
    cannotDeleteBody,
    MIN_VISIBLE_BODY,
    cannotDeleteTitle,
    MIN_STATUS_BODY,
    MIN_STATUS_COUNT,
    MIN_STATUS_TITLE,
    STATUS_EXISTS_BODY,
    STATUS_EXISTS_TITLE,
    StatusType,
    initialValidationState,
    statusExistsText,
    MIN_WORKFLOW_COUNT
} from '../helpers/statusConstants';
import { statusUtil } from '../helpers/statusUtil';
import { workflowStatusService } from '../services/workflowStatus.service';
import { commentStatusService } from '../services/commentStatus.service';

const StatusManageContext = createContext();

function StatusManageProvider({ children, statusType }) {
    const [editingComment, setEditingComment] = useState(undefined);
    const [visibleCommentStatuses, setVisibleCommentStatuses] = useState([]);
    const [hiddenCommentStatuses, setHiddenCommentStatuses] = useState([]);
    const [maxStatusLimit, setMaxStatusLimit] = useState();
    const [infoModalData, setInfoModalData] = useState({
        show: false,
        title: 'Info modal',
        body: 'Info modal body',
        secondaryButton: ''
    });

    const [validations, setValidations] = useState(initialValidationState);
    const commentStatuses = [...visibleCommentStatuses, ...hiddenCommentStatuses];


    const statusService = statusType == StatusType.file ? workflowStatusService : commentStatusService;
    
    const handleCommentStatusUpdate = async () => {
        let allValidated = await validateFields(editingComment.label);

        if (!allValidated) {
            exitEditMode();

            var modalData = { ...infoModalData };
            modalData.show = true;
            modalData.secondaryButton = '';
            modalData.title = STATUS_EXISTS_TITLE;
            modalData.body = STATUS_EXISTS_BODY;

            setInfoModalData(modalData);

            return;
        }

        store.dispatch(alertActions.loading(true));
        await statusService.updateStatus(editingComment);
        exitEditMode(editingComment);
        store.dispatch(alertActions.loading(false));

        if (editingComment.isVisible === true) {
            var copied = copyStatusList(visibleCommentStatuses);
            setVisibleCommentStatuses(copied);
        } else {
            var copied = copyStatusList(hiddenCommentStatuses);
            setHiddenCommentStatuses(statusUtil.sortItemsAlphabetically(copied));
        }
    };


    const updateStatusPriority = async (updatedList) => {
        store.dispatch(alertActions.loading(true));
        await statusService.updateStatusPriority(updatedList);
        store.dispatch(alertActions.loading(false));
    };

    const fetchStatusData = async () => {
        
        var { statuses, maxVisible } = await statusService.getAllStatus(true);
        console.log('Max limit', maxVisible);
        setMaxStatusLimit(maxVisible);

        var visibleStatuses = statuses.filter((x) => x.isVisible === true);
        setVisibleCommentStatuses(visibleStatuses);

        var hiddenStatuses = statuses.filter((x) => x.isVisible === false);
        setHiddenCommentStatuses(statusUtil.sortItemsAlphabetically(hiddenStatuses));
    };

    const copyStatusList = (statuses) => {
        let copied = statuses.map((status) => {
            var statusCopy = { ...status };

            if (statusCopy.id === editingComment.id) {
                statusCopy.label = editingComment.label;
            }

            return statusCopy;
        });

        return copied;
    };

    const exitEditMode = (status) => {
        setEditingComment(undefined);
    };

    const validateFields = async (commentStatus) => {
        var globalValidation = true;

        let validationCopy = initialValidationState;
        validationCopy.inputStatus.message = '';

        if (!commentStatus || commentStatus.length < 1) {
            validationCopy.inputStatus = {
                validated: false,
                message: 'Enter a valid annotation comment status'
            };
            globalValidation = false;
        } else {
            var hasAny =
                commentStatuses &&
                commentStatuses.length > 0 &&
                commentStatuses.some((x) => x.label == commentStatus.trim());
            if (hasAny) {
                validationCopy.inputStatus = {
                    validated: false,
                    message: statusExistsText(statusType , commentStatus)
                };
                globalValidation = false;
            }
        }
        setValidations(validationCopy);
        return globalValidation;
    };

    const addStatus = async ( commentStatus) => {

        let allValidated = await validateFields(commentStatus);

        if (!allValidated) {
            return;
        }

        store.dispatch(alertActions.loading(true));

        var currentListLength = visibleCommentStatuses.length;

        var visibility = currentListLength <= maxStatusLimit - 1 ? true : false;

        var addedStatus = await statusService.addStatus({
            label: commentStatus,
            priority: visibleCommentStatuses.length + 1,
            isVisible: visibility
        });

        if (visibility === true) {
            let visibleStatusesCopy = [...visibleCommentStatuses, addedStatus];
            setVisibleCommentStatuses(visibleStatusesCopy);
        } else {                        
            let sorted = statusUtil.sortItemsAlphabetically([...hiddenCommentStatuses, addedStatus]);
            setHiddenCommentStatuses(sorted);
        }

        store.dispatch(alertActions.success('Added !'));
        store.dispatch(alertActions.loading(false));

    }

    const handleDeleteCommentStatus = async (commentStatus) => {
        store.dispatch(alertActions.loading(true));

        await statusService.deleteStatus(commentStatus);

        if (commentStatus.isVisible === true) {
            var newItems = statusUtil.filterOutStatus(commentStatus, [
                ...visibleCommentStatuses
            ]);
            setVisibleCommentStatuses(newItems);
        } else {
            var newItems = statusUtil.filterOutStatus(commentStatus, [
                ...hiddenCommentStatuses
            ]);
            setHiddenCommentStatuses(newItems);
        }

        store.dispatch(alertActions.success('Successfully deleted'));

        store.dispatch(alertActions.loading(false));
    };

    const validateCanDelete = async (commentStatus) => {        
            
        store.dispatch(alertActions.loading(true));        
        
        var totalStatuses = [...visibleCommentStatuses, ...hiddenCommentStatuses];

        let minCount = statusType.type == StatusType.file.type ? MIN_WORKFLOW_COUNT : MIN_STATUS_COUNT;
        if (totalStatuses.length === minCount) {
            var modalData = { ...infoModalData };
            modalData.show = true;
            modalData.secondaryButton = '';
            modalData.title = MIN_STATUS_TITLE;
            modalData.body = MIN_STATUS_BODY;
            setInfoModalData(modalData);
            return;
        }


        //check is only one status is visible and this is the one visible
        //what will be the popup?

        if (visibleCommentStatuses.length === minCount && commentStatus.isVisible === true) {
            //this is the only visible status
            var modalData = { ...infoModalData };
            modalData.show = true;
            modalData.secondaryButton = '';
            modalData.title = cannotDeleteTitle(statusType.label);
            modalData.body = MIN_VISIBLE_BODY;
            setInfoModalData(modalData);
            store.dispatch(alertActions.loading(false));
            return;
        }

        var canDelete = await statusService.canDeleteStatus(commentStatus);
               
        if (canDelete !== true) {               
            var modalData = { ...infoModalData };
            modalData.show = true;
            modalData.secondaryButton = '';
            modalData.title = cannotDeleteTitle(statusType.label);
            modalData.body = cannotDeleteBody(statusType.label, statusType.type === StatusType.comment.type? "comments" : "file versions");
            setInfoModalData(modalData);
        }

        store.dispatch(alertActions.loading(false));

        return canDelete;
    }

    const updateStatus = async (param) => {
        var updatedStatus = param.status;
        store.dispatch(alertActions.loading(true));        
        if(statusType == StatusType.file) {
            await workflowStatusService.updateVisibility(updatedStatus);
        }
        else {
            await statusService.updateStatus(updatedStatus);
        }
        
        if (param.checked === false){
            
            var filtered = param.visibleList.filter(x => x.priority > 0);
   
    
            var prioritized = filtered.map((item, index) => {
                var changedItem = {...item};
                changedItem.priority = index + 1;
                return changedItem; 
            });
            updateStatusPriority(prioritized);
        }

       



        store.dispatch(alertActions.loading(false));
    }

    const providerState = {
        editingComment,
        setEditingComment,
        visibleCommentStatuses,
        setVisibleCommentStatuses,
        hiddenCommentStatuses,
        setHiddenCommentStatuses,
        infoModalData,
        setInfoModalData,
        maxStatusLimit,
        setMaxStatusLimit,
        validations,
        setValidations,
        commentStatuses,
        validateFields,
        handleCommentStatusUpdate,
        exitEditMode,
        statusType,
        fetchStatusData,
        updateStatusPriority,
        addStatus,
        handleDeleteCommentStatus,
        validateCanDelete,
        updateStatus
    };

    return (
        <StatusManageContext.Provider value={providerState}>
            {children}
        </StatusManageContext.Provider>
    );
}

const withStatusManageConsumer = (Component) => {
    return function ContextualComponent(props) {
        return (
            <StatusManageContext.Consumer>
                {(consumer) => <Component {...consumer} {...props} />}
            </StatusManageContext.Consumer>
        );
    };
};

export { StatusManageProvider, withStatusManageConsumer };
