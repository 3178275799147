import cx from 'classnames';
import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import ContributorColorPicker from '../../ContributorColorPicker';
import { drawingConstants } from '@wunderman-thompson/miranda-drawing';
import { getColorPropName } from '../../drawingHelper';

const AnnotationColorChangeTools = ({
    showShapeEditingTools,
    updateCurrentAnnotationOnCanvas,
    currentAnnotationOnCanvas,
    selectedComment,
    setArrowColor,
    arrowColor
}) => {
    const [shapeColor, setShapeColor] = useState('');
    const [showColorPicker, setShowColorPicker] = useState(false);

    // debounce function cleanup
    useEffect(() => {
        return () => {
            handleChangeDebounced.cancel();
        };
    }, []);

    useEffect(() => {
        if (selectedComment === -1) {
            setShowColorPicker(false);
        }
    }, [selectedComment]);

    useEffect(() => {
        if (!currentAnnotationOnCanvas) {
            return;
        }
        
        var color = currentAnnotationOnCanvas[getColorPropName(currentAnnotationOnCanvas)];
        setShapeColor(color);
    }, [currentAnnotationOnCanvas]);

    

    const handleChange = (color) => {
        setShapeColor(color.value);
        console.log('shape color has been changed to ' + color.value);
        updateCurrentAnnotationOnCanvas({
            prop: 'stroke',
            value: color.value
        });        
    };

    const handleChangeDebounced = useCallback(debounce(handleChange, 1000), [
        updateCurrentAnnotationOnCanvas
    ]);

    const onShapeColorChange = (color) => {
        setShapeColor(color.value);
        console.log('shape color has been changed to ' + color.value);

        var updates = [
            {
                prop: getColorPropName(currentAnnotationOnCanvas),
                value: color.value
            }
        ];

        if (currentAnnotationOnCanvas.type === drawingConstants.ANNOTATION_TYPE.textbox.typeName) {
            updates.push({
                prop: 'fill',
                value: color.value
            });
        }
        else if (currentAnnotationOnCanvas.type === drawingConstants.ANNOTATION_TYPE.pinPointer.typeName) {
            updates.push({
                prop: 'fill',
                value: color.value
            });
        }

        updateCurrentAnnotationOnCanvas(updates);
        setArrowColor(color.value);
    };

    return (
        <>
            {showShapeEditingTools && (
                <div
                    style={{
                        marginLeft: '20px',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                    <div
                        style={{
                            height: '20px',
                            width: '2px',
                            backgroundColor: 'var(--icon-text-color)',
                            opacity: '0.3',
                            marginRight: '20px'
                        }}></div>
                    <div className="document-editor-canvas-textbox-container">
                        <div
                            style={{ display: 'flex' }}
                            className="comment__content comment-form__content">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '20px'
                                }}>
                                <div
                                    style={{
                                        height: '20px',
                                        width: '20px',
                                        background: shapeColor,

                                        cursor: 'pointer'
                                    }}
                                    className={cx({
                                        'text-tools-icon': true
                                    })}
                                    title={'Change annotation color'}
                                    onClick={() => {
                                        setShowColorPicker(!showColorPicker);
                                    }}></div>

                                {showColorPicker && (
                                    <div
                                        className={cx({                                            
                                            'document-editor-annotation-color-picker': true,
                                            'skip-comment-click-outside' : true
                                        })}                                                                        
                                        tabIndex="0"
                                        onBlur={() => setShowColorPicker(false)}>
                                        <div className="document-editor-annotation-color-picker__colors">
                                            <ContributorColorPicker
                                                selectedColor={shapeColor}
                                                onSelectedColorChanged={onShapeColorChange}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export { AnnotationColorChangeTools };
