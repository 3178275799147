import React, { useEffect } from 'react';
import { clearSelectionEffect, drawSelectionHighlightEffect } from './drawingHelper';

export const useSelectionHighlightEffect = (enabled, disableArrow, mainDrawingCanvas, selectedComment) => {
    useEffect(() => {
        if (!enabled) {
            return;
        }
        if (
            disableArrow === undefined ||
            mainDrawingCanvas === undefined ||
            mainDrawingCanvas === null
        ) {
            return;
        }
        
        clearSelectionEffect(mainDrawingCanvas);
        
        if (disableArrow === false && selectedComment) {            
            drawSelectionHighlightEffect(mainDrawingCanvas, selectedComment);
        }

                
        return ( () => {})
    }, [enabled, disableArrow, mainDrawingCanvas, selectedComment]);
};
