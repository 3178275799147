import React from 'react';

function SettingsHeader({label, detail,  onConfigureClicked}) {
    return (
        <div className="settings-container">
            <div className="pull-left ">
                <div className="header dottedBottomBorder">{label}</div>
                <div className="details" style={{ paddingBottom: 10 }}>
                    {detail}
                </div>
                <div
                    className="misc-btn"
                    onClick={onConfigureClicked}>
                    <a className="_s_BuildNewApp">Configure</a>
                </div>
            </div>
        </div>
    );
}

export default SettingsHeader;
