import React, { useState, useEffect, useRef } from 'react';
import { VideoCompareContext } from '../VideoAnnotation/Context/VideoAnnotationContext';
import { videoAnnotationConstants } from '../VideoAnnotation/Context/videoAnnotation.constants';
import { convertZoomToScale, calculateNewZoom, bringToView } from '../DocumentAnnotation/DocumentAnnotationUtil';

function VideoCompareProvider({ children }) {
    const [videoPlayerA, setVideoPlayerA] = useState(null);
    const [videoPlayerB, setVideoPlayerB] = useState(null);
    const [documentViewerA, setDocumentViewerA] = useState(null);
    const [documentViewerB, setDocumentViewerB] = useState(null);
    const [proofVersionA, setProofVersionA] = useState({});
    const [proofVersionB, setProofVersionB] = useState({});
    const [compareCanvas, setCompareCanvas] = useState(null);
    const [compareOpacity, setCompareOpacity] = useState(1);
    const [overlayOpacity, setOverlayOpacity] = useState(0.5);    
    const [timeMode, setTimeMode] = useState(videoAnnotationConstants.TIME_MODE.TIME_CODE);
    const [isSynced, setIsSynced] = useState(true);
    const [isAutoCompare, setIsAutoCompare] = useState(false);
    const [isOverlayCompare, setIsOverlayCompare] = useState(false);

    const [compareColor, setCompareColor] = useState('#ff0000');
    const [compareIndex, setCompareIndex] = useState(0);
    const [compareSide, setCompareSide] = useState('right');
    const [containerZoom, setContainerZoom] = useState(100);
    const [zoomStyle, setZoomStyle] = useState(undefined);
    const [containerStyle, setContainerStyle] = useState({ width: '70%' });
    const [showComments, setShowComments] = useState(false);
    const [disableComments, setDisableComments] = useState(false);
    const [volume, setVolume] = useState(0);

    const [shouldTranslate, setShouldTranslate] = useState(false);


    const compareColorRef = useRef(compareColor);
    compareColorRef.current = compareColor;
    const compareOpacityRef = useRef(compareOpacity);
    compareOpacityRef.current = compareOpacity;

    const isAutoCompareRef = useRef(isAutoCompare);
    isAutoCompareRef.current = isAutoCompare;

    const startAutoCompare = useRef(undefined);
    const clearCanvas = useRef(undefined);
    const [focusedSide, setFocusedSide] = useState('left'); 


    var drawCallBackTimeout = null;

    useEffect(() => {
        
        if (isAutoCompare === true) {
            setShowComments(false);
            setDisableComments(true);
        } else {
            setDisableComments(false);            
        }

        if (!isAutoCompare) {
            clearCanvas.current && clearCanvas.current();
        } else {
            drawFrameOnCanvas(videoPlayerA, compareColorRef.current);
            compareCanvases(compareColorRef.current, compareOpacityRef.current);
        }
        return () => {};
    }, [isAutoCompare]);
    
    useEffect(() => {
        var scale = convertZoomToScale(containerZoom);
        //find transform origin 

        var jj = documentViewerA;
        var jp = documentViewerB;
        
        // we need the host scrollable's scroll top
        // var scrollTop = props.editorArea.current.scrollTop;
        // console.log(`Current scroll top is : ${scrollTop}`);

        // props.documentContainer.current.style.transformOrigin = `center ${scrollTop}px`; 
        var top = jj?.scrollTop;
        var tOrigin = (jj === null || jp === null)? 'center top' : `center ${top}px `;

        if (jj !== null || jp !== null) {
            setShouldTranslate(true);
        }
        
        var style = {            
            transform : `matrix(${scale}, 0, 0, ${scale}, 0, 0)`,
            transformOrigin: tOrigin ,        
        };
        setZoomStyle(style);

    }, [containerZoom]);

    useEffect(() => {       
        if (isAutoCompare) {
            compareCanvases(compareColorRef.current, compareOpacityRef.current);
        }
        return () => {};
    }, [compareIndex]);

    useEffect(() => {       
        if (isAutoCompare && compareSide !== 'none') {
            compareCanvases(compareColorRef.current, compareOpacityRef.current);
        }
        return () => {};
    }, [compareSide]);

    const compareCanvases = (selectedColor, opacity) => {
        var cleaned = selectedColor.substring(1);
        var color = parseInt(cleaned, 16);            
        startAutoCompare.current(color, opacity);
    }

    const setStartAutoCompare = (startAutoCompareHandler) => {
        startAutoCompare.current = startAutoCompareHandler;
    }


    const setClearCanvas = (clearCanvasHandler) => {
        clearCanvas.current = clearCanvasHandler;
    }

    // const drawFrameOnCanvas = (video, filterColor) => {    
    //     if (drawCallBackTimeout) clearTimeout(drawCallBackTimeout);

    //     console.log(
    //         '============Draw frame on canvas called===============',
    //         isAutoCompareRef.current,
    //         videoPlayerA.ended,
    //         videoPlayerB.ended
    //     );
    //     compareCanvases(compareColorRef.current, compareOpacityRef.current);

    //     if (
    //         (!videoPlayerA.ended || !videoPlayerB.ended) &&
    //         (!videoPlayerA.paused || !videoPlayerB.paused) &&
    //         isAutoCompareRef.current
    //     ) {
    //         drawCallBackTimeout = setTimeout(function () {
    //             drawFrameOnCanvas(video, '');
    //         }, 50);
    //     } else if( (videoPlayerA.paused && videoPlayerB.paused)) {
    //         // clearCompareCanvas();
    //     }
    // };

    const drawFrameOnCanvas = (video, filterColor) => {    
        if (drawCallBackTimeout) clearTimeout(drawCallBackTimeout);

        if (
            // (!videoPlayerA.ended || !videoPlayerB.ended) &&
            (!videoPlayerA.paused || !videoPlayerB.paused) && // don't draw if both paused and compare off
            isAutoCompareRef.current
        ) {
            console.log(
                '============Compare on canvas called===============',
                isAutoCompareRef.current,
                videoPlayerA.ended,
                videoPlayerB.ended
            );
            compareCanvases(compareColorRef.current, compareOpacityRef.current);
        }

        if(!isAutoCompareRef.current){
            console.log('============Clearing canvas===============');
            clearCompareCanvas();
        }

        drawCallBackTimeout = setTimeout(() => {
            drawFrameOnCanvas(video, '');
        }, 50);
       
    };


    const seekToTimeFromA = (targetFrame) => {
        var seekTime = Number((targetFrame / proofVersionA?.fileMetadata?.frameRate).toFixed(5));
        if (isSynced) {
            videoPlayerA.pause();
            videoPlayerB.pause();
            videoPlayerA.currentTime = seekTime;
            videoPlayerB.currentTime = seekTime;
        } else {
            videoPlayerA.currentTime = seekTime;
        }

        videoPlayerA.onseeked  = (e) => {
            if (isAutoCompare) compareCanvases(compareColorRef.current, compareOpacityRef.current);
            videoPlayerA.onseeked = undefined;
        };
        
    };

    const seekToTimeFromB = (targetFrame) => {
        var seekTime = Number((targetFrame / proofVersionB?.fileMetadata?.frameRate).toFixed(5));
        if (isSynced) {
            videoPlayerA.pause();
            videoPlayerB.pause();
            videoPlayerA.currentTime = seekTime;
            videoPlayerB.currentTime = seekTime;

            // videoPlayerA.onseeked = (e) => {
            //     if (isAutoCompare) drawFrameOnCanvas(videoPlayerA, compareColorRef.current);
            //     videoPlayerA.onseeked = undefined;
            // };
        } else {
            videoPlayerB.currentTime = seekTime;
        }
        videoPlayerB.onseeked  = (e) => {
            if (isAutoCompare) compareCanvases(compareColorRef.current, compareOpacityRef.current);
            videoPlayerB.onseeked = undefined;
        };
    };

    const onVideoPlay = (videoPlayer) => {
        if (isSynced) {
            videoPlayerA.play();
            videoPlayerB.play();
        } else {
            videoPlayer.play();
        }
        if (isAutoCompare) compareCanvases(compareColorRef.current, compareOpacityRef.current);
    };

    const onVideoPause = (videoPlayer) => {
        if (isSynced) {
            videoPlayerA.pause();
            videoPlayerB.pause();
        } else {
            videoPlayer.pause();
        }
    };

    const onResetToSync = () => {
        videoPlayerA.pause();
        videoPlayerB.pause();
        videoPlayerB.currentTime = videoPlayerA.currentTime;
        // var targetFrame = Number((videoPlayerA.currentTime * proofVersionA?.fileMetadata?.frameRate).toFixed(0));
        // seekToTimeFromA(targetFrame);
        // seekToTimeFromB(targetFrame);
        videoPlayerB.onseeked  = (e) => {
            if (isAutoCompare) compareCanvases(compareColorRef.current, compareOpacityRef.current);
            videoPlayerB.onseeked = undefined;
        };
    };

    const onCompareColorChange = (color) => {
        setCompareColor(color);        
        compareCanvases(color, compareOpacityRef.current);
    };

    const onCompareOpacityChange = (opacity) => {
        opacity = Number(opacity);
        setCompareOpacity(opacity);        
        compareCanvases(compareColorRef.current, opacity);
    };

    const clearCompareCanvas = () => {
        if (compareCanvas) {
            clearCanvas.current();
        }
    };

    const zoomIn = () => {        
        setContainerZoom(containerZoom + 5);
        console.log(`Zoom decreased - ${containerZoom}!`);
    }
    const zoomOut = () => {
        setContainerZoom(containerZoom - 5);
        console.log(`Zoom decreased - ${containerZoom}!`);
    }


    const translateOnScroll = (e)=> {
        console.log(
            `=============================on scroll start =================================`
        );
        console.log(`scrollTop :${e.currentTarget.scrollTop}`);
        console.log(`shouldTranslate :${shouldTranslate}`);

        if (e.currentTarget.scrollTop == 0 && shouldTranslate) {
            var clientRect = e.currentTarget.children[0].getBoundingClientRect()            
            var y = clientRect.y;
            var translationValue = 0;
            var prefixValue= 147;
            if (y < 0) {
                translationValue = prefixValue + Math.abs(y);
            } else {
                translationValue = prefixValue - y;
            }
            var currentStyle = zoomStyle;
            var scale = convertZoomToScale(containerZoom);
            
            var newStyle = {
                transform : `matrix(${scale}, 0, 0, ${scale}, 0, ${translationValue})`,
                transformOrigin : currentStyle.transformOrigin
            };
            
            
            setZoomStyle(newStyle);

            console.log(`making it false`);
            setShouldTranslate(false);
        }
        console.log(`=============================on scroll end =================================`);
    }

    const saveCompareVolume = (volume)=> {
        //volumeHelper.set(volume);
        setVolume(volume);
    }

    const providerState = {
        isSynced,
        isAutoCompare,
        documentViewerA,
        setDocumentViewerA,
        documentViewerB,
        setDocumentViewerB,
        proofVersionA,
        proofVersionB,
        compareCanvas,
        compareColor,
        timeMode,
        setProofVersionA,
        setProofVersionB,
        setCompareCanvas,
        onVideoPlay,
        onVideoPause,
        setIsSynced,
        setIsAutoCompare,
        onResetToSync,
        seekToTimeFromA,
        seekToTimeFromB,
        setTimeMode,
        setCompareColor,
        onCompareColorChange,
        setStartAutoCompare,
        compareOpacity,
        onCompareOpacityChange,
        setClearCanvas,
        compareIndex, 
        setCompareIndex,
        compareCanvases,
        compareSide, 
        setCompareSide,
        isOverlayCompare, 
        setIsOverlayCompare,
        overlayOpacity, 
        setOverlayOpacity,
        zoomIn,
        zoomOut,
        zoomStyle, 
        setZoomStyle,
        containerZoom,
        setContainerZoom,
        shouldTranslate, setShouldTranslate,
        translateOnScroll,
        containerStyle, 
        setContainerStyle,
        videoPlayerA, setVideoPlayerA,
        showComments, setShowComments,
        videoPlayerB, setVideoPlayerB,
        disableComments, setDisableComments,
        volume, saveCompareVolume,
        focusedSide, setFocusedSide
    };

    return (
        <VideoCompareContext.Provider value={providerState}>
            {children}
        </VideoCompareContext.Provider>
    );
}

export { VideoCompareProvider };
