import React from 'react';
import cx from 'classnames';

export const StrikedToggleButton = ({ show, setShow, iconClassName, disable = false }) => {
    return (
        <div
            className={cx({
                'mrnda-video-compare__toolbar__settings--item': true,
                active: false,
                'authorization-disabled': disable
            })}
            onClick={() => {
                setShow(!show);
            }}
            title={'Show/hide thumbnails'}>
            {/* <i className="far fa-comment-alt"></i>                     */}
            {/* <i className="fas fa-slash fa-stack-1x" style={{ textShadow: '0.1em 0.15em white' }}></i> */}

            <span className="fa-stack mrnda-document-compare-icon-stack">
                <i className={`far ${iconClassName} fa-stack-1x`} ></i>
                {show && (
                    <i
                        className="fas fa-slash fa-stack-1x"
                        style={{
                            textShadow: '0.1em 0.15em var(--secondary-background-color)'
                        }}></i>
                )}
            </span>
        </div>
    );
};
