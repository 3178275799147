
import React from 'react';
import cx from 'classnames';

const EditorLoading = ({
    isProofCompare,
    isProofReady
}) => {
    return (
        <div
            className={cx({
                'editor-loader': true
            })}
            style={{
                background: 'rgba(0, 0, 0, 0.3)',
                height: isProofCompare === true ?  'calc(100vh - 0px)' : 'calc(100% - 0px)',
                width: isProofCompare === true ?  '50vw' : '100%',
                position: 'absolute',
                zIndex: '9999',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>                
            {
                isProofReady ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                            position: 'absolute',
                            zIndex: '1',
                            pointerEvents: 'none',
                            zIndex: 2,
                            width: '100px',
                            height: '100px'
                        }}>
                        <circle
                            cx="50"
                            cy="50"
                            fill="none"
                            stroke="var(--primary-color)"
                            strokeWidth="6"
                            r="27"
                            strokeLinecap="round"
                            strokeDasharray="127.23450247038662 44.411500823462205">
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                repeatCount="indefinite"
                                dur="0.819672131147541s"
                                values="0 50 50;360 50 50"
                                keyTimes="0;1"></animateTransform>
                        </circle>
                    </svg>
                ) : (
                    <div className="d-flex">
                        <div className="mrnda-empty-state-wrapper">
                            <div>
                                <i
                                    style={{ fontSize: '40px', marginBottom: '12px' }}
                                    className="fas fa-hourglass-half typography--muted"></i>
                                <p className="typography--h1 typography--light typography--muted">
                                    Still Processing...
                                </p>
                                <p className="typography--h2 typography--light typography--muted">
                                    Check back in few seconds
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default EditorLoading;
