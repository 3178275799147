//@ts-check

import React from 'react';

const BinIcon = ({ height = 20, width = 20, color = 'currentColor', ...rest }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="20px/Interaction/Sort/bin" fill={color}>
                    <rect id="Rectangle" fillOpacity="0" x="0" y="0" width="20" height="20"></rect>
                    <g id="Line" transform="translate(2.000000, 1.000000)" fillRule="nonzero">
                        <path
                            d="M11.5,0 C11.7761424,0 12,0.223857625 12,0.5 L12,3 L15.5,3 C15.7761424,3 16,3.22385763 16,3.5 C16,3.77614237 15.7761424,4 15.5,4 L14.482,4 L14.034121,16.5535373 C14.0052968,17.3606143 13.3426683,18 12.5350767,18 L3.46492327,18 C2.65733173,18 1.99470316,17.3606143 1.96587898,16.5535373 L1.517,4 L0.5,4 C0.223857625,4 0,3.77614237 0,3.5 C0,3.22385763 0.223857625,3 0.5,3 L4,3 L4,0.5 C4,0.223857625 4.22385763,0 4.5,0 L11.5,0 Z M13.482,4 L2.517,4 L2.96524184,16.5178458 C2.97378234,16.7569797 3.14925074,16.951948 3.37720406,16.992322 L3.46492327,17 L12.5350767,17 C12.8042739,17 13.0251501,16.7868714 13.0347582,16.5178458 L13.482,4 Z M6.5,7 C6.77614237,7 7,7.22385763 7,7.5 L7,13.5 C7,13.7761424 6.77614237,14 6.5,14 C6.22385763,14 6,13.7761424 6,13.5 L6,7.5 C6,7.22385763 6.22385763,7 6.5,7 Z M9.5,7 C9.77614237,7 10,7.22385763 10,7.5 L10,13.5 C10,13.7761424 9.77614237,14 9.5,14 C9.22385763,14 9,13.7761424 9,13.5 L9,7.5 C9,7.22385763 9.22385763,7 9.5,7 Z M11,1 L5,1 L5,3 L11,3 L11,1 Z"
                            id="Combined-Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default BinIcon;
