import React, { useState } from 'react';
import cx from 'classnames';

const ColorPicker = ({selectedColor, onSelectedColorChanged}) => {

    return (
        <div className="color-palette">
            {/* <div className="color-palette__color-outer">
                                        <div
                                            className="color-palette__color-inner"
                                            style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                                        />
                                    </div> */}
            <div
                className={cx({
                    'color-palette__color-outer': true,
                    selected: '#ffffff' === selectedColor
                })}
                title={'No color'}
                onClick={() => 
                {                   
                    onSelectedColorChanged('#ffffff')}
                }>
                <div
                    className="color-palette__color-inner"
                    style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                />
            </div>
            <div style={{ margin: '2px 4px' }}>
                <span>{' | '}</span>
            </div>
            <div
                className={cx({
                    'color-palette__color-outer': true,
                    selected: '#ffff00' === selectedColor
                })}
                onClick={() => onSelectedColorChanged('#ffff00')}>
                <div
                    className="color-palette__color-inner"
                    style={{ backgroundColor: 'rgb(255, 255, 0)' }}
                />
            </div>
            <div
                className={cx({
                    'color-palette__color-outer': true,
                    selected: '#ff9b00' === selectedColor
                })}
                onClick={() => onSelectedColorChanged('#ff9b00')}>
                <div
                    className="color-palette__color-inner"
                    style={{ backgroundColor: 'rgb(255, 155, 0)' }}
                />
            </div>
            <div
                className={cx({
                    'color-palette__color-outer': true,
                    selected: '#ff0000' === selectedColor
                })}
                onClick={() => onSelectedColorChanged('#ff0000')}>
                <div
                    className="color-palette__color-inner"
                    style={{ backgroundColor: 'rgb(255, 0, 0)' }}
                />
            </div>
            <div
                className={cx({
                    'color-palette__color-outer': true,
                    selected: '#00ff00' === selectedColor
                })}
                onClick={() => onSelectedColorChanged('#00ff00')}>
                <div
                    className="color-palette__color-inner"
                    style={{ backgroundColor: 'rgb(0, 255, 0)' }}
                />
            </div>
            <div
                className={cx({
                    'color-palette__color-outer': true,
                    selected: '#00ffff' === selectedColor
                })}
                onClick={() => onSelectedColorChanged('#00ffff')}>
                <div
                    className="color-palette__color-inner"
                    style={{ backgroundColor: 'rgb(0, 255, 255)' }}
                />
            </div>
            <div
                className={cx({
                    'color-palette__color-outer': true,
                    selected: '#0000ff' === selectedColor
                })}
                onClick={() => onSelectedColorChanged('#0000ff')}>
                <div
                    className="color-palette__color-inner"
                    style={{ backgroundColor: 'rgb(0, 0, 255)' }}
                />
            </div>
            {/* <div className="color-palette__color-outer">
                                        <div
                                            className="color-palette__color-inner"
                                            style={{ backgroundColor: 'rgb(0, 0, 0)' }}
                                        />
                                    </div> */}
        </div>
    );
};

export default ColorPicker;