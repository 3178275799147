const breakpoints = {
  desktop: 768,
  bigScreen: 1824,
  tabletMobile: 767,
  portrait: 'portrait',
  retina: '2dppx',
};

export default breakpoints;

