import React, { useState, useEffect, useRef } from 'react';
import ExtensionModal from '../../../_shared/Modal/ExtensionModal';
import { s3Service } from '../../../_shared';
import BrowserSupportModal from '../../../_shared/Modal/BrowserSupportModal';
import { utilService } from '../../../../_helpers/util';
import { LiveHTMLSupportedBrowsers } from '../../proof.constants';
import { useScreenSizes } from '../../../responsive/useScreenSizes';

const messageTypes = {
    ScreenshotResponse: "mirandaHtmlLiveViewerSnapshotRes",
    ExtensionInstalled: "brandshare-collaboration-extension-detected",
    IframeUrlChanged: "mirandaHtmlLiveViewerUrlChanged",
    IFrameInfoResponse: "mirandaHtmlLiveViewerInfoResponse"
}


function isSupportedBrowser(browserName) {
    return LiveHTMLSupportedBrowsers.some(browser => browserName.toLowerCase() === browser.name.toLowerCase());
}

const WebsiteViewer = ({
    isVisible,
    onInitialLoaded,
    websiteURL,
    height,
    versionId,
    iFrameRef,
    viewportData, setViewportData,
    selectedComment,
    annotationComments,
    htmlLiveProofViewerInfo, setHtmlLiveProofViewerInfo,
    isInterActiveMode,
    makeProofReadonly,
    setScreenshotRequestProcessing
}) => {
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const frameID = versionId;
    const [isExtensionInstalled, setIsExtensionInstalled] = useState(null);
    const [showExtensionInstallModal, setShowExtensionInstallModal] = useState(false);
    const [showBrowserSupportModal, setShowBrowserSupportModal] = useState(false);
    const [browserName, setBrowserName] = useState('');
    const [isBrowserSupported, setIsBrowserSupported] = useState(true);
    const [backgroundImageOpacity, setBackgroundImageOpacity] = useState(0);
    const [iframeUrl, setIframeUrl] = useState(websiteURL);
    const { isMobile } = useScreenSizes();
    useEffect(() => {

        if (iFrameRef.current === undefined || iFrameRef.current === null) {
            return;
        }

        window.name = frameID;
        window.addEventListener("message", messageHandler);
        iFrameRef.current.contentWindow.addEventListener("message", messageHandlerFrame);

        let browserName = utilService.getBrowserName(navigator.userAgent)
        var isSupported = isSupportedBrowser(browserName);
        setBrowserName(browserName);
        setIsBrowserSupported(isSupported);
        setShowBrowserSupportModal(!isSupported);
        let isExtensionInstalled = detectExtension();
        setIsExtensionInstalled(isExtensionInstalled);
        setShowExtensionInstallModal(!isExtensionInstalled);

        return () => {
            window.removeEventListener("message", messageHandler);
            //iFrameRef.current.contentWindow.removeEventListener("message", messageHandlerFrame);
        };
    }, []);

    useEffect(() => {
        if (selectedComment === -1 || selectedComment === undefined) { return }

        //Change the iFrames current url and do other comment selection related tasks
        //Also need to validate if the comment has url    
        var currentComment = annotationComments[selectedComment];
        var commentUrl = currentComment.htmlLivePageInfo.url;

        if (commentUrl !== iframeUrl) {
            setIframeUrl(commentUrl);
        }

        //Check when do we need to set scroll positions 
        var pageData = JSON.parse(currentComment.htmlLivePageInfo.pageData);
        iFrameRef.current.contentWindow.postMessage({ type: "mirandaHtmlLiveViewerScrollPositionChange", scrollPosition: pageData.scrollPosition }, "*");

    }, [selectedComment])

    // isInterActiveMode
    useEffect(() => {

        if(!viewportData) {
            return;
        }


        if(isInterActiveMode === true && viewportData.image) {
            let updated = {...viewportData};
            updated.image = undefined;
            setViewportData(updated);
        }
            
    }, [isInterActiveMode])


    const detectExtension = () => {
        return 'brandshare-collaboration-extension-installed' === document.getElementById('brandshare-live-site-annotation-extension')?.content;
    };

    const messageHandler = async (message) => {

        if (message.data.type === messageTypes.ScreenshotResponse) {

            const top = (message.data.iframeBoundries && message.data.iframeBoundries.top) || 0,
                left = (message.data.iframeBoundries && message.data.iframeBoundries.left) || 0;

            var frameScreenshot = await createThumbnail(message.data.content, {
                top: top,
                left: left,
            });
            setScreenshotRequestProcessing(false);
            setViewportData(frameScreenshot);
            
            // var image = new Image();
            // image.src = message.data.content;
            // document.body.appendChild(image);
        }

        //Handle other cases if needed
        if (message.data.type === messageTypes.ExtensionInstalled) {
            setIsExtensionInstalled(true);
        }

        if (message.data.type === messageTypes.IframeUrlChanged && message.data.uid.startsWith(frameID)) {

            setHtmlLiveProofViewerInfo(currentInfo => {
                var currentInfoCpy = { ...currentInfo };
                currentInfoCpy.url = message.data.content;
                return currentInfoCpy;
            });
        }

        if (message.data.type === messageTypes.IFrameInfoResponse) {
            setHtmlLiveProofViewerInfo(currentInfo => {
                var currentInfoCpy = { ...currentInfo };
                currentInfoCpy.pageData = JSON.stringify(message.data.content);
                currentInfoCpy.url = message.data.url;
                return currentInfoCpy;
            });
        }
    };


    const messageHandlerFrame = async (message) => {

        if (message.data.type === messageTypes.IFrameInfoResponse && message.data.uid.startsWith(frameID)) {

        }
    };

    const createThumbnail = (n, t) => {
        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            const image = new Image();
            const iframeBounds = iFrameRef.current.getBoundingClientRect();
            const topOffset = Math.round(iframeBounds.top) + t.top;
            const leftOffset = Math.round(iframeBounds.left) + t.left;
            let tmpSize = { width: iframeBounds.width, height: iframeBounds.height };
            if (tmpSize) {
                image.onload = () => {
                    const devicePixelRatio = window.devicePixelRatio;
                    const width = Math.floor(tmpSize.width * devicePixelRatio);
                    const height = Math.floor(tmpSize.height * devicePixelRatio);

                    canvas.width = width;
                    canvas.height = height;

                    context.drawImage(
                        image,
                        Math.floor(leftOffset * devicePixelRatio),
                        Math.floor(topOffset * devicePixelRatio),
                        Math.floor(iframeBounds.width * devicePixelRatio),
                        Math.floor(iframeBounds.height * devicePixelRatio),
                        0,
                        0,
                        Math.floor(iframeBounds.width * devicePixelRatio),
                        Math.floor(iframeBounds.height * devicePixelRatio)
                    );

                    canvas.toBlob((blob) => {
                        resolve({
                            image: canvas.toDataURL("image/png"),
                            blob: blob
                        });
                    });
                };

                image.src = n
            }
            console.log("New snapshot created");
        });
    };

    const onLoaded = (e) => {
        if (isFirstLoad === true) {
            onInitialLoaded();
            setIsFirstLoad(false);
        }
    };

    const onInstalledClicked = () => {

        if (browserName.toLowerCase() === 'chrome' || browserName.toLowerCase() === 'edge') {
            let url = 'https://chromewebstore.google.com/detail/brandshare-collaboration/phohbijllkloddddkpiijbfmdlfpigmm';
            window.open(url, '_blank');
        }
        else {
            let url = 'https://addons.mozilla.org/addon/brandshare-collaboration';
            window.open(url, '_blank');
        }
        setShowExtensionInstallModal(false);
    }

    const onExtensionModalCloseClicked = () => {
        setShowExtensionInstallModal(false);
        makeProofReadonly();
    }

    const onBrowserModalCloseClicked = () => {
        setShowBrowserSupportModal(false);
        makeProofReadonly();
    }

    const customClass =
        isFirstLoad
            ? 'webviewer-loading'
            : 'webviewer-loaded';

    return (
        <div
            className={`image ${customClass} documentImageViewer`}
            style={{ height: '100%', width: '100%', position: 'absolute' }} >

            <iframe
                //ref={(node) =>  setPlayer(node) }
                id="my-frame-id"
                ref={iFrameRef}
                src={iframeUrl}
                frameBorder="0"
                sandbox="allow-same-origin allow-scripts allow-forms"
                onLoad={onLoaded}
                title={iframeUrl}
                style={{
                    width: `100%`,
                    height: height,
                    scrolling: 'no',
                    position: 'absolute'
                }}
            ></iframe>

            {
                selectedComment !== -1 &&
                <img className='viewport-image'
                    style={{ opacity: backgroundImageOpacity }}
                    src={annotationComments[selectedComment]?.viewportPath}
                    onLoad={() => {
                        setBackgroundImageOpacity(1);
                    }}
                >

                </img>
            }

            {!isInterActiveMode && viewportData.image && (
                <img className='viewport-image'
                    style={{ opacity: backgroundImageOpacity }}
                    src={viewportData.image}
                    onLoad={() => {
                        setBackgroundImageOpacity(1);
                    }}
                >
                </img>
            )};


            {showExtensionInstallModal && isMobile !== true && (
                <ExtensionModal
                    show={showExtensionInstallModal}
                    onPrimaryClick={onInstalledClicked}
                    onSecondaryClick={onExtensionModalCloseClicked}
                />
            )}
            {showBrowserSupportModal && isMobile !== true && (
                <BrowserSupportModal
                    show={showBrowserSupportModal}
                    onSecondaryClick={onBrowserModalCloseClicked}
                />
            )}
        </div>
    );
};
export default WebsiteViewer;
