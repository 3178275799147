import React, { useEffect, useState, useRef } from 'react';
import { store, config } from '../../../_helpers';
import { alertActions } from '../../App';
import cx from 'classnames';
import { bringToView } from '../DocumentAnnotation/DocumentAnnotationUtil';
import { ProofDocumentEditor } from '../DocumentAnnotation/ProofDocumentEditor';

export const ProofViewerDocument = ({ proofVersion, documentReady }) => {
    const [selectedPage, setSelectedPage] = useState(0);
    var documentViewer = null;
    var selectedCanvas = 0;
    const pagesContainer = useRef(null);

    useEffect(() => {
        //resetViewer();
        initDocumentViewer();
    }, []);

    const changeSelectedCanvas = (index, element, donotScroll = false) => {
        if (index === selectedPage) {
            return;
        }
        setSelectedPage(index);

        var target = pagesContainer.current.children[index];

        if (!donotScroll) {
            bringToView(target, 20);
        }
    };

    const initDocumentViewer = () => {
        if (!proofVersion || !documentReady) {
            return;
        }
        
        document.title = `${proofVersion.name} - Brandshare Collaboration`;
    };

    return (
        <>                
            {proofVersion.pages && (
                <ProofDocumentEditor
                    key = {proofVersion.id}
                    viewerVersion={proofVersion}
                    hideComments={true}
                    showThumbnails={true}
                    isAutoCompare={false}
                    isOverlayCompare={false}
                    side={'Left'}                    
                    setEditorRef={()=> {}}
                    hideAnnotations = {true}
                    disableLoader = {false}
                />
            )}
        </>
    );
};

export default ProofViewerDocument;
