import React from 'react';
import { connect } from 'react-redux';
import { utilService } from '../../_helpers';
import { roleActions, Grid } from '../../components';
import { RoleModal } from './RoleModal';
import { DataFetcher } from '../_shared/DataFetcher/DataFetcher';
const columns = [
    // { key: 'documentId', name: 'Id' ,  formatter: viewButtonFormatter},
    // {
    //     key: 'firstName',
    //     name: 'FirstName',
    //     // filterRenderer: AutoCompleteFilter,
    // },
    { key: 'name', name: 'Name' },
    { key: 'createdBy', name: 'Created By' },
    { key: 'createdAt', name: 'Created on' },
];
const defaultColumnProperties = {
    sortable: true,
    filterable: true,
    resizable: true,
};
class RoleManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            showroleModal: false,
            columns: columns.map(c => ({
                ...c,
                ...defaultColumnProperties,
            })),
            selectedRow: null,
            gridView: 'folderView',
            pageSize: 20,
            pageIndex: 0,
            totalPages: 1,
            totalCount: 0,
            sortColumn: null,
            sortDirection: 'NONE',
            filtersParam: [],
            proofsFilters: {},
            selectedrole: {},
            operationMode: 0,
            dataLoaded: false            
        };
    }
    async fetchData() {
        await this.loadFilterDataFromServer();
        await this.loadDataFromServer();
        this.setState({
            dataLoaded: true
        })
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.roles) {
            var rowsTemp = [...nextProps.roles];
            const rows = rowsTemp.map(item => {
                var newItem = { ...item };
                item.isEnabled === true
                    ? (newItem.isEnabled = 'Enabled')
                    : (newItem.isEnabled = 'Disabled');
                item.createdAt & (newItem.createdAt = new Date(item.createdAt).toLocaleString());
                return newItem;
            });
            return {
                roles: [...rows],
                // pageSize: nextProps.pageSize,
                // pageIndex: nextProps.pageIndex,
                totalPages: nextProps.totalPages,
                totalCount: nextProps.totalCount,
                rolesFilters: nextProps.rolesFilters
                    ? nextProps.rolesFilters
                    : prevState.rolesFilters,
            };
        } else {
            return null;
        }
    }
    loadDataFromServer() {
        const sortMapper = {
            ['ASC']: 1,
            ['DESC']: -1,
            ['NONE']: 1,
        };
        const data = {
            page: this.state.pageIndex,
            pageSize: this.state.pageSize,
            proofSortModel: {
                sortDirection: sortMapper[this.state.sortDirection],
                sortColumn: this.state.sortColumn,
            },
            proofFilters: this.state.filtersParam,
        };
        this.props.dispatch(roleActions.getAll(data));
    }
    async loadFilterDataFromServer() {
        await this.props.dispatch(roleActions.getAllRoleFilters());
    }
    handleGridSort(sCol, sDir) {
        this.setState({ pageIndex: 0, sortColumn: sCol, sortDirection: sDir }, () => {
            this.loadDataFromServer();
        });
    }
    handlePageIndexChange(pageIndex) {
        this.setState({ pageIndex: pageIndex }, () => {
            this.loadDataFromServer();
        });
    }
    handlePageSizeChange(psize) {
        this.setState({ pageSize: psize, pageIndex: 0 }, () => {
            this.loadDataFromServer();
        });
    }
    handleFilterChange(filtersParam) {
        console.log('FiltersParam', filtersParam);
        var filtersPayload = [];
        for (var key in filtersParam) {
            if (filtersParam.hasOwnProperty(key)) {
                if (Array.isArray(filtersParam[key].filterTerm)) {
                    filtersParam[key].filterTerm.forEach(function(item) {
                        const filterItem = {
                            filterColumn: filtersParam[key].column.key,
                            filterValue: item.value,
                        };
                        filtersPayload.push(filterItem);
                    });
                } else {
                    const filterItem = {
                        filterColumn: filtersParam[key].column.key,
                        filterValue: filtersParam[key].filterTerm,
                    };
                    filtersPayload.push(filterItem);
                }
            }
        }
        this.setState({ pageIndex: 0, filtersParam: filtersPayload }, () => {
            this.loadDataFromServer();
        });
    }
    async onRowSelected(row) {
        await utilService.setStateAsync.bind(this)({ selectedrole: row, operationMode: 2 });
        await utilService.setStateAsync.bind(this)({ showroleModal: true });
    }
    async onNewroleClick() {
        await utilService.setStateAsync.bind(this)({ selectedrole: {}, operationMode: 1 });
        await utilService.setStateAsync.bind(this)({ showroleModal: true });
    }
    handleSearchClick(event) {
        event.preventDefault();
        var { value } = event.target;
        var paramObj = {
            page: 0,
            pageSize: 20,
            proofFilters: value ? [{ filterColumn: 'name', filterValue: value }] : [],
            proofSortModel: { sortDirection: 1, sortColumn: null },
        };

        this.props.dispatch(roleActions.getAll(paramObj));
    }
    render() {
        const modalStyle = {
            position: 'absolute',
            top: 60,
            bottom: 0,
            left: 0,
            right: 0,
            overflow: 'auto',
            zindex: 1040,
        };

        return (
            <>
                <div className="settings-view__manage__container">
                    <div className="m-2">
                        <div className="settings-view-modal">
                            <button
                                id="editor-close-btn"
                                type="button"
                                className="close modal-close"
                                title="Close Modal"
                                onClick={this.props.onClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h1>Role</h1>
                            <div className="action-panel">
                                <div className="_s_LeftPanel pull-left">
                                    <div className="actionpanel-element">
                                        <div>
                                            <button
                                                type="button"
                                                id=""
                                                className="btn mrnda-btn new-item-btn"
                                                onClick={this.onNewroleClick.bind(this)}
                                                style={{ opacity : this.state.dataLoaded === true?  '1' : 0}}>
                                                <i className="fa fa-plus-circle"></i>
                                                <span className="btn-text _s_NewroleBtn ml-2" >
                                                    New Role
                                                </span>
                                            </button>
                                            {/* <div
                                                className="input-search"
                                                style={{ float: 'right', marginRight: '10px' }}>
                                                <input
                                                    type="text"
                                                    className="input-search__input"
                                                    placeholder="Search..."
                                                    onChange={this.handleSearchClick.bind(this)}
                                                />
                                                <span className="input-search__btn">
                                                    <i className="fas fa-search" />
                                                </span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-manage-body">
                                <DataFetcher onFetchData={this.fetchData.bind(this)} height={'476px'}>
                                    <Grid
                                        columns={this.state.columns}
                                        rows={this.state.roles}
                                        minHeight={420}
                                        enableFilter={false}
                                        proofsFilters={this.state.rolesFilters}
                                        handleGridSort={this.handleGridSort.bind(this)}
                                        totalPages={this.state.totalPages}
                                        handlePageIndexChange={this.handlePageIndexChange.bind(this)}
                                        handlePageSizeChange={this.handlePageSizeChange.bind(this)}
                                        totalCount={this.state.totalCount}
                                        handleFilterChange={this.handleFilterChange.bind(this)}
                                        onRowSelected={row => {
                                            this.onRowSelected(row);
                                        }}
                                    />
                                </DataFetcher>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showroleModal && (
                    <RoleModal
                        onClose={() => this.setState({ showroleModal: false })}
                        show={this.state.showroleModal}
                        roleObject={this.state.selectedrole}
                        operationMode={this.state.operationMode}
                    />
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    const { rolesData, rolesFilters } = state;
    const { rows, pageIndex, pageSize, totalCount, totalPages } = rolesData;
    return {
        roles: rows,
        pageIndex,
        pageSize,
        totalCount,
        totalPages,
        rolesFilters: rolesFilters.rolesFilters,
    };
}

const connectedSettingsPage = connect(mapStateToProps)(RoleManage);
export { connectedSettingsPage as RoleManage };
