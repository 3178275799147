import React from 'react';
import { MetadataResponses, MetadataAttachments, MetadataAnnotationCount, MetadataReplyCount, CommentAnnotationCount, CommentReplyCount, CommentAttachmentCount } from './MetadataComponents';

export function CommentStats(props) {
    return (
        <div
            className="comment__metadata"
            style={props.isMobile
                ? {
                    margin: 0,
                    padding: '12px 20px 0px 20px',
                    ...props.secondaryTextStyle
                }
                : {}}>
            <div className="comment__metadata__replies">
                <CommentAnnotationCount annotations={props.commentItem.annotations} />
            </div>
            <div style={{ marginLeft : '12px' }} className="comment__metadata__replies">                            
                <CommentAttachmentCount attachments={props.commentItem.attachments} />
            </div>
            <div
                className="comment__metadata__replies"
                style={{
                    flex: '1',
                    textAlign: 'right'
                }}>            
                
                <CommentReplyCount replies={props.commentItem.replies} />
            </div>
        </div>
    );
}
