import React from 'react';
import DesktopBreakpoint from '../../responsive/DesktopBreakpoint';
import { DocumentEditorThumbnails } from './Subs/DocumentEditorThumbnails';
import ScrollableEditor from './ScrollableEditor';
import PhoneBreakpoint from '../../responsive/PhoneBreakpoint';
import PagedEditor from './PagedEditor';
import EditorPageTools from './EditorPageTools';
import { EditorType } from '../proof.constants';

const DocumentEditorBody = ({
    shouldShowThumbnail,
    editorBodyStyle,
    pages,
    isProofCompare,
    proofVersion,
    editorArea,
    offsetHorizontalScrollbar,
    onEditorScrolled,
    pageCount,
    pagesContainer,
    loadablePages,
    hideAnnotations,
    isOverlayCompare,
    compareCanvas,
    isAutoCompare,
    side,
    isMobile,
    setCompareDocumentViewer,
    selectedCanvas,
    editorType,
    screenshotRequestProcessingRef
}) => {
    
    const  bodyStyle = editorType === EditorType.Paged ? { ...editorBodyStyle, flexDirection: 'column' } : editorBodyStyle
    
    return (
        <div style={bodyStyle} className={'mrnda-document-editor-area-body'}>
            {shouldShowThumbnail && (
                <DesktopBreakpoint>
                    <DocumentEditorThumbnails pages={pages} />
                </DesktopBreakpoint>
            )}        
            <DesktopBreakpoint>
                <ScrollableEditor
                    {...{
                        shouldShowThumbnail,
                        editorBodyStyle,
                        pages,
                        isProofCompare,
                        proofVersion,
                        editorArea,
                        offsetHorizontalScrollbar,
                        onEditorScrolled,
                        pageCount,
                        pagesContainer,
                        loadablePages,
                        hideAnnotations,
                        isOverlayCompare,
                        compareCanvas,
                        isAutoCompare,
                        side,
                        isMobile,
                        setCompareDocumentViewer,
                        screenshotRequestProcessingRef
                    }}
                />
            </DesktopBreakpoint>
            <PhoneBreakpoint>                
                <PagedEditor
                    {...{
                        selectedCanvas,
                        shouldShowThumbnail,
                        editorBodyStyle,
                        pages,
                        isProofCompare,
                        proofVersion,
                        editorArea,
                        offsetHorizontalScrollbar,
                        onEditorScrolled,
                        pageCount,
                        pagesContainer,
                        loadablePages,
                        hideAnnotations,
                        isOverlayCompare,
                        compareCanvas,
                        isAutoCompare,
                        side,
                        isMobile,
                        setCompareDocumentViewer
                    }}
                />                
            </PhoneBreakpoint>
        </div>
    );
};

export default DocumentEditorBody;
