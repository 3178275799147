//@ts-check
import React from 'react';
import { CommentBox } from './Comments/CommentBox';
import { trimAnnotationObject, uuidv4 } from '../DocumentAnnotationUtil';
import { withDocumentAnnotationConsumer } from '../Context/DocumentAnnotationConsumer';
import { documentCommentService } from '../documentComment.service';
import { CommentItem } from './Comments/CommentItem';
import { documentAnnotationConstants } from '../Context/documentAnnotation.constants';
import { history, contributorsHelper } from '../../../../_helpers';
import { s3Service } from '../../../_shared';
import { drawingConstants } from '@wunderman-thompson/miranda-drawing';

import cx from 'classnames';
import { connect } from 'react-redux';
import { ProofCompareGlobalModal } from '../../ProofCompare/ProofCompareGlobalModal';
import { utilService } from '../../../../_helpers';
import { CommentSorter } from './Comments/CommentSorter';
import { CommentListWrapper } from './Comments/CommentListWrapper';
import { array } from 'js-md5';
import { handleOnCancelMergeAnnotation } from '../../annotationBase';
import { CommentProcessing } from './Comments/CommentProcessing';
import { PROOF_TYPE, TEXT_EXTRACT_STATUS } from '../../proof.constants';
import { AnnotationShareModal } from '../../AnnotationShareModal';
import { userService } from '../../../User';
import ProofVersionStatus from '../../ProofViewer/ProofVersionStatus';
import { conditionalClassName } from '../../../responsive/responsiveUtils';
import DesktopBreakpoint from '../../../responsive/DesktopBreakpoint';
import ProofEditorHeader from '../../VideoAnnotation/Subs/ProofEditorHeader';

class DocumentAnnotationComment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showProofCompareModal: false,
            selectedComment: 0,
            showShareModal: false,
            historyLength: undefined
        };

    }

    componentDidMount() {
        const length = window.history.length;
        this.setState({ historyLength: length - 1 });
    }

    isAnnotationCurrent(annotation) {
        return this.props.currentFrameNumber === annotation.frameRange[0]
            ? '#715f50'
            : 'transparent';
    }

    async handleOnPostComment(comment, attachments = null, mentions = null) {
        this.props.donotHanldeLiveUpdate.current = true;
        const {
            documentMetadata,
            selectedAnnotation: currentAnnotations,
        } = this.props;

        const { proofId, proofVersionId, currentTenant } = documentMetadata;

        var commentAddPayload = {
            proofId: proofId,
            proofVersionId: proofVersionId,
            content: comment,
            status: documentAnnotationConstants.COMMENT_STATUS.None,
            page: this.props.selectedCanvas + 1,
            attachments: attachments,
            mentions: mentions,
            mentionURL: documentMetadata?.mentionURL
        };


        commentAddPayload.annotation = [];


        currentAnnotations.forEach(currentAnnotation => {


            trimAnnotationObject(currentAnnotation.annotationObject);

            if (currentAnnotation.annotationObject.type === drawingConstants.ANNOTATION_TYPE.commentStamp.typeName) {
                currentAnnotation.annotationObject.tintColor = contributorsHelper.convertColorToKey(currentAnnotation.annotationObject.tintColor);
            }
            else {
                currentAnnotation.annotationObject.stroke = contributorsHelper.convertColorToKey(currentAnnotation.annotationObject.stroke);
            }

            commentAddPayload.annotation.push({
                annotationType: currentAnnotation.annotationType,
                annotationObject: JSON.stringify(currentAnnotation.annotationObject),
                page: currentAnnotation.page,
            });

        });
        // TODO: Before saving the comment, we have to ensure 
        // The frame has been uploaded to s3
        if(this.props.proofVersion.mediaType ===  PROOF_TYPE.HTMLLive || this.props.proofVersion.mediaType ===  PROOF_TYPE.HTMLDynamicLive) {
            let viewPortPath = await this.uploadViewportToS3(this.props.proofVersion.framePath, documentMetadata.s3Config);
            commentAddPayload.viewportPath = viewPortPath;
            commentAddPayload.HTMLLivePageInfo = this.props.htmlLiveProofViewerInfo;
        }

        let commentResponse = await documentCommentService.addComment(commentAddPayload);
        this.props.setEditAllowedComments([...this.props.editAllowedComments, commentResponse.id]);


        this.processAnnotationsAfterSave(commentResponse, currentAnnotations);

        this.cleanStateAfterSave();


        await this.props.refreshComments();
        this.props.donotHanldeLiveUpdate.current = false;
    }


    async uploadViewportToS3(framePath, s3Config) {
        let commentID = uuidv4();
        let uploadPathFrame = `${framePath}/${commentID}_viewport.png`;
        console.log('view port upload path :', uploadPathFrame);
        await s3Service.uploadFrame(this.props.viewportData.blob, s3Config, uploadPathFrame);
        return uploadPathFrame;
    }

    processAnnotationsAfterSave(commentResponse, currentAnnotations) {
        var totalAdded = commentResponse.annotations.length - currentAnnotations.length;

        currentAnnotations.forEach((element, index) => {
            var targetPage = element.page;
            var responseIndex = totalAdded + index;
            const shapeInfo = { ...element.annotationObject };
            var temporaryShapeId = element.uuid;
            this.props.mainDrawingCanvas[targetPage - 1].removeObjectById(temporaryShapeId);

            //this.props.proofVersion.mediaType !== PROOF_TYPE.HTMLLive
            if (!utilService.isWebsiteProof(this.props.proofVersion)) {
                var drawableAnnotation = { parent: commentResponse.id, createdById: commentResponse.createdById, ...commentResponse.annotations[responseIndex] };
                this.props.drawSingleAnnotation(targetPage, drawableAnnotation, false, false);
            }
        });
    }


    async handleOnAttachAnnotationWithComment(commentId) {

        this.props.donotHanldeLiveUpdate.current = true;
        const {
            documentMetadata,
            selectedAnnotation: currentAnnotations,
        } = this.props;


        var addedAnnotations = [];

        currentAnnotations.forEach(currentAnnotation => {

            var { commentAddPayload } = this.prepareAnnotations(documentMetadata, currentAnnotation, commentId);

            addedAnnotations.push(commentAddPayload);

        });


        let commentResponse = await documentCommentService.addAnnotation(addedAnnotations);

        this.processAnnotationsAfterSave(commentResponse, currentAnnotations);

        this.cleanStateAfterSave();

        await this.props.refreshComments();
        this.props.setSelectedComment(-1);
        this.props.donotHanldeLiveUpdate.current = false;
    }

    cleanStateAfterSave() {
        this.props.setIsCommentAddMode(false);
        this.props.setIsComposite(false);
        this.props.setIsCommentMerge(false);
        this.props.setSelectedAnnotation([]);
        this.props.setCommentText('');
    }

    prepareAnnotations(documentMetadata, currentAnnotation, commentId) {
        const { proofId, proofVersionId, currentTenant } = documentMetadata;

        const shapeInfo = { ...currentAnnotation.annotationObject };
        trimAnnotationObject(currentAnnotation.annotationObject);


        if (currentAnnotation.annotationObject.type === drawingConstants.ANNOTATION_TYPE.commentStamp.typeName) {
            currentAnnotation.annotationObject.tintColor = contributorsHelper.convertColorToKey(currentAnnotation.annotationObject.tintColor);
        }
        else {
            currentAnnotation.annotationObject.stroke = contributorsHelper.convertColorToKey(currentAnnotation.annotationObject.stroke);
        }

        var commentAddPayload = {
            documentCommentId: commentId,
            proofId: proofId,
            proofVersionId: proofVersionId,
            annotationType: currentAnnotation.annotationType,
            annotationObject: JSON.stringify(currentAnnotation.annotationObject),
            page: currentAnnotation.page
        };
        return { commentAddPayload };
    }

    annotatedPageUploadPath(page) {
        return `${this.props.proofVersion.framePath}/${page}_annotated_page.png`;
    }

    handleOnCancelComment() {
        this.props.selectedAnnotation.forEach(element => {
            this.props.removeCanvasObjectById(element.uuid, element.page);
        });
        this.props.setSelectedAnnotation([]);
        this.props.setIsCommentAddMode(false);
        this.props.setIsComposite(false);
        this.props.setCommentText('');
    }

    handleOnCancelMergeAnnotation(commentItem) {
        handleOnCancelMergeAnnotation(this.props);
    }

    handleOnCommentClicked(commentItem) {

        if (this.props.initDrawing === true) {
            return;
        }

        if (this.props.selectedComment === commentItem.index) {
            return;
        }

        this.props.setSelectedComment(commentItem.index);
        this.props.peekToPage(commentItem.page - 1, commentItem.index, true);
    }

    async handleOnCommentDelete(comment) {

        this.props.deleteCommentArrowPointFinder(comment.id);
        this.props.setDisableArrow(true);
        this.props.setSelectedComment(-1);

        this.props.donotHanldeLiveUpdate.current = true;
        await documentCommentService.deleteComment(comment.id);

        comment.annotations.forEach(element => {
            var page = element.page === -1 ? comment.page : element.page;
            this.props.removeCanvasObjectById(element.id, page);
        });

        await this.props.refreshComments();
        this.props.donotHanldeLiveUpdate.current = false;

        contributorsHelper.configureContributors(this.props.proofVersion, this.props.annotationComments, this.props.contributors, this.props.userId, false, this.props);

    }

    async handleOnCommentSetStatus(statusValue, commentId) {
        var payload = {
            id: commentId,
            status: statusValue
        };
        await documentCommentService.setCommentStatus(payload);
        await this.props.refreshComments();
        this.props.setSelectedComment(-1);
    }

    async onAttachmentDelete(attachment) {
        var request = {
            id: attachment.id,
            commentId: attachment.commentId,
            replyId: attachment.replyId
        };
        await documentCommentService.deleteAttachment(request);
        await this.props.refreshComments();
        this.props.setSelectedComment(-1);
    };

    async getAttachment(request) {
        var attachment = await documentCommentService.getAttachment(request);
        return attachment;
    }

    async handleOnCommentReply(replyItem, attachments = null, mentions = null) {
        var replyAddPayload = {
            documentCommentId: replyItem.commentId,
            content: replyItem.reply,
            attachments: attachments,
            mentions: mentions,
            mentionURL: replyItem.mentionURL,
            color: contributorsHelper.convertColorToKey(this.props.userAnnotationColor)
        };
        var res = await documentCommentService.addCommentReply(replyAddPayload);
        this.props.setEditAllowedReplies([...this.props.editAllowedReplies, res.id]);
        this.props.setSelectedComment(-1);
    }

    async updateCommentContent(replyAddPayload) {
        var response = await documentCommentService.updateCommentContent(replyAddPayload);
        return response;
    }

    async updateReplyContent(replyAddPayload) {
        var response = await documentCommentService.updateReplyContent(replyAddPayload);
        return response;
    }

    onScrolled(e) {
        if (this.props.selectedComment === -1) { return };

        let bounds = e.target.getBoundingClientRect();
        this.props.calculateCommentArrowPoint(this.props.selectedComment, true, bounds);
    }

    skippedBack() {
        if (this.state.historyLength) {
            const length = window.history.length;
            var diff = this.state.historyLength - length;
            history.go(diff);
        } else {
            history.goBack();
        }
    };

    onCloseClick() {
        if(!utilService.isWebsiteProof(this.props.proofVersion)) {
            history.goBack();
        }
        else {
            this.skippedBack();
        }
    }

    shareEnabled() {

        let isEnabled = this.props.proofSettings.annotationShareEnabled === true
            && !this.props.alert.isNavActionsDisabled
            && this.props.alert.isHideTopbar !== true;
        return isEnabled;
    }

    render() {
        const {
            proofVersion,
            setProofVersion,
            isCommentAddMode,
            rangeSelectorValue,
            annotationComments,
            alert,
            hasNewComment,
            newCommentCount,
            commentsDeleted,
            deletedCommentCount,
            isProofCompare,
            side,
            documentMetadata,
            extractedText
        } = this.props;
        return (
            <div style={this.props.compactNavbar === true && !isProofCompare ? {marginTop : '61px',  height: 'calc(100% - 61px)'} : {}}  className={conditionalClassName('mrnda-video-editor-sidebar', this.props.isMobile, 'mobile')}>
                {
                    !isProofCompare &&
                    (
                        <div className="mrnda-video-editor-sidebar__header">

                            <DesktopBreakpoint>
                                <>
                                    <ProofEditorHeader
                                        proofVersion={proofVersion}
                                        isNavActionsDisabled={this.props.alert.isNavActionsDisabled}
                                        shareEnabled={this.shareEnabled()}
                                        onShareClicked={() =>
                                            this.setState({
                                                showShareModal: true
                                            })
                                        }
                                        handleCompareClicked={() =>
                                            this.setState({
                                                showProofCompareModal: true
                                            })}
                                        onCloseClick={this.onCloseClick.bind(this)}
                                        hideCloseButton={alert.isHideTopbar}
                                    />
                                    {
                                        proofVersion.proofId !== '' && proofVersion.proofId !== undefined &&
                                        <div style={{ marginTop: '12px', marginBottom: "4px" }}>
                                            <ProofVersionStatus proofVersion={proofVersion} updateProofVersion={(update) => {
                                                setProofVersion(update);
                                            }} />
                                        </div>
                                    }
                                </>
                            </DesktopBreakpoint>


                            {isCommentAddMode && extractedText.status != TEXT_EXTRACT_STATUS.IN_PROGRESS && (
                                <CommentBoxHOC
                                    page={this.props.selectedCanvas + 1}
                                    handleOnPostComment={this.handleOnPostComment.bind(this)}
                                    handleOnCancelComment={this.handleOnCancelComment.bind(this)}
                                    handleOnAttachAnnotationWithComment={this.handleOnAttachAnnotationWithComment.bind(this)}

                                />
                            )}
                            {isCommentAddMode && extractedText.status == TEXT_EXTRACT_STATUS.IN_PROGRESS && (
                                <CommentProcessingHOC
                                    page={this.props.selectedCanvas + 1}
                                    handleOnPostComment={this.handleOnPostComment.bind(this)}
                                    handleOnCancelComment={this.handleOnCancelComment.bind(this)}
                                    handleOnAttachAnnotationWithComment={this.handleOnAttachAnnotationWithComment.bind(this)}

                                />
                            )}
                        </div>
                    )
                }

                <div
                    style={{
                        opacity: isCommentAddMode ? 0.3 : 1,
                        pointerEvents: isCommentAddMode ? 'none' : 'all',
                        overflow: 'hidden auto',
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1
                    }}>
                    <DesktopBreakpoint>
                        <div className="mrnda-tabs mrnda-video-editor-sidebar__tabs">
                            <div
                                className={cx({
                                    'mrnda-tabs-themed__item': true,
                                    'mrnda-tabs-themed__item--active': true,
                                    'mrnda-video-editor-sidebar__tabs__item': true
                                })}>
                                Comments
                                <CommentSorterHOC />
                            </div>
                        </div>
                    </DesktopBreakpoint>
                    <div
                        className="mrnda-video-editor-sidebar__comments mrnda-tabs-content  mt-4"
                        style={this.props.isMobile ? {
                            display: 'block', padding: '02px 20px 0px 20px'
                        } : { display: 'block' }}
                        onScroll={this.onScrolled.bind(this)}>
                        {hasNewComment && (
                            <div
                                className="mrnda-editor-sidebar-new-comment-banner"
                                onClick={this.props.newCommentBannerClicked.bind(this)}>
                                <p className="mrnda-editor-sidebar-new-comment-banner__text">
                                    {newCommentCount} new comment
                                    {newCommentCount > 1 ? 's' : ''}{' '}
                                    available
                                </p>
                            </div>
                        )}

                        {commentsDeleted && (
                            <div
                                className="mrnda-editor-sidebar-new-comment-banner"
                                onClick={this.props.deletedCommentsBannerClicked.bind(this)}>
                                <p className="mrnda-editor-sidebar-new-comment-banner__text">
                                    {deletedCommentCount} comment
                                    {deletedCommentCount > 1 ? 's' : ''}{' '}
                                    deleted
                                </p>
                            </div>
                        )}

                        {/* Comments are wrapped with Comment list component to provide a 
                        common area for click target for comments to determine if click event
                        is occurred outside this area to clear comment selection arrow */}
                        <CommentList>
                            {annotationComments.map((comment, index) => {
                                var commentItem = { ...comment, index };

                                var avatarColor, annotationColor;


                                if (this.props.contributors !== null) {
                                    avatarColor = utilService.getUserAvatarPreferenceColor(this.props.contributors, commentItem.createdById)
                                    annotationColor = utilService.getUserAnnotationPreferenceColor(this.props.contributors, commentItem.createdById)

                                }

                                return (
                                    <CommentItemHOC
                                        key={commentItem.id}
                                        metadata={documentMetadata}
                                        commentItem={commentItem}
                                        onCommentReply={this.handleOnCommentReply.bind(this)}
                                        updateCommentContent={this.updateCommentContent.bind(this)}
                                        updateReplyContent={this.updateReplyContent.bind(this)}
                                        onCommentDelete={this.handleOnCommentDelete.bind(this)}
                                        onCommentSetStatus={this.handleOnCommentSetStatus.bind(this)}
                                        onCommentClicked={this.handleOnCommentClicked.bind(this)}
                                        onAnnotationMerge={this.handleOnAttachAnnotationWithComment.bind(this)}
                                        cancelMergeAnnotation={this.handleOnCancelMergeAnnotation.bind(this)}
                                        avatarColor={avatarColor}
                                        annotationColor={annotationColor}
                                        onAttachmentDelete={this.onAttachmentDelete.bind(this)}
                                        getAttachment={this.getAttachment.bind(this)}
                                        setCommentArrowPointFinder={this.props.setCommentArrowPointFinder}
                                        isSelected={
                                            this.props.selectedComment === index
                                        }
                                        isVisible={this.props.isMobile === true ? commentItem.page === this.props.selectedCanvas + 1 : true}
                                        side={this.props.side}
                                        isProofCompare={isProofCompare}

                                    />
                                );
                            })}
                        </CommentList>


                    </div>
                </div>
                {this.state.showProofCompareModal && (
                    <ProofCompareGlobalModal
                        show={this.state.showProofCompareModal}
                        proofId={proofVersion.proofId}
                        currentDirectoryNodeId={proofVersion.directoryNodeId}
                        proofObject={{
                            currentVersionId: proofVersion.id,
                            currentVersionProofId: proofVersion.proofId
                        }}
                        onHide={() =>
                            this.setState({
                                showProofCompareModal: false
                            })
                        }
                    />
                )}

                {this.props.proofSettings.annotationShareEnabled && this.state.showShareModal && (
                    <AnnotationShareModal
                        show={this.state.showShareModal}
                        onHide={() => this.setState({ showShareModal: false })}
                        proofId={this.props.proofVersion.proofId}
                        proofVersion={this.props.proofVersion}
                        proofSettings={this.props.proofSettings}
                        onClose={() => { }}
                    />
                )}

            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}
const hoc = connect(mapStateToProps)(withDocumentAnnotationConsumer(DocumentAnnotationComment));
const CommentItemHOC = withDocumentAnnotationConsumer(CommentItem);
const CommentBoxHOC = withDocumentAnnotationConsumer(CommentBox);
const CommentProcessingHOC = withDocumentAnnotationConsumer(CommentProcessing);
const CommentSorterHOC = withDocumentAnnotationConsumer(CommentSorter);
const CommentList = withDocumentAnnotationConsumer(CommentListWrapper);
export { hoc as DocumentAnnotationComment };
