import { tenantService, tenantConstants, alertActions, alertConstants } from '../../components';
import { history } from '../../_helpers';

export const tenantActions = {
    login,
    logout,
    register,
    getAll,
    delete: _delete
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));
        dispatch(loading(true));
        tenantService.login(username, password).then(
            tenant => {
                dispatch(success(tenant));
                dispatch(loading(false));
                history.push('/dashboard');
            },
            error => {
                dispatch(failure(error.toString()));
                //dispatch(alertActions.error(error.toString()));
                dispatch(loading(false));
            }
        );
    };

    function request(tenant) {
        return { type: tenantConstants.LOGIN_REQUEST, tenant };
    }
    function success(tenant) {
        return { type: tenantConstants.LOGIN_SUCCESS, tenant };
    }
    function failure(error) {
        return { type: tenantConstants.LOGIN_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function logout() {
    tenantService.logout();
    return { type: tenantConstants.LOGOUT };
}

function register(tenant) {
    return dispatch => {
        dispatch(request(tenant));
        dispatch(loading(true));
        tenantService.register(tenant).then(
            tenant => {
                dispatch(success());
                dispatch(loading(false));
                history.push('/dashboard');
                dispatch(alertActions.success('Added !'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(loading(false));
                //dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(tenant) {
        return { type: tenantConstants.REGISTER_REQUEST, tenant };
    }
    function success(tenant) {
        return { type: tenantConstants.REGISTER_SUCCESS, tenant };
    }
    function failure(error) {
        return { type: tenantConstants.REGISTER_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function getAll() {
    return dispatch => {
        dispatch(request());
        dispatch(loading(true));
        tenantService.getAll().then(
            tenants => {
                dispatch(success(tenants));
                dispatch(loading(false));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(loading(false));
            }
        );
    };

    function request() {
        return { type: tenantConstants.GETALL_REQUEST };
    }
    function success(tenants) {
        return { type: tenantConstants.GETALL_SUCCESS, tenants };
    }
    function failure(error) {
        return { type: tenantConstants.GETALL_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        tenantService
            .delete(id)
            .then(
                tenant => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) {
        return { type: tenantConstants.DELETE_REQUEST, id };
    }
    function success(id) {
        return { type: tenantConstants.DELETE_SUCCESS, id };
    }
    function failure(id, error) {
        return { type: tenantConstants.DELETE_FAILURE, id, error };
    }
}
