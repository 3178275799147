import React from 'react';
import { PROOF_TYPE } from '../proof.constants';
import { SelectedShapeIndicator } from './SelectedShapeIndicator';
import { DocumentPlayerWithCanvas } from './Subs/DocumentPlayerWithCanvas';
import cx from 'classnames';
import { EditorPageTools } from './EditorPageTools';
import { HtmlLiveNotSupported } from './HtmlLiveNotSupported';
import { utilService } from '../../../_helpers';

const PagedEditor = ({
    pages,
    isProofCompare,
    proofVersion,
    editorArea,
    offsetHorizontalScrollbar,
    onEditorScrolled,
    pageCount,
    pagesContainer,
    hideAnnotations,
    isOverlayCompare,
    compareCanvas,
    isAutoCompare,
    side,
    isMobile,
    setCompareDocumentViewer,
    selectedCanvas
}) => {
    return (
        <>
            <div
                className={cx({
                    'mrnda-document-editor-area-body-page-wrapper': true,
                    'mrnda-hide-scrollbar': isProofCompare
                })}
                style={{
                    zIndex: isProofCompare ? 'auto' : 1,
                    padding: !utilService.isWebsiteProof(proofVersion) ? '20px 0' : '0px',
                    padding: '0px'
                }}
                ref={editorArea}
                onMouseEnter={() => {
                    offsetHorizontalScrollbar();
                }}
                onScroll={onEditorScrolled}>
                <SelectedShapeIndicator />
                {pageCount > 0 && (
                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                        <div ref={pagesContainer} className={'mrnda-document-editor-area-pages'}>
                            <DocumentPlayerWithCanvas
                                key={selectedCanvas}
                                shouldLoad={true}
                                hideAnnotations={hideAnnotations}
                                {...{
                                    ...pages[selectedCanvas],
                                    index : selectedCanvas,
                                    isProofCompare: isProofCompare,
                                    isOverlayCompare: isOverlayCompare,
                                    compareCanvas: compareCanvas,
                                    isAutoCompare: isAutoCompare,
                                    setCompareDocumentViewer: setCompareDocumentViewer,
                                    side: side,
                                    isMobile: isMobile
                                }}
                            />
                        </div>
                    </div>
                )}
                {
                    (utilService.isWebsiteProof(proofVersion)) && 
                    <HtmlLiveNotSupported/>
                }
            </div>
            {pageCount > 1 && <EditorPageTools/>}
        </>
    );
};

export default PagedEditor;
