import React from 'react';

const DragIcon = ({ height = 12, width = 12, color = 'currentColor', marginTop = 0, ...rest }) => {
    return (
        <svg
            viewBox="0 0 20 20"
            version="1.1"
            width={width}
            height={height}
            className=""
            fill={color}
            {...rest}>
            <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
        </svg>
    );
};

export default DragIcon;
