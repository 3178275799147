import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { tenantService } from '../../Tenant';
import { DataLoadingIndicator } from '../../_shared/DataFetcher/DataLoadingIndicator';

const CustomEmailTemplatePreview = ({ show, onClose, emailSettings }) => {
    const [template, setTemplate] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchTemplate();
    }, []);

    const fetchTemplate = async () => {
        var template = await tenantService.getEmailTemplatePreview(emailSettings);
        setTemplate(template.body);
        var element = document.getElementById('mainPreview');
        element.innerHTML = template.body;
        setIsLoading(false);
    };
    return (
        <Modal show={show} onHide={onClose} size="lg" centered backdrop={'static'}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Email Template Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: 0 }}>
                <div id="mainPreview"></div>
                {isLoading === true && (
                    <DataLoadingIndicator
                        isLoading={isLoading}
                        height={'578px'}
                        width={'100%'}></DataLoadingIndicator>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default CustomEmailTemplatePreview;
