import React from 'react';
import { StatusItem } from './StatusItem';

import { DndContext } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { arrayMove } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

function DropIndicator({ isDragging }) {
    if (!isDragging) {
        return null;
    }

    return <li className="drop-indicator" />;
}

function StatusList({
    items,
    setItems,
    exitEditMode,
    editingComment,
    setEditingComment,
    onCheckChanged,
    enableDragging,
    updateStatusPriority    
}) {
    //const [sortedItems, setSortedItems] = React.useState(items);
    const [activeId, setActiveId] = React.useState(null);

    function handleDragStart(event) {
        const { active } = event;
        setActiveId(active.id);
    }

    function handleDragEnd(event) {
        const { active, over } = event;
        setActiveId(null);
        if (active && over && active.id !== over.id) {
            var updatedItems;            
            setItems((items) => {
                const oldIndex = items.findIndex( x => x.id === active.id);
                const newIndex = items.findIndex( x => x.id === over.id);
                updatedItems = arrayMove(items, oldIndex, newIndex); 
                return updatedItems;
            });
            if (updatedItems) {


                var prioritized = updatedItems.map((item, index) => {
                    var changedItem = {...item};
                    changedItem.priority = index + 1;
                    return changedItem; 
                });
                updateStatusPriority(prioritized);
            }
        
        }
    }

    return (
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
                <ul className="sortable-list">
                    {/* {JSON.stringify(items[0])} */}
                    {items.map((commentStatus, index) => (
                        <React.Fragment key={commentStatus.id}>
                            {/* comment item :
                            {JSON.stringify(commentStatus)} */}
                            
                            {activeId && index === 0 && (
                                <DropIndicator isDragging={activeId !== null} />
                            )}

                            <StatusItem
                                {...{
                                    id: commentStatus.id,
                                    itemText: `Item ${commentStatus.id}`,
                                    commentStatus,
                                    exitEditMode,
                                    editingComment,
                                    setEditingComment,
                                    onCheckChanged,
                                    index,
                                    enableDragging
                                }}
                            />
                            <DropIndicator isDragging={activeId !== null} />
                        </React.Fragment>
                    ))}
                </ul>
            </SortableContext>
        </DndContext>
    );
}

export default StatusList;
