import React from 'react';
import { AttachmentPicker } from './AttachmentPicker';
import Button from '../../../../_shared/Button';

const CommentBoxFooterActions = ({
    commentReply,
    attachments,
    setAttachments,
    editAllowed,
    setSelectedComment,
    handleSaveClicked,
    handleOnCancelClicked,
    style,
    ...rest
}) => {
    return (
        <div
            style={{...{
                width: '100%',
                display: 'flex',
                flexDirection: 'row'
            }, ...style}}>
            <AttachmentPicker attachments={attachments} setAttachments={setAttachments} />

            <Button
                variant={'transparent'}
                style={{ marginRight: '12px' }}
                onClick={handleOnCancelClicked}>
                <span>Cancel</span>
            </Button>

            <Button
                variant={'primary'}
                onClick={(e) => {
                    e.stopPropagation();
                    handleSaveClicked();
                }}
                
                >
                <span>Save</span>
            </Button>
        </div>
    );
};

export default CommentBoxFooterActions;
