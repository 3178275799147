import { authHeader, handleResponse, config } from '../../../_helpers';



async function saveDocumentToCache(cacheId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ cacheUri: cacheId })
    };

    return fetch(`${config.documentServiceHost}/api/page/SaveDocumentToCache`, requestOptions).then(handleResponse);
}

export {

    saveDocumentToCache
};
