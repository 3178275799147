import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { groupActions, alertActions, directoryActions, TreeView } from '../../../components';
import { utilService } from '../../../_helpers';

const initialValidationState = {
    directoryName: {
        validated: true,
        message: '',
    },
};

class DirectoryMoveModal extends React.Component {
    constructor(props) {
        super(props);
        var clonedTreedata = [...this.props.treeData];
        var mutedTreedata = clonedTreedata.map(item =>{
            var newItem = {...item};
            return newItem;
        });
        var validatedTreeData = utilService.deleteNode(
            { children: mutedTreedata },
            'children',
            this.props.currentDirectoryNode.key,
            'key',
            'children',
            [],
        );
        this.state = {
            validations: initialValidationState,
            treeData: validatedTreeData.children,
            currentDirectoryNode: this.props.currentDirectoryNode,
            currentParentDirectoryNode: this.props.currentParentDirectoryNode,
            currentDirectoryExpandedNodes: [this.props.currentParentDirectoryNode.key],
            submitted:false,
            canSave: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {}

    async onTreeNodeSelect(selectedNode) {
        var expandedKeys = utilService.findParentsArrayIds(
            this.state.treeData,
            selectedNode.key,
            'key',
            'title',
            'children',
        );
        await utilService.setStateAsync.bind(this)({
            currentParentDirectoryNode: selectedNode,
            currentDirectoryExpandedNodes: expandedKeys,
            submitted: false,
        });
    }

    onTreeNodeExpand(selectedNodeKeys) {
        this.setState({
            currentDirectoryExpandedNodes: selectedNodeKeys,
            submitted: false,
        });
    }

    async validateFields() {
        var globalValidation = true;
        var { validations } = this.state;
        var validationCopy = { ...validations };

        var requestPayload = {
            id: this.state.currentDirectoryNode.key,
            parentId: this.state.currentParentDirectoryNode.key,
            name: this.state.currentDirectoryNode.title,
        };
        var response = await this.props.dispatch(directoryActions.isNameUnique(requestPayload));
        if (!response.isNameUnique) {
            validationCopy.directoryName = {
                validated: false,
                message: 'Directory with the same name exists',
            };
            globalValidation = false;
        }

        await utilService.setStateAsync.bind(this)({
            validations: validationCopy,
        });
        return globalValidation;
    }
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({
            canSave: false
        });
        await utilService.setStateAsync.bind(this)({
            submitted: true,
            validations: initialValidationState,
        });

        if (!(await this.validateFields())) {
            return;
        }

        var childrenIds = utilService.findChildrenArrayIds(this.state.currentDirectoryNode);
        var requestPayload = {
            id: this.state.currentDirectoryNode.key,
            parentId: this.state.currentParentDirectoryNode.key,
            childrenIds: childrenIds,
        };
        await this.props.dispatch(directoryActions.move(requestPayload));
        this.props.dispatch(alertActions.success('Saved !'));
        this.onModalClose(true);
    }
    onModalClose(hasChanges) {
        this.props.onClose(hasChanges);
    }
    render() {
        console.log('DirectoryMoveModal Props: ', this.props);
        const { submitted, validations } = this.state;
        return (
            <Modal
                //{...this.props}
                show={this.props.show}
                onHide={()=>this.props.onClose(false)}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'}
                scrollable>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Move Directory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form name="form" onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                className="form-control mrnda-input-text"
                                name="name"
                                value={this.state.currentDirectoryNode.title || ''}
                                readOnly
                                disabled
                            />
                            {submitted && !validations.directoryName.validated && (
                                <div className="help-block">{validations.directoryName.message}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="name">Move to</label>
                            <div id="role-permission-container">
                                <TreeView
                                    treeData={this.state.treeData}
                                    onTreeNodeSelect={this.onTreeNodeSelect.bind(this)}
                                    onTreeNodeExpand={this.onTreeNodeExpand.bind(this)}
                                    selectedKeys={[this.state.currentParentDirectoryNode.key]}
                                    expandedKeys={this.state.currentDirectoryExpandedNodes}
                                />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant=""
                        // disabled={
                        //     this.state.fileUploadStatus === fileUploadConstants.FILE_LOADED
                        //         ? false
                        //         : true
                        // }
                        className="mrnda-btn mrnda-btn"
                        onClick={this.handleSubmit.bind(this)}
                        disabled={!this.state.canSave}
                        >
                        Save
                    </Button>
                    <Button  className ="mrnda-btn--secondary" onClick={()=>this.props.onClose(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    console.log('States in DirectoryMoveModal:', state);
    const { s3Info } = state;
    return {
        s3TempInfo: s3Info.s3Info,
    };
}

const connectedDirectoryMoveModal = connect(mapStateToProps)(DirectoryMoveModal);
export { connectedDirectoryMoveModal as DirectoryMoveModal };
