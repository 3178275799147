import { proofService } from '..';
import { videoCommentService } from '../VideoAnnotation/videoComment.service';
import { annotationReportConstants } from './annotationReport.constants';
import { alertConstants } from '../../App/alert.constants';
import { alertActions } from '../../App/alert.actions'
import { saveDocumentToCache } from './annotationReport.service';
import { utilService } from '../../../_helpers';
import { annotationService } from '../annotation.service';
const {
    PROOFSUMMARY_GETVIDEOPROOFVERION_REQUEST,
    PROOFSUMMARY_GETVIDEOPROOFVERION_SUCCESS,
    PROOFSUMMARY_GETVIDEOPROOFVERION_FAILURE,
    PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_REQUEST,
    PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_SUCCESS,
    PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_FAILURE,
    PROOFSUMMARY_DOWNLOADPAGE_REQUEST,
    PROOFSUMMARY_DOWNLOADPAGE_SUCCESS,
    PROOFSUMMARY_DOWNLOADPAGE_FAILURE
} = annotationReportConstants;

export const annotationReportActions = {
    getProofVersion,
    getVideoComments,
    getDocumentComments
};




function getVideoComments(proofId, versionId) {

    var requestObject = {
        proofId: proofId,
        proofVersionId: versionId,

    }
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));

            videoCommentService.getVideoComments(requestObject)

                .then(
                    videoComments => {

                        dispatch(success(videoComments));
                        dispatch(loading(false));
                        resolve(videoComments);
                    },
                    error => {
                        dispatch(failure(error.toString()));
                        dispatch(loading(false));
                        reject();
                    },
                );
        });
    };

    function request() {
        return { type: PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_REQUEST };
    }
    function success(data) {

        return {
            type: PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_SUCCESS,
            videoComments: data,
        };
    }
    function failure(error) {
        return { type: PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function getProofVersion(proofId, versionId) {

    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            proofService.getProofVersionSummary(proofId, versionId).then(
                proofVersionSummary => {
                    dispatch(success(proofVersionSummary));
                    dispatch(loading(false));
                    resolve(proofVersionSummary);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: PROOFSUMMARY_GETVIDEOPROOFVERION_REQUEST };
    }
    function success(proofVersion) {
        return {
            type: PROOFSUMMARY_GETVIDEOPROOFVERION_SUCCESS,
            proofVersion,
        };
    }
    function failure(error) {
        return { type: PROOFSUMMARY_GETVIDEOPROOFVERION_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}


function getDocumentComments(proofId, versionId) {

    var requestObject = {
        proofId: proofId,
        proofVersionId: versionId,

    }
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));

            annotationService.getDocumentComments(requestObject)

                .then(
                    videoComments => {

                        dispatch(success(videoComments));
                        dispatch(loading(false));
                        resolve(videoComments);
                    },
                    error => {
                        dispatch(failure(error.toString()));
                        dispatch(loading(false));
                        reject();
                    },
                );
        });
    };

    function request() {
        return { type: PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_REQUEST };
    }
    function success(data) {

        return {
            type: PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_SUCCESS,
            videoComments: data,
        };
    }
    function failure(error) {
        return { type: PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}







