import { directoryConstants } from '../../components';

export function directoriesData(state = {}, action) {
    switch (action.type) {
        case directoryConstants.GETALL_REQUEST:
            return {
                loading: true,
            };
        case directoryConstants.GETALL_SUCCESS:
            return {
                directories: action.data,
            };
        case directoryConstants.GETALL_FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}