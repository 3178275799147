import React, { useState } from 'react';
import { ConfirmationModal } from '../..';
import cx from 'classnames';
import StatusEdit from './StatusEdit';
import { withStatusManageConsumer } from './Context/StatusManageProvider';

function StatusRow({
    commentStatus,
    editingComment,
    setEditingComment,
    onCommentStatusLabelChanged,
    onCheckChanged,
    handleDeleteCommentStatus,
    validateCanDelete,
    statusType  
}) {
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState();   

    const deleteClicked = async () => {   
        let canDelete = await validateCanDelete(commentStatus);   
        if (canDelete === true) {
            setShowDeleteConfirmationModal(true);
        }     
    };

    const deleteModalSecondaryAction = () => {
        setShowDeleteConfirmationModal(false);
    };

    const deleteModalPrimaryAction = async () => {
        setShowDeleteConfirmationModal(false);
        await handleDeleteCommentStatus(commentStatus);
    };

    const onStatusEditClicked = (commentStatus) => {
        setEditingComment(commentStatus);
    };

    return (
        <div caption={commentStatus.label} className="custom-status-row" key={commentStatus.id}>
            <div
                className={cx({
                    'custom-status-cell': true,
                    'custom-status-cell-status': true,
                    'authorization-disabled':
                        editingComment !== undefined && commentStatus.id !== editingComment?.id
                })}
                style={{ width: '60%' }}>
                {editingComment !== undefined &&
                (editingComment.id === commentStatus.id) === true ? (
                    <CommentStatusEditHOC
                        {...{
                            commentStatus,
                            onCommentStatusLabelChanged
                        }}
                    />
                ) : (
                    <label
                        style={{ marginBottom: '0px' }}
                        className="span3 control-label custom-status-cell-status__label"
                        title={commentStatus.label}>
                        {commentStatus.label}
                    </label>
                )}
            </div>
            {/* Visibility section */}
            <div
                className={cx({
                    'custom-status-cell': true,
                    'custom-status-cell-visibility': true,
                    'authorization-disabled': editingComment !== undefined
                })}>
                <label className="check-container custom-status-cell-visibility__check">
                    <input
                        type="checkbox"
                        data-toggle="toggle"
                        data-on="Enabled"
                        data-off="Disabled"
                        name="visibility"
                        value={commentStatus.isVisible}
                        checked={commentStatus.isVisible}
                        onChange={(e) => {
                            onCheckChanged(e, commentStatus);
                        }}
                    />
                    <span
                        style={{ margin: 0, height: '17px' }}
                        className="check-container__checkmark">
                        <i className="fas fa-check" />
                    </span>
                </label>
            </div>

            <div
                className={cx({
                    'custom-status-cell': true,
                    'custom-status-cell-action': 'true',
                    'authorization-disabled': editingComment !== undefined
                })}>
                <a
                    data-value="59c3a2488986220f3466afb2"
                    className="_s_DeleteTreeItems folder-node-delete"
                    title="Edit Status"
                    onClick={() => {
                        onStatusEditClicked(commentStatus);
                    }}>
                    <i className="fas fa-pen" />
                </a>

                <a
                    data-value="59c3a2488986220f3466afb2"
                    className="_s_DeleteTreeItems folder-node-delete"
                    style={{ marginLeft: '20px' }}
                    title="Delete"
                    onClick={deleteClicked}>
                    <i className="far fa-trash-alt" />
                </a>
            </div>

            {showDeleteConfirmationModal && (
                <ConfirmationModal
                    show
                    onHide={deleteModalSecondaryAction}
                    onPrimaryClick={deleteModalPrimaryAction}
                    onSecondaryClick={deleteModalSecondaryAction}
                    primaryButtonText="Yes"
                    secondaryButtonText="No"
                    title={`Delete ${statusType.label.toLowerCase()} status`}
                    bodyText={`Are you sure you want to delete ?`}
                />
            )}
        </div>
    );
}

const CommentStatusEditHOC = withStatusManageConsumer(StatusEdit);

export default StatusRow;
