import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import heroImage from '../../assets/Images/intro-mobile-tenant.png';
import './HomePage.css';
class HomePage extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        document.title = 'Brandshare Collaboration - Admin Home';
    }
    renderHeroButtons(authenticationData) {
        if (authenticationData && Object.getOwnPropertyNames(authenticationData).length !== 0) {
            return (
                <>
                    <Link to="/dashboard" className="mrnda-btn">
                        <i>Get Started</i>
                    </Link>
                </>
            );
        } else {
            return (
                <>
                    <Link to="/login" className="mrnda-btn ">
                        {' '}
                        <i>Sign In</i>
                    </Link>
                    <Link to="/" className="mrnda-btn">
                        <i>Request Demo</i>
                    </Link>
                </>
            );
        }
    }
    render() {
        const { authentication } = this.props;
        return (
            <div id="hero-area" className="hero-area-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                            <div className="contents">
                                <h2 className="head-title">
                                    MIRANDA{' '}
                                    <span className="text-uppercase font-italic bg-accent-dark-gradient font-weight-normal">
                                        ADMIN{' '}
                                    </span>
                                    <br />
                                </h2>
                                <h3>World's Best Proofing Tool</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem
                                    repellendus quasi fuga nesciunt dolorum nulla magnam veniam
                                    sapiente, fugiat! fuga nesciunt dolorum nulla magnam veniam
                                    sapiente, fugiat!
                                </p>
                                <div className="header-button">
                                    {this.renderHeroButtons(authentication)}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                            <div className="intro-img">
                                <img className="img-fluid" src={heroImage} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication } = state;
    return {
        alert,
        authentication: authentication.user ? authentication.user.authData : {},
    };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };
