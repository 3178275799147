import React from 'react';

const DirectoryPathStatic = ({ path }) => {
    return (
        <div>
            <span>Home / </span>
            {path && path.split('/').map(item => <span key={item}>{item + ' / '}</span>)}
        </div>
    );
};

export { DirectoryPathStatic };
