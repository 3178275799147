import { handleResponse, config } from '../../_helpers';

export const supportService = {
    sendSupportRequest,
    getPreSignedUrl,
    getAttachmentConfig
};

function sendSupportRequest(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj),
    };
    return fetch(`${config.apiUrl}/support/sendSupportRequest`, requestOptions).then(handleResponse);
}

function getPreSignedUrl(fileName) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },               
    };
    return fetch(`${config.apiUrl}/support/GetPreSignedUrl/${fileName}`, requestOptions).then(handleResponse);
}


function getAttachmentConfig() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },               
    };
    return fetch(`${config.apiUrl}/support/getAttachmentConfig`, requestOptions).then(handleResponse);
}