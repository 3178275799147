import {
    proofService,
    proofConstants,
    tenantService,
    alertConstants,
    alertActions,
    DOWNLOAD_JOB_DATA,
} from '../../components';
import { utilService, store } from '../../_helpers';

export const proofActions = {
    get,
    getAll,
    getAllByDirectory,
    delete: _delete,
    add,
    ingestProof,
    move,
    copyProof,
    getAllProofsCount,
    getAllProofFilters,
    addPoofVersion,
    getProofVersions,
    getProofVersion,
    deleteProofVersion,
    clearProofs,
    runProofVersionDownload,
    getMainFileDownloadLink,
    getAllProofsVersions,
    someAction
};

function get(proofId) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            proofService.getById(proofId).then(
                proof => {
                    dispatch(success(proof));
                    dispatch(loading(false));
                    resolve(proof);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };
    function request() {
        return { type: proofConstants.GET_REQUEST };
    }
    function success(proof) {
        return { type: proofConstants.GET_SUCCESS, proof };
    }
    function failure(error) {
        return { type: proofConstants.GET_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function getAll(paramsObj) {
    return async dispatch => {
        console.log('GETALL PROOFS PARAM OBJ', paramsObj);
        dispatch(request());
        dispatch(loading(true));
        proofService.getAll(paramsObj).then(
            async paginatedProofData => {
                var currentTenant = await tenantService.getCurrentTenantObject();
                var thumbnailRequestObjects = [];
                // for (var proof of paginatedProofData.list) {
                //     var thumbnailRequestObject = {
                //         documentId: `${proof.defaultVersionId}.${utilService.getFileExtention(
                //             proof.documentName,
                //         )}`,
                //         pageNumber: 1,
                //         width: 260,
                //         height: 150,
                //         objectPath: proof.thumbnailPath,
                //     };
                //     thumbnailRequestObjects.push(thumbnailRequestObject);
                // }
                // var thumbnailRequest = {
                //     thumbnailItems : thumbnailRequestObjects,
                //     tenantInfo : currentTenant
                // }
                // var thumbnailResponses = await proofService.getProofThumbnail(
                //     thumbnailRequest,
                // );
                for (var proof of paginatedProofData.list) {
                    // var thumbnailResponse = thumbnailResponses.find(
                    //     response => response.documentId === proof.defaultVersionId,
                    // );
                    // proof.defaultVersionThumbnail = thumbnailResponse.thumbnailURL;
                    proof.createdAt = new Date(proof.createdAt).toLocaleString();
                    proof.modifiedAt = new Date(proof.modifiedAt).toLocaleString();
                }
                dispatch(success(paginatedProofData));
                dispatch(loading(false));
            },
            error => {
                dispatch(failure(error.toString()));
                //dispatch(alertActions.error(error.toString()));
                dispatch(loading(false));
            },
        );
    };

    function request() {
        return { type: proofConstants.GETALL_REQUEST };
    }
    function success(data) {
        return { type: proofConstants.GETALL_SUCCESS, data };
    }
    function failure(error) {
        return { type: proofConstants.GETALL_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function someAction(data) {
    return dispatch => { 
        dispatch(success(data));
    };

    function success(data) {
        return { type: proofConstants.GETALL_SUCCESS, data };
    }    
}

function getAllByDirectory(paramsObj) {
    return async dispatch => {
        return new Promise((resolve, reject) => {
            // console.log('GETALL PROOFS PARAM OBJ', paramsObj);
            dispatch(request());
            dispatch(loading(true));
            proofService.getAllByDirectory(paramsObj).then(
                async paginatedProofData => {
                    var currentTenant = await tenantService.getCurrentTenantObject();
                    var thumbnailRequestObjects = [];
                    // for (var proof of paginatedProofData.list) {
                    //     var thumbnailRequestObject = {
                    //         documentId: `${proof.defaultVersionId}.${utilService.getFileExtention(
                    //             proof.documentName,
                    //         )}`,
                    //         pageNumber: 1,
                    //         width: 260,
                    //         height: 150,
                    //         objectPath: proof.thumbnailPath,
                    //     };
                    //     thumbnailRequestObjects.push(thumbnailRequestObject);
                    // }
                    // var thumbnailRequest = {
                    //     thumbnailItems : thumbnailRequestObjects,
                    //     tenantInfo : currentTenant
                    // }
                    // var thumbnailResponses = await proofService.getProofThumbnail(
                    //     thumbnailRequest,
                    // );
                    for (var proof of paginatedProofData.proofs.list) {
                        // var thumbnailResponse = thumbnailResponses.find(
                        //     response => response.documentId === proof.defaultVersionId,
                        // );
                        // proof.defaultVersionThumbnail = thumbnailResponse.thumbnailURL;
                        proof.createdAt = new Date(proof.createdAt).toLocaleString();
                        proof.modifiedAt = new Date(proof.modifiedAt).toLocaleString();
                    }
                    dispatch(success(paginatedProofData));
                    dispatch(loading(false));
                    resolve(paginatedProofData);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: proofConstants.GETALL_BY_DIRECTORY_REQUEST };
    }
    function success(data) {
        return { type: proofConstants.GETALL_BY_DIRECTORY_SUCCESS, data };
    }
    function failure(error) {
        return { type: proofConstants.GETALL_BY_DIRECTORY_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function getAllProofsVersions(paramsObj) {
    return async dispatch => {
        return new Promise((resolve, reject) => {
            // console.log('GETALL PROOFS PARAM OBJ', paramsObj);
            // dispatch(request());
            // dispatch(loading(true));
            proofService.getAllProofsVersions(paramsObj).then(
                async paginatedProofData => {
                    for (var proof of paginatedProofData.list) {
                        proof.createdAt = new Date(proof.createdAt).toLocaleString();
                        proof.versions.forEach(version=>{
                            version.createdAt = new Date(proof.createdAt).toLocaleString();
                        })
                    }
                    // dispatch(success(paginatedProofData));
                    // dispatch(loading(false));
                    resolve(paginatedProofData);
                },
                error => {
                    // dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    // dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: proofConstants.GETALL_BY_DIRECTORY_REQUEST };
    }
    function success(data) {
        return { type: proofConstants.GETALL_BY_DIRECTORY_SUCCESS, data };
    }
    function failure(error) {
        return { type: proofConstants.GETALL_BY_DIRECTORY_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function clearProofs() {
    return async dispatch => {
        return new Promise((resolve, reject) => {
            var proofs = {
                list: [],
                pageIndex: 0,
                pageSize: 2147483647,
                totalCount: 0,
                totalPages: 1,
            };
            let data = {} ;
            dispatch(success(data));
            resolve(data);
        });
    };
    function success(data) {
        return { type: proofConstants.GETALL_BY_DIRECTORY_SUCCESS, data };
    }
}

function getAllProofsCount(paramsObj) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            proofService.getAllProofsCount(paramsObj).then(
                proofsObj => {
                    dispatch(success(proofsObj));
                    dispatch(loading(false));
                    resolve();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: proofConstants.GETALL_PROOFS_COUNT_REQUEST };
    }
    function success(proofsObj) {
        return { type: proofConstants.GETALL_PROOFS_COUNT_SUCCESS, proofsObj };
    }
    function failure(error) {
        return { type: proofConstants.GETALL_PROOFS_COUNT_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function getAllProofFilters() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            proofService.getAllProofFilters().then(
                proofsFilters => {
                    dispatch(success(proofsFilters));
                    dispatch(loading(false));
                    resolve();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: proofConstants.GETALL_FILTERS_REQUEST };
    }
    function success(proofsFilters) {
        return { type: proofConstants.GETALL_FILTERS_SUCCESS, proofsFilters };
    }
    function failure(error) {
        return { type: proofConstants.GETALL_FILTERS_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function add(proof) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(proof));
            dispatch(loading(true));
            proofService.add(proof).then(
                proof => {
                    dispatch(success(proof));
                    dispatch(loading(false));
                    // dispatch(
                    //     getAll({
                    //         page: 0,
                    //         pageSize: 20,
                    //         proofSortModel: {
                    //             sortDirection: 0,
                    //             sortColumn: null,
                    //         },
                    //         proofFilters: [],
                    //     }),
                    // );
                    resolve(proof);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    //dispatch(alertActions.error(error.toString()));
                    reject();
                },
            );
        });
    };
    function request(proof) {
        return { type: proofConstants.ADD_REQUEST, proof };
    }
    function success(proof) {
        return { type: proofConstants.ADD_SUCCESS, proof };
    }
    function failure(error) {
        return { type: proofConstants.ADD_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function _delete(proof) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(proof));
            dispatch(loading(true));
            proofService.delete(proof).then(
                () => {
                    dispatch(success(proof));
                    dispatch(loading(false));
                    resolve(proof);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    //dispatch(alertActions.error(error.toString()));
                    reject();
                },
            );
        });
    };

    function request(proof) {
        return { type: proofConstants.DELETE_REQUEST, proof };
    }
    function success(proof) {
        return { type: proofConstants.DELETE_SUCCESS, proof };
    }
    function failure(proof, error) {
        return { type: proofConstants.DELETE_FAILURE, proof, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function deleteProofVersion(proofVersion) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(proofVersion));
            dispatch(loading(true));
            proofService.deleteProofVersion(proofVersion).then(
                () => {
                    dispatch(success(proofVersion));
                    dispatch(loading(false));
                    resolve(proofVersion);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    //dispatch(alertActions.error(error.toString()));
                    reject();
                },
            );
        });
    };

    function request(proofVersion) {
        return { type: proofConstants.DELETE_REQUEST, proofVersion };
    }
    function success(proofVersion) {
        return { type: proofConstants.DELETE_SUCCESS, proofVersion };
    }
    function failure(proofVersion, error) {
        return { type: proofConstants.DELETE_FAILURE, proofVersion, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function ingestProof(proof) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(proof));
            dispatch(loading(true));
            proofService.ingestProof(proof).then(
                () => {
                    dispatch(success(proof));
                    dispatch(loading(false));
                    resolve(proof);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    //dispatch(alertActions.error(error.toString()));
                    reject();
                },
            );
        });
    };
    function request(proof) {
        return { type: proofConstants.INGEST_REQUEST, proof };
    }
    function success(proof) {
        return { type: proofConstants.INGEST_SUCCESS, proof };
    }
    function failure(error) {
        return { type: proofConstants.INGEST_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function move(requestObject) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(requestObject));
            dispatch(loading(true));
            proofService.move(requestObject).then(
                requestObject => {
                    dispatch(success(requestObject));
                    dispatch(loading(false));
                    resolve(requestObject);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };
    function request(requestObject) {
        return { type: proofConstants.INGEST_REQUEST, requestObject };
    }
    function success(requestObject) {
        return { type: proofConstants.INGEST_SUCCESS, requestObject };
    }
    function failure(error) {
        return { type: proofConstants.INGEST_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function copyProof(requestObject) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(requestObject));
            dispatch(loading(true));
            proofService.copyProof(requestObject).then(
                requestObject => {
                    // dispatch(success(requestObject));
                    dispatch(loading(false));
                    resolve(requestObject);
                },
                error => {
                    // dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };
    function request(requestObject) {
        return { type: proofConstants.INGEST_REQUEST, requestObject };
    }
    function success(requestObject) {
        return { type: proofConstants.INGEST_SUCCESS, requestObject };
    }
    function failure(error) {
        return { type: proofConstants.INGEST_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function addPoofVersion(proofVersion) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(proofVersion));
            dispatch(loading(true));
            proofService.addProofVersion(proofVersion).then(
                proofVersionResponse => {
                    dispatch(success(proofVersionResponse));
                    dispatch(loading(false));
                    //dispatch(get(proofVersion.id));
                    //dispatch(
                    //     getProofVersions(proofVersion.id)
                    // );
                    resolve(proofVersionResponse);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    //dispatch(alertActions.error(error.toString()));
                    reject();
                },
            );
        });
    };
    function request(proof) {
        return { type: proofConstants.ADD_REQUEST, proof };
    }
    function success(proof) {
        return { type: proofConstants.ADD_SUCCESS, proof };
    }
    function failure(error) {
        return { type: proofConstants.ADD_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function getProofVersions(proofId) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            proofService.getProofVersions(proofId).then(
                proofVersions => {
                    dispatch(success(proofVersions));
                    dispatch(loading(false));
                    resolve(proofVersions);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: proofConstants.PROOFVERSION_GETALL_REQUEST };
    }
    function success(proofVersions) {
        return {
            type: proofConstants.PROOFVERSION_GETALL_SUCCESS,
            proofVersions,
        };
    }
    function failure(error) {
        return { type: proofConstants.PROOFVERSION_GETALL_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function getProofVersion(proofId, versionId) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            proofService.getProofVersion(proofId, versionId).then(
                proofVersions => {
                    dispatch(success(proofVersions));
                    dispatch(loading(false));
                    resolve(proofVersions);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: proofConstants.PROOFVERSION_GETALL_REQUEST };
    }
    function success(proofVersions) {
        return {
            type: proofConstants.PROOFVERSION_GETALL_SUCCESS,
            proofVersions,
        };
    }
    function failure(error) {
        return { type: proofConstants.PROOFVERSION_GETALL_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
async function runProofVersionDownload(proofVersion) {
    return new Promise(async resolve => {
        var dispatch = store.dispatch;
        var toastId = await dispatch(alertActions.startToast('Processing Download...'));
        dispatch(alertActions.loading(true));
        dispatch(loading(true));
        var userToken = null;
        var jobToken = null;
        var jobData = DOWNLOAD_JOB_DATA;
        var filename = utilService.getFileName(proofVersion.name);
        jobData.documentName = `${filename}_v${proofVersion.versionNumber}`;
        var requestObject = {
            documentId: proofVersion.id,
            jobData: jobData,
        };
        var convertJobResult = await proofService.runConvertJob(requestObject);
        var minWaitFromResponse = 2000;
        var minWaitFromRequest = 3000;
        userToken = convertJobResult.userToken;
        jobToken = convertJobResult.jobToken;
        var requestTime = -1;
        var responseTime = -1;
        var runQuery = null;
        var queryDone = async function (statusJobData) {
            if (statusJobData.abort) {
                return;
            }
            if (statusJobData.errorMessages && statusJobData.errorMessages.length) {
                var messages = [
                    'The conversion job encountered an error:',
                    statusJobData.errorMessages[0],
                    'Check the console for more information.',
                ];
                return;
            } else if (statusJobData.isCompleted) {
                var url = lt.Document.Service.Custom.createEndpointGetUrl(
                    'Factory',
                    'DownloadDocument',
                    {
                        uri: statusJobData.outputDocumentUri,
                        includeAnnotations: false,
                        // To force download, use this
                        contentDisposition: 'attachment',
                        userData: lt.Document.DocumentFactory.serviceUserData,
                    },
                    true,
                );
                var deleteQueryRequest = {
                    userToken: userToken,
                    jobToken: jobToken

                }
                await proofService.deleteConvertJob(deleteQueryRequest);
                dispatch(success(url));
                await dispatch(alertActions.dismissToast(toastId));
                dispatch(alertActions.loading(false));
                console.log('DONE!');
                window.location.href = url;
                resolve();
                return;
            }
            // Keep querying
            await runQuery(Date.now());
        };
        runQuery = async function (responseTime) {
            var now = Date.now();
            if (requestTime !== -1) {
                var timeSinceRequest = now - requestTime;
                var timeSinceResponse = now - responseTime;
                // If we haven't waited the minimum since the response and haven't waited the maximum since the request, wait
                if (
                    timeSinceResponse < minWaitFromResponse ||
                    timeSinceRequest < minWaitFromRequest
                ) {
                    var waitTime = Math.max(
                        minWaitFromResponse - timeSinceResponse,
                        minWaitFromRequest - timeSinceRequest,
                    );
                    setTimeout(async function () {
                        await runQuery(responseTime);
                    }, waitTime);
                    // resolve();
                    return;
                }
            }
            var result = await proofService.queryConvertJob({ userToken, jobToken });
            requestTime = Date.now();
            await queryDone(result.jobData);
            // resolve();
            return;
        };
        await runQuery(Date.now());
    });
    function success(URL) {
        return {
            type: proofConstants.PROOFVERSION_DOWNLOAD_SUCCESS,
            URL,
        };
    }
    function failure(error) {
        return { type: proofConstants.PROOFVERSION_DOWNLOAD_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: proofConstants.PROOFVERSION_DOWNLOAD_REQUEST, isProcessing: isLoading };
    }
}

function getMainFileDownloadLink(requestObj) {
    return dispatch => {
        return new Promise(async (resolve, reject) => {
            var toastId = await dispatch(alertActions.startToast('Processing Download...'));
            dispatch(alertActions.loading(true));
            dispatch(loading());
            proofService.getMainFileDownloadLink(requestObj).then(
                async response => {
                    dispatch(alertActions.loading(false));
                    dispatch(success(response.downloadURL));
                    await dispatch(alertActions.dismissToast(toastId));
                    resolve(response);
                },
                async error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    dispatch(alertActions.loading(false));
                    await dispatch(alertActions.dismissToast(toastId));
                    reject();
                },
            );
        });
    };

    function success(URL) {
        return {
            type: proofConstants.PROOFVERSION_DOWNLOAD_SUCCESS,
            URL,
        };
    }
    function failure(error) {
        return { type: proofConstants.PROOFVERSION_DOWNLOAD_FAILURE, error };
    }
    function loading() {
        return { type: proofConstants.PROOFVERSION_DOWNLOAD_REQUEST };
    }
}
