export const EMAIL_SETTINGS_TYPES = {
    default: {
        value: 0,
        label: 'Default',
        tooltip: 'Default templates will be used to send email'
    },
    custom: {
        value: 1,
        label: 'Custom',
        tooltip: 'Emails will be sent using custom template'
    }    
};
