import {
    authHeader,
    config,    
    handleResponse
} from '../../../../_helpers';

export const commentStatusService = {    
    getAllStatus,
    addStatus,
    updateStatus,
    updateStatusPriority,
    deleteStatus,
    canDeleteStatus
};

async function getAllStatus(includeHidden) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${config.apiUrl}/tenant/getAllCommentStatus?includeHidden=${includeHidden}`, requestOptions).then(handleResponse);
}

async function addStatus(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/tenant/AddCommentStatus`, requestOptions).then(handleResponse);
}

async function updateStatus(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/tenant/updateCommentStatus`, requestOptions).then(handleResponse);
}

async function updateStatusPriority(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/tenant/updateCommentStatusPriority`, requestOptions).then(handleResponse);
}

async function deleteStatus(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/tenant/deleteCommentStatus`, requestOptions).then(handleResponse);
}

async function canDeleteStatus(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/tenant/canDeleteCommentStatus`, requestOptions).then(handleResponse);
}
