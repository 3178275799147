import React from 'react';
import { commentHelper } from '../../../../../_helpers/commentHelper';

const CommentSorter = (props) => {
    
    const onSortComments = () => {
        this.props.releaseCommentSelection();        
    }

    const sortPositional = () => {
        onSortComments();
        var result = commentHelper.sortPositionally([...props.annotationComments],props.pageCount, props.pageCount > 1);        
        props.setAnnotationComments(result);        
    }

    const sortDefault = () =>  {
        onSortComments();
        var result = commentHelper.sortByTime([...props.annotationComments] );        
        props.setAnnotationComments(result); 
    }
    
    return (
        <div
            className="mrnda-tabs-themed__item--active__tools"
            title="Sort comments">
            <div className="dropdown">
                <span
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{}}
                    className="threedots__label"
                    data-toggle="dropdown">
                    {/* <i className="fas fa-sort"></i> */}
                    <i className="fas fa-arrows-alt-v"></i>
                </span>
                <div
                    className="dropdown-menu drop-down-themed"
                    style={{ width: 'auto' }}
                    aria-labelledby="dropdownMenuButton">
                    <div
                        className="dropdown-item rounded-0 drop-down-item-no-transform"
                        onClick={() => {
                            sortDefault();
                        }}>
                        <a>
                            <span>Default</span>
                        </a>
                    </div>
                    <div
                        className="dropdown-item rounded-0 drop-down-item-no-transform"
                        onClick={() => {
                            sortPositional();
                        }}>
                        <a>
                            <span>Positional</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {CommentSorter};