import React from 'react';

function ExtensionBackdrop() {
    return (
        <div style={{ width: '443px', height: '170px', position: 'relative' }}>
            <div
                style={{
                    width: '443px',
                    height: '170px',
                    display: 'flex',
                    justifyContent: 'end',
                    position: 'absolute'
                }}>
                <div
                    style={{
                        position: 'relative',
                        height: '78px',
                        width: '78px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <svg
                        style={{ position: 'absolute' }}
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16" cy="16" r="16" fill="var(--primary-text-color)" fillOpacity="0.05" />
                        <circle cx="16" cy="16" r="15.5" stroke="var(--primary-text-color)" strokeOpacity="0.05" />
                    </svg>
                    <svg
                        style={{ position: 'absolute' }}
                        width="54"
                        height="54"
                        viewBox="0 0 54 54"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="27" cy="27" r="26.5" stroke="var(--primary-text-color)" stroke-opacity="0.1" />
                    </svg>
                    <svg
                        style={{ position: 'absolute' }}
                        width="78"
                        height="78"
                        viewBox="0 0 78 78"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="39" cy="39" r="38.5" stroke="var(--primary-text-color)" stroke-opacity="0.05" />
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none">
                        <path
                            d="M18.8186 9.00031L11.2901 9C10.8538 9 10.5 9.3561 10.5 9.79537V22.2032L10.5006 22.2365C10.5254 22.8755 11.2611 23.2291 11.7719 22.8336L14.2295 20.9306H18.7175L18.7768 20.9301C20.7634 20.8941 22.3521 19.2675 22.3521 17.2719L22.3515 17.2041C22.3368 16.3918 22.0568 15.6264 21.575 15.0102L21.5391 14.9651L21.5749 14.9204C22.0702 14.2871 22.3521 13.4962 22.3521 12.6587C22.3521 10.6762 20.7836 9.05575 18.8186 9.00031ZM12.0803 10.5907H18.7824L18.7744 10.5904C19.8848 10.6218 20.7719 11.5381 20.7719 12.6587C20.7719 13.3053 20.4759 13.9008 19.9802 14.2901L19.9345 14.325C19.5158 14.6356 19.5062 15.2585 19.9059 15.5833L19.9345 15.6055C20.4577 15.9937 20.7719 16.6057 20.7719 17.2719C20.7719 18.3998 19.8738 19.3192 18.7555 19.3395L18.7104 19.3399L13.9608 19.3398L13.9207 19.3409C13.7607 19.349 13.6066 19.406 13.4791 19.5048L12.0803 20.5876V10.5907Z"
                            fill="#0080E0"
                        />
                    </svg>
                </div>
            </div>

            <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'end', position: 'absolute' }}>
                <svg
                    style={{ position: 'absolute' }}
                    width="436"
                    height="163"
                    viewBox="0 0 436 163"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6 0.5H430C433.038 0.5 435.5 2.96243 435.5 6V162.5H0.5V6C0.5 2.96243 2.96243 0.5 6 0.5Z"
                        stroke="url(#paint0_linear_1_64)"
                        stroke-opacity="0.2"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_1_64"
                            x1="218"
                            y1="0"
                            x2="218"
                            y2="163"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="var(--primary-text-color)" />
                            <stop offset="1" stop-color="var(--primary-text-color)" stop-opacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>

            <div style={{paddingTop: '23px'}}>
                <svg
                    style={{marginLeft: '16px'}}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="20" height="20" fill="var(--primary-background-color)" fill-opacity="0.01" />
                    <path
                        d="M11 5L7 10L11 15"
                        stroke="var(--primary-text-color)"
                        stroke-opacity="0.2"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>

                <svg
                style={{marginLeft: '5px'}}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="20" height="20" fill="var(--primary-background-color)" fill-opacity="0.01" />
                    <path
                        d="M8 5L12 10L8 15"
                        stroke="var(--primary-text-color)"
                        stroke-opacity="0.2"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>

                <svg
                style={{marginLeft: '12px'}}
                    width="299"
                    height="32"
                    viewBox="0 0 299 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="299" height="32" rx="4" fill="var(--primary-text-color)" fill-opacity="0.05" />
                </svg>
            </div>
        </div>
    );
}

export default ExtensionBackdrop;
