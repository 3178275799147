import React from 'react';
import { connect } from 'react-redux';
import { history, utilService } from '../../_helpers';
import './TenantDashboard.css';
import {
    ProofsContainerListView,
    CreateProofModal,
    proofActions,
    ProofsContainerFolderView,
    AuthorizationComponent,
    authorizationConstants,
    directoryConstants,
    ProofCompareGlobalModal,
    AutoCompareButton,
    CreateProofComponent
} from '../../components';
import { tenantService } from '../Tenant';
import { CREATE_PROOF_MODE } from '../Proof';
import { isCompactNav } from '../../_helpers/navbarUtil';
import { WrappedInsidePrimaryNav } from '../_shared/Nav/withPrimaryNav';

const ProofListContainer = ({ isAuthorized, onClick }) => {
    if (isAuthorized) {
        return <ProofsContainerListView onRowSelected={onClick} />;
    } else return null;
};

function DashboardNavActions(props) {
    return (
        <div className="mrnda-sub-navigation__options">
            <ul className="mrnda-sub-navigation__list">
                <li className="mrnda-sub-navigation__list-item">
                    {/* <a className="disabled" onClick={this.onCreateProof.bind(this)}>
        Create Proof
        </a> */}
                    <AuthorizationComponent
                        component={CreateProofComponent}
                        classes=""
                        permissionKey={{
                            module: authorizationConstants.Dashboard.KEY,
                            action: authorizationConstants.Dashboard.ACTION_DASHBOARD_PROOF_CREATE
                        }}
                        onClick={props.onCreateProof}
                    />
                </li>
                {/* <li className="mrnda-sub-navigation__list-item">
      <a onClick={this.onViewProof.bind(this)}>
      View Proof
      </a>
      </li> */}
                {/* <li
      className="nav-list__item disabled-feature"
      >
      <a>Edit Metadata</a>
      </li> */}
                <li className="mrnda-sub-navigation__list-item">
                    <AuthorizationComponent
                        component={AutoCompareButton}
                        classes=""
                        disabled={!props.showCompareButton}
                        permissionKey={{
                            module: authorizationConstants.ProofViewer.KEY,
                            action: authorizationConstants.ProofViewer.ACTION_PROOF_VIEWER_COMPARE
                        }}
                        onClick={props.onCompareClicked}
                    />
                </li>

                <li className="mrnda-sub-navigation__list-item">
                    <div className="belect">
                        <select
                            id="gridView"
                            className="form-select"
                            name="gridView"
                            onChange={props.handleGridViewChange} // defaultValue="folderView"
                            value={props.gridView}>
                            <option value="listView">List View</option>
                            <option value="folderView" title="">
                                Folder View
                            </option>
                        </select>
                    </div>
                </li>
                <li className="mrnda-sub-navigation__list-item d-none">
                    <a>
                        <span className="mr-1">
                            <span className="muted mr-1">Filter:</span> No preset filter
                        </span>
                        <i className="fas fa-angle-down muted" />
                    </a>
                </li>

                {props.gridView !== 'folderView' && (
                    <li className="mrnda-sub-navigation__list-item">
                        <div className="mrnda-input-text--search">
                            <input
                                type="text"
                                className="mrnda-input-text"
                                placeholder="Search..."
                                onChange={props.handleSearchClick}
                            />
                            <span className="mrnda-icon-btn">
                                <i className="fas fa-search" />
                            </span>
                        </div>
                    </li>
                )}
                <li className="mrnda-sub-navigation__list-item d-none">
                    <label className="check-container">
                        <span className="text--small display--block">
                            <strong>Exact match</strong>
                        </span>
                        <input type="checkbox" />
                        <span className="check-container__checkmark">
                            <i className="fas fa-check" />
                        </span>
                    </label>
                </li>
            </ul>
        </div>
    );
}

function DashboardTitleBar(props) {

    return (
        
        
            !isCompactNav() ? 
        
        <nav
            style={
                isCompactNav(props.navBarConfig)
                    ? {
                          top: '0px'
                      }
                    : {}
            }
            className="mrnda-sub-navigation">
            <div className="mrnda-sub-navigation__title">
                <h1>Dashboard</h1>
            </div>
            <DashboardNavActions
                onCreateProof={props.onCreateProof}
                showCompareButton={props.showCompareButton}
                onCompareClicked={props.onCompareClicked}
                handleGridViewChange={props.handleGridViewChange}
                gridView={props.gridView}
                handleSearchClick={props.handleSearchClick}>                    
            </DashboardNavActions>
        </nav> 
        : 
        <WrappedInsidePrimaryNav WrappedComponent={DashboardNavActions} {...props}/>        
    );
    
}

class TenantDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.initialNodeState = {
            key: directoryConstants.ROOT_DIRECTORY_ID,
            title: 'Home',
            path: '',
            parentId: null,
            children: []
        };
        this.state = {
            showCreateProof: false,
            showProofCompareModal: false,
            selectedRow: null,
            gridView: 'folderView',
            currentDirectoryNode: this.initialNodeState,
            showCompareButton: true
        };
        this.folderViewComponent = React.createRef();
        this.handleDeleteProof = this.handleDeleteProof.bind(this);
        this.liveUpdateChange = null;
    }
    async componentDidMount() {
        document.title = 'Brandshare Collaboration - Dashboard';
        var currentView =
            this.props.match && this.props.match.params
                ? this.props.match.params.viewtype
                : 'folderView';

        switch (currentView) {
            case 'lists':
                await utilService.setStateAsync.bind(this)({ gridView: 'listView' });
                break;
            case 'folders':
            default:
                await utilService.setStateAsync.bind(this)({ gridView: 'folderView' });
                break;
        }

        //Compare settings
        let proofSettings = await tenantService.getProofSettings();
        this.state.showCompareButton = proofSettings.comparisonType == 2 ? false : true;
    }

    handleDeleteProof() {
        var newProofs = this.state.proofsMock;
        newProofs.shift();
        this.setState({
            proofsMock: newProofs
        });
    }
    onCreateProof() {
        this.setState({ showCreateProof: true });
    }
    onViewProof() {
        this.executeViewProof();
        //this.props.dispatch(alertActions.error("VOOO"));
    }
    executeViewProof() {
        if (this.state.selectedRow.id) {
            // let baseRoute = this.state.selectedRow.mediaType === 2 ? '/proof/video' : '/proof';
            let baseRoute = '/proof';

            // @ts-ignore
            history.push({
                pathname: `${baseRoute}/${this.state.selectedRow.id}/${this.state.selectedRow.defaultVersionId}`,
                data: this.state.selectedRow,
                hasBackRoute: true
            });
        }
    }

    async modalClose() {
        this.setState({ showCreateProof: false });
        var requestObject = {};
        if (this.state.gridView === 'folderView') {
            requestObject = {
                page: 0,
                pageSize: 2147483647,
                directoryNodeId: this.state.currentDirectoryNode.key,
                proofSortModel: {
                    sortDirection: -1,
                    sortColumn: 'createdAt'
                },
                proofFilters: []
            };
            this.liveUpdateChange && this.liveUpdateChange();
        } else {
            requestObject = {
                page: 0,
                pageSize: 20,
                directoryNodeId: this.state.currentDirectoryNode.key,
                proofSortModel: {
                    sortDirection: 0,
                    sortColumn: null
                },
                proofFilters: []
            };
        }
        await this.props.dispatch(proofActions.getAllByDirectory(requestObject));
    }

    handleLiveUpdateChange(changeLiveUpdateTarget) {
        this.liveUpdateChange = changeLiveUpdateTarget;
    }

    handleCreateTenant() {
        history.push('/register');
    }
    onRowSelected(row) {
        console.log('ROWS:', row);
        this.setState({ selectedRow: row }, () => {
            if (
                row.status &&
                row.status.metadataServiceStatus === 2 &&
                row.status.conversionServiceStatus == 2
            ) {
                this.executeViewProof();
            }
        });
    }

    async handleGridViewChange(event) {
        const { value } = event.target;
        await utilService.setStateAsync.bind(this)({
            gridView: value,
            selectedRow: null,
            currentDirectoryNode: this.initialNodeState
        });
        if (value === 'listView') {
            history.push({
                pathname: `/dashboard/lists`
            });
        } else {
            history.push({
                pathname: `/dashboard/folders`
            });
        }
    }
    handleCardClick(proofRow) {
        this.setState({ selectedRow: proofRow }, () => this.executeViewProof());
    }
    handleDirectoryChange(node) {
        this.setState({ currentDirectoryNode: node });
    }
    renderGridView(gridView) {
        switch (gridView) {
            case 'listView': {
                //return <ProofsContainerListView onRowSelected={this.onRowSelected.bind(this)} />;
                return (
                    <AuthorizationComponent
                        component={ProofListContainer}
                        permissionKey={{
                            module: authorizationConstants.Dashboard.KEY,
                            action: authorizationConstants.Dashboard.ACTION_DASHBOARD_PROOF_VIEW
                        }}
                        onClick={this.onRowSelected.bind(this)}
                    />
                );
            }
            case 'folderView': {
                return (
                    <ProofsContainerFolderView
                        setLiveUpdateListeners={this.handleLiveUpdateChange.bind(this)}
                        initialNodeState={this.initialNodeState}
                        handleCardClick={this.handleCardClick.bind(this)}
                        handleFolderClick={this.handleDirectoryChange.bind(this)}
                        {...this.props}
                    />
                );
            }
            default: {
                return <ProofsContainerListView onRowSelected={this.onRowSelected.bind(this)} />;
            }
        }
    }
    handleSearchClick(event) {
        event.preventDefault();
        var { value } = event.target;
        var paramObj = {
            page: 0,
            pageSize: 2147483647,
            proofFilters: value ? [{ filterColumn: 'documentName', filterValue: value }] : [],
            proofSortModel: { sortDirection: 1, sortColumn: null }
        };

        this.props.dispatch(proofActions.getAll(paramObj));
    }

    render() {
        // console.log('proofs.items in Dashboard', proofs.items);
        return (
            <>
                <DashboardTitleBar
                    navBarConfig={this.props.navBarConfig}
                    onCreateProof={this.onCreateProof.bind(this)}
                    showCompareButton={this.state.showCompareButton}
                    setState={this.setState}
                    handleGridViewChange={this.handleGridViewChange.bind(this)}
                    gridView={this.state.gridView}
                    onCompareClicked={() =>
                        this.setState({
                            showProofCompareModal: true
                        })
                    }
                    handleSearchClick={this.handleSearchClick.bind(this)}></DashboardTitleBar>

                <div className="main-content">
                    {/* <hr />
                        <div className="proof-controls">
                            <button
                                className="btn btn-primary border-0 bg-accent-gradient"
                                onClick={this.onCreateProof.bind(this)}
                            >
                                Create Proof
                            </button>
                            <span> | </span>
                            <button
                                className="btn btn-primary border-0 bg-accent-gradient"
                                onClick={this.onDeleteProof.bind(this)}
                            >
                                View Proof
                            </button>
                            <span> | </span>
                            <button className="btn btn-primary border-0 bg-accent-gradient">
                                Edit Metadata
                            </button>
                            <span> | </span>
                            <button className="btn btn-primary border-0 bg-accent-gradient">
                                Download
                            </button>
                            <span> | </span>
                            <button className="btn btn-primary border-0 bg-accent-gradient">
                                Share File
                            </button>
                            <span> | </span>
                            <button className="btn btn-primary border-0 bg-accent-gradient">
                                Compare Proof
                            </button>
                        </div>
                        <hr /> */}
                    {/* <div className="right-text-container">
                                {this.state.gridView === 'listView' &&
                                    <button

                                        disabled={!this.state.selectedRow}
                                        className="btn btn-primary border-0 bg-accent-gradient inactive"
                                        onClick={this.onViewProof.bind(this)}
                                    >
                                        View Proof
                                    </button>
                                <label className="check-container">
                                    <span className="text--small display--block muted">
                                        <strong>Select all</strong>
                                    </span>
                                    <input type="checkbox" />
                                    <span className="check-container__checkmark">
                                        <i className="fas fa-check" />
                                    </span>
                                </label>
                                <p className="text--small muted">
                                    <strong>10 items selected</strong>
                                </p>
                            </div> */}
                    {this.renderGridView(this.state.gridView)}
                    {this.state.showCreateProof && (
                        <CreateProofModal
                            currentDirectoryNodeId={this.state.currentDirectoryNode.key}
                            createMode={CREATE_PROOF_MODE.PROOF}
                            show={this.state.showCreateProof}
                            onHide={this.modalClose.bind(this)}
                        />
                    )}
                    {this.state.showProofCompareModal && (
                        <ProofCompareGlobalModal
                            currentDirectoryNodeId={this.state.currentDirectoryNode.key}
                            show={this.state.showProofCompareModal}
                            onHide={() =>
                                this.setState({
                                    showProofCompareModal: false
                                })
                            }
                        />
                    )}
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    const { proofs } = state;
    return {
        proofs
    };
}

const connectedHomePage = connect(mapStateToProps)(TenantDashboard);
export { connectedHomePage as TenantDashboard };
