// import 'rc-tree/assets/index.css';
import React, { Component } from 'react';
import './NavTool.css';
import { utilService } from '../../../_helpers';
class NavTool extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    onItemClick(item) {
        this.props.onItemClick(item);
    }

    render() {
        return (
            <ul className="navtool__container">
                <div className="navtool__container">
                    <i className="fa fa-arrow-alt-circle-left" aria-hidden="true"></i>
                    <i className="fa fa-arrow-alt-circle-right" aria-hidden="true"></i>
                </div>
            </ul>
        );
    }
}

export { NavTool };
