export const videoAnnotationConstants = {
    TIME_MODE: {
        TIME: 'VIDEO_ANNOTATION_TIME_MODE_TIME',
        TIME_FRAME: 'VIDEO_ANNOTATION_TIME_MODE_TIME_FRAME',
        TIME_CODE: 'VIDEO_ANNOTATION_TIME_MODE_TIME_CODE'
    },
    COMMENT_STATUS: {
        'video-comment.status.none': 'None',
        'video-comment.status.accepted': 'Accepted',
        'video-comment.status.completed': 'Completed',
        'video-comment.status.cancelled': 'Cancelled',
        'video-comment.status.rejected': 'Rejected',
        // 'video-comment.status.resolved': 'Resolved',
        'video-comment.status.reopened': 'Reopened',
        filter: (status) => {
            let value =
                status &&
                status !== 'video-comment.status.none' &&
                status !== 'video-comment.status.reopened' &&
                status !== 'filter';
            return value;
        }
    } ,
    SEEKBAR_DIRECTION: {
        forward : 'forward',
        backward : 'backward',
    }  
};

export const initPlayerColors = {
    backgroundColor: '#f7f8f9',
    primaryColor: '#fff',
    primaryTextColor: '#66666a',
    primaryAccentColor: '#66bdff'
};

export const sidebarTabViewMode = {
    COMMENT: 'comment',
    TRANSCRITPION: 'transcription'
}

export const transcriptionStatus = {
    NOT_GENERATED: 'NotGenerated',
    COMPLETED: 'Completed',
    IN_PROGRESS: 'Inprogress',
    ERROR: "Error",
    LOADING : "Loading"
}