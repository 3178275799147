import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Spinner } from 'react-bootstrap';
import {
    ProgressBarComponent,
    FileUploader,
    fileUploadConstants,
    proofActions,
    s3InfoActions,
    alertActions
} from '..';
import { utilService } from '../../_helpers';
import { s3Service } from '../_shared';

class CreateProofVersionModal extends React.Component {
    constructor(props) {
        super(props);
        this.fileUploaderComponent = React.createRef();
        this.THIS_NO_FILE_PLACEHOLDER = '--| No File Selected |--';
        this.state = {
            progressNow: 0,
            completed: false,
            fileUploadPromise: null,
            fileName: this.THIS_NO_FILE_PLACEHOLDER,
            fileUploadTimeLeft: 0,
            fileUploadStatus: fileUploadConstants.NONE,
            file: null,
            allowedFileExtentions: [],
            proofVersionId: '',
            uploadPath: ``,
            uploadPathThumb: '',
            proofVersion: {}
        };
    }
    async componentDidMount() {
        var fileExtentions = await s3Service.getFileExtentions();
        this.setState({ allowedFileExtentions: fileExtentions });
    }
    onFileChange(file) {
        return new Promise((resolve) => {
            this.filUploadCancel(file);
            this.setState(
                {
                    progressNow: 0,
                    completed: false,
                    fileUploadStatus: file
                        ? fileUploadConstants.FILE_LOADED
                        : fileUploadConstants.NONE,
                    fileUploadPromise: null,
                    fileUploadTimeLeft: 0,
                    fileName: file ? file.name : this.THIS_NO_FILE_PLACEHOLDER,
                    file
                },
                () => {
                    resolve();
                }
            );
        });
    }
    onFileUploadInit(fileUploadPromise) {
        this.props.dispatch(alertActions.loading(true));
        this.setState({
            fileUploadPromise
        });
    }
    onFileUploadStarted() {
        // just return success promise as no work is done here;
        return Promise.resolve('success');
    }
    onProgressChange(obj) {
        console.log(obj);
        this.setState({
            progressNow: obj.progressNow,
            fileName: obj.fileName,
            fileUploadStatus: fileUploadConstants.STARTED,
            fileUploadTimeLeft: obj.stats.secondsLeft
        });
    }
    async onCreateProofVersion() {
        var s3TempInfoPayload = {
            name: this.state.file.name,
            proofId: this.props.proofObject.id
        };
        var s3TempInfoResponse = await this.props.dispatch(
            s3InfoActions.getS3TempInfo(s3TempInfoPayload)
        );
        await utilService.setStateAsync.bind(this)({
            proofVersionId: s3TempInfoResponse.proofVersionId
        });
        await utilService.setStateAsync.bind(this)({
            uploadPath: s3TempInfoResponse.uploadPath,
            uploadPathThumb: s3TempInfoResponse.uploadPathThumb
        });
        this.fileUploaderComponent.current.onFileUpload();
    }
    async AddProofVersion() {
        var proofVersionRequest = {
            proofId: this.props.proofObject.id,
            proofVersionId: this.state.proofVersionId,
            name: this.state.file.name
        };
        var proofObjResponse = await this.props.dispatch(
            proofActions.addPoofVersion(proofVersionRequest)
        );
        var proofVersionResponseObj = {
            id: this.props.proofObject.id,
            defaultVersionId: this.state.proofVersionId
        };
        await this.props.dispatch(proofActions.ingestProof(proofVersionResponseObj));
        await utilService.setStateAsync.bind(this)({
            proofVersion: proofObjResponse.proofVersion
        });
    }
    async onFileUploadCompleted(obj) {
        if (this.state.file) {
            var proofVersionResponseObj;
            var proofVersionRequest = {
                id: this.props.proofObject.id,
                versions: {
                    new: {
                        proofVersionId: this.state.proofVersionId,
                        name: this.state.file.name,
                        status: 1
                    }
                }
            };
            var ext = utilService.getFileExtention(this.state.file.name);
            // var documentRequest = {
            //     DocumentId: `${this.state.proofVersionId}.${ext}`,
            //     DocumentName: this.state.file.name,
            //     ObjectPath: `${this.state.uploadPath}`,
            //     tenantInfo: await tenantService.getCurrentTenantObject()
            // };
            // var thumbnailUploadRequest = {
            //     DocumentId: `${this.state.proofVersionId}.${ext}`,
            //     PageNumber: 1,
            //     Width: 260,
            //     Height: 150,
            //     ObjectPath: this.state.uploadPathThumb,
            //     tenantInfo: await tenantService.getCurrentTenantObject()
            // };
            this.props.dispatch(alertActions.loading(true));
            await utilService.setStateAsync.bind(this)({
                fileUploadStatus: fileUploadConstants.FINALIZING
            });
            //Order matters
            await this.AddProofVersion();
            // await s3Service.cacheDocumentFromS3(documentRequest);
            // await s3Service.uploadThumbnailToS3(thumbnailUploadRequest);

            await this.props.dispatch(proofActions.get(proofVersionRequest.id));
            await this.props.dispatch(proofActions.getProofVersions(proofVersionRequest.id));
            this.props.dispatch(alertActions.success('Saved !'));
            this.props.dispatch(alertActions.loading(false));
            await utilService.setStateAsync.bind(this)({
                progressNow: obj.progressNow,
                completed: obj.completed,
                fileUploadStatus: fileUploadConstants.COMPLETED
            });
            this.props.onFileUploadCompleted(this.state.proofVersion);
            this.onModalClose();
        }
    }
    onModalClose() {
        this.filUploadCancel(this.state.file);
        this.props.onHide();
    }
    filUploadCancel(file) {
        if (this.state.fileUploadPromise && !this.state.completed) {
            this.state.fileUploadPromise.cancel().then(() => {
                this.props.dispatch(alertActions.loading(false));
                this.setState({
                    progressNow: file ? 1 : 0,
                    completed: false,
                    fileUploadStatus: file
                        ? fileUploadConstants.INITIATED
                        : fileUploadConstants.NONE,
                    fileUploadPromise: null,
                    fileUploadTimeLeft: 0,
                    fileName: file ? file.name : null,
                    file
                });
            });
        }
    }
    renderTimeLeft() {
        var timeLeftInMinutes = (this.state.fileUploadTimeLeft / 60).toFixed(2);
        if (timeLeftInMinutes < 1)
            return <label className="ml-1">{this.state.fileUploadTimeLeft}s</label>;
        else return <label className="ml-1">{Math.ceil(timeLeftInMinutes)}min</label>;
    }
    renderFileActionText() {
        switch (this.state.fileUploadStatus) {
            case fileUploadConstants.NONE: {
                return (
                    <>
                        {/* <FontAwesomeIcon icon="cloud-upload-alt" size="lg" />
                        <label className="ml-1">Upload</label> */}
                    </>
                );
            }
            case fileUploadConstants.FILE_LOADED: {
                return (
                    <>
                        {/* <FontAwesomeIcon icon="cloud-upload-alt" size="lg" />
                        <label className="ml-2">Ready to Upload</label> */}
                    </>
                );
            }
            case fileUploadConstants.INITIATED: {
                return (
                    <>
                        {/* <label className="ml-1 file-output__list-item-status__label">Preparing File Upload</label> */}
                        <Spinner animation="grow" variant="dark" size="sm" />
                    </>
                );
            }
            case fileUploadConstants.STARTED: {
                return (
                    <>
                        {/* <FontAwesomeIcon icon="hourglass-half" size="lg" /> */}
                        <label className="ml-1 file-output__list-item-status__label">
                            Uploading
                        </label>
                        <Spinner animation="grow" variant="dark" size="sm" />
                    </>
                );
            }
            case fileUploadConstants.FINALIZING: {
                return (
                    <>
                        <label className="ml-1 file-output__list-item-status__label">
                            Finalizing Upload
                        </label>
                        <Spinner animation="grow" variant="dark" size="sm" />
                    </>
                );
            }
            case fileUploadConstants.COMPLETED: {
                return (
                    <>
                        <label className="mr-1 file-output__list-item-status__label">
                            File Uploaded
                        </label>
                        <i className="fa fa-check check" />
                    </>
                );
            }
            default:
                break;
        }
    }
    render() {
        return (
            <Modal
                //{...this.props}
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'}
                scrollable>
                <Modal.Header closeButton={false}>
                    <Modal.Title className="h5" id="contained-modal-title-vcenter">
                        Create New File Version
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <h5>{this.renderFileActionText()}</h5>
                    <hr /> */}
                    <FileUploader
                        ref={this.fileUploaderComponent}
                        onProgressChange={this.onProgressChange.bind(this)}
                        onFileUploadCompleted={this.onFileUploadCompleted.bind(this)}
                        onFileUploadInit={this.onFileUploadInit.bind(this)}
                        onFileUploadStarted={this.onFileUploadStarted.bind(this)}
                        onFileChange={this.onFileChange.bind(this)}
                        allowedFileExtentions={[...this.state.allowedFileExtentions]
                            .map((x) => `.${x}`)
                            .toString()}
                        s3TempInfo={this.props.s3TempInfo}
                        uploadPath={this.state.uploadPath}
                    />
                    <div className="file-details__container">
                        <span className="file-output__list-item-image">
                            <i className="fas fa-copy fa-3x" />
                        </span>
                        <div className="file-output__list-item-header">
                            <span className="file-output__list-item-name text-truncate">
                                {this.state.fileName}
                            </span>
                            <span className="file-output__list-item-status">
                                {this.renderFileActionText()}
                            </span>
                        </div>
                        <div className="file-output__list-item-middle-area">
                            <ProgressBarComponent progressNow={this.state.progressNow} />
                        </div>
                        {this.state.file && (
                            <div className="file-output__list-item-footer">
                                <div className="file-output__list-item-footer__size">
                                    <label className="">
                                        {(this.state.file.size / 1048576).toFixed(2)} mb
                                    </label>
                                </div>
                                {this.state.fileUploadStatus === fileUploadConstants.STARTED &&
                                    this.state.progressNow > 0 && (
                                        <div className="file-output__list-item-footer__time">
                                            {/* <FontAwesomeIcon icon="stopwatch" size="lg" /> */}
                                            {this.renderTimeLeft()}
                                        </div>
                                    )}
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant=""
                        disabled={
                            this.state.fileUploadStatus === fileUploadConstants.FILE_LOADED
                                ? false
                                : true
                        }
                        className="mrnda-btn btn-sm"
                        onClick={this.onCreateProofVersion.bind(this)}>
                        Upload & Save
                    </Button>
                    <Button
                        className="mrnda-btn--secondary btn-sm"
                        disabled={
                            this.state.fileUploadStatus === fileUploadConstants.STARTED ||
                                this.state.fileUploadStatus == fileUploadConstants.INITIATED ||
                                this.state.fileUploadStatus == fileUploadConstants.FINALIZING
                                ? true
                                : false
                        }
                        onClick={this.onModalClose.bind(this)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    const { s3Info } = state;
    return {
        s3TempInfo: s3Info.s3Info
    };
}

const connectedCreateProofModel = connect(mapStateToProps)(CreateProofVersionModal);
export { connectedCreateProofModel as CreateProofVersionModal };
