//@ts-check

import React from 'react';

const ChevronRightIcon = ({ height = 20, width = 20, color = 'currentColor', ...rest }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="20px/Interaction/Chevron/Medium/chevron--right" fill={color}>
                    <rect id="Rectangle" fillOpacity="0" x="0" y="0" width="20" height="20"></rect>
                    <g
                        id="Outline"
                        transform="translate(11.000000, 10.000000) rotate(-90.000000) translate(-11.000000, -10.000000) translate(4.500000, 6.500000)"
                        fillRule="nonzero">
                        <path
                            d="M0.146446609,0.146446609 C0.341708755,-0.0488155365 0.658291245,-0.0488155365 0.853553391,0.146446609 L6.5,5.793 L12.1464466,0.146446609 C12.320013,-0.0271197425 12.5894374,-0.0464048927 12.7843055,0.0885911588 L12.8535534,0.146446609 C13.0488155,0.341708755 13.0488155,0.658291245 12.8535534,0.853553391 L6.85355339,6.85355339 C6.65829124,7.04881554 6.34170876,7.04881554 6.14644661,6.85355339 L0.146446609,0.853553391 C-0.0488155365,0.658291245 -0.0488155365,0.341708755 0.146446609,0.146446609 Z"
                            id="Path-2"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ChevronRightIcon;
