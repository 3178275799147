import React, { useEffect, useState } from 'react';
// import './ProofVideoEditor.scss';

import { VideoAnnotationComment } from './Subs/VideoAnnotationComment';
import { VideoEditorToolbox } from './Subs/VideoEditorToolbox';
import { VideoPlayerWithCanvas } from './Subs/VideoPlayerWithCanvas';
import { VideoAnnotationProvider } from './Context/VideoAnnotationProvider';
import { withVideoAnnotationConsumer } from './Context/VideoAnnotationConsumer';
import { proofService } from '../proof.service';
import { videoCommentService } from './videoComment.service';
import { userService } from '../../User';
import { s3Service, evaporateS3Config, ConfirmationModal } from '../../_shared';
import { config, authHeader, utilService, history, store, contributorsHelper } from '../../../_helpers';
import { tenantService } from '../../Tenant';
import { Prompt } from 'react-router-dom';
import { alertActions } from '../../App';
import { videoTranscriptionService } from '../VideoTranscription';
import { connect } from 'react-redux';
import { ThemeProvider, useTheme } from '../../App/Theme/ThemeContext';

import { ThemeChanger } from '../../App/Theme/ThemeChanger';
import AnnotationCommentArrow from '../AnnotationCommnetArrow';

import { PROOF_OPEN_MODE } from '../proof.constants';
import { getMarkerPosition } from './videoAnnotationHelper';
import { commentStatusService } from '../../Settings/CustomStatus/services/commentStatus.service';
import { useScreenSizes } from '../../responsive/useScreenSizes';
import { conditionalClassName } from '../../responsive/responsiveUtils';
import { VideoEditorToolboxMobile } from './Subs/VideoEditorToolboxMobile';
import ProofEditor from '../ProofEditor';
import DesktopBreakpoint from '../../responsive/DesktopBreakpoint';
import PhoneBreakpoint from '../../responsive/PhoneBreakpoint';
import { WrappedInsidePrimaryNav } from '../../_shared/Nav/withPrimaryNav';
import { isCompactNav, isRegularNav } from '../../../_helpers/navbarUtil';
const ProofVideoEditor = (props) => {
    return (
        <VideoAnnotationProvider>
            <ProofVideoEditorWrapperHOC {...props}></ProofVideoEditorWrapperHOC>
        </VideoAnnotationProvider>
    );
};

export const RequestProofVideoEditor = (props) => {
    return (
        <VideoAnnotationProvider>
            <ProofVideoEditorWrapperHOC {...props} fromRequest = {true} ></ProofVideoEditorWrapperHOC>
        </VideoAnnotationProvider>
    );
};

const ProofVideoEditorWrapper = (props) => {
    const {alert} = props;
    const [showExitConfirmModal, setShowExitConfirmModal] = useState(false);
    const [nextLocation, setNextLocation] = useState({});
    const { theme, setTheme } = useTheme();
    const { isMobile } = useScreenSizes();
        
    let compactNavbar = alert.isHideTopbar !== true && isCompactNav();
     
    useEffect(() => {        
        let proofId = undefined;
        let versionId = undefined;
        let compareMode = false;
        
        if (props.match) {
            proofId = props.match.params.proofid;
            versionId = props.match.params.versionid;
        }
        else if (props.compareVersion) {
            compareMode = true;
            proofId = props.compareVersion.proofId;
            versionId = props.compareVersion.id;
            props.setIsProofCompare(true);
            //props.setEditorRef(editorArea.current, props.side);
            props.setHotKeyEnabled(props.isFocused);
        }
        else if (props.mode === PROOF_OPEN_MODE.Editor) {            
            //setEditorMode(true);
            proofId = props.proofVersion.proofId;
            versionId = props.proofVersion.id;              
        }

        if (!proofId || !versionId) {
            history.push('./dashboard/folders');
            return;
        }
        async function fetchInitData() {
            let proofVersions = await proofService.getProofVersions(proofId);
            if (!proofVersions) {
                history.push('./dashboard/folders');
                return;
            }
            var proofVersionsArr = Object.values(proofVersions);
            var latestVersion = proofVersionsArr.sort(
                (a, b) => b.versionNumber - a.versionNumber
            )[0];
            var isLatest = latestVersion.id === versionId;

            if (!isLatest) {
            }
            let proofVersion = { ...proofVersions[versionId] };

            if(compareMode)
            {
                proofVersion.isLocked = true;
            }
            props.setProofVersion(proofVersion);

            document.title = `${proofVersion.name} - Brandshare Collaboration`;

            if (/*proofVersion.isLocked*/ false) {
                window.onbeforeunload = undefined;
            } else {
                window.onbeforeunload = () => {
                    utilService.getIsEditorDirty()
                };
            }

            let { authData: currentUser } = await userService.getCurrentUser();
            const { fileMetadata } = proofVersion;

            var currentTenant = await tenantService.getCurrentTenantObject();

            var {statuses} = await commentStatusService.getAllStatus(false);

            props.setCommentStatues(statuses);
            var proofSettings = await tenantService.getProofSettings();
            props.setProofSettings(proofSettings);

            var s3InfoPayload = {
                id: proofVersion.id,
                proofId: proofVersion.proofId,
                name: proofVersion.name
            };
            let s3MainInfo = await s3Service.getS3MainInfo(s3InfoPayload);
            let evaporateConfig = {
                ...evaporateS3Config,
                bucket: s3MainInfo.bucket,
                awsRegion: s3MainInfo.region,
                aws_key: s3MainInfo.accessKey,
                s3Acceleration : s3MainInfo.transferAcceleration,
                signerUrl: `${config.apiUrl}/s3`,
                signHeaders: { ...authHeader() }
            };
            let videoMetadata = {
                ...fileMetadata,
                proofId: proofVersion.proofId,
                proofVersionId: proofVersion.id,
                currentUser,
                currentTenant,
                s3Config: evaporateConfig,
                videoHeight: fileMetadata.height,
                videoWidth: fileMetadata.width,
                mentionURL: history?.location?.state?.mentionURL
            };
            props.setVideoMetadata(videoMetadata);

            var commentsPayload = {
                proofId: proofVersion.proofId,
                proofVersionId: proofVersion.id
            };

            props.changeLiveUpdateTarget( proofId,versionId, currentTenant.id);

            var annotationComments = await videoCommentService.getVideoComments(commentsPayload);                    
            props.setAnnotationComments(annotationComments);
            var contributors = contributorsHelper.configureUserPreference(currentUser, proofVersion, props);
            await contributorsHelper.configureContributors(proofVersion, annotationComments, contributors ,currentUser.id, false ,props);

            var transcriptions = await videoTranscriptionService.getTranscribeText(
                proofId,
                versionId
            );            
            props.setTranscriptions(transcriptions);
        }
        fetchInitData().then(() => {
            console.log('FETCHED');
        });
        
        return () => {
            window.onbeforeunload = undefined;
        };
    }, []);


    useEffect(() => {
        if (isMobile) {
            props.dispatch(alertActions.hideTopbar(true, true));
        } else {
            let hideTopbar = props.fromRequest === true;   
            props.dispatch(alertActions.hideTopbar(hideTopbar, hideTopbar));
        }
    }, [isMobile, props.fromRequest]);
    
    useEffect(()=>{
        if(props.videoPlayer !== null){
            props.reScaleCanvasContainer();            
            if(props.isProofCompare === true) {
                updateMarkers();   
            }         
        }
        console.log({props});
    }, [props.showComments])

    useEffect(()=>{

        if(props.isProofCompare !== true) { return }
        
       
        props.setHotKeyEnabled(props.isFocused);
       
    }, [props.isFocused])

    useEffect (()=> {        

        console.log('some weirdo :' ,  compactNavbar);  
              
        var regularNav = isRegularNav();
        if (props.alert.isHideTopbar === true || regularNav !== true) {
            props.setHasExtraTopBar(false);
        }
        else {
            props.setHasExtraTopBar(true);
        }

    }, [props.alert.isHideTopbar]) 

    const updateMarkers = () => {
        var modifiedMarkers = props.markersRef.current.map((marker) => {
            var item = { ...marker };            
            var style = getMarkerPosition(
                marker.comment.frameRange,
                item.alignment,
                props.videoMetadata.frameCount
            );
            item.style = style;
            return item;
        });

        props.setMarkers(modifiedMarkers);
    };

    const shouldShowExitConfirmModal = (nextLocation) => {
        if (utilService.getIsEditorDirty()) {
            setNextLocation(nextLocation);
            setShowExitConfirmModal(true);
            store.dispatch(alertActions.warn('Changes are still saving...'));
            store.dispatch(alertActions.loading(true));
            return false;
        } else {
            return true;
        }
    };

    let heightStyle = {};
    if(alert.isHideTopbar){
        heightStyle = {
            top: 0,
            height: "calc(100vh - 22px)",
        }
    }

    let containerStyle = {};
    let editorBodyStyle = {};
    let parentStyle = {};

    if (props.isProofCompare) {
        heightStyle = {
            top: 0,
            height: '100%'
        };
        containerStyle = {
            flexDirection: props.side === 'left'
                ? 'row'
                : 'row-reverse'
        }

        editorBodyStyle = {
            flexDirection: props.side === 'left'
                ? 'row'
                : 'row-reverse'
        }
        parentStyle ={  height: '100%' }
    }
    
    const editorContainerClassName = conditionalClassName('mrnda-video-editor-container' , isMobile, 'mobile');
    return (
        <div  style={ isMobile ? {...parentStyle , height: '100vh'}:  parentStyle} className="mrnda-video-editor-body">            
            
            <AnnotationCommentArrow
                color={props.arrowColor}
                startPoints={props.arrowStartPoints}
                point2={props.arrowPoint2}
                disable={props.disableArrow}
            />
                                                     
            <Prompt
                when={utilService.getIsEditorDirty()}
                message={(nextLocation) => shouldShowExitConfirmModal(nextLocation)}
            />
            <div style={isMobile? {...heightStyle, ...containerStyle, height : '100%'} : {...heightStyle, ...containerStyle}} className={editorContainerClassName}>
                {
                    props.isProofCompare
                        ? props.showComments && <VideoAnnotationComment compactNavbar={compactNavbar} isMobile = {isMobile} isProofCompare={props.isProofCompare}  side= {props.side}/>
                        : <VideoAnnotationComment  compactNavbar = {compactNavbar} isMobile = {isMobile} isProofCompare={props.isProofCompare} />
                }

                <div
                    className={conditionalClassName('mrnda-video-editor-area', isMobile, 'mobile')}>
                    { !props.isProofCompare &&  
                        <>
                            <PhoneBreakpoint>
                                <VideoEditorToolboxMobileHOC
                                    selectedToolbarAction={props.selectedToolbarAction}
                                    setSelectedToolbarAction={props.setSelectedToolbarAction}
                                />
                            </PhoneBreakpoint>

                            <DesktopBreakpoint>
                                <div style={ compactNavbar === true?  {width: 'calc(100% + 350px)'} : {}}> <PageTitleBar compactNavbar = {compactNavbar} style = {{border :'1px solid red'}} /> </div>
                            </DesktopBreakpoint>
                        </>
                    }

                    <ProofEditor {...props} />
                </div>
            </div>
        </div>
    );
};

function PageTitleBar(props) {
    return (
        <>
        
            {props.compactNavbar === true ? (                
                <WrappedInsidePrimaryNav
                    WrappedComponent={VideoEditorToolbox}
                    showRightActions={true}
                    style={{ backgroundColor: 'var(--secondary-background-color)' }}
                    primaryActionsStyle={{ marginRight: '0' }}
                    pageActionsStyle={{ paddingLeft: '0px' }}
                    secondaryActionsStyle={{ width: '250px' }}
                    extraText=""
                />
            ) : (
                <VideoEditorToolbox />
            )}
        </>
    );
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const ProofVideoEditorWrapperHOC = connect(mapStateToProps)(withVideoAnnotationConsumer(ProofVideoEditorWrapper));
const VideoEditorToolboxMobileHOC = withVideoAnnotationConsumer(VideoEditorToolboxMobile);
export { ProofVideoEditor, ProofVideoEditorWrapperHOC as ProofVideoEditorWrapper };
