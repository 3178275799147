import React, { useEffect, useState } from 'react';
import { VideoPlayerWithCanvas } from './VideoAnnotation/Subs/VideoPlayerWithCanvas';
import { EditorToolbarActionNamesMobile } from './VideoAnnotation/Subs/EditorToolbarActionsMobile';
import ColorSelector from './Mobile/ColorSelector';
import ShapeSelector from './Mobile/ShapeSelector';
import { withVideoAnnotationConsumer } from './VideoAnnotation/Context/VideoAnnotationConsumer';
import { PROOF_TYPE } from './proof.constants';
import DocumentEditorBody from './DocumentAnnotation/DocumentEditorBody';
import { useScreenSizes } from '../responsive/useScreenSizes';
import { withDocumentAnnotationConsumer } from './DocumentAnnotation/Context/DocumentAnnotationConsumer';
import { utilService } from '../../_helpers';
import { proofService } from './proof.service';
import { drawingConstants } from '@wunderman-thompson/miranda-drawing';
import { getColorPropName } from './drawingHelper';
import { ProofNotSupported } from './DocumentAnnotation/ProofNotSupported';
import { IOSCanvasAreaLimit, proofUtils } from '../../_helpers/proofUtils';


const ProofEditor = ({
    onLoadedCompareVideoData,
    seekToTimeCompare,
    compareVolume,
    saveCompareVolume,
    selectedToolbarAction,
    shouldShowThumbnail,
    editorBodyStyle,
    pages,
    isProofCompare,
    proofVersion,
    editorArea,
    offsetHorizontalScrollbar,
    onEditorScrolled,
    pageCount,
    pagesContainer,
    loadablePages,
    hideAnnotations,
    isOverlayCompare,
    compareCanvas,
    isAutoCompare,
    side,
    setCompareDocumentViewer,
    onPlayVideoCompare,
    selectedCanvas,
    userAnnotationColor,
    userId,
    editorType,
    ...props
}) => {
    const [currentColor, setCurrentColor] = useState(userAnnotationColor);
    const { isMobile } = useScreenSizes();
    const [shapeColor, setShapeColor] = useState('');
    

    useEffect(()=> {

        if  ( proofVersion === undefined || proofVersion === null || Object.keys(proofVersion).length === 0) {
            return;
        }

        let isCanvasLimitReached = proofUtils.checkCanvasRenderLimit(proofVersion);
        props.setCanvasLimitExceeded(isCanvasLimitReached);

    }, [proofVersion])

    const onCompareColorChange = async ({ key, value: color }) => {
        var request = {
            proofId: proofVersion.proofId,
            Preference: {
                userID: userId,
                color: key,
                userInitial: utilService.getUserInitial(props.userFullName)
            }
        };

        let response = await proofService.updateAnnotationColorPreference(request);

        var newContributors = props.contributors.map((e, i) => {
            let newItem = { ...e };
            i === 0 && (newItem.color = color);
            return newItem;
        });

        props.setContributors(newContributors);
        props.setUserAnnotationColor(color);
        props.setSelectedToolbarAction('None');
    };
    
    const onShapeColorChange = (color) => {
        setShapeColor(color.value);
        console.log('shape color has been changed to ' + color.value);  
        console.log(props);      
        var updates = [
            {   
                            
                prop: getColorPropName(props.currentAnnotationOnCanvas),
                value: color.value
            }
        ];
    

        if (props.currentAnnotationOnCanvas.type === drawingConstants.ANNOTATION_TYPE.textbox.typeName) {
            updates.push({
                prop: 'fill',
                value: color.value
            });
        } else if (
            props.currentAnnotationOnCanvas.type === drawingConstants.ANNOTATION_TYPE.pinPointer.typeName
        ) {
            updates.push({
                prop: 'fill',
                value: color.value
            });
        }

        props.updateCurrentAnnotationOnCanvas(updates);
        props.setArrowColor(color.value);
        props.setSelectedToolbarAction('None');
    };

    
    const colorSelectorColor = props.showShapeEditingTools === true ?  'black': userAnnotationColor

    const renderMobileEditor = () => {
        console.log('render mobile actions called : ', selectedToolbarAction);
        switch (selectedToolbarAction) {
            case EditorToolbarActionNamesMobile.color:
                return (
                    <ColorSelector
                        userAnnotationColor={colorSelectorColor}
                        onSelectedColorChanged={props.showShapeEditingTools === true && props.selectedComment !== -1 ? onShapeColorChange : onCompareColorChange }
                    />
                );
            case EditorToolbarActionNamesMobile.comment:
                //TODO : [HIGH] Change this whole approach, there is a huge issue in performance
                const ShapeSelectorHOC =
                    utilService.isVideoProof(proofVersion) === true
                        ? withVideoAnnotationConsumer(ShapeSelector)
                        : withDocumentAnnotationConsumer(ShapeSelector);

                return <ShapeSelectorHOC />;
        }
    };
    const isVideo = () => proofVersion.mediaType === PROOF_TYPE.Video || PROOF_TYPE.HTMLDynamic;
    const canvasParentStyle = { height: '100%' };
    const canvasParentStyleMobile = { height: '100%', display: 'flex', flexDirection: 'column' };

    return (
        <div
            style={{
                flex : 1,
                height: isMobile ? 'calc(100% - 77px)' : 'calc(100% - 85px)',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column-reverse'
            }}>
            <div style={isMobile ? canvasParentStyleMobile : canvasParentStyleMobile}>
                {proofVersion.mediaType === PROOF_TYPE.Video || proofVersion.mediaType === PROOF_TYPE.HTMLDynamic ? (
                    <VideoPlayerWithCanvas
                        {...{
                            compareCanvas: compareCanvas,
                            isAutoCompare: isAutoCompare,
                            isProofCompare: isProofCompare,
                            onLoadedCompareVideoData: onLoadedCompareVideoData,
                            side: side,
                            onPlayVideoCompare: onPlayVideoCompare,
                            seekToTimeCompare: seekToTimeCompare,
                            compareVolume: compareVolume,
                            saveCompareVolume: saveCompareVolume,
                            isMobile: isMobile
                        }}
                    />
                ) : (
                    <DocumentEditorBody
                        {...{
                            shouldShowThumbnail,
                            editorBodyStyle,
                            pages,
                            isProofCompare,
                            proofVersion,
                            editorArea,
                            offsetHorizontalScrollbar,
                            onEditorScrolled,
                            pageCount,
                            pagesContainer,
                            loadablePages,
                            hideAnnotations,
                            isOverlayCompare,
                            compareCanvas,
                            isAutoCompare,
                            setCompareDocumentViewer,
                            side,
                            isMobile,
                            selectedCanvas,
                            editorType,
                            screenshotRequestProcessingRef: props.screenshotRequestProcessingRef
                        }}
                    />
                )}
            </div>

            {isMobile === true && selectedToolbarAction !== 'None' && (
                <div className="mrnda-video-editor-area-selection--mobile">
                    {renderMobileEditor()}
                </div>
            )}
            {props.canvasLimitExceeded === true && (
                <div className="mrnda-video-editor-area-selection--mobile">
                    <ProofNotSupported primaryText={"Cannot Render the file"}  secondaryText={"The file dimension is greater than maximum allowed size on your device"}
                        additionalTexts={[`Allowed size : ${IOSCanvasAreaLimit} Pixels`, `Proof size: ${proofUtils.getTotalPixels(proofVersion)} Pixels`]}
                    />
                </div>
            )}
        </div>
    );
};

export default ProofEditor;
