//@ts-check
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
const CreateProofHTMLDynamicGuide = ({ onClose }) => {
    return (
        <Modal
            show={true}
            onHide={onClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={'static'}
            scrollable>
            <Modal.Header closeButton={true}>
                <Modal.Title className="h5" id="contained-modal-title-vcenter">
                    HTML Dynamic Guide
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                className={'p-0'}
                style={{ backgroundColor: 'var(--primary-background-color)' }}>
                <div
                    style={{
                        height: 500,
                        display: 'flex'
                    }}
                    className="padding-2x">
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: '14px'
                        }}
                        className="margin-1x--right">
                        <p
                            style={{
                                marginBottom: '20px'
                            }}>
                            {`The zip package must be 1 level deep. For example, if you unzip the package folder, there should be 1 parent folder like shown on the image.`}
                            <br />
                            <br />
                            {`All project files and folders should be inside that parent folder along with the Entry File (i.e. index.html) which opens the folder in the browser.`}
                        </p>
                        <p style={{}}>
                            {`The Entry File can be any name. Just make sure to enter the name in the form. 
                            Also make sure, if this file is opened on the browser, the project outputs the expected preview.`}
                        </p>
                    </div>
                    <div
                        style={{
                            flex: 1,
                            display: 'flex'
                        }}>
                        <img
                            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                            src="../../assets/Images/html-dynamic-guide.png"
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer
                style={{
                    borderTop: '1px solid var(--border-color)',
                    backgroundColor: 'var(--primary-background-color)'
                }}>
                <Button className="mrnda-btn--secondary btn-sm" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateProofHTMLDynamicGuide;
