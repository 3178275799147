import React, { useState } from 'react';
import cx from 'classnames';
//const colors = ['#ff0000','#B97A95', '#DE8971', '#A1DD70',,'#4AA96C' ,'#3EDBF0', '#185ADB','#4B778D', '#A239EA','#7868E6' ,'#FFD66B', '#ff9b00'];
import { AnnotationColors } from '../../_helpers';
import { useScreenSizes } from '../../components/responsive/useScreenSizes';

const ContributorColorPicker = ({colors = AnnotationColors, selectedColor, onSelectedColorChanged }) => {
    
    const { isMobile } = useScreenSizes();
    return (
        <div className= {cx("contributor-color-picker", {"contributor-color-picker--mobile" : isMobile})}>
            {colors.map((color, index) => {
                return (
                    <div
                        key={index}
                        className={cx({
                            'contributor-color-picker__color-outer': true,
                            'contributor-color-picker__color-outer--mobile': isMobile,
                            selected: color === selectedColor
                        })}
                        onClick={() => onSelectedColorChanged(color)}>
                        <div
                            className="contributor-color-picker__color-inner"
                            style={{ backgroundColor: color.value }}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default ContributorColorPicker;
