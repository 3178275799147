import {
    authHeader,
    config,
    handleResponse
} from '../../../../_helpers';

export const workflowService = {        
    getAll,
    addWorkflow,  
    updateStatusVisibility,
    update,
    canDelete,
    deleteWorkflow  
};


async function getAll(showAllStatus,filterEmpty) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${config.apiUrl}/workflow/getAll?showAllStatus=${showAllStatus}&filterEmpty=${filterEmpty}`, requestOptions).then(handleResponse);
}


async function addWorkflow(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/workflow/addWorkflow`, requestOptions).then(handleResponse);
}

async function updateStatusVisibility(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/workflow/updateStatusVisibility`, requestOptions).then(handleResponse);
}


async function update(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/workflow/update`, requestOptions).then(handleResponse);
}

async function canDelete(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/workflow/canDelete`, requestOptions).then(handleResponse);
}

async function deleteWorkflow(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };
    return fetch(`${config.apiUrl}/workflow/delete`, requestOptions).then(handleResponse);
}