import React from 'react';
import { Link } from 'react-router-dom';
import BrandIcon from '../_shared/Icon/BrandIcon';
import { connect } from 'react-redux';

export function NavPrimary(props) {
    return (
        <div
            style={props.customStyles}
            className={`mrnda-primary-navigation__item--logo  ${props.alert.isHideTopbar ? 'd-none' : ''}`}>
            <Link
                to="/"
                className="navbar-brand"
                title="Brandshare Collaboration"
                onClick={(e) => {

                    if (props.alert.isNavActionsDisabled === true) {
                        e.preventDefault();
                    }
                }}>
                {
                    props.compactNavbar === true
                        ? <div style={{ display : 'flex', color:'var(--primary-text-color)'}}>
                            <BrandIcon  />
                            <div style={{  marginLeft: '4px', fontSize : '18px'}}>                                
                                {props.extraText === "" ? "Annotate" : `Annotation ${props.extraText}`}
                            </div>
                        </div>
                        : <img src="../../assets/Images/brand_logo_all_white.png"></img>
                }

            </Link>

        </div>
    );
}

function mapStateToProps(state) {
    const { alert, authentication } = state;
    return {
        alert,
        authentication: authentication.user ? authentication.user.authData : {}
    };
}
var NavPrimaryActions = connect(mapStateToProps)(NavPrimary);
export {  NavPrimaryActions};
