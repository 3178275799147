import React from 'react';
import {
    VideoAnnotationContext,
    VideoCompareContext,
    VideoTranscriptionContext
} from './VideoAnnotationContext';

// function VideoAnnotationConsumer({ children }) {
//     return (
//         <VideoAnnotationContext.Consumer>
//             {(context) => {
//                 if (context === undefined) {
//                     throw new Error('Context is undefined');
//                 }
//                 return children(context);
//             }}
//         </VideoAnnotationContext.Consumer>
//     );
// }
// class VideoAnnotationConsumer extends React.Component {
//     render() {
//         const { children } = this.props;

//         return (
//             <VideoAnnotationContext.Consumer>
//                 {(context) => children(context)}
//             </VideoAnnotationContext.Consumer>
//         );
//     }
// }

const withVideoAnnotationConsumer = (Component) => {
    return function ContextualComponent(props) {
        return (
            <VideoAnnotationContext.Consumer>
                {(consumer) => <Component {...consumer} {...props} />}
            </VideoAnnotationContext.Consumer>
        );
    };
};

const withVideoCompareConsumer = (Component) => {
    return function ContextualComponent(props) {
        return (
            <VideoCompareContext.Consumer>
                {(consumer) => <Component {...consumer} {...props} />}
            </VideoCompareContext.Consumer>
        );
    };
};

const withVideoTranscriptionConsumer = (Component) => {
    return function ContextualComponent(props) {
        return (
            <VideoTranscriptionContext.Consumer>
                {(consumer) => <Component {...consumer} {...props} />}
            </VideoTranscriptionContext.Consumer>
        );
    };
};

export { withVideoAnnotationConsumer, withVideoCompareConsumer, withVideoTranscriptionConsumer };
