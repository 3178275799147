import React from 'react';
import { useScreenSizes } from '../../../../responsive/useScreenSizes';
import { secondarySmallTextStyle } from '../../../../_shared/Styles/TextStyles';
import DesktopBreakpoint from '../../../../responsive/DesktopBreakpoint';

const AttachmentItem = ({
    attachment,
    onAttachmentDelete,
    onAttachmentClicked,
    attachmentFor,
    showDelete
}) => {
    const { isMobile } = useScreenSizes();
    return (
        <>
            {attachment.isActive && (
                <div className="attachment">
                    <div className="attachment__body" style={ isMobile?  {  paddingLeft : '0' , padding : '4px' } : {}}>
                        <span style={isMobile? secondarySmallTextStyle : {}} className="attachment__icon">
                            <i className="fas fa-paperclip" />
                        </span>

                        <span
                            className="attachment__text text-truncate"
                            title={attachment.name}
                            style={isMobile? secondarySmallTextStyle : {}}
                            onClick={() => {
                                onAttachmentClicked(attachment, attachmentFor);
                            }}>
                            {attachment.name}
                        </span>
                        {showDelete && (
                            <DesktopBreakpoint>
                                <div>
                                    <span
                                        className="attachment-delete"
                                        onClick={() => {
                                            onAttachmentDelete(attachment);
                                        }}>
                                        <i className="far fa-trash-alt" />
                                    </span>
                                </div>
                            </DesktopBreakpoint>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default AttachmentItem;
