import chroma from 'chroma-js';

const changeOpacity = (hexColor, weight) => {    
    return chroma(hexColor).alpha(weight);
};
const changeBrightness = (hexColor, weight) => {
    return chroma(hexColor).brighten(weight);
};


const changeDarkness = (hexColor, weight) => {
    return chroma(hexColor).desaturate(weight);
};

const darken = (hexColor, weight = 1) => {
    return chroma(hexColor).darken(weight);
};

export const colorUtilService = {
    changeOpacity,
    changeBrightness,
    changeDarkness,
    darken   
};
