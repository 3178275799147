import React, { useRef } from 'react';
import { useOutsideClickHandler } from '../../../../../_helpers/outsideClickHandler';

const CommentListWrapper = ({ children, mainDrawingCanvas, setSelectedComment, setDisableArrow, isVideo, clearCommentSelection, selectedCanvas  }) => {
    const wrapperRef = useRef(null);

    const onClickedOutside = () => {
        clearCommentSelection(selectedCanvas)
    };

    const getTargetsVideo = () => {
        var canvases = [];        
        var upperCanvas = mainDrawingCanvas?.fabricCanvas.upperCanvasEl;
        canvases.push(upperCanvas);
        var zoomTools = document.getElementById('toolbox-zoom-tools');
        var shapeTools = document.getElementById('toolbox-shape-tools');  
        var skips = document.getElementsByClassName('skip-comment-click-outside');
        return [wrapperRef.current, ...canvases, zoomTools, shapeTools, ...skips];
    };

    const getTargets = () => {
        var canvases = [];

        mainDrawingCanvas.forEach((element) => {
            var upperCanvas = element?.fabricCanvas.upperCanvasEl;
            canvases.push(upperCanvas);
        });
        
        var zoomTools = document.getElementById('toolbox-zoom-tools');
        var shapeTools = document.getElementById('toolbox-shape-tools');   
        var mobileToolbox = document.getElementById('editor-toolbar-actions-mobile');        
        var skips = document.getElementsByClassName('skip-comment-click-outside');
             
        return [wrapperRef.current, ...canvases, zoomTools, shapeTools, ...skips];
    };
    
    useOutsideClickHandler(isVideo === true? getTargetsVideo() : getTargets(), onClickedOutside, ['skip-comment-click-outside']);

    return <div ref={wrapperRef}>
       
        
        {children}
        
        
        </div>;
};

export { CommentListWrapper };
