import { authorizationService } from '.';
import { authorizationConstants } from '../components';
import { store } from './';

export const commentHelper = {
    canDelete,
    quickSort,
    sortPositionally,
    sortByTime,
    showEdit,
    canModifyAnnotation
};

function canDelete(createdById) {
    const state = store.getState();
    const { authentication } = state;
    var userId = authentication.user.authData.id;

    let isUserPermittedToDelete = authorizationService.isUserPermitted(
        authorizationConstants.ProofEditor.KEY,
        authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_COMMENT_DELETE
    );

    let isUserPermittedToDeleteAny = authorizationService.isUserPermitted(
        authorizationConstants.ProofEditor.KEY,
        authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_COMMENT_DELETE_ANY
    );

    var canDelete = isUserPermittedToDeleteAny
        ? true
        : isUserPermittedToDelete && userId === createdById
        ? true
        : false;

    return canDelete;
}

function canModifyAnnotation(createdById) {
    const state = store.getState();
    const { authentication } = state;
    var userId = authentication.user.authData.id;
    
    let isUserPermittedToModifyAny = authorizationService.isUserPermitted(
        authorizationConstants.ProofEditor.KEY,
        authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_ANNOTATION_MODIFY_ANY
    );

    

    var canModify = isUserPermittedToModifyAny
        ? true
        : userId === createdById
        ? true
        : false;

    return canModify;
}

function showEdit(createdById) {
    const state = store.getState();
    const { authentication } = state;
    var userId = authentication.user.authData.id;

    
    return userId === createdById;
}

function swap(items, leftIndex, rightIndex) {
    var temp = items[leftIndex];
    items[leftIndex] = items[rightIndex];
    items[rightIndex] = temp;
}

function getPosition(item) {
    var { top, left } = JSON.parse(item.annotations[0].annotationObject);
    return { topPosition: top, leftPosition: left};
}

function getPage(item) {
    var { page } = item;
    return { page: page };
}

function getTime(item) {
    var { createdAt } = item.annotations[0];
    var converted = new Date(createdAt);
    return { createdAt: converted };
}

function partition(items, left, right, pivotFunc, func1, func2) {
    var itemIndex = Math.floor((right + left) / 2);
    var pivot = pivotFunc(items[itemIndex]),
        i = left, //left pointer
        j = right; //right pointer

    while (i <= j) {
        while (func1(items[i], pivot)) {
            i++;
        }
        while (func2(items[j], pivot)) {
            j--;
        }

        if (i <= j) {
            swap(items, i, j); //swap two elements
            i++;
            j--;
        }
    }
    return i;
}

function quickSort(items, left, right, pivotFunc, func1, func2) {
    var index;
    if (items.length > 1) {
        index = partition(items, left, right, pivotFunc, func1, func2); //index returned from partition
        if (left < index - 1) {
            //more elements on the left side of the pivot
            quickSort(items, left, index - 1, pivotFunc, func1, func2);
        }
        if (index < right) {
            //more elements on the right side of the pivot
            quickSort(items, index, right, pivotFunc, func1, func2);
        }
    }
    return items;
}

function sortByTime(items) {
    var sortedItems = quickSort(
        items,
        0,
        items.length - 1,
        (item) => getTime(item),
        (item, pivot) => getTime(item).createdAt < pivot.createdAt,
        (item, pivot) => getTime(item).createdAt > pivot.createdAt
    );
    return sortedItems;
}

function sortPositionally(items, pageCount , isMulti = false) {        
    var sortedItems = [];

    if( !isMulti) 
    {
        sortedItems = positionalSort(items);
        return sortedItems;
    }


    var itemsStaggered = [];
    
    for (var i = 1; i <= pageCount; i++) {            
        var pages = items.filter(x => x.page === i);
        itemsStaggered.push(pages);
    }

    itemsStaggered.forEach( (element) => {           
        var sorted = positionalSort(element)
        sortedItems.push(...sorted);
    });

    return sortedItems;
}

function positionalSort(items) {
    var sorted = quickSort(
        items,
        0,
        items.length - 1,
        (item) => getPosition(item),
        (item, pivot) =>
            getPosition(item).topPosition < pivot.topPosition,
        (item, pivot) =>
            getPosition(item).topPosition > pivot.topPosition
    );

    return sorted;
}