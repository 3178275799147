import { store } from '../../../_helpers';
import { alertActions } from '../../App';

function readableTime(t) {
    var theMinutes = '0' + Math.floor(t / 60); // Divide seconds to get minutes, add leading zero
    var theSeconds = '0' + parseInt(t % 60); // Get remainder seconds
    var theTime = theMinutes.slice(-2) + ':' + theSeconds.slice(-2); // Slice to two spots to remove excess zeros
    return theTime;
}

function getReadableTimeRange(value, frameRate) {
    var startTime = value / frameRate;
    return readableTime(startTime);
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}



function getProgressMarkerPosition(frameNumber, frameCount) {
    var progressBarDom = document.getElementById('mrnda-video-editor-area-controls-seekbar');
    if (progressBarDom === null || progressBarDom === undefined) {  return 0 };
    var position = (frameNumber / frameCount) * progressBarDom.offsetWidth;
    return position;
}

function toTime(frames, frameRate, currentTime) {
    var time = typeof frames !== 'number' ? currentTime : frames,
        frameRate = frameRate;
    var dt = new Date(),
        format = 'hh:mm:ss' + (typeof frames === 'number' ? ':ff' : '');
    dt.setHours(0);
    dt.setMinutes(0);
    dt.setSeconds(0);
    dt.setMilliseconds(time * 1000);
    function wrap(n) {
        return n < 10 ? '0' + n : n;
    }
    return format.replace(/hh|mm|ss|ff/g, function (format) {
        switch (format) {
            case 'hh':
                return wrap(dt.getHours() < 13 ? dt.getHours() : dt.getHours() - 12);
            case 'mm':
                return wrap(dt.getMinutes());
            case 'ss':
                return wrap(dt.getSeconds());
            case 'ff':
                return wrap(Math.floor((time % 1) * frameRate));
        }
    });
}

function frameToTimeCode(frame, frameRate, currentTime) {
    if (!frame) {
        return toTime(frame, frameRate, currentTime);
    }
    var frameNumber = Number(frame);
    var fps = frameRate;
    function wrap(n) {
        return n < 10 ? '0' + n : n;
    }
    var _hour = fps * 60 * 60,
        _minute = fps * 60;
    var _hours = (frameNumber / _hour).toFixed(0);
    var _minutes = Number((frameNumber / _minute).toString().split('.')[0]) % 60;
    var _seconds = Number((frameNumber / fps).toString().split('.')[0]) % 60;
    var SMPTE_HOURS = wrap(_hours) === '00' ? '' : wrap(_hours) + ':';
    var SMPTE =
        SMPTE_HOURS +
        wrap(_minutes) +
        ':' +
        wrap(_seconds) +
        '.' +
        wrap(Math.round(frameNumber % fps));
    return SMPTE;
}

function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    // return { width: srcWidth*ratio, height: srcHeight*ratio };
    return ratio;
}

function trimAnnotationObject(annotationObject) {
    delete annotationObject.uuid;
    delete annotationObject.uuid_parent;
    delete annotationObject.id;
    delete annotationObject.commentId;
    delete annotationObject.action;
    delete annotationObject.filters;
}

function switchVideoEditorDirty(value) {
    if (value) {
        store.dispatch(alertActions.loading(true));
        store.dispatch(alertActions.makeProofEditorDirty(true));
        window.onbeforeunload = () => true;
    } else {
        store.dispatch(alertActions.loading(false));
        store.dispatch(alertActions.makeProofEditorDirty(false));
        window.onbeforeunload = undefined;
    }
}

function getVideoCurrentTimers(currentTimeInSeconds, frameRate) {
    var cfn = Math.round(currentTimeInSeconds * frameRate);
    cfn = cfn ? cfn : 0;

    var ctt = readableTime(currentTimeInSeconds);
    ctt = ctt ? ctt : '00:00:00';

    var ctc = frameToTimeCode(cfn, frameRate, currentTimeInSeconds);
    return { currentFrameNumber: cfn, currentTimeInText: ctt, currentTimeInTimeCode: ctc };
}

function getVideoTimers(durationInSeconds, frameRate) {
    let frameCount = Math.round(frameRate * durationInSeconds);
    let durationInTimeCode = frameToTimeCode(frameCount, frameRate, durationInSeconds);
    let durationInText = readableTime(durationInSeconds);
    return { frameCount, durationInText, durationInTimeCode };
}

export {
    readableTime,
    getReadableTimeRange,
    getProgressMarkerPosition,
    uuidv4,
    frameToTimeCode,
    calculateAspectRatioFit,
    trimAnnotationObject,
    switchVideoEditorDirty,
    getVideoCurrentTimers,
    getVideoTimers
};
