import React from 'react';
import { secondaryBaseTextStyle, primaryBaseTextStyle } from '../../_shared/Styles/TextStyles';

export function HtmlLiveNotSupported() {
    const baseStyle = {
        maxWidth : '210px',
        lineHeight : '18px'
    }
    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                position: 'absolute',
                backgroundColor: 'var(--primary-background-color)',
                display: 'flex',
                flexDirection: "column" , 
                alignContent: 'center' ,
                justifyContent : 'center',
                alignItems: 'center'
            }}>
            <span style={{ ...baseStyle, ...primaryBaseTextStyle, textAlign: 'center', fontWeight: '600'}}>
                Live HTML annotations are not supported on mobile devices
            </span>
            <span style={{ marginTop: '12px', ...baseStyle,...secondaryBaseTextStyle}}>Please visit this page on a PC</span>
        </div>
    );
}
