import { store } from '../../../_helpers';
import { alertActions } from '../../App';
import scrollIntoView from 'scroll-into-view-if-needed';
import scrollIntoViewSmooth from 'smooth-scroll-into-view-if-needed';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    // return { width: srcWidth*ratio, height: srcHeight*ratio };
    return ratio;
}

function trimAnnotationObject(annotationObject) {
    delete annotationObject.uuid;
    delete annotationObject.uuid_parent;
    delete annotationObject.id;
    delete annotationObject.commentId;
    delete annotationObject.action;
    delete annotationObject.filters;
}

function switchDocumentEditorDirty(value) {
    if (value) {
        store.dispatch(alertActions.loading(true));
        store.dispatch(alertActions.makeProofEditorDirty(true));
        window.onbeforeunload = () => true;
    } else {
        store.dispatch(alertActions.loading(false));
        store.dispatch(alertActions.makeProofEditorDirty(false));
        window.onbeforeunload = undefined;
    }
}

function convertZoomToScale(zoom) {
    var scale = zoom / 100; 
    return scale;
}

function calculateNewZoom(available, current, currentZoom) {
    if (currentZoom === undefined) {
        currentZoom = 100;
    }
    current = current * convertZoomToScale(currentZoom);

    if (available === current) {
        return undefined;
    } else {
        return (currentZoom / current) * available;
    }
}

function bringToView(element, marginTop) {
    scrollIntoView(element, {
        behavior: (actions) =>
            actions.forEach(({ el, top, left }) => {
                el.scrollTop = top - marginTop;
                el.scrollLeft = left;
            }),
        scrollMode: 'if-needed',
        block: 'start'
    });
}

function bringToViewSmooth(element) {
    console.log("bringToView smooth");
    scrollIntoViewSmooth(element, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
        block: 'start',
        duration: 150
    });
}

function bringToViewSmoothPoint(point, parent) {    
    
    

    


    // scrollIntoViewSmooth(ele, {
    //     behavior: 'smooth',
    //     scrollMode: 'if-needed',
    //     block: 'start',
    //     duration: 150
    // });
}

export {
    uuidv4,
    calculateAspectRatioFit,
    trimAnnotationObject,
    switchDocumentEditorDirty,
    convertZoomToScale,
    calculateNewZoom,
    bringToView,
    bringToViewSmooth,
    bringToViewSmoothPoint
};
