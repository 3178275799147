import React from 'react';
import { StatusItem } from './StatusItem';

import { DndContext } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { arrayMove } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import StatusHeader from './StatusHeader';
import WorkflowRow from './WorkflowRow';
import { withWorkflowManageConsumer } from './Context/WorkflowManageProvider';

function DropIndicator({ isDragging }) {
    if (!isDragging) {
        return null;
    }

    return <li className="drop-indicator" />;
}

function WorkflowList({
    items,
    setItems,
    exitEditMode,
    editingComment,
    setEditingComment,
    onCheckChanged,
    enableDragging,
    updateStatusPriority,
    visibleCommentStatuses,
    workflowStatuses,
    membersData
}) {
    //const [sortedItems, setSortedItems] = React.useState(items);
    const [activeId, setActiveId] = React.useState(null);

    function handleDragStart(event) {
        const { active } = event;
        setActiveId(active.id);
    }

    function handleDragEnd(event) {
        const { active, over } = event;
        setActiveId(null);
        if (active && over && active.id !== over.id) {
            var updatedItems;
            setItems((items) => {
                const oldIndex = items.findIndex((x) => x.id === active.id);
                const newIndex = items.findIndex((x) => x.id === over.id);
                updatedItems = arrayMove(items, oldIndex, newIndex);
                return updatedItems;
            });
            if (updatedItems) {
                var prioritized = updatedItems.map((item, index) => {
                    var changedItem = { ...item };
                    changedItem.priority = index + 1;
                    return changedItem;
                });
                updateStatusPriority(prioritized);
            }
        }
    }

    return (
        <> 
            <ul className="sortable-list" style={{paddingRight: '16px' , marginBottom: "-12px"}}>
                {/* {JSON.stringify(items[0])} */}
                {items.map((workflow, index) => (
                    <React.Fragment key={workflow.id}>                       
                        <WorkflowRowHOC
                            {...{
                                id: workflow.id,
                                itemText: `Item ${workflow.id}`,
                                workflow,
                                exitEditMode,
                                editingComment,
                                setEditingComment,
                                onCheckChanged,
                                index,
                                enableDragging,
                                workflowStatuses,
                                membersData
                            }}
                        />
                        <DropIndicator isDragging={activeId !== null} />
                    </React.Fragment>
                ))}
            </ul>
        </>
    );
}
const WorkflowRowHOC = withWorkflowManageConsumer(WorkflowRow);

export default WorkflowList;
