import { combineReducers } from 'redux';
import {
    proofData,
    proofsByDirectory,
    proofsFilters,
    proofVersions,
    tenants,
    usersData,
    usersFilters,
    groupsData,
    groupsFilters,
    rolesData,
    rolesFilters,
    registration,
    authentication,
    s3Info,
    alert,
    alertStatuses,
    directoriesData,
    proofVersionDownload,
    proofSummaryData,
    annotationReportData,
    videoProofTranscribeData,
    providersData,
} from '../components';
const rootReducer = combineReducers({
    authentication,
    registration,
    usersData,
    usersFilters,
    groupsData,
    groupsFilters,
    rolesData,
    rolesFilters,
    alert,
    alertStatuses,
    tenants,
    proofData,
    proofsByDirectory,
    proofsFilters,
    proofVersions,
    s3Info,
    directoriesData,
    proofVersionDownload,
    proofSummaryData,
    videoProofSummaryData: annotationReportData,
    videoProofTranscribeData,
    providersData
});

export default rootReducer;
