import React from 'react';
import { EditorToolbarActionButton } from './EditorToolbarActionButton';
import { EditorToolbarActionNamesMobile } from './EditorToolbarActionsMobile';
import ColorIcon from '../../../_shared/Icon/ColorIcon';
export const EditorToolbarColorBubble = (props) => {    
    return (        
        <EditorToolbarActionButton
            isDisabled = {props.isDisabled}
            handleClicked={props.handleColorPickerClicked}
            isPressed={props.selectedToolbarAction === EditorToolbarActionNamesMobile.color}
            icon={<ColorIcon color={props.selectedColor} />}
            
        />
    );
};
