import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { tenantActions } from '../../components';
import { history } from '../../_helpers';
import './AdminDashboard.css';
import { ConfirmationModal } from '../_shared';
import { tenantService } from '../Tenant';
import { alertActions } from '../App';
class AdminDashboard extends React.Component {
    constructor(props) {
        super(props);
        var URL = window.location.href;
        var host = window.location.host.split('.')[1] + '.' + window.location.host.split('.')[2];
        var subdomain = window.location.host.split('.')[0];
        this.state = {
            tenantsMock: [],
            host,
            showDeleteConfirm: false,
            selectedTenant: null
        };

        console.log('URL:' + URL);
        console.log('hostname:' + subdomain);
        console.log('host:' + host);
    }
    componentDidMount() {
        document.title = 'Brandshare Collaboration - Dashboard';
        this.props.dispatch(tenantActions.getAll());
    }

    async handleDeleteTenant() {
        await tenantService.delete(this.state.selectedTenant.id);
        this.setState({ selectedTenant: null, showDeleteConfirm: false });
        this.props.dispatch(tenantActions.getAll());
        this.props.dispatch(alertActions.success("Tenant deleted !"))
    }

    onDelete(tenant) {
        this.setState({ selectedTenant: tenant, showDeleteConfirm: true });
    }
    
    handleCreateTenant(event) {
        history.push('/register');
    }

    render() {
        const { tenants } = this.props;
        return (
            <div className="tenants-table">
                <h3>All registered Tenants</h3>
                <hr></hr>
                <button className="btn mrnda-btn" onClick={this.handleCreateTenant}>
                    Create Tenant
                </button>
                <hr></hr>
                {tenants.items && (
                    <table className="table table-hover">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">URL</th>
                                <th scope="col">Data Center</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tenants.items.map((tenant, index) => (
                                <tr key={tenant.id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{tenant.name}</td>
                                    <td>
                                        <a
                                            href={
                                                'http://' + tenant.subdomain + '.' + this.state.host
                                            }
                                            target="_blank">
                                            {tenant.subdomain}.{this.state.host}
                                        </a>
                                    </td>
                                    {/* <Link to={"http://"+tenant.subdomain +".miranda.local:3000"} target="_blank" className="nav-link">{tenant.subdomain}.miranda.local</Link> */}
                                    <td>{tenant.dataCenter}</td>
                                    <td>
                                        {tenant.deleting ? (
                                            <em> - Deleting...</em>
                                        ) : tenant.deleteError ? (
                                            <span className="text-danger">
                                                {' '}
                                                - ERROR: {tenant.deleteError}
                                            </span>
                                        ) : (
                                            <button
                                                className="btn btn-warning btn-sm"
                                                onClick={() => this.onDelete(tenant)}>
                                                Delete
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                {this.state.showDeleteConfirm && <ConfirmationModal
                    show={this.state.showDeleteConfirm}
                    onHide={() => this.setState({ showDeleteConfirm: false })}
                    onPrimaryClick={this.handleDeleteTenant.bind(this)}
                    onSecondaryClick={() => this.setState({ showDeleteConfirm: false })}
                    primaryButtonText="Yes"
                    secondaryButtonText="No"
                    title={`Delete "${this.state.selectedTenant?.name}"`}
                    bodyText="Are you sure you want to delete this tenant ?"
                />}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { tenants } = state;
    return {
        tenants
    };
}

const connectedHomePage = connect(mapStateToProps)(AdminDashboard);
export { connectedHomePage as AdminDashboard };
