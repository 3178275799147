export const proofSummaryConstants = {

    PROOFSUMMARY_GETPROOFVERION_REQUEST: 'PROOFSUMMARY_GETPROOFVERION_REQUEST',
    PROOFSUMMARY_GETPROOFVERION_SUCCESS: 'PROOFSUMMARY_GETPROOFVERION_SUCCESS',
    PROOFSUMMARY_GETPROOFVERION_FAILURE: 'PROOFSUMMARY_GETPROOFVERION_FAILURE',

    PROOFSUMMARY_ANNOTATIONCOMMENTS_REQUEST: 'PROOFSUMMARY_ANNOTATIONCOMMENTS_REQUEST',
    PROOFSUMMARY_ANNOTATIONCOMMENTS_SUCCESS: 'PROOFSUMMARY_ANNOTATIONCOMMENTS_SUCCESS',
    PROOFSUMMARY_ANNOTATIONCOMMENTS_FAILURE: 'PROOFSUMMARY_ANNOTATIONCOMMENTS_FAILURE',

    PROOFSUMMARY_DOWNLOADPAGE_REQUEST: 'PROOFSUMMARY_DOWNLOADPAGE_REQUEST',
    PROOFSUMMARY_DOWNLOADPAGE_SUCCESS: 'PROOFSUMMARY_DOWNLOADPAGE_SUCCESS',
    PROOFSUMMARY_DOWNLOADPAGE_FAILURE: 'PROOFSUMMARY_DOWNLOADPAGE_FAILURE',
};


