//@ts-check

import React from 'react';

const LucidLinkIcon = ({ height = 20, width = 20, color = 'currentColor' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.3447 8.26161C12.0788 7.75377 12.9628 7.45667 13.9163 7.45667C16.4417 7.45667 18.5 9.53251 18.5 12.107C18.5 14.6816 16.4417 16.7574 13.9163 16.7574C12.5906 16.7574 11.3958 16.1873 10.5572 15.2716L10.5509 15.2647L10.5443 15.2581L5.59756 10.246C5.79733 10.1901 5.99988 10.1619 6.16006 10.1619C6.29884 10.1619 6.49785 10.2003 6.70281 10.2803C6.90888 10.3607 7.07586 10.4657 7.17339 10.5646L7.17351 10.5647L11.2135 14.6594C11.8866 15.3883 12.8463 15.8502 13.9163 15.8502C15.9665 15.8502 17.6175 14.1682 17.6175 12.107C17.6175 10.0459 15.9665 8.36384 13.9163 8.36384C13.2087 8.36384 12.5489 8.56614 11.9883 8.91367L11.3447 8.26161Z"
                stroke={color}
            />
            <path
                d="M8.0116 15.3053L8.65431 15.955C7.9203 16.4613 7.03667 16.7575 6.08387 16.7575C3.55756 16.7575 1.5 14.6853 1.5 12.1176C1.5 9.54983 3.55756 7.47767 6.08387 7.47767C7.40952 7.47767 8.60438 8.04675 9.44282 8.96049L9.44915 8.96739L9.45573 8.97404L14.401 13.9741C14.2017 14.0297 13.9996 14.0577 13.8396 14.0577C13.7008 14.0577 13.5018 14.0194 13.2968 13.9395C13.0906 13.8592 12.9234 13.7544 12.8257 13.6556L8.7859 9.56932C8.11274 8.84189 7.1535 8.38097 6.08387 8.38097C4.03426 8.38097 2.38276 10.0592 2.38276 12.1176C2.38276 14.176 4.03426 15.8542 6.08387 15.8542C6.79107 15.8542 7.45106 15.6523 8.0116 15.3053ZM9.95341 3.4033C8.38968 3.4033 7.06162 4.38204 6.51857 5.75724C6.37406 5.74386 6.2258 5.7359 6.07405 5.7359C5.90793 5.7359 5.74416 5.74385 5.58286 5.75886C6.16391 3.86807 7.90223 2.5 9.95341 2.5C12.0037 2.5 13.7417 3.86686 14.3235 5.75643C14.1702 5.74298 14.0147 5.7359 13.8571 5.7359C13.6973 5.7359 13.5414 5.74471 13.3897 5.75946C12.8469 4.38286 11.5182 3.4033 9.95341 3.4033Z"
                stroke={color}
            />
        </svg>
    );
};

export default LucidLinkIcon;
