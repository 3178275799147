import {
    roleService,
    roleConstants,
    alertConstants,
    tenantService,
} from '../../components';
import { history } from '../../_helpers';

export const roleActions = {
    add,
    update,
    getAll,
    getAllRoleFilters,
    isNameUnique,
    getModuleActionPermissions,
    delete: _delete,
};

function add(role) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(role));
            dispatch(loading(true));
            roleService.add(role).then(
                role => {
                    dispatch(success(role));
                    dispatch(loading(false));
                    resolve(role);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };
    function request(role) {
        return { type: roleConstants.ADD_REQUEST, role };
    }
    function success(role) {
        return { type: roleConstants.ADD_SUCCESS, role };
    }
    function failure(error) {
        return { type: roleConstants.ADD_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function update(role) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(role));
            dispatch(loading(true));
            roleService.update(role).then(
                role => {
                    dispatch(success(role));
                    dispatch(loading(false));
                    resolve(role);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };
    function request(role) {
        return { type: roleConstants.ADD_REQUEST, role };
    }
    function success(role) {
        return { type: roleConstants.ADD_SUCCESS, role };
    }
    function failure(error) {
        return { type: roleConstants.ADD_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function getAll(paramsObj) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            roleService.getAll(paramsObj).then(
                async paginatedProofData => {
                    dispatch(success(paginatedProofData));
                    dispatch(loading(false));
                    resolve(paginatedProofData);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: roleConstants.GETALL_REQUEST };
    }
    function success(data) {
        return { type: roleConstants.GETALL_SUCCESS, data };
    }
    function failure(error) {
        return { type: roleConstants.GETALL_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function getAllRoleFilters() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            roleService.getAllRoleFilters().then(
                rolesFilters => {
                    dispatch(success(rolesFilters));
                    dispatch(loading(false));
                    resolve();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: roleConstants.GETALL_FILTERS_REQUEST };
    }
    function success(rolesFilters) {
        return { type: roleConstants.GETALL_FILTERS_SUCCESS, rolesFilters };
    }
    function failure(error) {
        return { type: roleConstants.GETALL_FILTERS_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function isNameUnique(requestObject) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            roleService.isNameUnique(requestObject).then(
                response => {
                    dispatch(success(response));
                    dispatch(loading(false));
                    resolve(response);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: roleConstants.IS_NAME_UNIQUE_REQUEST };
    }
    function success(IsRoleUnique) {
        return { type: roleConstants.IS_NAME_UNIQUE_SUCCESS, IsRoleUnique };
    }
    function failure(error) {
        return { type: roleConstants.IS_NAME_UNIQUE_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function getModuleActionPermissions(requestObject) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            roleService.getModuleActionPermissions(requestObject).then(
                response => {
                    dispatch(success(response));
                    dispatch(loading(false));
                    resolve(response);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: roleConstants.GET_MODULE_PERMISSION_REQUEST };
    }
    function success(moduleActionPermissions) {
        return { type: roleConstants.GET_MODULE_PERMISSION_SUCCESS, moduleActionPermissions };
    }
    function failure(error) {
        return { type: roleConstants.GET_MODULE_PERMISSION_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        roleService
            .delete(id)
            .then(() => dispatch(success(id)), error => dispatch(failure(id, error.toString())));
    };

    function request(id) {
        return { type: roleConstants.DELETE_REQUEST, id };
    }
    function success(id) {
        return { type: roleConstants.DELETE_SUCCESS, id };
    }
    function failure(id, error) {
        return { type: roleConstants.DELETE_FAILURE, id, error };
    }
}
