import React, { useEffect, useState } from 'react';
import { VideoCommentPreviewTimeRangeHeader } from './VideoCommentPreviewTimeRangeHeader';
import AnnotationViewer from './AnnotationViewer';
import { utilService } from '../../../_helpers';


export function VideoCommentPreview({annotationObject, getPageDetail, comment, metadata, previewUrl = undefined, pageNo = undefined, pageDetails, pages , onGetBackdropSource, onBackgroundLoaded }) {    
    
    console.log("Metadata", utilService.updateCDNURL(metadata.websiteURL));

    return (
        <>
            <div className="comment-preview-item--source-name"></div>
            <div className="comment-preview-item--content">
                <div className="comment-preview-item--header">
                    {metadata.mediaType === 2 || metadata.mediaType === 4 ? (
                        <VideoCommentPreviewTimeRangeHeader
                            comment={comment}
                            metadata={metadata}></VideoCommentPreviewTimeRangeHeader>
                    ) : (
                        (comment.htmlLivePageInfo && comment.htmlLivePageInfo.url) ? 
                        <div className="comment-preview-item--page">
                            URL <strong>{ utilService.getOnlyHTMLPagePartFromCDNUrl(utilService.updateCDNURL(metadata.websiteURL), comment.htmlLivePageInfo.url) }</strong>
                        </div>
                        : <div className="comment-preview-item--page">
                            Page <strong>{pageNo === undefined? comment.page : pageNo }</strong>
                        </div>
                    )}
                </div>
                <AnnotationViewer {...{ comment,annotationObject ,metadata, previewUrl : undefined, pageNo : undefined, getPageDetail, pages, onGetBackdropSource, onBackgroundLoaded }}/>
            </div>
        </>
    );
}
