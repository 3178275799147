import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { utilService } from '../../_helpers';
import { AuthorizationRoute } from '../../components';

export const PrivateRoute = ({
    component,
    loginPath,
    landingPath,
    bypassAuthorization,
    permissionKey,
    ...rest
}) => {
    const shouldViewRoute = () => {        
        try {
            let shouldView = false;
            var user  = JSON.parse(localStorage.getItem('user'));  
            if (user === null) { return shouldView }  

            var type = user?.authData.userType;  
            if( type === 4) {
                localStorage.removeItem('user');
            } else {
                shouldView = true;
            }
            return shouldView;
        }
        catch {
            localStorage.removeItem('user');
            return false;
        }
    }
    return (
        <Route
            {...rest}
            render={props => {
                if (shouldViewRoute()) {
                    return (
                        <AuthorizationRoute
                            component={component}
                            loginPath={loginPath}
                            permissionKey={permissionKey}
                            routeProps={props}
                            bypassAuthorization={bypassAuthorization}
                        />
                    );
                } else {
                    return <Redirect to={{ pathname: landingPath, state: { from: props.location } }} />;
                }
            }}
        />
    )
}
