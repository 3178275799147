import { authHeader, handleResponse, config } from '../../_helpers';
import { localAnnotationStore } from '../../_helpers/localAnnotationStore';
import { authorizationConstants } from '../_shared';
import  xml2js  from 'xml2js';

export const proofService = {
    getAll,
    getAllByDirectory,
    getById,
    add,
    update,
    delete: _delete,
    deleteProofObjectFromCache,
    ingestProof,
    move,
    copyProof,
    getAllProofsCount,
    getAllProofFilters,
    saveAnnotations,
    setAnnotations,
    loadAnnotations,
    getProofVersions,
    addProofVersion,
    deleteProofVersion,
    getProofThumbnail,
    documentConvert,
    runConvertJob,
    queryConvertJob,
    deleteConvertJob,
    getMainFileDownloadLink,
    lockToggleProofVersion,
    getProofVersion,
    getProofVersionSummary,
    getAllProofsVersions,
    getVideoProofVersionSummary,
    
    createHTMLStaticProof,
    createHTMLLiveProof,
    createHTMLDynamicProof,
    createHTMLDynamicLiveProof,
    requestHTMLDynamicPreview,
    addHTMLStaticProofVersion,
    addHTMLDynamicProofVersion,
    addHTMLDynamicLiveProofVersion,
    addHTMLLiveProofVersion,
    IsProofVersionModified,
    getAnnotatedProofVersions,
    updateAnnotationColorPreference,

    getPageText,
    updateProofVersionWorkflow,
    getPages,

    createHTMLZipValidationRequest
};

function getAll(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj),
    };

    return fetch(`${config.apiUrl}/proof/AllProofs`, requestOptions).then(handleResponse);
}
function getAllByDirectory(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj),
    };

    return fetch(`${config.apiUrl}/proof/getallproofs`, requestOptions).then(handleResponse);
}

function getAllProofsVersions(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj),
    };

    return fetch(`${config.apiUrl}/proof/getallproofsversions`, requestOptions).then(handleResponse);
}

function getAllProofsCount(paramsObj) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        body: JSON.stringify(paramsObj),
    };

    return fetch(`${config.apiUrl}/proof/allproofscount`, requestOptions).then(handleResponse);
}
function getAllProofFilters() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        // body: tenantId
    };

    return fetch(`${config.apiUrl}/proof/allprooffilters`, requestOptions).then(handleResponse);
}
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${config.apiUrl}/proof/${id}`, requestOptions).then(handleResponse);
}

function add(proof) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proof),
    };

    return fetch(`${config.apiUrl}/proof/createproof`, requestOptions).then(handleResponse);
}

function update(proof) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proof),
    };

    return fetch(`${config.apiUrl}/proofs/${proof.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(proof) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proof),
    };

    return fetch(`${config.apiUrl}/proof/deleteproof`, requestOptions).then(handleResponse);
}
function deleteProofVersion(proofVersion) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proofVersion),
    };

    return fetch(`${config.apiUrl}/proof/deleteproofversion`, requestOptions).then(handleResponse);
}
async function deleteProofObjectFromCache(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };

    return fetch(`${config.documentServiceUrl}/factory/deletemany`, requestOptions).then(
        handleResponse,
    );
}
function ingestProof(proof) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proof),
    };

    return fetch(`${config.apiUrl}/proof/ingestproof`, requestOptions).then(handleResponse);
}

function move(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };

    return fetch(`${config.apiUrl}/proof/move`, requestOptions).then(handleResponse);
}

function copyProof(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };

    return fetch(`${config.apiUrl}/proof/copyproof`, requestOptions).then(handleResponse);
}

async function loadAnnotations(paramObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramObj),
    };

    return fetch(`${config.documentServiceUrl}/page/GetAnnotations`, requestOptions).then(
        handleResponse,
    );
}
async function saveAnnotations(proofVersionObj) {        
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(proofVersionObj ),
    };

    return fetch(`${config.apiUrl}/proof/saveannotations`, requestOptions).then(handleResponse);
}
async function setAnnotations(paramObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramObj),
    };

    return fetch(`${config.documentServiceUrl}/page/SetAnnotations`, requestOptions).then(
        handleResponse,
    );
}
function getProofVersions(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/proof/GetProofVersions/${id}`, requestOptions).then(
        handleResponse,
    );
}

function getAnnotatedProofVersions(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/proof/GetAnnotatedProofVersions/${id}`, requestOptions).then(
        handleResponse,
    );
}

function getProofVersion(proofId, versionId) {

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/proof/${proofId}/Version/${versionId}`, requestOptions).then(
        handleResponse,
    );
}

function getProofVersionSummary(proofId, versionId) {

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/proof/${proofId}/VersionSummary/${versionId}`, requestOptions).then(
        handleResponse,
    );
}
function getVideoProofVersionSummary(proofId, versionId) {

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/videoproof/${proofId}/VersionSummary/${versionId}`, requestOptions).then(
        handleResponse,
    );
}

function addProofVersion(proofVersion) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proofVersion),
    };

    return fetch(`${config.apiUrl}/proof/addproofversion`, requestOptions).then(handleResponse);
}

function addHTMLStaticProofVersion(proofVersion) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proofVersion),
    };

    return fetch(`${config.apiUrl}/htmlproof/addHTMLStaticProofVersion`, requestOptions).then(handleResponse);
}

function addHTMLLiveProofVersion(proofVersion) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proofVersion),
    };

    return fetch(`${config.apiUrl}/htmlproof/addHTMLLiveProofVersion`, requestOptions).then(handleResponse);
}

function addHTMLDynamicProofVersion(proofVersion) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proofVersion),
    };

    return fetch(`${config.apiUrl}/htmlproof/addHTMLDynamicProofVersion`, requestOptions).then(handleResponse);
}

async function getProofThumbnail(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };

    return fetch(`${config.documentServiceUrl}/page/GetThumbnailsFromS3`, requestOptions).then(
        handleResponse,
    );
}

async function documentConvert(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };
    return fetch(`${config.documentServiceUrl}/Document/Convert`, requestOptions).then(
        handleResponse,
    );
}

async function runConvertJob(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };
    return fetch(`${config.documentServiceUrl}/StatusJobConverter/Run`, requestOptions).then(
        handleResponse,
    );
}

async function queryConvertJob(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };
    return fetch(`${config.documentServiceUrl}/StatusJobConverter/Query`, requestOptions).then(
        handleResponse,
    );
}

async function deleteConvertJob(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };
    return fetch(`${config.documentServiceUrl}/StatusJobConverter/Delete`, requestOptions).then(
        handleResponse,
    );
}

function getMainFileDownloadLink(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };

    return fetch(`${config.apiUrl}/fileshare/getmainfiledownloadlink`, requestOptions).then(
        handleResponse,
    );
}
function lockToggleProofVersion(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };

    return fetch(`${config.apiUrl}/proof/lockToggleProofVersion`, requestOptions).then(
        handleResponse,
    );
}

function createHTMLStaticProof(proof) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proof),
    };

    return fetch(`${config.apiUrl}/htmlproof/CreateHTMLStaticProof`, requestOptions).then(handleResponse);
}
function createHTMLLiveProof(proof) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proof),
    };

    return fetch(`${config.apiUrl}/htmlproof/CreateHTMLLiveProof`, requestOptions).then(handleResponse);
}


function createHTMLDynamicProof(proof) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proof),
    };

    return fetch(`${config.apiUrl}/htmlproof/CreateHTMLDynamicProof`, requestOptions).then(handleResponse);
}

function createHTMLDynamicLiveProof(proof) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proof),
    };

    return fetch(`${config.apiUrl}/htmlproof/CreateHTMLDynamicLiveProof`, requestOptions).then(handleResponse);
}

function requestHTMLDynamicPreview(proof) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proof),
    };

    return fetch(`${config.apiUrl}/htmlproof/RequestHTMLDynamicPreview`, requestOptions).then(handleResponse);
}


function IsProofVersionModified(proof) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proof),
    };

    return fetch(`${config.apiUrl}/proof/IsProofVersionModified`, requestOptions).then(handleResponse);
}

function updateAnnotationColorPreference(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request),
    };

    return fetch(`${config.apiUrl}/proof/UpdateAnnotationColorPreference`, requestOptions).then(handleResponse);
}

function getPageText(proofVersionId, pageNumber) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${config.apiUrl}/proof/getPageText/${proofVersionId}/${pageNumber}`, requestOptions).then(
        handleResponse,
    );
}

function updateProofVersionWorkflow(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };

    return fetch(`${config.apiUrl}/proof/updateProofVersionWorkflow`, requestOptions).then(
        handleResponse,
    );
}

function getPages(versionId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/proof/getPages/${versionId}?pageNumber=1&pageSize=20`, requestOptions).then(
        handleResponse,
    );
}

function createHTMLZipValidationRequest(proof) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proof),
    };

    return fetch(`${config.apiUrl}/htmlproof/CreateHTMLZipValidationRequest`, requestOptions).then(handleResponse);
}

function addHTMLDynamicLiveProofVersion(proofVersion) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(proofVersion),
    };

    return fetch(`${config.apiUrl}/htmlproof/AddHTMLDynamicLiveProofVersion`, requestOptions).then(handleResponse);
}