// import 'rc-tree/assets/index.css';
import React, { Component } from 'react';
import './BreadCrumb.scss';
import { utilService } from '../../../_helpers';
const items = [
    { id: '1', title: 'Home' },
    { id: '2', title: 'BD' },
    { id: '3', title: 'Dhaka' },
];
class BreadCrumb extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    onItemClick(item) {
        this.props.onItemClick(item);
    }

    render() {
        return (
            <ul className="mrnda-breadcrumb">
                {this.props.items.map((item, index) => (
                    <li
                        key={item.key}
                        className="mrnda-breadcrumb__item"
                        onClick={() => this.onItemClick(item)}>
                        <a href="javascript:void(0)">{item.title}</a>
                    </li>
                ))}
            </ul>
        );
    }
}

export { BreadCrumb };
