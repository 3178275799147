import React, { useEffect, useState } from 'react';
import MentionLabel from '../DocumentAnnotation/Subs/Comments/MentionLabel';

export function VideoCommentAnnotation({ annotationObject, comment }) {
    return (
        <div className="">
            <div className="comment-header">

                <span className="comment-author">{annotationObject.createdBy}</span>
                <span className="comment-created">
                    {new Date(annotationObject.createdAt).toLocaleString()}
                </span>
            </div>
            <div className="comment-resolved">

                <span className="comment-resolved--text">{annotationObject.annotationType}</span>
            </div>
            <div className="comment-text">
                <MentionLabel commentText={comment.content} mentions = {comment.mentions}/>            
            </div>

            <div className="comment-replies">
                {comment.replies && comment.replies.filter(x=>x.replyType === 1).map(reply => (
                    <div key={reply.id} className="comment-reply">
                        <div className="comment-reply-header">
                            <span className="comment-reply-number">

                                <i className="fas fa-reply"></i>
                            </span>
                            <span className="comment-reply-header-text">{reply.createdBy}</span>
                            <span className="comment-reply-status statusReview bg-accent-dark-gradient">{reply.status}</span>
                            <span className="comment-reply-created">
                                {new Date(reply.createdAt).toLocaleString()}
                            </span>
                        </div>
                        <div className="comment-reply-text">                            
                            <MentionLabel commentText={reply.content} mentions = {reply.mentions}/>               
                        </div>
                        
                    </div>
                ))}
            </div>
            <hr></hr>
        </div>
    );
}
