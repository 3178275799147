import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { tenantActions, tenantService } from '../../components';
import './TenantRegisterPage.css';
import { utilService } from '../../_helpers';

const initialValidationState = {
    name: {
        validated: true,
        message: ''
    },
    subDomain: {
        validated: true,
        message: ''
    },
    dataCenter: {
        validated: true,
        message: ''
    }
};

class TenantRegisterPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tenant: {
                Name: '',
                SubDomain: '',
                DataCenter: '',
            },
            dataCenters: [],
            submitted: false,
            validations: initialValidationState,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    async componentDidMount() {
        document.title = 'Brandshare Collaboration - Tenant Register';
        var dataCenters = await tenantService.getDataCenters();
        this.setState({ dataCenters });
    }
    handleChange(event) {
        const { name, value } = event.target;
        const { tenant } = this.state;
        this.setState({
            tenant: {
                ...tenant,
                [name]: value,
            },
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        await utilService.setStateAsync.bind(this)({
            submitted: true,
            validations: initialValidationState
        });
        var allValidated = await this.validateFields();
        if (!allValidated) {
            return;
        }
        const { tenant } = this.state;
        const { dispatch } = this.props;
        if (tenant.Name && tenant.SubDomain) {
            console.log('inside registration tenant');
            var tenantPayload = {
                ...tenant,
                subDomain: tenant.subDomain?.trim()
            }
            dispatch(tenantActions.register(tenantPayload));
        }
        console.log('49:User in registration:', this.state.tenant);
    }

    async validateFields() {
        var globalValidation = true;
        var { validations, tenant } = this.state;
        var validationCopy = { ...validations };
        if (
            !tenant.Name ||
            (tenant.Name && tenant.Name.length < 2)
        ) {
            validationCopy.name = {
                validated: false,
                message: 'Name must be more than 1 character'
            };
            globalValidation = false;
        }
        if (
            !tenant.SubDomain || !tenantService.isSubdomainValid(tenant.SubDomain)
        ) {
            validationCopy.subDomain = {
                validated: false,
                message: 'Subdomain is not valid '
            };
            globalValidation = false;
        }

        var isUnique = await tenantService.isSubdomainUnique(tenant.SubDomain?.trim());
        if(!isUnique){
            validationCopy.subDomain = {
                validated: false,
                message: 'Subdomain already exists'
            };
            globalValidation = false;
        }

        if (!tenant.DataCenter || tenant.DataCenter === 'none') {
            validationCopy.dataCenter = {
                validated: false,
                message: 'Select a datacenter '
            };
            globalValidation = false;
        }

        await utilService.setStateAsync.bind(this)({
            validations: validationCopy
        });
        return globalValidation;
    }

    render() {
        const { registering } = this.props;
        const { tenant, submitted, dataCenters, validations } = this.state;
        return (
            <div className="tenant-register-container">
                <h2>Register</h2>
                <hr></hr>
                <form name="form" onSubmit={this.handleSubmit}>
                    <div className={'form-group' + (submitted && !validations.name.validated ? ' has-error' : '')}>
                        <label htmlFor="Name">Tenant Name</label>
                        <input
                            type="text"
                            className="form-control"
                            name="Name"
                            value={tenant.Name}
                            onChange={this.handleChange}
                        />
                        {submitted && !validations.name.validated && (
                            <div className="help-block">{validations.name.message}</div>
                        )}
                    </div>
                    <div
                        className={
                            'form-group' + (submitted && !validations.subDomain.validated ? ' has-error' : '')
                        }>
                        <label htmlFor="SubDomain">URL</label>
                        <div className="d-flex">
                            <input
                                type="text"
                                className="form-control"
                                name="SubDomain"
                                value={tenant.SubDomain}
                                onChange={this.handleChange}
                            />
                            <label style={{ fontWeight: 'bold', padding: '5px' }}>
                                {`.${tenantService.getCurrentHostDomain(window.location.hostname)}`}
                            </label>
                        </div>
                        {submitted && !validations.subDomain.validated && (
                            <div className="help-block">{validations.subDomain.message}</div>
                        )}
                    </div>
                    <div
                        className={
                            'form-group' + (submitted && !validations.dataCenter.validated ? ' has-error' : '')
                        }>
                        <label htmlFor="Data Center">Data Center</label>
                        <select
                            id="DataCenter"
                            className="form-control"
                            name="DataCenter"
                            onChange={this.handleChange}>
                            <option key="none" value="none">
                                Select One
                            </option>
                            {this.state.dataCenters.map(dataCenter => (
                                <option key={dataCenter.key} value={dataCenter.key}>
                                    {dataCenter.displayName}
                                </option>
                            ))}
                        </select>
                        {submitted && !validations.dataCenter.validated && (
                            <div className="help-block">{validations.dataCenter.message}</div>
                        )}
                    </div>
                    <div className="form-group">
                        <button className="btn mrnda-btn mr-2">Save</button>
                        {registering && (
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        )}
                        <Link to="/dashboard" className="btn btn-link">
                            Cancel
                        </Link>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering } = state.registration;
    return {
        registering,
    };
}

const connectedRegisterPage = connect(mapStateToProps)(TenantRegisterPage);
export { connectedRegisterPage as TenantRegisterPage };
