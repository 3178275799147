import React, { useState, useEffect, useRef } from 'react';
import { DocumentCompareContext } from '../DocumentAnnotation/Context/DocumentAnnotationContext';
import { videoAnnotationConstants } from '../VideoAnnotation/Context/videoAnnotation.constants';
import { convertZoomToScale, calculateNewZoom, bringToView } from '../DocumentAnnotation/DocumentAnnotationUtil';
import { func } from 'prop-types';
import useStateRef from 'react-usestateref';

function DocumentCompareProvider({ children }) {
    const [documentViewerA, setDocumentViewerA, documentViewerARef] = useStateRef(null);
    const [documentViewerB, setDocumentViewerB, documentViewerBRef] = useStateRef(null);
    const [proofVersionA, setProofVersionA] = useState({});
    const [proofVersionB, setProofVersionB] = useState({});
    const [compareCanvas, setCompareCanvas] = useState(null);
    const [compareOpacity, setCompareOpacity] = useState(1);
    const [overlayOpacity, setOverlayOpacity] = useState(0.5);
    const [timeMode, setTimeMode] = useState(videoAnnotationConstants.TIME_MODE.TIME_CODE);
    const [isSynced, setIsSynced] = useState(true);
    const [isAutoCompare, setIsAutoCompare] = useState(false);
    const [isOverlayCompare, setIsOverlayCompare] = useState(false);
    const [compareColor, setCompareColor] = useState('#ff0000');
    const [compareIndex, setCompareIndex] = useState(undefined);
    const [compareSide, setCompareSide] = useState('right');
    const [compareContainerZoom, setCompareContainerZoom] = useState(100);
    const [zoomStyle, setZoomStyle] = useState(undefined);
    const [containerStyle, setContainerStyle] = useState({ width: '70%' });
    const [showComments, setShowComments] = useState(false);
    const [showThumbnails, setShowThumbnails] = useState(false);
    const [disableThumbnails, setDisableThumbnails] = useState(false);
    const [disableComments, setDisableComments] = useState(false);
    const [shouldTranslate, setShouldTranslate] = useState(false);
    const [viewerASynced, setViewerASynced] = useState(true);
    const [viewerBSynced, setViewerBSynced] = useState(true);

    const compareColorRef = useRef(compareColor);
    compareColorRef.current = compareColor;

    const isAutoCompareRef = useRef(isAutoCompare);
    isAutoCompareRef.current = isAutoCompare;

    const startAutoCompare = useRef(undefined);
    const clearCanvas = useRef(undefined);
    const [compareHandTool, setCompareHandTool] = useState(false);


    let drawCallBackTimeout = null;

    useEffect(() => {
        
        
        if (isAutoCompare === true) {
            setShowComments(false);
            setShowThumbnails(false);
            setDisableComments(true);
            setDisableThumbnails(true);

        }
        else {
            setDisableComments(false);
            setDisableThumbnails(false);
        }

        if (!isAutoCompare) {
            if (clearCanvas.current) {
                clearCanvas.current();
            }
        } else {
            //drawFrameOnCanvas(videoPlayerA, compareColorRef.current);
            //Find the colors and opacity
            compareCanvases(compareColorRef.current, compareOpacity);
        }


        return () => { };

    }, [isAutoCompare]);



    //Show/hide thumbnails button
    useEffect(() => {
        if (showComments === undefined || showComments === undefined || isOverlayCompare === true || isAutoCompare) { return; }
        
        setDisableThumbnails(showComments)
    }, [showComments]);

    // show hide comment button
    useEffect(() => {
        if (showThumbnails === undefined || showThumbnails === undefined || isOverlayCompare === true || isAutoCompare) { return; }

        setDisableComments(showThumbnails)
    }, [showThumbnails]);

    useEffect(() => {

        if (isOverlayCompare === true) {
            setShowComments(false);
            setShowThumbnails(false);
            setDisableComments(true);
            setDisableThumbnails(true);

        }
        else {
            setDisableComments(false);
            setDisableThumbnails(false);
        }

    }, [isOverlayCompare])





    // useEffect(() => {       
    //     if (isAutoCompare) {
    //         compareCanvases(compareColorRef.current, compareOpacity);
    //     }
    //     return () => {};
    // }, [compareIndex]);



    useEffect(() => {
        setViewerASynced(false);
        setViewerBSynced(false);
    }, [compareIndex]);

    useEffect(() => {
        setViewerASynced(true);
    }, [documentViewerA]);

    useEffect(() => {
        setViewerBSynced(true);
    }, [documentViewerB]);

    useEffect(() => {

        if (viewerASynced === false || viewerBSynced === false) {
            return;
        }

        if (isAutoCompare) {
            compareCanvases(compareColorRef.current, compareOpacity);
        }
    },[viewerASynced, viewerBSynced])




    useEffect(() => {
        if (isAutoCompare) {
            compareCanvases(compareColorRef.current, compareOpacity);
        }
        return () => { };
    }, [compareSide]);

    const compareCanvases = (selectedColor, opacity) => {
        var cleaned = selectedColor.substring(1);
        var color = parseInt(cleaned, 16);
        startAutoCompare.current(color, opacity);
    }

    const setStartAutoCompare = (startAutoCompareHandler) => {
        startAutoCompare.current = startAutoCompareHandler;
    }


    const setClearCanvas = (clearCanvasHandler) => {
        clearCanvas.current = clearCanvasHandler;
    }



    const seekToTimeFromA = (targetFrame) => {
        var seekTime = Number((targetFrame / proofVersionA?.fileMetadata?.frameRate).toFixed(5));
        if (isSynced) {
            videoPlayerA.pause();
            videoPlayerB.pause();
            videoPlayerA.currentTime = seekTime;
            videoPlayerB.currentTime = seekTime;
        } else {
            videoPlayerA.currentTime = seekTime;
        }

        videoPlayerA.onseeked = (e) => {
            if (isAutoCompare) drawFrameOnCanvas(videoPlayerA, compareColorRef.current);
            videoPlayerA.onseeked = undefined;
        };
        // setTimeout(function () {
        //     drawFrameOnCanvas(videoPlayerA);
        // }, 300);
    };

    const seekToTimeFromB = (targetFrame) => {
        var seekTime = Number((targetFrame / proofVersionB?.fileMetadata?.frameRate).toFixed(5));
        if (isSynced) {
            videoPlayerA.pause();
            videoPlayerB.pause();
            videoPlayerA.currentTime = seekTime;
            videoPlayerB.currentTime = seekTime;

            videoPlayerA.onseeked = (e) => {
                if (isAutoCompare) drawFrameOnCanvas(videoPlayerA, compareColorRef.current);
                videoPlayerA.onseeked = undefined;
            };
        } else {
            videoPlayerB.currentTime = seekTime;
        }
    };

    const onVideoPlay = (videoPlayer) => {
        if (isSynced) {
            videoPlayerA.play();
            videoPlayerB.play();
        } else {
            videoPlayer.play();
        }
        if (isAutoCompare) drawFrameOnCanvas(videoPlayerA, compareColorRef.current);
    };

    const onVideoPause = (videoPlayer) => {
        if (isSynced) {
            videoPlayerA.pause();
            videoPlayerB.pause();
        } else {
            videoPlayer.pause();
        }
    };

    const onResetToSync = () => {
        videoPlayerA.pause();
        videoPlayerB.pause();
        videoPlayerB.currentTime = videoPlayerA.currentTime;
    };

    const onCompareColorChange = (color) => {
        setCompareColor(color);
        compareCanvases(color, compareOpacity);
    };

    const onCompareOpacityChange = (opacity) => {
        setCompareOpacity(opacity);
        compareCanvases(compareColor, opacity);
    };

    const clearCompareCanvas = () => {
        if (compareCanvas) {
            var context = compareCanvas.getContext('2d');
            context.clearRect(0, 0, compareCanvas.width, compareCanvas.height);
        }
    };

    const invertColors = (data) => {
        for (var i = 0; i < data.length; i += 4) {
            data[i] = data[i] ^ 255; // Invert Red
            data[i + 1] = data[i + 1] ^ 255; // Invert Green
            data[i + 2] = data[i + 2] ^ 255; // Invert Blue
        }
    };

    function applyDuotone(data, color1, color2) {
        var gradientColors = createGradient(color1, color2);

        applyGrayscale(data);
        for (var i = 0; i < data.length; i += 4) {
            data[i] = gradientColors[data[i]].r;
            data[i + 1] = gradientColors[data[i + 1]].g;
            data[i + 2] = gradientColors[data[i + 2]].b;
            data[i + 3] = 255;
        }
    }

    function applyGrayscale(data) {
        for (var i = 0; i < data.length; i += 4) {
            var r = data[i];
            var g = data[i + 1];
            var b = data[i + 2];
            var gray = (r + g + b) / 3;

            data[i] = data[i + 1] = data[i + 2] = gray;
        }
    }

    function createGradient(fromHex, toHex) {
        let gradients = {};
        if (gradients[fromHex + toHex]) {
            return gradients[fromHex + toHex];
        }

        var gradient = [];
        var maxValue = 255;
        var from = getRGBColor(fromHex);
        var to = getRGBColor(toHex);

        for (var i = 0; i <= maxValue; i++) {
            var intensityB = i;
            var intensityA = maxValue - intensityB;

            gradient[i] = {
                r: (intensityA * from.r + intensityB * to.r) / maxValue,
                g: (intensityA * from.g + intensityB * to.g) / maxValue,
                b: (intensityA * from.b + intensityB * to.b) / maxValue
            };
        }

        gradients[fromHex + toHex] = gradient;

        return gradient;
    }

    function getRGBColor(hex) {
        var colorValue;

        if (hex[0] === '#') {
            hex = hex.substr(1);
        }

        colorValue = parseInt(hex, 16);

        return {
            r: colorValue >> 16,
            g: (colorValue >> 8) & 255,
            b: colorValue & 255
        };
    }

    var rgbToHsl = function (r, g, b) {
        (r /= 255), (g /= 255), (b /= 255);
        var max = Math.max(r, g, b),
            min = Math.min(r, g, b);
        var h,
            s,
            l = (max + min) / 2;

        if (max == min) {
            h = s = 0; // achromatic
        } else {
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }

        return [h, s, l];
    };


    const zoomIn = () => {
        setCompareContainerZoom(compareContainerZoom + 5);
        console.log(`Zoom increased - ${compareContainerZoom}!`);
    }
    const zoomOut = () => {
        var newZoom = compareContainerZoom - 5;
        if (newZoom < 1) { return }
        setCompareContainerZoom(newZoom);
    }
    const onHandToolClicked = () => {
        setCompareHandTool((current) => !current);
    }

    const translateOnScroll = (e) => {
        console.log(
            `=============================on scroll start =================================`
        );
        console.log(`scrollTop :${e.currentTarget.scrollTop}`);
        console.log(`shouldTranslate :${shouldTranslate}`);

        if (e.currentTarget.scrollTop == 0 && shouldTranslate) {
            var clientRect = e.currentTarget.children[0].getBoundingClientRect()
            var y = clientRect.y;
            var translationValue = 0;
            var prefixValue = 147;
            if (y < 0) {
                translationValue = prefixValue + Math.abs(y);
            } else {
                translationValue = prefixValue - y;
            }
            var currentStyle = zoomStyle;
            var scale = convertZoomToScale(containerZoom);

            var newStyle = {
                transform: `matrix(${scale}, 0, 0, ${scale}, 0, ${translationValue})`,
                transformOrigin: currentStyle.transformOrigin
            };


            setZoomStyle(newStyle);

            console.log(`making it false`);
            setShouldTranslate(false);
        }
        console.log(`=============================on scroll end =================================`);
    }






    function convertToDueTone(imageData, pixelCount, color1, color2) {
        var pixels = imageData.data;
        var pixelArray = [];
        var gradientArray = [];

        // Creates a gradient of 255 colors between color1 and color2
        for (var d = 0; d < 255; d += 1) {
            var ratio = d / 255;
            var l = ratio;
            var rA = Math.floor(color1[0] * l + color2[0] * (1 - l));
            var gA = Math.floor(color1[1] * l + color2[1] * (1 - l));
            var bA = Math.floor(color1[2] * l + color2[2] * (1 - l));
            gradientArray.push([rA, gA, bA]);
        }

        for (var i = 0, offset, r, g, b, a, srcHSL, convertedHSL; i < pixelCount; i++) {
            offset = i * 4;
            // Gets every color and the alpha channel (r, g, b, a)
            r = pixels[offset + 0];
            g = pixels[offset + 1];
            b = pixels[offset + 2];
            a = pixels[offset + 3];

            // Gets the avg
            var avg = Math.floor(0.299 * r + 0.587 * g + 0.114 * b);
            // Gets the hue, saturation and luminosity
            var hsl = rgbToHsl(avg, avg, avg);
            // The luminosity from 0 to 255
            var luminosity = Math.max(0, Math.min(254, Math.floor(hsl[2] * 254)));

            // Swap every color with the equivalent from the gradient array
            r = gradientArray[luminosity][0];
            g = gradientArray[luminosity][1];
            b = gradientArray[luminosity][2];

            pixelArray.push(r);
            pixelArray.push(g);
            pixelArray.push(b);
            pixelArray.push(a);
        }

        return pixelArray;
    }

    const providerState = {
        isSynced,
        isAutoCompare,
        documentViewerA,
        setDocumentViewerA,
        documentViewerB,
        setDocumentViewerB,
        proofVersionA,
        proofVersionB,
        compareCanvas,
        compareColor,
        timeMode,
        setProofVersionA,
        setProofVersionB,
        setCompareCanvas,
        onVideoPlay,
        onVideoPause,
        setIsSynced,
        setIsAutoCompare,
        onResetToSync,
        seekToTimeFromA,
        seekToTimeFromB,
        setTimeMode,
        setCompareColor,
        onCompareColorChange,
        setStartAutoCompare,
        compareOpacity,
        onCompareOpacityChange,
        setClearCanvas,
        compareIndex,
        setCompareIndex,
        compareCanvases,
        compareSide,
        setCompareSide,
        isOverlayCompare,
        setIsOverlayCompare,
        overlayOpacity,
        setOverlayOpacity,
        zoomIn,
        zoomOut,
        zoomStyle,
        setZoomStyle,
        compareContainerZoom,
        setCompareContainerZoom,
        shouldTranslate, setShouldTranslate,
        translateOnScroll,
        containerStyle,
        setContainerStyle,
        showComments,
        setShowComments,
        showThumbnails,
        setShowThumbnails,
        disableThumbnails,
        setDisableThumbnails,
        disableComments,
        setDisableComments,
        documentViewerARef,
        documentViewerBRef,
        compareHandTool, 
        setCompareHandTool,
        onHandToolClicked
    };

    return (
        <DocumentCompareContext.Provider value={providerState}>
            {children}
        </DocumentCompareContext.Provider>
    );
}

export { DocumentCompareProvider };
