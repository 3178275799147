import React, { useEffect, useState } from 'react';
import { DataLoadingIndicator } from './DataLoadingIndicator';
import { CSSTransition } from 'react-transition-group';
import './DataFetcher.scss';
import cx from 'classnames';
const DataFetcher = ({
    children,
    onFetchData,
    height = '100%',
    width = '100%',
    variant = 'default'
}) => {
    const [showData, setShowData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (onFetchData == null || undefined) {
            return;
        }
        init();
    }, []);

    const init = async () => {
        await onFetchData();
        setIsLoading(false);
    };

    return (
        <>
            {variant === 'default' ? (
                <>
                    <CSSTransition in={showData} timeout={300} classNames="section" unmountOnExit>
                        {children}
                    </CSSTransition>
                    <CSSTransition
                        in={isLoading}
                        timeout={300}
                        classNames="section"
                        unmountOnExit
                        onExited={() => setShowData(true)}>
                        <DataLoadingIndicator
                            isLoading={isLoading}
                            height={height}
                            width={width}></DataLoadingIndicator>
                    </CSSTransition>
                </>
            ) : (
                <div className="skeleton-fetcher-host">
                    {isLoading === true && (
                        <div className={'skeleton-skeleton-fetcher-mask skeleton'}></div>
                    )}
                    <div className="skeleton-fetcher-content">{children}</div>                   
                </div>
            )}
        </>
    );
};

export { DataFetcher };
