import React from 'react';
import { StatusManage } from '../StatusManage';
import { StatusManageProvider, withStatusManageConsumer } from './StatusManageProvider';
import { StatusType } from '../helpers/statusConstants';

const CommentStatusManageWrapper = (props) => {
    return (        
        <StatusManageProvider statusType={StatusType.comment}>
            <StatusManageWithConsumer {...props }></StatusManageWithConsumer>
        </StatusManageProvider>
    );
};

const StatusManageWithConsumer = withStatusManageConsumer(StatusManage);

export { CommentStatusManageWrapper };
