import { s3Service, s3InfoConstants, alertConstants } from '../../components';
export const s3InfoActions = {
    getS3TempInfo,
    getS3MainInfo,
    getS3MainAttachmentInfo,
    getS3PublicInfo
};

function getS3TempInfo(requestObject) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            s3Service.getS3TempInfo(requestObject).then(
                s3TempInfo => {
                    dispatch(success(s3TempInfo));
                    dispatch(loading(false));
                    resolve(s3TempInfo);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                }
            );
        });
    };

    function request() {
        return { type: s3InfoConstants.GET_REQUEST };
    }
    function success(s3Info) {
        return { type: s3InfoConstants.GET_SUCCESS, s3Info };
    }
    function failure(error) {
        return { type: s3InfoConstants.GET_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function getS3MainInfo(request) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            s3Service.getS3MainInfo(request).then(
                s3MainInfo => {
                    dispatch(success(s3MainInfo));
                    dispatch(loading(false));
                    resolve(s3MainInfo);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                }
            );
        });
    };

    function request() {
        return { type: s3InfoConstants.GET_REQUEST };
    }
    function success(s3Info) {
        return { type: s3InfoConstants.GET_SUCCESS, s3Info };
    }
    function failure(error) {
        return { type: s3InfoConstants.GET_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function getS3MainAttachmentInfo(requestObject) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            s3Service.getS3MainInfoAttachment(requestObject).then(
                s3MainInfo => {
                    dispatch(success(s3MainInfo));
                    dispatch(loading(false));
                    resolve(s3MainInfo);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                }
            );
        });
    };

    function request() {
        return { type: s3InfoConstants.GET_REQUEST };
    }
    function success(s3Info) {
        return { type: s3InfoConstants.GET_SUCCESS, s3Info };
    }
    function failure(error) {
        return { type: s3InfoConstants.GET_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function getS3PublicInfo(requestObject) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            s3Service.getS3PublicInfo(requestObject).then(
                s3MainInfo => {
                    dispatch(success(s3MainInfo));
                    dispatch(loading(false));
                    resolve(s3MainInfo);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                }
            );
        });
    };

    function request() {
        return { type: s3InfoConstants.GET_REQUEST };
    }
    function success(s3Info) {
        return { type: s3InfoConstants.GET_SUCCESS, s3Info };
    }
    function failure(error) {
        return { type: s3InfoConstants.GET_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}