import React, { useState } from 'react';
import {
    MAX_CHAR_LIMIT,
    MAX_CHAR_LIMIT_TEXT
} from './helpers/statusConstants';
import cx from 'classnames';

const StatusAdd = ({
    validations,
    statusType,
    addStatus
}) => {
    const [commentStatus, setCommentStatus] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [info, setInfo] = useState({
        show: false,
        message: ''
    });

    const onStatusAdd = async () => {
        setSubmitted(true);

        setInfo({
            show: false,
            message: ''
        });
        await addStatus(commentStatus);
        setCommentStatus('');
    };

    const handleChange = (e) => {
        const { value } = e.target;

        if (value.length >= MAX_CHAR_LIMIT) {
            setInfo({
                show: true,
                message: MAX_CHAR_LIMIT_TEXT
            });
        } else {
            setInfo({
                show: false,
                message: ''
            });
        }
        setCommentStatus(value);
        setSubmitted(false);
    };

    const handleInputKeyPress = (event) => {
        if (event.key === 'Enter') {
            onStatusAdd();
        }
    };

    return (
        <>
            <div style={{}}>
                <label>Add {statusType.label} Status</label>
            </div>
            <div
                style={{
                    width: '100%',
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                }}
                className="d-flex">
                <input
                    style={{ flex: '3 0 50%', maxWidth: '232px' }}
                    type="text"
                    name="inputDomain"
                    value={commentStatus}
                    onChange={handleChange}
                    onKeyPress={handleInputKeyPress}
                    className="mrnda-input-text margin-1x--bottom margin-1x--right"
                    placeholder="Status"
                    maxLength={MAX_CHAR_LIMIT}
                    autoFocus=""
                    autoComplete="off"
                    required
                />
                <button
                    style={{ flex: '0 0 5%', maxWidth: '56px' }}
                    className={cx('mrnda-btn btn-sm', {
                        'authorization-disabled':
                            commentStatus === undefined || commentStatus === ''
                    })}
                    onClick={onStatusAdd}>
                    Add
                </button>
                {info.show === true && (
                    <div style={{ flex: '3 0 100%' }} className="help-block">
                        {info.message}
                    </div>
                )}
                {submitted && !validations.inputStatus.validated && (
                    <div style={{ flex: '3 0 100%' }} className="help-block">
                        {validations.inputStatus.message}
                    </div>
                )}
            </div>
        </>
    );
};

export default StatusAdd;
