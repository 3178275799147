import React from 'react';
import { isCompactNav } from '../../../_helpers/navbarUtil';
import withPrimaryNav from '../../_shared/Nav/withPrimaryNav';

// const connectedUserLoginPage = connect(mapStateToProps)(UserLoginPage);
// export { connectedUserLoginPage as UserLoginPage };
export function LoginPageTitleBar(props) {
    const CompactNav = withPrimaryNav();
    return (
        <>
            {isCompactNav() && <CompactNav {...props} />}
        </>
    );
}
