//@ts-check

import React from 'react';

const Highlight = ({ height = 22, width = 22, color = 'currentColor' }) => {
    return (
        // <svg
        //     version="1.1"
        //     width={width}
        //     height={height}
        //     x="0"
        //     y="0"
        //     viewBox="0 0 512 512"
        //     className=""
        //     fill={color}>
        //     <g>
        //         <g>
        //             <g>
        //                 <path
        //                     d="m187.116 474.685c2.521 1.272 5.207 1.875 7.855 1.875 6.399 0 12.576-3.521 15.652-9.613l6.767-13.408c4.496-8.908 13.841-14.204 23.782-13.489l19.483 1.396c7.055.499 13.683-3.25 16.873-9.57 0 0 20.109-39.885 20.205-40.142.004-.011.01-.021.014-.032l53.625-146.076c1.427-3.889-.567-8.198-4.456-9.626-3.888-1.424-8.198.568-9.625 4.456l-50.723 138.172-153.381-77.409 20.185-30.611c2.28-3.458 1.326-8.11-2.133-10.391-3.457-2.279-8.11-1.326-10.39 2.133l-24.82 37.642c-.007.01-.011.021-.018.031-.15.23-20.291 40.097-20.291 40.097-3.19 6.32-2.278 13.879 2.323 19.259l12.696 14.844c6.484 7.583 7.77 18.238 3.275 27.146l-6.767 13.408c-2.106 4.173-2.461 8.916-1 13.356 1.461 4.439 4.564 8.045 8.737 10.15l7.509 3.79-25.31 51.956c-1.899 3.899-1.661 8.423.639 12.101 2.299 3.678 6.261 5.873 10.599 5.873h43.142c4.745 0 9.021-2.632 11.159-6.868l17.199-34.078zm-66.62-51.244c-.121-.367-.263-1.103.143-1.907l6.767-13.409c7.229-14.323 5.161-31.458-5.267-43.652l-12.696-14.845c-.657-.768-.787-1.848-.332-2.75l16.495-32.684 155.026 78.239-16.495 32.684c-.457.903-1.404 1.427-2.411 1.368l-19.483-1.396c-16-1.156-31.017 7.367-38.246 21.692l-6.767 13.407c-.621 1.231-2.129 1.727-3.357 1.106l-72.13-36.403c-.803-.406-1.126-1.083-1.247-1.45zm29.528 73.559h-37.604l23.466-48.17 30.643 15.465z"
        //                     className=""></path>
        //                 <path
        //                     d="m403.051 34.252-62.019-31.3c-12.465-6.289-27.661-2.245-35.347 9.411l-146.951 222.86c-2.28 3.458-1.325 8.11 2.133 10.391 1.271.838 2.704 1.239 4.122 1.239 2.437 0 4.827-1.186 6.268-3.372l107.136-162.479 99.077 50.002-27.77 75.649c-1.428 3.889.567 8.198 4.456 9.626 3.887 1.423 8.198-.569 9.625-4.456l52.695-143.544c4.812-13.108-.959-27.737-13.425-34.027zm-.656 28.857-19.721 53.721-95.968-48.434 31.502-47.775c3.493-5.297 10.399-7.138 16.066-4.277l62.019 31.3c5.666 2.858 8.289 9.508 6.102 15.465z"
        //                     className=""></path>
        //                 <path
        //                     d="m266.243 278.644c1.841 0 3.69-.294 5.489-.886 4.44-1.462 8.045-4.565 10.151-8.738l40.262-79.777c2.106-4.173 2.461-8.917 1-13.356-1.462-4.44-4.565-8.046-8.738-10.151l-26.495-13.371c-4.173-2.106-8.915-2.459-13.356-1-4.44 1.462-8.045 4.564-10.151 8.738l-40.263 79.778c-4.347 8.614-.876 19.159 7.739 23.507l26.495 13.371c2.482 1.252 5.165 1.885 7.867 1.885zm-27.603-28.648c-1.23-.621-1.727-2.128-1.105-3.358l40.263-79.778c.406-.804 1.083-1.127 1.45-1.248.184-.061.459-.126.79-.126s.716.065 1.119.269l26.494 13.371c.804.406 1.127 1.083 1.248 1.45s.263 1.104-.143 1.908l-40.263 79.778c-.406.804-1.083 1.127-1.45 1.248-.368.121-1.104.264-1.908-.143z"
        //                     className=""></path>
        //                 <path
        //                     d="m350.449 497h-160.874c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h160.874c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z"
        //                     className=""></path>
        //                 <path
        //                     d="m399.935 497h-14.88c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h14.88c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z"
        //                     className=""></path>
        //             </g>
        //         </g>
        //     </g>
        // </svg>
        <svg
            version="1.1"
            width={width}
            height={height}
            x="0"
            y="0"
            viewBox="0 0 512 512"
            className=""
            fill={color}>
            <g>
                <g>
                    <path d="m497 466.453h-482c-8.284 0-15 6.716-15 15s6.716 15 15 15h482c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
                    <path d="m63.573 436.453h84.854c3.979 0 7.794-1.581 10.606-4.394l28.649-28.648c3.024-3.025 7.425-4.063 11.482-2.71l32.892 10.964c14.906 4.97 31.071 1.153 42.183-9.958 12.271-12.312 227.028-266.269 227.113-266.371 14.997-17.725 13.885-44.47-2.533-60.887l-45.814-45.815c-16.42-16.418-43.164-17.529-60.892-2.529-11.911 10.057-248.249 209.485-266.366 227.108-11.112 11.111-14.928 27.275-9.959 42.184l10.965 32.892c1.353 4.058.314 8.458-2.711 11.482l-71.075 71.075c-4.29 4.29-5.573 10.742-3.252 16.347 2.322 5.605 7.791 9.26 13.858 9.26zm177.97-53.25-32.892-10.964c-14.523-4.841-30.227-1.324-41.301 9.135l-21.273-21.272c10.459-11.075 13.978-26.776 9.136-41.301l-10.965-32.892c-1.225-3.672-.49-7.625 1.903-10.585l105.978 105.976c-2.961 2.393-6.911 3.129-10.586 1.903zm190.249-333.357 45.814 45.815c5.473 5.472 5.843 14.387.848 20.291l-10.538 12.445-68.861-68.861 12.441-10.535c5.907-4.998 14.822-4.627 20.296.845zm-55.71 29.143 72.382 72.382-175.994 207.844-104.233-104.232zm-251.188 302.355 21.214 21.214-3.895 3.895h-42.427z"></path>
                </g>
            </g>
        </svg>
    );
};

export default Highlight;
