import { authHeader, handleResponse, config } from '../../_helpers';

export const directoryService = {
    getAll,
    getPermittedAll,
    add,
    delete: _delete,
    update,
    move,
    isNameUnique,
    getDirectoryPermissions,
    setDirectoryPermissions,
    validateDirectoryName,
    validateFilnameWithExtention,
    validateWebsiteDisplayName
};

function getAll(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj),
    };
    return fetch(`${config.apiUrl}/directory/getdirectories`, requestOptions).then(handleResponse);
}

function getPermittedAll(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj),
    };
    return fetch(`${config.apiUrl}/directory/getpermitteddirectories`, requestOptions).then(handleResponse);
}

function add(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj),
    };
    return fetch(`${config.apiUrl}/directory/add`, requestOptions).then(handleResponse);
}

function update(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj),
    };
    return fetch(`${config.apiUrl}/directory/update`, requestOptions).then(handleResponse);
}

function _delete(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj),
    };
    return fetch(`${config.apiUrl}/directory/delete`, requestOptions).then(handleResponse);
}

function move(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj),
    };
    return fetch(`${config.apiUrl}/directory/move`, requestOptions).then(handleResponse);
}

async function isNameUnique(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject)
    };

    return fetch(
        `${config.apiUrl}/directory/isunique`,
        requestOptions
    ).then(handleResponse);
}

function getDirectoryPermissions(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj),
    };
    return fetch(`${config.apiUrl}/directory/directorypermission/getall`, requestOptions).then(handleResponse);
}

function setDirectoryPermissions(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj),
    };
    return fetch(`${config.apiUrl}/directory/directorypermission/set`, requestOptions).then(handleResponse);
}

function validateDirectoryName(name) {
    var rg1 = /^[^\\/:\*\?"<>\|\#]+$/; // forbidden characters \ / : * ? " < > |
    var rg2 = /^\./; // cannot start with dot (.)
    var rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
    var rg4 = /^[^.]*$/;
    return rg1.test(name) && !rg2.test(name) && !rg3.test(name) && rg4.test(name);
}

function validateFilnameWithExtention(fileName) {
    var regex = /^[\w,\s-]+\.[A-Za-z]+$/i;
    return regex.test(fileName);
}

function validateWebsiteDisplayName(name) {

    let result = { isValid: true, };
    if (name === undefined || name === '') {
        result.isValid = false;
        result.reason = 'Cannot be empty';
        return result;
    }

    var rg1 = /^[^\\/:\*\?"<>\|\#]+$/; // forbidden characters \ / : * ? " < > |
    var rg2 = /^\./; // cannot start with dot (.)
    var rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
    var rg4 = /\.$/;

    let cases = [
        { regex: rg1, shouldBe: true, detail: 'Cannot contain characters \ / : * ? " < > |' },
        { regex: rg2, shouldBe: false, detail: 'Cannot start with dot (.)' },
        { regex: rg3, shouldBe: false, detail: 'Cannot contain system reserved names' },
        { regex: rg4, shouldBe: false, detail: 'Cannot end with a dot (.)' },
    ];

    for (const c of cases) {
        let caseMatched = c.regex.test(name);
        if (caseMatched !== c.shouldBe) {
            result.isValid = false;
            result.reason = c.detail;
            break;
        }
    }

    return result;
}