export  { handleOnCancelMergeAnnotation };


function handleOnCancelMergeAnnotation({
    selectedAnnotation,
    removeCanvasObjectById,
    setSelectedAnnotation,
    setIsCommentAddMode,
    setIsComposite,
    setSelectedTool,
    setInitDrawing
}) {  

    selectedAnnotation.forEach(element => {
        removeCanvasObjectById(element.uuid, element.page);
    }); 
    setSelectedAnnotation([]);
    setIsCommentAddMode(false);         
    setIsComposite(false);   
    setSelectedTool('');
    setInitDrawing(false);                     
}