import React from 'react';
import WorkflowAdd from './WorkFlowAdd';
import WorkflowList from './WorkflowList';
import { withWorkflowManageConsumer } from './Context/WorkflowManageProvider';
import { DataFetcher } from '../../_shared/DataFetcher/DataFetcher';
import { ConfirmationModal } from '../..';

const WorkflowManage = ({
    editingComment,
    setEditingComment,
    visibleCommentStatuses,
    setVisibleCommentStatuses,
    hiddenCommentStatuses,
    setHiddenCommentStatuses,
    infoModalData,
    setInfoModalData,
    onClose,
    maxStatusLimit,
    setMaxStatusLimit,
    statusType,
    fetchStatusData,
    updateStatusPriority,
    updateStatus,
    workflowStatuses,
    membersData
}) => {
    const removeStatusItem = (status, statusList) => {
        var removed = statusList.filter((x) => x.id !== status.id);
        return removed;
    };

    const addStatusItem = (status, statusList) => {
        var added = [...statusList, status];
        return added;
    };

    const uncheckItem = (newCheck, commentStatus) => {
        let item = visibleCommentStatuses.find((x) => x.id === commentStatus.id);
        let itemCopy = { ...item };
        itemCopy.isVisible = newCheck;

        let removed = removeStatusItem(itemCopy, [...visibleCommentStatuses]);
        setVisibleCommentStatuses(removed);

        let added = addStatusItem(itemCopy, [...hiddenCommentStatuses]);

        setHiddenCommentStatuses(statusUtil.sortItemsAlphabetically(added));
        return itemCopy;
    };

    const checkItem = (newCheck, commentStatus) => {
        console.log('Slot available, showing the status');

        let item = hiddenCommentStatuses.find((x) => x.id === commentStatus.id);
        let itemCopy = { ...item };
        itemCopy.isVisible = newCheck;
        itemCopy;
        var removed = removeStatusItem(itemCopy, [...hiddenCommentStatuses]);
        setHiddenCommentStatuses(statusUtil.sortItemsAlphabetically(removed));

        var added = addStatusItem(itemCopy, [...visibleCommentStatuses]);
        setVisibleCommentStatuses(added);
        return itemCopy;
    };

    const onCheckChanged = async (e, commentStatus) => {
        var newCheck = e.target.checked;
        console.log('Check changed', newCheck);
        var statusUpdated;
        if (newCheck === false) {
            if (visibleCommentStatuses.length <= MIN_VISIBLE_COUNT) {
                var modalData = { ...infoModalData };
                modalData.show = true;
                modalData.secondaryButton = '';
                modalData.title = MIN_VISIBLE_TITLE;
                modalData.body = MIN_VISIBLE_BODY;
                setInfoModalData(modalData);
                return;
            } else {
                statusUpdated = uncheckItem(newCheck, commentStatus);
            }
        } else {
            if (visibleCommentStatuses.length >= maxStatusLimit) {
                var modalData = { ...infoModalData };
                modalData.show = true;
                modalData.secondaryButton = '';
                modalData.title = MAX_LIMIT_TITLE;
                modalData.body = maxLimitBody(maxStatusLimit);
                setInfoModalData(modalData);
                return;
            } else {
                statusUpdated = checkItem(newCheck, commentStatus);
            }
        }

        updateStatus(statusUpdated);
    };

    const handleInfoModalAction = () => {
        var modalData = { ...infoModalData };
        modalData.show = false;
        setInfoModalData(modalData);
    };

    return (
        <div className="workflow-status-list-container">            
            <DataFetcher height={'476px'} onFetchData={fetchStatusData}>
                <div style={{ height: '100%' }}>
                    <WorkflowAddHOC />
                    {visibleCommentStatuses?.length > 0 ? <span >Workflow</span> : 
                        <div
                            style={{
                                display: 'flex',
                                paddingBottom: '16px',
                                paddingTop: '16px'

                            }}>
                            <span>
                                No workflow to show. Please add workflows.
                            </span>
                        </div>
                    }         
                    <div style={{marginTop: '12px', paddingBottom: '0px'}} className="comment-status-list-container">
                        <div style={{ paddingBottom: '0px'}} className="comment-status-list-container">
                            <WorkflowList
                                {...{
                                    items: visibleCommentStatuses,
                                    setItems: setVisibleCommentStatuses,
                                    editingComment,
                                    setEditingComment,
                                    onCheckChanged,
                                    enableDragging: true,
                                    updateStatusPriority,
                                    visibleCommentStatuses,
                                    workflowStatuses,
                                    membersData
                                }}
                            />
                        </div>
                    </div>
                </div>
            </DataFetcher>

            {infoModalData.show && (                
                    <ConfirmationModal
                        show
                        onHide={handleInfoModalAction}
                        onPrimaryClick={handleInfoModalAction}
                        onSecondaryClick={handleInfoModalAction}
                        primaryButtonText="Ok"
                        secondaryButtonText={infoModalData.secondaryButton}
                        title={infoModalData.title}
                        bodyText={infoModalData.body}
                    />
                )}
        </div>
    );
};

const WorkflowAddHOC = withWorkflowManageConsumer(WorkflowAdd);

export default WorkflowManage;
