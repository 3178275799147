export const groupConstants = {
    ADD_REQUEST: 'GROUPS_REGISTER_REQUEST',
    ADD_SUCCESS: 'GROUPS_REGISTER_SUCCESS',
    ADD_FAILURE: 'GROUPS_REGISTER_FAILURE',

    GETALL_REQUEST: 'GROUPS_GETALL_REQUEST',
    GETALL_SUCCESS: 'GROUPS_GETALL_SUCCESS',
    GETALL_FAILURE: 'GROUPS_GETALL_FAILURE',

    GETALL_FILTERS_REQUEST: 'GROUPS_FILTERS_GETALL_REQUEST',
    GETALL_FILTERS_SUCCESS: 'GROUPS_FILTERS_GETALL_SUCCESS',
    GETALL_FILTERS_FAILURE: 'GROUPS_FILTERS_GETALL_FAILURE',
    
    DELETE_REQUEST: 'GROUPS_DELETE_REQUEST',
    DELETE_SUCCESS: 'GROUPS_DELETE_SUCCESS',
    DELETE_FAILURE: 'GROUPS_DELETE_FAILURE',
    
    IS_NAME_UNIQUE_REQUEST: 'GROUPS_IS_NAME_UNIQUE_REQUEST',
    IS_NAME_UNIQUE_SUCCESS: 'GROUPS_IS_NAME_UNIQUE_SUCCESS',
    IS_NAME_UNIQUE_FAILURE: 'GROUPS_IS_NAME_UNIQUE_FAILURE',
};
