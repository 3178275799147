export const tenantConstants = {
    REGISTER_REQUEST: 'TENANTS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'TENANTS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'TENANTS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'TENANTS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'TENANTS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'TENANTS_LOGIN_FAILURE',
    
    LOGOUT: 'TENANTS_LOGOUT',

    GETALL_REQUEST: 'TENANTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'TENANTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'TENANTS_GETALL_FAILURE',

    DELETE_REQUEST: 'TENANTS_DELETE_REQUEST',
    DELETE_SUCCESS: 'TENANTS_DELETE_SUCCESS',
    DELETE_FAILURE: 'TENANTS_DELETE_FAILURE'    
};
