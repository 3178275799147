import React from 'react';
import { isCompactNav } from '../../../_helpers/navbarUtil';
import { WrappedInsidePrimaryNav } from '../../_shared/Nav/withPrimaryNav';
import { AuthorizationComponent, authorizationConstants } from '../../_shared';
import cx from 'classnames'
import {
    ProofEditButton,
    AutoCompareButton,
    CreateProofVersionButton,
    MoveProofButton,
    CopyProofButton,
    DeleteProofButton,
    ProofSummaryButton
} from './ProofViewerComponents';
import { utilService } from '../../../_helpers';

export function ProofViewerCloseButton(props) {
    return (
        <li
            style={{
                verticalAlign: 'sub'
            }}
            className="mrnda-sub-navigation__list-item">
            <button
                title="Close File"
                onClick={props.onCloseClick} // disabled={!this.state.proofAvailable}
                className={cx('mrnda-icon-btn', {
                    'authorization-disabled':
                        !props.hasBackRoute && props.directoryNodeId == undefined
                })}>
                <i className="fas fa-times fa-2x"></i>
            </button>
        </li>
    );
}

function ViewerToolbarActions(props) {
    return (
        <div className="mrnda-sub-navigation__options">
            <ul className="mrnda-sub-navigation__list">
                <AuthorizationComponent
                    component={ProofEditButton}
                    classes="mrnda-sub-navigation__list-item"
                    permissionKey={{
                        module: authorizationConstants.ProofViewer.KEY,
                        action: authorizationConstants.ProofViewer.ACTION_PROOF_VIEWER_EDIT
                    }}
                    onClick={props.onEdit}
                    disabled={!props.proofAvailable}
                />
                <li className="mrnda-sub-navigation__list-item">
                    <AuthorizationComponent
                        component={CreateProofVersionButton}
                        classes=""
                        permissionKey={{
                            module: authorizationConstants.ProofViewer.KEY,
                            action: authorizationConstants.ProofViewer
                                .ACTION_PROOF_VIEWER_CREATE_VERSION
                        }}
                        onClick={props.onCreateNewProofVersionClick}
                    />
                </li>
                <li className="mrnda-sub-navigation__list-item">
                    <AuthorizationComponent
                        component={AutoCompareButton}
                        classes=""
                        permissionKey={{
                            module: authorizationConstants.ProofViewer.KEY,
                            action: authorizationConstants.ProofViewer.ACTION_PROOF_VIEWER_COMPARE
                        }}
                        onClick={props.onAutoCompareClick}
                        disabled={utilService.isWebsiteProof(props.currentVersion)}
                    />
                </li>
                <li className="mrnda-sub-navigation__list-item">
                    <AuthorizationComponent
                        component={ProofSummaryButton}
                        classes=""
                        permissionKey={{
                            module: authorizationConstants.ProofViewer.KEY,
                            action: authorizationConstants.ProofViewer
                                .ACTION_PROOF_VIEWER_CREATE_VERSION
                        }}
                        onClick={props.onProofSummaryClick}
                        disabled={!props.proofAvailable}
                    />
                </li>
                {/* <div className="sub-nav__divider" />*/}
                <li className="mrnda-sub-navigation__list-item">
                    <AuthorizationComponent
                        component={CopyProofButton}
                        classes=""
                        permissionKey={{
                            module: authorizationConstants.Dashboard.KEY,
                            action: authorizationConstants.Dashboard
                                .ACTION_DASHBOARD_PROOF_DIRECTORY_EDIT
                        }}
                        onClick={props.onCopyProof}
                        disabled={
                            !props.proofAvailable // ||proofVersions.some((x) => x?.mediaType === 2)
                        }
                    />
                </li>{' '}
                <li className="mrnda-sub-navigation__list-item">
                    <AuthorizationComponent
                        component={MoveProofButton}
                        classes=""
                        permissionKey={{
                            module: authorizationConstants.Dashboard.KEY,
                            action: authorizationConstants.Dashboard
                                .ACTION_DASHBOARD_PROOF_DIRECTORY_EDIT
                        }}
                        onClick={props.onMoveProof}
                    />
                </li>
                <li className="mrnda-sub-navigation__list-item">
                    <AuthorizationComponent
                        component={DeleteProofButton}
                        classes=""
                        permissionKey={{
                            module: authorizationConstants.ProofViewer.KEY,
                            action: authorizationConstants.ProofViewer.ACTION_PROOF_VIEWER_DELETE
                        }}
                        onClick={props.onDeleteProof}
                    />
                </li>
                {props.compactNavbar !== true && (
                    <ProofViewerCloseButton
                        onCloseClick={props.onCloseClick}
                        hasBackRoute={props.hasBackRoute}
                        directoryNodeId={props.directoryNodeId}/>
                )}
            </ul>
        </div>
    );
}

export function ViewerTitlebar(props) {
    

    return !isCompactNav() ? (
        <nav className="mrnda-sub-navigation">
            <div className="mrnda-sub-navigation__title">
                <h1>File</h1>
            </div>
            <ViewerToolbarActions
                onEdit={props.onEdit}
                proofAvailable={props.proofAvailable}
                onCreateNewProofVersionClick={props.onCreateNewProofVersionClick}
                onAutoCompareClick={props.onAutoCompareClick}
                currentVersion={props.currentVersion}
                onProofSummaryClick={props.onProofSummaryClick}
                onCopyProof={props.onCopyProof}
                onMoveProof={props.onMoveProof}
                onDeleteProof={props.onDeleteProof}
                onCloseClick={props.onCloseClick}
                hasBackRoute={props.hasBackRoute}
                directoryNodeId={props.directoryNodeId}></ViewerToolbarActions>
        </nav>
    ) : (
        <WrappedInsidePrimaryNav  WrappedComponent={ViewerToolbarActions} {...props}/>
    );
}
