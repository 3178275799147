import React, { useEffect, useState, useCallback, useRef } from 'react';
// import './CommentBox.scss';
import { withDocumentAnnotationConsumer } from '../../Context/DocumentAnnotationConsumer';
import { documentCommentService } from '../../documentComment.service';

import {
    utilService,
    store,
    authHeader,
    config,
    contributorsHelper
} from '../../../../../_helpers';
import { MetadataPageNumber, MetadataTimeRange } from './MetadataComponents';
import cx from 'classnames';

import { AttachmentsUploader } from './AttachmentsUploader';
import { AttachmentPicker } from './AttachmentPicker';
import MirandaMentionInput from './MirandaMentionInput';
import { mentionHelper } from '../../../../../_helpers/mentionHelper';
import { CommentItemLite } from './CommentItemLite';
import { Spinner } from 'react-bootstrap';

function CommentProcessing(props) {
    const [commentPost, setcommentPost] = useState('');
    const [posted, setPosted] = useState(false);
    const [attachments, setAttachments] = useState(null);
    const [uploadedAttachments, setUploadedAttachments] = useState(null);
    const [selectedComment, setSelectedComment] = useState('');
    const [addNew, setAddNew] = useState(true);
    const attachmentsRef = useRef(null);
    const uploadHandlersRef = useRef([]);
    const uploadedAttachmentsRef = useRef(null);
    const indexRef = useRef(0);
    // const [index, setIndex] = useState(0);
    const [fontColor, setFontColor] = useState();

    const commentPostRef = useRef('');
    const mentionDataRef = useRef({});

    useEffect(() => {
        if (props.editMode !== false) {
            props.setCommentText('');
        }
    }, []);
    useEffect(() => {
        attachmentsRef.current = attachments;
        var fontCol = contributorsHelper.findFontColor(props.userAnnotationColor);
        setFontColor(fontCol);
    }, [attachments]);

    useEffect(() => {
        uploadedAttachmentsRef.current = uploadedAttachments;
    }, [uploadedAttachments]);

    useEffect(() => {
        commentPostRef.current = commentPost;
    }, [commentPost]);

    const handleChange = (event) => {
        var { name, value } = event.target;
        setcommentPost(value);
    };

    const handleOnPostComment = useCallback(() => {
        var mentionRes = mentionHelper.processInputValue(props.commentText);
        mentionDataRef.current = mentionRes;
        commentPostRef.current = mentionRes.tValue;
        console.log(mentionRes);

        setPosted(true);
        var uploadHandler = uploadHandlersRef.current[indexRef.current];
        if (uploadHandler) {
            uploadHandler(indexRef.current);
        } else {
            if (props.editMode) {
                updateComment();
            } else {
                postComment();
            }
        }
    });

    const handleOnAttachAnnotationToComment = useCallback((commentId) => {
        setPosted(true);
        props.handleOnAttachAnnotationWithComment(selectedComment);
        console.log('Attach with existing comment');
    });

    const handleOnCancelComment = useCallback(() => {
        setPosted(false);
        props.handleOnCancelComment();
        props.clearShapeTools();
    }, []);

    const onUploadComplete = (uploadedNow, attachmentNo) => {
        var prev = uploadedAttachmentsRef.current === null ? [] : uploadedAttachmentsRef.current;
        uploadedAttachmentsRef.current = [...prev, uploadedNow];

        var newIndex = indexRef.current + 1;
        indexRef.current = newIndex;
        var uploadHandler = uploadHandlersRef.current[newIndex];

        if (uploadHandler) {
            uploadHandler(newIndex);
        } else {
            postComment();
        }
    };

    const postComment = () => {
        props.handleOnPostComment(
            commentPostRef.current,
            uploadedAttachmentsRef.current,
            mentionDataRef.current.mentions
        );
    };

    const updateComment = () => {
        props.handleOnUpdateComment(commentPostRef.current, mentionDataRef.current.mentions);
    };

    const updateUploadHandlers = (handler) => {
        var newArr = [...uploadHandlersRef.current, handler];
        uploadHandlersRef.current = newArr;
    };
    const onRemoveAttachment = (index) => {
        var attachmentsCopy = attachments.map((attachment) => {
            var newAttachment = { ...attachment };
            return newAttachment;
        });

        attachmentsCopy.splice(index, 1);
        uploadHandlersRef.current.splice(index, 1);
        setAttachments(attachmentsCopy);
    };

    return (
        <div
            className={cx({
                'comments-container': true,
                'comments-container__add-comment-box': !props.editMode,
                'comments-container__edit-comment-box': props.editMode
            })}
            style={{}}>
            <div>
                <div className="comment-form">
                    <div className="comment-form__header">
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div className="comment-form__avatar">
                                <span
                                    className="mrnda-avatar--secondary--small"
                                    style={{
                                        background: props.userAnnotationColor,
                                        color: fontColor
                                    }}>
                                    {utilService.getUserInitial(
                                        props.proofVersion.mediaType === 2 ||
                                            props.proofVersion.mediaType === 4
                                            ? props.videoMetadata.currentUser?.fullName
                                            : props.documentMetadata.currentUser?.fullName
                                    )}
                                </span>
                            </div>
                            <div className="comment-form__details">
                                <div className="comment-form__author">
                                    {props.proofVersion.mediaType === 2 ||
                                    props.proofVersion.mediaType === 4
                                        ? props.videoMetadata.currentUser?.fullName
                                        : props.documentMetadata.currentUser?.fullName}
                                </div>
                                <div className="comment-form__info">
                                    <span>
                                        <div className="time-range">
                                            {props.proofVersion.mediaType === 2 ||
                                            props.proofVersion.mediaType === 4 ? (
                                                <MetadataTimeRange
                                                    frameRange={props.frameRange}
                                                    classes="comment__metadata__time-range__item"
                                                    frameRate={props.videoMetadata.frameRate}
                                                />
                                            ) : (
                                                <MetadataPageNumber
                                                    page={props.page}
                                                    classes="comment__metadata__time-range__item"
                                                />
                                            )}
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="comment__content comment-form__content">
                        <div className="comment__body-wrapper">
                            <div
                                className="comment__body"
                                style={{
                                    background: 'var(--primary-background-color)',
                                    display: 'flex',
                                    padding: '4px 8px',
                                    alignItems: 'center'
                                }}>
                                <Spinner animation="border" variant="primary" size="sm" />
                                <span className="comment-text" style={{ marginLeft: 12 }}>
                                    <span>Extracting text...</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div
                        className="comment-form__footer"
                        style={{ marginTop: props.editMode ? '12px' : '0' }}>
                        <div className="comment-form__footer__action-buttons">
                            <button
                                className="button button-comment-form--primary"
                                onClick={handleOnPostComment}
                                disabled={true}>
                                <span>Save</span>
                            </button>
                            <button
                                className="button button-comment-form--textual"
                                onClick={handleOnCancelComment}>
                                <span>Cancel</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export { CommentProcessing };
