import React, { useEffect, createContext, useState, useContext } from 'react';
import localStorage from 'local-storage';
import { colorUtilService } from '../../../_helpers';
import { defaultThemeColors, defaultThemeColorsV2 } from './color.constants';
import { useScreenSizes } from '../../responsive/useScreenSizes';


const cssColorVarNames = {
    primaryColor: '--primary-color',
    primaryBackgroundColor: '--primary-background-color',
    secondaryBackgroundColor: '--secondary-background-color',
    primaryTextColor: '--primary-text-color',
    secondaryTextColor: '--secondary-text-color',
    iconTextColor: '--icon-text-color',
    borderColor: '--border-color',
    primaryColorShade2: '--primary-color-shade-2',
    controlBorderColor :'--control-border-color',
    tertiaryBackgroundColor :'--tertiary-background-color',    
    blackBackgroundColor :'--block-background-color',    
};

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [themeName, setThemeName] = useState('light');    
    const [themeColors, setThemeColors] = useState();
    const { isMobile } = useScreenSizes();
    var shouldSetTheme = true;

    useEffect(() => {
        if (shouldSetTheme) {           
            var child = children;
            var preference = localStorage.get('preference-theme') || 'light';
            setTheme(preference, isMobile);
        }
    }, []);
    useEffect(() => {
        if (themeColors === undefined) {
            return;
        }

        setTheme(themeName, isMobile);

    }, [isMobile])
    const setProperty = (target, value) => {
        if (value != undefined && value != null) document.body.style.setProperty(target, value);
    };

    const setPrimaryColorLightened = (target, value, name) => {
        if (value != undefined && value != null) 
        {

            
            var newCol =  name === "light" 
                                    ? colorUtilService.changeBrightness(value, 1.1) 
                                    : colorUtilService.changeDarkness(value, 1);
            document.body.style.setProperty(target, newCol);
        }
    };


    const setTheme = (name, shouldUseNewColors = false) => {
               
        var colors = shouldUseNewColors? defaultThemeColorsV2[name] : defaultThemeColors[name];

        setProperty(cssColorVarNames.primaryColor, colors.primaryColor);
        setPrimaryColorLightened(cssColorVarNames.primaryColorShade2, colors.primaryColor, name);
        setProperty(cssColorVarNames.primaryBackgroundColor, colors.primaryBackgroundColor);
        setProperty(cssColorVarNames.secondaryBackgroundColor, colors.secondaryBackgroundColor);
        setProperty(cssColorVarNames.primaryTextColor, colors.primaryTextColor);
        setProperty(cssColorVarNames.secondaryTextColor, colors.secondaryTextColor);
        setProperty(cssColorVarNames.iconTextColor, colors.iconTextColor);
        setProperty(cssColorVarNames.borderColor, colors.borderColor);   
        setProperty(cssColorVarNames.controlBorderColor, colors.controlBorderColor);        
        setProperty(cssColorVarNames.tertiaryBackgroundColor, colors.tertiaryBackgroundColor);        
        setProperty(cssColorVarNames.blackBackgroundColor, colors.blackBackgroundColor);        

        setProperty('--icon-mask', name === "light" ? '0%' : '100%');
        localStorage.set('preference-theme', name);

        setThemeName(name);
        setThemeColors(colors);
    };

    const setCustomTheme = (customTheme) => {
        setProperty(cssColorVarNames.primaryColor, customTheme.primaryColor);
        setProperty(cssColorVarNames.primaryBackgroundColor, customTheme.primaryBackgroundColor);
        setProperty(cssColorVarNames.secondaryBackgroundColor, customTheme.secondaryBackgroundColor);
        setProperty(cssColorVarNames.primaryTextColor, customTheme.primaryTextColor);
        setProperty(cssColorVarNames.secondaryTextColor, customTheme.secondaryTextColor);
        setProperty(cssColorVarNames.iconTextColor, customTheme.iconTextColor);
        setProperty(cssColorVarNames.borderColor, customTheme.borderColor);
        setProperty(cssColorVarNames.tertiaryBackgroundColor, customTheme.tertiaryBackgroundColor);
        setThemeColors((current) => {return {...current, ...customTheme }});
        shouldSetTheme = false;        
    };
    return (
        <ThemeContext.Provider value={{ theme: themeName, setTheme, setCustomTheme, themeColors }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);
