//@ts-check
import imageCompression from 'browser-image-compression';

export const IMAGE_COMPRESSION_OPTION_TYPE = {
    LOW: {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 1024,
        useWebWorker: true
    },
    MIDIUM: {
        maxSizeMB: 0.08,
        maxWidthOrHeight: 800,
        useWebWorker: true
    },
    HIGH: {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 400,
        useWebWorker: true
    }
};
export const compressImage = async (fileBlob, options = IMAGE_COMPRESSION_OPTION_TYPE.MIDIUM) => {
    var mergedOption = {
        ...IMAGE_COMPRESSION_OPTION_TYPE.MIDIUM,
        ...options
    };
    console.log(`COMPRESSION: originalFile size ${fileBlob.size / 1024 / 1024} MB`);
    const compressed = await imageCompression(fileBlob, mergedOption);
    console.log(`COMPRESSION: compressed size ${compressed.size / 1024 / 1024} MB`);
    return compressed;
};
