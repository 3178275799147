//@ts-check

import React from 'react';

const AttachmentIcon = ({ width = 14, height = 14, color = 'currentColor' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 14 14"
            fill="none">
            <path
                d="M2.38855 7.61145L7.05546 2.94454C8.1294 1.8706 9.8706 1.8706 10.9445 2.94454V2.94454C12.0185 4.01849 12.0185 5.75969 10.9445 6.83363L6.17157 11.6066C5.58579 12.1924 4.63604 12.1924 4.05025 11.6066V11.6066C3.46447 11.0208 3.46447 10.0711 4.05025 9.48528L8.04541 5.49013"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default AttachmentIcon;
