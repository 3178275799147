import { useMediaQuery } from 'react-responsive';
import breakpoints from './breakpoints'; // Import the breakpoints

const useScreenSizes = () => {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: breakpoints.desktop });
    const isBigScreen = useMediaQuery({ minWidth: breakpoints.bigScreen });
    const isMobile = useMediaQuery({ maxWidth: breakpoints.tabletMobile });
    const isPortrait = useMediaQuery({ orientation: breakpoints.portrait });
    const isRetina = useMediaQuery({ minResolution: breakpoints.retina });

    return {
        isDesktopOrLaptop,
        isBigScreen,
        isMobile,
        isPortrait,
        isRetina
    };
};

export { useScreenSizes };
