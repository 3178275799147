//@ts-check

import React from 'react';

const DragLeftIcon = ({ height = 20, width = 7, color = 'currentColor' }) => {
    return (
        <svg height={height} viewBox="0 0 7 20" width={width} xmlns="http://www.w3.org/2000/svg">
            <path
                d="m-7.37406619 6.01444816c.26821319-.48278373.87701648-.65672753 1.35980021-.38851435l8.51435707 4.72915729 8.51435711-4.72915729c.4482991-.2490551 1.005258-.1168626 1.2972828.28983642l.0625174.09867793c.2682131.48278373.0942694 1.09158702-.3885144 1.35980021l-8.99999998 5.00000003c-.30202254.1677903-.66926332.1677903-.97128586 0l-9-5.00000003c-.48278373-.26821319-.65672753-.87701648-.38851435-1.35980021z"
                transform="matrix(0 1 -1 0 12.500091 7.499909)"
            />
        </svg>
    );
};

export default DragLeftIcon;
