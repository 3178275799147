import 'rc-tree-select/assets/index.less';
import React, { Component } from 'react';
import TreeSelect from 'rc-tree-select';
import ChevronDown from '../Icon/ChevronDown';
class TreeSelectView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.selectedValue,
        };
    }
    onChange(value) {
        this.setState({ value });
        this.props.onNodeSelect(value);
    }
    render() {
        const { treeData } = this.props;
        var validatedTreedata =
            treeData && treeData.length > 0
                ? treeData
                : [{ key: '0', title: 'No data', disabled: true }];
        return (
            <div style={{ width: 350, position:'relative', display:'flex'}} >
                <TreeSelect
                    className='mrnda-tree-select'
                    style={{ width: 350}}
                    dropdownStyle={{backgroundColor: 'var(--secondary-background-color)', color: 'var(--secondary-text-color)', border: '1px solid var(--control-border-color)'}}
                    treeData={validatedTreedata}
                    labelInValue
                    value={this.props.selectedValue}
                    onChange={this.onChange.bind(this)}
                    showTreeIcon={false}
                    treeLine
                    animation={'slide-up'}
                    placeholder={<i>Select</i>}
                />            

                <div style={{ position: 'absolute', top: '8px', right: '12px' }}>
                    <ChevronDown height='16px' width='16px' />
                </div>           
            </div>
        );
    }
}

export { TreeSelectView };
