//@ts-check

import React from 'react';

const Strikeout = ({ height = 22, width = 22, color = 'currentColor' }) => {
    return (
        <svg
            version="1.1"
            width={width}
            height={height}
            x="0"
            y="0"
            viewBox="0 0 72 72"
            className=""
            fill={color}>
            <g id="format_strikethrough">
                <path d="m58 36h-44c-1.7 0-3 1.3-3 3s1.3 3 3 3h19v18h-6c-1.7 0-3 1.3-3 3s1.3 3 3 3h18c1.7 0 3-1.3 3-3s-1.3-3-3-3h-6v-18h19c1.7 0 3-1.3 3-3s-1.3-3-3-3z"></path>
                <path d="m60 6h-48c-1.7 0-3 1.3-3 3v8c0 1.7 1.3 3 3 3s3-1.3 3-3v-5h18v17.6c0 1.7 1.3 3 3 3s3-1.3 3-3v-17.6h18v5c0 1.7 1.3 3 3 3s3-1.3 3-3v-8c0-1.7-1.3-3-3-3z"></path>
            </g>
        </svg>
    );
};

export default Strikeout;
