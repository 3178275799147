import React, { useEffect, useState } from 'react';
import { frameToTimeCode, getReadableTimeRange } from '../VideoAnnotation/VideoAnnotationUtil';
export function VideoCommentPreviewTimeRangeHeader({ comment, metadata }) {


    const frameCount = Math.round(metadata.frameRate * metadata.durationInSeconds);
    const firstDurationInTimeCode = frameToTimeCode(
        comment.frameRange[0],
        metadata.frameRate,
        comment.frameRange[0] / metadata.frameRate
    );
    const secondDurationInTimeCode = frameToTimeCode(
        comment.frameRange[1],
        metadata.frameRate,
        comment.frameRange[1] / metadata.frameRate
    );
    const firstReadableTimeRange = getReadableTimeRange(comment.frameRange[0], metadata.frameRate);
    const secondReadableTimeRange = getReadableTimeRange(comment.frameRange[1], metadata.frameRate);
    const frame1 = comment.frameRange[0];
    const frame2 = comment.frameRange[1];

    if (comment.frameRange[0] === comment.frameRange[1]) {
        return (
            <>
                <div >Time <strong>{firstReadableTimeRange}</strong></div>
                <div>Frame <strong>{frame1}</strong></div>
                <div >Timecode <strong>{firstDurationInTimeCode}</strong></div>
            </>
        );
    } else {
        return (
            <>
                <div >Time <strong>{`${firstReadableTimeRange} - ${secondReadableTimeRange}`}</strong></div>
                <div>Frame <strong>{`${frame1} - ${frame2}`}</strong></div>
                <div >Timecode <strong>{`${firstDurationInTimeCode} - ${secondDurationInTimeCode}`}</strong></div>
            </>
        );
    }
}