import {
    userService,
    userConstants,
    alertConstants,
    alertActions,
    tenantService,
} from '../../components';
import { history, utilService } from '../../_helpers';
import { websocketService } from '../App/LiveUpdate/websocket.service';

export const userActions = {
    login,
    logout,
    createAccount,
    update,
    getAll,
    getAllUserFilters,
    isEmailUnique,
    delete: _delete,
    updateCurrentUser,
    checkProviderByEmail,
    redirectToOktaLogin,
    getAllV2,
    searchUsers
};

function login(username, password, subdomain = '') {
    return dp => {
        return new Promise((resolve, reject) => {
            dp(request({ username }));
            dp(loading(true));
            // var route = '/tenantdashboard';
            // if (subdomain && subdomain.length > 1 && subdomain[0] == "admin")
            //     route = '/admindashboard';
            userService.login(username, password).then(
                async user => {
                    if (user && user.success) {
                        localStorage.setItem('user', JSON.stringify(user));
                        var tenant = await tenantService.getSetCurrentTenant();
                        dp(success(user));
                        resolve(user);
                        dp(loading(false));
                        // history.push('/dashboard/folders');
                    } else {
                        dp(failure(user.message.toString()));
                        // dp(alertActions.error(error.toString()));
                        dp(loading(false));
                        resolve(user);
                    }
                },
                error => {
                    dp(failure(error.toString()));
                    // dp(alertActions.error(error.toString()));
                    dp(loading(false));
                    reject();
                },
            );
        });
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function checkProviderByEmail(username) {
    return dp => {
        return new Promise((resolve, reject) => {
            dp(request({ username }));
            dp(loading(true));
            userService.checkProviderByEmail(username).then(                
                async user => {
                    if (user && (user.success || (!user.success && user.authProvider.toString() == ""))) {                        
                        dp(success(user));
                        resolve(user);
                        dp(loading(false));
                    } else {
                        dp(failure(user.message.toString()));
                        dp(loading(false));
                        resolve(user);
                    }
                },
                error => {
                    dp(failure(error.toString()));
                    dp(loading(false));
                    reject();
                },
            );
        });
    };

    function request(user) {
        return { type: userConstants.PROVIDER_SELECTION_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.PROVIDER_SELECTION_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.PROVIDER_SELECTION_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function updateCurrentUser(user) {
    return { type: userConstants.LOGIN_SUCCESS, user};
}

function redirectToOktaLogin(autheToken) {
    return dp => {
        return new Promise((resolve, reject) => {
            dp(request({ autheToken }));
            dp(loading(true));
            userService.redirectToOktaLogin(autheToken).then(
                async user => {
                    if (user && user.success) {
                        localStorage.setItem('user', JSON.stringify(user));
                        var tenant = await tenantService.getSetCurrentTenant();
                        dp(success(user));
                        resolve(user);
                        dp(loading(false));
                    } else {
                        dp(failure(user.message.toString()));
                        dp(loading(false));
                        resolve(user);
                    }
                },
                error => {
                    dp(failure(error.toString()));
                    dp(loading(false));
                    reject();
                },
            );
        });
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function logout() {
    userService.logout();
    websocketService.closeClient();
    var empty = {};
    return { type: userConstants.LOGOUT, empty};
}

function createAccount(user) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(user));
            dispatch(loading(true));
            userService.createAccount(user).then(
                user => {
                    dispatch(success(user));
                    dispatch(loading(false));
                    resolve(user);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };
    function request(user) {
        return { type: userConstants.REGISTER_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.REGISTER_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.REGISTER_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function update(user) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(user));
            dispatch(loading(true));
            userService.update(user).then(
                user => {
                    dispatch(success(user));
                    dispatch(loading(false));
                    resolve(user);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };
    function request(user) {
        return { type: userConstants.REGISTER_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.REGISTER_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.REGISTER_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function getAll(paramsObj) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            userService.getAll(paramsObj).then(
                async paginatedProofData => {
                    dispatch(success(paginatedProofData));
                    dispatch(loading(false));
                    resolve(paginatedProofData);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: userConstants.GETALL_REQUEST };
    }
    function success(data) {
        return { type: userConstants.GETALL_SUCCESS, data };
    }
    function failure(error) {
        return { type: userConstants.GETALL_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function getAllV2(paramsObj) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            userService.getAllV2(paramsObj).then(
                async paginatedProofData => {
                    dispatch(success(paginatedProofData));
                    dispatch(loading(false));
                    resolve(paginatedProofData);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: userConstants.GETALL_REQUEST };
    }
    function success(data) {
        return { type: userConstants.GETALL_SUCCESS, data };
    }
    function failure(error) {
        return { type: userConstants.GETALL_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}


function searchUsers(paramsObj) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            userService.searchUsers(paramsObj).then(
                async paginatedProofData => {
                    dispatch(success(paginatedProofData));
                    dispatch(loading(false));
                    resolve(paginatedProofData);
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: userConstants.GETALL_REQUEST };
    }
    function success(data) {
        return { type: userConstants.GETALL_SUCCESS, data };
    }
    function failure(error) {
        return { type: userConstants.GETALL_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}

function getAllUserFilters() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            userService.getAllUserFilters().then(
                usersFilters => {
                    dispatch(success(usersFilters));
                    dispatch(loading(false));
                    resolve();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: userConstants.GETALL_FILTERS_REQUEST };
    }
    function success(usersFilters) {
        return { type: userConstants.GETALL_FILTERS_SUCCESS, usersFilters };
    }
    function failure(error) {
        return { type: userConstants.GETALL_FILTERS_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function isEmailUnique(requestObject) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            userService.isEmailUnique(requestObject).then(
                response => {
                    dispatch(success(response));
                    dispatch(loading(false));
                    resolve(response);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: userConstants.ISEMAILUNIQUE_REQUEST };
    }
    function success(IsUserUnique) {
        return { type: userConstants.ISEMAILUNIQUE_SUCCESS, IsUserUnique };
    }
    function failure(error) {
        return { type: userConstants.ISEMAILUNIQUE_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService
            .delete(id)
            .then(user => dispatch(success(id)), error => dispatch(failure(id, error.toString())));
    };

    function request(id) {
        return { type: userConstants.DELETE_REQUEST, id };
    }
    function success(id) {
        return { type: userConstants.DELETE_SUCCESS, id };
    }
    function failure(id, error) {
        return { type: userConstants.DELETE_FAILURE, id, error };
    }
}
