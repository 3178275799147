export const proofVideoTranscribeConstants = {

    PROOFVIDEO_GETTRANSCRIBETEXT_REQUEST: 'PROOFVIDEO_GETTRANSCRIBETEXT_REQUEST',
    PROOFVIDEO_GETTRANSCRIBETEXT_SUCCESS: 'PROOFVIDEO_GETTRANSCRIBETEXT_SUCCESS',
    PROOFVIDEO_GETTRANSCRIBETEXT_FAILURE: 'PROOFVIDEO_GETTRANSCRIBETEXT_FAILURE',


};


