import { proofConstants } from '../../components';

export function proofData(state = {}, action) {
    switch (action.type) {
        case proofConstants.GETALL_REQUEST:
            return {
                loading: true,
            };
        case proofConstants.GETALL_SUCCESS:
            return {
                rows: action.data.list,
                pageIndex: action.data.pageIndex,
                pageSize: action.data.pageSize,
                totalCount: action.data.totalCount,
                totalPages: action.data.totalPages,
            };
        case proofConstants.GETALL_FAILURE:
            return {
                error: action.error,
            };
        case proofConstants.DELETE_REQUEST:
            return {
                loading: true,
            };
        case proofConstants.DELETE_SUCCESS:
            // remove deleted proof from state
            return {
                proof: action.data,
            };
        case proofConstants.DELETE_FAILURE:
            return {
                error: action.error,
            };
        case proofConstants.GETALL_PROOFS_COUNT_REQUEST:
            return {
                loading: true,
            };
        case proofConstants.GETALL_PROOFS_COUNT_SUCCESS:
            return {
                proofsObj: action.proofsObj,
            };
        case proofConstants.GETALL_PROOFS_COUNT_FAILURE:
            return {
                error: action.error,
            };
        case proofConstants.GET_REQUEST:
            return {
                loading: true,
            };
        case proofConstants.GET_SUCCESS:
            return {
                proof: action.proof,
            };
        case proofConstants.GET_FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}
export function proofsByDirectory(state = {}, action) {
    switch (action.type) {
        case proofConstants.GETALL_BY_DIRECTORY_REQUEST:
            return {
                loading: true,
            };
        case proofConstants.GETALL_BY_DIRECTORY_SUCCESS:
            return {
                rows: action.data?.proofs?.list,
                pageIndex: action.data?.proofs?.pageIndex,
                pageSize: action.data?.proofs?.pageSize,
                totalCount: action.data?.proofs?.totalCount,
                totalPages: action.data?.proofs?.totalPages,
                directoryProofCount: action.data?.directoryProofCount,
            };
        case proofConstants.GETALL_BY_DIRECTORY_FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}

export function proofsObj(state = {}, action) {
    switch (action.type) {
        case proofConstants.GETALL_PROOFS_COUNT_REQUEST:
            return {
                loading: true,
            };
        case proofConstants.GETALL_PROOFS_COUNT_SUCCESS:
            return {
                proofsObj: action.proofsObj,
            };
        case proofConstants.GETALL_PROOFS_COUNT_FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}
export function proofsFilters(state = {}, action) {
    switch (action.type) {
        case proofConstants.GETALL_FILTERS_REQUEST:
            return {
                loading: true,
            };
        case proofConstants.GETALL_FILTERS_SUCCESS:
            return {
                proofsFilters: action.proofsFilters,
            };
        case proofConstants.GETALL_FILTERS_FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}
export function proofVersions(state = {}, action) {
    switch (action.type) {
        case proofConstants.PROOFVERSION_GETALL_REQUEST:
            return {
                loading: true,
            };
        case proofConstants.PROOFVERSION_GETALL_SUCCESS:
            return {
                proofVersions: action.proofVersions,
            };
        case proofConstants.PROOFVERSION_GETALL_FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}

export function proofVersionDownload(state = {}, action) {
    switch (action.type) {
        case proofConstants.PROOFVERSION_DOWNLOAD_REQUEST:
            return {
                isProcessing: true,
            };
        case proofConstants.PROOFVERSION_DOWNLOAD_SUCCESS:
            return {
                isProcessing: false,
                URL: action.URL,
            };
        case proofConstants.PROOFVERSION_DOWNLOAD_FAILURE:
            return {
                error: action.error,
                isProcessing: false,
            };
        default:
            return state;
    }
}
