import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { UserResetPassword } from '../../../../User';
import { userService } from '../../../../User/user.service';
import { COMMENT_CHAR_LIMIT } from '../../../proof.constants';
import { useScreenSizes } from '../../../../responsive/useScreenSizes';
import { useTheme } from '../../../../App/Theme/ThemeContext';
import { colorUtilService } from '../../../../../_helpers';


function sanitize (value, users) {

    var sanitizedUsers = users.filter(u => !value.includes(u.id));


    return sanitizedUsers;

}

function fetchUsers(query, callback, value) {
    if (!query) {
        var params = {
            page: 0,
            pageSize: 10,
            proofSortModel: { sortDirection: 1, sortColumn: null },
            proofFilters: []
        };

        userService
            .getAllUsersInfo(params)
            .then((res) =>
            {

            
                var sanitizedUsers = sanitize(value, res.list);
                console.log(sanitizedUsers);

                return sanitizedUsers.map((user) => ({
                    display: `${user.firstName} ${user.lastName}`,
                    id: user.id
                }))
            }
            )
            .then(callback);

        return;
    }

    userService
        .search(query)
        .then((res) =>{
            
            var sanitizedUsers  = sanitize(value, res);
            return sanitizedUsers.map((user) => ({ display: `${user.firstName} ${user.lastName}`, id: user.id }))
        
        })
        .then(callback);
}

const MirandaMentionInput = ({ placeholder, aValue, setAValue, inputFor }) => {
    const {isMobile} = useScreenSizes();
    const { themeColors } = useTheme();
    const handleChange = (event) => {
        setAValue(event.target.value);
    };
    const mentionStyle = isMobile ? {
        background: colorUtilService.changeOpacity(themeColors.primaryTextColor, 0.05).hex(), 
        borderRadius: '4px',
        border: `1px solid ${colorUtilService.changeOpacity(themeColors.primaryTextColor, 0.20).hex()}`
    } : {}; 
    return (
        <div>
            <MentionsInput
                className={`${inputFor}-mentions${isMobile? '--mobile' : ''}`}
                style={mentionStyle}
                value={aValue}                
                onChange={handleChange}
                placeholder={placeholder}
                maxLength={COMMENT_CHAR_LIMIT}>
                <Mention
                    className="mentions__mention"
                    // style={defaultMentionStyle}
                    trigger="@"
                    data={(q, c) => { fetchUsers (q, c, aValue) }}
                />
            </MentionsInput>
        </div>
    );
};

export default MirandaMentionInput;
