import React from 'react';
import { AttachmentPicker } from './AttachmentPicker';
import Button from '../../../../_shared/Button';

const CommentFooterActions = ({
    commentReply,
    attachments,
    setAttachments,
    editAllowed,
    setSelectedComment,
    handleOnCommentReply,
    ...rest
}) => {
    return (
        <div
            className="reply-form__footer"
            style={{
                width: '100%',
                flexWrap: 'unset',
                justifyContent: 'unset',
                padding: '20px 20px 8px 20px'
            }}>
            <AttachmentPicker attachments={attachments} setAttachments={setAttachments} />

            <Button
                variant={'transparent'}
                style={{ marginRight: '12px' }}
                onClick={() => {
                    setSelectedComment(-1);
                }}>
                <span>Cancel</span>
            </Button>

            <Button
                variant={'primary'}
                onClick={handleOnCommentReply}
                disabled={commentReply === '' || commentReply === undefined}>
                <span>Reply</span>
            </Button>
        </div>
    );
};

export default CommentFooterActions;
