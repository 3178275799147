import React from 'react';
import cx from 'classnames';
import { utilService, colorUtilService } from '../../../../../_helpers';
import { MetadataAnnotationIconList, MetadataCommentPosition } from './MetadataComponents';
import CommentStatus from './CommentStatus';
import PhoneBreakpoint from '../../../../responsive/PhoneBreakpoint';
import { PROOF_TYPE } from '../../../proof.constants';

export function CommentItemHeader(props) {
    return (
        <div
            className="comment__header"
            style={props.isMobile
                ? {
                    margin: '0px 20px 0px 20px'
                }
                : {}}>
            <div
                className="comment__avatar"
                style={props.isMobile
                    ? {
                        flex: '0 32px'
                    }
                    : {}}>
                <span
                    style={props.isMobile
                        ? {
                            background: colorUtilService.changeOpacity(
                                props.primaryTextColor,
                                0.1
                            ),
                            color: 'var(--primary-text-color)',
                            height: '32px',
                            width: '32px',
                            fontWeight: '600',
                            lineHeight: '18px',
                            fontSize: '12px'
                        }
                        : {
                            background: props.commentColor,
                            color: props.fontColor
                        }}
                    className="mrnda-avatar--secondary--small">
                    {utilService.getUserInitial(props.commentItem.createdBy)}
                </span>
            </div>
            <div
                style={props.isMobile
                    ? {
                        minWidth: '62%',
                        marginLeft: '12px'
                    }
                    : {}}
                className="comment__details">
                <div
                    style={props.isMobile
                        ? {
                            fontSize: '12px',
                            fontWeight: 600,
                            lineHeight: '16px',
                            color: 'var(--primary-text-color)'
                        }
                        : {}}
                    className="comment__author text-truncate"
                    title={props.commentItem.createdBy}>
                    {props.commentItem.createdBy}
                </div>
                <div
                    className="comment__date-area"
                    style={props.isMobile ? props.secondaryTextStyle : {}}>
                    <div className="comment__date-area__date">
                        <span className="date-field text-truncate">
                            {new Date(props.commentItem.createdAt).toLocaleString([], {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                            })}
                        </span>
                        {props.commentItem.isEdited === true && (
                            <>
                                <span className="dot-separator">•</span>
                                <span className="comment__edit-indicator"> Edited </span>
                            </>
                        )}
                    </div>
                </div>
                {!props.isMobile && (
                    <MetadataAnnotationIconList
                        commentColor={props.commentColor}
                        annotations={props.commentItem.annotations} />
                )}
            </div>

            <PhoneBreakpoint>
                <MetadataCommentPosition
                    style={{
                        flex: '1',
                        flexBasis: 'auto',
                        display: 'flex',
                        alignItems: 'start',
                        textAlign: 'right',
                        fontSize: '12px',
                        lineHeight: '16px',
                        width: props.proofVersion.mediaType === PROOF_TYPE.HTMLLive ? "calc(35% - 32px)" : "initial" 
                    }}
                    {...{
                        proofVersion: props.proofVersion,
                        rangeSelectorValue: props.rangeSelectorValue,
                        commentItem: props.commentItem,
                        editMode: props.editMode,
                        metadata: props.metadata
                    }} />
            </PhoneBreakpoint>

            {!props.isMobile && (
                <>
                    <CommentStatus
                        {...{
                            commentItem: props.commentItem,
                            isStatusUpdating: props.isCommentStatusUpdating
                        }} />
                    <div
                        style={{
                            height: '24px'
                        }}
                        className={cx({
                            'comment__options threedots': true,
                            'authorization-disabled': props.proofVersion.isLocked
                        })}>
                        <span style={{}} className="threedots__label" data-toggle="dropdown">
                            <i className="fas fa-ellipsis-h"></i>
                        </span>
                        <div
                            style={{
                                width: '160px'
                            }}
                            className={cx({
                                'dropdown-menu drop-down-themed': true
                            })}>
                            {props.commentStatuses.map((status) => {
                                return (
                                    <a
                                        key={status.id}
                                        className={cx('dropdown-item', 'text-truncate', {
                                            active: props.commentItem.status === status,
                                            'authorization-disabled': !props.isUserPermittedToAdd
                                        })}
                                        title={status.label}
                                        onClick={async () => {
                                            if (props.commentItem.status === status.label) {
                                                return;
                                            }

                                            props.setIsCommentStatusUpdating(true);
                                            await props.onCommentSetStatus(
                                                status.id,
                                                props.commentItem.id
                                            );
                                            props.setIsCommentStatusUpdating(false);
                                        }}>
                                        {status.label}
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </>
            )}

            {/* Not sure about the purpose of this  */}
            <div className="comment__header-close">
                <button className="comment__close">
                    <div className="comment__close-icon" />
                </button>
            </div>
        </div>
    );
}
