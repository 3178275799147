import React, { useState } from 'react';
import DesktopBreakpoint from '../../responsive/DesktopBreakpoint';
import { DocumentEditorThumbnails } from './Subs/DocumentEditorThumbnails';
import { PROOF_TYPE } from '../proof.constants';
import { SelectedShapeIndicator } from './SelectedShapeIndicator';
import { DocumentPlayerWithCanvas } from './Subs/DocumentPlayerWithCanvas';
import cx from 'classnames';
import { utilService } from '../../../_helpers';

const ScrollableEditor = ({
    pages,
    isProofCompare,
    proofVersion,
    editorArea,
    offsetHorizontalScrollbar,
    onEditorScrolled,
    pageCount,
    pagesContainer,
    loadablePages,
    hideAnnotations,
    isOverlayCompare,
    compareCanvas,
    isAutoCompare,
    side,
    isMobile,
    setCompareDocumentViewer,
    screenshotRequestProcessingRef
}) => {
       
    const noOverflow = {
        overflow: 'hidden'
    };
    const pageWrapperBaseStyle = {
        zIndex: isProofCompare ? 'auto' : 1,
        padding: utilService.isWebsiteProof(proofVersion) ? '20px 0' : '0px'
    };
    const pageWrapperStyle =
        utilService.isWebsiteProof(proofVersion) === true && screenshotRequestProcessingRef.current === true
            ? { ...pageWrapperBaseStyle, ...noOverflow }
            : { ...pageWrapperBaseStyle };
    
    return (
        <div
            className={cx({
                'mrnda-document-editor-area-body-page-wrapper': true,
                'mrnda-hide-scrollbar': isProofCompare 
            })}

            style={{...pageWrapperStyle}}
            ref={editorArea}
            onMouseEnter={offsetHorizontalScrollbar}
            onScroll={onEditorScrolled}>
            <SelectedShapeIndicator />
            {pageCount > 0 && (
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                    <div ref={pagesContainer} className={'mrnda-document-editor-area-pages'}>
                        {pages.map((page, index) => {
                            return (
                                <DocumentPlayerWithCanvas
                                    key={index}
                                    shouldLoad={loadablePages.some((p) => p.page === index + 1)}
                                    hideAnnotations={hideAnnotations}
                                    {...{
                                        ...page,
                                        index,
                                        isProofCompare: isProofCompare,
                                        isOverlayCompare: isOverlayCompare,
                                        compareCanvas: compareCanvas,
                                        isAutoCompare: isAutoCompare,
                                        setCompareDocumentViewer: setCompareDocumentViewer,
                                        side: side,
                                        isMobile: isMobile
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ScrollableEditor;
