import React, { useEffect } from 'react';
import { history, store } from '../../_helpers';
import { alertActions } from './alert.actions';
import useLocalStorageState from 'use-local-storage-state';

function ThirdPartyLanding() {
    const [isHosted, setIsHosted] = useLocalStorageState('isHosted');
    useEffect(() => {
        setIsHosted('true'); 
        //store.dispatch(alertActions.toggleNavType(true));       
        // var found = document.getElementById('main-nav');
        // if (found !== null ){
        //   found.remove();
        // }
        
        history.push('/');
    }, []);
    return <div></div>;
}

export default ThirdPartyLanding;
