import { useEffect } from 'react';
import { utilService } from './';

function useOutsideClickHandler(targets, handler, ignoreClasses = [] ) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (targets === null || handler === null) {
                return;
            }
                
            var ignores = [];
            ignoreClasses.map((className)=>{
                var ignoreElements = document.getElementsByClassName(className);
                ignores = [...ignores, ...ignoreElements]

            })

            if (isClickedOutside([...targets, ...ignores], event)) {
                handler();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });
}

function isClickedOutside(elements, event) {
    elements.forEach((element) => {
        if (element === null) return;
    });

    var isPointInside = false;

    for (let i = 0; i < elements.length; i++) {

        if (elements[i] === null || elements[i] === undefined)  continue;
        
        var boundingRect = elements[i].getBoundingClientRect();

        // first point
        let x1 = boundingRect.left,
            y1 = boundingRect.top,
            // second point
            x2 = boundingRect.right,
            y2 = boundingRect.bottom;

        // given point
        let x = event.x,
            y = event.y;

        // function call
        if (utilService.findPoint(x1, y1, x2, y2, x, y)) {
            isPointInside = true;
            break;
        } else {
            isPointInside = false;
        }
    }

    return !isPointInside;
}

export { useOutsideClickHandler };
