import React from 'react';
import { useTheme } from './ThemeContext';
import './ThemeChanger.css';
import cx from 'classnames';

export const ThemeChanger = ({ invertedColor }) => {
    const { theme, setTheme } = useTheme();

    const toggleTheme = () => {
        var themeName = theme == 'dark' ? 'light' : 'dark';
        setTheme(themeName);
    };

    const handleOnClicked = (e) => {
        var element = e.target;
        element.classList.add('animate');
        setTimeout(() => {
            toggleTheme();
        }, 150);
        setTimeout(() => element.classList.remove('animate'), 300);
    };
    var customStyle = invertedColor ? { color: 'var(--primary-text-color)' } : {};
    return (
        <div className="fas" style={{ display: 'contents' }}>
            <span
                title={`Enable ${theme === 'light' ? 'dark' : 'light'} mode`}
                className={cx({ 'day-night-toggle': true, active: theme === 'light' })}
                style={customStyle}
                onClick={handleOnClicked}></span>
        </div>
    );
};
