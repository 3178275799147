//@ts-check

import React from 'react';

const PlayMarkerIcon = ({ height = 73, width = 13, color = 'currentColor', isFocused, isStaticMarker }) => {
    return (
        <svg
            style={{
                marginLeft: '-6.5px',
                marginTop:  isStaticMarker? '0' : '6.5px',
                filter: isFocused === true ? 'drop-shadow(2px 0px 0px rgb(0 0 0 / 1.4))' : 'none'
            }}
            height={height}
            viewBox="0 0 13 73"
            width={width}
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="m11.383536 0c.2540599 0 .498595.09670079.6839411.2704628.4029123.37773025.4233263 1.01056606.0455961 1.41347833l-4.9220732 5.24905887.00076799 65.067c0 .5522847-.44771525 1-1 1s-1-.4477153-1-1l-.00076799-65.067-4.9205372-5.24905887c-.14480167-.15445512-.23608861-.35001368-.26249532-.55793485l-.00796748-.12600628c0-.55228475.44771525-1 1-1z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    );
};

export default PlayMarkerIcon;
