import { convertZoomToScale} from '../components/Proof/DocumentAnnotation/DocumentAnnotationUtil';
import { isRegularNav } from './navbarUtil';

export {
    calculateDocumentAnnotationCenterPoint,
    calculateVideoAnnotationCenterPoint
};

const calculateDocumentAnnotationCenterPoint = (object, containerZoom, canvasContainer, isProofCompare, extraTopBar) => {
    let scale = convertZoomToScale(containerZoom);
    var point = calculate(object, scale, canvasContainer, () => {}, isProofCompare, false, extraTopBar);
    return {...point, uuid: object.uuid};
};
const calculateVideoAnnotationCenterPoint = (object, containerScale, canvasContainer, isProofCompare, extraTopBar) => {
    var point = calculate(object, containerScale, canvasContainer.canvas, ()=>{}, isProofCompare, true, extraTopBar);
    return {...point, uuid:object.uuid};
};


function calculate(object, scale, canvasContainer, setArrowPoint1, isProofCompare, isVideo, hasExtraTopBar) {
    if (isNaN(scale)) {
        return;
    } 
    //NOTE: 116 is navbar and toolbar height need to calculate it 
    let toolbarHeight = 116;
    let containerPoints = canvasContainer.getBoundingClientRect();
    let containerTop =  hasExtraTopBar === true ? containerPoints.top - 60 : containerPoints.top;    
    
    let left = containerPoints.left,        
        top = isProofCompare? containerTop - toolbarHeight : containerTop,
        objectCords = object.oCoords,
        pointX = objectCords.mb.x,
        pointY = objectCords.mr.y,
        objectXScaled = pointX * scale,
        objectYScaled = pointY * scale;

    var point = {
        x: left + objectXScaled,
        y: top + objectYScaled
    } 
    setArrowPoint1(point);
    return point;
}