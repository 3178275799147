import React from 'react';

const SupportSuccessModal = () => {
    return (
        <div
            id="successMessage"
            className="text-center"
            style={{                
                fontSize: '28px',
                marginTop: '15%'
            }}>
            <div className='successDiv'>
                {/* <i className="fas fa-check support-success-icon"></i> */}
                <img id="image" src="../../assets/Images/successImage.png" style={{  objectFit:'contain', height:'76px', maxWidth: '100px'}} /><br />
                <p className="support-success-title">
                    Your request has been successfully submitted
                </p>
            </div>
        </div>
    );
}

export default SupportSuccessModal;
