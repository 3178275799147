import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { annotationService, proofService } from '../..';
import { annotationReportActions } from './annotationReport.actions';
import { history, utilService } from '../../../_helpers';
import { VideoComment } from './VideoComment';
import './ProofSummary.scss';
import { AnnotationReportHeader } from './AnnotationReportHeader';
import { videoCommentService } from '../VideoAnnotation/videoComment.service';
import useStateRef from 'react-usestateref';
import { WrappedInsidePrimaryNav } from '../../_shared/Nav/withPrimaryNav';
import { isCompactNav } from '../../../_helpers/navbarUtil';
const { getProofVersion, getVideoComments, getDocumentComments } = annotationReportActions;
const CHUNK_SIZE = 3;

function AnnotationReportToolbar(props) {    
    return (
        <nav
            className="mrnda-sub-navigation navbar"
            style={props.compactNavbar ? {
                backgroundColor: 'var(--secondary-background-color)',
                boxShadow: 'none',
                ...props.heightStyle,    

            } : {
                backgroundColor: 'var(--secondary-background-color)',
                ...props.heightStyle,                 
            }}>
            {
                props.compactNavbar !== true && 
                <div className="mrnda-sub-navigation__title">
                    <h1>Annotation Report</h1>
                </div>
            }
            
            <div className="mrnda-sub-navigation__options">
                <ul className="mrnda-sub-navigation__list">
                    <li
                        onClick={props.handlePrintOnClick}
                        className="mrnda-sub-navigation__list-item"
                        title="Print/Save">
                        <button className={'mrnda-btn'}>
                            <i
                                style={{
                                    marginRight: '8px'
                                }}
                                className="fas fa-print"
                            />
                            Print / Save
                        </button>
                    </li>
                </ul>
            </div>

            {
                <ProgressBar
                    style={{
                        opacity: props.progressOpacity,
                    }}
                    value={props.loadedCount}
                    maxValue={props.totalReportItems}
                />
            }
        </nav>
    );
}

function AnnotationReport(props) {
    const [isPrintable, setIsPrintable] = useState(false);
    const [pages, setPages, pagesRef] = useStateRef([]);
    const [isVideo, setIsVideo] = useState(false);
    const [videoComments, setVideoComments] = useState([]);
    const [loadableChunk, setLoadableChunk] = useState([]);
    const totalLoadableCount = useRef(CHUNK_SIZE);
    const totalLoadedCount = useRef(0);
    const [totalReportItems, setTotalReportItems] = useState();
    const [loadedCount, setLoadedCount] = useState(0);
    const [progressOpacity, setProgressOpacity] = useState(0);

    useEffect(() => {
        const { proofid, versionid } = props.match.params;
        if (!proofid || !versionid) {
            history.goBack();
            return;
        }
        const fetchProofVersion = async () => {
            let proofVersionSummaryFetched = await props.dispatch(
                getProofVersion(proofid, versionid)
            );
            const { proofVersion } = proofVersionSummaryFetched;

            let isVideoProof = utilService.isVideoProof(proofVersion);
            setIsVideo(isVideoProof);

            let comments = [];
            if (isVideoProof === true) {
                comments = await videoCommentService.getVideoComments({
                    proofId: proofid,
                    proofVersionId: versionid
                });
            } else {
                comments = await annotationService.getDocumentComments({
                    proofId: proofid,
                    proofVersionId: versionid
                });
            }

            setVideoComments(
                comments.map((comment, i) => {
                    if (i < 3) {
                        comment.reportLoadStatus = 'Loading';
                    }
                    return comment;
                })
            );

            const totalItems = comments.reduce(
                (count, comment) => count + comment.annotations.length,
                0
            );
            console.log('total items : ', totalItems);
            setTotalReportItems(totalItems);
        };

        fetchProofVersion().then((_) => console.log(''));
    }, []);

    useEffect(() => {
        if (totalReportItems === undefined) {
            return;
        }
        setProgressOpacity(1);
    }, [totalReportItems]);

    useEffect(() => {
        if (totalReportItems === undefined) {
            return;
        }

        if (totalReportItems === loadedCount) {
            setProgressOpacity(0);
        }
    }, [loadedCount]);

    useEffect(() => {
        if (isPrintable) {
            window.print();
            setIsPrintable(false);
        }
    }, [isPrintable]);

    const handlePrintOnClick = useCallback(() => {
        setIsPrintable(true);
        //window.print();
    });

    let heightStyle = {};
    let marginStyle = {};
    if (props.alert.isHideTopbar) {
        heightStyle = {
            top: 0
        };
        marginStyle = {
            margin: '100px 0px 30px'
        };
    }
    const videoRef = useRef();
    const getVideoFrame = ({ fileURL, timeInSecond }) => {
        var source = videoComments.current?.find((x) => x.pageNumber === details.pageNumber).src;
        return source;
    };
    const captureFrame = (video, resolve) => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        const imageDataUrl = canvas.toDataURL(); // Get the frame as a data URL
        resolve(imageDataUrl);
    };
    const getPageSrc = (details) => {
        var source = pagesRef.current?.find((x) => x.pageNumber === details.pageNumber).src;
        return source;
    };
    const onBackgroundLoaded = () => {
        totalLoadedCount.current++;
        setLoadedCount(totalLoadedCount.current);

        if (totalLoadedCount.current < totalLoadableCount.current) {
            return;
        }

        if (totalLoadedCount.current >= totalReportItems) {
            return;
        }

        var fromIndex = totalLoadedCount.current;
        var to = (totalLoadableCount.current += CHUNK_SIZE);

        var previousList = [...videoComments];

        var loaded = previousList.slice(0, fromIndex);

        var loadable = previousList.slice(fromIndex, fromIndex + CHUNK_SIZE).map((item) => {
            item.reportLoadStatus = 'Loading';
            return item;
        });
        var left = previousList.slice(fromIndex + CHUNK_SIZE, previousList.length);
        var modified = [...loaded, ...loadable, ...left];
    };

    const getPageDetail = (videoComment) => {
        var details =
            isVideo === true
                ? {
                      ...props.proofVersionSummary.proofVersion.fileMetadata,
                      timeInSecond:
                          videoComment.frameRange[0] /
                          props.proofVersionSummary.proofVersion.fileMetadata.frameRate
                  }
                : props.proofVersionSummary.proofVersion.pages[videoComment.page - 1];

        return details;
    };

    // let compactNavbar = alert.isHideTopbar !== true && isCompactNav();
    let compactNavbar = isCompactNav();
       
    return (
        <div className="mrnda-annotation-report-page">            
            {
                compactNavbar === true
                ? <WrappedInsidePrimaryNav 
                    WrappedComponent={AnnotationReportToolbar}
                    showRightActions = {true}
                    style = {{ backgroundColor: 'var(--secondary-background-color)' }}
                    primaryActionsStyle = {{ marginRight: "0"}}
                    pageActionsStyle = {{ paddingLeft: '0px' }}  
                    secondaryActionsStyle = {{}}
                    extraText = "Report"
                    totalReportItems={totalReportItems}
                    loadedCount={loadedCount}
                    progressOpacity={progressOpacity}
                    handlePrintOnClick={handlePrintOnClick}
                    heightStyle={heightStyle} />
                : <AnnotationReportToolbar
                    totalReportItems={totalReportItems}
                    loadedCount={loadedCount}
                    progressOpacity={progressOpacity}
                    handlePrintOnClick={handlePrintOnClick}
                    heightStyle={heightStyle}
                />
            }
            
            <div
                id="proof-summary-print-area"
                className="annotation-report-print-area"
                style={{ backgroundColor: 'var(--primary-background-color)' }}>
                <div
                    //className={`${isPrintable ? 'mx-auto my-auto' : ' '} print-summary-content`}
                    className={`${isPrintable ? 'mx-auto my-auto' : ' '} print-summary-content`}>
                    <AnnotationReportHeader
                        proofVersionSummary={props.proofVersionSummary}
                        pageCount={props.pageCount}
                        margins={marginStyle}
                    />
                    <div className="proof-summary">
                        <div className="">
                            <section className="section-collapsible css-xl53lb eca69of0">
                                <header className="section-header">
                                    <div className="section-header-title">
                                        <span>Comments</span>
                                    </div>
                                </header>
                                <div className="section-body">
                                    <div className="comments-list page-thumbnails">
                                        {videoComments &&
                                            videoComments.map((videoComment, index) => {
                                                return (
                                                    <VideoComment
                                                        key={videoComment.id}
                                                        comment={videoComment}
                                                        metadata={props.metadata}
                                                        pages={pages}
                                                        getPageDetail={getPageDetail}
                                                        onBackgroundLoaded={onBackgroundLoaded}
                                                        onGetBackdropSource={
                                                            isVideo === true
                                                                ? getVideoFrame
                                                                : getPageSrc
                                                        }
                                                    />
                                                );
                                            })}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { videoComments, proofVersion } = state.videoProofSummaryData;

    const proofVersionSummary = proofVersion
        ? { ...proofVersion, versionCount: proofVersion.versionCount }
        : { proofVersion: {}, versionCount: 0 };
    const comments = videoComments;
    const fileMetadata = proofVersion
        ? {
              ...proofVersion.proofVersion.fileMetadata,
              mediaType: proofVersion.proofVersion.mediaType
          }
        : {};

    return {
        metadata: fileMetadata,
        proofVersionSummary,
        alert: state.alert
    };
}

const annotationReport = connect(mapStateToProps)(AnnotationReport);
export { annotationReport as AnnotationReport };

const ProgressBar = ({ value, maxValue, ...rest }) => {
    const progressPercentage = Math.floor((value / maxValue) * 100);

    return (
        <div className="annotation-report-progress-bar" {...rest}>
            <div
                className="annotation-report-progress-bar-fill"
                style={{ width: `${progressPercentage}%` }}></div>
        </div>
    );
};
