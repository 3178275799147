import { config, handleResponse } from "../../../_helpers";

export const liveUpdateService = {
    setTarget,
    changeTarget,
    getConnectionByTarget,
    notifyEditorLock,
    setConnectionData,
    getEditorAquiredConnections
};
async function postData(url = "", data = {}) {
    
    
    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });
    return response.json();
}

async function getData(url = "") {    
    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },        
    });
    return response.json();
}

async function setTarget(body) {
    var api = `${config.liveUpdateApi}/api/Notification/SetTarget`;
    postData(api, body).then((data) => {
        console.log(data);
    });    
}

async function changeTarget(body) {
    var api = `${config.liveUpdateApi}/api/Notification/ChangeTarget`;
    postData(api, body).then((data) => {
        console.log(data);
    });    
}

async function setConnectionData(body) {
    var api = `${config.liveUpdateApi}/api/Notification/setConnectionData`;
    postData(api, body).then((data) => {
        console.log(data);
    });    
}

async function notifyEditorLock(body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    };
    return fetch(`${config.liveUpdateApi}/api/Notification/notifyEditorLock`, requestOptions).then(handleResponse);
}

async function getConnectionByTarget(target) {
    var api = `${config.liveUpdateApi}/api/Analytics/GetTotalConnectionsByTarget?Key=${target.key}&value=${target.value}`;
    return await getData(api);;
}

async function getEditorAquiredConnections(body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    };
    return fetch(`${config.liveUpdateApi}/api/Misc/getEditorAquiredConnections`, requestOptions).then(handleResponse);
}