//@ts-check

import React from 'react';

const CircleIcon = ({ height = 60, width = 60, color = 'currentColor' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M37.75 28C37.75 25.4141 36.7228 22.9342 34.8943 21.1057C33.0658 19.2772 30.5859 18.25 28 18.25C25.4141 18.25 22.9342 19.2772 21.1057 21.1057C19.2772 22.9342 18.25 25.4141 18.25 28C18.25 30.5859 19.2772 33.0658 21.1057 34.8943C22.9342 36.7228 25.4141 37.75 28 37.75C30.5859 37.75 33.0658 36.7228 34.8943 34.8943C36.7228 33.0658 37.75 30.5859 37.75 28ZM16 28C16 24.8174 17.2643 21.7652 19.5147 19.5147C21.7652 17.2643 24.8174 16 28 16C31.1826 16 34.2348 17.2643 36.4853 19.5147C38.7357 21.7652 40 24.8174 40 28C40 31.1826 38.7357 34.2348 36.4853 36.4853C34.2348 38.7357 31.1826 40 28 40C24.8174 40 21.7652 38.7357 19.5147 36.4853C17.2643 34.2348 16 31.1826 16 28Z"
                fill={color} 
            />
        </svg>
    );
};

export default CircleIcon;
