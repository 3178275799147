import React, { Component } from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();
const MultiValueLabel = (props) => {
    //console.log('MultiValueLable:', props);
    return (
        <div content={'Customise your multi-value label component!'}>
            <components.MultiValueLabel {...props} />
        </div>
    );
};
const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: 'var(--secondary-background-color)',
        color: 'var(--primary-text-color)'
    }),

    multiValueLabel: (provided, state) => ({
        ...provided,
        color: 'var(--secondary-text-color)'
    }),
    control: (provided, state) => ({
        ...provided,
        borderWidth: '2px',
        backgroundColor: 'var(--secondary-background-color)'
    }),
    groupHeading: (base) => ({
        ...base,
        flex: '1 1',
        // color: 'white',
        margin: 0,
        color: 'var(--primary-text-color)'
    }),

    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition, color: 'var(--secondary-text-color)' };
    },
    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? "var(--primary-color)" : "",
        "&:hover": {
          ...styles
        }
      })
};

const groupStyles = {
    // border: `2px dotted ${colourOptions[2].color}`,
    fontSize: '20px',
    fontWeight: '600',
    background: 'var(--tertiary-background-color)',
    padding: '5px 0px',
    display: 'flex'
};

const GroupHeading = (props) => (
    <div style={groupStyles}>
        <components.GroupHeading {...props} />
    </div>
);
import { utilService } from '../../_helpers';

class MultiSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: this.props.options,
            selectedOptions: null,
            addedOptions: [],
            removedOptions: []
        };
    }

    componentDidMount() {}

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.options || nextProps.selectedOptions) {
            return {
                options: [...nextProps.options],
                selectedOptions: nextProps.selectedOptions
            };
        } else {
            return null;
        }
    }
    async onChange(selectedOptions, actionType) {        
        // | 'select-option'
        // | 'deselect-option'
        // | 'remove-value'
        // | 'pop-value'
        // | 'set-value'
        // | 'clear'
        // | 'create-option';
        if (this.props.isMulti === true) {
            switch (actionType.action) {
                case 'select-option':
                    {
                        
                        var currentAddedOption = [];
                        currentAddedOption.push(actionType.option.id);
                        await utilService.setStateAsync.bind(this)({
                            addedOptions: currentAddedOption,
                            removedOptions: []
                        });
                    }
                    break;
                case 'create-option':
                    {
                        
                    }
                    break;
                case 'remove-value':
                    {
                        var currentRemovedOption = [];
                        currentRemovedOption.push(actionType.removedValue.id);
                        await utilService.setStateAsync.bind(this)({
                            removedOptions: currentRemovedOption,
                            addedOptions: []
                        });
                    }
                    break;
                default:
                    break;
            }
        }
        await utilService.setStateAsync.bind(this)(selectedOptions);        
        this.props.onChange(selectedOptions, this.state.addedOptions, this.state.removedOptions);
    }
    setValue() {
        console.log('Select Down');
    }

    render() {
        const commonProps = {
            styles: customStyles,
            closeMenuOnSelect: this.props.closeMenuOnSelect,
            maxMenuHeight: 150,
            components: { MultiValueLabel, GroupHeading },
            onChange: this.onChange.bind(this),
            onFocus: this.props.onFocus,
            isMulti: this.props.isMulti,
            options: this.state.options,
            value: this.state.selectedOptions,
            isDisabled: this.props.isDisabled,
            isClearable: false,
            selectedIndex : this.props.selectedIndex,
            theme: (theme) => ({
                ...theme,
                borderRadius: 4,
                colors: {
                    ...theme.colors,
                    primary25: 'var(--primary-color)',
                    primary: 'var(--primary-color)',
                    neutral0: 'var(--primary-background-color)',
                    neutral05: 'var(--primary-background-color)',
                    neutral10: 'var(--secondary-background-color)',
                    neutral15: 'var(--primary-background-color)',
                    neutral20: 'var(--control-border-color)',
                    neutral30: 'var(--secondary-text-color)',
                    neutral50: 'var(--secondary-text-color)',
                    neutral60: 'var(--secondary-text-color)',
                    neutral70: 'var(--secondary-text-color)',
                    neutral80: 'var(--secondary-text-color)',
                    neutral90: 'var(--secondary-text-color)'
                }
            })
        };
        const { isCreatable, createLabelText } = this.props;
        return (
            <>
                {isCreatable ? (
                    <CreatableSelect
                        {...commonProps}
                        formatCreateLabel={(value) => `${createLabelText || 'Create'} "${value}"`}
                    />
                ) : (
                    <Select {...commonProps} />                    
                )}
            </>
        );
    }
}

export { MultiSelect };
