export const roleConstants = {
    ADD_REQUEST: 'ROLES_REGISTER_REQUEST',
    ADD_SUCCESS: 'ROLES_REGISTER_SUCCESS',
    ADD_FAILURE: 'ROLES_REGISTER_FAILURE',

    GETALL_REQUEST: 'ROLES_GETALL_REQUEST',
    GETALL_SUCCESS: 'ROLES_GETALL_SUCCESS',
    GETALL_FAILURE: 'ROLES_GETALL_FAILURE',

    GETALL_FILTERS_REQUEST: 'ROLES_FILTERS_GETALL_REQUEST',
    GETALL_FILTERS_SUCCESS: 'ROLES_FILTERS_GETALL_SUCCESS',
    GETALL_FILTERS_FAILURE: 'ROLES_FILTERS_GETALL_FAILURE',
    
    DELETE_REQUEST: 'ROLES_DELETE_REQUEST',
    DELETE_SUCCESS: 'ROLES_DELETE_SUCCESS',
    DELETE_FAILURE: 'ROLES_DELETE_FAILURE',

    IS_NAME_UNIQUE_REQUEST: 'ROLES_IS_NAME_UNIQUE_REQUEST',
    IS_NAME_UNIQUE_SUCCESS: 'ROLES_IS_NAME_UNIQUE_SUCCESS',
    IS_NAME_UNIQUE_FAILURE: 'ROLES_IS_NAME_UNIQUE_FAILURE',

    GET_MODULE_PERMISSION_REQUEST: 'ROLES_GET_MODULE_PERMISSION_REQUEST',
    GET_MODULE_PERMISSION_SUCCESS: 'ROLES_GET_MODULE_PERMISSION_SUCCESS',
    GET_MODULE_PERMISSION_FAILURE: 'ROLES_GET_MODULE_PERMISSION_FAILURE',
};
