import { userService } from '../../User/user.service';
import { userActions } from '../../User/user.actions';
import { toast, cssTransition } from 'react-toastify';

import { store } from '../../../_helpers';

export const liveUpdateGlobalHelper = {
    hanldeRoleUpdate,
    handleUserRoleChanged,
    GetRoleGlobalTragets,
    updateCurrentTargetsWithNewRole
};

function handleUserRoleChanged (data) {
    toast(`Your User Role has been changed !`);
    const { newRoleId, permissions } = data;
    updateCurrentUserRole(permissions, newRoleId);
};
function hanldeRoleUpdate (data) {
    toast(`Your User Permissions has been changed !`);
    updateCurrentUserRole(data);
};

function updateCurrentUserRole (permissions, roleId = undefined) {    
    userService.getCurrentUser().then((user) => {
        user.rolePermission = permissions;
        if (roleId !== undefined) {
            user.authData.roleId = roleId;
        }
        localStorage.setItem('user', JSON.stringify(user));
        store.dispatch(userActions.updateCurrentUser(user));
    });
};

async function updateCurrentTargetsWithNewRole(payload, targets) {
    var data = await userService.getCurrentUser();
    let currentRoleId = data.authData.roleId;
    var searchValue = `User/${currentRoleId}/UserRoleChanged`;
    var index = targets.findIndex((x) => x.value == searchValue);
    var newRoleChangedTarget = {
        key: 'Settings',
        value: `User/${payload.newRoleId}/UserRoleChanged`
    };
    targets[index] = newRoleChangedTarget;

    var newRoleUpdateTarget = {
        key: 'Settings',
        value: `Role/${payload.newRoleId}/RoleUpdate`
    };

    index = targets.findIndex((x) => x.value == `Role/${currentRoleId}/RoleUpdate`);
    targets[index] = newRoleUpdateTarget;
    return targets;
}

async function GetRoleGlobalTragets () {
    var data = await userService.getCurrentUser();
    let roleId = data.authData.roleId;
    return [
        {
            key: 'Settings',
            value: `Role/${roleId}/RoleUpdate`
        },
        {
            key: 'Settings',
            value: `User/${roleId}/UserRoleChanged`
        }
    ];
};
