import React from "react";
import { Button } from 'react-bootstrap';


const CustomEmailTemplateFilePreviewer = ({ src, showActions, onRemove }) => {
    
    return (
        <div className='custom-template-file-previewer'>
            
            <img src={src} />
            {showActions &&
                <Button   style={{marginTop : '12px'}} variant="danger" onClick={onRemove}>
                    Remove
                </Button>
            }
        </div>
    );
};

export default CustomEmailTemplateFilePreviewer;
