import React from 'react';

function EdgeIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none">
            <g clipPath="url(#clip0_296_1362)">
                <path
                    d="M54.1523 44.6579C53.3524 45.0762 52.5274 45.4449 51.682 45.7618C48.9913 46.7686 46.1409 47.2815 43.268 47.2758C32.1773 47.2758 22.5164 39.6469 22.5164 29.8571C22.5306 28.544 22.895 27.2586 23.5719 26.1334C24.2488 25.0082 25.2138 24.084 26.3672 23.4563C16.3359 23.8782 13.7578 34.3313 13.7578 40.4555C13.7578 57.7711 29.7164 59.5266 33.1547 59.5266C35.0086 59.5266 37.8047 58.9875 39.4828 58.4579L39.7898 58.3547C46.2474 56.1223 51.7526 51.7579 55.3992 45.9797C55.5109 45.8038 55.5608 45.5956 55.5409 45.3881C55.5211 45.1806 55.4327 44.9857 55.2897 44.8341C55.1467 44.6825 54.9572 44.5828 54.7512 44.5509C54.5453 44.519 54.3345 44.5566 54.1523 44.6579Z"
                    fill="url(#paint0_linear_296_1362)"
                />
                <path
                    opacity="0.35"
                    d="M54.1523 44.6579C53.3524 45.0762 52.5274 45.4449 51.682 45.7618C48.9913 46.7686 46.1409 47.2815 43.268 47.2758C32.1773 47.2758 22.5164 39.6469 22.5164 29.8571C22.5306 28.544 22.895 27.2586 23.5719 26.1334C24.2488 25.0082 25.2138 24.084 26.3672 23.4563C16.3359 23.8782 13.7578 34.3313 13.7578 40.4555C13.7578 57.7711 29.7164 59.5266 33.1547 59.5266C35.0086 59.5266 37.8047 58.9875 39.4828 58.4579L39.7898 58.3547C46.2474 56.1223 51.7526 51.7579 55.3992 45.9797C55.5109 45.8038 55.5608 45.5956 55.5409 45.3881C55.5211 45.1806 55.4327 44.9857 55.2897 44.8341C55.1467 44.6825 54.9572 44.5828 54.7512 44.5509C54.5453 44.519 54.3345 44.5566 54.1523 44.6579Z"
                    fill="url(#paint1_radial_296_1362)"
                />
                <path
                    d="M24.7754 56.5827C22.685 55.2852 20.8733 53.5851 19.4458 51.5812C17.8187 49.352 16.699 46.7938 16.1653 44.0861C15.6316 41.3784 15.6968 38.5867 16.3563 35.9068C17.0158 33.2269 18.2536 30.7238 19.9829 28.573C21.7122 26.4221 23.8911 24.6757 26.3668 23.4562C27.0981 23.1116 28.3473 22.4882 30.009 22.5187C31.1798 22.5272 32.3326 22.8067 33.3772 23.3353C34.4219 23.8639 35.3299 24.6273 36.0301 25.5655C36.9766 26.8292 37.4984 28.3603 37.5208 29.939C37.5208 29.8897 43.2536 11.2827 18.7708 11.2827C8.48169 11.2827 0.0207547 21.0468 0.0207547 29.6132C-0.0198298 34.1447 0.949755 38.6283 2.85904 42.7382C5.97933 49.3958 11.4359 54.6757 18.1925 57.5753C24.9492 60.4749 32.5354 60.7922 39.5106 58.4671C37.0683 59.2371 34.488 59.4683 31.9478 59.1446C29.4075 58.8209 26.9677 57.95 24.7965 56.5921L24.7754 56.5827Z"
                    fill="url(#paint2_linear_296_1362)"
                />
                <path
                    opacity="0.41"
                    d="M24.7754 56.5827C22.685 55.2852 20.8733 53.5851 19.4458 51.5812C17.8187 49.352 16.699 46.7938 16.1653 44.0861C15.6316 41.3784 15.6968 38.5867 16.3563 35.9068C17.0158 33.2269 18.2536 30.7238 19.9829 28.573C21.7122 26.4221 23.8911 24.6757 26.3668 23.4562C27.0981 23.1116 28.3473 22.4882 30.009 22.5187C31.1798 22.5272 32.3326 22.8067 33.3772 23.3353C34.4219 23.8639 35.3299 24.6273 36.0301 25.5655C36.9766 26.8292 37.4984 28.3603 37.5208 29.939C37.5208 29.8897 43.2536 11.2827 18.7708 11.2827C8.48169 11.2827 0.0207547 21.0468 0.0207547 29.6132C-0.0198298 34.1447 0.949755 38.6283 2.85904 42.7382C5.97933 49.3958 11.4359 54.6757 18.1925 57.5753C24.9492 60.4749 32.5354 60.7922 39.5106 58.4671C37.0683 59.2371 34.488 59.4683 31.9478 59.1446C29.4075 58.8209 26.9677 57.95 24.7965 56.5921L24.7754 56.5827Z"
                    fill="url(#paint3_radial_296_1362)"
                />
                <path
                    d="M35.6975 34.8891C35.5076 35.1352 34.924 35.475 34.924 36.2156C34.924 36.8273 35.3225 37.4156 36.0303 37.9102C39.4006 40.2539 45.7545 39.9445 45.7709 39.9445C48.2682 39.9385 50.7182 39.2627 52.8654 37.9875C55.0315 36.7227 56.8293 34.9136 58.0804 32.7395C59.3314 30.5655 59.9923 28.1021 59.9975 25.5938C60.0584 20.3414 58.1225 16.8492 57.3396 15.3023C52.3732 5.5875 41.6529 1.46869e-07 29.9951 1.46869e-07C22.1112 -0.000779431 14.5438 3.10195 8.92969 8.63712C3.31557 14.1723 0.105985 21.695 -0.00488281 29.5781C0.107617 21.0141 8.62012 14.0977 18.7451 14.0977C19.5654 14.0977 24.2436 14.1773 28.5889 16.4578C32.4186 18.4688 34.4248 20.8969 35.8193 23.3039C37.2678 25.8047 37.5256 28.9641 37.5256 30.2227C37.5256 31.4812 36.8834 33.3469 35.6975 34.8891Z"
                    fill="url(#paint4_radial_296_1362)"
                />
                <path
                    d="M35.6975 34.8891C35.5076 35.1352 34.924 35.475 34.924 36.2156C34.924 36.8273 35.3225 37.4156 36.0303 37.9102C39.4006 40.2539 45.7545 39.9445 45.7709 39.9445C48.2682 39.9385 50.7182 39.2627 52.8654 37.9875C55.0315 36.7227 56.8293 34.9136 58.0804 32.7395C59.3314 30.5655 59.9923 28.1021 59.9975 25.5938C60.0584 20.3414 58.1225 16.8492 57.3396 15.3023C52.3732 5.5875 41.6529 1.46869e-07 29.9951 1.46869e-07C22.1112 -0.000779431 14.5438 3.10195 8.92969 8.63712C3.31557 14.1723 0.105985 21.695 -0.00488281 29.5781C0.107617 21.0141 8.62012 14.0977 18.7451 14.0977C19.5654 14.0977 24.2436 14.1773 28.5889 16.4578C32.4186 18.4688 34.4248 20.8969 35.8193 23.3039C37.2678 25.8047 37.5256 28.9641 37.5256 30.2227C37.5256 31.4812 36.8834 33.3469 35.6975 34.8891Z"
                    fill="url(#paint5_radial_296_1362)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_296_1362"
                    x1="13.7578"
                    y1="41.4961"
                    x2="55.5562"
                    y2="41.4961"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#0C59A4" />
                    <stop offset="1" stopColor="#114A8B" />
                </linearGradient>
                <radialGradient
                    id="paint1_radial_296_1362"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(36.8437 41.8256) scale(22.3547 21.237)">
                    <stop offset="0.72" stopOpacity="0" />
                    <stop offset="0.95" stopOpacity="0.53" />
                    <stop offset="1" />
                </radialGradient>
                <linearGradient
                    id="paint2_linear_296_1362"
                    x1="35.7934"
                    y1="23.3647"
                    x2="9.68638"
                    y2="51.8015"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1B9DE2" />
                    <stop offset="0.16" stopColor="#1595DF" />
                    <stop offset="0.67" stopColor="#0680D7" />
                    <stop offset="1" stopColor="#0078D4" />
                </linearGradient>
                <radialGradient
                    id="paint3_radial_296_1362"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(16.5406 46.6425) rotate(-81.3844) scale(33.6155 27.1579)">
                    <stop offset="0.76" stopOpacity="0" />
                    <stop offset="0.95" stopOpacity="0.5" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="paint4_radial_296_1362"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(6.05664 11.1039) rotate(92.2906) scale(47.4825 101.128)">
                    <stop stopColor="#35C1F1" />
                    <stop offset="0.11" stopColor="#34C1ED" />
                    <stop offset="0.23" stopColor="#2FC2DF" />
                    <stop offset="0.31" stopColor="#2BC3D2" />
                    <stop offset="0.67" stopColor="#36C752" />
                </radialGradient>
                <radialGradient
                    id="paint5_radial_296_1362"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(56.262 18.1421) rotate(73.7398) scale(22.8141 18.5524)">
                    <stop stopColor="#66EB6E" />
                    <stop offset="1" stopColor="#66EB6E" stopOpacity="0" />
                </radialGradient>
                <clipPath id="clip0_296_1362">
                    <rect width="60" height="60" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default EdgeIcon;
