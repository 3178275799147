import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './_helpers';
import { App } from './components';
import { toast, ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// setup fake backend
import { configureFakeBackend } from './_helpers';
//configureFakeBackend();
toast.configure();
render(
    <Provider store={store}>
        <App />
        <ToastContainer
            position="bottom-right"
            autoClose={2500}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
            closeButton={false}
            // className="toastify-container"
            // toastClassName="toastify-toast"
            transition={Slide}
        />
    </Provider>,
    document.getElementById('app'),
);
