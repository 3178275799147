import React from 'react';
import { Button } from 'react-bootstrap';
import CloseIcon from '../Icon/CloseIcon';


const ModalHeader = ({ title, onPrimaryClick, onSecondaryClick, primaryText }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between'
            }}>
            <span
                style={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: 'var(--primary-text-color)'
                }}>
                {title}
            </span>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                {
                    primaryText && 
                    <Button
                    variant=""
                    className="mrnda-btn btn-sm"
                    style={{ height: '34px', paddingTop: '0px', paddingBottom: '0px' }}
                    onClick={onPrimaryClick}>
                    {primaryText}
                </Button>
                }
                <div
                    style={{
                        marginLeft: '20px',
                        marginRight: '20px',
                        opacity: '0.1',
                        width: '2px',
                        height: '34px',
                        backgroundColor: 'var(--primary-text-color)'
                    }}></div>
                <Button
                    className="btn-sm"
                    style={{
                        height: '34px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        backgroundColor: 'transparent',
                        border: 'none', 
                        color: 'var(--primary-text-color)'
                    }}
                    onClick={onSecondaryClick}>
                    <CloseIcon />
                    
                </Button>
            </div>
        </div>
    );
};

export default ModalHeader;
