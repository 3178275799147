// @ts-check
import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import { calculateAspectRatioFit } from '../VideoAnnotation/VideoAnnotationUtil';
import { VideoPlayerControl } from '../VideoAnnotation/Subs/VideoPlayerControl';
import { ProofVideoEditor } from '..';


const VideoCompareCanvas = ({ playerSide, compareSide, transformContainer, canvasOpacity }) => {
    return (
        <>
            <div
                className="VideoCompareCanvasHost"
                style={{
                    height: '100%',
                    width: '100%',                    
                    display: 'flex',
                    position: 'absolute'
                }}>
                {playerSide === compareSide && (
                    <canvas
                        key={transformContainer.toString()}
                        id="compare-output-canvas"
                        className="auxiliary"
                        style={{
                            zIndex: 30,
                            position: 'absolute',                            
                            height: '100%',
                            width: '100%',
                            opacity: canvasOpacity ? canvasOpacity : 1
                        }}
                    />
                )}
            </div>
        </>
    );
};

const CompareVideoPlayer = ({
    proofVersion,
    resizeCounter,
    version,
    setVideoPlayer,
    setVideoCanvas,
    onVideoPlay,
    onVideoPause,
    seekToTime,
    timeMode,
    onTimeModeChange,
    onPlayerTimeUpdate,
    showCaption,
    currentCaption,
    canvasOpacity,
    playerColors,
    initialVolume,
    overrideTheme,
    playerSide,
    compareClassName,
    compareSide,
    showComments,
    isAutoCompare,
    volume, saveCompareVolume,
    isFocused
}) => {
    const videoPlayer = useRef(null);
    const canvasContainer = useRef(null);
    const videoCanvas = useRef(null);
    const [currentTimeInSeconds, setCurrentTimeInSeconds] = useState(0);    
    const [isPaused, setIsPaused] = useState(true);
    const [transformContainer, setTransformContainer] = useState('');
    const [hideCanvas, setHideCanvas] = useState(false);

    useEffect(() => {
        // console.log('RESIZED', version);
        // reScaleCanvasContainer();
        return () => { };
    }, [resizeCounter]);

   


    const reScaleCanvasContainer = () => {
        let video = videoPlayer.current;
        let containerHeight = canvasContainer.current.offsetHeight - 20;
        let containerWidth = canvasContainer.current.offsetWidth - 20;
        let ratio = calculateAspectRatioFit(
            video.videoWidth,
            video.videoHeight,
            containerWidth,
            containerHeight
        ).toFixed(2);
        let transformContainer = { transform: `scale(${ratio}, ${ratio})` };
        setHideCanvas(true);
        setTransformContainer(transformContainer);
        setTimeout(() => {
            setHideCanvas(false);
        }, 1000);
    };

    const onLoadedVideoData = (player) => {        
        player.id = `${playerSide}Player`;
        videoPlayer.current = player;
        console.log('Compare Video player loaded called!');
        videoPlayer.current.volume = volume;
        setVideoPlayer(videoPlayer.current);
        setVideoCanvas(videoCanvas.current);
    };

    const onLoadedMetadata = (event) => {
        reScaleCanvasContainer();
    };

    const onTimeUpdate = (event) => {
        // var currentTime = Math.round(this.player.currentTime());
        var currentTime = videoPlayer.current.currentTime;
        setCurrentTimeInSeconds(currentTime);
        onPlayerTimeUpdate && onPlayerTimeUpdate(currentTime);
    };

    const onVolumeChange = (value) => {
        videoPlayer.current.volume = value;
        setVolume(value);
    };

    const onPlayPause = () => {
        if (videoPlayer.current.paused) {            
            onVideoPause(videoPlayer.current);
            
        } else {            
            onVideoPlay(videoPlayer.current);           
        }
    };
    console.log("HIDE CANVAS", hideCanvas)
    return (
        <>
            {proofVersion.id && (
               <div style={{                       
                   display: 'contents'                                                  
            }}>
                    <ProofVideoEditor
                    compareVersion={proofVersion}
                    showComments={showComments}
                    onLoadedCompareVideoData={onLoadedVideoData}
                    side={playerSide}
                    isFocused = {isFocused}
                    onPlayVideoCompare ={onPlayPause}
                    seekToTimeCompare = {seekToTime}
                    isAutoCompare = {isAutoCompare}
                    compareVolume = {volume}
                    saveCompareVolume = {saveCompareVolume}
                    compareCanvas={
                        <VideoCompareCanvas
                            playerSide={playerSide}
                            compareSide={compareSide}
                            transformContainer={transformContainer}
                            canvasOpacity={canvasOpacity}
                        />
                    }></ProofVideoEditor>
               </div>
            )}


        </>
    );
};

export { CompareVideoPlayer };
