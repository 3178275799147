//@ts-check

import React from 'react';

const AddCommentIcon = ({ height = 20, width = 20, color = 'currentColor' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 19 19"
            fill="none">
            <path
                d="M17.5 0C18.3284 0 19 0.671573 19 1.5V8.5C19 9.32843 18.3284 10 17.5 10H16V13.5C16 14.8807 14.8807 16 13.5 16H8.425L4.81235 18.8904C4.50679 19.1349 4.06396 18.9481 4.00628 18.5813L4 18.5V16H2.5C1.17452 16 0.0899613 14.9685 0.00531769 13.6644L0 13.5V6.5C0 5.11929 1.11929 4 2.5 4H8V1.5C8 0.671573 8.67157 0 9.5 0H17.5ZM8 5H2.5C1.67157 5 1 5.67157 1 6.5V13.5C1 14.3284 1.67157 15 2.5 15H4.5C4.77614 15 5 15.2239 5 15.5V17.459L7.93765 15.1096C8.00414 15.0564 8.08273 15.0213 8.16577 15.0071L8.25 15H13.5C14.3284 15 15 14.3284 15 13.5V10H9.5C8.7203 10 8.07955 9.40511 8.00687 8.64446L8 8.5V5ZM17.5 1H9.5C9.22386 1 9 1.22386 9 1.5V8.5C9 8.77614 9.22386 9 9.5 9H17.5C17.7761 9 18 8.77614 18 8.5V1.5C18 1.22386 17.7761 1 17.5 1ZM16 6C16.2761 6 16.5 6.22386 16.5 6.5C16.5 6.77614 16.2761 7 16 7H11C10.7239 7 10.5 6.77614 10.5 6.5C10.5 6.22386 10.7239 6 11 6H16ZM16 3C16.2761 3 16.5 3.22386 16.5 3.5C16.5 3.77614 16.2761 4 16 4H11C10.7239 4 10.5 3.77614 10.5 3.5C10.5 3.22386 10.7239 3 11 3H16Z"
                fill={color}                
            />
        </svg>
    );
};

export default AddCommentIcon;
