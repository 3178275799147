
import { utilService } from './';
import { proofService } from '../components';
import { drawingConstants } from '@wunderman-thompson/miranda-drawing';
import  chroma  from 'chroma-js';

export const AnnotationColors = [
    { key: '0', value: '#FF0000' },
    { key: '1', value: '#00B050' },
    { key: '2', value: '#FFFF00' },
    { key: '3', value: '#FFC000' },
    { key: '4', value: '#C5E0B4' },
    { key: '5', value: '#FFFFE5' },
    { key: '6', value: '#421C5E' },
    { key: '7', value: '#0070C0' },
    { key: '8', value: '#00B0F0' },
    { key: '9', value: '#8497B0' },
    { key: '10', value: '#C9C9C9' },
    { key: '11', value: '#F2F2F2' }
];

export const HighlightColors = [
    { key: '0', value: '#fdff32' },
    { key: '1', value: '#f50b86' },
    { key: '2', value: '#8cff32' },
    { key: '3', value: '#8900ff' },
    { key: '4', value: '#05f9e2' }
];

export const contributorsHelper = {
    configureUserPreference,
    configureContributors,
    convertColorToKey,
    convertKeyToColor,
    findCommentColor,
    findFontColor,
    findFontColorFromKey,
    HighlightColors
};

function convertKeyToColor(key){
    if(key === null || key === undefined) { return undefined }
    if(key.startsWith('#')) { return key};
    var item = AnnotationColors.find(x => x.key === key );
    return item? item.value : AnnotationColors[0].value;
}

function convertColorToKey(color){          
    var item = AnnotationColors.find(x => x.value === color );
    if(item !== undefined){
        return item.key;
    }
    else {
        if (color.startsWith('#')) {
            return color;
        } 
    }
}


function configureUserPreference(currentUser, proofVersion, {setUserId, setUserFullName, setUserAnnotationColor, contributors, setContributors}) {

    var id = currentUser.id;
    setUserId(id);
    setUserFullName(currentUser.fullName);

    var preferences = proofVersion.settings?.annotationColorPreference;

   

    var currentUserPreference = preferences === undefined ? undefined : preferences.find(x => x.userID == id);
    if (currentUserPreference === undefined) {

        var defaultColor = '#ff0000'
        var currentUserPrefDefault = {
            userID: id,
            color: defaultColor,
        }
        setUserAnnotationColor(defaultColor);
        currentUserPreference = currentUserPrefDefault;
    }
    else {
        var col = convertKeyToColor(currentUserPreference.color);
        setUserAnnotationColor(col);
        currentUserPreference.color = col;
    }
    if(contributors === null){
        setContributors([currentUserPreference]);
    } 
    
    return [currentUserPreference];
}

async function configureContributors(proofVersion, annotationComments, contributors ,userId, fetchProof ,{setContributors}) {      
        
    var preferences = await getPreferences(fetchProof, proofVersion);   
    //Contributors
    if (preferences !== undefined) {
        
        var contributorsWithPreference = annotationComments.length > 0 
            ? preferences.filter(x => annotationComments.some(c => c.createdById === x.userID && x.userID !== userId)) 
            : preferences.filter(x => x.userID === userId);

        var commentsHavePreference = (annotationComments.filter(x => preferences.some(p => p.userID === x.createdById))).map((comment) => {
            
            var pref = preferences.find(x=> x.userID === comment.createdById);
            
            return {
                userID: pref.userID,
                color: convertKeyToColor(pref.color),
                avatarColor: convertKeyToColor(pref.color),
                userInitial: utilService.getUserInitial(comment.createdBy),
                fullName: comment.createdBy
            }
        });

        var contributorsHavePreference = commentsHavePreference.filter((item, index) => commentsHavePreference.findIndex(x=> x.userID === item.userID) === index && item.userID !== userId);

        var commentsHaveNotPreference = (annotationComments.filter(x => !preferences.some(p => p.userID === x.createdById))).map((comment) => {
            return {
                userID: comment.createdById,
                color: '#ff0000',
                avatarColor: '#ff0000',
                userInitial: utilService.getUserInitial(comment.createdBy),
                fullName: comment.createdBy
            }
        });

        var contributorsHaveNotPreference = commentsHaveNotPreference.filter((item, index) => commentsHaveNotPreference.findIndex(x=> x.userID === item.userID) === index && item.userID !== userId);


        var contributorsWithoutPreference = (annotationComments.filter(x => !preferences.some(p => p.userID === x.createdById))).map((comment) => {
            return {
                userID: comment.createdById,
                color: '#ff0000',
                avatarColor: '#ff0000',
                userInitial: utilService.getUserInitial(comment.createdBy),
                
            }
        });
    
        const sortedContributors = [
            ...contributors.filter(c => c.userID === userId),
            ...contributorsHavePreference,
            ...contributorsHaveNotPreference
        ];

        setContributors(sortedContributors);
    }
}

async function getPreferences(fetchProof, proofVersion) {
    var preferences;
    if (fetchProof) {
        let newProofVersion = await proofService.getProofVersion(proofVersion.proofId, proofVersion.id);
        preferences = newProofVersion.settings?.annotationColorPreference;                
    }
    else {
        preferences = proofVersion.settings?.annotationColorPreference;
    }
    return preferences === undefined ? [] : preferences;
}


function findCommentColor ({ annotationObject, annotationType }){
    const { ANNOTATION_TYPE } = drawingConstants;    
    var annotation = JSON.parse(annotationObject);
    var key =
        annotationType === ANNOTATION_TYPE.commentStamp.typeName
            ? annotation.tintColor
            : annotation.stroke;

    switch(annotationType) {
        case ANNOTATION_TYPE.commentStamp.typeName : 
            key = annotation.tintColor;
            break;
        case ANNOTATION_TYPE.textbox.typeName : 
            key = annotation.fill;
            break;
        default:
            key = annotation.stroke;
            break;
        
    }

    return convertKeyToColor(key);
};


function findFontColorFromKey(colorKey) {
    
    if (colorKey=== null){ return ``}
    
    var color = convertKeyToColor(colorKey);
    return findFontColor(color);
}


function findFontColor(color) {
       
    if (color === undefined) return;
    
    var [red, green, blue] = chroma(color).rgb();

    if ((red*0.299 + green*0.587 + blue*0.114) > 186){
        return '#000000';
    }
    else {
        return '#ffffff';
    }
}