//@ts-check
import React from 'react';
import { DrawingCanvas, ShapeAction, drawingConstants } from '@wunderman-thompson/miranda-drawing';
import { VideoPlayerControl } from './VideoPlayerControl';
import {
    uuidv4,
    readableTime,
    getProgressMarkerPosition,
    calculateAspectRatioFit,
    trimAnnotationObject,
    switchVideoEditorDirty
} from '../VideoAnnotationUtil';
import { withVideoAnnotationConsumer } from '../Context/VideoAnnotationConsumer';
import { ConfigureMetadata } from './ConfigureMetadata';
import { alertActions } from '../../../App';
import { store } from '../../../../_helpers';
import { calculateVideoAnnotationCenterPoint }  from '../../../../_helpers/annotationArrowHelper';
import { videoCommentService } from '../videoComment.service';
import { s3Service } from '../../../_shared';
import { sidebarTabViewMode, videoAnnotationConstants } from '../Context/videoAnnotation.constants';
import scrollIntoView from 'scroll-into-view-if-needed';
import { contributorsHelper } from '../../../../_helpers';
import cx from 'classnames';
import { compressImage } from '../../../../_helpers/file-helper';
import { handleCanvasObjectUpdate } from '../../canvasHelper';
import { VideoPlayerShortcuts } from './VideoPlayerShortcuts';

const { ADD } = ShapeAction;
class VideoPlayerWithCanvas extends React.Component {
    constructor(props) {
        super(props);
        this.player = null;
        this.playerWrapper = null;

        this.state = {
            canvas: null,

            durationInSeconds: 0,

            currentTimeInText: '00:00:00',

            isPaused: true,
            volume: 0,
            showConfigureMetadata: false,

            transformContainer: {}
        };
        this.mainDrawingCanvas = null;
        this.canvasContainer = null;
        this.onWindowResize = this.onWindowResize.bind(this);
        this.resizeTimer = null;
    }

    componentDidMount() {
        this.props.setVideoPlayer(this.player);        
        //this.props.videoPlayerRef = (this.player);   
        this.props.setPlayerArea(this.playerWrapper);
        this.props.setEditorArea(this.canvasContainer);
        window.addEventListener('resize', this.onWindowResize);
        store.dispatch(alertActions.loading(true));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.videoMetadata.fileURL !== this.props.videoMetadata.fileURL) {
            this.player.load();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize() {
        this.props.selectAnnotationOnCanvas();
        var mutatedAnnotations = this.props.annotationComments.map((item) => {
            var newItem = { ...item };
            newItem.position = getProgressMarkerPosition(
                item.frameRange[0],
                this.props.videoMetadata.frameCount
            );
            return newItem;
        });
        this.props.reScaleCanvasContainer();
        this.props.setAnnotationComments(mutatedAnnotations);        

        this.resizeCommentArrow();
    }

    resizeCommentArrow() {
        if(this.props.selectedComment === -1){ return;}

        this.props.setDisableArrow(true);
        if (this.resizeTimer !== null){
            clearTimeout(this.resizeTimer);
        }

        this.resizeTimer = setTimeout(() => {
            if(this.props.selectedComment === -1){
                this.props.setDisableArrow(false);
                return; 
            }
            this.props.redrawAnnotationCommentArrow();
            this.props.calculateCommentArrowPoint(this.props.selectedComment);
            this.props.setDisableArrow(false);
        }
        , 600);
    }

    debouncedWindowResized = () => {
        this.debounce(this.onWindowResize, 250);
    };

    debounce = (fn, ms, ...rest) => {
        let timer;
        return (_) => {
            clearTimeout(timer);
            timer = setTimeout((_) => {
                timer = null;
                fn.apply(this, rest);
            }, ms);
        };
    };

    onLoadedMetadata(event) {
        let video = event.target;
        var durationInSeconds = video.duration;
        var changedVideoMetadata = {
            ...this.props.videoMetadata,
            durationInSeconds,
            // videoWidth: video.videoWidth,
            // videoHeight: video.videoHeight,
            videoTagWidth: video.offsetWidth,
            videoTagHeight: video.offsetHeight
        };
        this.props.setVideoMetadata(changedVideoMetadata);
        this.props.reScaleCanvasContainer();
        video.volume = this.props.volume;
    }

    // reScaleCanvasContainer() {
    //     let video = this.player;
    //     let containerHeight = this.canvasContainer.offsetHeight - 20;
    //     let containerWidth = this.canvasContainer.offsetWidth - 20;
    //     let ratio = calculateAspectRatioFit(
    //         video.videoWidth,
    //         video.videoHeight,
    //         containerWidth,
    //         containerHeight
    //     ).toFixed(2);
    //     let transformContainer = { transform: `scale(${ratio}, ${ratio})` };
    //     this.setState({ transformContainer });
    // }

    onLoadedVideoData(event) {
        let canvasEvents = {
            onCanvasObjectUpdated: this.onCanvasObjectUpdated.bind(this),
            onCanvasTextEditEntered: this.onCanvasTextEditEntered.bind(this)
        };
        this.mainDrawingCanvas = new DrawingCanvas(this.canvas, new fabric.Canvas(this.canvas, { selection: false }), canvasEvents, this.onObjectSelectionChanged.bind(this), this.onSelectionCleared.bind(this), this.onObjectModifying.bind(this));
        this.props.setMainDrawingCanvas(this.mainDrawingCanvas);
        store.dispatch(alertActions.loading(false));


        if (this.props.isProofCompare){
            this.props.onLoadedCompareVideoData(this.player);
        }

    }

    onRangeSliderChange(seekTime) {

        this.setCurrentTime(seekTime);
    }

    setCurrentTime(seekTime) {
        this.player.currentTime = seekTime;
        
    }

    onTimeUpdate(event) {
        // var currentTime = Math.round(this.player.currentTime());
        var currentTime =Number(Number(this.player.currentTime).toFixed(2));
        if (!this.player.paused) {
            this.mainDrawingCanvas.clear();
        }
        var stateObject = {
            currentFrameNumber: Math.round(currentTime * this.props.videoMetadata.frameRate),
            currentTimeInText: readableTime(currentTime)
        };
        this.setState({ ...stateObject });
        this.props.setCurrentTimeInSeconds(currentTime);

        const { transcriptions, setCurrentCaption } = this.props;
        let currentCaption = '';
        if (
            transcriptions &&
            transcriptions.status == 'Completed' &&
            transcriptions.transcribeData &&
            transcriptions.transcribeData.length > 0
        ) {
            let currentItem = transcriptions.transcribeData?.find(
                (x) => currentTime >= x.timeRange[0] && currentTime <= x.timeRange[1]
            );
            currentCaption = currentItem ? currentItem.value : '';
            let currentTimeRangeId = currentItem
                ? `tr-${currentItem.timeRange[0]}-${currentItem.timeRange[1]}`
                : '';
            let currentDOMItem = document.getElementById(currentTimeRangeId);
            if (currentDOMItem) {
                scrollIntoView(currentDOMItem, {
                    behavior: 'smooth',
                    scrollMode: 'if-needed'
                });
            }
        }

        setCurrentCaption(currentCaption);
        // console.log('TIMECODE:', this.props.currentTimeInTimeCode, stateObject.currentFrameNumber);
        // this.props.onTimeUpdate(stateObject);
    }

    onVolumeChange(value) {        
        this.player.volume = value;

        //To be removed

        // if(this.props.isProofCompare === true){
        //     this.props.saveCompareVolume(value);
        // }
        // else {
        //     this.props.saveVolume(value);
        // }
                
        this.props.saveVolume(value);        
    }

    onPlayPauseToggle() {
        
        if (this.props.isCommentAddMode === true) {
            return;
        }

        if (this.player.paused) {
            this.player.play();
            this.setState({ isPaused: false });
        } else {
            this.player.pause();
            this.setState({ isPaused: true });
            this.props.seekToTime(this.props.currentFrameNumber);
        }

        if(this.props.isProofCompare){
            this.props.onPlayVideoCompare();
        }
    }

    handleDrop(e) {
        e = e || window.event;
        if (e.preventDefault) {
            e.preventDefault();
        }
        if (e.stopPropagation) {
            e.stopPropagation();
        }

        this.mainDrawingCanvas.toggleDrawingMode(false);
        const shapeType = e.dataTransfer.getData('shape');
        this.player.pause();
        var object = {
            top: e.clientY - e.currentTarget.getBoundingClientRect().top - 25,
            left: e.clientX - e.currentTarget.getBoundingClientRect().left
        };

        const shape = {
            id: uuidv4(),
            type: shapeType,
            action: ADD,
            onDrawCompleted: this.onShapeDrawComplete.bind(this),
            ...object
        };
        this.mainDrawingCanvas.drawShape(shape);
    }

    async onCanvasObjectUpdated(event) {                
        await handleCanvasObjectUpdate(
            this.props,
            event,
            this.props.mainDrawingCanvas,
            videoCommentService.updateVideoAnnotation,
            this.props.videoMetadata,
            'videoCommentId',
            'video'
        );
    }

    onCanvasTextEditEntered(value, hasAnyText) {
        this.props.setCanvasTextEditEntered(value || hasAnyText);
    }
    onObjectSelectionChanged(object) {
        
        var selected = object.target.uuid_parent;

        var selectedIndex = this.props.annotationComments.findIndex(x => x.id === selected);

        if (this.props.selectedComment === selectedIndex) {
            //Selection is similar
        }
        else {
            //change selected comment
            if (selectedIndex !== -1) {
                this.props.setSelectedComment(selectedIndex);
            }            
        }

        this.createArrow(object.selected[0]);
        this.props.onAnnotationSelectionChanged(object.selected[0]);

    }

    onEditorScrolled  (e) {                        
        // if (props?.selectedComment !== -1) {
        //     props.selectAnnotationOnCanvas();
        // }

        // if (e.currentTarget.scrollTop == 0 && props.shouldTranslate) {
        //     var clientRect = props.documentContainer.current.getBoundingClientRect();

        //     var y = clientRect.y;
        //     var translationValue = 0;
        //     if (y < 0) {
        //         translationValue = 147 + Math.abs(y);
        //     } else {
        //         translationValue = 147 - y;
        //     }
        //     var scale = convertZoomToScale(props.containerZoom);
        //     props.documentContainer.current.style.transform = `matrix(${scale}, 0, 0, ${scale}, 0, ${translationValue})`;

           
        //     props.setShouldTranslate(false);
        // }
        
        
        
        // if(props.isProofCompare === true) {            
        //     props.onScrolled(props.side, e, editorArea);
        // }       
        if(this.props?.selectedComment !== -1){                    
            this.props.clearCommentSelection();        
        }
    }

    
    

    onSelectionCleared(obj) {

        if (obj.e === undefined) {
            return;
        }
                
        this.props.releaseCommentSelection();               
    }
    onObjectModifying() {        
        if (this.props.disableArrow) {
            return;
        }

        this.props.setDisableArrow(true);        
    }
    createArrow(object) {  
        calculateVideoAnnotationCenterPoint(object, this.props.containerScale, this.mainDrawingCanvas, this.props.isProofCompare, this.props.hasExtraTopBar);    
    }

    onMetadataSave(metadata) {
        var changedVideoMetadata = {
            ...this.props.videoMetadata,
            frameRate: metadata.frameRate
        };
        this.props.setVideoMetadata(changedVideoMetadata);
        this.setState({ showConfigureMetadata: false });
    }

    onLeftArrowPressed  ()  {
        if(this.props.hotKeyEnabled !== true) { return }
        
        if (this.props.isCommentAddMode === true) { return }
        this.changeSeekBarPosition(videoAnnotationConstants.SEEKBAR_DIRECTION.backward);      
    }

    onRightArrowPressed () {
        if(this.props.hotKeyEnabled !== true) { return }
    
        if (this.props.isCommentAddMode === true) { return }
        this.changeSeekBarPosition(videoAnnotationConstants.SEEKBAR_DIRECTION.forward);      
    }

    onSpacePressed () {
        if(this.props.hotKeyEnabled !== true) { return }
    
        this.onPlayPauseToggle();
    }

    changeSeekBarPosition  (direction)  {
      
        const { TIME, TIME_FRAME, TIME_CODE } = videoAnnotationConstants.TIME_MODE;

        let value = 5;        
        if(direction === videoAnnotationConstants.SEEKBAR_DIRECTION.forward) {
            value = 5;
        }
        else {
            value = -5;
        }

        var newCurrentFrame = this.props.currentFrameNumber + value;
        if (value < 0 && newCurrentFrame < 0) {
            newCurrentFrame = 0;
        }
        this.props.seekToTime(newCurrentFrame);

        this.props.releaseCommentSelection();                        
        if (this.props.isProofCompare){
            this.props.seekToTimeCompare(newCurrentFrame);
        }
    }

    render() {
        const {
            videoMetadata,
            seekToTime,
            setTimeMode,
            currentCaption,
            sidebarTabView,
            videoPlayerContainerTransform,
            compareCanvas, 
            isAutoCompare
        } = this.props;
        const {
            fileURL,
            mimeType,
            videoWidth,
            videoHeight,
            videoTagWidth,
            videoTagHeight
        } = videoMetadata;
        // console.log('VIDEO META', videoMetadata);
        let containerHeigh =
            sidebarTabView === sidebarTabViewMode.COMMENT
                ? 'calc(100% - 185px)'
                : 'calc(100% - 265px)';
        return (
            <>
                <div
                    ref={(node) => (this.canvasContainer = node)}
                    id="mrnda-canvas-container"
                    className={cx("mrnda-video-editor-area-canvas-container",{  "mrnda-video-editor-area-canvas-container--mobile" :  this.props.isMobile})} 
                    style={{ width: '100%', height: containerHeigh, overflow: this.props.isProofCompare? 'hidden': 'auto' }}
                    // width={960}
                    // height={videoTagHeight}
                    onScroll = {this.onEditorScrolled.bind(this)}
                    onDrop={this.handleDrop.bind(this)}
                    onMouseEnter={this.props.onPrepareForDrawing}
                    onMouseLeave={this.props.onCleanUpDrawing}                        
                    >
                    <VideoPlayerShortcuts spaceHandler={this.onSpacePressed.bind(this)} leftArrowHandler = {this.onLeftArrowPressed.bind(this)} rightArrowHandler = {this.onRightArrowPressed.bind(this)} ></VideoPlayerShortcuts>
                    <div
                        ref={(node) => (this.playerWrapper = node)}
                        className="mrnda-video-editor-area-canvas-wrapper"
                        style={{ ...videoPlayerContainerTransform }}>
                        <canvas
                            className={cx({
                            'auxiliary' : true,
                            'main-drawing-canvas': true,
                            'd-transparent' : this.props.isAutoCompare})}
                        
                            ref={(node) => (this.canvas = node)}
                            id="canvas"                            
                            style={{
                                zIndex: 30,
                                position: 'absolute'
                                // width: '960px',
                                // height: videoTagHeight
                            }}
                            width={videoWidth}
                            height={videoHeight}
                        />

                        {this.props.isProofCompare && (
                            <>
                                {React.cloneElement(compareCanvas, {
                                    // pageSrc: this.props.src,
                                    // index: this.props.index,
                                    // compareIndex: this.props.selectedCanvas
                                })}
                            </>
                        )}

                        <div
                            style={
                                {
                                    // width: 960,
                                    // height: 'auto'
                                }
                            }
                            className="mrnda-video-editor-area-videoplayer-wrapper">                         
                            <video
                                ref={(node) => (this.player = node)}
                                crossOrigin="anonymous"
                                className="mrnda-video-editor-area-videoplayer-wrapper--video"
                                style={
                                    {
                                        // width: '100%'
                                    }
                                }
                                // width={960}
                                // height={400}
                                // controls

                                preload="auto"
                                // poster={this.props.videoMetadata.poster}
                                data-setup="{}"
                                onLoadedMetadata={this.onLoadedMetadata.bind(this)}
                                onLoadedData={this.onLoadedVideoData.bind(this)}
                                onTimeUpdate={this.onTimeUpdate.bind(this)}
                                onPlay={() => this.setState({ isPaused: false })}
                                onPause={() => this.setState({ isPaused: true })}
                                onEnded={() => this.setState({ isPaused: true })}
                                playsInline
                                >
                                <source src={fileURL} type={mimeType} />
                            </video>
                            
                        </div>
                    </div>                    
                </div>
                {sidebarTabView == sidebarTabViewMode.TRANSCRITPION && (
                    <div
                        className="mrnda-video-canvas__player-area__caption"
                        title={currentCaption}>
                        {currentCaption && (
                            <span className={'mrnda-video-canvas__player-area__caption--text'}>
                                {currentCaption}
                            </span>
                        )}
                    </div>
                )}
                {/* volume={this.props.isProofCompare === true ?  this.props.compareVolume : this.props.volume} */}
                <VideoPlayerControl
                    isPaused={this.state.isPaused}
                    onVolumeChange={this.onVolumeChange.bind(this)}
                    volume={this.props.volume}
                    videoMetadata={videoMetadata}
                    onPlayPauseToggle={this.onPlayPauseToggle.bind(this)}
                    setCurrentTime={this.setCurrentTime.bind(this)}
                    seekToTime={(frame) => {
                        seekToTime(frame);
                        this.props.releaseCommentSelection();                        
                        if (this.props.isProofCompare){
                            this.props.seekToTimeCompare(frame);
                        }

                    }}
                    durationInSeconds={this.state.durationInSeconds}
                    onRangeSliderChange={this.onRangeSliderChange.bind(this)}
                    onTimeModeChange={(mode) => setTimeMode(mode)}
                    onMetadataConfigureShow={() => this.setState({ showConfigureMetadata: true })}
                    showAnnotationMarkers={true}
                    overrideTheme={false}
                    framePickerCheckClicked = {() => {
                        videoCommentHelper.saveComment("", undefined, undefined,this.props);
                    }}
                    framePickerCloseClicked = {() => {                        
                        videoCommentHelper.cancelComment(this.props);
                    }}
                />
                {this.state.showConfigureMetadata && (
                    <ConfigureMetadata
                        show={this.state.showConfigureMetadata}
                        onHide={() => this.setState({ showConfigureMetadata: false })}
                        onClose={() => this.setState({ showConfigureMetadata: false })}
                        onMetadataSave={this.onMetadataSave.bind(this)}
                    />
                )}
            </>
        );
    }
}
const hoc = withVideoAnnotationConsumer(VideoPlayerWithCanvas);
export { hoc as VideoPlayerWithCanvas };
