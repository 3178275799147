import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    AdminDashboard,
    TenantRegisterPage,
    PrivateRoute,
    AdminLoginPage,
    HomePage,
} from '../../../components';
import NavBar from '../Nav';

function mapStateToProps() {
    return {};
}

class AdminTenantPage extends Component {
    render() {
        return (
            <>
                <NavBar />
                <Switch>
                    <PrivateRoute
                        exact
                        path="/dashboard"
                        component={AdminDashboard}
                        landingPath={"./login"}
                        loginPath="login"
                        bypassAuthorization={true}
                    />
                     <PrivateRoute
                        exact
                        path="/dashboard/folders"
                        component={AdminDashboard}
                        landingPath={"./login"}
                        loginPath="/login"
                        bypassAuthorization={true}
                    />
                     <PrivateRoute
                        exact
                        path="/"
                        component={AdminDashboard}
                        loginPath="/login"
                        landingPath={"./login"}
                        permissionKey={{}}
                        bypassAuthorization={true}
                    />
                    <PrivateRoute
                        exact
                        path="/home"
                        component={AdminDashboard}
                        loginPath="/login"
                        landingPath={"./login"}
                        permissionKey={{}}
                        bypassAuthorization={true}
                    />
                    {/* <PrivateRoute
            exact
            path="/"
            component={AdminDashboard}
            loginPath="login"
          /> */}
                    <PrivateRoute
                        exact
                        path="/register"
                        component={TenantRegisterPage}
                        loginPath="login"
                        landingPath={"./login"}
                        bypassAuthorization={true}
                    />
                    <Redirect path='/landing' to={{ pathname: '/login'}} />
                    <Route path="/login" component={AdminLoginPage} />
                    {/* <Route path="/" component={HomePage} /> */}
                    {/* <Route path="/register" component={TenantRegisterPage} /> */}
                </Switch>
            </>
        );
    }
}

export default connect(mapStateToProps)(AdminTenantPage);
