import React from 'react';
export const CreateProofComponent = ({ classes, isAuthorized, onClick }) => {
    var tootltipProps = !isAuthorized
        ? {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Not authorized',
              delay: 0
          }
        : null;
    return (
        <a
            className={isAuthorized ? classes : classes + ' authorization-disabled'}
            onClick={isAuthorized ? onClick : () => {}}
            {...tootltipProps}>
            New File
        </a>
    );
};
export const ProofEditButton = ({ classes, isAuthorized, onClick, disabled }) => {
    var tootltipProps = !isAuthorized
        ? {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Not authorized',
              delay: 0
          }
        : null;
    return (
        <li
            onClick={isAuthorized && !disabled ? onClick : () => {}}
            className={isAuthorized && !disabled ? classes : classes + ' authorization-disabled'}
            {...tootltipProps}>
            <span className={isAuthorized ? 'mrnda-btn' : 'mrnda-btn mrnda-btn--disabled'}>
                Annotate File
            </span>
        </li>
    );
};
export const AutoCompareButton = ({ classes, isAuthorized, onClick, disabled }) => {
    var tootltipProps = !isAuthorized
        ? {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Not authorized',
              delay: 0
          }
        : null;
    return (
        <a
            onClick={isAuthorized && !disabled ? onClick : () => {}}
            className={isAuthorized && !disabled ? classes : classes + ' authorization-disabled'}
            {...tootltipProps}>
            Compare
        </a>
    );
};
export const CreateProofVersionButton = ({ classes, isAuthorized, onClick }) => {
    var tootltipProps = !isAuthorized
        ? {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Not authorized',
              delay: 0
          }
        : null;
    return (
        <a
            className={isAuthorized ? classes : classes + ' authorization-disabled'}
            onClick={isAuthorized ? onClick : () => {}}
            {...tootltipProps}>
            New File Version
        </a>
    );
};
export const MoveProofButton = ({ classes, isAuthorized, onClick }) => {
    var tootltipProps = !isAuthorized
        ? {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Not authorized',
              delay: 0
          }
        : null;
    return (
        <a
            onClick={isAuthorized ? onClick : () => {}}
            className={isAuthorized ? classes : classes + ' authorization-disabled'}
            {...tootltipProps}>
            Move
        </a>
    );
};
export const CopyProofButton = ({ classes, isAuthorized, onClick, disabled }) => {
    var tootltipProps = !isAuthorized
        ? {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Not authorized',
              delay: 0
          }
        : null;
    return (
        <a
            onClick={isAuthorized && !disabled ? onClick : () => {}}
            className={isAuthorized && !disabled ? classes : classes + ' authorization-disabled'}
            {...tootltipProps}>
            Copy
        </a>
    );
};
export const ProofSummaryButton = ({ classes, isAuthorized, onClick, disabled }) => {
    var tootltipProps = !isAuthorized
        ? {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Not authorized',
              delay: 0
          }
        : null;
    return (
        <a
            onClick={isAuthorized && !disabled ? onClick : () => {}}
            className={isAuthorized && !disabled ? classes : classes + ' authorization-disabled'}
            {...tootltipProps}>
            Annotation Report
        </a>
    );
};
export const DeleteProofButton = ({ classes, isAuthorized, onClick }) => {
    var tootltipProps = !isAuthorized
        ? {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Not authorized',
              delay: 0
          }
        : null;
    return (
        <a
            onClick={isAuthorized ? onClick : () => {}}
            className={isAuthorized ? classes : classes + ' authorization-disabled'}
            {...tootltipProps}>
            Delete File
        </a>
    );
};
export const DeleteProofVersionButton = ({ classes, isAuthorized, disabled, onClick }) => {
    var tootltipProps = !isAuthorized
        ? {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Not authorized',
              delay: 0
          }
        : {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Delete File Version',
              delay: 0
          };
    return (
        <button
            className={isAuthorized ? classes : classes + ' authorization-disabled'}
            style={{ width: '100%' }}
            disabled={disabled}
            onClick={isAuthorized ? onClick : () => {}}
            {...tootltipProps}>
            <i className="far fa-trash-alt mr-2" />
        </button>
    );
};
export const SetDefaultButton = ({ classes, isAuthorized, disabled, onClick }) => {
    var tootltipProps = !isAuthorized
        ? {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Not authorized',
              delay: 0
          }
        : {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Set Default',
              delay: 0
          };
    return (
        <button
            className={isAuthorized ? classes : classes + ' authorization-disabled'}
            disabled={disabled}
            onClick={isAuthorized ? onClick : () => {}}
            {...tootltipProps}>
            <i className="fas fa-bookmark pl-1 pr-1"></i>
        </button>
    );
};
export const DownloadVersionButton = ({ classes, isAuthorized, disabled }) => {
    var tootltipProps = !isAuthorized
        ? {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Not authorized',
              delay: 0
          }
        : {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Download',
              delay: 0
          };
    return (
        <button
            type="button"
            className={isAuthorized ? classes : classes + ' authorization-disabled'}
            disabled={disabled}
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            {...tootltipProps}>
            <i className="fas fa-arrow-alt-circle-down pl-1 pr-1"></i>
        </button>
    );
};
export const DowloadOriginalVersionButton = ({ classes, isAuthorized, disabled, onClick }) => {
    // var tootltipProps = !isAuthorized
    //     ? {
    //           datatoggle: 'tooltip',
    //           dataplacement: 'top',
    //           title: 'Not authorized',
    //           delay: 0,
    //       }
    //     : {
    //           datatoggle: 'tooltip',
    //           dataplacement: 'top',
    //           title: 'Download Annotated Version',
    //           delay: 0,
    //       };
    return (
        <>
            <a
                className={isAuthorized ? classes : classes + ' authorization-disabled'}
                disabled={disabled}
                onClick={isAuthorized ? onClick : () => {}}>
                <span>Download Original</span>
            </a>
        </>
    );
};
export const DowloadAnnotatedVersionButton = ({ classes, isAuthorized, onClick, disabled }) => {
    // var tootltipProps = !isAuthorized
    //     ? {
    //           datatoggle: 'tooltip',
    //           dataplacement: 'top',
    //           title: 'Not authorized',
    //           delay: 0,
    //       }
    //     : {
    //           datatoggle: 'tooltip',
    //           dataplacement: 'top',
    //           title: 'Download Annotated Version',
    //           delay: 0,
    //       };
    return (
        <>
            <a
                className={
                    isAuthorized && !disabled ? classes : classes + ' authorization-disabled'
                }
                disabled={disabled}
                onClick={isAuthorized || disabled ? onClick : () => {}}>
                <span>Download Annotated</span>
            </a>
        </>
    );
};
export const ShareVersionButton = ({ classes, isAuthorized, disabled, onClick }) => {
    var tootltipProps = !isAuthorized
        ? {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Not authorized',
              delay: 0
          }
        : {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Notify',
              delay: 0
          };
    return (
        <button
            className={isAuthorized ? classes : classes + ' authorization-disabled'}
            disabled={disabled}
            onClick={isAuthorized ? onClick : () => {}}
            {...tootltipProps}>
            <i className="fas fa-at"></i>
        </button>
    );
};
export const LockUnlockButton = ({ classes, isAuthorized, disabled, onClick, isLocked }) => {
    var tootltipProps = !isAuthorized
        ? {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Not authorized',
              delay: 0
          }
        : {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: isLocked ? 'Unlock' : 'Lock',
              delay: 0
          };
    return (
        <button
            className={isAuthorized ? classes : classes + ' authorization-disabled'}
            disabled={disabled}
            onClick={isAuthorized ? onClick : () => {}}
            {...tootltipProps}>
            {isLocked ? <i className="fas fa-lock"></i> : <i className="fas fa-lock-open"></i>}
        </button>
    );
};
