import { providerConstants } from '../../components';

export function providersData(state = {}, action) {
    switch (action.type) {
        case providerConstants.GETALL_REQUEST:
            return {
                loading: true,
            };
        case providerConstants.GETALL_SUCCESS:
            return {
                rows: action.data.list,
                pageIndex: action.data.pageIndex,
                pageSize: action.data.pageSize,
                totalCount: action.data.totalCount,
                totalPages: action.data.totalPages,
            };
        case providerConstants.GETALL_FAILURE:
            return {
                error: action.error,
            };
        case providerConstants.DELETE_REQUEST:
            // add 'deleting:true' property to role being deleted
            return {
                ...state,
                items: state.items.map(provider =>
                    provider.id === action.id ? { ...provider, deleting: true } : provider,
                ),
            };
        case providerConstants.DELETE_SUCCESS:
            // remove deleted role from state
            return {
                items: state.items.filter(provider => provider.id !== action.id),
            };
        case providerConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to role
            return {
                ...state,
                items: state.items.map(provider => {
                    if (provider.id === action.id) {
                        // make copy of role without 'deleting:true' property
                        const { deleting, ...providerCopy } = provider;
                        // return copy of role with 'deleteError:[error]' property
                        return { ...providerCopy, deleteError: action.error };
                    }

                    return provider;
                }),
            };
        default:
            return state;
    }
}

export function providersFilters(state = {}, action) {
    switch (action.type) {
        case providerConstants.GETALL_FILTERS_REQUEST:
            return {
                loading: true,
            };
        case providerConstants.GETALL_FILTERS_SUCCESS:
            return {
                providersFilters: action.rolesFilters,
            };
        case providerConstants.GETALL_FILTERS_FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}

// export function moduleActionPerissions(state = {}, action) {
//     switch (action.type) {
//         case roleConstants.GET_MODULE_PERMISSION_REQUEST:
//             return {
//                 loading: true,
//             };
//         case roleConstants.GET_MODULE_PERMISSION_SUCCESS:
//             return {
//                 moduleActionPermissions: action.moduleActionPermissions,
//             };
//         case roleConstants.GET_MODULE_PERMISSION_FAILURE:
//             return {
//                 error: action.error,
//             };
//         default:
//             return state;
//     }
// }
