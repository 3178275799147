import cx from 'classnames'; // Assuming cx is imported from the `classnames` library

const conditionalClassName = (baseClassName, flag, suffix) => {
    const classNames = {};

    

    if (flag) {
        classNames[`${baseClassName}--${suffix}`] = true;
    }
    else {
        classNames[baseClassName] = true;
    }

    return cx(classNames);
};

export { conditionalClassName };
