//@ts-check

import React from 'react';

const ChevronLeftIcon = ({ height = 20, width = 20, color = 'currentColor', ...rest }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"            
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
            >
            <path
                d="M12.3536 16.3536C12.5488 16.1583 12.5488 15.8417 12.3536 15.6464L6.707 10L12.3536 4.35355C12.5271 4.17999 12.5464 3.91056 12.4114 3.71569L12.3536 3.64645C12.1583 3.45118 11.8417 3.45118 11.6464 3.64645L5.64645 9.64645C5.45118 9.84171 5.45118 10.1583 5.64645 10.3536L11.6464 16.3536C11.8417 16.5488 12.1583 16.5488 12.3536 16.3536Z"
                fill={color}                
            />
        </svg>
    );
};

export default ChevronLeftIcon;
