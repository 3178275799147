import {
    authHeader,
    config,
    handleResponse
} from '../../_helpers';

export const thirdPartyIntegrationService = {        
    getLucidLinkConfiguration,
    saveLucidLinkConfiguration,
    getLucidLinkedDirectory
};

async function getLucidLinkConfiguration() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${config.apiUrl}/lucidlink/getConfiguration`, requestOptions).then(handleResponse);
}

async function saveLucidLinkConfiguration(requestData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
    };

    return fetch(`${config.apiUrl}/lucidlink/saveConfiguration`, requestOptions).then(handleResponse);
}

async function getLucidLinkedDirectory() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${config.apiUrl}/lucidlink/getlucidlinkeddirectory`, requestOptions).then(handleResponse);
}
