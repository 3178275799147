import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { authorizationService } from '../../_helpers';

const AuthorizationComponent = ({ component: Component, permissionKey, rolePermission, ...props }) => {
    var isAuthorized =
        permissionKey &&
        authorizationService.isUserPermitted(permissionKey.module, permissionKey.action);
        //NOTE : rolePermission is needed for rerendering components on role change
    return <Component rolePermission isAuthorized={isAuthorized} {...props} />;
};

function mapStateToProps(state) {
    const { authentication } = state;
    var rolePermission = authentication?.user?.rolePermission || [] ;
    return {
        rolePermission
    };
}


const connected = connect(mapStateToProps)(AuthorizationComponent);
export { connected as AuthorizationComponent };