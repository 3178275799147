import { proofVideoTranscribeConstants } from './proofVideoTranscibe.constants';
const {
    PROOFVIDEO_GETTRANSCRIBETEXT_REQUEST,
    PROOFVIDEO_GETTRANSCRIBETEXT_SUCCESS,
    PROOFVIDEO_GETTRANSCRIBETEXT_FAILURE

} = proofVideoTranscribeConstants;
export function videoProofTranscribeData(state = {}, action) {

    switch (action.type) {

        case PROOFVIDEO_GETTRANSCRIBETEXT_REQUEST:
            return {
                ...state,
                transcribeResult: action.transcribeResult
            };
        case PROOFVIDEO_GETTRANSCRIBETEXT_SUCCESS:

            return {
                ...state,
                transcribeResult: action.transcribeResult
            };

        case PROOFVIDEO_GETTRANSCRIBETEXT_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        default:
            return state;
    }
}