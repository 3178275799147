import { authHeader, handleResponse, config } from '../../_helpers';

export const groupService = {
    login,
    logout,
    add,
    getAll,
    getById,
    update,
    delete: _delete,
    getCurrentgroup,
    getAllGroupFilters,
    getAllMembers,
    isNameUnique
};

function login(groupname, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'mode': 'cors' },
        body: JSON.stringify({ 
            Email: groupname, 
            Password: password })
    };
    return fetch(`${config.apiUrl}/auth/login`, requestOptions)
        .then(handleResponse)
        .then(group => {
            // store group details and jwt token in local storage to keep group logged in between page refreshes
            localStorage.setItem('group', JSON.stringify(group));
            console.log("35: Inside groupService", group);
            return group;
        });
    //return group;
}

function logout() {
    // remove group from local storage to log group out
    localStorage.removeItem('group');
    localStorage.removeItem('tenant');
}

function getAll(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj),
    };
    return fetch(`${config.apiUrl}/group/getgroups`, requestOptions).then(handleResponse);
}
function getAllGroupFilters() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        // body: tenantId
    };

    return fetch(`${config.apiUrl}/group/allgroupfilters`, requestOptions).then(handleResponse);
}

function getAllMembers(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj),
    };
    return fetch(`${config.apiUrl}/group/getmembers`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/groups/${id}`, requestOptions).then(handleResponse);
}

function add(group) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(),'Content-Type': 'application/json' },
        body: JSON.stringify(group)
    };

    return fetch(`${config.apiUrl}/group/add`, requestOptions).then(handleResponse);
}

function update(group) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(),'Content-Type': 'application/json' },
        body: JSON.stringify(group)
    };

    return fetch(`${config.apiUrl}/group/update`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(),'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/group/delete/${id}`, requestOptions).then(handleResponse);
}

async function getCurrentgroup() {
    let groupFromLocalStorage = JSON.parse(localStorage.getItem('group'));
    // const requestOptions = {
    //     method: 'GET',
    //     headers: authHeader()
    // };

    // return fetch(`${config.apiUrl}/groups/${groupFromLocalStorage.id}`, requestOptions).then(handleResponse);

    // return new Promise((resolve, reject) => {
    //     resolve(groupFromLocalStorage);
    // });
    return groupFromLocalStorage;
}
async function isNameUnique(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject)
    };

    return fetch(
        `${config.apiUrl}/group/isunique`,
        requestOptions
    ).then(handleResponse);
}
function handleResponseBadly(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}