//@ts-check

import React from 'react';

const EditIcon = ({ height = 20, width = 20, color = 'currentColor', ...rest }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g fill={color}>
                    <rect id="Rectangle" fillOpacity="0" x="0" y="0" width="20" height="20"></rect>
                    <g id="Outline" transform="translate(2.000000, 1.414214)" fillRule="nonzero">
                        <path
                            d="M8,0.585786438 C8.27614237,0.585786438 8.5,0.809644063 8.5,1.08578644 C8.5,1.36192881 8.27614237,1.58578644 8,1.58578644 L2.5,1.58578644 C1.67157288,1.58578644 1,2.25735931 1,3.08578644 L1,14.0857864 C1,14.9142136 1.67157288,15.5857864 2.5,15.5857864 L13.5,15.5857864 C14.3284271,15.5857864 15,14.9142136 15,14.0857864 L15,8.58578644 C15,8.30964406 15.2238576,8.08578644 15.5,8.08578644 C15.7761424,8.08578644 16,8.30964406 16,8.58578644 L16,14.0857864 C16,15.4664983 14.8807119,16.5857864 13.5,16.5857864 L2.5,16.5857864 C1.11928813,16.5857864 0,15.4664983 0,14.0857864 L0,3.08578644 C0,1.70507456 1.11928813,0.585786438 2.5,0.585786438 L8,0.585786438 Z M15.5606602,0.439339828 L16.1464466,1.02512627 C16.732233,1.6109127 16.732233,2.56066017 16.1464466,3.14644661 L8.85355339,10.4393398 C8.78375312,10.5091401 8.69485363,10.5567177 8.59805807,10.5760768 L6.09805807,11.0760768 C5.7481975,11.1460489 5.43973755,10.8375889 5.50970966,10.4877284 L6.00970966,7.98772837 C6.02906877,7.89093281 6.07664634,7.80203332 6.14644661,7.73223305 L13.4393398,0.439339828 C14.0251263,-0.146446609 14.9748737,-0.146446609 15.5606602,0.439339828 Z M14.1464466,1.14644661 L6.961,8.33078644 L6.637,9.94778644 L8.253,9.62478644 L15.4393398,2.43933983 C15.6129062,2.26577348 15.6321913,1.99634908 15.4971953,1.80148093 L15.4393398,1.73223305 L14.8535534,1.14644661 C14.6582912,0.951184464 14.3417088,0.951184464 14.1464466,1.14644661 Z"
                            id="Combined-Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default EditIcon;
