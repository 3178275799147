import localStorage from 'local-storage';
import { alertActions } from '../components';
import { store } from './store';

export const NAV_BAR_TYPE = {
    regular: 'Regular',
    compact : 'Compact'    
}

export const getNavConfig = () => {    
    var preference = localStorage.get('isHosted') || 'false';
    let navType = preference === "true" ? NAV_BAR_TYPE.compact:  NAV_BAR_TYPE.regular;
    //let navType = NAV_BAR_TYPE.compact;
    const NavConfig = { type: navType};
    return NavConfig;
}

export const isCompactNav = () => {
    var navBarConfig = getNavConfig();
    return navBarConfig.type === NAV_BAR_TYPE.compact;
}

export const isRegularNav = () => { 
    var navConfig = getNavConfig();   
    return navConfig.type === NAV_BAR_TYPE.regular;    
}

export const clearNavConfig = () => {
    //store.dispatch(alertActions.toggleNavType(false));    
    if (window.location === window.parent.location) {       
        localStorage.set('isHosted', 'false');
    }   
}