import React from 'react';

export const DataLoadingIndicator = ({ height = '100%', width = '100%' }) => {
    return (
        <div
            className="d-flex"
            style={{
                height: height,
                width: width
            }}>
            <div className="mrnda-empty-state-wrapper">
                <div className="mrnda-empty-state" style={{padding : '0'}}>
                    <i
                        style={{
                            fontSize: '28px',
                            marginBottom: '12px'
                        }}
                        className="fas fa-hourglass-half typography--muted"></i>
                    <p
                        className="typography--h1 typography--light typography--muted loading"
                        style={{ fontSize: '18px' }}>
                        Loading<span className="one">.</span>
                        <span className="two">.</span>
                        <span className="three">.</span>
                    </p>
                </div>
            </div>
        </div>
    );
};
