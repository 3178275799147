import { useEffect, useCallback } from 'react';

function useKeyboardShortcut(key, handler) {
    useEffect(() => {
        window.addEventListener('keydown', keyDownHandler);

        return () => {
            window.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    const keyDownHandler = useCallback((event) => {                    
        if (event.key === key) {
            handler();        
        }
    }, []);
}

export { useKeyboardShortcut };
