import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import AttachmentViewer from './AttachmentsViewer';
const AttachmentsListModal = ({
    show,
    onClose,
    attachments,
    onAttachmentDelete,
    onAttachmentClicked,
    showDelete
}) => {
    return (
        <Modal
            show={show}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={'static'}
            dialogClassName="attachments-list-modal"
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">All Attachments</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{marginBottom : '8px'}}>
                <AttachmentViewer
                    attachments={attachments}
                    onAttachmentDelete={onAttachmentDelete}
                    onAttachmentClicked={onAttachmentClicked}
                    showDelete={showDelete}
                    truncate={false}
                />
            </Modal.Body>
        </Modal>
    );
};

export { AttachmentsListModal };
