import React from 'react';

export const SelectedShapeIndicator = ({height, width, left, top}) => {
    return (
        <div
            id="selected-canvas-object-indicator"
            style={{
                position: 'absolute',
            }}>
            {' '}
        </div>
    );
};
