import React, { useState, useRef, useEffect } from 'react';
import ContributorColorPicker from './ContributorColorPicker';
import { utilService,contributorsHelper } from '../../_helpers';
import { proofService } from './proof.service';

const MAX_CONTRIBUTORS = 4;

function ContributorsCollapsed({
    contributors,
    total,
    userId,
    showColorPicker,
    setShowColorPicker
}) {
    const [open, setOpen] = useState(false);
    const isFocused = useRef(false);
    return (
        <div className="contributors-collapsed">
            <div
                className="contributor-more--item"
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => {
                    setTimeout(() => {
                        console.log(`on leave : ${isFocused.current}`);
                        if (!isFocused.current){
                            setOpen(false);
                        }
                        
                    }, 200);
                }}>
                <div className="contributor-more--item--inner"></div>
                <span> +{total} </span>
            </div>

            {open && (
                <div
                    className="contributors-collapsed-dropdown"
                    onMouseEnter={() => {
                        console.log('dropdown hovered');
                        isFocused.current = true;

                    }
                    }
                    onMouseLeave={() => {
                        console.log('dropdown hover moved');
                        isFocused.current = false;
                        setOpen(false);
                    }}>
                    <div className="contributors-collapsed-dropdown-inner">
                        <ContributorsList
                            contributors={contributors?.slice(0, MAX_CONTRIBUTORS)}
                            userId={userId}
                            setShowColorPicker={setShowColorPicker}
                            showColorPicker={showColorPicker}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}


const ContributorItem = ({ 
    contributor,
    userId,
    showSeparator,
    showColorPicker,
    setShowColorPicker, 
    index}) => {


    const [fontColor, setFontColor] = useState();
    useEffect(() => {        
        var fontCol = contributorsHelper.findFontColor(contributor.color);
        setFontColor(fontCol);

    }, [])
    useEffect(() => {                
        var fontCol = contributorsHelper.findFontColor(contributor.color);
        setFontColor(fontCol);
    }, [contributor])

    return (

        <div style={{ display: 'flex' }} data-title={contributor.fullName}>
            {showSeparator && index === 1 && (
                <div className="contributors-space" />
            )}
            <div
                style={{ 
                    background:
                        contributor.avatarColor === undefined
                            ? contributor.color
                            : contributor.avatarColor                    
                }}
                className="mrnda-proof-editor-contributors--item"
                onClick={() => {
                    if (contributor.userID !== userId) {
                        return;
                    }
                    setShowColorPicker(!showColorPicker);
                }}>                
                {contributor.userID === userId ? (
                    <i style={{ color: fontColor }} className="fas fa-pencil-alt" />
                ) : (
                        <span style={{ color: fontColor }} 
                              className="contributor-avatar--secondary--small">

                            {' '}
                            {contributor.userInitial}{' '}
                        </span>
                )}
            </div>
        </div>

    );


}

const ContributorsList = ({
    contributors,
    userId,
    showSeparator,
    showColorPicker,
    setShowColorPicker
}) => {

    

    return (
        <>
            {contributors?.map((contributor, index) => {
                return (
                    <div key={contributor.userID}>
                        <ContributorItem 
                        contributor={contributor}
                        userId={userId}
                        showSeparator={showSeparator}
                        showColorPicker={showColorPicker}
                        setShowColorPicker={setShowColorPicker}
                        index = {index}
                        ></ContributorItem>

                    </div>
                );
            })}
        </>
    );
};

const ContributorsComponent = ({
    contributors,
    userAnnotationColor,
    proofVersion,
    userId,
    userFullName,
    setContributors,
    reDrawCurrentUserAnnotations,
    setUserAnnotationColor
}) => {
    const [showColorPicker, setShowColorPicker] = useState(false);
    
    const onCompareColorChange = async ({key, value: color}) => {
        var request = {
            proofId: proofVersion.proofId,
            Preference: {
                userID: userId,
                color: key,
                userInitial: utilService.getUserInitial(userFullName)
            }
        };

        let response = await proofService.updateAnnotationColorPreference(request);

        var newContributors = contributors.map((e, i) => {
            let newItem = { ...e };
            i === 0 && (newItem.color = color);
            return newItem;
        });

        setContributors(newContributors);        
        setUserAnnotationColor(color);
    };

    return (
        <div className="mrnda-proof-editor-contributors">
            <ContributorsList
                contributors={contributors?.slice(0, MAX_CONTRIBUTORS)}
                userId={userId}
                setShowColorPicker={setShowColorPicker}
                showSeparator={true}
                showColorPicker={showColorPicker}
            />
            {contributors?.length > MAX_CONTRIBUTORS && (
                <div>
                    <ContributorsCollapsed
                        contributors={contributors.slice(MAX_CONTRIBUTORS, contributors.length)}
                        total={contributors?.length - MAX_CONTRIBUTORS}
                        userId={userId}
                        showSeparator={false}
                    />
                </div>
            )}

            {showColorPicker && (
                <div
                    className="document-editor-annotation-color-picker"
                    tabIndex="0"
                    onBlur={() => setShowColorPicker(false)}>
                    <div className="document-editor-annotation-color-picker__colors">
                        <ContributorColorPicker
                            selectedColor={userAnnotationColor}
                            onSelectedColorChanged={onCompareColorChange}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContributorsComponent;
