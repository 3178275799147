import React, { useState } from 'react';
import AttachmentItem from './AttachmentItem';
import { AttachmentsListModal } from './AttachmentsListModal';
import cx from 'classnames';
import { CSSTransition } from "react-transition-group";
import { useScreenSizes } from '../../../../responsive/useScreenSizes';


const MAX_ITEMS = 3;

const AttachmentViewer = ({
    attachments,
    onAttachmentDelete,
    onAttachmentClicked,
    showDelete,
    attachmentFor
}) => {
    const { isMobile } = useScreenSizes();
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [truncate, setTruncate] = useState(true);

    
    return (
        <>
            {attachments && attachments.length > 0 && (
                <>
                    <div style={isMobile && attachmentFor === "comment" ?  { margin : '12px 0px 0px 0px' , padding: '0', border: 'none' } : {}} className={`${attachmentFor}__attachments-list`}>
                        {attachments
                            .slice(0, truncate ? MAX_ITEMS : attachments.length)
                            .map((attachment) => {
                                return (
                                    <AttachmentItem
                                        key={attachment.id}
                                        attachment={attachment}
                                        onAttachmentDelete={onAttachmentDelete}
                                        onAttachmentClicked={onAttachmentClicked}
                                        showDelete={showDelete}
                                        attachmentFor={attachmentFor}
                                    />
                                );
                            })}
                    </div>

                    {attachments.length > MAX_ITEMS && (
                        
                            <div
                                className={`attachment ${attachmentFor}-attachment__collapsed`}
                                onClick={() => {
                                    setTruncate(!truncate);
                                }}>
                                <div className="attachment__body">
                                    <span
                                        className={cx({
                                            attachment__icon: true,
                                            'attachment__icon-rotate': !truncate
                                        })}>
                                        <i className="fas fa-chevron-down" />
                                    </span>

                                    <span className="attachment__text">
                                        {truncate
                                            ? `${attachments.length - MAX_ITEMS} more attachments`
                                            : 'Collapse attachments'}
                                    </span>
                                </div>
                            </div>                        
                    )}
                </>
            )}

            {showDetailsModal && truncate && (
                <AttachmentsListModal
                    onClose={() => setShowDetailsModal(false)}
                    show={showDetailsModal}
                    onAttachmentDelete={onAttachmentDelete}
                    onAttachmentClicked={onAttachmentClicked}
                    attachments={attachments}
                />
            )}
        </>
    );
};

export default AttachmentViewer;
