import React from 'react';

const CustomEmailTemplateBuilderRow = ({
    isHeaderRow,
    placeHolder,
    value: Value,
    templatesUploaderRef,
    emailSettings,
    setEmailSettings,
    submitValidation,
    settingPropName,
    required
}) => {
    return (
        <div className="custom-template-builder-row">
            {/* <div className="custom-template-builder-cell custom-template-builder-cell-action ">
                {isHeaderRow === false && (
                    <>
                        <button
                            style={{ background: 'none'}}
                            className="mrnda-icon-btn typography--dark"
                            >
                           
                            <i className="fas fa-pen"></i>
                        </button>
                        <button
                            style={{marginLeft: '12px', background: 'none'}}
                            className="mrnda-icon-btn typography--dark"
                            >
                           
                            <i className="fas fa-trash-alt"></i>
                        </button>
                    </>
                )}
            </div> */}

            <div className="custom-template-builder-cell custom-template-builder-cell-placeholder">
                <div>{placeHolder} 
                
                </div>
                {
                    required === true && 
                    <div className='custom-template-builder-cell-placeholder-required' >*</div>
                }
            </div>

            <div className="custom-template-builder-cell custom-template-builder-cell-value">
                {isHeaderRow === true ? (
                    <div>{Value}</div>
                ) : (
                   React.createElement(Value, {...{ 
                    templatesUploaderRef :templatesUploaderRef,
                    emailSettings : emailSettings,
                    setEmailSettings : setEmailSettings,
                    submitValidation : submitValidation,
                    settingPropName : settingPropName
                }})
                )}
            </div>
        </div>
    );
};

export default CustomEmailTemplateBuilderRow;
