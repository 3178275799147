import React from 'react';
import ChevronRightIcon from '../../_shared/Icon/ChevronRightIcon';
import ChevronLeftIcon from '../../_shared/Icon/ChevronLeftIcon';
import { secondaryBaseTextStyle } from '../../_shared/Styles/TextStyles';
import { useTheme } from '../../App/Theme/ThemeContext';
import { colorUtilService } from '../../../_helpers';
import { withDocumentAnnotationConsumer } from './Context/DocumentAnnotationConsumer';

const EditorPageTools = ({ selectedCanvas, pages, changeSelectedCanvas, releaseCommentSelection }) => {
    const { themeColors } = useTheme();
    const label = 'Page';
    const separator = '/';
    const iconButtonMargin = {
        transition: 'opacity 0.3s',
        marginTop: '18px',
        marginBottom: '18px',
        marginLeft: '20px',
        marginRight: '20px',
        opacity: 1
    };

    const canGoBack = () => {
        return selectedCanvas - 1 >= 0;
    };

    const canGoNext = () => {
        return selectedCanvas + 1 < pages.length;
    };

    const handleNextClicked = () => {
        if (!canGoNext()) {
            return;
        }
        releaseCommentSelection();
        changeSelectedCanvas(selectedCanvas + 1, true);
    };

    const handlePreviousClicked = () => {
        if (!canGoBack()) {
            return;
        }
        releaseCommentSelection();
        changeSelectedCanvas(selectedCanvas - 1, true);
    };
    const buttonDisabledStyle = { opacity: 0.3 };
    const spanMarginStyle = { marginLeft : '2px', marginRight : '2px' };
    return (
        <div
            style={{
                ...{
                    background: colorUtilService.changeOpacity(themeColors.primaryTextColor, 0.07),
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                },
                ...secondaryBaseTextStyle
            }}>
            <ChevronLeftIcon
                style={
                    canGoBack()
                        ? { ...iconButtonMargin }
                        : { ...iconButtonMargin, ...buttonDisabledStyle }
                }
                onClick={handlePreviousClicked}
            />
            <div style={{ display: 'inline' }}>
                <span style={spanMarginStyle}>{label}</span>
                <span style={spanMarginStyle}>{selectedCanvas + 1}</span>
                <span style={spanMarginStyle}>{separator}</span>
                <span style={spanMarginStyle}>{pages.length}</span>
            </div>
            <ChevronRightIcon
                style={
                    canGoNext()
                        ? { ...iconButtonMargin }
                        : { ...iconButtonMargin, ...buttonDisabledStyle }
                }
                onClick={handleNextClicked}
            />
        </div>
    );
};
const EditorPageToolsHOC = withDocumentAnnotationConsumer(EditorPageTools);
export { EditorPageToolsHOC as EditorPageTools };
