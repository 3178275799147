import { utilService } from './util';

export const IOSCanvasAreaLimit = 16777216;

export const proofUtils = {
    checkCanvasRenderLimit,
    getTotalPixels
};

function checkCanvasRenderLimit(proofVersion) {

    let totalCanvasArea = getTotalPixels(proofVersion);
    const isIOS = /iPhone/.test(navigator.userAgent) && !window.MSStream;
    let isCanvasLimitReached = isIOS && totalCanvasArea >= IOSCanvasAreaLimit;
    
    return isCanvasLimitReached;
}

function getTotalPixels(proofVersion) {
    var pixelRatio = window.devicePixelRatio;
    var isVideoProof = utilService.isVideoProof(proofVersion);
    let dimensionSource = isVideoProof === true ? proofVersion.fileMetadata : proofVersion.pages[0];
    const defaultDimensionSource = { width: 0, height: 0 };

    let { width, height } = dimensionSource || defaultDimensionSource,
        canvasWidth = width * pixelRatio,
        canvasHeight = height * pixelRatio,
        totalCanvasArea = canvasWidth * canvasHeight;        

    return totalCanvasArea;
}