//@ts-check

import { contributorsHelper } from '../../_helpers';
import { s3Service } from '../_shared';
import { drawingConstants, ShapeAction } from '@wunderman-thompson/miranda-drawing';
import { trimAnnotationObject } from './DocumentAnnotation/DocumentAnnotationUtil';

export { handleCanvasObjectUpdate, findWordsInSelection, extractText };

async function handleCanvasObjectUpdate(
    {
        setSelectedAnnotation,
        selectedAnnotation,
        isCommentAddMode,
        refreshComments,
        annotationComments,
        proofVersion,
        isCommentMerge,
        updateObjectArrowPoint,
        disableArrow,
        setDisableArrow,
        donotHanldeLiveUpdate,
        index
    },
    event,
    canvasContainer,
    updateAnnotation,
    metadata,
    commentIdPropName,
    type
) {
    var targetEvent = event.target;
    let targetObject = targetEvent.toObject();
    targetObject.tintColor = targetEvent.tintColor;
    targetObject.action = ShapeAction.ADD;

    updateObjectArrowPoint(targetEvent, canvasContainer);
    if (disableArrow && !isCommentAddMode) {
        setDisableArrow(false);
    }

    if (isCommentAddMode === true) {
        var updatedAnnotations = [...selectedAnnotation];
        updateAnnotationAndSnapshot(
            selectedAnnotation,
            targetObject,
            updatedAnnotations,
            canvasContainer,
            index
        );
        setSelectedAnnotation(updatedAnnotations);
    } else if (isCommentMerge === true) {
        //Do some thing for annotation attatchment
        var updatedAnnotations = [...selectedAnnotation];
        updateAnnotationAndSnapshot(
            selectedAnnotation,
            targetObject,
            updatedAnnotations,
            canvasContainer,
            index
        );
        setSelectedAnnotation(updatedAnnotations);
    } else {
        if (targetObject.type === drawingConstants.ANNOTATION_TYPE.commentStamp.typeName) {
            targetObject.tintColor = contributorsHelper.convertColorToKey(targetObject.tintColor);
        } else {
            targetObject.stroke = contributorsHelper.convertColorToKey(targetObject.stroke);
        }

        let updateAnnotationPayload = {
            id: targetObject.uuid
        };

        updateAnnotationPayload[commentIdPropName] = targetObject.uuid_parent;

        donotHanldeLiveUpdate.current = true;

        var targetObjectUUID = targetObject.uuid;
        trimAnnotationObject(targetObject);
        updateAnnotationPayload.annotationObject = JSON.stringify(targetObject);
        await updateAnnotation(updateAnnotationPayload);

        let commentResponse = annotationComments.find(
            (x) => x.id == updateAnnotationPayload[commentIdPropName]
        );
        
        refreshComments();
        donotHanldeLiveUpdate.current = false;
    }

    function updateAnnotationAndSnapshot(
        selectedAnnotation,
        targetObject,
        updatedAnnotations,
        canvasContainer,
        index
    ) {
        selectedAnnotation.forEach((element, index) => {
            if (element.uuid === targetObject.uuid) {
                var currentComment = { ...element };
                currentComment.annotationObject = targetObject;
                updatedAnnotations[index] = currentComment;
            }
        });
    }
}

const extractText = (top, left, width, height, scaleX, scaleY, pageWidth, pageHeight, textContent) => {
    // var { tl, br, width, height } = event.target.aCoords;
    // var { width, height, scaleX, scaleY } = event.target;

    var r = {
        top: top,
        left: left,
        // width: this.getHeightScaled(br.x),
        // height:this.getWidthScaled(br.y)
        width: width * scaleX,
        height: height * scaleY
    };

    var inside = findWordsInSelection(r, textContent, pageHeight, pageWidth);
    var text = '';
    inside.forEach((block) => {
        // var box = block.geometry.boundingBox;

        // var top = box.top * this.props.height;
        // var left = box.left * this.props.width;
        // var w = box.width * this.props.width;
        // var h = box.height * this.props.height;
        // // console.log(block.Text);
        // // console.log(top);
        // // console.log(left);
        // // console.log(w);
        // // console.log(h);

        // var rect = new fabric.Rect({
        //     top: top,
        //     left: left,
        //     width: w,
        //     height: box.height * this.props.height,
        //     stroke: 'blue',
        //     fill: ''
        // });

        // this.mainDrawingCanvas.add(rect);
        text = text ? text + ' ' + block.text : block.text;
    });
    return text;
};

function findWordsInSelection(selectionRect, textBlocks, imageHeight, imageWidth) {
    if(!!!textBlocks){
        return []
    }
    var wordsBlocks = textBlocks.blocks.filter((x) => x.blockType.value === 'WORD');

    console.log(selectionRect);
    let text = '';

    var insideBlocks = wordsBlocks.filter((x) => findInside(selectionRect, x.geometry.boundingBox, imageHeight, imageWidth));

    insideBlocks.forEach((block) => {
        text = text + ' ' + block.text;
    });
    return insideBlocks;
}

function findInside(rect1, rect2, imageHeight, imageWidth) {
    //console.log(rect2);

    // Left coordinate = BoundingBox.Left (0.3922065) * document page width (608) = 238

    // Top coordinate = BoundingBox.Top (0.15567766) * document page height (588) = 91

    // Bounding box width = BoundingBox.Width (0.284666) * document page width (608) = 173

    // Bounding box height = BoundingBox.Height (0.2930403) * document page height (588) = 172

    let x1 = rect1.left,
        x2 = rect2.left * imageWidth,
        y1 = rect1.top,
        y2 = rect2.top * imageHeight,
        w1 = rect1.width,
        w2 = rect2.width * imageWidth,
        h1 = rect1.height,
        h2 = rect2.height * imageHeight;

    // return (
    // 	x2 + w2 / 2 <= x1 + w1 / 2 &&
    // 	x2 - w2 / 2 >= x1 - w1 / 2 &&
    // 	y2 + h2 / 2 <= y1 + h1 / 2 &&
    // 	y2 - h2 / 2 >= y1 - h1 / 2
    // );
    return x2 + w2 < x1 + w1 && x2 > x1 && y2 > y1 && y2 + h2 < y1 + h1;
}
