import React from 'react';

function ChromeIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none">
            <g clipPath="url(#clip0_296_1356)">
                <path
                    d="M29.999 44.9968C38.2833 44.9968 44.999 38.2811 44.999 29.9968C44.999 21.7125 38.2833 14.9968 29.999 14.9968C21.7147 14.9968 14.999 21.7125 14.999 29.9968C14.999 38.2811 21.7147 44.9968 29.999 44.9968Z"
                    fill="white"
                />
                <path
                    d="M17.0087 37.5L4.02036 15.0037C1.38677 19.5637 0.000181694 24.7367 1.78551e-08 30.0026C-0.000181658 35.2685 1.38606 40.4417 4.01932 45.0019C6.6526 49.562 10.4401 53.3486 15.001 55.9808C19.5618 58.6129 24.7352 59.9979 30.0011 59.9964L42.9894 37.5V37.4961C41.6739 39.7775 39.7809 41.6721 37.5007 42.9896C35.2205 44.307 32.6336 45.0008 30.0002 45.0012C27.3668 45.0016 24.7796 44.3086 22.499 42.9918C20.2186 41.675 18.3249 39.7809 17.0087 37.5Z"
                    fill="url(#paint0_linear_296_1356)"
                />
                <path
                    d="M42.9882 37.4991L30.0001 59.9955C35.266 59.9963 40.4392 58.6105 44.9997 55.9777C49.5602 53.3449 53.3471 49.5579 55.9798 44.9973C58.6123 40.4366 59.9979 35.2633 59.997 29.9974C59.9961 24.7315 58.6087 19.5587 55.9744 14.999H29.9979L29.9946 15.001C32.6281 14.9997 35.2154 15.6919 37.4964 17.0078C39.7775 18.3238 41.6718 20.2171 42.9888 22.4976C44.3059 24.778 44.9992 27.365 44.9992 29.9984C44.9991 32.6318 44.3054 35.2187 42.9882 37.4991Z"
                    fill="url(#paint1_linear_296_1356)"
                />
                <path
                    d="M29.999 41.8751C36.5574 41.8751 41.874 36.5584 41.874 30C41.874 23.4417 36.5574 18.125 29.999 18.125C23.4406 18.125 18.124 23.4417 18.124 30C18.124 36.5584 23.4406 41.8751 29.999 41.8751Z"
                    fill="#1A73E8"
                />
                <path
                    d="M29.9992 15H55.9758C53.3434 10.4392 49.5568 6.65188 44.9965 4.01877C40.4362 1.38568 35.2629 -0.000373667 29.997 7.55637e-08C24.7311 0.000373818 19.5582 1.38715 14.9983 4.0209C10.4383 6.65465 6.65217 10.4426 4.02051 15.0036L17.0088 37.5L17.0121 37.5018C15.6943 35.2218 15 32.6351 14.9991 30.0017C14.9983 27.3683 15.6909 24.781 17.0073 22.5003C18.3236 20.2195 20.2174 18.3255 22.498 17.009C24.7787 15.6923 27.3658 14.9994 29.9992 15Z"
                    fill="url(#paint2_linear_296_1356)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_296_1356"
                    x1="33.2464"
                    y1="58.1228"
                    x2="7.2688"
                    y2="13.1282"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1E8E3E" />
                    <stop offset="1" stopColor="#34A853" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_296_1356"
                    x1="25.6229"
                    y1="60.075"
                    x2="51.6005"
                    y2="15.0804"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FCC934" />
                    <stop offset="1" stopColor="#FBBC04" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_296_1356"
                    x1="4.02052"
                    y1="18.7509"
                    x2="55.9758"
                    y2="18.7509"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D93025" />
                    <stop offset="1" stopColor="#EA4335" />
                </linearGradient>
                <clipPath id="clip0_296_1356">
                    <rect width="60" height="60" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default ChromeIcon;
