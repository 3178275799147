import { authHeader, handleResponse, config } from '../../_helpers';

export const providerService = {   
    getAll
};

function getAll(paramsObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(paramsObj),
    };
    return fetch(`${config.apiUrl}/user/getallproviders`, requestOptions).then(handleResponse);
}
