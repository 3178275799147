//@ts-check
import React, { useState, useCallback, useRef, useEffect } from 'react';
import cx from 'classnames';
import { utilService, authorizationService } from '../../../../../_helpers';
import { withDocumentAnnotationConsumer } from '../../Context/DocumentAnnotationConsumer';
import ReplyItem from './ReplyItem';
import AttachmentItem from './AttachmentItem';
import AttachmentViewer from './AttachmentsViewer';
import { documentAnnotationConstants } from '../../Context/documentAnnotation.constants';
import { MetadataAnnotationIcon, MetadataAnnotationIconList, MetadataResponses, MetadataPageNumber, MetadataTimeRange, MetadataAttachments } from './MetadataComponents';
import { documentCommentService } from '../../documentComment.service';
import { videoCommentService } from '../../../VideoAnnotation/videoComment.service';
import { bringToViewSmooth } from '../../DocumentAnnotationUtil';
import { authorizationConstants } from '../../../../_shared';
// import './_commentItem.scss'
import { AttachmentModal } from './AttachmentModal';
import { AttachmentPicker } from './AttachmentPicker';
import { AttachmentsUploader } from './AttachmentsUploader';
import { drawingConstants } from '@wunderman-thompson/miranda-drawing';
import { contributorsHelper, config } from '../../../../../_helpers';
import MentionLabel from './MentionLabel';
import MirandaMentionInput from './MirandaMentionInput';
import { mentionHelper } from '../../../../../_helpers/mentionHelper';
import { commentHelper } from '../../../../../_helpers/commentHelper';
import { CommentBox } from './CommentBox';

const ARROW_SPACE_TOP = 30;

const CommentItemLite = ({
    commentItem,
    isCommentAddMode,
    peekToPage,
    onCommentReply,
    onCommentDelete,
    onCommentSetStatus,
    onCommentClicked,
    isGroupSelected,
    // documentMetadata,
    // videoMetadata,
    metadata,
    proofVersion,
    refreshComments,
    index,
    contributors,
    avatarColor,
    annotationColor,
    userAnnotationColor,
    onAttachmentDelete,
    getAttachment,
    userId,
    setArrowPoint2,
    setCommentArrowPointFinders,
    isVisible,
    setSelectedComment,
    side,
    isProofCompare,
    setAnnotationComments,
    annotationComments,
    updateCommentContent,
    updateReplyContent,
    editAllowedComments,
    editAllowedReplies, 
    setEditAllowedReplies,
    onCommentItemLiteClicked,
    isSelected    
    
}) => {
    const { COMMENT_STATUS } = documentAnnotationConstants;


    const [commentReply, setcommentReply] = useState('');
    const [showUserModal, setShowUserModal] = useState(false);
    const [clickedAttachment, setClickedAttachment] = useState();
    const [attachments, setAttachments] = useState(null);
    const [uploadedAttachments, setUploadedAttachments] = useState(null);
    const [aValue, setAValue] = useState('');
    const [truncate, setTruncate] = useState(true);
    const attachmentsRef = useRef(null);
    const uploadHandlersRef = useRef([]);
    const uploadedAttachmentsRef = useRef(null);
    const indexRef = useRef(0);
    // const [index, setIndex] = useState(0);

    const commentReplyRef = useRef('');
    const mentionDataRef = useRef({});

    const commentArea = useRef(null);
    const [commentColor, setCommentColor] = useState();
    const [fontColor, setFontColor] = useState();
    const [replyFontColor, setReplyFontColor] = useState();
    const [editedCommentText, setEditedCommentText] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editAllowed, setEditAllowed] = useState(false);    
    const { ANNOTATION_TYPE } = drawingConstants;

    useEffect(()=>{        
        colorHelper();        
    }, []);

    const colorHelper = ()=> {       
        var color = contributorsHelper.findCommentColor(commentItem.annotations[0]);
        setCommentColor(color);        
        var fontCol = contributorsHelper.findFontColor(color);
        var repCol = contributorsHelper.findFontColor(userAnnotationColor);

        setFontColor(fontCol);
        setReplyFontColor(repCol);
    }
    useEffect(() => {
        attachmentsRef.current = attachments;
    }, [attachments]);

    

    useEffect(() => {
        if(commentItem === null) { return }        
        colorHelper();
    }, [commentItem.annotations[0]]);

    useEffect(() => {                   
        if(editMode === true)
        {
            if (commentItem.mentions.length > 0){            
                var converted = mentionHelper.processCommentValueForInput(commentItem.content, commentItem.mentions)
                setEditedCommentText(converted);
            }
            else {
                setEditedCommentText(commentItem.content);
            }                    
        }
    }, [editMode]);

    useEffect(()=> {

        setcommentReply(aValue);

    },[aValue])
    useEffect(() => {
        var repCol = contributorsHelper.findFontColor(userAnnotationColor);        
        setReplyFontColor(repCol);
    }, [userAnnotationColor]);
   
    useEffect(() => {
        uploadedAttachmentsRef.current = uploadedAttachments;
    }, [uploadedAttachments]);

    useEffect(() => {
        commentReplyRef.current = commentReply;
    }, [commentReply]);
    const handleChange = (event) => {
        var { name, value } = event.target;
        setcommentReply(value);
    };


    
    


    const calculateCommentArrowPoint = (fromScroll = false) => {
        
        if (!fromScroll){
            bringToViewSmooth(commentArea.current, 20);
        }
    
        //NOTE: compare toolbar height 
        let toolbarHeight = 116;
        var points = commentArea.current.getBoundingClientRect();
        let top = (isProofCompare === true? points.top - toolbarHeight : points.top ) + ARROW_SPACE_TOP;
        setArrowPoint2(
            {
                x: side === "left"?  points.right : points.left,
                y: top,
            }
        );
    }

    const onAttachmentClicked = (item, attachmentFor) => {       
        var attachment = {...item};
        attachment.attachmentFor = attachmentFor;
        setClickedAttachment(attachment);
        setShowUserModal(true);
    }

    const handleOnCommentReply = useCallback(() => {
        
        var mentionRes = mentionHelper.processInputValue(aValue);
        mentionDataRef.current = mentionRes;
        commentReplyRef.current = mentionRes.tValue;
        console.log(mentionRes);

        var uploadHandler = uploadHandlersRef.current[indexRef.current];
        if (uploadHandler) {
            uploadHandler(indexRef.current);
        }
        else {
            postReply();
        }
    }, [commentReply]);


    const postReply = async () => {
        var replyItem = {
            commentId: commentItem.id,
            reply: commentReplyRef.current,
            mentionURL: metadata.mentionURL
        };
        onCommentReply(replyItem, uploadedAttachmentsRef.current, mentionDataRef.current.mentions);
        setAValue('');
        resetAttachments();        
    }

    const resetAttachments = () => {
        indexRef.current = 0;
        setAttachments(null);
        uploadHandlersRef.current = [];
        setUploadedAttachments(null);
        uploadedAttachmentsRef.current = [];        
    }

    const updateUploadHandlers = (handler) => {
        var newArr = [...uploadHandlersRef.current, handler];
        uploadHandlersRef.current = (newArr);
    }
    const onUploadComplete = (uploadedNow, attachmentNo) => {
        var prev = uploadedAttachmentsRef.current === null ? [] : uploadedAttachmentsRef.current;
        uploadedAttachmentsRef.current = ([...prev, uploadedNow]);

        var newIndex = indexRef.current + 1;
        indexRef.current = (newIndex);
        var uploadHandler = uploadHandlersRef.current[newIndex];

        if (uploadHandler) {
            uploadHandler(newIndex);
        }
        else {
            postReply();
        }
    }

    const onRemoveAttachment = (index) => {

        var attachmentsCopy = attachments.map((attachment) => {
            var newAttachment = { ...attachment };
            return newAttachment;
        });

        attachmentsCopy.splice(index, 1);
        uploadHandlersRef.current.splice(index, 1);
        setAttachments(attachmentsCopy);

    }

    const handleOnUpdateComment = async (comment, mentions = null) => {
        var commentUpdatePayload = {
            proofId: commentItem.proofId,
            proofVersionId: commentItem.proofId,
            commentId: commentItem.id,
            content: comment,
            mentions: mentions,
            mentionURL: metadata?.mentionURL,
        };
        let response = await updateCommentContent(commentUpdatePayload);

        setEditMode(false);

        onCommentUpdateCompleted(response);
    };

    const onCommentUpdateCompleted = (updatedComment) => {
        var modified = annotationComments.map((comment) => {
            return comment.id === updatedComment.id ? updatedComment : comment;
        });

        setAnnotationComments(modified);
    };
    const onReplyDelete = async (reply) => {
        var requestPayload = {
            id: reply.id,
            
        };

        if (proofVersion.mediaType === 2 || proofVersion.mediaType === 4){
            requestPayload.VideoCommentId = commentItem.id;
            await videoCommentService.deleteVideoCommentReply(requestPayload);
        }
        else{        
            requestPayload.documentCommentId = commentItem.id;
            await documentCommentService.deleteCommentReply(requestPayload);
        }
        
        await refreshComments();
    };

    let isUserPermittedToAdd = authorizationService.isUserPermitted(
        authorizationConstants.ProofEditor.KEY,
        authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_COMMENT_ADD
    );
    let isUserPermittedToDelete = authorizationService.isUserPermitted(
        authorizationConstants.ProofEditor.KEY,
        authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_COMMENT_DELETE
    );

    let isUserPermittedToDeleteAny = authorizationService.isUserPermitted(
        authorizationConstants.ProofEditor.KEY,
        authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_COMMENT_DELETE_ANY
    );
    

    const canEdit = ()=>{
        var canEdit = editAllowedComments.some(x => x === commentItem.id);
        if (!canEdit)
        {
            setEditAllowed(canEdit);
            return;
        }

        var currentDate = new Date();
        var createdDate = new Date(commentItem.createdAt);

        var def = currentDate - createdDate;

        var minutes = Math.floor(def / 60000);
       

        if (minutes > config.editThresholdInMinute) {
            setEditAllowed(false);
        }
    }


    return (
        <div 
            ref={commentArea} 
            className= {cx({'mrnda-comment-container' : true})}             
            onClick={onCommentItemLiteClicked} 
            style={{}}>
            <div
                className={cx({
                    'comment-lite': true,
                    'comment-lite-selected' : isSelected                    
                })}
            >
                <div className="comment__header">
                    
                    <div className="comment__details">
                        <div className="comment__date-area">
                            <div className="comment__date-area__date">
                                <span className="date-field text-truncate">
                                    {new Date(commentItem.createdAt).toLocaleString()}
                                </span>
                                <span className="dot-separator">•</span>
                                                                            
                                <MetadataAnnotationIconList commentColor={commentColor} annotations = {commentItem.annotations}/>                                              
                                
                            </div>
                        </div>
                    </div>
                    <div className="comment__status">
                        {COMMENT_STATUS.filter(commentItem.status) && (
                            <span className="comment__status__item">
                                <span>{COMMENT_STATUS[commentItem.status]}</span>
                            </span>
                        )}
                    </div>
                    
                    <div className="comment__header-close">
                        <button className="comment__close">
                            <div className="comment__close-icon" />
                        </button>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection:'column'}} className="comment-lite__body-wrapper">                    
                    <div className="comment__body" style={{background: editMode?  'var(--secondary-background-color)' : 'var(--primary-background-color)'}}>                        
                        {editMode ? (                            
                            <CommentBox 
                                editMode={true} 
                                commentText = {editedCommentText}
                                handleOnUpdateComment = {handleOnUpdateComment}
                                setCommentText = {setEditedCommentText}
                                handleOnCancelComment = { () => { setEditMode(false) }}
                            />
                        ) : (
                            
                            <MentionLabel
                                commentText={commentItem.content}
                                mentions={commentItem.mentions}
                            />
                            
                        )}
                        
                    </div>                   
                </div>
                <div className="comment-lite__metadata">
                    <div className="comment__metadata__time-range">
                        {
                            proofVersion.mediaType === 2 || proofVersion.mediaType === 4
                                ? <MetadataTimeRange frameRange={commentItem.frameRange} classes="comment__metadata__time-range__item" frameRate={metadata.frameRate} />
                                : <MetadataPageNumber page={commentItem.page} annotations ={commentItem.annotation}  classes="comment__metadata__time-range__item" />
                        }
                    </div>
                    <div className="comment__metadata__replies">
                        <MetadataResponses replies={commentItem.replies} />
                    </div>
                    <div className="comment__metadata__replies">
                        <MetadataAttachments attachments={commentItem.attachments} />
                    </div>
                   
                </div>                
            </div>
        </div>
    );
};

export { CommentItemLite };
