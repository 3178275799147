import React, { useEffect, useRef, useState } from 'react';
import { DrawingCanvas, ShapeAction } from '@wunderman-thompson/miranda-drawing';
import AnnotationBackdrop from './AnnotationBackdrop';
import { modifyShapeColor, additionalProps } from '../drawingHelper';
import { fabric } from 'fabric';

const AnnotationViewer = ({ comment, annotationObject, getPageDetail, onGetBackdropSource, onBackgroundLoaded }) => {
    const componentRef = useRef();
    const canvasRef = useRef();
    const [size, setSize] = useState(undefined);
    const [scale, setScale] = useState(1);
    const [pageDetails, setPageDetails] = useState();
    useEffect(() => {
        if (!componentRef.current) {
            return;
        }
        
        let details = getPageDetail(annotationObject);
        
        setPageDetails(details);
        var width = componentRef.current.offsetWidth;
        let pageScale = (1 / details.width) * width;
        let componentSize = { width: details.width, height: details.height };
        setSize(componentSize);
        setScale(pageScale);
    }, [])

    useEffect(() => {
        if (size === undefined) { return }
        
        createCanvas(size);
    }, [size]);

    const createCanvas = (componentSize) => {
        canvasRef.current.width = componentSize.width;
        canvasRef.current.height = componentSize.height;

        let mainDrawingCanvas = new DrawingCanvas(
            canvasRef.current,
            new fabric.Canvas(canvasRef.current, { selection: false }),
            () => { },
            () => { },
            () => { },
            () => { }
        );
        mainDrawingCanvas.hoverCursor = 'default';
        drawSingleAnnotation(mainDrawingCanvas, annotationObject);
    };

    const drawSingleAnnotation = (mainDrawingCanvas, drawableAnnotation) => {
        let isSelectable = false;

        let annotationObject = JSON.parse(drawableAnnotation.annotationObject);

        const shape = {
            type: drawableAnnotation.shapeType,
            ...annotationObject,
            action: ShapeAction.ADD,
            selectable: isSelectable
        };
        
        additionalProps(shape, '');
        modifyShapeColor(shape, 'red');
        mainDrawingCanvas.drawShape(shape);
        mainDrawingCanvas.fabricCanvas.hoverCursor = 'default';
    };

    return (
        <div className='viewer-host' style={size? { height: size.height * scale } : {}}>
            <div
                className='viewer-canvas'
                ref={componentRef}
                style={{
                    position: 'relative',
                    backgroundColor: 'var(--tertiary-background-color)',
                    ...size,
                    transform: `scale(${scale})`,
                    transformOrigin: 'left top'
                }}>
                <canvas ref={canvasRef} style={{}} />
                {comment.reportLoadStatus === 'Loading' && (
                    <AnnotationBackdrop
                        className={'viewer-background'}
                        details={pageDetails}
                        onGetBackdropSource={onGetBackdropSource}
                        comment={comment}
                        annotationObject = {annotationObject}
                        onBackgroundLoaded={onBackgroundLoaded}
                    />
                )}
            </div>

        </div>
    );
};

export default AnnotationViewer;
