import React, { Component } from 'react';
import { match } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    proofActions,
    AuthorizationComponent,
    TreeView,
    alertActions,
    directoryActions,
    DirectoryMoveModal,
    ConfirmationModal,
    BreadCrumb
} from '../../components';
import { thirdPartyIntegrationService } from '../Settings/thirdPartyIntegration.service';
import { Filters } from 'react-data-grid-addons';
import { utilService, history } from '../../_helpers';
import { authorizationConstants } from '../_shared';
import { useLiveUpdate } from '../App/LiveUpdate/LiveUpdateContext';
import { modifyVars } from 'less';
import withLiveUpdateHook from './LiveUpdateWrapper';
import classNames from 'classnames';

const { MultiSelectFilter } = Filters;
const columns = [
    { key: 'documentId', name: 'Id' },
    {
        key: 'documentName',
        name: 'File Name'
    },
    { key: 'createdBy', name: 'Created By', filterRenderer: MultiSelectFilter },
    { key: 'createdAt', name: 'Created on', filterRenderer: MultiSelectFilter },
    { key: 'modifiedAt', name: 'Last Modified' },
    {
        key: 'modifiedBy',
        name: 'Last Modified by',
        filterRenderer: MultiSelectFilter
    },
    { key: 'status', name: 'Status', filterRenderer: MultiSelectFilter },
    { key: 'currentVersion', name: 'Current Version' }
];

const defaultColumnProperties = {
    sortable: true,
    filterable: true,
    resizable: true
};

const initialValidationState = {
    directoryName: {
        validated: true,
        message: ''
    }
};

const ModifyDirectoryComponent = ({ classes, isAuthorized, onClick }) => {
    var tootltipProps = !isAuthorized
        ? {
              datatoggle: 'tooltip',
              dataplacement: 'top',
              title: 'Not authorized',
              delay: 0
          }
        : null;
    return (
        <span
            style={{
                fontSize: '13px',
                paddingTop: '11px',
                position: 'absolute',
                right: '35px'
            }}
            className={isAuthorized ? classes : classes + ' authorization-disabled'}
            title="Edit"
            onClick={isAuthorized ? onClick : () => {}}
            {...tootltipProps}>
            <i style={{ fontSize: 18 }} className="fas fa-edit"></i>
        </span>
    );
};
class ProofsContainerFolderView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            proofs: [],
            columns: columns.map((c) => ({
                ...c,
                ...defaultColumnProperties
            })),
            pageSize: 2147483647,
            pageIndex: 0,
            totalPages: 1,
            totalCount: 0,
            sortColumn: 'createdAt',
            sortDirection: 'DESC',
            filtersParam: [],
            proofsFilters: {},
            treeData: [],
            currentDirectoryNode: this.props.initialNodeState,
            currentDirectoryNodeChildrenIds: utilService.findChildrenArrayIds(
                this.props.initialNodeState
            ),
            currentDirectoryExpandedNodeIds: [this.props.initialNodeState.key],
            currentDirectoryExpandedNodes: [this.props.initialNodeState],

            isEditMode: false,
            showDirectoryInput: false,
            showDirectoryMoveModal: false,
            showDeleteConfirmationModal: false,

            directoryName: null,
            actionMode: 1, // 1 = save, 2 = rename;
            submitted: false,
            validations: initialValidationState,
            canSave: true,
            proofsByDirectory: {},
            lucidLinkedDirectory: null,
            showDirectoryDeleteMessage: false
        };

        this.changeLiveUpdateTarget();
    }

    changeLiveUpdateTarget = () => {
        const targets = [
            {
                key: 'Dashboard',
                value: 'dashboard/Thumbnail'
            },
            {
                key: 'Dashboard',
                value: 'dashboard/FolderAdded'
            },
            {
                key: 'Dashboard',
                value: 'dashboard/FolderDeleted'
            },
            {
                key: 'Dashboard',
                value: 'dashboard/FolderMoved'
            },
            {
                key: 'Dashboard',
                value: 'dashboard/ProofAdded'
            },
            {
                key: 'Dashboard',
                value: 'dashboard/ProofDeleted'
            },
            {
                key: 'Dashboard',
                value: 'dashboard/FolderUpdate'
            }
        ];
        this.props.liveUpdateHook.changeTarget(targets);
        this.props.liveUpdateHook.setThumbnailUpdateListener(this.handleThumnailUpdate.bind(this));
        this.props.liveUpdateHook.setFolderListeners(
            this.handleFolderAdded.bind(this),
            this.handleFolderDeleted.bind(this),
            this.handleFolderMoved.bind(this),
            this.handleFolderUpdated.bind(this)
        );
        this.props.liveUpdateHook.setProofListeners(
            this.handleProofAddded.bind(this),
            this.handleProofDeleted.bind(this)
        );
    };

    traverseTree = (element, data, onNodeFound, searchParent = true) => {
        var key = searchParent ? data.parentId : data.key;
        if (element.key == key) {
            return onNodeFound(element, data);
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.traverseTree(element.children[i], data, onNodeFound, searchParent);
            }
            return result;
        }
        return null;
    };

    handleThumnailUpdate(data) {
        var { proofId, url, status } = data;
        var modified = this.state.proofs.map((proof) => {
            let modifiedproof = { ...proof };
            if (modifiedproof.id == proofId) {
                modifiedproof.thumbnailURL = url;
                modifiedproof.status = {
                    ...modifiedproof.status,
                    ...status
                };
            }
            return modifiedproof;
        });
        let { proofsByDirectory } = this.state;
        proofsByDirectory.rows = modified;
        this.props.dispatch(proofActions.someAction(proofsByDirectory));
        console.log('Thumbnail updated !');
    }

    copyArray(arr) {
        var copied = arr.map((proof) => {
            let modifiedproof = { ...proof };
            return modifiedproof;
        });

        return copied;
    }

    async handleProofAddded(data) {
        var copied = this.copyArray(this.state.proofs);

        if (data.directoryNodeId === this.state.currentDirectoryNode.key) {
            var modified = [data, ...copied];
            let { proofsByDirectory } = this.state;
            proofsByDirectory.rows = modified;
            this.props.dispatch(proofActions.someAction(proofsByDirectory));
        }
    }

    handleProofDeleted(data) {
        var copied = this.copyArray(this.state.proofs);
        var index = copied.findIndex((x) => x.id === data.id);
        copied.splice(index, 1);
        let { proofsByDirectory } = this.state;
        proofsByDirectory.rows = copied;
        this.props.dispatch(proofActions.someAction(proofsByDirectory));
    }

    handleFolderAdded(newData) {
        var copy = this.copyArray(this.state.treeData);
        const onNodeFound = (element, data) => {
            element.children.push(data);
            element.isLeaf = element.children.length === 0 ? true : false;
        };

        this.traverseTree(copy[0], newData, onNodeFound);

        this.setState({ treeData: copy });
        console.log('Folder added notification hanlded');
    }

    handleFolderUpdated(newData) {
        var copy = this.copyArray(this.state.treeData);
        const onNodeFound = (element, data) => {
            element.title = data.title;
            element.path = data.path;
        };

        var folder = this.traverseTree(copy[0], newData, onNodeFound, false);

        this.setState({ treeData: copy });
        console.log('Folder added notification hanlded');
    }

    handleFolderDeleted(data) {
        var copy = this.copyArray(this.state.treeData);
        const onNodeFound = (element, data) => {
            var index = element.children.findIndex((x) => x.key === data.key);
            element.children.splice(index, 1);
            element.isLeaf = element.children.length === 0 ? true : false;
        };
        this.traverseTree(copy[0], data, onNodeFound);
        this.setState({ treeData: copy });

        if (this.state.currentDirectoryNode.key === data.key) this.onTreeNodeSelect(data.parentId);

        console.log('Folder deletion');
    }

    handleFolderMoved(data) {
        var { newNode, oldNode } = data;

        var copy = this.copyArray(this.state.treeData);

        const removeNodeFound = (element, data) => {
            var index = element.children.findIndex((x) => x.key === data.key);
            var targetNode = element.children[index];
            element.children.splice(index, 1);
            element.isLeaf = element.children.length === 0 ? true : false;

            return targetNode;
        };

        var deletedNode = this.traverseTree(copy[0], oldNode, removeNodeFound);

        console.log('Folder deleted from previous location location');

        const addNodeFound = (element, data) => {
            element.children.push(data);
            element.isLeaf = element.children.length === 0 ? true : false;
        };

        newNode.children = deletedNode.children;
        newNode.isLeaf = newNode.children.length === 0 ? true : false;
        this.traverseTree(copy[0], newNode, addNodeFound);

        console.log('Folder added to the new location');

        this.setState({ treeData: copy });
    }

    async componentDidMount() {
        await this.props.dispatch(proofActions.clearProofs());
        var currentNodeKey = this.props.initialNodeState.key;
        if (this.props.match && this.props.match.params && this.props.match.params.nodeid) {
            currentNodeKey = this.props.match.params.nodeid;
        }
        // await this.fetchAllFromServer();
        await this.loadDirectoryDataFromServer();
        await this.onTreeNodeSelect(currentNodeKey);
        this.props.setLiveUpdateListeners(this.changeLiveUpdateTarget.bind(this));

        const directory = await thirdPartyIntegrationService.getLucidLinkedDirectory();
        this.setState({ lucidLinkedDirectory: directory });
    }

    async fetchAllFromServer() {
        await this.loadDirectoryDataFromServer();
        await this.loadDataFromServer();
    }

    static getDerivedStateFromProps(nextProps, prevState) {                
        if (nextProps.proofs) {

            //NOTE : This is a dumb fix stopping race condition of dashboard folder proofs
            //       The issue occurs when a getProofsByDirectory(A) call is loading,
            //       and another call(B) is started by clicking folder
            //       then if B loads faster, it will update ui, after that A completes loading and 
            //       binds A's content to B folders view.

            var incoming = (nextProps.proofs[0]?.directoryNodeId);
            var selectedNode = prevState.currentDirectoryNode.key;
            var updatedProofs = incoming !== selectedNode? prevState.proofs : nextProps.proofs;

            return {
                proofs: updatedProofs,
                // pageSize: nextProps.pageSize,
                // pageIndex: nextProps.pageIndex,
                totalPages: nextProps.totalPages,
                totalCount: nextProps.totalCount,
                proofsFilters: nextProps.proofsFilters
                    ? nextProps.proofsFilters
                    : prevState.proofsFilters,
                proofsByDirectory: nextProps.proofsByDirectory
            };
        } else {
            return null;
        }
    }

    async loadDataFromServer() {
        const sortMapper = {
            ['ASC']: 1,
            ['DESC']: -1,
            ['NONE']: 1
        };
        const data = {
            page: this.state.pageIndex,
            pageSize: this.state.pageSize,
            directoryNodeId: this.state.currentDirectoryNode.key,
            proofSortModel: {
                sortDirection: sortMapper[this.state.sortDirection],
                sortColumn: this.state.sortColumn
            },
            proofFilters: this.state.filtersParam
        };
        await this.props.dispatch(proofActions.getAllByDirectory(data));
    }

    async loadDirectoryDataFromServer() {
        var directories = await this.props.dispatch(directoryActions.getPermittedAll({}));
        var node = utilService.searchTree(
            directories[0],
            this.state.currentDirectoryNode.key,
            'key'
        );
        await utilService.setStateAsync.bind(this)({
            treeData: directories,
            currentDirectoryNode: node
        });
    }

    handleCardClick(defaultVersionId) {
        this.clearSelection();
        const proofRow = this.state.proofs.find(
            (proof) => proof.defaultVersionId === defaultVersionId
        );
        this.props.handleCardClick(proofRow);
    }

    async handleFolderClick(selectedNodeId) {
        var selectedNode = utilService.searchTree(this.state.treeData[0], selectedNodeId, 'key');
        await this.onTreeNodeSelect(selectedNode.key);
    }

    clearSelection() {
        if (document.selection && document.selection.empty) {
            document.selection.empty();
        } else if (window.getSelection) {
            var sel = window.getSelection();
            sel.removeAllRanges();
        }
    }

    async handleTreeNodeSelect(selectedNode) {
        await this.onTreeNodeSelect(selectedNode.key);
    }

    async onTreeNodeSelect(selectedNodeKey) {
        /** -----------------------------
         ** Setting proof empty is necessary for avoid rendering glitch
         ** Directory will only render with 0 proofs first
         ** then fetched proofs
         ** ------------------------------
         **/

         history.push({
            pathname:
            selectedNodeKey === this.props.initialNodeState.key
                    ? `/dashboard/folders`
                    : `/dashboard/folders/${selectedNodeKey}`
        });

        await this.props.dispatch(proofActions.clearProofs());
        await utilService.setStateAsync.bind(this)({ proofs: [] });
        var selectedNode = utilService.searchTree(this.state.treeData[0], selectedNodeKey, 'key');
        var expandedKeys = utilService.findParentsArrayIds(
            this.state.treeData,
            selectedNode.key,
            'key',
            'title',
            'children'
        );
        var expandedObjects = utilService.findParentObjects(
            this.state.treeData,
            selectedNode.key,
            'key',
            'title',
            'children'
        );
        this.resetInputGroup();

        await utilService.setStateAsync.bind(this)({
            currentDirectoryNode: selectedNode,
            currentDirectoryExpandedNodeIds: expandedKeys,
            currentDirectoryExpandedNodes: expandedObjects,
            currentDirectoryNodeChildrenIds: utilService.findChildrenArrayIds(selectedNode)
        });
        this.props.handleFolderClick(selectedNode);
        await this.loadDataFromServer();
    }

    onTreeNodeExpand(selectedNodeKeys) {
        this.setState({
            currentDirectoryExpandedNodeIds: selectedNodeKeys
        });
    }

    handleAddDirectory() {
        this.setState({
            showDirectoryInput: true,
            actionMode: 1
        });
    }

    handleRenameDirectory() {
        this.setState({
            directoryName: this.state.currentDirectoryNode.title,
            showDirectoryInput: true,
            actionMode: 2
        });
    }

    handleMoveDirectory() {
        this.setState({
            showDirectoryMoveModal: true
        });
    }

    async handleMoveDirectoryOnClose(hasChanges) {
        this.setState({ showDirectoryMoveModal: false });
        if (hasChanges) {
            await this.fetchAllFromServer();
            await this.onTreeNodeSelect(this.state.currentDirectoryNode.key);
        }
    }

    handleInputKeyPress(event) {
        if (event.key === 'Enter') {
            this.state.actionMode == 1 ? this.handleSaveInput() : this.handleSaveRenameInput();
        }
    }

    handleDirectoryInputChange(event) {
        var { name, value } = event.target;
        this.setState({
            directoryName: value,
            submitted: false
        });
    }

    async validateFields() {
        var globalValidation = true;
        var { validations } = this.state;
        var validationCopy = { ...validations };
        if (
            !this.state.directoryName ||
            (this.state.directoryName && this.state.directoryName.length < 2)
        ) {
            validationCopy.directoryName = {
                validated: false,
                message: 'Name must be more than 1 character '
            };
            globalValidation = false;
        }

        if (
            !this.state.directoryName ||
            (this.state.directoryName && !this.validateDirectoryName(this.state.directoryName))
        ) {
            validationCopy.directoryName = {
                validated: false,
                message: 'Directory name not valid'
            };
            globalValidation = false;
        }

        if (this.state.directoryName) {
            var requestPayload = {
                parentId:
                    this.state.actionMode == 2
                        ? this.state.currentDirectoryNode.parentId
                        : this.state.currentDirectoryNode.key,
                name: this.state.directoryName,
                id: this.state.currentDirectoryNode.key
            };
            var response = await this.props.dispatch(directoryActions.isNameUnique(requestPayload));
            if (!response.isNameUnique) {
                validationCopy.directoryName = {
                    validated: false,
                    message: 'Directory with the same name exists'
                };
                globalValidation = false;
            }
        }

        this.setState({
            validations: validationCopy
        });
        return globalValidation;
    }

    validateDirectoryName(name) {
        // var re = /^[\w.-]+$/;
        // return !re.test(name);
        var rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
        var rg2 = /^\./; // cannot start with dot (.)
        var rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
        return rg1.test(name) && !rg2.test(name) && !rg3.test(name);
    }

    async handleSaveInput() {
        await utilService.setStateAsync.bind(this)({
            submitted: true,
            canSave: false,
            validations: initialValidationState
        });
        if (!(await this.validateFields())) {
            return;
        }

        var requestPayload = {
            parentId: this.state.currentDirectoryNode.key,
            name: this.state.directoryName,
            path:
                this.state.currentDirectoryNode.parentId == null
                    ? this.state.directoryName
                    : `${this.state.currentDirectoryNode.path}/${this.state.directoryName}`
        };
        var newDirectoryNode = await this.props.dispatch(directoryActions.add(requestPayload));
        this.props.dispatch(alertActions.success('Saved !'));
        this.resetInputGroup();
        await this.fetchAllFromServer();
    }

    async handleSaveRenameInput() {
        await utilService.setStateAsync.bind(this)({
            submitted: true,
            canSave: false,
            validations: initialValidationState
        });
        
        if (!(await this.validateFields())) {
            return;
        }
        var childrenIds = utilService.findChildrenArrayIds(this.state.currentDirectoryNode);
        var requestPayload = {
            id: this.state.currentDirectoryNode.key,
            name: this.state.directoryName,
            path:
                this.state.currentDirectoryNode.parentId == null
                    ? this.state.directoryName
                    : `${this.state.currentDirectoryNode.path}/${this.state.directoryName}`,
            childrenIds
        };
        var newDirectoryNode = await this.props.dispatch(directoryActions.update(requestPayload));
        this.props.dispatch(alertActions.success('Saved !'));
        this.resetInputGroup();
        this.fetchAllFromServer();
    }

    async handleCancelInput() {
        this.resetInputGroup();
    }
    resetInputGroup() {
        utilService.setStateAsync.bind(this)({
            showDirectoryInput: false,
            directoryName: null,
            submitted: false,
            canSave: true
        });
    }

    //DELETE DIRECTORY REGION
    handleDeleteDirectoryClick() {
        if (this.state.currentDirectoryNode.key === this.state.lucidLinkedDirectory) {
            this.setState({ showDirectoryDeleteMessage: true });
        } else {
            this.setState({ showDeleteConfirmationModal: true });
        }
    }

    onDeleteDirectoryOkClick() {
        this.setState({
            showDirectoryDeleteMessage: false
        });
    }

    async onDeleteDirectoryYesClick() {
        await utilService.setStateAsync.bind(this)({
            submitted: true,
            validations: initialValidationState
        });

        var requestPayload = {
            id: this.state.currentDirectoryNode.key,
            childrenIds: this.state.currentDirectoryNodeChildrenIds
        };
        await this.props.dispatch(directoryActions.delete(requestPayload));
        this.props.dispatch(alertActions.success('Saved !'));
        await utilService.setStateAsync.bind(this)({
            currentDirectoryNode: this.props.initialNodeState,
            currentDirectoryNodeChildrenIds: utilService.findChildrenArrayIds(
                this.props.initialNodeState
            ),
            currentDirectoryExpandedNodeIds: [this.props.initialNodeState.key],
            currentDirectoryExpandedNodes: [this.props.initialNodeState],
            showDirectoryInput: false,
            showDeleteConfirmationModal: false,
            directoryName: null
        });
        await this.fetchAllFromServer();
    }

    onDeleteDirectoryNoClick() {
        this.setState({
            showDeleteConfirmationModal: false
        });
    }

    async handleBreadcrumbItemClick(item) {
        await this.onTreeNodeSelect(item.key);
    }

    handleEditModeChange() {}
    render() {
        let { directoryProofCount, liveUpdateHook } = this.props;
        return (
            <>
                <div className="mrnda-sidebar--left mrnda-sidebar--subnav-open">
                    {this.state.isEditMode ? (
                        <div className="treeview__toolbar-container">
                                    {!this.state.showDirectoryInput && (
                                        <div className="treeview__toolbar__actiongroup">
                                            <span
                                                className="treeview__toolbar-item__icon treeview__toolbar-item"
                                                title="Add Directory"
                                                onClick={this.handleAddDirectory.bind(this)}>
                                                <i className="fa fa-folder-plus"></i>
                                            </span>
                                            <span
                                                className={
                                                    this.state.currentDirectoryNode.key ===
                                                    this.props.initialNodeState.key
                                                        ? 'treeview__toolbar-item__icon treeview__toolbar-item authorization-disabled'
                                                        : 'treeview__toolbar-item__icon treeview__toolbar-item'
                                                }
                                                title="Rename Directory"
                                                onClick={this.handleRenameDirectory.bind(this)}>
                                                <i className="fa fa-pen"></i>
                                            </span>
                                            <span
                                                className={
                                                    this.state.currentDirectoryNode.key ===
                                                    this.props.initialNodeState.key
                                                        ? 'treeview__toolbar-item__icon treeview__toolbar-item authorization-disabled'
                                                        : 'treeview__toolbar-item__icon treeview__toolbar-item'
                                                }
                                                title="Move Directory"
                                                onClick={this.handleMoveDirectory.bind(this)}>
                                                <i className="fas fa-arrows-alt"></i>
                                            </span>
                                            <span
                                                className={
                                                    this.state.currentDirectoryNode.key ===
                                                    this.props.initialNodeState.key
                                                        ? 'treeview__toolbar-item__icon treeview__toolbar-item authorization-disabled'
                                                        : 'treeview__toolbar-item__icon treeview__toolbar-item'
                                                }
                                                title="Delete Directory"
                                                onClick={this.handleDeleteDirectoryClick.bind(
                                                    this
                                                )}>
                                                <i className="fa fa-trash-alt"></i>
                                            </span>
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    right: '32px',
                                                    fontSize: '25px',
                                                    paddingTop: '4px'
                                                }}
                                                className={
                                                    'treeview__toolbar-item__icon treeview__toolbar-item'
                                                }
                                                title="Close Editor"
                                                onClick={() =>
                                                    this.setState({ isEditMode: false })
                                                }>
                                                <i className="fa fa-times"></i>
                                            </span>
                                        </div>
                                    )}
                                    {this.state.showDirectoryInput && (
                                        <div className="treeview__toolbar__inputgroup">
                                            <span className="treeview__toolbar-item">
                                                <input
                                                    type="text"
                                                    autoFocus={true}
                                                    className="treeview__toolbar__item__input form-control"
                                                    name="directoryName"
                                                    value={this.state.directoryName || ''}
                                                    autoComplete="off"
                                                    placeholder="Directory Name"
                                                    onKeyPress={this.handleInputKeyPress.bind(this)}
                                                    onChange={this.handleDirectoryInputChange.bind(
                                                        this
                                                    )}
                                                    maxLength={25}
                                                />

                                                {this.state.submitted &&
                                                    !this.state.validations.directoryName
                                                        .validated && (
                                                        <div className="help-block">
                                                            {
                                                                this.state.validations.directoryName
                                                                    .message
                                                            }
                                                        </div>
                                                    )}
                                            </span>
                                            <button
                                                className="mrnda-icon-btn typography--dark treeview__toolbar-item__icon"
                                                title="Save"
                                                onClick={
                                                    this.state.actionMode == 1
                                                        ? this.handleSaveInput.bind(this)
                                                        : this.handleSaveRenameInput.bind(this)
                                                }
                                                disabled={!this.state.canSave}>
                                                <i className="fa fa-save"></i>
                                            </button>
                                            <button
                                                className="mrnda-icon-btn typography--dark treeview__toolbar-item__icon"
                                                title="Cancel"
                                                onClick={this.handleCancelInput.bind(this)}>
                                                <i className="fa fa-ban"></i>
                                            </button>
                                        </div>
                                    )}
                        </div>
                    ) : (
                        <div className="treeview__toolbar-container">
                            <span className="treeview__toolbar__item__text treeview__toolbar-item">
                                <h5>Directory</h5>
                            </span>
                            {/* <span
                                style={{
                                    fontSize: '13px',
                                    paddingTop: '11px',
                                    position: 'absolute',
                                    right: '35px',
                                }}
                                className="treeview__toolbar-item__icon treeview__toolbar-item"
                                title="Edit"
                                onClick={() => this.setState({ isEditMode: true })}>
                                <i className="fa fa-wrench"></i>
                            </span> */}
                            <AuthorizationComponent
                                component={ModifyDirectoryComponent}
                                classes="treeview__toolbar-item__icon treeview__toolbar-item"
                                permissionKey={{
                                    module: authorizationConstants.Dashboard.KEY,
                                    action: authorizationConstants.Dashboard
                                        .ACTION_DASHBOARD_PROOF_DIRECTORY_EDIT
                                }}
                                onClick={() =>
                                    this.setState({ isEditMode: true, canSave: true })
                                }
                            />
                        </div>
                    )}
                    <div className="mrnda-sidebar__content" style={{marginTop: '68px', height: "calc(100% - 80px)"}}>
                        <div className="padding-2x">
                            <div
                                className={
                                    this.state.isEditMode ? 'treeview__container-focus' : ''
                                }
                                style={{marginTop: '-10px'}}
                                >
                                <TreeView
                                    treeData={this.state.treeData}
                                    onTreeNodeSelect={this.handleTreeNodeSelect.bind(this)}
                                    onTreeNodeExpand={this.onTreeNodeExpand.bind(this)}
                                    selectedKeys={[this.state.currentDirectoryNode.key]}
                                    expandedKeys={
                                        this.state.currentDirectoryExpandedNodeIds
                                    }
                                    lucidLinkedDirectory={this.state.lucidLinkedDirectory}></TreeView>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mrnda-main-content mrnda-main-content--subnav-open mrnda-main-content--sidebar-left-open">
                    <div className="padding-2x--sides padding-2x--top">
                        <BreadCrumb
                            items={this.state.currentDirectoryExpandedNodes}
                            onItemClick={this.handleBreadcrumbItemClick.bind(this)}
                        />
                    </div>

                    <div className="padding-2x">
                        <div className="mrnda-fluid-grid">
                            {this.state.currentDirectoryNode.children.map((node) => {
                                let proofCount = 0;
                                if (directoryProofCount) {
                                    proofCount = directoryProofCount[node.key];
                                }
                                return (
                                    <div
                                        key={node.key}
                                        className={' mrnda-card mrnda-card--hover cursor--pointer'}
                                        onClick={() =>
                                            this.handleFolderClick(node.key, node.children)
                                        }>
                                        <div className="whitebg">
                                            <div className="mrnda-card__image-container mrnda-card__image-container--folder">
                                                <div className="whitebg-image__option whitebg-image__option--more d-none">
                                                    <i className="fas fa-ellipsis-v" />
                                                </div>
                                                <div className="whitebg-image__option whitebg-image__option--check d-none">
                                                    <i className="fas fa-check" />
                                                </div>
                                            </div>
                                            <div className="padding-1_5x text--center">
                                                <p className="typography--ellipsis margin-_5x--bottom">
                                                    <span className="typography--dark">
                                                        {node.title}
                                                    </span>
                                                </p>
                                                <p className="typography--ellipsis typography--small typography--muted">
                                                    <span>
                                                        {proofCount > 1
                                                            ? `${proofCount} files`
                                                            : `${proofCount} file`}
                                                    </span>
                                                    <span className="mrnda-dot-separator">•</span>
                                                    <span>
                                                        {node.children.length > 1
                                                            ? `${node.children.length} directories`
                                                            : `${node.children.length} directory`}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            {this.state.proofs.map((proof) => (
                                <AuthorizationComponent
                                    key={proof.id}
                                    component={FolderViewCard}
                                    proof={proof}
                                    permissionKey={{
                                        module: authorizationConstants.Dashboard.KEY,
                                        action: authorizationConstants.Dashboard
                                            .ACTION_DASHBOARD_PROOF_VIEW
                                    }}
                                    classes="mrnda-card mrnda-card--hover cursor--pointer"
                                    onClick={() => this.handleCardClick(proof.defaultVersionId)}
                                />
                            ))}
                        </div>
                    </div>
                    {this.state.proofs &&
                        this.state.proofs.length < 1 &&
                        this.state.currentDirectoryNode &&
                        this.state.currentDirectoryNode.children.length < 1 && (
                            <div className="mrnda-empty-state-wrapper">
                                <div className="mrnda-empty-state">
                                    <img
                                        className="icon-mask mrnda-empty-state__icon margin-3x--bottom"
                                        src="../../assets/Images/Icons/interactive/icon-int-folder.svg"
                                    />
                                    <p className="typography--h1 typography--light typography--muted">
                                        This directory is empty
                                    </p>
                                </div>
                            </div>
                        )}
                </div>
                {this.state.showDirectoryMoveModal && (
                    <DirectoryMoveModal
                        treeData={this.state.treeData}
                        currentDirectoryNode={this.state.currentDirectoryNode}
                        currentParentDirectoryNode={this.props.initialNodeState}
                        show={this.state.showDirectoryMoveModal}
                        onClose={this.handleMoveDirectoryOnClose.bind(this)}
                    />
                )}
                {this.state.showDeleteConfirmationModal && (
                    <ConfirmationModal
                        show
                        onHide={this.onDeleteDirectoryNoClick.bind(this)}
                        onPrimaryClick={this.onDeleteDirectoryYesClick.bind(this)}
                        onSecondaryClick={this.onDeleteDirectoryNoClick.bind(this)}
                        primaryButtonText="Yes"
                        secondaryButtonText="No"
                        title={`Delete Directory: ${this.state.currentDirectoryNode.title}`}
                        bodyText={`Are you sure you want to delete the directory including the files in it?`}
                    />
                )}
                {this.state.showDirectoryDeleteMessage && (
                    <ConfirmationModal
                        show
                        onHide={this.onDeleteDirectoryOkClick.bind(this)}
                        onSecondaryClick={this.onDeleteDirectoryOkClick.bind(this)}
                        secondaryButtonText="Ok"
                        title={`Delete Directory: ${this.state.currentDirectoryNode.title}`}
                        bodyText={`This directory is connected to LucidLink and cannot be deleted`}
                    />
                )}
            </>
        );
    }
}

const FolderViewCard = ({ proof, classes, onClick, isAuthorized }) => {
    var proofAvailable =
        proof.mediaType === 2 || proof.mediaType === 4
            ? proof.status &&
              proof.status.thumbnailServiceStatus === 2 &&
              proof.status.metadataServiceStatus === 2 &&
              proof.status.conversionServiceStatus === 2
            : proof.status.documentProcessingStatus === 2;

    var thumbStatus =
        proof.mediaType === 2 || proof.mediaType === 4
            ? proof.status.thumbnailServiceStatus
            : proof.status.documentThumbnailStatus;
    var thumbnailAvailable = proof.status && thumbStatus === 2;

    var proofHasProcessingError =
        (proof.status && proof.status.thumbnailServiceStatus === 999) ||
        proof.status.metadataServiceStatus === 999 ||
        proof.status.conversionServiceStatus === 999 ||
        proof.status.htmlStaticToImageStatus === 999 ||
        proof.status.htmlDynamicStatus === 999;

    var defaultThumbClass =
        proof.mediaType === 2 || proof.mediaType === 4 ? 'fas fa-film' : 'far fa-file-alt';

    var title = '';
    if (!isAuthorized) {
        title = 'Not authorized';
    }

    if (!proofAvailable) {
        title = proofHasProcessingError ? 'Error' : 'Still processing...';
    }
    var tootltipProps =
        !isAuthorized || !proofAvailable
            ? {
                  datatoggle: 'tooltip',
                  dataplacement: 'top',
                  title,
                  delay: 0
              }
            : null;

    return (
        <div {...tootltipProps}>
            <div
                key={proof.id}
                className={
                    isAuthorized /*&& proofAvailable */
                        ? classes
                        : classes + ' authorization-disabled'
                }
                onClick={isAuthorized ? onClick : () => {}}>
                
                
                <div className="mrnda-card__image-container" {...tootltipProps}>
                    {proofAvailable ? (
                        <div
                            className="mrnda-card__image"
                            style={{
                                backgroundImage: `url('${proof.thumbnailURL}')`
                            }}></div>
                    ) : (
                        
                        <div
                            className="mrnda-card__image"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '90px'
                            }}>
                            <i className={defaultThumbClass}></i>
                            <div
                                style={{ display: proofAvailable ? 'none' : 'block' }}
                                className={`proof-folder-view-processing ${
                                    proofHasProcessingError ? 'has-error' : ''
                                }`}>
                                {proofHasProcessingError ? 'ERROR' : 'Processing'}
                            </div>
                        </div>
                    )}
                    <div className="whitebg-image__option whitebg-image__option--more d-none">
                        <i className="fas fa-ellipsis-v" />
                    </div>
                    <div className="whitebg-image__option whitebg-image__option--check d-none">
                        <i className="fas fa-check" />
                    </div>
                    {
                        proof.isLocked === true &&
                        <div className='mrnda-card__locked'>
                            <i className="fas fa-lock"></i>
                            <span>Locked</span>
                        </div>
                    }

                    {/* <div                         
                        className={classNames(
                            {
                                'mrnda-card__info': true,
                                'mrnda-card__info-has-recent' : proof.hasRecentActivity
                            })}>
                        <i className="fas fa-info-circle"></i>
                        <div className='mrnda-card__info-contents'>
                            <span>
                                Total comments : <strong>{proof.defaultVersionCommentCount}</strong>
                            </span>
                            <span>
                                Total versions : <strong>{proof.versionCount}</strong>
                            </span>
                        </div>                                                
                    </div> */}

                </div>
                <>
                <div className="padding-1_5x">
                    <p title={proof.documentName} className="typography--ellipsis margin-_5x--bottom">
                        <span className="typography--dark">{proof.documentName}</span>
                    </p>
                    <p className="typography--ellipsis typography--small typography--muted">
                        Created by {proof.createdBy}
                    </p>                                    
                </div>
                {/* Proof Version and Status */}
                <div className="mrnda-card__proof-status">                    
                    <p style={{height:'20px', width:'85%'}} className="typography--ellipsis typography--small typography--muted">
                       {proof.workflowStatus? proof.workflowStatus.label : "" } 
                    </p>

                    <div                         
                        className={classNames(
                            {
                                'mrnda-card__info': true,
                                'mrnda-card__info-has-recent' : proof.hasRecentActivity
                            })}>
                        <i className="fas fa-info-circle"></i>
                        <div className='mrnda-card__info-contents'>
                            <span >
                                Total comments : <strong>{proof.defaultVersionCommentCount}</strong>
                            </span>
                            <span>
                                Total versions : <strong>{proof.versionCount}</strong>
                            </span>
                        </div>                                                
                    </div>
                </div>   
                </>             
            </div>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    const { proofsByDirectory, proofsFilters } = state;
    const {
        rows,
        pageIndex,
        pageSize,
        totalCount,
        totalPages,
        directoryProofCount
    } = proofsByDirectory;
   
    return {
        proofs: rows,
        pageIndex,
        pageSize,
        totalCount,
        totalPages,
        proofsFilters: proofsFilters.proofsFilters,
        directoryProofCount,
        proofsByDirectory
    };
}

const withLiveUpdate = withLiveUpdateHook(ProofsContainerFolderView);
const connectedProofsContainer = connect(mapStateToProps)(withLiveUpdate);
export { connectedProofsContainer as ProofsContainerFolderView };


