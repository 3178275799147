export const fileUploadConstants = {
    FILE_LOADED: 'FILEUPLOAD_FILE_LOADED',
    INITIATED: 'FILEUPLOAD_INITIATED',
    STARTED: 'FILEUPLOAD_STARTED',
    ERROR: 'FILEUPLOAD_ERROR',
    FINALIZING: 'FILEUPLOAD_FINALIZING',
    COMPLETED: 'FILEUPLOAD_COMPLETED',
    PAUSED: 'FILEUPLOAD_PAUSED',
    NONE: 'FILEUPLOAD_NONE'
};
