import React from 'react';

export const EditorToolbarActionButton = ({ icon, handleClicked, isPressed, isDisabled }) => {
    const editorToolbarActionButtonStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '44px',
        width: '44px',
        justifyContent: 'center',
        marginLeft: '12px',
        borderRadius: '4px',
        transition: 'all 0.5s'
    };
    const disabledStyle = {
        opacity: 0.5,
        pointerEvents: 'none',
        cursor: 'not-allowed'
    };
    const baseStyle = isPressed
        ? {
              ...editorToolbarActionButtonStyle,
              background: 'var(--tertiary-background-color)'
          }
        : editorToolbarActionButtonStyle;

    return (
        <div
            onClick={handleClicked}
            style={isDisabled === true ? {...baseStyle, ...disabledStyle} : baseStyle}>
            {icon}
        </div>
    );
};
