import React from 'react';
import { connect } from 'react-redux';
import { userActions, userService, alertActions, SupportContactArea } from '../../../components';
import { utilService, history } from '../../../_helpers';
import './UserLoginPage.scss';
import localStorage from 'local-storage';
import { LoginPageTitleBar } from './LoginPageTitleBar';
import withNoAuthNav from '../../_shared/Nav/withNoAuthNav';

const initialValidationState = {
    email: {
        validated: true,
        message: '',
    },
};
class UserForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            validations: initialValidationState,
            submitted: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        document.title = 'Brandshare Collaboration - Request Password Change';
        this.props.dispatch(userActions.logout());
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value, submitted: false });
    }

    async validateFields() {
        var globalValidation = true;
        var { validations } = this.state;
        var validationCopy = { ...validations };

        if (
            !this.state.email ||
            (this.state.email && userService.validateEmail(this.state.email))
        ) {
            validationCopy.email = {
                validated: false,
                message: 'Enter a valid email ',
            };
            globalValidation = false;
        }
        await utilService.setStateAsync.bind(this)({
            validations: validationCopy,
        });
        return globalValidation;
    }

    async handleSubmit(e) {
        e.preventDefault();
        await utilService.setStateAsync.bind(this)({
            submitted: true,
            validations: initialValidationState,
        });
        var allValidated = await this.validateFields();
        if (!allValidated) {
            return;
        }
        const { email } = this.state;
        if (email) {
            await userService.forgotPasswordInvite(email);
            this.props.dispatch(alertActions.success('Email Sent !'));
            history.push('/login');
        }
    }

    getImageSource() {
        var preference = localStorage.get('preference-theme') || 'light';        
        return preference === 'dark'?  '../../assets/Images/brand_logo_all_white.png' : '../../assets/Images/brand_logo.png';
    }

    render() {
        const { email, submitted, validations } = this.state;
        return (
            <div className="mrnda-main-content padding-2x">
                <div className="mrnda-flex-center-wrapper">
                    <div className="sign-in-box-wrapper">
                        <div className="tenant-logo margin-2x--bottom typography--center">
                            <div>
                                {/* <i style={{ color: '#fc275c' }} className="fab fa-mix fa-7x"></i> */}
                                <img src={this.getImageSource()}></img>
                            </div>
                        </div>
                        <div className="sign-in-box mrnda-card padding-3x margin-4x--bottom">
                            <h1 className="margin-2x--bottom text--center">
                                Forgot your password ?
                            </h1>
                            <div className="tile-message">
                                <form className="" name="form" onSubmit={this.handleSubmit}>
                                    <div className="form-horizontal">
                                        <div className="form-horizontal">
                                            <p
                                                style={{
                                                    opacity: '.8',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                    width: '310px',
                                                    marginBottom: '10px',
                                                }}>
                                                Enter your email and click "Send" to recieve
                                                password reset link via email.
                                            </p>
                                            {submitted && !validations.email.validated && (
                                                <div className="help-block">
                                                    {validations.email.message}
                                                </div>
                                            )}
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={email}
                                                onChange={this.handleChange}
                                                className="mrnda-input-text margin-2x--bottom"
                                                placeholder="Email"
                                                autoFocus=""
                                                required
                                            />
                                            <div className="control-group">
                                                <div className="controls">
                                                    <button className="mrnda-btn mrnda-btn--full margin-2x--bottom">
                                                        Send
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <p className="text sm-forward typography--center">
                                    <a className="typography--small" href=""></a>
                                </p>
                            </div>
                        </div>
                        <SupportContactArea />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedUserForgotPassword = withNoAuthNav(connect(mapStateToProps)(UserForgotPassword));
export { connectedUserForgotPassword as UserForgotPassword };
