import React, { useState } from 'react';
import { history } from '../../../_helpers';
import cx from 'classnames';
import { withDocumentCompareConsumer } from '../DocumentAnnotation/Context/DocumentAnnotationConsumer';
import { documentAnnotationConstants } from '../DocumentAnnotation/Context/documentAnnotation.constants';
import ColorPicker from '../../_shared/ColorPickerComponent';
import { StrikedToggleButton } from './StrikedToggleButton';
import HandIcon from '../../_shared/Icon/HandIcon';
import { isCompactNav } from '../../../_helpers/navbarUtil';
import { WrappedInsidePrimaryNav } from '../../_shared/Nav/withPrimaryNav';


const ProofVideoCompareToolbar = ({
    proofVersionA,
    proofVersionB,
    isSynced,
    setIsSynced,
    isAutoCompare,
    setIsAutoCompare,
    onResetToSync,
    compareColor,
    onCompareColorChange,
    compareOpacity,
    onCompareOpacityChange,
    autoCompareClicked,
    setCompareSide,
    compareSide,
    isOverlayCompare, 
    setIsOverlayCompare,
    overlayOpacity, 
    setOverlayOpacity,
    zoomIn,
    zoomOut,
    compareContainerZoom,
    setCompareContainerZoom,
    showComments,
    setShowComments,
    showThumbnails,
    setShowThumbnails,
    disableThumbnails, 
    setDisableThumbnails,
    disableComments, 
    setDisableComments,
    documentViewerA, documentViewerB,
    compareHandTool,
    onHandToolClicked,
    compactNavbar
}) => {

    const {ZOOM_SELECTIONS:zoomSelections } = documentAnnotationConstants;
    const [showOpacityColorPicker, setShowOpacityColorPicker] = useState(false);
    const [showOverlayOpacityPicker, setShowOverlayOpacityPicker] = useState(false);
    const [selectedZoom, setSelectedZoom] = useState(zoomSelections[0]);

    const zoomText = (zoom) => {
        if (zoom === undefined){
            return " ";
        }
        return `${Math.ceil(zoom)}%`;
    };
    


    return (
        <div className="mrnda-video-compare__toolbar"
             style={compactNavbar === true? { border: 'none', 'zIndex': '1'}: {'zIndex': '1'}}
                >
             
            <div
                className="mrnda-video-compare__toolbar__header text-truncate"
                title={`${proofVersionA.name} - v${proofVersionA.versionNumber}`}>
                <span className="mrnda-video-compare__toolbar__header--text text-truncate">
                    {proofVersionA.name}
                </span>{' '}
                <span className="mrnda-video-compare__toolbar__header--version">
                    - v{proofVersionA.versionNumber}
                </span>
            </div>

            {/* NOTE: Id will allow comment list to add 
            this element in click outside handler's ignore list */}
            <div 
                id = 'toolbox-zoom-tools'
                className="mrnda-video-compare__toolbar__settings">
                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                        active: isSynced
                    })}
                    onClick={() => setIsSynced(!isSynced)}
                    title={'Sync'}>
                    <i className="fas fa-link"></i>
                </div>
                


                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                        active: false,
                        'authorization-disabled': disableThumbnails
                    })}
                    onClick={()=>{
                        setShowThumbnails(!showThumbnails)
                    }}
                    title={'Show/Hide thumbnails'}>                    
                             
                    <span className="fa-stack mrnda-document-compare-icon-stack" >
                    
                        <i className="far fa-bookmark fa-stack-1x"></i>
                        {
                            showThumbnails && 
                            <i className="fas fa-slash fa-stack-1x" style={{ textShadow: '0.1em 0.15em var(--secondary-background-color)' }}></i>
                        }
                    </span>
                   

                </div>


                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                         active: false,
                        'authorization-disabled': disableComments
                    })}
                    onClick={()=>{
                        setShowComments(!showComments)
                    }}
                    title={'Show/Hide comments'}>                    
                             
                    <span className="fa-stack mrnda-document-compare-icon-stack" >
                        <i className="far fa-comment-alt fa-stack-1x"></i>
                        {
                            showComments && 
                            <i className="fas fa-slash fa-stack-1x" style={{ textShadow: '0.1em 0.15em var(--secondary-background-color)' }}></i>
                        }
                    </span>
                   

                </div>

                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                         active: compareHandTool,
                    })}
                    onClick={onHandToolClicked}
                    title={'Hand tool   (H)'}>                    
                             
                    <span className="fa-stack mrnda-document-compare-icon-stack" >                    
                        <HandIcon height='20px'/>
                    </span>
                   

                </div>

                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                        active: false
                    })}
                    onClick={zoomOut}
                    title={'Zoom out'}>                    
                    <i className="fas fa-search-minus"></i>
                </div>
                
                
                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                        active: false
                    })}                    
                    title={'Zoom in'}>                    

                    <div style={{marginTop : '-2px'}} className="belect zoom-select">
                    <select
                        id="zoomSelectionsSelect"
                        className="mrnda-input-select-document-zoom"
                        name="zoomSelectionsSelect"
                        value={selectedZoom.value}
                        onChange={(event) => {
                            var index = event.nativeEvent.target.selectedIndex;
                            var selectedOption = zoomSelections[index];
                            setCompareContainerZoom(selectedOption.value);
                        }}>
                        {zoomSelections.map((c) => (
                            <option
                                style={{
                                    display: c.value === -1 ? 'none' : 'block'
                                }}
                                title={c.tooltip}
                                key={c.value}
                                value={c.value}>
                                {c.value === -1 ? zoomText(compareContainerZoom) : c.label}
                            </option>
                        ))}
                    </select>
                
                    </div>
                

                </div>


               
                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                        active: false
                    })}
                    onClick={zoomIn}
                    title={'Zoom in'}>                    
                    <i className="fas fa-search-plus"></i>
                </div>
                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                        active: true
                    })}
                    // onClick={() => setIsAutoCompare(!isAutoCompare)}
                    title={'Color and Opacity'}>
                    <span>{' | '}</span>
                </div>

                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,    
                         active: isOverlayCompare,
                         'd-none': isAutoCompare,                
                    })}
                    onClick = {() => {
                       setIsOverlayCompare(!isOverlayCompare)
                    }}
                    title={'Overlay compare'}>
                    <i className="far fa-file"></i>
                </div>


                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                        active: showOverlayOpacityPicker,
                        'd-none': !isOverlayCompare
                    })}
                    title={'Overlay Opacity'}>
                    <div
                        className={cx({
                            'overlay-opacity-host__main': true,
                            selected: showOverlayOpacityPicker
                        })}
                        onClick={() => setShowOverlayOpacityPicker(!showOverlayOpacityPicker)}>

                        <span className = 'overlay-opacity-host__main__label'>
                            {overlayOpacity}
                        </span>

                    </div>
                </div>




                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                        'd-none': isOverlayCompare,
                        'authorization-disabled': documentViewerA === null  || documentViewerB === null,
                        active: isAutoCompare
                    })}
                    onClick={() => {
                            autoCompareClicked();
                            setIsAutoCompare(!isAutoCompare);
                        }
                    }
                    title={'Auto compare'}>
                    <i className="far fa-clone"></i>
                </div>
            
                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                        active: true,
                        'd-none': !isAutoCompare
                    })}
                    // onClick={() => setIsAutoCompare(!isAutoCompare)}
                    title={'Color and Opacity'}>
                    <div
                        className={cx({
                            'color-palette__color-outer': true,
                            selected: showOpacityColorPicker
                        })}
                        onClick={() => setShowOpacityColorPicker(!showOpacityColorPicker)}>
                        <div
                            className="color-palette__color-inner"
                            style={{ backgroundColor: compareColor }}
                        />
                    </div>
                </div>

                <div
                    className={cx({
                        'mrnda-video-compare__toolbar__settings--item': true,
                        'd-none': !isAutoCompare
                    })}
                    onClick = {() => {
                        console.log('switch side clicked !');
                        setCompareSide(compareSide === 'right' ? 'left' : 'right');
                    }}
                    title={'Switch side'}>
                    <i className="fas fa-exchange-alt"></i>
                </div>

                {isAutoCompare && showOpacityColorPicker && (
                    <div
                        className="mrnda-document-compare__toolbar__settings__auto-compare"
                        tabIndex="0"
                        onBlur={() => setShowOpacityColorPicker(false)}>
                        <div className="mrnda-document-compare__toolbar__settings__auto-compare__opacity">
                            <div className="mrnda-document-compare__toolbar__settings__auto-compare__opacity--header">
                                <span>Opacity</span>
                            </div>
                            <div className="">
                                <input
                                    type="range"
                                    name="volume"
                                    className="mrnda-document-compare__toolbar__settings__auto-compare__opacity--range"
                                    min="0"
                                    max="1"
                                    step="0.1"
                                    value={compareOpacity}
                                    onChange={(event) => {
                                        onCompareOpacityChange(event.currentTarget.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mrnda-video-compare__toolbar__settings__auto-compare__colors">
                            <ColorPicker selectedColor ={compareColor} onSelectedColorChanged={onCompareColorChange} />                            
                        </div>
                    </div>
                )}


                {isOverlayCompare && showOverlayOpacityPicker && (
                    <div
                        className="mrnda-document-compare__toolbar__settings__auto-compare"
                        tabIndex="0"
                        onBlur={() => setShowOpacityColorPicker(false)}>
                        <div className="mrnda-document-compare__toolbar__settings__auto-compare__opacity">
                            <div className="mrnda-document-compare__toolbar__settings__auto-compare__opacity--header">
                                <span>Overlay</span>
                            </div>
                            <div className="">
                                <input
                                    type="range"
                                    name="volume"
                                    className="mrnda-document-compare__toolbar__settings__auto-compare__opacity--range"
                                    min="0"
                                    max="1"
                                    step="0.1"
                                    value={overlayOpacity}
                                    onChange={(event) => {
                                        setOverlayOpacity(event.currentTarget.value);
                                    }}
                                />
                            </div>
                        </div>
                        
                    </div>
                )}


            </div>
            <div
                style={{marginRight : '48px'}}
                className="mrnda-video-compare__toolbar__header text-truncate"
                title={`${proofVersionB.name} - v${proofVersionB.versionNumber}`}>
                <span className="mrnda-video-compare__toolbar__header--text text-truncate">
                    {proofVersionB.name}
                </span>{' '}
                <span className="mrnda-video-compare__toolbar__header--version">
                    - v{proofVersionB.versionNumber}
                </span>
            </div>
            <button
                style={ compactNavbar === true? { top: '74px' } : {}}
                title="Close Proof"
                onClick={() => history.goBack()}
                className="mrnda-video-compare__toolbar__close mrnda-icon-btn">
                <i className="fas fa-times fa-2x"></i>
            </button>
        </div>
    );
};

const DocumentCompareToolbar = (props) => {
        
    const compactNavbar = isCompactNav();

    return (
        <>
            {                
                compactNavbar === true 
                ? <WrappedInsidePrimaryNav 
                        WrappedComponent={ProofVideoCompareToolbarHOC}
                        showRightActions = {true}
                        style = {{ backgroundColor: 'var(--secondary-background-color)' }}
                        primaryActionsStyle = {{ marginRight: "0" }}
                        pageActionsStyle = {{ paddingLeft: '0px', width : 'calc(100% - 240px)' }} 
                        secondaryActionsStyle = {{ marginLeft  : '0px'}}      
                        autoCompareClicked={() => { }}                                           
                        />

                : <ProofVideoCompareToolbarHOC autoCompareClicked={() => { }}/>
            }
        </>
    );
}

const ProofVideoCompareToolbarHOC = withDocumentCompareConsumer(ProofVideoCompareToolbar);
export { ProofVideoCompareToolbarHOC, ProofVideoCompareToolbar, DocumentCompareToolbar };
