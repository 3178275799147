import React, { useState, useEffect } from 'react';
import { VideoTranscriptionContext } from '../VideoAnnotation/Context/VideoAnnotationContext';
import { initPlayerColors } from '../VideoAnnotation/Context/videoAnnotation.constants';



function VideoTranscriptionProvider({ children }) {
    const [videoPlayer, setVideoPlayer] = useState(null);
    const [currentTimeInSeconds, setCurrentTimeInSeconds] = useState(0);
    const [playerColors, setPlayerColors] = useState(initPlayerColors);

    const seekToTime = (targetFrame, frameRate) => {
        var seekTime = Number((targetFrame / frameRate).toFixed(5));
        videoPlayer.currentTime = seekTime;
        videoPlayer.pause();
        setCurrentTimeInSeconds(seekTime);
    };

    const onPlayPauseToggle = () => {
        if (videoPlayer.paused) {
            videoPlayer.play();
        } else {
            videoPlayer.pause();
        }
    };

    const providerState = {
        videoPlayer,
        playerColors,
        setVideoPlayer,
        onPlayPauseToggle,
        seekToTime,
        currentTimeInSeconds,
        setCurrentTimeInSeconds,
        setPlayerColors
    };

    return (
        <VideoTranscriptionContext.Provider value={providerState}>
            {children}
        </VideoTranscriptionContext.Provider>
    );
}

export { VideoTranscriptionProvider };
