import React, { useRef, useState, useMemo, useCallback } from 'react';
import debounce from 'lodash.debounce';
import useStateRef from 'react-usestateref';
import cx from 'classnames';
const SearchComponent = ({ handleSearch, clearEnabled,  handleCleared }) => {
    
    const [searchText, setSearchText] = useState('');
    const isFetchingRef = useRef(false);
    
    const inputRef = useRef();

    const onTextChange = async (event) => {
        event.preventDefault();
        var { value } = event.target;        
        setSearchText(value); 
        performSearchDebounced(value);
    }

    const performSearch = async (value) => {
        isFetchingRef.current = true;
        console.log('searching...' + value);
        await handleSearch(value);
        isFetchingRef.current = false;    
    }
    

    const performSearchDebounced = useCallback(debounce(performSearch, 300),[]);

    const onHandleCleared = async () => {
        inputRef.current.value = '';
        setSearchText('');
        await handleCleared();
    }
    
    return (
        <div className="mrnda-input-text--search user-manage-search">
            <span className={`mrnda-icon-btn ${isFetchingRef.current === true ? 'icon_spin' : ''}`}>
                <i className={`fas ${isFetchingRef.current === true ? 'fa-spinner' : 'fa-search'}`}/>                
            </span>

            <input
                ref = {inputRef} 
                type="text"
                className="mrnda-input-text"
                placeholder="Search..."
                onChange={onTextChange}
            />

            {(clearEnabled === true && searchText !== '') && (
                <span className="mrnda-icon-btn mrnda-icon-btn-close" onClick={onHandleCleared}>
                    <i className="fas fa-times" />
                </span>
            )}
        </div>
    );
};

export default SearchComponent;
