import React from 'react';
import { secondaryBaseTextStyle, primaryBaseTextStyle } from '../../_shared/Styles/TextStyles';

export function ProofNotSupported({primaryText, secondaryText, additionalTexts }) {
    const baseStyle = {
        maxWidth : '210px',
        lineHeight : '18px'
    }
    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                position: 'absolute',
                backgroundColor: 'var(--primary-background-color)',
                display: 'flex',
                flexDirection: "column" , 
                alignContent: 'center' ,
                justifyContent : 'center',
                alignItems: 'center'
            }}>
            <span style={{ ...baseStyle, ...primaryBaseTextStyle, textAlign: 'center', fontWeight: '600'}}>
                {primaryText}
            </span>
            <span style={{ marginTop: '12px', ...baseStyle,...secondaryBaseTextStyle, textAlign: 'center'}}>
                {secondaryText}
            </span>

            {
                additionalTexts?.map((text, index) => {
                    return (
                        <span key={index} style={{ marginTop: '12px', ...baseStyle, ...secondaryBaseTextStyle, textAlign: 'center' }}>
                            {text}
                        </span>
                    )
                })
            }
        </div>
    );
}
