import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { alertActions, proofActions, TreeView, DirectoryPathStatic } from '../../../components';
import { utilService } from '../../../_helpers';

class ProofMoveModal extends React.Component {
    constructor(props) {
        super(props);
        var clonedTreedata = [...this.props.treeData];
        var mutedTreedata = clonedTreedata.map(item => {
            var newItem = { ...item };
            return newItem;
        });
        this.state = {
            treeData: mutedTreedata,
            proof: this.props.proof,
            targetParentDirectoryNode: this.props.targetParentDirectoryNode,
            currentDirectoryExpandedNodes: [this.props.targetParentDirectoryNode.key],
            submitted: false,
            canSave: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {}

    async onTreeNodeSelect(selectedNode) {
        var expandedKeys = utilService.findParentsArrayIds(
            this.state.treeData,
            selectedNode.key,
            'key',
            'title',
            'children',
        );
        await utilService.setStateAsync.bind(this)({
            targetParentDirectoryNode: selectedNode,
            currentDirectoryExpandedNodes: expandedKeys,
            submitted: false,
        });
    }

    onTreeNodeExpand(selectedNodeKeys) {
        this.setState({
            currentDirectoryExpandedNodes: selectedNodeKeys,
            submitted: false,
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({
            canSave: false
        });
        var requestPayload = {
            id: this.state.proof.id,
            directoryNodeId: this.state.targetParentDirectoryNode.key,
        };
        await this.props.dispatch(proofActions.move(requestPayload));
        this.props.dispatch(alertActions.success('Saved !'));
        this.onModalClose(true);
    }
    onModalClose(hasChanges) {
        this.props.onClose(hasChanges);
    }
    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={() => this.props.onClose(false)}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'}
                scrollable>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Move File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form name="form" onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Current Directory</label>
                            <div
                                style={{
                                    padding: '10px',
                                    backgroundColor: 'var(--secondary-background-color)',
                                    borderRadius: '0.3rem',
                                }}>
                                <DirectoryPathStatic path={this.state.proof.path} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="name">Move to</label>
                            <div id="role-permission-container">
                                <TreeView
                                    treeData={this.state.treeData}
                                    onTreeNodeSelect={this.onTreeNodeSelect.bind(this)}
                                    onTreeNodeExpand={this.onTreeNodeExpand.bind(this)}
                                    selectedKeys={[this.state.targetParentDirectoryNode.key]}
                                    expandedKeys={this.state.currentDirectoryExpandedNodes}
                                />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant=""
                        className="mrnda-btn mrnda-btn"
                        onClick={this.handleSubmit.bind(this)}
                        disabled={!this.state.canSave}
                        >
                        Save
                    </Button>
                    <Button  className ="mrnda-btn--secondary" onClick={() => this.props.onClose(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    const { s3Info } = state;
    return {
        s3TempInfo: s3Info.s3Info,
    };
}

const connectedProofMoveModal = connect(mapStateToProps)(ProofMoveModal);
export { connectedProofMoveModal as ProofMoveModal };
