import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import DesktopBreakpoint from '../responsive/DesktopBreakpoint';

const AnnotationCommentArrow = ({ point2, startPoints, disable, color, singlePoint = false }) => {
    return (
        <DesktopBreakpoint>
            <div
                className={cx({
                    'annotation-comment-arrow': true,
                    'annotation-comment-arrow-enabled': !disable,
                    'annotation-comment-arrow-disabled': disable
                })}>
                {/* <div
                style={{
                    marginTop: '60px',
                    position: 'absolute',
                    zIndex: 9999999,
                    right: 0,
                    bottom: 0
                }}>
                <h1 className="success"> startPoints : {JSON.stringify(startPoints)}</h1>

                <h1 className="success"> startPoints[0] : {JSON.stringify(startPoints[0])}</h1>
                <h1 className="success"> startPoints[1] : {JSON.stringify(startPoints[1])}</h1>
            </div> */}

                {startPoints && point2.x && point2.y && startPoints && (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            zIndex: '1',
                            pointerEvents: 'none',
                            left: 0,
                            zIndex: 2
                        }}>
                        <g
                            stroke={color === undefined ? 'var(--primary-color)' : color}
                            strokeWidth="1">
                            <filter id="dropshadow" height="130%">
                                <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                                <feOffset dx="0" dy="0.05" result="offsetblur" />
                                <feComponentTransfer>
                                    <feFuncA type="linear" slope="0.5" />
                                </feComponentTransfer>
                                <feMerge>
                                    <feMergeNode />
                                    <feMergeNode in="SourceGraphic" />
                                </feMerge>
                            </filter>

                            {/* <line
                            x1={point1.x}
                            y1={point1.y}
                            x2={point2.x}
                            y2={point2.y}
                            style={{ filter: 'url(#dropshadow)' }}></line> */}

                            {startPoints.map((p, index) => {
                                return (
                                    <line
                                        key={index}
                                        x1={p.x}
                                        y1={p.y}
                                        x2={point2.x}
                                        y2={point2.y}
                                        style={{ filter: 'url(#dropshadow)' }}></line>
                                );
                            })}
                        </g>
                    </svg>
                )}
            </div>
        </DesktopBreakpoint>
    );
};

export default AnnotationCommentArrow;
