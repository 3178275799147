
export const defaultThemeColors = {
    light: {
        primaryColor: '#0091FF',
        primaryBackgroundColor: '#ffffff',
        secondaryBackgroundColor: '#f7f8f9',
        primaryTextColor: '#11111a',
        secondaryTextColor: '#78787d',
        iconTextColor: '#66666a',
        borderColor: '#dadce0',
        controlBorderColor: '#d6d6d6',
        tertiaryBackgroundColor : '#e9ecef',
        blackBackgroundColor : '#f5f5f5',
    },
    dark: {
        primaryColor: '#0068b8',
        primaryBackgroundColor: '#353535',
        secondaryBackgroundColor: '#3e3e3e',
        primaryTextColor: '#f5f5f5',
        secondaryTextColor: '#bfbfbf',
        iconTextColor: '#bbbbbb',
        borderColor: '#2d2d2d',
        controlBorderColor : '#525252',
        tertiaryBackgroundColor: '#5a5a5a',
        blackBackgroundColor : '#222222',
    }
};

export const defaultThemeColorsV2 = {
    light: {
        primaryColor: '#0091FF',
        primaryBackgroundColor: '#ffffff',
        secondaryBackgroundColor: '#f7f8f9',
        primaryTextColor: '#11111a',
        secondaryTextColor: '#78787d',
        iconTextColor: '#66666a',
        borderColor: '#dadce0',
        controlBorderColor: '#d6d6d6',
        tertiaryBackgroundColor : '#e9ecef',
        blackBackgroundColor : '#f5f5f5',
    },
    dark: {
        primaryColor: '#0068b8',
        primaryBackgroundColor: '#1A1A1A',
        secondaryBackgroundColor: '#222222',
        tertiaryBackgroundColor: '#333333',
        primaryTextColor: '#f4f4f4',
        secondaryTextColor: '#AEAEAE',
        iconTextColor: '#bbbbbb',
        borderColor: '#2d2d2d',
        controlBorderColor : '#525252',        
        blackBackgroundColor : '#222222',
    }
};