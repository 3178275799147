import React from 'react';
export function AnnotationReportHeader({ proofVersionSummary, pageCount, margins = {} }) {
    return (
        <>
            <div className="print-summary-header-container" style={margins}>
                <h1 className="print-summary-header">
                    <span className="primary-summary-header-title">
                        {proofVersionSummary.proofVersion.name}
                    </span>
                    <span className="print-summary-version">
                        v.
                        <span className="version-current">
                            {proofVersionSummary.proofVersion.versionNumber}
                        </span>
                        /<span className="version-all">{proofVersionSummary.versionCount}</span>
                    </span>
                </h1>
                <span className="print-summary-date">{new Date().toLocaleString()}</span>
            </div>
            <div className="print-summary-header-body-container">
                <section className="section-collapsible">
                    <header className="section-header">
                        <div className="section-header-title">
                            {/* <svg
                        fill="currentColor"
                        preserveAspectRatio="xMidYMid meet"
                        height="16px"
                        width="16px"
                        className="icon"
                        viewBox="0 0 16 16"
                        style={{ verticalAlign: 'middle' }}>
                        <path d="M8 3.5l-7.707 7.367c-0.18 0.17-0.293 0.41-0.293 0.676s0.112 0.507 0.292 0.676c0.185 0.174 0.434 0.281 0.708 0.281s0.523-0.107 0.708-0.281l6.292-6.015 6.293 6.015c0.184 0.174 0.433 0.28 0.707 0.28s0.523-0.107 0.708-0.281c0.18-0.169 0.292-0.409 0.292-0.676s-0.112-0.507-0.292-0.676z" />
                    </svg> */}
                            <span>File Details</span>
                        </div>
                    </header>
                    <div className="section-body">
                        <div className="proof-details-content">
                            <div className="proof-thumbnail">
                                <span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        id="Layer_1"
                                        x={0}
                                        y={0}
                                        version="1.1"
                                        viewBox="0 0 66 79"
                                        xmlSpace="preserve">
                                        <path
                                            fill="var(--primary-text-color)"
                                            d="M64.4 19.3c0-.1-.1-.1-.1-.1L46.1.9s-.1-.1-.2-.1H16.6c-.3 0-.5.2-.5.5V8H9.3c-.3 0-.5.2-.5.5v6.8H2c-.3 0-.5.2-.5.5v62c0 .3.2.5.5.5h47.4c.3 0 .5-.2.5-.5V71h6.8c.3 0 .5-.2.5-.5v-6.8H64c.3 0 .5-.2.5-.5V19.4s0-.1-.1-.1zM46.2 2.4L62.8 19H46.2V2.4zm2.7 74.9H2.5v-61h6.3v54.2c0 .3.2.5.5.5h39.6v6.3zm7.3-7.3H9.8V9h6.3v54.2c0 .3.2.5.5.5h39.6V70zm-39.1-7.3v-61h28.2v17.8c0 .3.2.5.5.5h17.8v42.8H17.1z"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <div className="proof-details">
                                <div className="proof-info-col proof-info-col-1">
                                    <div className="proof-info-col-cell">
                                        <label className="proof-details-row--label">
                                            File version name
                                        </label>
                                        <div className="proof-details-row--value">
                                            {proofVersionSummary.proofVersion.name}
                                        </div>
                                    </div>
                                    <div className="proof-info-col-cell">
                                        <label className="proof-details-row--label">Folder</label>
                                        <div className="proof-details-row--value">
                                            {proofVersionSummary.proofVersion.directoryPath}
                                        </div>
                                    </div>
                                    <div className="proof-info-col-cell">
                                        <label className="proof-details-row--label">Owner</label>
                                        <div className="proof-details-row--value">
                                            {proofVersionSummary.proofVersion.createdBy}
                                        </div>
                                    </div>
                                    <div className="proof-info-col-cell">
                                        <label className="proof-details-row--label">Created</label>
                                        <div className="proof-details-row--value">
                                            {new Date(proofVersionSummary.proofVersion.createdAt).toLocaleString()}
                                        </div>
                                    </div>
                                    <div className="proof-info-col-cell">
                                        <label className="proof-details-row--label">State</label>
                                        <div className="proof-details-row--value">Active</div>
                                    </div>
                                </div>
                                <div className="proof-info-col proof-info-col-2">
                                    <div className="proof-info-col-cell">
                                        <label className="proof-details-row--label">Comments</label>
                                        <div className="proof-details-row--value">
                                            {proofVersionSummary.annotationCount}
                                        </div>
                                    </div>

                                </div>
                                <div className="proof-info-col proof-info-col-3" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
