import React, { createContext, useState } from 'react';
import { alertActions, groupService } from '../../..';
import { store } from '../../../../_helpers';
import {
    cannotDeleteBody,
    MIN_VISIBLE_BODY,
    cannotDeleteTitle,
    MIN_STATUS_BODY,
    MIN_STATUS_COUNT,
    MIN_STATUS_TITLE,
    STATUS_EXISTS_BODY,
    STATUS_EXISTS_TITLE,
    StatusType,
    initialValidationState,
    statusExistsText,
    Cannot_Delete_Workflow_Body,
    Cannot_Delete_Workflow_Title,
    MIN_WORKFLOW_COUNT
} from '../helpers/statusConstants';
import { statusUtil } from '../helpers/statusUtil';
import { workflowStatusService } from '../services/workflowStatus.service';
import { commentStatusService } from '../services/commentStatus.service';
import { workflowService } from '../services/workflow.service';

const WorkflowManageContext = createContext();

function WorkflowManageProvider({ children }) {
    const [editingWorkflow, setEditingWorkflow] = useState(undefined);
    const [visibleCommentStatuses, setVisibleCommentStatuses] = useState([]);
    const [hiddenCommentStatuses, setHiddenCommentStatuses] = useState([]);
    const [maxStatusLimit, setMaxStatusLimit] = useState();
    const [workflowStatuses, setWorkflowStatuses] = useState([]);
    const [title, setTitle] = useState("this is from provider")
    const [infoModalData, setInfoModalData] = useState({
        show: false,
        title: 'Info modal',
        body: 'Info modal body',
        secondaryButton: ''
    });

    const [validations, setValidations] = useState(initialValidationState);
    const commentStatuses = [...visibleCommentStatuses, ...hiddenCommentStatuses];
    const [membersData, setMembersData] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState([]);

    
    

    const handleWorkflowUpdate = async () => {
        let allValidated = await validateFields(editingWorkflow.name);

        if (!allValidated) {
            exitEditMode();

            var modalData = { ...infoModalData };
            modalData.show = true;
            modalData.secondaryButton = '';
            modalData.title = STATUS_EXISTS_TITLE;
            modalData.body = STATUS_EXISTS_BODY;

            setInfoModalData(modalData);

            return;
        }

        store.dispatch(alertActions.loading(true));
        await workflowService.update(editingWorkflow);        
        

        var copy  = visibleCommentStatuses.map((element)=> {


            var elementCopy = {...element};

            if (element.id ==  editingWorkflow.id){
                elementCopy.name = editingWorkflow.name;
            }

            return elementCopy;
        });

        setVisibleCommentStatuses(copy);
        exitEditMode(editingWorkflow);
        store.dispatch(alertActions.loading(false));
    };


    const updateStatusPriority = async (updatedList) => {
        store.dispatch(alertActions.loading(true));
        await statusService.updateStatusPriority(updatedList);
        store.dispatch(alertActions.loading(false));
    };

    const fetchStatusData = async () => {        
        var { workflows, maxVisible } = await workflowService.getAll(true, false);
        var { statuses, maxVisible } = await workflowStatusService.getAllStatus(false);        
        await loadMembersData();
        setMaxStatusLimit(maxVisible);        
        setVisibleCommentStatuses(workflows);    
        setWorkflowStatuses(statuses);    
    };


    const updateWorkflow = (workflow) => {

        setVisibleCommentStatuses(current => {
            var currentWorkflows = current.map(w => {
                var copy = { ...w };
                if (copy.id === workflow.id) {
                    copy = workflow
                }
                return copy;
            });

            return currentWorkflows

        });
    }


    const  loadMembersData = async () => {
        var membersData = await groupService.getAllMembers();
        var rowsTemp = [...membersData];
        const rows = rowsTemp.map((item) => {
            var newItem = { ...item };
            return newItem;
        });
        
        var users = rows.filter((x) => x.memberType === 1);
        var groups = rows.filter((x) => x.memberType === 2);
        var groupedOptions = [];
        if (users && users.length > 0) {
            groupedOptions.push({
                label: 'Users',
                options: users
            });
        }
        if (groups && groups.length > 0) {
            groupedOptions.push({
                label: 'Groups',
                options: groups
            });
        }

        setMembersData(membersData);
        setFilteredMembers(groupedOptions);
        // await utilService.setStateAsync.bind(this)({
        //     originalCombinedMembers: membersData,
        //     originalMembers: groupedOptions,
        //     filteredMembers: groupedOptions
        // });
    }

    const copyStatusList = (statuses) => {
        let copied = statuses.map((status) => {
            var statusCopy = { ...status };

            if (statusCopy.id === editingWorkflow.id) {
                statusCopy.label = editingWorkflow.label;
            }

            return statusCopy;
        });

        return copied;
    };

    const exitEditMode = (status) => {
        setEditingWorkflow(undefined);
    };

    const validateFields = async (workflowName) => {
        var globalValidation = true;

        let validationCopy = initialValidationState;
        validationCopy.inputStatus.message = '';

        if (!workflowName || workflowName.length < 1) {
            validationCopy.inputStatus = {
                validated: false,
                message: 'Enter a valid workflow name'
            };
            globalValidation = false;
        } else {
            var hasAny =
                visibleCommentStatuses &&
                visibleCommentStatuses.length > 0 &&
                visibleCommentStatuses.some((x) => x.name == workflowName.trim());
            if (hasAny) {
                validationCopy.inputStatus = {
                    validated: false,
                    message: 'Workflow already exists !'
                };
                globalValidation = false;
            }
        }
        setValidations(validationCopy);
        return globalValidation;
    };

    const addStatus = async ( commentStatus) => {

        let allValidated = await validateFields(commentStatus);

        if (!allValidated) {
            return;
        }

        store.dispatch(alertActions.loading(true));

        var addedStatus = await workflowService.addWorkflow({            
            name: commentStatus
        });

        let visibleStatusesCopy = [...visibleCommentStatuses, addedStatus];
        setVisibleCommentStatuses(visibleStatusesCopy);

        store.dispatch(alertActions.success('Added !'));
        store.dispatch(alertActions.loading(false));

    }

    const handleDeleteWorkflow = async (commentStatus) => {
        store.dispatch(alertActions.loading(true));
        await workflowService.deleteWorkflow(commentStatus);

        var newItems = statusUtil.filterOutStatus(commentStatus, [...visibleCommentStatuses]);
        setVisibleCommentStatuses(newItems);

        store.dispatch(alertActions.success('Successfully deleted'));

        store.dispatch(alertActions.loading(false));
    };

    const validateCanDelete = async (workflow) => {        
            
        store.dispatch(alertActions.loading(true));        
        
        var totalStatuses = [...visibleCommentStatuses, ...hiddenCommentStatuses];

        
        if (totalStatuses.length === MIN_WORKFLOW_COUNT) {
            var modalData = { ...infoModalData };
            modalData.show = true;
            modalData.secondaryButton = '';
            modalData.title = MIN_STATUS_TITLE;
            modalData.body = MIN_STATUS_BODY;
            setInfoModalData(modalData);
            return;
        }


        //check is only one status is visible and this is the one visible
        //what will be the popup?

        if (visibleCommentStatuses.length === 1 && workflow.isVisible === true) {
            //this is the only visible status
            var modalData = { ...infoModalData };
            modalData.show = true;
            modalData.secondaryButton = '';
            modalData.title = cannotDeleteTitle('workflow');
            modalData.body = MIN_VISIBLE_BODY;
            setInfoModalData(modalData);
            store.dispatch(alertActions.loading(false));
            return;
        }

        var canDelete = await workflowService.canDelete(workflow);
               
        if (canDelete !== true) {               
            var modalData = { ...infoModalData };
            modalData.show = true;
            modalData.secondaryButton = '';
            modalData.title = Cannot_Delete_Workflow_Title;
            modalData.body = Cannot_Delete_Workflow_Body;
;
            setInfoModalData(modalData);
        }

        store.dispatch(alertActions.loading(false));

        return canDelete;
    }

    const updateStatus = async (statusUpdated) => {
        store.dispatch(alertActions.loading(true));
        await statusService.updateStatus(statusUpdated);
        store.dispatch(alertActions.loading(false));
    }

    const providerState = {
        editingWorkflow,
        setEditingWorkflow,
        visibleCommentStatuses,
        setVisibleCommentStatuses,
        hiddenCommentStatuses,
        setHiddenCommentStatuses,
        infoModalData,
        setInfoModalData,
        maxStatusLimit,
        setMaxStatusLimit,
        validations,
        setValidations,
        commentStatuses,
        validateFields,
        handleWorkflowUpdate,
        exitEditMode,
        fetchStatusData,
        updateStatusPriority,
        addStatus,
        handleDeleteWorkflow,
        validateCanDelete,
        updateStatus,
        title,
        workflowStatuses, setWorkflowStatuses,
        membersData,
        updateWorkflow,
        filteredMembers
    };

    return (
        <WorkflowManageContext.Provider value={providerState}>
            {children}
        </WorkflowManageContext.Provider>
    );
}

const withWorkflowManageConsumer = (Component) => {
    return function ContextualComponent(props) {
        return (
            <WorkflowManageContext.Consumer>
                {(consumer) => <Component {...consumer} {...props} />}
            </WorkflowManageContext.Consumer>
        );
    };
};

export { WorkflowManageProvider, withWorkflowManageConsumer };
