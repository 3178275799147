import { tenantConstants } from '../../components';

export function tenants(state = {}, action) {
  switch (action.type) {
    case tenantConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case tenantConstants.GETALL_SUCCESS:
      return {
        items: action.tenants
      };
    case tenantConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
    case tenantConstants.DELETE_REQUEST:
      // add 'deleting:true' property to tenant being deleted
      return {
        ...state,
        items: state.items.map(tenant =>
          tenant.id === action.id
            ? { ...tenant, deleting: true }
            : tenant
        )
      };
    case tenantConstants.DELETE_SUCCESS:
      // remove deleted tenant from state
      return {
        items: state.items.filter(tenant => tenant.id !== action.id)
      };
    case tenantConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to tenant 
      return {
        ...state,
        items: state.items.map(tenant => {
          if (tenant.id === action.id) {
            // make copy of tenant without 'deleting:true' property
            const { deleting, ...tenantCopy } = tenant;
            // return copy of tenant with 'deleteError:[error]' property
            return { ...tenantCopy, deleteError: action.error };
          }

          return tenant;
        })
      };
    default:
      return state
  }
}