import React from 'react';
import { useTheme } from '../../../App/Theme/ThemeContext';
import PlayMarker from '../PlayMarker';
import { AnnotationMarkers } from './AnnotationMarkers';
import FramePicker from './FramePicker';
import { useScreenSizes } from '../../../responsive/useScreenSizes';
import  cx  from 'classnames';

function VideoPlayerTimeline({
    showAnnotationMarkers,
    currentFrameNumber,
    frameCount,
    onProgressClick,
    handleOnRangeSliderChange,
    rangeSelectorValue,
    onThumbCLick,
    videoMetadata,
    isCommentAddMode,
    seekToTime
}) {
    const { theme } = useTheme();
    const { isMobile } = useScreenSizes();
    var timelineHeight = !showAnnotationMarkers ? { height: '62px' } : { height: '62px' };

    return (
        <div
            style={{ margin: 0, padding: '20px' }}
            className={cx("mrnda-video-editor-area-timeline-wrapper", { 'mrnda-video-editor-area-timeline-wrapper--mobile' : isMobile})}>
            <div style={{paddingRight: '24px', borderRadius: '4px' ,backgroundColor: theme === 'light' ? 'var(--tertiary-background-color)' : 'var(--primary-background-color)' }}>
                <div
                    style={{ margin: 0, ...timelineHeight }}
                    className="mrnda-video-editor-area-timeline">
                    <div
                        id="mrnda-video-editor-area-controls-seekbar"
                        style={
                            theme === 'light'
                                ? { backgroundColor: 'var(--tertiary-background-color)' }
                                : {}
                        }
                        onClick={onProgressClick}
                        className="mrnda-video-editor-area-controls-seekbar">
                        <div
                            style={{ width: `${(currentFrameNumber / frameCount) * 100}%` }}
                            className="mrnda-video-editor-area-controls-seekbar-progress">
                            
                        </div>
                    </div>

                    <div
                        className="mrnda-video-editor-area-controls-markers"
                        onClick={onProgressClick}>
                        {showAnnotationMarkers && (
                            <AnnotationMarkers
                                handleOnRangeSliderChange={handleOnRangeSliderChange}
                                rangeSelectorValue={rangeSelectorValue}
                            />
                        )}
                    </div>

                    {isCommentAddMode && (
                        <FramePicker
                            frameCount={frameCount}
                            onThumbCLick={onThumbCLick}
                            handleOnRangeSliderChange={handleOnRangeSliderChange}
                            rangeSelectorValue={rangeSelectorValue}
                            frameRate={videoMetadata.frameRate}                            
                        />
                    )}

                   
                    {/* <div
                    className="mrnda-video-editor-area-controls-seekbar-tooltip"
                    id="video-player-tooltip"
                    style={{
                        left: toolTipLeftPosition,
                        display: displayToolTip ? 'block' : 'none'
                    }}>
                    {toolTipTimeInText}
                </div> */}
                </div>
            </div>
        </div>
    );
}

export default VideoPlayerTimeline;
