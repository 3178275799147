import React, { Component } from 'react';
import { useLiveUpdate } from "../App/LiveUpdate/LiveUpdateContext";

const withLiveUpdateHook = (Component) => {
    return function WrappedComponent(props) {
      const liveUpdateHook = useLiveUpdate();      
      return <Component {...props} liveUpdateHook={liveUpdateHook} />;
    }
}

export default withLiveUpdateHook