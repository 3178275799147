import React, { useState, useEffect, useRef, useCallback } from 'react';
import useStateRef from 'react-usestateref';
import { DrawingCanvas, ShapeAction, drawingConstants } from '@wunderman-thompson/miranda-drawing';
import { DocumentAnnotationContext } from './DocumentAnnotationContext';
import { fabric } from 'fabric';
import { documentCommentService } from '../documentComment.service';
import { authorizationService, utilService, contributorsHelper } from '../../../../_helpers';
import { authorizationConstants } from '../../../_shared';
import { useLiveUpdate } from '../../../App/LiveUpdate/LiveUpdateContext';
import { convertZoomToScale, calculateNewZoom, bringToView, bringToViewSmooth, bringToViewSmoothPoint } from '../DocumentAnnotationUtil';
import { uuidv4 } from '../DocumentAnnotationUtil';
import { modifyAnnotationColor, getPreferredColor, additionalProps } from '../../drawingHelper'
import { calculateDocumentAnnotationCenterPoint } from '../../../../_helpers/annotationArrowHelper';
import { compressImage, IMAGE_COMPRESSION_OPTION_TYPE } from '../../../../_helpers/file-helper';
import debounce from 'lodash.debounce';
import { useKeyPressed } from '../../../../_helpers/hooks/useKeyboardShortcut';
import { commentHelper } from '../../../../_helpers/commentHelper';
import { TEXT_EXTRACT_STATUS, PROOF_TYPE, EditorType } from '../../proof.constants';
import { tenantService } from '../../..';
import { useScreenSizes } from '../../../responsive/useScreenSizes';
import { useSelectionHighlightEffect } from '../../useSelectionHighlightEffect';


const documentMetadataInit = {
    proofId: '',
    proofVersionId: '',
    currentUser: {},
    currentTenant: {},
    s3Config: {},
    poster: '',
    fileURL: null,
    mimeType: 'video/mp4',
    frameRate: 0,
    frameCount: 0,
    durationInSeconds: 0,
    durationInTimeCode: '00:00.00',
    durationInText: '00:00:00',
    documentWidth: 0,
    documentHeight: 0,
    documentTagWidth: 0,
    documentTagHeight: 0
};

const DRAW_ARROW_DELAY = 300;
const PAGE_LOAD_THRESHOLD = 1;
const NEW_PAGES_LOAD_DELAY = 2000;

function DocumentAnnotationProvider({ children }) {
    const [proofVersion, setProofVersion] = useState({});
    const [mainDrawingCanvas, setMainDrawingCanvas] = useState([]);
    const [pagesContainer, setPagesContainer] = useState([]);
    const [canvasContainers, setCanvasContainers] = useState([]);
    const [selectedComment, setSelectedComment, selectedCommentRef] = useStateRef(-1);
    const [documentContainer, setDocumentContainer] = useState(null);
    const [editorArea, setEditorArea] = useState(null);
    const [containerZoom, setContainerZoom] = useState(undefined);
    const [documentMetadata, setDocumentMetadata] = useState(documentMetadataInit);
    const [annotationComments, setAnnotationComments] = useState([]);
    const [selectedAnnotation, setSelectedAnnotation, selectedAnnotationRef] = useStateRef([]);
    const [currentFrameNumber, setCurrentFrameNumber] = useState(0);
    const [isCommentAddMode, setIsCommentAddMode] = useState(false);
    const [canvasTextEditEntered, setCanvasTextEditEntered] = useState(false);
    const [selectedCanvas, setSelectedCanvas] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [loadedPageCount, setLoadedPageCount] = useState(0);
    const [hasNewComment, setHasNewComment] = useState(false);
    const [newCommentCount, setNewCommentCount] = useState(0);
    const [commentsDeleted, setCommentsDeleted] = useState(false);
    const [deletedCommentCount, setDeletedCommentCount] = useState(0);
    const [initDrawing, setInitDrawing] = useState(false);
    const [selectedTool, setSelectedTool] = useState('');
    const [currentUuid, setCurrentUuid] = useState('');
    const [lastZoom, setLastZoom] = useState(0);
    const [shouldTranslate, setShouldTranslate] = useState(false);
    const [shouldTranslateHorizontal, setShouldTranslateHorizontal] = useState(false);

    const [textInputValue, setTextInputValue] = useState(undefined);
    const [proofVersionLoaded, setProofVersionLoaded] = useState(false);
    const [userAnnotationColor, setUserAnnotationColor] = useState('#ff0000');
    const [userId, setUserId] = useState('');
    const [userFullName, setUserFullName] = useState(null);
    const [contributors, setContributors, contributorsRef] = useStateRef(null);
    const [arrowPoint2, setArrowPoint2] = useState([{ x: 0, y: 0 }]);
    const [arrowStartPoints, setArrowStartPoints] = useState([]);
    const [disableArrow, setDisableArrow] = useState(false);
    const [arrowColor, setArrowColor] = useState();
    const [isProofCompare, setIsProofCompare] = useState(false);

    const [currentAnnotationOnCanvas, setCurrentAnnotationOnCanvas, currentAnnotationOnCanvasRef] = useStateRef(undefined);
    const [showShapeEditingTools, setShowShapeEditingTools] = useState(false);

    const [commentText, setCommentText] = useState('');
    const [editAllowedComments, setEditAllowedComments] = useState([]);
    const [editAllowedReplies, setEditAllowedReplies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isComposite, setIsComposite, isCompositeRef] = useStateRef(false);
    const [isCommentMerge, setIsCommentMerge] = useState(false);
    const [loadablePages, setLoadablePages] = useState([]);    
    const [isFullyLoaded, setIsFullyLoaded, isFullyLoadedRef] = useStateRef(false);
    const [leftToLoad, setLeftToLoad] = useState();
    const [proofSettings, setProofSettings] = useState({});
    const [visiblePages, setVisiblePages] = useState([]);
    const [editingCommentId, setEditingCommentId] = useState('');
    const {
        connectionStatus,
        changeTarget,
        setEditorCommentListeners,
        setEditorAttachmentListeners,
        setCommentDeleteListener,
        setCommentReplyListener,
        setCommentStatusListener,
        setCommentAnnotationUpdateListener,
        setCommentUpdateListener,
        setTextExtractListeners,
        setCommentStatusListeners
    } = useLiveUpdate();

    const donotHanldeLiveUpdate = useRef(false);
    const annotationCommentsRef = useRef([]);
    const mainDrawingCanvasRef = useRef([]);
    const proofVersionRef = useRef([]);
    const deletedComments = useRef([]);
    const newComments = useRef([]);
    const iFrameRef = useRef();

    const commentArrowPointFindersRef = useRef(new Map());
    const [pages, setPages] = useState([]);
    const [textPages, setTextPages] = useState([]);
    const [isPanningSelected, setIsPanningSelected] = useState(false);
    const [isInteractiveSelected, setIsInteractiveSelected] = useState(true);
    const [extractedText, setExtractedText, extractedTextRef] = useStateRef({uuiid: '', pageNumber: 0, status: ''});
    const [shapeIndicatorStyle, setShapeIndicatorStyle] = useState({ left: 100, top: 100, height: 100,  width: 100 });
    const [commentStatuses, setCommentStatues] = useState([]); 
    const [viewportData, setViewportData] = useState({});
    const [htmlLiveProofViewerInfo, setHtmlLiveProofViewerInfo] = useState({});
    const [bestZoom, setBestZoom] = useState();
    const [selectedToolbarAction, setSelectedToolbarAction] = useState('None');
    const [editorType, setEditorType] = useState(EditorType.Scrollable);
    const [canvasLimitExceeded, setCanvasLimitExceeded] = useState(false);
    const [hasExtraTopBar, setHasExtraTopBar] = useState(true);
    const [screenshotRequestProcessing, setScreenshotRequestProcessing, screenshotRequestProcessingRef] = useStateRef(false);
    const { isMobile } = useScreenSizes();
    useSelectionHighlightEffect(isMobile, disableArrow, mainDrawingCanvas[selectedCanvas], annotationComments[selectedComment]);

    useEffect(() => {
        //TODO : move it to element style
        if (containerZoom !== undefined && documentContainer.current !== null && documentContainer.current !== undefined) {                        
            setDisableArrow(true);
            var scale = convertZoomToScale(containerZoom);
            documentContainer.current.style.transform = `matrix(${scale}, 0, 0, ${scale}, 0, 0)`;
            //documentContainer.current.style.transformOrigin = `center center`;
            setShouldTranslate(true);
            setShouldTranslateHorizontal(true);
            redrawArrow(containerZoom);                  
        }
    }, [containerZoom]);
 
    const redrawArrow = (containerZoom) => {
        if (selectedComment === -1) { return; }

        setTimeout(() => {
            redrawAnnotationCommentArrow(containerZoom);

        }, DRAW_ARROW_DELAY);
    }
    const donotRequestNew = useRef(false);


    useEffect(() => {
        if (loadedPageCount === 0 || pageCount === 0) {
            return;
        }

        let isLastCanvas = loadedPageCount === pageCount;

        if (isLastCanvas || editorType === EditorType.Paged) {
            documentLoaded();
        }        
    }, [loadedPageCount]);


    useEffect(() => {
        return () => {
            //NOTE : We are using ref to clean up debounced method as it is not being cleaned 
            //       directly from bellow           
            donotRequestNew.current = true;
        };
      }, []);

    useEffect(() => {   
        if (loadablePages.length === 0 || !pages || pages.length === 0 ) {
            return;
        }
        
        var isPendingLoadCompleted = !loadablePages.some(x => x.isLoaded === false);
        
        if (isPendingLoadCompleted === false) {  return; }
        

        if (isFullyLoaded === true) 
        { 
            return; 
        }
                
        requestNewLoadingDebounced(pages);
    
    },[loadablePages])
        
    const requestNewLoading = (pages)=> {
        
        if (donotRequestNew.current === true) {            
            requestNewLoadingDebounced.cancel();
            return ;
        }
        var left  = [];

        pages.forEach((page)=> {


            let isLoaded = loadablePages.some(p => p.page === page.pageNumber);
            if (!isLoaded) {

                left.push({  page : page.pageNumber, isLoaded : false});
            }

        });
        


        if (left.length ===0) {
            setIsFullyLoaded(true);  
        }

                
        let numberOfPagesToAdd = (PAGE_LOAD_THRESHOLD * 2) + 1;
        let reqLoadable = left.slice(0, numberOfPagesToAdd);
        
        setLoadablePages(current  => findUniqueLoadablePages(current, reqLoadable) ) 
    }
    
    const requestNewLoadingDebounced = useCallback(debounce(requestNewLoading, NEW_PAGES_LOAD_DELAY), [loadablePages, setLoadablePages]);
    
    useEffect(() => {
        if (selectedComment !== -1 && mainDrawingCanvas.length > 0) {

            selectAnnotationOnCanvas(true);
            calculateCommentArrowPoint(selectedComment);
            var comment = annotationComments[selectedComment];

            var preferredColor = contributorsHelper.findCommentColor(comment.annotations[0]);
            setDisableArrow(false);
            setArrowColor(preferredColor);
        }
        else if (selectedComment === -1 && mainDrawingCanvas[selectedCanvas]) {
            
            clearCommentSelection(selectedCanvas);
            onSelectedAnnotationCleared();
        }

    }, [selectedComment]);

    useEffect(() => {
        annotationCommentsRef.current = annotationComments;
    }, [annotationComments]);

    useEffect(() => {
        proofVersionRef.current = proofVersion;
    }, [proofVersion]);

    useEffect(() => {
        mainDrawingCanvasRef.current = mainDrawingCanvas;
    }, [mainDrawingCanvas]);

    useEffect(() => {
        if (initDrawing === true) {            
            setDisableArrow(true); 
            
            if (utilService.isWebsiteProof(proofVersion)) {                            
                requestScreenshot();
            }                   
        }
    }, [initDrawing]);
    
    //TODO : move them to helper
    const exportViewPort = async (element, uuid) => {
        requestScreenshot();
    }

    const requestScreenshot = () => {
        setScreenshotRequestProcessing(true);
		window.postMessage({ type: "mirandaHtmlLiveViewerSnapshot" }, "*");
        iFrameRef.current.contentWindow.postMessage({ type: "mirandaHtmlLiveViewerInfoRequest" }, "*");        
	};
    // End

    const initAnnotationsOfCanvas = (canvas, index) => {
        
        let isUserPermittedToAdd = authorizationService.isUserPermitted(
            authorizationConstants.ProofEditor.KEY,
            authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_COMMENT_ADD
        );
    
        var annotations = findAllAnnotationsForCanvas(index);
        drawAllShapes(canvas, annotations, isUserPermittedToAdd);            
        selectAnnotationOnCanvas();
    }


    const updateMainDrawingCanvases = (canvas, index) => {
        var copy = [...mainDrawingCanvas];
        copy[index] = canvas;
        setMainDrawingCanvas(copy);                               
        initAnnotationsOfCanvas(canvas, index)        
    }
    
    const isShapeVisible =  (arrowPoint1) => {    
        var area = editorArea.current.getBoundingClientRect();
        //NOTE : We are changing y point this way to keep it inside target area
        var isInside = utilService.findPoint(area.left, area.top, area.right, area.bottom, arrowPoint1.x, arrowPoint1.y);
        return isInside;
    };

    const changeLiveUpdateTarget = (proofId, proofVersionId, tenantId) => {
        const targets = [
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentAdded`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentDeleted`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentReply`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentReplyDeleted`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentReplyUpdated`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentAttachmentDeleted`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentStatus`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentAnnotationUpdate`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/TranscriptionCompleted`
            },
            {
                key: 'Editor'
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/CommentUpdate`
            },
            {
                key: 'Editor',
                value: `${proofId}/${proofVersionId}/TextExtractCompleted`
            },
            {
                key: 'Editor',
                value: `${tenantId}/CommentStatusListUpdated`
            },
            {
                key: 'Editor',
                value: `${tenantId}/WorkflowUpdated`
            },
        ];
        changeTarget(targets);
        setEditorCommentListeners(handleNewCommentAdded, handleCommentReplyDeleted, handleCommentReplyUpdated, targets);
        setEditorAttachmentListeners(handleCommentAttachmentDeleted);
        setCommentDeleteListener(handleCommentDeleted, targets);
        setCommentReplyListener(handleCommentReply, targets);
        setCommentStatusListener(handleCommentStatusChange, targets);
        setCommentAnnotationUpdateListener(handleCommentAnnotationUpdate, targets);
        setCommentUpdateListener(handleCommentUpdate, targets);
        setTextExtractListeners(handleTextExtractLiveUpdate, targets);
        setCommentStatusListeners(handleCommentStatusListUpdate, targets);            
    };

    const handleCommentStatusListUpdate = async (data) => {
        var { statuses } = await tenantService.getAllCommentStatus(false);            
        setCommentStatues(statuses);     
        
        var payLoad = {
            proofId: proofVersionRef.current.proofId,
            proofVersionId: proofVersionRef.current.id
        };
        var commentsResponse = await documentCommentService.getComments(payLoad);
        setAnnotationComments(commentsResponse);
    }


    

    const handleTextExtractLiveUpdate = (data) => {
        setPages((pages) => {
            var updatedPages = pages.map((page) => {
                var updatedPage = { ...page };
                updatedPage.textExtractStatus = TEXT_EXTRACT_STATUS.READY;
                return updatedPage;
            });
            return updatedPages;
        });
        setProofVersion((prev) => {
            return {
                ...prev,
                status: {
                    ...prev.status,
                    textExtractStatus: TEXT_EXTRACT_STATUS.READY
                }
            };
        });
    }

    const handleCommentAnnotationUpdate = (data) => {
        console.log(selectedAnnotation);
        var { commentId, annotations, pageNumber } = data;

        let editedAnnotationComment;
        var modified = annotationCommentsRef.current.map((comment) => {
            let modifiedComment = { ...comment };
            if (modifiedComment.id == commentId) {
                modifiedComment.annotations = annotations;
                editedAnnotationComment = modifiedComment;
            }

            return modifiedComment;
        });

        setAnnotationComments(modified);

        annotations.forEach((annotation) => {
            let targetPage = annotation.page === -1 ? pageNumber : annotation.page;
            mainDrawingCanvasRef.current[(pageToIndex(targetPage))].removeObjectById(annotation.id);
            var drawableAnnotation = { parent: editedAnnotationComment.id, createdById: editedAnnotationComment.createdById, ...annotation };
            drawSingleAnnotation(targetPage, drawableAnnotation, false, false);
        });
    };

    const handleCommentUpdate = (data) => {
        console.log(selectedAnnotation);
        var { commentId, updatedComment } = data;
        let editedAnnotationComment;
        var modified = annotationCommentsRef.current.map((comment) => {
            if (comment.id === commentId) {
                comment = updatedComment;
            }
            return comment;
        });
        setAnnotationComments(modified);
    };

    const getIsSelectable = () => {
        let isUserPermittedToAdd = authorizationService.isUserPermitted(
            authorizationConstants.ProofEditor.KEY,
            authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_COMMENT_ADD
        );

        let isSelectable = proofVersion.isLocked || !isUserPermittedToAdd ? false : true;
        return isSelectable;
    };
    const handleCommentStatusChange = (data) => {
        var { commentId, status } = data;
        var modified = annotationCommentsRef.current.map((comment) => {
            let modifiedComment = { ...comment };
            if (modifiedComment.id == commentId) {
                modifiedComment.status = status;
            }
            return modifiedComment;
        });

        setAnnotationComments(modified);
    };

    const handleCommentReply = (data) => {
        var { commentId, reply } = data;
        var modified = annotationCommentsRef.current.map((comment) => {
            let modifiedComment = { ...comment };
            if (modifiedComment.id == commentId) {
                modifiedComment.replies.push(reply);
            }
            return modifiedComment;
        });
        setAnnotationComments(modified);
    };

    const handleCommentReplyDeleted = (data) => {
        var { commentId, reply } = data;
        var modified = annotationCommentsRef.current.map((comment) => {
            let modifiedComment = { ...comment };
            if (modifiedComment.id == commentId) {
                // modifiedComment.replies.push(reply);
                // fond the target comment
                // find the target reply
                var index = modifiedComment.replies.findIndex((x) => x.id === reply.id);
                modifiedComment.replies.splice(index, 1);
            }
            return modifiedComment;
        });
        setAnnotationComments(modified);
    };
    const handleCommentReplyUpdated = (data) => {
        var { commentId, updatedReply } = data;
        var modified = annotationCommentsRef.current.map((comment) => {
            let modifiedComment = { ...comment };
            if (modifiedComment.id == commentId) {
                var index = modifiedComment.replies.findIndex((x) => x.id === updatedReply.id);
                modifiedComment.replies[index] = updatedReply;
            }
            return modifiedComment;
        });
        setAnnotationComments(modified);
    };

    const handleCommentAttachmentDeleted = (data) => {
        var { commentId, attachment } = data;

        var isReply = attachment.replyId !== null ? true : false;

        var modified = annotationCommentsRef.current.map((comment) => {
            let modifiedComment = { ...comment };
            if (modifiedComment.id == commentId) {

                if (isReply) {

                    let reply = modifiedComment.replies.find((x) => x.id === attachment.replyId);
                    let index = reply.attachments?.findIndex((x) => x.id === attachment.id);
                    reply.attachments.splice(index, 1);

                } else {
                    let index = modifiedComment.attachments?.findIndex((x) => x.id === attachment.id);
                    modifiedComment.attachments.splice(index, 1);
                }

            }
            return modifiedComment;
        });
        setAnnotationComments(modified);
    };



    const handleCommentDeleted = (data) => {
        if (donotHanldeLiveUpdate.current) {
            return;
        }

        setCommentsDeleted(true);
        deletedComments.current.push(data);
        setDeletedCommentCount(deletedComments.current.length);
    };

    const handleNewCommentAdded = (data) => {
        if (donotHanldeLiveUpdate.current) {
            return;
        }

        setHasNewComment(true);
        newComments.current.push(data);
        setNewCommentCount(newComments.current.length);
    };

    const newCommentBannerClicked = (reason) => {
        setHasNewComment(false);
        const updated = annotationComments.concat(newComments.current);
        setAnnotationComments(updated);

        //draw new annotation


        newComments.current.forEach((comment) => {
            comment.annotations.forEach((annn) => {
                var drawableAnnotation = { parent: comment.id, createdById: comment.createdById, ...annn };
                drawSingleAnnotation(annn.page, drawableAnnotation, false, false);
            })
        });

        newComments.current = [];

        var props = providerState;
        contributorsHelper.configureContributors(proofVersion, updated, contributors, userId, true, props);
    };

    const pageToIndex = (page) => {
        return page - 1;
    }

    const deletedCommentsBannerClicked = () => {
        setCommentsDeleted(false);



        deletedComments.current.forEach((deleted) => {
            var dComment = annotationCommentsRef.current.find(x => x.id === deleted.commentId);
            dComment.annotations.forEach(element => {
                mainDrawingCanvasRef.current[pageToIndex(element.page)].removeObjectById(element.id);
            });

        });

        var modified = annotationComments.map((comment) => {
            return comment;
        });

        deletedComments.current.forEach((deleted) => {
            var index = modified.findIndex((x) => x.id === deleted.commentId);
            modified.splice(index, 1);
        });

        setAnnotationComments(modified);

        deletedComments.current.forEach((deleted) => {

            var id = deleted.annotationUuid;
            var canvas = mainDrawingCanvasRef.current[pageToIndex(deleted.pageNumber)];
            canvas.removeObjectById(id);

        });
        deletedComments.current = [];
        var props = providerState;
        contributorsHelper.configureContributors(proofVersion, modified, contributors, userId, false, props);
    };

    const toggleDrawingMode = (toggle, index) => {
        mainDrawingCanvas[index].toggleDrawingMode(toggle);
        toggle && mainDrawingCanvas[index].initFreeHand(userAnnotationColor);
    };

    const removeCanvasObjectById = (id, page) => {
        mainDrawingCanvas[pageToIndex(page)].removeObjectById(id);
    };

    const canvasLoaded = () => {
        var totalLoaded = loadedPageCount + 1;
        setLoadedPageCount(totalLoaded);
    };

    const calcScale = () => {        
        let currentElement = canvasContainers[selectedCanvas];   
        const containerWidth = editorArea.current.offsetWidth;
        const containerHeight = editorArea.current.offsetHeight;
        let imageWidth = currentElement.offsetWidth;
        let imageHeight = currentElement.offsetHeight;
        
        if (imageWidth === 0 && imageHeight === 0) {
            const page  = pages[selectedCanvas];
            imageWidth = page.width;
            imageHeight = page.height;
        }

        const scaleX = containerWidth / imageWidth;
        const scaleY = containerHeight / imageHeight;
    
        return Math.min(scaleX, scaleY);
    };

    const fitToSpace = (target) => {        
        console.log('Page container fitted on screen');
        var parent = editorArea.current;
        var currentElement = canvasContainers[selectedCanvas];

        
        var zoom = undefined;
        if (currentElement.offsetHeight > currentElement.offsetWidth) {
            zoom = heightFit(parent, currentElement)
        }{
            zoom = widthFit(parent, currentElement)
        }

        if (zoom != undefined) {
            setContainerZoom(zoom);
            setBestZoom(zoom);
        }
    };

    const fitToSpaceV2 = () => {                                
        let zoom  = calcScale() * 100;
        console.log('New zoom is :', zoom); 
        if (zoom != undefined) {
            setContainerZoom(zoom);
            setBestZoom(zoom);
        }
    };

    const heightFit = (parent, currentElement) => {
        var available = parent.offsetHeight - 40;
        var current = currentElement.offsetHeight;
        var zoom = calculateNewZoom(available, current, containerZoom);
        return zoom;
    }

    const widthFit = (parent, currentElement) => {
        var available = parent.offsetWidth - 40;
        var current = currentElement.offsetWidth;

        var zoom = calculateNewZoom(available, current, containerZoom);
        return zoom;
    }

    const initAnnotations = () => {
        // if (currentFrameNumber !== targetFrame) {
        let isUserPermittedToAdd = authorizationService.isUserPermitted(
            authorizationConstants.ProofEditor.KEY,
            authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_COMMENT_ADD
        );

        getIsSelectable
        mainDrawingCanvas.forEach((element, index) => {
            var selectedAnnotations = annotationComments
                .filter((item) => item.page === index + 1)
                .map((item) => {
                    return { ...item };
                });

            var anns = findAllAnnotationsForCanvas(index);
            drawAllShapes(mainDrawingCanvas[index], anns, isUserPermittedToAdd);
        });
        selectAnnotationOnCanvas();
    };



    const findAllAnnotationsForCanvas = (index) => {
        var annotations = [];

        annotationComments.forEach((ac) => {
            ac.annotations.forEach((shape) => {
                if (shape.page - 1 === index) {
                    annotations.push({ parent: ac.id, createdById: ac.createdById, ...shape });
                }
            });
        });
        
        return annotations;
    }
    const selectAnnotationOnCanvas = (isSelectionChange = false) => {
        if (annotationComments.length === 0 || selectedComment === -1) {
            return;
        }
        
        if (utilService.isWebsiteProof(proofVersion)) {                
            
            let comment = annotationCommentsRef.current[selectedComment];
            mainDrawingCanvas[pageToIndex(comment.annotations[0].page)].clear()
            comment.annotations.forEach((annn) => {
                var drawableAnnotation = { parent: comment.id, createdById: comment.createdById, ...annn };
                drawSingleAnnotation(annn.page, drawableAnnotation, false, false);
            })
        }
        
        var selectedCommentObj = annotationComments[selectedComment];
        var annotationIds = selectedCommentObj.annotations;

        var isSelectable = getIsSelectable() 
       
        if (isSelectable) {
            var canvas = mainDrawingCanvas[pageToIndex(annotationIds[0].page)];
            canvas !== undefined && canvas.selectObjectById(annotationIds[0].id);
        }

        calculateArrowPoints(annotationIds, isSelectionChange);

    }

    const updateObjectArrowPoint = (object, canvasContainer) => {
        //REFEC
        var point = calculateDocumentAnnotationCenterPoint(object, containerZoom, canvasContainer, isProofCompare, hasExtraTopBar);
        var index = arrowStartPoints.findIndex(x => x.uuid === object.uuid);

        if (index === -1) {
            setArrowStartPoints([...arrowStartPoints, point]);
        }
        else {
            var points = [...arrowStartPoints];
            points[index] = point;
            setArrowStartPoints(points);
        }
    }



    const calculateBestZoom = () => {
        var maxElement = {
            width: 0
        };

        if(utilService.isWebsiteProof(proofVersion) || editorType === EditorType.Paged){
            fitToSpaceV2();
            return;
        }

        if (pageCount == 1) {

            //For single page document we don't need to 
            //Calculate zoom, we will fit the document 
            //into available space
            fitToSpace(selectedCanvas);
            return;
        }


        //var dd = mainDrawingCanvas;


        pages.forEach((element) => {
            if (element.width > maxElement.width) {
                maxElement = { offsetWidth: element.width };
            }
        });
        
        var parent = editorArea.current;
        var currentElement = maxElement;

        //Width fit
        var available = Math.abs(parent.offsetWidth * 0.6 - 100);
        var current = currentElement.offsetWidth;

        var zoom = calculateNewZoom(available, current, containerZoom);

        if (zoom != undefined) {
            setContainerZoom(zoom);
            setBestZoom(zoom);
        }


    };

    const refreshComments = async () => {
        var payLoad = {
            proofId: documentMetadata.proofId,
            proofVersionId: documentMetadata.proofVersionId
        };
        var documentCommentsResponse = await documentCommentService.getComments(payLoad);

        setAnnotationComments(documentCommentsResponse);
    };

    const peekToPage = (targetFrame, commentIndex = -1, selectCommentAfterScrolled = false) => {
        changeSelectedCanvas(targetFrame, false, commentIndex, selectCommentAfterScrolled);
    };

    const drawAllShapes = (drawingCanvas, selectedAnnotations, isUserPermittedToAdd, clearCanvas = true, removePrevious = false, color = null) => {

        if (utilService.isWebsiteProof(proofVersion)) {
            
            //For html live annotation we cannot draw all the annotations
            return;
        }

        if (clearCanvas) {
            drawingCanvas.clear();
        }

        let isSelectable = proofVersion.isLocked || !isUserPermittedToAdd ? false : true;
        

        selectedAnnotations.forEach((annotation) => {
            let annotationObject = JSON.parse(annotation.annotationObject);
            annotationObject.uuid = annotation.id;
            annotationObject.uuid_parent = annotation.parent;
            const shape = {
                type: annotation.shapeType,
                ...annotationObject,
                action: ShapeAction.ADD,
                selectable: isSelectable,
            };
            
            additionalProps(shape, annotation.createdById);
            modifyAnnotationColor(shape, annotation.createdById, contributorsRef.current, color, annotation.createdById === userId);

            if (removePrevious) {
                drawingCanvas.removeObjectById(annotation.id);
            }

            drawingCanvas.drawShape(shape);

        });
    }

    
    const redraw = (pageNumber, annotationComments, isSelectable, clearPage = true) => {
        if (clearPage) {
            mainDrawingCanvasRef.current[pageNumber].clear();
        }
        annotationComments.forEach((annotationComment) => {
            let annotationObject = JSON.parse(annotationComment.annotations[0].annotationObject);
            annotationObject.uuid = annotationComment.annotations[0].id;
            annotationObject.uuid_parent = annotationComment.id;
            const shape = {
                type: annotationComment.shape,
                ...annotationObject,
                action: ShapeAction.ADD,
                selectable: isSelectable
            };
            mainDrawingCanvasRef.current[pageNumber].drawShape(shape);
        });
    };

    const drawSingleAnnotation = (
        pageNumber,
        drawableAnnotation,
        clearPage = false,
        keepSelected = true,
    ) => {
        pageNumber = pageToIndex(pageNumber);
        if (clearPage) {
            mainDrawingCanvasRef.current[pageNumber].clear();
        }


        let isSelectable = getIsSelectable();

        let annotationObject = JSON.parse(drawableAnnotation.annotationObject);
        annotationObject.uuid = drawableAnnotation.id;
        annotationObject.uuid_parent = drawableAnnotation.parent;
        const shape = {
            type: drawableAnnotation.shapeType,
            ...annotationObject,
            action: ShapeAction.ADD,
            selectable: isSelectable 
                        //&& annotationObject.type != drawingConstants.ANNOTATION_TYPE.textHighlight.typeName
        };
        
        additionalProps(shape, drawableAnnotation.createdById);
        modifyAnnotationColor(shape, userId, contributorsRef.current);
        mainDrawingCanvasRef.current[pageNumber].drawShape(shape);
        if (keepSelected === true) {
            mainDrawingCanvasRef.current[pageNumber].selectObjectById(annotationObject.uuid);
        }
    };
    const onCommentAdd = (shapeInfo, canvas, index) => {
        var uuid = shapeInfo.uuid;
        const annotationType = shapeInfo.type;
        const canvasObject = {
            ...shapeInfo
        };
        canvasObject.onDrawCompleted = null;

        var annotation = {
            uuid,
            uuid_parent: shapeInfo.uuid_parent,

            annotationType: annotationType,
            annotationObject: canvasObject,
            operationMode: 0,
            page: index + 1
        };

        updateSelectedAnnotation(annotation);
        setIsCommentAddMode(true);
        return uuid;
    };

    const onAnnotationMerge = (shapeInfo, canvas, index) => {
        var uuid = shapeInfo.uuid;
        const annotationType = shapeInfo.type;
        const canvasObject = {
            ...shapeInfo
        };
        canvasObject.onDrawCompleted = null;

        var annotation = {
            uuid,
            uuid_parent: shapeInfo.uuid_parent,

            annotationType: annotationType,
            annotationObject: canvasObject,
            operationMode: 0,
            page: index + 1
        };

        updateSelectedAnnotation(annotation);
        setIsCommentMerge(true);
        return uuid;
    };


    const updateSelectedAnnotation = (annotation) => {
        var current = [...selectedAnnotation];

        var findIndex = current.findIndex(x => x.uuid === annotation.uuid);

        if (findIndex === -1) {
            setSelectedAnnotation([...current, annotation]);
        }
        else {
            current[findIndex] = annotation;
            setSelectedAnnotation([...current]);
        }
    };

    const createTempCanvases = (index) => {
        return new Promise((resolve, reject) => {
            var currentImage = canvasContainers[index].children[1];

            var tmpCanvas = document.createElement('canvas');
            tmpCanvas.setAttribute('id', '_temp_canvas');
            tmpCanvas.width = currentImage.width;
            tmpCanvas.height = currentImage.height;        
            
            var imgObj = new Image();
            imgObj.src = currentImage.dataset.mainsrc;
            imgObj.width = currentImage.width;
            imgObj.height = currentImage.height;
            imgObj.crossOrigin = 'anonymous'; 
            imgObj.onload = function () 
            {                
                var mockDrawingCanvas = new DrawingCanvas(tmpCanvas);
                var f_img = new fabric.Image(imgObj);
                mockDrawingCanvas.setBackgroundImage(f_img);                
                resolve({ tmpCanvas, mockDrawingCanvas })
            } 
        });
    };

    const releaseCommentSelection = () => {

        // We wont release the selection if composite mode
        if (isComposite === true) { return; }

        // Other wise just clear selection
        setSelectedComment(-1);
        setDisableArrow(true);

        if (utilService.isWebsiteProof(proofVersion)) {
            //Clear the canvas
            mainDrawingCanvas[0].clear();
        }

    }

    const saveCanvasWithAllDrawings = async (index, currentId, tmpCanvas, mockDrawingCanvas) => {
        if (tmpCanvas === undefined && mockDrawingCanvas === undefined) {            
            ({ tmpCanvas, mockDrawingCanvas } = await  createTempCanvases(index));
        }

        return new Promise((resolve, reject) => {
            function callBackAfterAll(blob) {
                var file = new File([blob], 'captureAll.png');
                let allAnnotationCapture = blob;
                console.log(file);
                resolve(allAnnotationCapture);
            }

            var selectedAnnotations = annotationComments
                .filter((item) => item.page === index + 1 && item.id !== currentId)
                .map((item) => {
                    return { ...item };
                });

            drawAllShapes(mockDrawingCanvas, selectedAnnotations, true, false);
            tmpCanvas.toBlob(callBackAfterAll, 'image/png', 1);
        });
    };

    


    const addLoadablePages = (pages) => {
        
        if(!pages || pages.length <=0) { return }
        
        var currentLoadable = [];

        let pre = [];
        let post = [];
        
        var start = pages[0].page;
        var end = pages[pages.length-1].page;

        for(let i = start-1 ; i >= start-PAGE_LOAD_THRESHOLD; i--  ){
            
            if (i < 1) {
                break;
            }
            
            var some = {
                page : i,
                isLoaded:false
            }
            
            pre.push(some);
        }


        for(let i = start + 1; i<=end + PAGE_LOAD_THRESHOLD; i++  ){
                        
            if (i > proofVersion.pages.length) {
                break;
            }

            var some = {
                page : i,
                isLoaded:false
            }

            post.push(some);
        }

        pages = [...pre, ...pages, ...post];
        setLoadablePages(current => findUniqueLoadablePages(current,pages));
    };


    const findUniqueLoadablePages = (current, all) => {
        var currentLoadable = [];
        all.forEach((page) => {

            var exists = current.some(x => x.page === page.page);
            if( exists === false ){
                currentLoadable.push(page);                
            }
        });
        
        return currentLoadable.concat(current)
    }

    const changeLoadablePages = (currentPage) => {
        
        if (isFullyLoaded === true){ return; }

        var currentLoadable = [];

        for (
            let i = currentPage - PAGE_LOAD_THRESHOLD;
            i <= currentPage + PAGE_LOAD_THRESHOLD;
            i++
        ) {
            if (i < 1 || i > pages.length ) {
                continue;
            }
            else if( loadablePages.some(x => x.page === i)){
                continue;
            }
            currentLoadable.push({ page : i, isLoaded : false });
        }
    
        setLoadablePages(current => findUniqueLoadablePages(current, currentLoadable));
        
    };

    const changeSelectedCanvas = (index, donotScroll = false, commentIndex = -1, selectCommentAfterScrolled = false) => {
        if (index === selectedCanvas) {
            return;
        }


        changeLoadablePages(index + 1);


        if (isCommentAddMode) {
            return;
        }



        // Clear selection from currently selected 
        // canvas before changing selection
        // mainDrawingCanvas[selectedCanvas].clearSelection();
        setSelectedCanvas(index);
        if (!donotScroll) {
            bringToView(canvasContainers[index], 20);

            //NOTE : As we clear selection while scrolling,
            //       Selection get cleared if scroll is triggered by 
            //       bringToView, so we select comment again after bringToView
            //       completes its scrolling
            if (selectCommentAfterScrolled) {
                setTimeout(() => {
                    setSelectedComment(commentIndex);
                }, 200);
            }
        }

        // var activeComment = annotationComments[commentIndex === -1 ? selectedComment : commentIndex];
        // if (pageToIndex(activeComment?.page) !== index && isComposite === false) {
        //     releaseCommentSelection();
        // }
    };

    const startDrawing = (shapeType, index, onShapeDrawComplete, isFreehand = false) => {
        
        if (!isFreehand) {
            //setIsCommentAddMode(false);
        } else {
            toggleDrawingMode(true, index);
        }

        let uuid = uuidv4();
        setCurrentUuid(uuid);
        let shape = {
            uuid: uuid,
            uuid_parent: uuidv4(),
            type: shapeType,
            action: ShapeAction.START,
            onDrawCompleted: onShapeDrawComplete
        };

        if (shapeType !== drawingConstants.ANNOTATION_TYPE.commentStamp.typeName) {
            shape = { ...shape, ...currentAnnotationOnCanvasRef.current }
        }
        
        additionalProps(shape, userId);
        modifyAnnotationColor(shape, userId, contributorsRef.current);

        mainDrawingCanvas[index].drawShape(shape);
    }

    const isPreDrawing = (shapeProps) => {
        return shapeProps.uuid === undefined;
    }
    const isPostDrawing = (shapeProps) => {
        var exists = annotationComments.some(x => x.id === shapeProps.uuid_parent)
        return !exists;
    }
    const updateCurrentAnnotationOnCanvas = (updates) => {

        if (isPreDrawing(currentAnnotationOnCanvas) === true) {

            var newProps = { ...currentAnnotationOnCanvas };

            var incoming = {};
            updates.forEach((update) => {
                incoming[update.prop] = update.value;
            })


            setCurrentAnnotationOnCanvas({ ...newProps, ...incoming });

            return;
        }
        
        updates.forEach((update)=> {            
            currentAnnotationOnCanvas.set(update.prop, update.value);
            setTextInputValue(update.value);
            
            if(currentAnnotationOnCanvas.type === drawingConstants.ANNOTATION_TYPE.strikeOut.typeName){                
                
                //Strike has  multiple objects grouped together 
                //So we need to update all the objects inside it.
                currentAnnotationOnCanvas._objects.forEach(element => {
                    element.set(update.prop, update.value);
                })
            }
        })

        //updateSelectedAnnotation(currentAnnotationOnCanvas);


        if (isPostDrawing(currentAnnotationOnCanvas) === true) {
            reRenderChanges();
            return;
        }

        var copied = [...annotationComments];
        copied[selectedComment].annotations[0].annotationObject = currentAnnotationOnCanvas;
        setAnnotationComments(copied);

        reRenderChanges();
    }
    const reRenderChanges = () => {
        var canvas = mainDrawingCanvas[selectedCanvas];
        // NOTE: After changing text object modified event doesn't  fire in
        //       fabric canvas. So we are triggering the event manually.  
        canvas.fabricCanvas.trigger('object:modified', { target: currentAnnotationOnCanvas });

        // NOTE: After changing the text fabric canvas doesn't show it 
        //       So we need to call render all manually.
        canvas.renderAll();
    }
    const redrawAnnotationCommentArrow = (zoom = undefined) => {
        if (selectedComment === -1) return;

        setDisableArrow(false);
        calculateArrowPoints(annotationComments[selectedComment].annotations)
    }

    const setCommentArrowPointFinders = (key, value) => {
        commentArrowPointFindersRef.current.set(key, value);
    }

    const deleteCommentArrowPointFinder = (key) => {
        commentArrowPointFindersRef.current.delete(key);
    }

    const calculateCommentArrowPoint = (commentIndex, fromScroll, bounds) => {
        var comment = annotationComments[commentIndex === undefined ? selectedComment : commentIndex];
        if (comment === undefined) { return; }
        var finder = commentArrowPointFindersRef.current.get(comment.id);

        if (finder) {
            finder(fromScroll, bounds);
        }
    }

    const clearCommentSelection = (canvasIndex) => {
        if (canvasIndex === -1 ) {
            return;
        }
        mainDrawingCanvas[canvasIndex].clearSelection();
        releaseCommentSelection();
    }

    const onSelectedAnnotationCleared = (shape) => {
        setShowShapeEditingTools(false);
        setCurrentAnnotationOnCanvas(undefined);
    }

    const onAnnotationSelectionChanged = (shape) => {

        var comment = annotationComments.find(x => x.id === shape.uuid_parent);

        if (comment === undefined || comment === null || comment?.createdById === userId) {
            setShowShapeEditingTools(true);
            setCurrentAnnotationOnCanvas(shape);
        }
        else {
            onSelectedAnnotationCleared();
        }
    }

    const clearShapeTools = () => {
        setCurrentAnnotationOnCanvas(undefined);
        setShowShapeEditingTools(false);
    }

    const  documentLoaded = () => {
        calculateBestZoom();
        setIsLoading(false);
        var initialLoadablePageNum = getInitialLoadablePageNum();
        changeLoadablePages(initialLoadablePageNum, 1);
    }

    const getInitialLoadablePageNum = () => {        
        return PAGE_LOAD_THRESHOLD + 1;
    }

    const calculateArrowPoints = (annotations, isSelectionChange = false) => {
        var points = [];
        let canvas = {};
        annotations.forEach(annotation => {
            canvas = mainDrawingCanvas[pageToIndex(annotation.page)];
            
            if (!canvas) { return };

            var object = canvas.getObjectById(annotation.id);
            var point = calculateDocumentAnnotationCenterPoint(object, containerZoom, canvasContainers[pageToIndex(annotation.page)], isProofCompare, hasExtraTopBar);
                    
            points = ([...points, point]);
        });

        setArrowStartPoints(points);

        if(isSelectionChange === false) {
            return;
        } 

        var anyShapeVisible = points.some(x => isShapeVisible(x));

        if (anyShapeVisible === false ) {
            var annotation =  annotations[0];
            canvas = mainDrawingCanvas[pageToIndex(annotation.page)];
            var object = canvas.getObjectById(annotation.id);
            updateSelectedShapeIndicator(object, points[0]);                      
        }

    }

    const updateSelectedShapeIndicator  = (object, point) => {
        
        
        let normalSpacing = 40;

        let objWidth = ((object.width ) * convertZoomToScale(containerZoom)) + normalSpacing;
        let objHeight = ((object.height ) * convertZoomToScale(containerZoom)) + normalSpacing;
    
        let leftPoint = (point.x - objWidth/2) + 'px';
        let topPoint = (point.y - (60 + objHeight/2)) + 'px';
        //setShapeIndicatorStyle({height : objHeight + 'px', width: objWidth + 'px', left : leftPoint, top: topPoint  });
        let shapePointer = document.getElementById('selected-canvas-object-indicator'); 
        shapePointer.style.left = leftPoint;
        shapePointer.style.top = topPoint;
        shapePointer.style.height = objHeight;
        shapePointer.style.width = objWidth;                
        bringToViewSmooth(shapePointer); 
    }

    const loadAndSetTextPages = (payload) => {
        var textPages = [];
        for (const page of payload) {
            var textContent = '';

            try {
                textContent = page?.textFile ? JSON.parse(page.textFile) : '';
            } catch (error) {
                textContent = '';
            }
            var newPage = {
                pageNumber: page.pageNumber,
                texts: textContent
            };
            textPages.push(newPage);
        }
        setTextPages(textPages);
    }

    const changeInitDrawing = async (value) => {
        
        if (value === true) {
            if (utilService.isWebsiteProof(proofVersion)) {
                if (containerZoom !== bestZoom) {
                    setContainerZoom(bestZoom);
                    await new Promise((resolve) => setTimeout(resolve, 400));                    
                }
            }
        }

        setInitDrawing(value);
    }

    const onDrawingToolClicked = (shapeObject) => {        
        setIsPanningSelected(false);
        setDisableArrow(true);
        setSelectedTool(shapeObject.typeName);
        setInitDrawing(true);
        setIsComposite(true);
        
        
        if (shapeObject.typeName === drawingConstants.ANNOTATION_TYPE.highlight.typeName) {
            //onHighlightPreDraw();
        } else {
            clearShapeTools();
        }

        startDrawing(shapeObject.typeName, selectedCanvas, onShapeDrawComplete, false);
    };
    
    const onShapeDrawComplete = (shape) => {            
        if (isCompositeRef.current === true && selectedComment !== -1) {
            //New drawing flow
            console.log('shape draw complete.', shape);

            if (shape && shape.action === ShapeAction.ADD) {
                onAnnotationMerge(shape, editorArea.current, selectedCanvas);
                toggleDrawingMode(false, 0);
            }
            setSelectedTool('');
            setInitDrawing(false);

        } else {
            console.log('shape draw complete.', shape);
            if (shape && shape.action === ShapeAction.ADD) {
                setIsCommentAddMode(true);
                onCommentAdd(shape, editorArea.current, selectedCanvas);
                toggleDrawingMode(false, 0);
            }
            setSelectedTool('');
            setInitDrawing(false);
        }
    };

    const providerState = {
        proofVersion,
        mainDrawingCanvas,
        documentMetadata,
        annotationComments,
        selectedAnnotation,
        selectedAnnotationRef,
        currentFrameNumber,
        isCommentAddMode,
        canvasTextEditEntered,
        selectedCanvas,
        containerZoom,
        editorArea,
        initDrawing,
        selectedTool,
        connectionStatus,
        hasNewComment,
        documentContainer,
        currentUuid,
        pagesContainer,
        textPages,
        setTextPages,

        setProofVersion,
        setMainDrawingCanvas,
        setDocumentMetadata,
        setAnnotationComments,
        setSelectedAnnotation,
        setCanvasTextEditEntered,
        setIsCommentAddMode,

        toggleDrawingMode,
        removeCanvasObjectById,
        refreshComments,
        peekToPage,
        onCommentAdd,
        newCommentBannerClicked,
        donotHanldeLiveUpdate,
        changeLiveUpdateTarget,
        newCommentCount,
        commentsDeleted,
        deletedCommentsBannerClicked,
        deletedCommentCount,
        setContainerZoom,
        setDocumentContainer,
        setEditorArea,
        canvasLoaded,
        setPageCount,
        changeSelectedCanvas,
        setInitDrawing,
        setSelectedTool,
        setCurrentUuid,
        startDrawing,
        setPagesContainer,
        fitToSpace,
        initAnnotations,
        drawSingleAnnotation,
        canvasContainers,
        setCanvasContainers,
        saveCanvasWithAllDrawings,
        selectedComment,
        setSelectedComment,
        setLastZoom,
        shouldTranslate,
        setShouldTranslate,
        shouldTranslateHorizontal,
        setShouldTranslateHorizontal,
        textInputValue,
        selectAnnotationOnCanvas,
        //onObjectSelectionChanged,

        proofVersionLoaded,
        setProofVersionLoaded,
        userAnnotationColor,
        setUserAnnotationColor,
        userId,
        setUserId,
        userFullName,
        setUserFullName,
        contributors,
        setContributors,
        arrowPoint2, setArrowPoint2,
        redrawAnnotationCommentArrow,
        disableArrow, setDisableArrow,

        calculateCommentArrowPoint,
        setCommentArrowPointFinders,
        deleteCommentArrowPointFinder,
        arrowColor, setArrowColor,
        pageCount,
        isProofCompare,
        setIsProofCompare,
        clearCommentSelection,
        selectedCommentRef,
        updateCurrentAnnotationOnCanvas,
        showShapeEditingTools,
        setShowShapeEditingTools,
        currentAnnotationOnCanvas,
        setCurrentAnnotationOnCanvas,
        onSelectedAnnotationCleared,
        onAnnotationSelectionChanged,
        commentText, setCommentText,
        editAllowedComments, setEditAllowedComments,
        editAllowedReplies, setEditAllowedReplies,
        isLoading, setIsLoading,
        arrowStartPoints,
        setArrowStartPoints,
        isComposite,
        setIsComposite,
        clearShapeTools,
        onAnnotationMerge,
        isCommentMerge,
        setIsCommentMerge,
        updateObjectArrowPoint,
        releaseCommentSelection,
        setLoadedPageCount,
        pages,
        setPages,
        loadablePages,
        setLoadablePages,
        updateMainDrawingCanvases,
        addLoadablePages,        
        isFullyLoaded, setIsFullyLoaded,
        isPanningSelected, setIsPanningSelected,

        loadAndSetTextPages,
        extractedText, setExtractedText, extractedTextRef,
        proofSettings, setProofSettings,
        visiblePages, setVisiblePages,
        isFullyLoadedRef,
        shapeIndicatorStyle,
        editingCommentId, setEditingCommentId,
        commentStatuses, setCommentStatues,
        isInteractiveSelected, setIsInteractiveSelected,
        iFrameRef,
        viewportData, setViewportData,
        htmlLiveProofViewerInfo, setHtmlLiveProofViewerInfo,
        changeInitDrawing,
        onDrawingToolClicked,
        selectedToolbarAction, setSelectedToolbarAction,
        editorType, setEditorType,
        canvasLimitExceeded, 
        setCanvasLimitExceeded,
        setScreenshotRequestProcessing, screenshotRequestProcessingRef,
        hasExtraTopBar, setHasExtraTopBar
    };

    return (
        <DocumentAnnotationContext.Provider value={providerState}>
            {children}
        </DocumentAnnotationContext.Provider>
    );
 
}

export { DocumentAnnotationProvider };
