import React, { useEffect, useRef, useState } from 'react';
import { utilService, store } from '../../_helpers';
import { userService } from '../User';
import { supportService } from './support.service';
import './SupportRequest.scss';
import '../../assets/lib/Common/richtext.min.css';
import some from '../../assets/lib/Common/jquery.richtext';
import { RichTextConfig, ParallelUploadCount } from './support.constants';
import SupportAttachment from './SupportAttachment';
import SupportSuccessModal from './SupportSuccessModal';
import useStateRef from 'react-usestateref';
import { alertActions } from '../App';
import { WrappedInsidePrimaryNav } from '../_shared/Nav/withPrimaryNav';
import { isCompactNav } from '../../_helpers/navbarUtil';

//NOTE: This component has some jquery.
//      We have used jquery to keep this support page aligned with marcombox support page.
//      Richtext.JQuery is used in marcombox so we have used that library in this to.
const SupportRequest = () => {
    const [userEmail, setUserEmail] = useState();
    const [currentUser, setCurrentUser] = useState();
    const [subject, setSubject] = useState();
    const [validFiles, setValidFiles, validFilesRef] = useStateRef([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState();
    const [fileErrorMessage, setFileErrorMessage] = useState();
    const [_fileName, setFileName] = useState([]);
    const [_fileOriginalName, setFileOriginalName] = useState(0);
    const [uploadIDs, setUploadIDs] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [_fileSize, setFileSize] = useState(0);
    const descriptionRef = useRef();
    const [isDropping, setIsDropping] = useState(false);
    const [attachmentConfig, setAttachmentConfig] = useState(undefined);

    const uuid = () => Math.random().toString(36).slice(-6);

    useEffect(() => {
        async function fetchData() {
            var currentUser = await userService.getCurrentUser();
            if (currentUser?.authData?.email !== undefined) {
                setCurrentUser(currentUser?.authData?.email);
            }

            var config = await supportService.getAttachmentConfig();            
            setAttachmentConfig(config)
            
        }
        
        store.dispatch(alertActions.disableNavActions(true));
        store.dispatch(alertActions.hideRightNavActions(true));
        
        fetchData();
        initRichtext();
    }, []);

    useEffect(()=> {

        if(!validFiles || validFiles.length < 1){
            return;
        }
        
        var files = validFiles.filter(file => file.isUploaded === false);
        
        if (!files) { return }

        setUploadIDs(files.slice(0, ParallelUploadCount).map( f => f.id));

    }, [validFiles])
    const initRichtext = () => {
        $('#description').richText(RichTextConfig);
        $('.richText-help').hide();
        $('.richText-toolbar').hide();
    };

    const totalFileSize = () => {
        let totalSize = 0;
        validFilesRef.current.forEach((f) => { totalSize = totalSize + f.size });        
        return totalSize === undefined? 0 : totalSize;
    };
    const spaceAvailable = (pickedFile) => {
        var sizeInMB = pickedFile.size / (1024 * 1024);
        var currentSize = totalFileSize();
        console.log('current size :' + currentSize);
        let increasedSize = currentSize + sizeInMB;
        console.log('increased size :' + increasedSize);
        return increasedSize < attachmentConfig.uploadLimitInMB;
    };

    const validateFileType = (pickedFile) => {
        var supportedTypes = currentUser !== undefined? attachmentConfig.allowedTypesLoggedIn : attachmentConfig.allowedTypes;
        var type = utilService.getFileExtention(pickedFile.name).toLowerCase();        
        return supportedTypes.some((x) => x === type);
    };

    const onDragStarted = () => {
        setIsDropping(true);
    };

    const onDragLeft = () => {
        setIsDropping(false);
    };
    const onFileDropped = (evt) => {
        evt.preventDefault();
        setIsDropping(false);
        var files = evt.dataTransfer.files;
        if (files.count < 1) {
            return;
        }

        onFilesAttached(files);
    };

    const onFilesAttached = async (pickedFiles) => {
        setErrorMessage('');
        setFileErrorMessage('');

        pickedFiles.forEach(async (pickedFile) => {
            let isValidType = validateFileType(pickedFile);

            if (isValidType === false) {
                setFileErrorMessage('File type not supported');
                return;
            }

            let isSpaceAvailable = spaceAvailable(pickedFile);
            if (isSpaceAvailable === false) {
                setFileErrorMessage('Can not upload more than ' + attachmentConfig.uploadLimitInMB + ' MB');
                return;
            }

            let validFile = {
                name: pickedFile.name,
                size: pickedFile.size / (1024 * 1024),
                id: uuid(),
                pickedFile: pickedFile,
                isUploaded: false
            };

            setValidFiles((prev) => [...prev, validFile]);

            //_fileOriginalName[validFIleCount] = pickedFile.name;
        });
    };

    const onAttachmentChanged = async (e) => {
        let pickedFiles = [...e.target.files];
        onFilesAttached(pickedFiles);
    };

    const onUploadCompleted = (fileKey, id) => {       
        setValidFiles((prev) => {
            var modified = prev.map((x) => {
                var newFile = { ...x };
                if (newFile.id === id) {
                    newFile.key = fileKey;
                    newFile.isUploaded = true;
                }
                return newFile;
            });
            return modified;
        });        
    };

    const onDeleteClicked = (id) => {
        setValidFiles(validFiles.filter((x) => x.id !== id));
        //TODO: After delete reduce total size in MB
    };

    const validation = () => {
        var email = userEmail || currentUser;
        if (!email || email == '') {
            setErrorMessage('Email is required');
            return false;
        }

        var invalidEmail = userService.validateEmail(email.trim());

        if (invalidEmail === true) {
            setErrorMessage('Invalid Email');
            return false;
        }

        if (!subject || subject == '') {
            setErrorMessage('Subject is required');
            return false;
        }
        return true;
    };
    const getRequestEmail = () => userEmail || currentUser;
    const onSubmitClicked = async () => {
        var isValidToSubmit = validation();
        if (!isValidToSubmit) {
            return;
        }
        setErrorMessage('');
        setFileErrorMessage('');

        //NOTE : Must work on files
        let nameOfFiles = [];
        for (const item in uploadedFiles) {
            nameOfFiles.push(_fileName[item]);
        }

        var files = validFiles.map((file) => file.key);

        let des = $('#description').val().toString();

        let request = {
            userEmail: getRequestEmail(),
            subject: subject.trim(),
            description: des,
            nameOfFiles: files
        };

        var tt = await supportService.sendSupportRequest(request);
        //TODO : Optimize success UI.

        setIsSubmitted(true);
        setValidFiles([]);
        setUploadIDs([]);
        $('#description').val('');
    };
    return (
        <>
        {
            isCompactNav() === true && <WrappedInsidePrimaryNav showRightActions={false}/>
        }
        <div className="mrnda-main-content">
            <div className="miranda-support">
                {isSubmitted === true ? (
                    <SupportSuccessModal />
                ) : (
                    <div className="form-container" style={{maxWidth: '55%'}}>
                        <div className="form">
                            <h1>Submit a request</h1>
                            <div
                                className="control-group error-text"
                                id="errorMessage"
                                >
                                {' '}
                                {errorMessage}
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Your email address *</label>

                                <input
                                    type="text"
                                    id="email"
                                    className="form-control"
                                    name="email"
                                    disabled={currentUser !== undefined}
                                    defaultValue={currentUser}
                                    onChange={(event) => {
                                        var { value } = event.target;
                                        setUserEmail(value);
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="subject">Subject *</label>
                                <input
                                    type="text"
                                    id="subject"
                                    name="subject"
                                    className="form-control"
                                    onChange={(event) => {
                                        var { value } = event.target;
                                        setSubject(value);
                                    }}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="description">Description </label>
                                <textarea
                                    ref={descriptionRef}
                                    className="form-control description-textarea"
                                    id="description"
                                    name="description"
                                />
                                <div style={{ fontSize: '11px' }}>
                                    Please enter the details of your request. A member of our
                                    support staff will respond as soon as possible.
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="control-label">Attachment (optional) </label>
                                <div
                                    className="file-area"
                                    id="filearea"
                                    onDrop={onFileDropped}
                                    onDragOver={onDragStarted}
                                    onDragLeave={onDragLeft}
                                    
                                    >
                                    <input
                                        id="attachment"
                                        type="file"
                                        multiple
                                        onChange={onAttachmentChanged}                                        
                                    />
                                    <div
                                        className="file-dummy"
                                        style={{
                                            backgroundColor:
                                                isDropping === true
                                                    ? 'var(--border-color)'
                                                    : 'var(--secondary-background-color)'
                                        }}>
                                        <span id="default" className="default">
                                            <span className="addfile">Add file</span> or drop files
                                            here
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="form-group error-text"
                                id="fileErrorMessage"
                                >
                                {fileErrorMessage}
                            </div>

                            <div id="fileNames" className="fileContainer">
                                {validFiles &&
                                    validFiles.map((file, index) => {
                                        return (
                                            <SupportAttachment
                                                key={file.id}
                                                shouldUpload={uploadIDs.some(x => x === file.id)}
                                                file={file}
                                                index={index}
                                                onDeleteClicked={onDeleteClicked}
                                                onUploadCompleted={onUploadCompleted}
                                            />
                                        );
                                    })}
                            </div>
                            <div className="form-group" style={{ paddingTop: '20px' }}>
                                <button
                                    className="btn btn-info "
                                    id="submit"
                                    disabled={validFiles.some(x=> x.isUploaded === false)}
                                    onClick={onSubmitClicked}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
        </>
    );
};

export default SupportRequest;
