//@ts-check

import React from 'react';

const HelpCircleIcon = ({ height = 20, width = 20, color = 'currentColor', ...rest }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 0.8C4.02355 0.8 0.8 4.02355 0.8 8C0.8 11.9764 4.02355 15.2 8 15.2C11.9764 15.2 15.2 11.9764 15.2 8C15.2 4.02355 11.9764 0.8 8 0.8ZM7.23884 9.87275H8.1252V9.82843C8.13997 8.91253 8.37633 8.51368 9.02633 8.10743C9.67632 7.71226 10.0604 7.14352 10.0604 6.32734C10.0604 5.17508 9.21837 4.33304 7.91838 4.33304C6.72181 4.33304 5.77267 5.07167 5.71727 6.32734H6.64794C6.70334 5.45576 7.31271 5.10122 7.91838 5.10122C8.61269 5.10122 9.17405 5.55916 9.17405 6.28302C9.17405 6.87023 8.83797 7.29125 8.40588 7.55346C7.68202 7.99294 7.24992 8.42135 7.23884 9.82843V9.87275ZM7.71157 12.0591C8.07719 12.0591 8.37633 11.7599 8.37633 11.3943C8.37633 11.0287 8.07719 10.7296 7.71157 10.7296C7.34595 10.7296 7.0468 11.0287 7.0468 11.3943C7.0468 11.7599 7.34595 12.0591 7.71157 12.0591Z"
                fill={color}
            />
        </svg>
    );
};

export default HelpCircleIcon;
