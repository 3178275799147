//@ts-check

import React from 'react';

const BackwardIcon = ({ height = 20, width = 20, color = 'currentColor' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="20px/Interaction/Multimedia/rewind" fill={color}>
                    <rect id="Rectangle" fillOpacity="0" x="0" y="0" width={width} height={height}></rect>
                    <g id="Outline" transform="translate(1.000000, 1.999044)" fillRule="nonzero">
                        <path
                            d="M0.8200922,0.116844936 L7,5.267 L7,0.500955576 C7,0.0770367522 7.49442874,-0.154541281 7.8200922,0.116844936 L16.8200922,7.61684494 C17.0599693,7.81674249 17.0599693,8.18516866 16.8200922,8.38506622 L7.8200922,15.8850662 C7.49442874,16.1564524 7,15.9248744 7,15.5009556 L7,10.734 L0.8200922,15.8850662 C0.494428738,16.1564524 0,15.9248744 0,15.5009556 L0,0.500955576 C0,0.0770367522 0.494428738,-0.154541281 0.8200922,0.116844936 Z M8,1.56795558 L8,14.4329556 L15.718,8.00095558 L8,1.56795558 Z M1,1.56795558 L1,14.4329556 L7,9.432 L7,6.568 L1,1.56795558 Z"
                            id="Combined-Shape"
                            transform="translate(8.500000, 8.000956) scale(-1, 1) translate(-8.500000, -8.000956) "></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default BackwardIcon;
