import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { alertActions, proofActions, TreeView, DirectoryPathStatic, directoryActions } from '../../../components';
import { utilService, history } from '../../../_helpers';

class ProofCopyModal extends React.Component {
    constructor(props) {
        super(props);
        var clonedTreedata = [...this.props.treeData];
        var mutedTreedata = clonedTreedata.map(item => {
            var newItem = { ...item };
            return newItem;
        });
        var targetParentDirectoryNode = this.props.targetParentDirectoryNode;
        this.state = {
            treeData: mutedTreedata,
            proof: this.props.proof,
            copiedProof: {},
            targetParentDirectoryNode: targetParentDirectoryNode,
            currentDirectoryExpandedNodes: [this.props.targetParentDirectoryNode.key],
            submitted: false,
            canSave: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() { }

    async onTreeNodeSelect(selectedNode) {
        var expandedKeys = utilService.findParentsArrayIds(
            this.state.treeData,
            selectedNode.key,
            'key',
            'title',
            'children',
        );
        await utilService.setStateAsync.bind(this)({
            targetParentDirectoryNode: selectedNode,
            currentDirectoryExpandedNodes: expandedKeys,
            submitted: false,
        });
    }

    onTreeNodeExpand(selectedNodeKeys) {
        this.setState({
            currentDirectoryExpandedNodes: selectedNodeKeys,
            submitted: false,
        });
    }

    async handleSubmit(event) {
        this.setState({
            canSave: false
        });
        event.preventDefault();
        var requestPayload = {
            id: this.state.proof.id,
            directoryNodeId: this.state.targetParentDirectoryNode.key,
        };
        var copiedProof = await this.props.dispatch(proofActions.copyProof(requestPayload));
        this.props.dispatch(alertActions.success('Copied !'));
        this.setState({
            submitted: true,
            copiedProof,
        });
    }

    onModalClose(hasChanges) {
        this.props.onClose(hasChanges);
    }

    renderFooterButtons() {
        switch (this.state.submitted) {
            case true: {
                return (
                    <>
                        <Button
                            variant=""
                            className="mrnda-btn mrnda-btn"
                            onClick={this.handleOpenProof.bind(this)}
                            disabled={this.props.isProcessing}
                        >
                            Open Copied File
                        </Button>
                        <Button
                            className="mrnda-btn--secondary"
                            onClick={this.handleOpenDirectory.bind(this)}
                            disabled={this.props.isProcessing}
                        >
                            Open Directory
                        </Button>
                    </>
                );
            }
            case false: {
                return (
                    <>
                        <Button
                            variant=""
                            disabled={this.state.submitted}
                            className="mrnda-btn mrnda-btn"
                            onClick={this.handleSubmit.bind(this)}
                            disabled={!this.state.canSave}
                        >
                            Copy
                        </Button>
                        <Button
                            className="mrnda-btn--secondary"
                            onClick={() => this.props.onClose(false)}
                        >
                            Close
                        </Button>
                    </>
                );
            }
            default:
                break;
        }
    }

    handleOpenProof() {
        this.props.onOpenProof(this.state.copiedProof.id, this.state.copiedProof.defaultVersionId);
        this.props.onClose(false);
    }

    async handleOpenDirectory() {
        await this.props.dispatch(
            directoryActions.navigateToDirectory(this.state.copiedProof.directoryNodeId),
        );
    }
    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={() => this.props.onClose(false)}
                size="m"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'}
                scrollable>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Copy File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!this.state.submitted && (
                        <div>
                            <form name="form" onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name">Current Directory</label>
                                    <div
                                        style={{
                                            padding: '10px',
                                            backgroundColor: 'var(--secondary-background-color)',
                                            borderRadius: '0.3rem',
                                        }}>
                                        <DirectoryPathStatic path={this.state.proof.path} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name">Copy to</label>
                                    <div id="role-permission-container">
                                        <TreeView
                                            treeData={this.state.treeData}
                                            onTreeNodeSelect={this.onTreeNodeSelect.bind(this)}
                                            onTreeNodeExpand={this.onTreeNodeExpand.bind(this)}
                                            selectedKeys={[
                                                this.state.targetParentDirectoryNode.key,
                                            ]}
                                            expandedKeys={this.state.currentDirectoryExpandedNodes}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}

                    {this.state.submitted && (
                        <div>
                            <label htmlFor="name">
                                This <span>File</span> has been copied to
                            </label>
                            <div
                                className="d-flex"
                                style={{
                                    padding: '10px',
                                    backgroundColor: 'var(--secondary-background-color)',
                                    borderRadius: '0.3rem',
                                }}>
                                {
                                    <DirectoryPathStatic
                                        path={this.state.targetParentDirectoryNode.path}
                                    />
                                }
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>{this.renderFooterButtons()}</Modal.Footer>
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    const { alert } = state;
    return {
        isProcessing: alert && alert.isLoading ? true : false,
    };
}

const connectedProofCopyModal = connect(mapStateToProps)(ProofCopyModal);
export { connectedProofCopyModal as ProofCopyModal };

// export { ProofCopyModal };
