//@ts-check
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { history, utilService } from '../../_helpers';
import { alertActions, fileShareService, SupportContactArea } from '../../components';
import localStorage from 'local-storage';
import { FILE_SHARE_RESULT } from '../_shared/fileshare.constants';
import withNoAuthNav from '../_shared/Nav/withNoAuthNav';

class FileDownloadRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statusText: 'Loading...',
            isLoading: true,
            showOTP: false,
            otp: '',
            submitted: false,
            otpError: '',
            fileShareDownloadOTPExpiresInMinute: 10
        };
        this.currentPathname = null;
        this.currentSearch = null;
    }
    async componentDidMount() {
        var { linkToken, targetParam, isEditable } = this.getLinkParams();

        if (!linkToken) {
            await utilService.setStateAsync.bind(this)({
                statusText: 'Link Invalid',
                isLoading: false
            });
            return;
        }

        this.props.dispatch(alertActions.loading(true));


        if (isEditable=== true) {
            var result = await fileShareService.verifyAnnotationShareLink(
                linkToken,
                targetParam
            )
            this.doCommonShareActions(result);
            this.props.dispatch(alertActions.loading(false));            
        } else {
            var isLegacy = this.props?.location?.pathname?.includes('/share/filedownload');
            var result = await fileShareService.VerifyFileDownloadLink(
                linkToken,
                targetParam,
                isLegacy                       
            );
            this.doCommonShareActions(result);
            this.props.dispatch(alertActions.loading(false));
        }

        
        // var isLegacy = this.props?.location?.pathname?.includes('/share/filedownload');
        // var result = isEditable === true ?  await fileShareService.verifyAnnotationShareLink(
        //     linkToken,
        //     targetParam            
        // ): 
        // await fileShareService.VerifyFileDownloadLink(
        //     linkToken,
        //     targetParam,
        //     isLegacy                       
        // );
        // this.doCommonShareActions(result);
        this.props.dispatch(alertActions.loading(false));
    }

    doCommonShareActions(result) {
        switch (result.resultType) {
            case FILE_SHARE_RESULT.OTPRequired:
                {
                    this.setState({
                        isLoading: false,
                        showOTP: true,
                        fileShareDownloadOTPExpiresInMinute:
                            result.fileShareDownloadOTPExpiresInMinute || 10
                    });
                }
                break;
            case FILE_SHARE_RESULT.Success:
                {
                    this.setState({
                        statusText: 'Your requested file has been downloaded !',
                        isLoading: false,
                        showOTP: false
                    });
                    window.location.href = result.downloadURL;
                }
                break;
            case FILE_SHARE_RESULT.NotFound:
                {
                    this.setState({
                        statusText: 'Not Found !',
                        isLoading: false,
                        showOTP: false
                    });
                }
                break;
            case FILE_SHARE_RESULT.ExpiredLink:
                {
                    this.setState({
                        statusText: 'Link Expired',
                        isLoading: false,
                        showOTP: false
                    });
                }
                break;
            case FILE_SHARE_RESULT.NeedLogin:
                {
                    localStorage.remove('user');
                    history.push({ pathname: '/landing', state: { from: this.props.location } });
                }
                break;
        }
    }

    doCommonShareActionsEditable(result) {
        switch (result.resultType) {
            case FILE_SHARE_RESULT.OTPRequired:
                {
                    this.setState({
                        isLoading: false,
                        showOTP: true,
                        fileShareDownloadOTPExpiresInMinute:
                            result.fileShareDownloadOTPExpiresInMinute || 10
                    });
                }
                break;
            case FILE_SHARE_RESULT.Success:
                {
                    history.replace({
                        pathname: `${result.editorRoute}`,
                    });
                }
                break;
            case FILE_SHARE_RESULT.NotFound:
                {
                    this.setState({
                        statusText: 'Not Found !',
                        isLoading: false,
                        showOTP: false
                    });
                }
                break;
            case FILE_SHARE_RESULT.ExpiredLink:
                {
                    this.setState({
                        statusText: 'Link Expired',
                        isLoading: false,
                        showOTP: false
                    });
                }
                break;
            case FILE_SHARE_RESULT.NeedLogin:
                {
                    localStorage.remove('user');
                    history.push({ pathname: '/landing', state: { from: this.props.location } });
                }
                break;
        }
    }

    getLinkParams() {
        var linkToken =
            this.props.match && this.props.match.params ? this.props.match.params.linkToken : null;
        var targetParam =
            this.props.match && this.props.match.params
                ? this.props.match.params.targetParam
                : null;

        var edit =
            this.props.match && this.props.match.params
                    ? this.props.match.params.edit
                    : null;
        return { linkToken, targetParam,  isEditable : edit !== undefined };
    }

    async onVerifyOTP(e) {
        e.preventDefault();
        this.setState({
            submitted: true
        });

        const { otp } = this.state;
        if (!otp) return;

        this.props.dispatch(alertActions.loading(true));

        var { linkToken, targetParam, isEditable } = this.getLinkParams();
           
        var result = await fileShareService.VerifyFileDownloadOTP(linkToken, targetParam, otp, isEditable);
    
        if (isEditable === true) {
            this.doCommonShareActionsEditable(result);
        } 
        else {
            this.doCommonShareActions(result);
        }
        switch (result.resultType) {
            case FILE_SHARE_RESULT.OTPInvalid:
                {
                    await utilService.setStateAsync.bind(this)({
                        otpError: 'Invalid One-Time Password',
                        isLoading: false
                    });
                }
                break;
            case FILE_SHARE_RESULT.OTPExpired:
                {
                    await utilService.setStateAsync.bind(this)({
                        otpError: 'One-Time Password Expired. Reopen the link to resend.',
                        isLoading: false
                    });
                }
                break;
        }
            
        this.props.dispatch(alertActions.loading(false));
    }

    getImageSource() {
        var preference = localStorage.get('preference-theme') || 'light';
        return preference === 'dark'
            ? '../../assets/Images/brand_logo_all_white.png'
            : '../../assets/Images/brand_logo.png';
    }

    render() {
        const { submitted, otpError, fileShareDownloadOTPExpiresInMinute } = this.state;
        return (
            <div
                className="mrnda-main-content d-flex justify-content-center align-items-center"
                style={{}}>
                {this.state.showOTP ? (
                    <>
                        <div className="padding-2x">
                            <div className="mrnda-flex-center-wrapper">
                                <div className="sign-in-box-wrapper">
                                    <div className="tenant-logo margin-2x--bottom typography--center">
                                        <div>
                                            {/* <i style={{ color: '#fc275c' }} className="fab fa-mix fa-7x"></i> */}
                                            <img src={this.getImageSource()}></img>
                                        </div>
                                    </div>
                                    <div className="sign-in-box mrnda-card padding-3x margin-4x--bottom">
                                        <h1 className="margin-2x--bottom text--center">
                                            Requires One-Time Password
                                        </h1>
                                        <div className="tile-message">
                                            <form
                                                className=""
                                                name="form"
                                                onSubmit={this.onVerifyOTP.bind(this)}>
                                                <div className="form-horizontal">
                                                    <div className="form-horizontal">
                                                        <p
                                                            style={{
                                                                opacity: '.8',
                                                                fontSize: '12px',
                                                                textAlign: 'center',
                                                                width: '310px',
                                                                marginBottom: '10px'
                                                            }}>
                                                            {`Enter the one-time password that has
                                                            been sent to your email (expires in ${fileShareDownloadOTPExpiresInMinute}
                                                            minutes)`}
                                                            .
                                                        </p>
                                                        <div
                                                            className="help-block mb-1"
                                                            style={{
                                                                visibility:
                                                                    submitted && otpError
                                                                        ? 'visible'
                                                                        : 'hidden',
                                                                textAlign: 'center',
                                                                minHeight: '20px'
                                                            }}>
                                                            {otpError}
                                                        </div>
                                                        <input
                                                            type="text"
                                                            id="otp"
                                                            name="OTP"
                                                            value={this.state.otp}
                                                            onChange={({ target }) => {
                                                                const { value, name } = target;
                                                                this.setState({
                                                                    otp: value,
                                                                    submitted: false,
                                                                    otpError: ''
                                                                });
                                                            }}
                                                            className="mrnda-input-text margin-2x--bottom"
                                                            placeholder="One-Time Password"
                                                            autoFocus=""
                                                            required
                                                        />

                                                        <div className="control-group">
                                                            <div className="controls">
                                                                <button className="mrnda-btn mrnda-btn--full margin-2x--bottom">
                                                                    Verify
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <p className="text sm-forward typography--center">
                                                <a className="typography--small" href=""></a>
                                            </p>
                                        </div>
                                    </div>
                                    <SupportContactArea />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {this.state.isLoading && (
                            <div
                                className="spinner-grow text-danger m-2"
                                role="status"
                                style={{ width: '3.5rem', height: '3.5rem' }}></div>
                        )}
                        <h1>{this.state.statusText}</h1>
                    </>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedRegisterPage = withNoAuthNav(connect(mapStateToProps)(withRouter(FileDownloadRequest)));
export { connectedRegisterPage as FileDownloadRequest };
