import React from 'react';
import cx from 'classnames';
function StatusHeader() {
    return (
        <div className="custom-status-row custom-status-header">
            <div style={{width: '40px'}}>

            </div>
            <div className="custom-status-cell custom-status-cell-status ">
                <span className='custom-status-cell-status__label' >Status</span>
            </div>            
            <div
                className={cx({
                    'custom-status-cell': true,
                    'custom-status-cell-visibility': true
                })}
               >
                <span>Visibility</span>
            </div>

            <div
                className={cx({
                    'custom-status-cell': true,
                    'custom-status-cell-action': 'true'
                })}
                >
                <span>Actions</span>
            </div>
        </div>
    );
}

export default StatusHeader;
