import React, { Component } from 'react';
import Evaporate from 'evaporate';
import { authHeader, config, utilService, store } from '../../../_helpers';
import MD5 from 'js-md5';
import { sha256 as SHA256 } from 'js-sha256';
import { s3Service, CREATE_PROOF_MODE } from '../../../components';
import { alertActions } from '../../App';

const md5 = (x) => {
    const o = MD5.create();
    o.update(x);
    return o.base64();
};
const sha256 = (x) => {
    const o = SHA256.create();
    o.update(x);
    return o.hex();
};
class FileUploader extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        this.state = {
            s3TempInfo: {
                bucket: '',
                awsRegion: '',
                aws_key: '',
                signerUrl: `${config.apiUrl}/s3`,
                awsSignatureVersion: '4',
                //cloudfront: true,
                progressIntervalMS: 200,
                signHeaders: { ...authHeader() },
                computeContentMd5: true,
                // cryptoMd5Method: function (data) { return crypto.createHash('md5').digest('base64'); },
                // cryptoHexEncodedHash256 : function (data) { return crypto.createHash('sha256').update(data).digest('hex'); }
                cryptoMd5Method: (_) => md5(_),
                cryptoHexEncodedHash256: (_) => sha256(_)
            },
            proofVersionId: '',            
            tanent: '',
            dragIn: false,
            isUploading: false
        };
    }
   
    componentWillUnmount() {
        window.onbeforeunload = undefined;
    }
    async onChange(e) {
        const validFiles = [];
        var pickedFiles = [...e.target.files];
        pickedFiles.forEach(file => {
            if (file && !this.validateFile(file)) return;

            validFiles.push(file);
        });
        
        console.log('1. file changed', validFiles);
        var prevFiles = this.props.files === null? []: this.props.createMode === CREATE_PROOF_MODE.PROOF? this.props.files : []; 
        
        var prevFilesTrimmed = prevFiles.map(x => {return x.file})
        var newFiles = [...prevFilesTrimmed, ...validFiles];
        await this.props.onFileChange(newFiles);
        console.log('4.1 Immediate after file changed', newFiles);
    }
    handleDrag(e) {
        e.preventDefault();
        e.stopPropagation();
        this.onDragIn();
    }
    handleDragIn(e) {
        e.preventDefault();
        e.stopPropagation();
        this.onDragIn();
    }
    handleDragOut(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ dragIn: false });
    }
    onDragIn() {
        this.setState({ dragIn: true });
    }
    async onDropFile(e) {
        if (this.state.isUploading) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const validFiles = [];
            var pickedFiles = this.props.createMode === CREATE_PROOF_MODE.PROOF? [...e.dataTransfer.files] : [e.dataTransfer.files[0]];
            
            pickedFiles.forEach(file => {
                if (file && !this.validateFile(file)) {
                    this.setState({ dragIn: false });
                    return;
                }
                validFiles.push(file);
            });
            
            console.log('1. file changed', validFiles);
            await utilService.setStateAsync.bind(this)({ dragIn: false });
            
            var prevFiles = this.props.files === null? []: this.props.createMode === CREATE_PROOF_MODE.PROOF? this.props.files : []; 
        
            var prevFilesTrimmed = prevFiles.map(x => {return x.file})
            var newFiles = [...prevFilesTrimmed, ...validFiles];
            await this.props.onFileChange(newFiles);
           
            console.log('4.1 Immediate after drop file changed', newFiles);
        }
    }
    async onFileUpload(currentIndex) {
        window.onbeforeunload = () => true;
        console.log('Clicked passed in FileUpload');
        await this.props.onFileUploadStarted();

        console.log('5. After File change handled in Create Proof Modal', this.props.s3TempInfo);
        this.setState(
            {
                s3TempInfo: {
                    ...this.state.s3TempInfo,
                    bucket: this.props.s3TempInfo.bucket,
                    awsRegion: this.props.s3TempInfo.region,
                    aws_key: this.props.s3TempInfo.accessKey,
                    s3Acceleration : this.props.s3TempInfo.transferAcceleration
                },
                proofVersionId: this.props.s3TempInfo.proofVersionId,
                tanent: this.props.s3TempInfo.tanent,
                uploadPath: this.props.uploadPath,
                isUploading: true
            },
            () => {
                this.props.files[currentIndex].file && this.uploadToS3(this.props.files[currentIndex].file, this.state.s3TempInfo);
            }
        );
    }
    uploadToS3(file, s3TempInfo, callbacks) {
        callbacks = {
            onFileUploadInit: this.props.onFileUploadInit,
            onProgressChange: this.props.onProgressChange,
            onFileUploadCompleted: this.props.onFileUploadCompleted
        };
        s3Service.uploadToS3(file, s3TempInfo, callbacks, this.state.uploadPath);
    }
    onBrowseClick(e) {
        if (this.state.isUploading) {
            return;
        }
        e.preventDefault();
        this.fileInputRef.current.click();
    }
    validateFile(file) {
        var fileExtention = utilService.getFileExtention(file.name).toLowerCase();
        var allowedFileExtentionArray = this.props.allowedFileExtentions.split(',');
        var result = allowedFileExtentionArray.includes(`.${fileExtention}`);
        if (!result) {
            store.dispatch(alertActions.warn('File type not supported !'));
        }
        return result;
    }
    render() {
        return (
            <>
                <div className="file-upload__modal" role="section">
                    <form
                        className="file-upload__form js-dropzone"
                        role="form"
                        onDragEnter={this.handleDragIn.bind(this)}
                        onDragLeave={this.handleDragOut.bind(this)}
                        onDragOver={this.handleDrag.bind(this)}
                        onDrop={this.onDropFile.bind(this)}
                        style={{ backgroundColor: this.state.dragIn ? '#99dbdc' : 'transparent' }}>
                        <input
                            type="file"
                            ref={this.fileInputRef}
                            id="file-dropzone"
                            className="file__input js-file__input mr-2 mb-2"
                            // accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .ppt, .mp4,  .webm, .avi, .mov, .wmv, .mkv, .ogg, .flv"
                            accept={this.props.allowedFileExtentions}
                            disabled={this.state.isUploading}
                            multiple ={this.props.createMode === CREATE_PROOF_MODE.PROOF? true : false}
                            onChange={this.onChange.bind(this)}
                        />
                        <label className="file__input-label" htmlFor="file-dropzone">
                            Drag file here or{' '}
                            <button
                                className="file__input-label-button"
                                role="button"
                                disabled={this.state.isUploading}
                                onClick={this.onBrowseClick.bind(this)}>
                                browse
                            </button>
                        </label>
                    </form>
                </div>
            </>
        );
    }
}
export { FileUploader };
