//@ts-check

import React from 'react';

const DownloadIcon = ({ height = 16, width = 16, color = 'currentColor' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 16 19"
            fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.47203 2.58198e-05C9.60478 -0.00839367 9.74296 0.0358653 9.85356 0.146472L15.8536 6.14647C15.9642 6.25708 16.0084 6.39525 16 6.528V16C16 17.1046 15.1046 18 14 18H11C10.7239 18 10.5 17.7762 10.5 17.5C10.5 17.2239 10.7239 17 11 17H14C14.5523 17 15 16.5523 15 16V7.00003H10.5C9.67158 7.00003 9.00001 6.32845 9.00001 5.50003V1.00003H4.00001C3.44772 1.00003 3.00001 1.44774 3.00001 2.00003V7.00003C3.00001 7.27617 2.77615 7.50003 2.50001 7.50003C2.22387 7.50003 2.00001 7.27617 2.00001 7.00003V2.00003C2.00001 0.895456 2.89544 2.58198e-05 4.00001 2.58198e-05H9.47203ZM14.293 6.00003L10 1.70603V5.50003C10 5.74549 10.1769 5.94963 10.4101 5.99197L10.5 6.00003H14.293ZM7.67461 15.1204C7.88428 14.9407 8.19993 14.965 8.37964 15.1746C8.55935 15.3843 8.53507 15.6999 8.3254 15.8797L4.82763 18.8777C4.74498 18.9495 4.63855 18.9946 4.52173 18.9996C4.51449 18.9999 4.50725 19 4.50001 19C4.37469 19 4.26014 18.9539 4.1724 18.8778L0.674613 15.8797C0.46495 15.6999 0.44067 15.3843 0.620381 15.1746C0.800092 14.965 1.11574 14.9407 1.3254 15.1204L4.00001 17.4125V10C4.00001 9.72388 4.22387 9.50003 4.50001 9.50003C4.77615 9.50003 5.00001 9.72388 5.00001 10V17.4125L7.67461 15.1204Z"
                fill={color}                
            />
        </svg>
    );
};

export default DownloadIcon;
