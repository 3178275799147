import React, { useRef } from 'react';
import { fileUploadConstants } from '../../../../../components';
import { uuidv4 } from '../../DocumentAnnotationUtil'
import { useScreenSizes } from '../../../../responsive/useScreenSizes';
import PhoneBreakpoint from '../../../../responsive/PhoneBreakpoint';

function AttachmentPicker({ attachments, setAttachments }) {
    const fileInputRef = useRef();
    const {isMobile} = useScreenSizes();
    const onFileChange = (e) => {        
        var file = e.target.files[0];
        var files = [...e.target.files];
        var prev = attachments === null ? [] : [...attachments];

        var newPicks = files.map((file) => {
            return {
                progressNow: 0,
                completed: false,
                fileUploadStatus: file ? fileUploadConstants.FILE_LOADED : fileUploadConstants.NONE,
                fileUploadPromise: null,
                fileUploadTimeLeft: 0,
                fileName: file ? file.name : this.THIS_NO_FILE_PLACEHOLDER,
                file: file,
                guid: uuidv4()
            };
        });

        let newAttachments = [...prev, ...newPicks];
        setAttachments(newAttachments);
    };

    return (
        <div style={isMobile? {flex: 1, display: 'flex', alignItems: 'center', justifyItems:'center'} : {}}>
            <input
                type="file"
                ref={fileInputRef}
                id="file-dropzone"
                className="file__input js-file__input mr-2 mb-2"
                // accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .ppt, .mp4,  .webm, .avi, .mov, .wmv, .mkv, .ogg, .flv"
                // accept={this.props.allowedFileExtentions}
                // disabled={this.state.isUploading}
                multiple
                onChange={onFileChange}
            />

            <span
                className="attachment-icon"
                style={isMobile? { background: 'transparent', width: 'Auto' }: {}}
                title="Attach files"
                onClick={() => {
                    fileInputRef.current.click();
                }}>
                <i className="fa fa-paperclip"></i>
                <PhoneBreakpoint>
                    <span style={{ marginLeft: '8px'}} >Attach file</span>
                </PhoneBreakpoint>
            </span>
        </div>
    );
}

export { AttachmentPicker };
