import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import {
    userActions,
    alertActions,
    MultiSelect,
    userService,
    tenantService
} from '../../components';
import { utilService } from '../../_helpers';
import { ConfirmationModal } from '../_shared';
import { AUTH_PROVIDER_MAP, groupService, providerService, roleService } from '../User';
import classnames from 'classnames';
import { DataFetcher } from '../_shared/DataFetcher/DataFetcher';
const initialValidationState = {
    firstName: {
        validated: true,
        message: ''
    },
    lastName: {
        validated: true,
        message: ''
    },
    email: {
        validated: true,
        message: ''
    },
    role: {
        validated: true,
        message: ''
    },
    provider: {
        validated: true,
        message: ''
    }
};

function convertProviderDisplayName(name){

        switch (name.toLowerCase()) {
            case 'miranda':
                return 'Brandshare Collaboration';
                
            case 'marcombox':
                return 'Brandshare DAM';

            case 'oktagateway':
                return 'Okta Gateway'

            default:
                return name;      
    }
}

class UserModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userObject: this.props.userObject,
            validations: initialValidationState,
            groups: [],
            roles: [],
            existingGroups: null,
            existingRole: {},
            showDeleteConfirm: false,            
            providers: [],
            existingProvider: {},
            submitEnabled: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async loadGroupDataFromServer() {
        const paramsObj = {
            page: 0,
            pageSize: 2147483647,
            proofSortModel: {
                sortDirection: 1,
                sortColumn: 'name',
            },
            proofFilters: [],
        };
                
        var groupsData = await groupService.getAll(paramsObj);
        this.setState({groups : groupsData.list})
        var groupsData = this.state.groups;
        var rowsTemp = [...groupsData];
        const rows = rowsTemp.map((item) => {
            var newItem = { ...item };
            newItem.value = item.id;
            newItem.label = item.name;
            // newItem.addedGroupIds = item.addedGroupIds ? newItem.addedGroupIds : [];
            // newItem.removedGroupIds = item.removedGroupIds ? newItem.removedGroupIds : [];
            return newItem;
        });
        const rowsExistingGroups =
            this.state.userObject.groups && this.state.userObject.groups.length > 0
                ? rows.filter((item) => this.state.userObject.groups.includes(item.id))
                : [];
        await utilService.setStateAsync.bind(this)({ existingGroups: rowsExistingGroups });
        await utilService.setStateAsync.bind(this)({ groups: rows });

    }
    async loadRoleDataFromServer() {
        const rolesRequest = {
            page: 0,
            pageSize: 2147483647,
            proofSortModel: {
                sortDirection: 1,
                sortColumn: 'name',
            },
            proofFilters: [],
        };
        var roles  = await roleService.getAll(rolesRequest);
        this.setState({roles : roles.list}); 
        
        var rolesData = this.state.roles;
        var rolesRowsTemp = [...rolesData];
        const rolesRows = rolesRowsTemp.map((item) => {
            var newItem = { ...item };
            newItem.value = item.id;
            newItem.label = item.name;
            return newItem;
        });
        const rowsExitingRole =
            this.state.userObject.roleId &&
            rolesRows &&
            rolesRows.find((x) => x.id === this.state.userObject.roleId);
        await utilService.setStateAsync.bind(this)({ existingRole: rowsExitingRole });
        await utilService.setStateAsync.bind(this)({ roles: rolesRows });
        
    }
    async loadProviderDataFromServer() {
        
        const providerRequest = {
            page: 0,
            pageSize: 2147483647,
            proofSortModel: {
                sortDirection: 1,
                sortColumn: 'name',
            },
            proofFilters: [],
        };
        
        var providers = await providerService.getAll(providerRequest);
        this.setState({providers : providers.list})
        var providersData = this.state.providers;
        var providersRowsTemp = [...providersData];
        const providerRows = providersRowsTemp.map((item) => {
            var newItem = { ...item };
            newItem.value = item.id;
            newItem.label = convertProviderDisplayName(item.name);
            return newItem;
        });
        const rowsExitingProvider =
            this.state.userObject.authProvider &&
            providerRows &&
            providerRows.find((x) => x.id.toString() === this.state.userObject.authProvider.toString());
        await utilService.setStateAsync.bind(this)({ existingProvider: rowsExitingProvider });
        await utilService.setStateAsync.bind(this)({ providers: providerRows });  
    }
   

    onModalClose() {
        this.props.onClose();
    }

    handleChange(event) {
        
        var { name, value } = event.target;
        const { userObject } = this.state;
        if (name === 'isEnabled') {
            value = value == 'false' ? 'Enabled' : 'Disabled';
        }
        this.setState({
            userObject: {
                ...userObject,
                [name]: value
            },
            submitted: false,            
            submitEnabled : true,
        });
    }

    async onChangeMultiSelect(selectedOptions, addedGroupIds, removedGroupIds) {
        var selectedGroupIds = selectedOptions
            ? selectedOptions.map((item) => {
                  var newItem = { ...item };
                  return newItem.id;
              })
            : [];

        var newAddedGroupIds = this.state.userObject.addedGroupIds
            ? [...this.state.userObject.addedGroupIds, ...addedGroupIds]
            : addedGroupIds;
        var newRemovedGroupIds = this.state.userObject.removedGroupIds
            ? [...this.state.userObject.removedGroupIds, ...removedGroupIds]
            : removedGroupIds;
        var newGroupObject = {
            ...this.state.userObject,
            groups: selectedGroupIds,
            addedGroupIds: newAddedGroupIds,
            removedGroupIds: newRemovedGroupIds
        };
        this.setState({
            userObject: newGroupObject,
            existingGroups: selectedOptions,
            submitted: false,            
            submitEnabled : true,
        });
    }

    async onChangeRoleSelect(selectedOption) {
        var selectedRoleId = selectedOption ? selectedOption.id : null;
        var newGroupObject = {
            ...this.state.userObject,
            roleId: selectedRoleId
        };
        this.setState({
            userObject: newGroupObject,
            existingRole: selectedOption,
            submitted: false,            
            submitEnabled : true,
        });
    }
    
    async onChangeProviderSelect(selectedOption) {
        var selectedAuthProvider = selectedOption ? selectedOption.id : null;
        var newGroupObject = {
            ...this.state.userObject,
            authProvider: selectedAuthProvider
        };
        this.setState({
            userObject: newGroupObject,
            existingProvider: selectedOption,
            submitted: false,            
            submitEnabled : true,
        });
    }

    async validateFields() {
        var globalValidation = true;
        var { validations } = this.state;
        var validationCopy = { ...validations };
        if (
            !this.state.userObject.firstName ||
            (this.state.userObject.firstName && this.state.userObject.firstName.length < 3)
        ) {
            validationCopy.firstName = {
                validated: false,
                message: 'First Name must be more than 2 characters '
            };
            globalValidation = false;
        }
        if (
            !this.state.userObject.lastName ||
            (this.state.userObject.lastName && this.state.userObject.lastName.length < 3)
        ) {
            validationCopy.lastName = {
                validated: false,
                message: 'Last Name must be more than 2 characters '
            };
            globalValidation = false;
        }

        if (!this.state.userObject.roleId) {
            validationCopy.role = {
                validated: false,
                message: 'User must have a role '
            };
            globalValidation = false;
        }

        if (!this.state.userObject.authProvider) {
            validationCopy.provider = {
                validated: false,
                message: 'User must have a provider '
            };
            globalValidation = false;
        }

        if (this.props.operationMode == 1) {
            if (this.state.userObject.email) {
                var userObjectRequest = { ...this.state.userObject };
                if (
                    userObjectRequest.isEnabled === 'Enabled'
                        ? (userObjectRequest.isEnabled = true)
                        : (userObjectRequest.isEnabled = false)
                );

                if (
                    !this.state.userObject.email ||
                    (this.state.userObject.email &&
                        userService.validateEmail(this.state.userObject.email))
                ) {
                    validationCopy.email = {
                        validated: false,
                        message: 'Enter a valid email '
                    };
                    globalValidation = false;
                }

                if (validationCopy.email.validated) {
                    var emailDomainValidResponse = await tenantService.validateEmailDomain(
                        userObjectRequest.email
                    );
                    if (!emailDomainValidResponse.isValid) {
                        validationCopy.email = {
                            validated: false,
                            message: 'Email Domain is not permitted'
                        };
                        globalValidation = false;
                    }
                }
                if (validationCopy.email.validated) {
                    var response = await this.props.dispatch(
                        userActions.isEmailUnique(userObjectRequest)
                    );
                    if (!response.isEmailUnique) {
                        validationCopy.email = {
                            validated: false,
                            message: 'User with the email already exists'
                        };
                        globalValidation = false;
                    }
                }
            }
        }
        await utilService.setStateAsync.bind(this)({
            validations: validationCopy
        });
        return globalValidation;
    }
    async handleSubmit(event) {
        event.preventDefault();
        await utilService.setStateAsync.bind(this)({
            submitted: true,
            validations: initialValidationState,            
            submitEnabled : false,
        });
        var allValidated = await this.validateFields();
        if (!allValidated) {
            return;
        }
        const { userObject } = this.state;
        const { dispatch } = this.props;
        var userObjectRequest = { ...userObject };
        if (
            userObjectRequest.isEnabled === 'Enabled'
                ? (userObjectRequest.isEnabled = true)
                : (userObjectRequest.isEnabled = false)
        );
        delete userObjectRequest.lastLogin;
        if (userObject.firstName && userObject.lastName && userObject.email) {
            if (this.props.operationMode === 1)
                await dispatch(userActions.createAccount(userObjectRequest));
            else if (this.props.operationMode === 2)
                await dispatch(userActions.update(userObjectRequest));
            else return;
            await this.refetchUsers();
            this.props.dispatch(alertActions.success('Saved !'));
            this.onModalClose();
        }
    }

    async refetchUsers() {
        const data = {
            page: 0,
            pageSize: 20,
            proofSortModel: {
                sortDirection: 1,
                sortColumn: null
            },
            proofFilters: []
        };
        await this.props.dispatch(userActions.getAllV2(data));
    }

    async handleResendInvite() {
        var { id: userId } = { ...this.state.userObject };
        if (userId) {
            this.props.dispatch(alertActions.loading(true));
            await userService.sendRegistrationInviteEmail(userId);
            this.props.dispatch(alertActions.success('Invite Resend !'));
            this.props.dispatch(alertActions.loading(false));
        }
    }

    onDeleteClick(e){
        e.preventDefault();
        this.setState({ showDeleteConfirm: true });
    }
    async handleDelete(){
        await userService.delete(this.state.userObject.id)
        this.props.dispatch(alertActions.success("User deleted !"));
        await this.refetchUsers();
        this.onModalClose();
    }

    render() {
        const { isProcessing } = this.props;
        const { userObject, submitted, validations } = this.state;
        const user = userObject;
        const titleText = this.props.operationMode === 1 ? 'Add User' : 'Edit User';
        const canEdit = user.authProvider == 1;
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'}
                scrollable>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{titleText}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form name="form" onSubmit={this.handleSubmit} autoComplete="nope">
                        <div
                            className={
                                'form-group' + (submitted && !user.firstName ? ' has-error' : '')
                            }>
                            <label htmlFor="firstName">First Name</label>
                            <input
                                type="text"
                                className="form-control mrnda-input-text"
                                name="firstName"
                                value={user.firstName || ''}
                                onChange={this.handleChange}
                                autoComplete="off"
                            />
                            {submitted && !validations.firstName.validated && (
                                <div className="help-block">{validations.firstName.message}</div>
                            )}
                        </div>
                        <div
                            className={
                                'form-group' + (submitted && !user.lastName ? ' has-error' : '')
                            }>
                            <label htmlFor="lastName">Last Name</label>
                            <input
                                type="text"
                                className="form-control mrnda-input-text"
                                name="lastName"
                                value={user.lastName || ''}
                                onChange={this.handleChange}
                                autoComplete="off"
                            />
                            {submitted && !validations.lastName.validated && (
                                <div className="help-block">{validations.lastName.message}</div>
                            )}
                        </div>
                        <div
                            className={
                                'form-group' + (submitted && !user.email ? ' has-error' : '')
                            }>
                            <label htmlFor="email">Email</label>
                            <div style={ (this.props.operationMode === 2) ? { border: '1px solid lightgray', borderRadius: '5px'} : {}}>
                            <input
                                type="text"
                                className="form-control mrnda-input-text"
                                name="email"
                                value={user.email || ''}
                                onChange={this.handleChange}
                                disabled={this.props.operationMode == 2 ? true : false}
                                autoComplete="off"
                            />                            
                            {submitted && !validations.email.validated && (
                                <div className="help-block">{validations.email.message}</div>
                            )}
                            </div>
                        </div>
                        <div className={'form-group'}>
                            <label htmlFor="role">Select Role</label>

                            <DataFetcher variant='skeleton' height='40px' onFetchData={this.loadRoleDataFromServer.bind(this)}>
                                <>
                                    <MultiSelect
                                        isMulti={false}
                                        options={this.state.roles}
                                        selectedOptions={this.state.existingRole}
                                        onChange={this.onChangeRoleSelect.bind(this)}
                                        closeMenuOnSelect={true}
                                    />
                                    {submitted && !validations.role.validated && (
                                        <div className="help-block">{validations.role.message}</div>
                                    )}
                                </>
                            </DataFetcher>
                        </div>
                        <div className={'form-group'}>
                            <label htmlFor="groups">Select Groups</label>
                            <DataFetcher variant='skeleton' height='40px' onFetchData={this.loadGroupDataFromServer.bind(this)}>
                                <MultiSelect
                                    isMulti={true}
                                    options={this.state.groups}
                                    selectedOptions={this.state.existingGroups}
                                    onChange={this.onChangeMultiSelect.bind(this)}
                                    closeMenuOnSelect={true}
                                />
                            </DataFetcher>                                                            
                        </div>
                        <div
                            className={
                                'form-group' + (submitted && !user.firstName ? ' has-error' : '')
                            }>
                            <label htmlFor="status">Status</label>
                            <label className="check-container display--block ml-1">
                                <span className="display--block role-permission-section__body__check-label">
                                    <strong>Enabled</strong>
                                </span>
                                <input
                                    type="checkbox"
                                    className="ml-2 mr-2"
                                    data-toggle="toggle"
                                    data-on="Enabled"
                                    data-off="Disabled"
                                    name="isEnabled"
                                    value={user.isEnabled === 'Enabled' ? true : false}
                                    checked={user.isEnabled === 'Enabled' ? true : false}
                                    onChange={this.handleChange}
                                />
                                <span className="check-container__checkmark">
                                    <i className="fas fa-check" />
                                </span>
                            </label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="loginProvider">Login Provider</label>{' '}
                            <DataFetcher variant='skeleton' height='40px' onFetchData={this.loadProviderDataFromServer.bind(this)}>
                                <div style={ (this.props.operationMode === 2) ? { border: '1px solid lightgray', borderRadius: '5px'} : {}}>
                                <MultiSelect
                                    isMulti={false}
                                    options={this.state.providers}
                                    selectedOptions={this.state.existingProvider}
                                    onChange={this.onChangeProviderSelect.bind(this)}
                                    closeMenuOnSelect={true}
                                    isDisabled={(this.props.operationMode === 2 ? true: false)}
                                />
                                {submitted && !validations.provider.validated && (
                                    <div className="help-block">{validations.provider.message}</div>
                                )}
                                </div>
                            </DataFetcher>
                        </div>
                        {this.props.operationMode === 2 && (
                            <div className="d-flex justify-content-between">
                                <div className="form-group">
                                    <label htmlFor="delete">Delete this user</label>
                                    <button
                                        className="mrnda-btn--caution"
                                        style={{ padding: 10, display: 'block' }}
                                        onClick={this.onDeleteClick.bind(this)}>
                                        <i className="far fa-trash-alt mr-2" />
                                        Delete
                                    </button>
                                </div>
                                {user.authProvider === 0 && (
                                    <div className="form-group">
                                        <label htmlFor="delete">Invite</label>
                                        <button
                                            className="mrnda-btn--secondary"
                                            style={{ padding: 10, display: 'block' }}
                                            onClick={this.handleResendInvite.bind(this)}
                                            disabled={isProcessing}>
                                            <i className="far fa-paper-plane mr-2" />
                                            Resend
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    
                    <Button
                        variant=""                        
                        className={classnames({"btn mrnda-btn" : true, "authorization-disabled" : this.state.submitEnabled !== true})}
                        onClick={this.handleSubmit.bind(this)}                        
                        >
                        Save
                    </Button>
                    
                    <Button className="mrnda-btn--secondary" onClick={this.onModalClose.bind(this)}>
                        Close
                    </Button>
                </Modal.Footer>
                <ConfirmationModal
                    show={this.state.showDeleteConfirm}
                    onHide={() => this.setState({ showDeleteConfirm: false })}
                    onPrimaryClick={this.handleDelete.bind(this)}
                    onSecondaryClick={() => this.setState({ showDeleteConfirm: false })}
                    primaryButtonText="Yes"
                    secondaryButtonText="No"
                    title={`Delete "${this.state.userObject?.firstName}"`}
                    bodyText="Are you sure you want to delete this user ?"
                />
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    const { alert } = state;
    return {
        isProcessing: alert && alert.isLoading ? true : false
    };
}

const connectedCreateProofModel = connect(mapStateToProps)(UserModal);
export { connectedCreateProofModel as UserModal };
