import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import './UserLoginPage.css';
import { userActions } from '../../../components';
import { utilService, history } from '../../../_helpers';
import './UserLoginPage.scss';
import localStorage from 'local-storage';

class AdminLoginPage extends React.Component {
    constructor(props) {
        super(props);
        const subdomain = window.location.host.split('.')[0];
        this.state = {
            username: '',
            password: '',
            submitted: false,
            subdomain: subdomain,
            loginNoMatch: true,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        document.title = 'Brandshare Collaboration - Admin Login';
        this.props.dispatch(userActions.logout());
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value, submitted: false, loginNoMatch: false });
    }
    getImageSource() {
        var preference = localStorage.get('preference-theme') || 'light';        
        return preference === 'dark'?  '../../assets/Images/brand_logo_all_white.png' : '../../assets/Images/brand_logo.png';
    }
    async handleSubmit(e) {
        e.preventDefault();
        await utilService.setStateAsync.bind(this)({
            submitted: true,
        });
        const { username, password } = this.state;
        const subdomain = window.location.host.split('.')[0];
        if (username && password) {
            var responseAuth = await this.props.dispatch(
                userActions.login(username, password, subdomain),
            );
            if (responseAuth && !responseAuth.success) {
                await utilService.setStateAsync.bind(this)({
                    loginNoMatch: true,
                });
            } else {
                history.push('/dashboard');
            }
        }
    }

    render() {
        const { loggingIn } = this.props;
        const { username, password, submitted, loginNoMatch } = this.state;
        return (
            <div className="mrnda-main-content padding-2x">
                <div className="mrnda-flex-center-wrapper">
                    <div className="sign-in-box-wrapper">
                        <div className="tenant-logo margin-2x--bottom typography--center">
                            <div>
                                 {/* <i style={{ color: '#fc275c' }} className="fab fa-mix fa-7x"></i> */}
                                 <img src={this.getImageSource()}></img>
                            </div>
                            <hr></hr>
                            <h1 className="h3 mb-3 font-weight-normal text-center" style={{}}>Admin Login</h1>
                        </div>
                        <div className="sign-in-box mrnda-card padding-3x margin-4x--bottom">
                            <h1 className="margin-3x--bottom text--center">Welcome</h1>
                            <div className="tile-message">
                                {submitted && loginNoMatch && (
                                    <div className="typography--center typography--caution margin-2x--bottom">
                                        Incorrect Email or Password
                                    </div>
                                )}
                                <form className="" name="form" onSubmit={this.handleSubmit}>
                                    <div className="form-horizontal">
                                        <div className="form-horizontal">
                                            <input
                                                type="email"
                                                id="inputEmail"
                                                className="mrnda-input-text margin-2x--bottom"
                                                name="username"
                                                value={username}
                                                onChange={this.handleChange}
                                                placeholder="Email"
                                                required
                                                autoFocus=""
                                            />

                                            <input
                                                type="password"
                                                id="password"
                                                name="password"
                                                value={password}
                                                onChange={this.handleChange}
                                                className="mrnda-input-text margin-2x--bottom"
                                                placeholder="Password"
                                                required
                                            />

                                            <div className="control-group">
                                                <div className="controls">
                                                    <button className="mrnda-btn mrnda-btn--full margin-2x--bottom">
                                                        Sign in
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <p className="text sm-forward typography--center">
                                    <a className="typography--small" href="">
                                        Forgot your password?
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="sender">
                            <p className="typography--small typography--muted typography--center">
                                <a
                                    href="mailto:support@mirandaproof.com"
                                    className="link-block margin-3x--right">
                                    Support
                                </a>
                                <a href="http://www.mirandaproof.com" className="link-block">
                                    Brandshare Collaboration
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    console.log('IN Login:');
    console.log(state);
    const { loggingIn } = state.authentication;
    return {
        loggingIn,
    };
}

const connectedAdminLoginPage = connect(mapStateToProps)(AdminLoginPage);
export { connectedAdminLoginPage as AdminLoginPage };
