import { alertConstants } from '../../components';
import { toast, cssTransition } from 'react-toastify';
import React from 'react';
export const alertActions = {
    success,
    error,
    warn,
    clear,
    loading,
    startToast,
    dismissToast,
    makeProofEditorDirty,
    hideTopbar,
    disableNavActions,
    hideRightNavActions,
    toggleNavType
};

function success(message) {
    toast(`✔ ${message}`);
    return { type: alertConstants.SUCCESS, message };
}

function error(message) {
    toast.error(`📛 ${message}`);
    // toast.error(<div>Error 400 <br />Field text invalid</div>);
    return { type: alertConstants.ERROR, message };
}
function warn(message) {
    toast(`❗ ${message}`);
    return { type: alertConstants.WARNING, message };
}
function clear() {
    return { type: alertConstants.CLEAR };
}

function loading(isLoading) {
    return { type: alertConstants.LOADING, isLoading };
}

function startToast(message) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            var id = toast(`${message}`, { autoClose: false });
            resolve(id);
        });
    };
}

function dismissToast(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            toast.dismiss(id);
            resolve(id);
        });
    };
}

function makeProofEditorDirty(isProofEditorDirty) {
    return { type: alertConstants.PROOF_EDITOR_DIRTY, isProofEditorDirty };
}

function hideTopbar(isHideTopbar, isHideTranscription = false) {
    return { type: alertConstants.HIDE_TOPBAR, isHideTopbar, isHideTranscription };
}

function disableNavActions(isNavActionsDisabled) {
    return { type: alertConstants.DISABLE_NAV_ACTIONS, isNavActionsDisabled};
}

function hideRightNavActions(isHideRightNavActions) {
    return { type: alertConstants.HIDE_RIGHT_NAV_ACTIONS, isHideRightNavActions};
}

function toggleNavType(isCompact) {
    return { type: alertConstants.TOGGLE_NAVBAR, isCompact};
}
