import React, { useEffect, useState } from 'react';
export function PageCommentPreview({ page }) {
    return (
        <>
            <div className="comment-preview-item--source-name">
                </div>
            <div className="comment-preview-item--content">
                <div className="comment-preview-item--header">
                    <div className="comment-preview-item--page">
                        Page <strong>{page.pageNumber}</strong>
                    </div>
                </div>
                <img
                    src={page.pageUrl}
                    alt=""
                />
            </div>
        </>
    );
}