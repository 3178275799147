export const mentionHelper = { processInputValue, processLabelValue, processCommentValueForInput };
const INPUT_REGEX_EXP = '(?:^|)(\\@([^\\@]*]))(\\([^\\)]+\\))';
const LABEL_REGEX_EXP = '(?:^|)\\@\\([^\\)]+\\)';
const COMMENT_REGEX_EXP = '(?:^|)\(\\@\)\(\\([^\\)]+\\)\)';


export const COMMENT_TEXT_TYPE  = {
    text : "TEXT",
    mention : "MENTION"
}

function cleanUp(str) {
    return str.replace(/[\@\[{()}\]]/g, '');
}

function getUserName(id, mentions) {
    var user = mentions.find((x) => x.userId === id);
    return user.name;
}

function processInputValue(tValue) {
    var cleanedValue = tValue;
    var commentValue = tValue;

    var result = [...tValue.matchAll(INPUT_REGEX_EXP)];

    var mentions = [];
    result.forEach((item) => {
        var name = cleanUp(item[2]);
        tValue = tValue.replace(item[2], '');
        cleanedValue = cleanedValue.replace(item[0], name);
        commentValue = commentValue.replace(item[0], '');
        var mention = {
            name: cleanUp(item[2]),
            userId: cleanUp(item[3])
        };

        mentions.push(mention);
    });

    return {
        result,
        mentions,
        tValue,
        cleanedValue,
        commentValue
    };
}

function processLabelValue(tValue, mentions) {
    
    var result = [...tValue.matchAll(LABEL_REGEX_EXP)];
    
    if (result.length === 0) return [createPortion(tValue, COMMENT_TEXT_TYPE.text)];

    var plainValue = tValue;
    var processed = [];
    var reduceCount = 0;
    result.forEach((item) => {
        var t = plainValue.substring(0, item.index - Math.abs(reduceCount));
        plainValue = plainValue.replace(t, '');
        reduceCount -= t.length;


        var pre = createPortion(t, COMMENT_TEXT_TYPE.text);        
        processed.push(pre);

        var mention = createPortion(getUserName(cleanUp(item[0]), mentions), COMMENT_TEXT_TYPE.mention);
        processed.push(mention);

        plainValue = plainValue.replace(item[0], '');
        reduceCount -= item[0].length;
    });

    if (plainValue.length > 0) {
        var rest = createPortion(plainValue, COMMENT_TEXT_TYPE.text);
        processed.push(rest);
    }

    return processed;
}

function processCommentValueForInput(tValue, mentions) {
    var result = [...tValue.matchAll(COMMENT_REGEX_EXP)];

    result.forEach((item) => {
        var mention = mentions.find((m) => m.userId === cleanUp(item[2]));
        tValue = tValue.replace(item[2], `[${mention.name}]${item[2]}`);
    });

    return tValue;
}

function createPortion(value, type) {
   return { type: type, value: value }
}