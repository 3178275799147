import React, { useState } from 'react';
import { MAX_CHAR_LIMIT, MAX_CHAR_LIMIT_TEXT } from './helpers/statusConstants';
import cx from 'classnames';

const WorkflowEdit = ({ workflow, handleWorkflowUpdate, setEditingWorkflow, editingWorkflow }) => {
    const [isValueChanged, setIsValueChanged] = useState(false);
    const [info, setInfo] = useState({
        show: false,
        message: ''
    });
    const handleCancelEdit = () => {
        setEditingWorkflow(undefined);
        setIsValueChanged(false);
    };

    const onWorkflowLabelChanged = (e) => {
        var { value } = e.target;

        var workflowCopy = { ...workflow };

        if (workflowCopy.id === workflow.id) {
            if (workflowCopy.name !== value) {
                setIsValueChanged(true);
            }
            workflowCopy.name = value;
            setEditingWorkflow(workflowCopy);
        }
    };

    const onEditContainerClicked = (event) => {
        //NOTE: This has been used to prevent passing the event to its parent 
        //to avoid unwanted collapsing of the workflow item.
        event.stopPropagation();
    }


    const handleInputKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleWorkflowUpdate();
        }
    };

    return (
        <div style={{width:"100%", marginTop:'8px', marginBottom:'8px' }}>
            <div className="customized-status-edit" onClick={onEditContainerClicked}>
                <input
                    defaultValue={workflow.name}
                    className="form-control mrnda-input-text"
                    maxLength={MAX_CHAR_LIMIT}
                    style={{ paddingLeft: '8px', paddingRight: '8px', marginLeft: '8px' }}
                    onKeyPress={handleInputKeyPress}
                    onChange={(e) => {
                        const { value } = e.target;

                        if (value.length >= MAX_CHAR_LIMIT) {
                            setInfo({
                                show: true,
                                message: MAX_CHAR_LIMIT_TEXT
                            });
                        } else {
                            setInfo({
                                show: false,
                                message: ''
                            });
                        }
                        onWorkflowLabelChanged(e);
                    }}
                />

                <div style={{ height: '40px', alignItems: 'center', display: 'flex' }} >
                    <span
                        className={cx('mrnda-icon-btn customized-status-edit-save', {
                            'authorization-disabled': !isValueChanged || editingWorkflow.name === ""
                        })}
                        onClick={handleWorkflowUpdate}>
                        <i className="fa fa-check"></i>
                    </span>

                    <span
                        className={'mrnda-icon-btn customized-status-edit-cancel'}
                        onClick={handleCancelEdit}>
                        <i className="fa fa-times"></i>
                    </span>
                </div>


            </div>

            {info.show === true && (
                <div style={{ flex: '3 0 100%', marginLeft: '16px' }} className="help-block">
                    {info.message}
                </div>
            )}
        </div>
    );
};

export default WorkflowEdit;
