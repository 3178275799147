import React, { useEffect, useState } from 'react';
import { ConfirmationModal } from '../..';
import cx from 'classnames';
import WorkflowEdit from './WorkflowEdit';
import { withStatusManageConsumer } from './Context/StatusManageProvider';
import ForwardIcon from '../../_shared/Icon/ForwardIcon';
import DragLeftIcon from '../../_shared/Icon/DragLeftIcon';
import ChevronRightIcon from '../../_shared/Icon/ChevronRightIcon';
import BinIcon from '../../_shared/Icon/BinIcon';
import EditIcon from '../../_shared/Icon/EditIcon';
import './Workflow.scss';
import WorkflowConfigRow from './WorkflowConfigRow';
import ChevronDown from '../../_shared/Icon/ChevronDown';
import { withWorkflowManageConsumer } from './Context/WorkflowManageProvider';
import { useTheme } from '../../App/Theme/ThemeContext';
import chroma from 'chroma-js';

function WorkflowRow({
    workflow,
    editingWorkflow,
    setEditingWorkflow,
    onCommentStatusLabelChanged,
    onCheckChanged,
    handleDeleteWorkflow,
    validateCanDelete,
    visibleCommentStatuses,
    workflowStatuses,
    membersData,
    updateWorkflow,
    filteredMembers
}) {
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState();
    const [isExpanded, setIsExpanded] = useState(false);
    const {theme, themeColors} = useTheme();
    const deleteClicked = async () => {
        let canDelete = await validateCanDelete(workflow);
        if (canDelete === true) {
            setShowDeleteConfirmationModal(true);
        }
    };
    
    const deleteModalSecondaryAction = () => {
        setShowDeleteConfirmationModal(false);
    };

    const deleteModalPrimaryAction = async () => {
        setShowDeleteConfirmationModal(false);
        await handleDeleteWorkflow(workflow);
    };

    const onStatusEditClicked = (workflow) => {
        setEditingWorkflow(workflow);
    };

    const onHeaderClicked = () => {
        if (editingWorkflow !== undefined) {
            return;
        }
        setIsExpanded(!isExpanded);
    };
    
    // NOTE: To follow the design we need another shade of color and currently, our theme
    //       does not support any more color directly so we have build new shade dynamically.
    const backgroundColor = chroma(themeColors.primaryTextColor).alpha(0.05);

    return (
        <div
            caption={workflow.label}
            style={{ marginBottom: '12px' }}
            className="workflow-row"
            key={workflow.id}>
            

            <div className="workflow-row-content" style={{backgroundColor : backgroundColor }}>
                <div className="workflow-row-content-collapsed" onClick={onHeaderClicked}>
                    <div
                        className={cx({
                            '': true,
                            '': true,
                            'authorization-disabled':
                                editingWorkflow !== undefined && workflow.id !== editingWorkflow?.id
                        })}
                        style={{ flex: 1 }}>
                        {editingWorkflow !== undefined &&
                        (editingWorkflow.id === workflow.id) === true ? (
                            <WorkflowEditHOC
                                {...{
                                    workflow,
                                    onCommentStatusLabelChanged
                                }}
                            />
                        ) : (
                            <span
                                style={{ marginBottom: '0px' }}
                                className="workflow-row-name"
                                title={workflow.name}>
                                {workflow.name}
                            </span>
                        )}
                    </div>

                    <div style={{height:'100%'}}>
                    <div
                        className={cx({
                            'workflow-row-actions-host': true,
                            'authorization-disabled': editingWorkflow !== undefined
                        })}>
                        <a
                            title="Edit Status"
                            onClick={(e) => {
                                e.stopPropagation();
                                onStatusEditClicked(workflow);
                            }}>
                            <EditIcon />
                        </a>

                        <a
                            style={{ marginLeft: '20px' }}
                            title="Delete"
                            onClick={(e) => {
                                e.stopPropagation();
                                deleteClicked();
                            }}>
                            <BinIcon />
                        </a>

                        <div
                            style={{ marginLeft: '20px', marginTop: '1px' }}
                            className="icon-separator"></div>

                        <a style={{ marginLeft: '20px' }}>
                            <ChevronRightIcon
                                className={cx('workflow-row-action-icon', {
                                    'workflow-row-action-icon-expanded': isExpanded
                                })}
                            />
                        </a>
                    </div>
                    </div>
                </div>

                {isExpanded === true && (
                    <>
                        {workflowStatuses.length > 0 ? (
                            <>
                                <hr className="workflow-row-content-hr" />
                                <div
                                    className={cx('workflow-row-content-expanded', {
                                        'authorization-disabled': editingWorkflow !== undefined
                                    })}>
                                    <WorkflowConfigRow
                                        workflowStatuses={workflowStatuses}
                                        workflowStatus={undefined}
                                        isHeader={true}
                                        workflow={workflow}
                                        maxStatusCount={workflowStatuses.length}
                                        updateWorkflow={updateWorkflow}
                                    />
                                    <hr className="workflow-row-content-hr" />
                                    {workflow.statuses.map((workflowStatus, index) => {
                                        let isLast = index === workflowStatuses.length - 1;
                                        return (
                                            <div
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                                key={index}>
                                                <WorkflowConfigRow
                                                    workflow={workflow}
                                                    workflowStatus={workflowStatus}
                                                    membersData={membersData}
                                                    updateWorkflow={updateWorkflow}
                                                    filteredMembers={filteredMembers}
                                                />
                                                {isLast !== true && (
                                                    <hr className="workflow-row-content-hr" />
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        ) : (
                            <div style={{ 
                                display : 'flex',
                                padding : '16px'                                  
                            }}>
                                <span>There is no workflow status to configure, Please add workflow statuses from the workflow status tab</span>
                            </div>
                        )}
                    </>
                )}
            </div>
            
            {showDeleteConfirmationModal && (
                <ConfirmationModal
                    show
                    onHide={deleteModalSecondaryAction}
                    onPrimaryClick={deleteModalPrimaryAction}
                    onSecondaryClick={deleteModalSecondaryAction}
                    primaryButtonText="Yes"
                    secondaryButtonText="No"
                    title="Delete workflow"
                    bodyText={`Are you sure you want to delete ?`}
                />
            )}
        </div>
    );
}

const WorkflowEditHOC = withWorkflowManageConsumer(WorkflowEdit);

export default WorkflowRow;
