//@ts-check
import React, { useEffect, useState } from 'react';
import { drawingConstants } from '@wunderman-thompson/miranda-drawing';
import { getReadableTimeRange } from '../../../VideoAnnotation/VideoAnnotationUtil';
import { contributorsHelper, utilService } from '../../../../../_helpers';
import PinIcon from '../../../../_shared/PinIcon';
const MAX_ICONS = 8;
import Highlight from '../../../../_shared/Icon/Highlight';
import Strikeout from '../../../../_shared/Icon/Strikeout';
import TextHighlightIcon from '../../../../_shared/Icon/TextHighlightIcon';
import DesktopBreakpoint from '../../../../responsive/DesktopBreakpoint';
import { useScreenSizes } from '../../../../responsive/useScreenSizes';
import { PROOF_TYPE } from '../../../proof.constants';
import ReplyIcon from '../../../../_shared/Icon/ReplyIcon';
import { secondarySmallTextStyle } from '../../../../_shared/Styles/TextStyles';
import AnnotationIcon from '../../../../_shared/Icon/AnnotationIcon';
import AttachmentIcon from '../../../../_shared/Icon/AttachmentIcon';



const MetadataTimeRange = ({ frameRange, frameRate, classes }) => {
    if (frameRange[0] === frameRange[1]) {
        return (
            <span className={classes}>
                <span>{getReadableTimeRange(frameRange[0], frameRate)}</span>
                <DesktopBreakpoint>
                    <span> | Frame {frameRange[0]}</span>
                </DesktopBreakpoint>
            </span>
        );
    } else {
        return (
            <span className={classes}>
                <span>
                    {getReadableTimeRange(frameRange[0], frameRate)} -{' '}
                    {getReadableTimeRange(frameRange[1], frameRate)}
                </span>
                <DesktopBreakpoint>

                    <span>
                        {' '}|{' '}Frame {frameRange[0]} - {frameRange[1]}
                    </span>
                </DesktopBreakpoint>
            </span>
        );
    }
};

const MetadataPageNumber = ({ page, classes, annotations }) => {
    const [isMulti, setIsMulti] = useState(false);

    useEffect(() => {
        if (annotations && annotations.length > 1) {
            var single = annotations.every((v) => v.page === annotations[0].page);
            setIsMulti(!single);
        }
    }, [annotations]);
    {
        return (
            <span className={classes}>
                {isMulti === true ? <span>Multipage</span> : <span>Page {page}</span>}
            </span>
        );
    }
};

const MetadataUrl = ({ url, classes, annotations }) => {
    const [isMulti, setIsMulti] = useState(false);    
    useEffect(() => {
        if (annotations && annotations.length > 1) {
            var single = annotations.every((v) => v.page === annotations[0].page);
            setIsMulti(!single);
        }
    }, [annotations]);
    {
        return (
            <span className={classes} title={url} >
                {isMulti === true ? <span> </span> : <span className='text-truncate' >{`URL : ${url}`}</span>}
            </span>
        );
    }
};

const MetadataAttachments = ({ attachments }) => {
    const { isMobile } = useScreenSizes();
    let pureRepliesCount = attachments ? attachments.length : 0;
    let attachmentText = isMobile ? `${pureRepliesCount} attachments` : `${pureRepliesCount} ${pureRepliesCount === 1 ? 'Attachment' : 'Attachments'}`;
    return (
        pureRepliesCount > 0 && (
            <span className="comment__metadata__attachments__item">
                <DesktopBreakpoint>
                    <i className="fas fa-paperclip" />
                </DesktopBreakpoint>
                <span>{isMobile ? attachmentText : attachments.length}</span>
            </span>
        )
    );
};

const MetadataAnnotationCount = ({ annotations }) => {
    const { isMobile } = useScreenSizes();
    let annotationCount = annotations ? annotations.length : 0;
    let attachmentText = `${annotationCount} annotations`;
    return (
        annotationCount > 0 && (
            <span className="comment__metadata__attachments__item">
                <span>{isMobile ? attachmentText : annotations.length}</span>
            </span>
        )
    );
};

const MetadataResponses = ({ replies }) => {
    const { isMobile } = useScreenSizes();
    let pureReplies = replies && replies.filter((x) => x.replyType === 1 && x.isActive === true);
    let pureRepliesCount = pureReplies ? pureReplies.length : 0;
    let replyText = isMobile ? `${pureRepliesCount} replies` : `${pureRepliesCount} ${pureRepliesCount === 1 ? 'Reply' : 'Replies'}`;
    return (
        (isMobile || pureRepliesCount > 0) && (
            <span className="comment__metadata__replies__item">
                <span>{replyText}</span>
            </span>
        )
    );
};

const MetadataAnnotationIcon = ({ annotationType, color, annotationObject }) => {
    const { ANNOTATION_TYPE } = drawingConstants;
    const [annotationColor, setAnnotationColor] = useState();
    useEffect(() => {
        var annotation = JSON.parse(annotationObject);
        var key = '';
        if (annotationType === ANNOTATION_TYPE.commentStamp.typeName) {
            key = annotation.tintColor;
        } else {
            key = annotation.stroke;
        }

        var converted = contributorsHelper.convertKeyToColor(key);
        setAnnotationColor(converted);
    }, []);

    switch (ANNOTATION_TYPE[annotationType].typeName) {
        case ANNOTATION_TYPE.highlight.typeName:
            return (
                <span
                    style={{ color: annotationColor }}
                    className="annotation-type-icon"
                    title={ANNOTATION_TYPE[annotationType]?.name}>
                    <Highlight width={12} height={12} color={color} />
                </span>
            );

        case ANNOTATION_TYPE.textHighlight.typeName:
            return (
                <span
                    style={{ color: annotationColor }}
                    className="annotation-type-icon"
                    title={ANNOTATION_TYPE[annotationType]?.name}>
                    <TextHighlightIcon width={12} height={12} color={color} style={{ marginTop: -5 }} />
                </span>
            );

        case ANNOTATION_TYPE.pinPointer.typeName:
            return (
                <span
                    style={{ color: annotationColor }}
                    className="annotation-type-icon"
                    title={ANNOTATION_TYPE[annotationType]?.name}>
                    <PinIcon width={16} height={16} color={color} />
                </span>
            );

        case ANNOTATION_TYPE.strikeOut.typeName:
            return (
                <span
                    style={{ color: annotationColor }}
                    className="annotation-type-icon"
                    title={ANNOTATION_TYPE[annotationType]?.name}>
                    <Strikeout width={16} height={16} color={color} />
                </span>
            );
        default:
            return (
                <span
                    style={{ color: annotationColor }}
                    className="annotation-type-icon"
                    title={ANNOTATION_TYPE[annotationType]?.name}>
                    <i className={ANNOTATION_TYPE[annotationType]?.icon}></i>
                </span>
            );
    }
};

const MetadataAnnotationIconList = ({ annotations, commentColor }) => {
    return (
        <div className="metadata-icons-list">
            {annotations.slice(0, MAX_ICONS).map((annotation) => {
                return (
                    <span key={annotation.id} style={{ marginRight: '4px' }}>
                        <MetadataAnnotationIcon
                            color={commentColor}
                            annotationType={annotation.annotationType}
                            annotationObject={annotation.annotationObject}
                        />
                    </span>
                );
            })}
            {annotations.length > MAX_ICONS && (
                <div style={{ height: '16px', width: '16px', display: 'inline' }}>
                    +{annotations.length - MAX_ICONS}
                </div>
            )}
        </div>
    );
};

const CommentReplyCount = ({ replies }) => {

    let pureReplies = replies && replies.filter((x) => x.replyType === 1 && x.isActive === true);
    let pureRepliesCount = pureReplies ? pureReplies.length : 0;
    return (
        (
            <div style={secondarySmallTextStyle} className="comment__metadata__replies__item">
                <ReplyIcon />
                <span style={{ marginLeft: '4px' }}>{pureRepliesCount}</span>
            </div>
        )
    );
};

const CommentAnnotationCount = ({ annotations }) => {

    let annotationCount = annotations ? annotations.length : 0;
    return (
        (
            <div style={secondarySmallTextStyle} className="comment__metadata__replies__item">
                <AnnotationIcon />
                <span style={{ marginLeft: '4px' }}>{annotationCount}</span>
            </div>
        )
    );
};

const CommentAttachmentCount = ({ attachments }) => {

    let pureRepliesCount = attachments ? attachments.length : 0;
    return (
        pureRepliesCount > 0 && (
            <div style={secondarySmallTextStyle} className="comment__metadata__attachments__item">
                <AttachmentIcon />
                <span style={{ marginLeft: '4px' }}>{pureRepliesCount}</span>
            </div>
        )
    );
};

const MetadataCommentPosition = ({ proofVersion, rangeSelectorValue, commentItem, editMode, metadata, ...rest }) => {

    return (
        <div {...rest} className="comment__metadata__time-range">
            {proofVersion.mediaType === 2 || proofVersion.mediaType === 4 ? (
                <MetadataTimeRange
                    frameRange={editMode === true ? rangeSelectorValue : commentItem.frameRange}
                    classes="comment__metadata__time-range__item"
                    frameRate={metadata.frameRate}
                />
            ) : (

                utilService.isWebsiteProof(proofVersion)
                    ?   <MetadataUrl url={ utilService.isHTMLLiveProof(proofVersion) ? commentItem.htmlLivePageInfo.url : utilService.getOnlyLastPart(commentItem.htmlLivePageInfo.url) }
                        annotations={commentItem.annotations}
                        classes="comment__metadata__time-range__item text-truncate" />
                    : <MetadataPageNumber page={commentItem.page}
                        annotations={commentItem.annotations}
                        classes="comment__metadata__time-range__item" />

            )}
        </div>
    );
};

export {
    MetadataAnnotationIcon,
    MetadataResponses,
    MetadataPageNumber,
    MetadataTimeRange,
    MetadataAttachments,
    MetadataAnnotationIconList,
    MetadataUrl,
    MetadataCommentPosition,
    MetadataAnnotationCount,
    CommentReplyCount,
    CommentAnnotationCount,
    CommentAttachmentCount
};
