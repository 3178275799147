import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import ExtensionBackdrop from '../Icon/ExtensionBackground/ExtensionBackdrop';
import CloseIcon from '../Icon/CloseIcon';
import ModalHeader from './ModalHeader';

function ExtensionModal({ show, onSecondaryClick, onPrimaryClick }) {
    return (
        <Modal
            show={show}
            onHide={onSecondaryClick}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={'static'}
            scrollable>
            <Modal.Header
                style={{
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: '12px',
                    paddingBottom: '12px'
                }}>                    
                <ModalHeader title={"Install browser extension"} primaryText={"Install"} onPrimaryClick={onPrimaryClick} onSecondaryClick={onSecondaryClick} />
            </Modal.Header>
            <Modal.Body style={{ padding: '0px' }}>
                <div style={{ marginLeft: '20px', marginRight: '20px', marginTop: '16px' }}>
                    To make full use of our annotation tool, you need to install the Brandshare
                    Collaboration browser extension
                </div>
                <div
                    style={{
                        marginTop: '33px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <ExtensionBackdrop />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ExtensionModal;
