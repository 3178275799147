import React, { useRef, useState, useEffect, useCallback } from 'react';
import CustomEmailTemplateBuilderRow from './CustomEmailTemplateBuilderRow';
import { Button } from 'react-bootstrap';
import { TemplateUploader } from './TemplateUploader';
import some from '../../../assets/lib/Common/jquery.richtext';
import './CustomEmailTemplate.scss';
import EmailHeaderFooterBuilderComponent from './EmailHeaderFooterBuilderComponent';
import CustomEmailTemplateFilePreviewer from './CustomEmailTemplateFilePreviewer';

export const RichTextConfig = {
    imageUpload: false,
    fileUpload: false,

    // media
    videoEmbed: false,
    urls: false,

    // tables
    table: false,

    // code
    removeStyles: false,
    code: false,
    bold: false,
    italic: false,
    underline: false,

    // text alignment
    leftAlign: false,
    centerAlign: false,
    rightAlign: false,
    justify: false,

    // lists
    ol: false,
    ul: false,

    // title
    heading: false,

    // fonts
    fonts: false,
    fontColor: false,
    fontSize: false
};

const ALLOWED_DIMENSIONS = {
    width: 600,
    height: 125
};
const TemplateFilePreviewer = ({ src, showActions, onRemove }) => {
    
    return (
        <div className='custom-template-file-previewer'>
            
            <img src={src} />
            {showActions &&
                <Button style={{marginTop : '12px'}} variant="secondary" onClick={onRemove}>
                    Remove
                </Button>
            }
        </div>
    );
};


const SignatureBuilderComponent = ({ emailSettings, setEmailSettings, submitValidation }) => {
    const descriptionRef = useRef();

    useEffect(() => {
        initRichtext();

        $('.richText-editor').on('keyup', signatureKeyUp);
        return () => {
            console.log('clean up');
        };
    }, []);

    const signatureKeyUp = (e) => {
        var val = $('#description').val().toString();
        console.log('Email signature changed', val);
        var settings;
        setEmailSettings((prev) => {
            prev.emailSignature = val;
            settings = { ...prev };
            return prev;
        });
        submitValidation(settings);
    };
    const initRichtext = () => {
        $('#description').richText(RichTextConfig);
        $('.richText-help').hide();
        $('.richText-toolbar').hide();
    };

    const onSignatureChanged = (e) => {
        // let value = $('#description').val().toString();
        // console.log(value);
        // var copiedSettings = { ...emailSettings };
        // copiedSettings.emailSignature = value;
        // setEmailSettings((prev) => copiedSettings);
    };

    return (
        <div className="custom-email-template-signature">
            <textarea
                ref={descriptionRef}
                id="description"
                className="form-control mrnda-input-textarea"
                style={{ resize: 'none', padding: 0 }}
                name="Email Signature"
                onBlur={() => {
                    console.log('text area focus leave');
                }}
                value={emailSettings.emailSignature}
            />
        </div>
    );
};

const CustomEmailTemplateBuilder = ({
    templatesUploaderRef,
    setEmailSettings,
    emailSettings,
    submitValidation
}) => {
    return (
        <div className="custom-template-builder">
            {/* <CustomEmailTemplateBuilderRow
                {...{ isHeaderRow: true, placeHolder: 'Placeholder', value: 'Value' }}
            /> */}

            <CustomEmailTemplateBuilderRow
                {...{
                    isHeaderRow: false,
                    placeHolder: 'Header',
                    value: EmailHeaderFooterBuilderComponent,
                    templatesUploaderRef: templatesUploaderRef,
                    emailSettings: emailSettings,
                    setEmailSettings: setEmailSettings,
                    settingPropName: 'customTemplateHeaderUrl',
                    required: true
                }}
            />
        
            <CustomEmailTemplateBuilderRow
                {...{
                    isHeaderRow: false,
                    placeHolder: 'Email Signature',
                    value: SignatureBuilderComponent,
                    emailSettings: emailSettings,
                    setEmailSettings: setEmailSettings,
                    submitValidation: submitValidation,
                    required: true
                }}
            />

            <CustomEmailTemplateBuilderRow
                {...{
                    isHeaderRow: false,
                    placeHolder: 'Footer',
                    value: EmailHeaderFooterBuilderComponent,
                    templatesUploaderRef: templatesUploaderRef,
                    emailSettings: emailSettings,
                    setEmailSettings: setEmailSettings,
                    settingPropName: 'customTemplateFooterUrl',
                    required: true
                }}
            />
        </div>
    );
};

export default CustomEmailTemplateBuilder;
