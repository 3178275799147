import { authHeader, handleResponse, config } from '../../_helpers';

export const apiAccessService = {
    getAll,
    add,
    delete: _delete,
    validateToken,
    isNameUnique,
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${config.apiUrl}/apiaccess/getall`, requestOptions).then(handleResponse);
}

function add(param) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(param),
    };
    return fetch(`${config.apiUrl}/apiaccess/add`, requestOptions).then(handleResponse);
}

function _delete(param) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${config.apiUrl}/apiaccess/delete/${param}`, requestOptions).then(handleResponse);
}

async function isNameUnique(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject),
    };

    return fetch(`${config.apiUrl}/apiaccess/isunique`, requestOptions).then(handleResponse);
}

function validateToken(param) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + param.tokenValue, 'Content-Type': 'application/json' },
        body: JSON.stringify(param),
    };
    return fetch(`${config.apiUrl}/apiaccess/validatetoken`, requestOptions).then(handleResponse);
}
