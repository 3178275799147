export const annotationReportConstants = {

    PROOFSUMMARY_GETVIDEOPROOFVERION_REQUEST: 'PROOFSUMMARY_GETPROOFVERION_REQUEST',
    PROOFSUMMARY_GETVIDEOPROOFVERION_SUCCESS: 'PROOFSUMMARY_GETPROOFVERION_SUCCESS',
    PROOFSUMMARY_GETVIDEOPROOFVERION_FAILURE: 'PROOFSUMMARY_GETPROOFVERION_FAILURE',

    PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_REQUEST: 'PROOFSUMMARY_ANNOTATIONCOMMENTS_REQUEST',
    PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_SUCCESS: 'PROOFSUMMARY_ANNOTATIONCOMMENTS_SUCCESS',
    PROOFSUMMARY_VIDEOANNOTATIONCOMMENTS_FAILURE: 'PROOFSUMMARY_ANNOTATIONCOMMENTS_FAILURE',

    PROOFSUMMARY_DOWNLOADPAGE_REQUEST: 'PROOFSUMMARY_DOWNLOADPAGE_REQUEST',
    PROOFSUMMARY_DOWNLOADPAGE_SUCCESS: 'PROOFSUMMARY_DOWNLOADPAGE_SUCCESS',
    PROOFSUMMARY_DOWNLOADPAGE_FAILURE: 'PROOFSUMMARY_DOWNLOADPAGE_FAILURE',
};


