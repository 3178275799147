// import 'rc-tree/assets/index.css';
import React, { Component, useState } from 'react';
import Slider from 'rc-slider';
// import ReactSlider from 'react-slider'

import 'rc-slider/assets/index.css';
import './RangeSelector.scss';
import { utilService } from '../../../_helpers';
import ReactSlider from './ReactSlider';

// const Range = Slider.Range;
const RangeSelector = ({ leftIcon, rightIcon, ...props}) => {
    // console.log('Rangeselector props', props);
    return (
        // <Range
        //     allowCross={false}
        //     defaultValue={[0, 0]}
        //     value={props.value}
        //     min={0}
        //     max={props.maxValue < 1 ? 1 : props.maxValue}
        //     onBeforeChange={(e)=>console.log(e)}
        //     onChange={props.onChange}
        // />
        <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            // snapDragDisabled
            // defaultValue={[10,11]}
            value={props.value}
            min={0}
            max={props.maxValue < 1 ? 1 : props.maxValue}
            ariaLabel={['Lower thumb', 'Upper thumb']}
            ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
            renderThumb={(props, state) => <div {...props}> {props.key === 'example-thumb-1'? rightIcon : leftIcon}</div>}            
            renderTrack={(props, state) => <div {...props}> {props.key === 'example-track-1' && <div className='example-track-1-selected-area'></div>} </div>}
            pearling
            onThumbClick={props.onThumbCLick}
            onSliderClick={(value) => console.log('SLIDER_CLICK', value)}
            onAfterChange={props.onChange}
            disableSliderClick = {props.disableSliderClick}
            onDragging = {props.onDragging}
            minDistance={0}
        />
    );
};

export { RangeSelector };
