export const apiAccessConstants = {
    GETALL_REQUEST: 'API_ACCESS_GETALL_REQUEST',
    GETALL_SUCCESS: 'API_ACCESS_GETALL_SUCCESS',
    GETALL_FAILURE: 'API_ACCESS_GETALL_FAILURE',

    ADD_REQUEST: 'API_ACCESS_ADD_REQUEST',
    ADD_SUCCESS: 'API_ACCESS_ADD_SUCCESS',
    ADD_FAILURE: 'API_ACCESS_ADD_FAILURE',
    
    DELETE_REQUEST: 'API_ACCESS_DELETE_REQUEST',
    DELETE_SUCCESS: 'API_ACCESS_DELETE_SUCCESS',
    DELETE_FAILURE: 'API_ACCESS_DELETE_FAILURE',
    
    IS_NAME_UNIQUE_REQUEST: 'API_ACCESS_IS_NAME_UNIQUE_REQUEST',
    IS_NAME_UNIQUE_SUCCESS: 'API_ACCESS_IS_NAME_UNIQUE_SUCCESS',
    IS_NAME_UNIQUE_FAILURE: 'API_ACCESS_IS_NAME_UNIQUE_FAILURE',
};
