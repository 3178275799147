//@ts-check
import React, { useState, useCallback, useRef, useEffect } from 'react';
import cx from 'classnames';
import { utilService, authorizationService, colorUtilService } from '../../../../../_helpers';
import ReplyItem from './ReplyItem';
import AttachmentViewer from './AttachmentsViewer';
import { documentAnnotationConstants } from '../../Context/documentAnnotation.constants';
import {
    MetadataResponses,
    MetadataAttachments,
    MetadataCommentPosition
} from './MetadataComponents';
import { documentCommentService } from '../../documentComment.service';
import { videoCommentService } from '../../../VideoAnnotation/videoComment.service';
import { bringToViewSmooth } from '../../DocumentAnnotationUtil';
import { authorizationConstants } from '../../../../_shared';
import { AttachmentModal } from './AttachmentModal';
import { AttachmentsUploader } from './AttachmentsUploader';
import { drawingConstants } from '@wunderman-thompson/miranda-drawing';
import { contributorsHelper, config } from '../../../../../_helpers';
import MentionLabel from './MentionLabel';
import MirandaMentionInput from './MirandaMentionInput';
import { mentionHelper } from '../../../../../_helpers/mentionHelper';
import { commentHelper } from '../../../../../_helpers/commentHelper';
import { CommentBox } from './CommentBox';
import { overlapPercentage } from '../../../../../_helpers/scrollHelper';

import { useScreenSizes } from '../../../../responsive/useScreenSizes';
import DesktopBreakpoint from '../../../../responsive/DesktopBreakpoint';
import PhoneBreakpoint from '../../../../responsive/PhoneBreakpoint';
import { useTheme } from '../../../../App/Theme/ThemeContext';
import CommentItemFooter from './CommentItemFooter';
import CommentFooterActions from './CommentFooterActions';
import { CommentStats } from './CommentStats';
import { CommentItemHeader } from './CommentItemHeader';
import { AddGroupAnnotation } from './AddGroupAnnotation';
import { isRegularNav } from '../../../../../_helpers/navbarUtil';
const ARROW_SPACE_TOP = 30;

const SCROLL_MODE = {
    userInitiated: 'userInitiated',
    bringToViewSmooth: 'bringToViewSmooth'
};

const CommentItem = ({
    commentItem,
    isCommentAddMode,
    peekToPage,
    onCommentReply,
    onCommentDelete,
    onCommentSetStatus,
    onCommentClicked,
    isSelected,
    isGroupSelected,
    // documentMetadata,
    // videoMetadata,
    metadata,
    proofVersion,
    refreshComments,
    index,
    contributors,
    avatarColor,
    annotationColor,
    userAnnotationColor,
    onAttachmentDelete,
    getAttachment,
    userId,
    setArrowPoint2,
    setCommentArrowPointFinders,
    isVisible,
    setSelectedComment,
    side,
    isProofCompare,
    setAnnotationComments,
    annotationComments,
    updateCommentContent,
    updateReplyContent,
    editAllowedComments,
    editAllowedReplies,
    setEditAllowedReplies,
    onAnnotationMerge,
    cancelMergeAnnotation,
    isCommentMerge,
    isComposite,
    selectedAnnotation,
    setEditingCommentId,
    clearShapeTools,
    setRangeSelectorValue,
    rangeSelectorValue,
    updateAnnotationMarker,
    commentStatuses,
    selectedComment,
    releaseCommentSelection,
    clearCommentSelection,
    selectedCanvas,
    hasExtraTopBar
}) => {
    const { COMMENT_STATUS } = documentAnnotationConstants;
    const onCommentClick = () => {
        if (isCommentAddMode) {
            return;
        }
        onCommentClicked(commentItem);
    };

    const [commentReply, setcommentReply] = useState('');
    const [showUserModal, setShowUserModal] = useState(false);
    const [clickedAttachment, setClickedAttachment] = useState();
    const [attachments, setAttachments] = useState(null);
    const [uploadedAttachments, setUploadedAttachments] = useState(null);
    const [aValue, setAValue] = useState('');
    const [truncate, setTruncate] = useState(true);
    const attachmentsRef = useRef(null);
    const uploadHandlersRef = useRef([]);
    const uploadedAttachmentsRef = useRef(null);
    const indexRef = useRef(0);
    // const [index, setIndex] = useState(0);

    const commentReplyRef = useRef('');
    const mentionDataRef = useRef({});

    const commentArea = useRef(null);
    const [commentColor, setCommentColor] = useState();
    const [fontColor, setFontColor] = useState();
    const [replyFontColor, setReplyFontColor] = useState();
    const [editedCommentText, setEditedCommentText] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editAllowed, setEditAllowed] = useState(false);
    const [isCommentStatusUpdating, setIsCommentStatusUpdating] = useState(false);

    const { ANNOTATION_TYPE } = drawingConstants;
    const scrollModeRef = useRef(SCROLL_MODE.userInitiated);
    const { isMobile } = useScreenSizes();
    const { theme, themeColors } = useTheme();
    useEffect(() => {
        setCommentArrowPointFinders(commentItem.id, calculateCommentArrowPoint);
        colorHelper();
    }, []);

    const colorHelper = () => {
        var color = contributorsHelper.findCommentColor(commentItem.annotations[0]);
        setCommentColor(color);
        var fontCol = contributorsHelper.findFontColor(color);
        var repCol = contributorsHelper.findFontColor(userAnnotationColor);

        setFontColor(fontCol);
        setReplyFontColor(repCol);
    };
    useEffect(() => {
        attachmentsRef.current = attachments;
    }, [attachments]);

    useEffect(() => {
        var exists = editAllowedComments.some((x) => x === commentItem.id);
        setEditAllowed(exists);
    }, [editAllowedComments]);

    useEffect(() => {
        if (commentItem === null) {
            return;
        }
        colorHelper();
    }, [commentItem.annotations[0]]);

    useEffect(() => {
        if (editMode === true) {
            if (commentItem.mentions.length > 0) {
                var converted = mentionHelper.processCommentValueForInput(
                    commentItem.content,
                    commentItem.mentions
                );
                setEditedCommentText(converted);
            } else {
                setEditedCommentText(commentItem.content);
            }
        }
    }, [editMode]);

    useEffect(() => {
        setcommentReply(aValue);
    }, [aValue]);
    useEffect(() => {
        var repCol = contributorsHelper.findFontColor(userAnnotationColor);
        setReplyFontColor(repCol);
    }, [userAnnotationColor]);

    useEffect(() => {
        uploadedAttachmentsRef.current = uploadedAttachments;
    }, [uploadedAttachments]);

    useEffect(() => {
        commentReplyRef.current = commentReply;
    }, [commentReply]);
    const handleChange = (event) => {
        var { name, value } = event.target;
        setcommentReply(value);
    };

    useEffect(() => {
        if (!isVisible && isSelected) {       
            clearCommentSelection(commentItem.page - 1);
        }
    }, [isVisible]);

    const calculateCommentArrowPoint = (fromScroll, bounds) => {
        if (!fromScroll) {
            scrollModeRef.current = SCROLL_MODE.bringToViewSmooth;
            bringToViewSmooth(commentArea.current);
            utilService.timeout(300).then((x) => {
                scrollModeRef.current = SCROLL_MODE.userInitiated;
            });
        }

        //NOTE: compare toolbar height

        let toolbarHeight = isProofCompare === true ? 116 : 0;

        var points = commentArea.current.getBoundingClientRect();
        let top = points.top - toolbarHeight;

        if (bounds && scrollModeRef.current === SCROLL_MODE.userInitiated) {
            var elem = commentArea.current;
            var docViewTop = bounds.top - toolbarHeight;
            var docViewBottom = docViewTop + bounds.height;

            var elemTop = top;
            var elemBottom = elemTop + elem.offsetHeight;
            var some = overlapPercentage([docViewTop, docViewBottom], [elemTop, elemBottom]);

            if (some <= 0) {
                setSelectedComment(-1);
                return;
            } else if (some < 100) {
                if (elemTop < docViewTop && docViewTop - elemTop > ARROW_SPACE_TOP) {
                    top = docViewTop - ARROW_SPACE_TOP;
                }
            }
        }
        
        var navHeight = hasExtraTopBar ? 60 : 0;  

        setArrowPoint2({
            x: side === 'left' ? points.right : points.left,
            y: top + ARROW_SPACE_TOP - navHeight
        });
    };

    const onAttachmentClicked = (item, attachmentFor) => {
        var attachment = { ...item };
        attachment.attachmentFor = attachmentFor;
        setClickedAttachment(attachment);
        setShowUserModal(true);
    };

    const handleOnCommentReply = useCallback(() => {
        var mentionRes = mentionHelper.processInputValue(aValue);
        mentionDataRef.current = mentionRes;
        commentReplyRef.current = mentionRes.tValue;
        console.log(mentionRes);

        var uploadHandler = uploadHandlersRef.current[indexRef.current];
        if (uploadHandler) {
            uploadHandler(indexRef.current);
        } else {
            postReply();
        }
    }, [commentReply]);

    const postReply = async () => {
        var replyItem = {
            commentId: commentItem.id,
            reply: commentReplyRef.current,
            mentionURL: metadata.mentionURL
        };
        onCommentReply(replyItem, uploadedAttachmentsRef.current, mentionDataRef.current.mentions);
        setAValue('');
        resetAttachments();
    };

    const resetAttachments = () => {
        indexRef.current = 0;
        setAttachments(null);
        uploadHandlersRef.current = [];
        setUploadedAttachments(null);
        uploadedAttachmentsRef.current = [];
    };

    const updateUploadHandlers = (handler) => {
        var newArr = [...uploadHandlersRef.current, handler];
        uploadHandlersRef.current = newArr;
    };
    const onUploadComplete = (uploadedNow, attachmentNo) => {
        var prev = uploadedAttachmentsRef.current === null ? [] : uploadedAttachmentsRef.current;
        uploadedAttachmentsRef.current = [...prev, uploadedNow];

        var newIndex = indexRef.current + 1;
        indexRef.current = newIndex;
        var uploadHandler = uploadHandlersRef.current[newIndex];

        if (uploadHandler) {
            uploadHandler(newIndex);
        } else {
            postReply();
        }
    };

    const onRemoveAttachment = (index) => {
        var attachmentsCopy = attachments.map((attachment) => {
            var newAttachment = { ...attachment };
            return newAttachment;
        });

        attachmentsCopy.splice(index, 1);
        uploadHandlersRef.current.splice(index, 1);
        setAttachments(attachmentsCopy);
    };

    const handleOnUpdateComment = async (comment, mentions = null) => {
        var commentUpdatePayload = {
            proofId: commentItem.proofId,
            proofVersionId: commentItem.proofId,
            commentId: commentItem.id,
            content: comment,
            mentions: mentions,
            mentionURL: metadata?.mentionURL
        };

        //NOTE: ask about the update mechanism
        if (commentItem.frameRange) {
            if (
                commentItem.frameRange[0] !== rangeSelectorValue[0] ||
                commentItem.frameRange[1] !== rangeSelectorValue[1]
            ) {
                //frameRange changed
                commentUpdatePayload.frameRange = rangeSelectorValue;
            } else {
                commentUpdatePayload.frameRange = commentItem.frameRange;
            }
        }

        let response = await updateCommentContent(commentUpdatePayload);

        setEditMode(false);
        setEditingCommentId('');
        onCommentUpdateCompleted(response);
        setSelectedComment(-1);
    };

    const onCommentUpdateCompleted = (updatedComment) => {
        var modified = annotationComments.map((comment) => {
            return comment.id === updatedComment.id ? updatedComment : comment;
        });
        setAnnotationComments(modified);
        if (updateAnnotationMarker) {
            updateAnnotationMarker(updatedComment, modified);
        }
    };
    const onReplyDelete = async (reply) => {
        var requestPayload = {
            id: reply.id
        };

        if (proofVersion.mediaType === 2 || proofVersion.mediaType === 4) {
            requestPayload.VideoCommentId = commentItem.id;
            await videoCommentService.deleteVideoCommentReply(requestPayload);
        } else {
            requestPayload.documentCommentId = commentItem.id;
            await documentCommentService.deleteCommentReply(requestPayload);
        }

        await refreshComments();
        setSelectedComment(-1);
    };

    let isUserPermittedToAdd = authorizationService.isUserPermitted(
        authorizationConstants.ProofEditor.KEY,
        authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_COMMENT_ADD
    );
    let isUserPermittedToDelete = authorizationService.isUserPermitted(
        authorizationConstants.ProofEditor.KEY,
        authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_COMMENT_DELETE
    );

    let isUserPermittedToDeleteAny = authorizationService.isUserPermitted(
        authorizationConstants.ProofEditor.KEY,
        authorizationConstants.ProofEditor.ACTION_PROOF_EDITOR_COMMENT_DELETE_ANY
    );

    const canEdit = () => {
        var canEdit = editAllowedComments.some((x) => x === commentItem.id);
        if (!canEdit) {
            setEditAllowed(canEdit);
            return;
        }

        var currentDate = new Date();
        var createdDate = new Date(commentItem.createdAt);

        var def = currentDate - createdDate;

        var minutes = Math.floor(def / 60000);

        if (minutes > config.editThresholdInMinute) {
            setEditAllowed(false);
        }
    };

    const secondaryTextStyle = {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        color: 'var(--secondary-text-color)'
    };

    return (
        <div
            ref={commentArea}
            className={cx({
                'mrnda-comment-container': true,
                'd-none': !isVisible,
                'mrnda-comment-container--mobile': isMobile
            })}
            onClick={onCommentClick}>
            <div
                style={
                    isMobile
                        ? isSelected
                            ? { outline: `2px solid ${commentColor}`, border: 'none' }
                            : {}
                        : {}
                }
                className={cx({
                    comment: true,
                    'comment--selected': isSelected,
                    'comment--group-selected': isGroupSelected && !isMobile,
                    'comment--mobile': isMobile
                })}>
                <CommentItemHeader
                    commentColor={commentColor}
                    fontColor={fontColor}
                    editMode={editMode}
                    isCommentStatusUpdating={isCommentStatusUpdating}
                    setIsCommentStatusUpdating={setIsCommentStatusUpdating}
                    isMobile={isMobile}
                    primaryTextColor={themeColors.primaryTextColor}
                    isUserPermittedToAdd={isUserPermittedToAdd}
                    secondaryTextStyle={secondaryTextStyle}
                    commentItem={commentItem}
                    onCommentSetStatus={onCommentSetStatus}
                    metadata={metadata}
                    proofVersion={proofVersion}
                    rangeSelectorValue={rangeSelectorValue}
                    commentStatuses={commentStatuses}
                />
                <div
                    style={
                        isMobile
                            ? {
                                  display: 'flex',
                                  flexDirection: 'column',
                                  margin: '4px 0px 12px 64px'
                              }
                            : { display: 'flex', flexDirection: 'column' }
                    }
                    className="comment__body-wrapper">
                    <div
                        className={cx('comment__body', { 'comment__body--mobile': isMobile })}
                        style={{
                            background: editMode
                                ? 'var(--secondary-background-color)'
                                : 'var(--primary-background-color)'
                        }}>
                        {editMode ? (
                            <CommentBox
                                editMode={true}
                                commentText={editedCommentText}
                                handleOnUpdateComment={handleOnUpdateComment}
                                setCommentText={setEditedCommentText}
                                handleOnCancelComment={() => {
                                    setEditMode(false);
                                }}
                                setEditingCommentId={setEditingCommentId}
                                clearShapeTools={clearShapeTools}
                            />
                        ) : (
                            <MentionLabel
                                commentText={commentItem.content}
                                mentions={commentItem.mentions}
                            />
                        )}

                        {isSelected && (
                            <PhoneBreakpoint>
                                <AttachmentViewer
                                    attachments={commentItem.attachments}
                                    onAttachmentDelete={onAttachmentDelete}
                                    onAttachmentClicked={onAttachmentClicked}
                                    attachmentFor="comment"
                                    showDelete={commentHelper.canDelete(commentItem.createdById)}
                                />
                            </PhoneBreakpoint>
                        )}
                    </div>
                </div>
                <PhoneBreakpoint>
                    <div
                        className="comment__metadata-separator--mobile"
                        style={{
                            background: colorUtilService.changeOpacity(
                                themeColors.primaryTextColor,
                                0.1
                            )
                        }}></div>
                </PhoneBreakpoint>
                <DesktopBreakpoint>
                    <div
                        className="comment__metadata"
                        style={
                            isMobile
                                ? {
                                      margin: 0,
                                      padding: '12px 12px 0px 12px',
                                      ...secondaryTextStyle
                                  }
                                : {}
                        }>
                        <DesktopBreakpoint>
                            <MetadataCommentPosition
                                {...{
                                    proofVersion,
                                    rangeSelectorValue,
                                    commentItem,
                                    editMode,
                                    metadata
                                }}
                            />
                        </DesktopBreakpoint>

                        <div className="comment__metadata__replies">
                            <MetadataResponses replies={commentItem.replies} />
                        </div>
                        <div className="comment__metadata__replies">
                            <MetadataAttachments attachments={commentItem.attachments} />
                        </div>
                    </div>
                </DesktopBreakpoint>

                {!isSelected && (
                    <PhoneBreakpoint>
                        <CommentStats
                            isMobile={isMobile}
                            secondaryTextStyle={secondaryTextStyle}
                            commentItem={commentItem}></CommentStats>
                    </PhoneBreakpoint>
                )}

                {isSelected && (
                    <>
                        {isComposite === false ? (
                            <div>
                                <DesktopBreakpoint>
                                    <AttachmentViewer
                                        attachments={commentItem.attachments}
                                        onAttachmentDelete={onAttachmentDelete}
                                        onAttachmentClicked={onAttachmentClicked}
                                        attachmentFor="comment"
                                        showDelete={commentHelper.canDelete(
                                            commentItem.createdById
                                        )}
                                    />
                                </DesktopBreakpoint>

                                <div
                                    className="comment__replies-list"
                                    style={
                                        isMobile
                                            ? {
                                                  margin: '0px',
                                                  padding: '0px',
                                                  border: 'none',
                                                  paddingTop: '2px'
                                              }
                                            : {}
                                    }>
                                    {commentItem.replies
                                        .filter(
                                            (item) => item.replyType === 1 && item.isActive === true
                                        )
                                        .map((reply, index, array) => {
                                            var replyItem = { ...reply };

                                            if (contributors !== null) {
                                                avatarColor =
                                                    utilService.getUserAvatarPreferenceColor(
                                                        contributors,
                                                        replyItem.createdById
                                                    );
                                            }

                                            return (
                                                <ReplyItem
                                                    key={replyItem.id}
                                                    replyItem={replyItem}
                                                    onReplyDelete={onReplyDelete}
                                                    updateReplyContent={async (payload) => {
                                                        payload = {
                                                            ...payload,
                                                            mentionURL: metadata?.mentionURL
                                                        };
                                                        return await updateReplyContent(payload);
                                                    }}
                                                    avatarColor={avatarColor}
                                                    commentId={commentItem.id}
                                                    onAttachmentClicked={onAttachmentClicked}
                                                    onAttachmentDelete={onAttachmentDelete}
                                                    setAnnotationComments={setAnnotationComments}
                                                    annotationComments={annotationComments}
                                                    showAttachmentDelete={commentHelper.canDelete(
                                                        commentItem.createdById
                                                    )}
                                                    showContextMenu={!isProofCompare}
                                                    editAllowedReplies={editAllowedReplies}
                                                    setSelectedComment={setSelectedComment}
                                                    isLast={index === array.length - 1}
                                                />
                                            );
                                        })}
                                </div>

                                <div
                                    className={cx({
                                        'reply-form': true,
                                        'd-none': proofVersion.isLocked || !isUserPermittedToAdd
                                    })}
                                    style={isMobile ? { margin: '0px' } : {}}>
                                    <div>
                                        <div
                                            className="reply-form__body-wrapper"
                                            style={isMobile ? { margin: '0px' } : {}}>
                                            <DesktopBreakpoint>
                                                <div className="reply-form__avatar">
                                                    <span
                                                        style={{
                                                            background: userAnnotationColor,
                                                            color: replyFontColor
                                                        }}
                                                        className="mrnda-avatar--secondary--small">
                                                        {utilService.getUserInitial(
                                                            // proofVersion.mediaType === 2 || proofVersion.mediaType === 4
                                                            //     ? videoMetadata.currentUser?.fullName
                                                            //     : documentMetadata.currentUser?.fullName
                                                            metadata.currentUser?.fullName
                                                        )}
                                                    </span>
                                                </div>
                                            </DesktopBreakpoint>
                                            <div
                                                className="reply-form__body"
                                                style={
                                                    isMobile
                                                        ? {
                                                              margin: '0px',
                                                              width: '100%',
                                                              padding: '20px 20px 0px 20px'
                                                          }
                                                        : {}
                                                }>
                                                <MirandaMentionInput
                                                    placeholder={'Enter a response here...'}
                                                    aValue={aValue}
                                                    setAValue={setAValue}
                                                    inputFor="reply"
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className="reply-form__attachments-list"
                                            style={
                                                isMobile
                                                    ? {
                                                          margin: '0px 0px 0px 0px',
                                                          padding: '0px 20px'
                                                      }
                                                    : {}
                                            }>
                                            <AttachmentsUploader
                                                attachments={attachments}
                                                onUploadComplete={onUploadComplete}
                                                updateUploadHandlers={updateUploadHandlers}
                                                onRemoveAttachment={onRemoveAttachment}
                                            />
                                        </div>

                                        {isMobile ? (
                                            <CommentFooterActions
                                                {...{
                                                    commentReply,
                                                    attachments,
                                                    setAttachments,
                                                    editAllowed,
                                                    setSelectedComment,
                                                    handleOnCommentReply
                                                }}
                                            />
                                        ) : (
                                            <CommentItemFooter
                                                {...{
                                                    commentItem,
                                                    commentReply,
                                                    attachments,
                                                    setAttachments,
                                                    editAllowed,
                                                    setRangeSelectorValue,
                                                    setSelectedComment,
                                                    handleOnCommentReply,
                                                    onCommentDelete,
                                                    canEdit,
                                                    setEditMode,
                                                    editMode,
                                                    setEditingCommentId
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <AddGroupAnnotation
                                commentItem={commentItem}
                                onAnnotationMerge={onAnnotationMerge}
                                cancelMergeAnnotation={cancelMergeAnnotation}
                                length={selectedAnnotation.length}
                            />
                        )}
                    </>
                )}

                {showUserModal && (
                    <AttachmentModal
                        onClose={() => setShowUserModal(false)}
                        show={showUserModal}
                        attachment={clickedAttachment}
                        getAttachment={getAttachment}
                    />
                )}
            </div>
        </div>
    );
};

export { CommentItem };
