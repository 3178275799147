import { proofSummaryConstants } from './proofSummary.constants';
const {
    PROOFSUMMARY_GETPROOFVERION_REQUEST,
    PROOFSUMMARY_GETPROOFVERION_SUCCESS,
    PROOFSUMMARY_GETPROOFVERION_FAILURE,
    PROOFSUMMARY_ANNOTATIONCOMMENTS_REQUEST,
    PROOFSUMMARY_ANNOTATIONCOMMENTS_SUCCESS,
    PROOFSUMMARY_ANNOTATIONCOMMENTS_FAILURE,
    PROOFSUMMARY_DOWNLOADPAGE_REQUEST,
    PROOFSUMMARY_DOWNLOADPAGE_SUCCESS,
    PROOFSUMMARY_DOWNLOADPAGE_FAILURE,
} = proofSummaryConstants;
export function proofSummaryData(state = {}, action) {

    switch (action.type) {
        case PROOFSUMMARY_DOWNLOADPAGE_REQUEST:
        case PROOFSUMMARY_ANNOTATIONCOMMENTS_REQUEST:
        case PROOFSUMMARY_GETPROOFVERION_REQUEST:
            return {
                ...state
            };
        case PROOFSUMMARY_GETPROOFVERION_SUCCESS:
            return {
                ...state,
                proofVersion: action.proofVersion
            };

        case PROOFSUMMARY_DOWNLOADPAGE_SUCCESS:

            return {
                ...state,
                isCached: action.isCached,
                cachedDocumentId: action.cacheDocumentId
            };
        case PROOFSUMMARY_ANNOTATIONCOMMENTS_SUCCESS:

            return {
                ...state,
                pageAnnotationComments: action.annotationComments,
            };
        case PROOFSUMMARY_DOWNLOADPAGE_FAILURE:
        case PROOFSUMMARY_ANNOTATIONCOMMENTS_FAILURE:
        case PROOFSUMMARY_GETPROOFVERION_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        default:
            return state;
    }
}