import React, { useEffect, useState } from 'react';
import { supportService } from './support.service';
import { evaporateS3Config, s3Service } from '../_shared';
import { authHeader, config } from '../../_helpers';
import { Spinner } from 'react-bootstrap';

const SupportAttachment = ({ shouldUpload, file, index, onDeleteClicked, onUploadCompleted }) => {
    const [progressNow, setProgressNow] = useState(0);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        if (shouldUpload === true) {
            uploadFile();
        }
    }, [shouldUpload]);

    const getPreSignedUrl = async (fileName) => {
        var response = await supportService.getPreSignedUrl(fileName);
        return response;
    };

    const uploadFile = async () => {
        let res = await getPreSignedUrl(file.name);
        let { fileName } = res;

        let evaporateConfig = {
            ...evaporateS3Config,
            bucket: res.bucket,
            awsRegion: res.region,
            aws_key: res.accessKey,
            signerUrl: `${config.apiUrl}/support/SignerUrl`,
            signHeaders: { ...authHeader() }
        };
        await uploadToS3(file, fileName, evaporateConfig);
    };

    async function uploadToS3(file, uploadPath, s3Config) {
        try {
            //TODO: Need to handle errors in file upload

            let callbacks = {
                onProgressChange: (e) => {
                    setProgressNow(e.progressNow);
                },
                onFileUploadCompleted: () => {
                    console.log('File upload Completed');
                    setIsUploaded(true);
                    setIsUploading(false);
                    onUploadCompleted(uploadPath, file.id);
                }
            };
            setIsUploading(true);
            s3Service.uploadToS3(file.pickedFile, s3Config, callbacks, uploadPath);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div key={file.id} className="title" id={'filediv' + index} style={{display: 'flex', flexDirection: 'column'}}>
            <div>
            <span title={file.name} className="name text-truncate" id={'file' + index}>
                <i style={{ paddingRight: '5px' }} className="fa fa-paperclip"></i>
                {file.name}
            </span>
            <span
                className="_deleteButton"
                index="' + validFIleCount + '"
                style={{ float: 'right' }}
                onClick={() => {
                    onDeleteClicked(file.id);
                }}>
                {isUploading === true ? (
                    <Spinner style={{ paddingRight: '5px' }} className="upload-spinner" animation="grow" variant="dark" size="sm" />
                ) : (
                    <i style={{ paddingRight: '3px' }} className="fa fa-times"></i>
                )}
            </span>
            </div>

            {isUploaded === false && (
                <div
                    className="progress"
                    style={{
                        width: `${progressNow}%`,
                        backgroundColor: file.isUploaded === true ? 'green' : 'blue'
                    }}></div>
            )}
        </div>
    );
};

export default SupportAttachment;
