import React from 'react';
import { Redirect } from 'react-router-dom';
import { authorizationService } from '../../_helpers';

export const AuthorizationRoute = ({
    component: Component,
    loginPath,
    permissionKey,
    routeProps,
    bypassAuthorization,
}) => {
    if (
        bypassAuthorization ||
        (permissionKey &&
            authorizationService.isUserPermitted(permissionKey.module, permissionKey.action))
    ) {
        return <Component {...routeProps} />;
    } else {
        return <Redirect to={{ pathname: '/', state: { from: routeProps.location } }} />;
    }
};
