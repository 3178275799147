import React from 'react';
import ReactPaginate from 'react-paginate';
import './Pagination.css';

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 20,
            selectedPage: 0,
            pageCount: this.props.totalPages,
            startItem: 0,
            endItem: 0,
            totalRecords: 0,
        };
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        console.log('selected Page : ' + nextProps.selectedPage);

        const start = nextProps.selectedPage * this.state.pageSize + 1;
        const end = start + this.state.pageSize - 1;
        this.setState({
            selectedPage: nextProps.selectedPage,
            totalCount: nextProps.totalCount,
            startItem: start,
            endItem: end > nextProps.totalCount ? nextProps.totalCount : end,
            pageCount: nextProps.totalPages,
        });
    }
    handlePageSizeChange(event) {
        const pSize = parseInt(event.target.value, 10);
        this.setState({ pageSize: pSize }, () => {
            if (this.props.pageSizeChanged) {
                this.props.pageSizeChanged(pSize);
            }
        });
    }

    render() {
        return (
            <div className="clearfix pagination-container">
                {this.props.showCountText && (
                    <span className="float-left pagination-info text--small ellipsis muted">
                        Showing {this.state.startItem} to {this.state.endItem} of{' '}
                        {this.state.totalCount} entries
                    </span>
                )}

                <div className="float-right">
                    <nav>
                        <ReactPaginate
                            className="float-left"
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            forcePage={this.state.selectedPage}
                            pageCount={this.props.totalPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.props.pageChanged}
                            containerClassName={'pagination'}
                            subContainerClassName={'page'}
                            activeClassName={'active'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                        />
                    </nav>
                </div>
                {this.props.showPageSizeChanger && (
                    <div className="float-right show-entries text--small ellipsis muted">
                        <label>
                            Show{'   '}
                            <select
                                value={this.state.pageSize}
                                onChange={this.handlePageSizeChange}
                                style={{ marginRight: 3, marginLeft: 3 }}>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="70">70</option>
                                <option value="100">100</option>
                            </select>
                            {'   '}
                            entries
                        </label>
                    </div>
                )}
            </div>
        );
    }
}
Pagination.defaultProps = {
    showCountText: true,
    showPageSizeChanger: true,
};
export { Pagination };
