import React, { useState, useRef } from 'react';
import { useTheme } from '../../../App/Theme/ThemeContext';
import PlayMarker from '../PlayMarker';
import { AnnotationMarkers } from './AnnotationMarkers';
import FramePicker from './FramePicker';
import { useScreenSizes } from '../../../responsive/useScreenSizes';
import cx from 'classnames';
import { getProgressMarkerPosition } from '../VideoAnnotationUtil';
import PlayMarkerIcon from '../../../_shared/Icon/PlayMarkerIcon';

function VideoPlayerTimelineMobile({
    showAnnotationMarkers,
    currentFrameNumber,
    frameCount,
    onProgressClick,
    handleOnRangeSliderChange,
    rangeSelectorValue,
    onThumbCLick,
    videoMetadata,
    isCommentAddMode,
    seekToTime,
    timeLineWidth
}) {
    const CLICK_THRESHOLD = 200;
    const seekBarControl = useRef(null);
    const { isMobile } = useScreenSizes();
    var timelineHeight = !showAnnotationMarkers ? { height: '62px' } : { height: '62px' };
    let clickTimer;

    const timelinePosition = getProgressMarkerPosition(
        currentFrameNumber,
        frameCount
    );
    
    const [isDragging, setIsDragging] = useState(false);
    const offset = useRef({ x: 0, y: 0 });
        
    const onTouchMoveHandler = (e) => {
        
        if (isDragging !== true) {
            return;
        }

        if (isCommentAddMode) {
            return;
        }

        e.stopPropagation();
        
        const touch = e.touches[0];
        const originalDrag = touch.clientX - offset.current.x;
        let currentLeftValue = offset.current.currentLeft;
        const dragAmount = toggleSign(originalDrag); 
        
        

        let totalPassed = getProgressMarkerPosition(
            dragAmount,
            frameCount
        );

        let frameNumber = (totalPassed);
        
        let newFrameNumber = currentLeftValue + frameNumber
        
        if (newFrameNumber < 0) {  return; }

        if (newFrameNumber > frameCount) { return; }

        seekToTime(newFrameNumber);
    };
    
    const toggleSign = (x) => x ? -x : x;

    const onTouchStart = (e) => {        
        const touch = e.touches[0];
        offset.current = {
            x: touch.clientX,
            currentLeft : currentFrameNumber,          
        };

        clickTimer = setTimeout(() => {
            setIsDragging(true);
        }, CLICK_THRESHOLD);
    };
    const handleEnd = () => {
        
        clearTimeout(clickTimer);
        setIsDragging(false);
    };

    return (
        <div
            style={{ marginTop: '12px', transform: 'translateX(50%)'  }}
            className={cx('mrnda-video-editor-area-timeline-wrapper', {
                'mrnda-video-editor-area-timeline-wrapper--mobile': isMobile
            })}>
            <div
                className="hello-class"
                style={{ paddingRight: '24px', borderRadius: '4px', width: timeLineWidth }}
                >
                <div
                    ref={seekBarControl}                    
                    style={{
                        boxShadow: isDragging? 'rgba(0, 0, 0, 0.1) 0px 0px 5px' : 'none' ,
                        margin: 0,
                        left: `-${timelinePosition}px`,
                        ...timelineHeight,
                        
                    }}
                    className="mrnda-video-editor-area-timeline">
                    <div
                        id="mrnda-video-editor-area-controls-seekbar"
                        //style={{ backgroundColor: 'red' }}
                        style={{ backgroundColor: 'var(--tertiary-background-color)' }}
                        
                        className="mrnda-video-editor-area-controls-seekbar">
                        <div
                            // style={{ width: `${(currentFrameNumber / frameCount) * 100}%` }}
                            className="mrnda-video-editor-area-controls-seekbar-progress"></div>
                    </div>

                    <div
                        className="mrnda-video-editor-area-controls-markers"
                        onClick={onProgressClick}
                        onTouchStart={onTouchStart}
                        onTouchEnd={handleEnd}
                        onTouchMove={onTouchMoveHandler}
                        onMouseLeave={handleEnd}                        
                        >
                        {showAnnotationMarkers && (
                            <AnnotationMarkers
                                handleOnRangeSliderChange={handleOnRangeSliderChange}
                                rangeSelectorValue={rangeSelectorValue}
                            />
                        )}
                    </div>

                    {isCommentAddMode && (
                        <FramePicker
                            frameCount={frameCount}
                            onThumbCLick={onThumbCLick}
                            handleOnRangeSliderChange={handleOnRangeSliderChange}
                            rangeSelectorValue={rangeSelectorValue}
                            frameRate={videoMetadata.frameRate}
                        />
                    )}

                    {/* <div
                    className="mrnda-video-editor-area-controls-seekbar-tooltip"
                    id="video-player-tooltip"
                    style={{
                        left: toolTipLeftPosition,
                        display: displayToolTip ? 'block' : 'none'
                    }}>
                    {toolTipTimeInText}
                </div> */}
                </div>
                {/* <PlayMarker
                    {...{
                        isCommentAddMode,
                        frameCount: videoMetadata.frameCount,
                        currentFrameNumber,
                        seekToTime,
                        isStaticMarker: true
                    }}
                /> */}
                <div style={{pointerEvents: 'none', position: 'absolute', height: '80px', width: '100%', zIndex: 0, transform: 'translateY(-6.5px)'}}>
                    <PlayMarkerIcon isStaticMarker={true} isFocused={false}/>
                </div>
            </div>
        </div>
    );
}

export default VideoPlayerTimelineMobile;
