import React, { useEffect, useState, useCallback, version } from 'react';
import { connect } from 'react-redux';
import { alertActions, proofService, s3Service, tenantService } from '../../../components';
import { proofSummaryActions } from './proofSummary.actions';
import { proofSummaryConstants } from './proofSummary.constants';
import { history, config, utilService } from '../../../_helpers';
import { PageSummary } from './PageSummary';
const { getProofVersion, getAnnotationComments, runProofVersionSummary } = proofSummaryActions;
import './ProofSummary.scss';
import { ProofSummaryHeader } from './ProofSummaryHeader';

function ProofSummary(props) {
    const [proofVersionSummary, setProofVersion] = useState({ proofVersion: {}, versionCount: 0 });
    const [isPrintable, setIsPrintable] = useState(false);
    useEffect(() => {
        const { proofid, versionid } = props.match.params;
        if (!proofid || !versionid) {
            history.goBack();
            return;
        }
        async function fetchProofVersion(proofId, versionId) {
            var proofVersionSummaryFetched = await proofService.getProofVersionSummary(
                proofId,
                versionId,
            );
            var tenant = await tenantService.getCurrentTenantObject();
            var ext = utilService.getFileExtention(proofVersionSummaryFetched.proofVersion.name);
            var documentRequest = {
                DocumentId: `${proofVersionSummaryFetched.proofVersion.id}.${ext}`,
                TenantName: tenant.subdomain,
                DocumentName: proofVersionSummaryFetched.proofVersion.name,
                ObjectPath: `${proofVersionSummaryFetched.proofVersion.fileMetadata.fileURL}`,
                tenantInfo: await tenantService.getCurrentTenantObject()
            };
            await s3Service.cacheDocumentFromS3(documentRequest);
            var annotations = proofVersionSummaryFetched.proofVersion.annotations;
            if (annotations) {
                var annotationsParamObj = {
                    documentId: proofVersionSummaryFetched.proofVersion.id,
                    pageNumber: 0,
                    annotations,
                };
                await proofService.setAnnotations(annotationsParamObj);
            }
            setProofVersion(proofVersionSummaryFetched);
            document.title = `${proofVersionSummaryFetched.proofVersion.name} - Annotation Report - Brandshare Collaboration`;
        }
        async function fetchComments(proofid, versionid) {
            //props.dispatch(alertActions.loading(true));
            await props.dispatch(runProofVersionSummary(proofid, versionid));
            // props.dispatch(getAnnotationComments(proofid, versionid));
            await props.dispatch(getAnnotationComments(proofid, versionid));
            //props.dispatch(alertActions.loading(false));
        }
        props.dispatch(alertActions.loading(true));
        fetchProofVersion(proofid, versionid).then(() =>
            fetchComments(proofid, versionid).then(() =>
                props.dispatch(alertActions.loading(false)),
            ),
        );
        // fetchComments(proofid, versionid);
    }, []);
    useEffect(() => {
        if (isPrintable) {
            window.print();
            setIsPrintable(false);
        }
    }, [isPrintable]);
    const handlePrintOnClick = useCallback(() => {
        setIsPrintable(true);
        // window.print();
    });

    let heightStyle = {};
    let marginStyle= {};
    if(props.alert.isHideTopbar){
        heightStyle = {
            top: 0
        }
        marginStyle = {
            margin: "100px 0px 30px"
        }
    }
    return (
        <div className="main-content">
            <nav className="mrnda-sub-navigation navbar" style={heightStyle} style={{backgroundColor:'var(--secondary-background-color)'}} >
                <div className="mrnda-sub-navigation__title">
                    <h1>Annotation Report</h1>
                </div>
                <div className="mrnda-sub-navigation__options">
                    <ul className="mrnda-sub-navigation__list">
                        <li
                            onClick={handlePrintOnClick}
                            className="mrnda-sub-navigation__list-item"
                            title="Print/Save">
                            <button disabled={!props.isCached} className={'mrnda-btn'}>
                                <i className="fas fa-print" />
                                Print/Save
                            </button>
                        </li>
                    </ul>
                </div>
            </nav>
            <div id="proof-summary-print-area" className='annotation-report-print-area' style={{ backgroundColor: 'var(--primary-background-color)' }}>
                <div 
                    className={`'mx-auto my-auto print-summary-content`}
                    //className={`${isPrintable ? 'mx-auto my-auto' : ' '} print-summary-content`}
                    >
                    <ProofSummaryHeader
                        proofVersionSummary={proofVersionSummary}
                        pageCount={props.pageCount}
                        margins = {marginStyle}
                    />
                    <div className="proof-summary">
                        <div className="">
                            <section className="section-collapsible css-xl53lb eca69of0">
                                <header className="section-header">
                                    <div className="section-header-title">
                                        {/* <svg
                                            fill="currentColor"
                                            preserveAspectRatio="xMidYMid meet"
                                            height="16px"
                                            width="16px"
                                            className="icon"
                                            viewBox="0 0 16 16"
                                            style={{ verticalAlign: 'middle' }}>
                                            <path d="M8 3.5l-7.707 7.367c-0.18 0.17-0.293 0.41-0.293 0.676s0.112 0.507 0.292 0.676c0.185 0.174 0.434 0.281 0.708 0.281s0.523-0.107 0.708-0.281l6.292-6.015 6.293 6.015c0.184 0.174 0.433 0.28 0.707 0.28s0.523-0.107 0.708-0.281c0.18-0.169 0.292-0.409 0.292-0.676s-0.112-0.507-0.292-0.676z" />
                                        </svg> */}
                                        <span>Comments</span>
                                    </div>
                                </header>
                                <div className="section-body">
                                    <div className="comments-list page-thumbnails">
                                        {props.pageAnnotationComments.map((page, index) => (
                                            <PageSummary
                                                key={page.pageNumber}
                                                page={page}></PageSummary>
                                        ))}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { pageAnnotationComments, isCached, cachedDocumentId } = state.proofSummaryData;

    const pAnnotationComments =
        isCached && pageAnnotationComments
            ? pageAnnotationComments.map(pageAnnotation => {
                return {
                    ...pageAnnotation,
                    pageUrl: `${config.documentServiceHost}/api/page/GetImage?documentId=${cachedDocumentId}&pageNumber=${pageAnnotation.pageNumber}`,
                };
            })
            : [];
    console.log('mapStateToProps', pAnnotationComments);
    return {
        pageAnnotationComments: pAnnotationComments,
        pageCount: pAnnotationComments ? pAnnotationComments.length : 0,
        isCached,
        alert : state.alert
    };
}

const proofSummary = connect(mapStateToProps)(ProofSummary);
export { proofSummary as ProofSummary };
