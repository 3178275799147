//@ts-check
import React from 'react';
import { WorkflowManageProvider, withWorkflowManageConsumer } from './WorkflowManageProvider';
import WorkflowManage from '../WorkflowManage';

const WorkflowManageWrapper = (props) => {
    return (
        <WorkflowManageProvider>
            <WorkflowManageWithConsumer {...props}></WorkflowManageWithConsumer>
        </WorkflowManageProvider>
    );
};

const WorkflowManageWithConsumer = withWorkflowManageConsumer(WorkflowManage);

export { WorkflowManageWrapper };
