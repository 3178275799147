import { proofService, annotationService, DOWNLOAD_JOB_DATA } from '../';
import { proofSummaryConstants } from './proofSummary.constants';
import { alertConstants } from '../../App/alert.constants';
import { alertActions } from '../../App/alert.actions'
import { saveDocumentToCache } from './proofSummary.service';
import { utilService } from '../../../_helpers';
const {
    PROOFSUMMARY_GETPROOFVERION_REQUEST,
    PROOFSUMMARY_GETPROOFVERION_SUCCESS,
    PROOFSUMMARY_GETPROOFVERION_FAILURE,
    PROOFSUMMARY_ANNOTATIONCOMMENTS_REQUEST,
    PROOFSUMMARY_ANNOTATIONCOMMENTS_SUCCESS,
    PROOFSUMMARY_ANNOTATIONCOMMENTS_FAILURE,
    PROOFSUMMARY_DOWNLOADPAGE_REQUEST,
    PROOFSUMMARY_DOWNLOADPAGE_SUCCESS,
    PROOFSUMMARY_DOWNLOADPAGE_FAILURE
} = proofSummaryConstants;

export const proofSummaryActions = {
    getProofVersion,
    getAnnotationComments,
    runProofVersionSummary
};




function getAnnotationComments(proofId, versionId) {

    var requestObject = {
        proofId: proofId,
        proofVersionId: versionId,

    }
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            annotationService.getAnnotationComments(requestObject)
                .then(response => response.json())
                .then(
                    annotationComments => {

                        dispatch(success(annotationComments));
                        dispatch(loading(false));
                        resolve(annotationComments);
                    },
                    error => {
                        dispatch(failure(error.toString()));
                        dispatch(loading(false));
                        reject();
                    },
                );
        });
    };

    function request() {
        return { type: PROOFSUMMARY_ANNOTATIONCOMMENTS_REQUEST };
    }
    function success(data) {

        return {
            type: PROOFSUMMARY_ANNOTATIONCOMMENTS_SUCCESS,
            annotationComments: data.annotationPages,
        };
    }
    function failure(error) {
        return { type: PROOFSUMMARY_ANNOTATIONCOMMENTS_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}
function getProofVersion(proofId, versionId) {

    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            dispatch(loading(true));
            proofService.getProofVersion(proofId, versionId).then(
                proofVersion => {
                    dispatch(success(proofVersion));
                    dispatch(loading(false));
                    resolve(proofVersion);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(loading(false));
                    reject();
                },
            );
        });
    };

    function request() {
        return { type: PROOFSUMMARY_GETPROOFVERION_REQUEST };
    }
    function success(proofVersion) {
        return {
            type: PROOFSUMMARY_GETPROOFVERION_SUCCESS,
            proofVersion,
        };
    }
    function failure(error) {
        return { type: PROOFSUMMARY_GETPROOFVERION_FAILURE, error };
    }
    function loading(isLoading) {
        return { type: alertConstants.LOADING, isLoading: isLoading };
    }
}


function runProofVersionSummary(proofId, versionId) {

    return dispatch => {
        return new Promise(async resolve => {
            // const proofVersion = await proofService.getProofVersion(proofId, versionId);


            var toastId = await dispatch(alertActions.startToast('Processing Report...'));
            dispatch(alertActions.loading(true));
            dispatch(loading(true));
            var userToken = null;
            var jobToken = null;
            var jobData = DOWNLOAD_JOB_DATA;
            // var filename = utilService.getFileName(proofVersion.name);

            //jobData.documentName = `${filename}_v${proofVersion.versionNumber}`;
            var requestObject = {
                documentId: versionId,
                jobData: jobData,
            };
            var convertJobResult = await proofService.runConvertJob(requestObject);
            var minWaitFromResponse = 2000;
            var minWaitFromRequest = 3000;
            userToken = convertJobResult.userToken;
            jobToken = convertJobResult.jobToken;
            var requestTime = -1;
            var responseTime = -1;
            var runQuery = null;
            var queryDone = async function (statusJobData) {
                if (statusJobData.abort) {
                    return;
                }
                if (statusJobData.errorMessages && statusJobData.errorMessages.length) {
                    var messages = [
                        'The conversion job encountered an error:',
                        statusJobData.errorMessages[0],
                        'Check the console for more information.',
                    ];
                    return;
                } else if (statusJobData.isCompleted) {

                    const cacheUrl = statusJobData.outputDocumentUri;
                    const cacheDocumentId = cacheUrl.replace('leadcache://', '');

                    await saveDocumentToCache(cacheUrl);

                    var deleteQueryRequest = {
                        userToken: userToken,
                        jobToken: jobToken

                    }
                    await proofService.deleteConvertJob(deleteQueryRequest);
                    await dispatch(success(true, cacheDocumentId));
                    //await dispatch(getAnnotationComments(proofId, versionId));
                    await dispatch(alertActions.dismissToast(toastId));
                    dispatch(alertActions.loading(false));

                    resolve();
                    return;
                }
                // Keep querying
                await runQuery(Date.now());
            };
            runQuery = async function (responseTime) {
                var now = Date.now();
                if (requestTime !== -1) {
                    var timeSinceRequest = now - requestTime;
                    var timeSinceResponse = now - responseTime;
                    // If we haven't waited the minimum since the response and haven't waited the maximum since the request, wait
                    if (
                        timeSinceResponse < minWaitFromResponse ||
                        timeSinceRequest < minWaitFromRequest
                    ) {
                        var waitTime = Math.max(
                            minWaitFromResponse - timeSinceResponse,
                            minWaitFromRequest - timeSinceRequest,
                        );
                        setTimeout(async function () {
                            await runQuery(responseTime);
                        }, waitTime);
                        // resolve();
                        return;
                    }
                }
                var result = await proofService.queryConvertJob({ userToken, jobToken });
                requestTime = Date.now();
                await queryDone(result.jobData);
                // resolve();
                return;
            };
            await runQuery(Date.now());
        });
        function success(isCached, cacheDocumentId) {
            return {
                type: PROOFSUMMARY_DOWNLOADPAGE_SUCCESS,
                isCached,
                cacheDocumentId
            };
        }
        function failure(error) {
            return { type: PROOFSUMMARY_DOWNLOADPAGE_FAILURE, error };
        }
        function loading(isLoading) {
            return { type: PROOFSUMMARY_DOWNLOADPAGE_REQUEST, isProcessing: isLoading };
        }
    }
}



