import React from 'react';
import PinIcon from '../../../_shared/PinIcon';
import AudioLoudIcon from '../../../_shared/Icon/AudioLoudIcon';
import ColorIcon from '../../../_shared/Icon/ColorIcon';
import cx from 'classnames';
import AddCommentIcon from '../../../_shared/Icon/AddCommentIcon';
import AudioMutedIcon from '../../../_shared/Icon/AudioMutedIcon';
import { EditorToolbarMuteButton } from './EditorToolbarMuteButton';
import { EditorToolbarActionButton } from './EditorToolbarActionButton';
import { EditorToolbarColorBubble } from './EditorToolbarColorBubble';
import { getColorPropName } from '../../drawingHelper';

export const EditorToolbarActionNamesMobile = {
    color: 'ColorSelection',
    comment: 'AnnotationSelection',
    none : 'None'
};

const EditorToolbarActionsMobile = ({
    selectedToolbarAction,
    setSelectedToolbarAction,
    isMuted,
    handleAudioClicked,
    showMuteButton,
    selectedShape,
    currentContributor,
    showShapeEditingTools,
    currentAnnotationOnCanvas,
    selectedTool,
    selectedComment,
    videoPlayer
}) => {
    const editorToolbarActionsStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    };

    const handleColorPickerClicked = (e) => {        
        if (selectedToolbarAction === EditorToolbarActionNamesMobile.color) {
            setSelectedToolbarAction(EditorToolbarActionNamesMobile.none);
        }
        else {
            setSelectedToolbarAction(EditorToolbarActionNamesMobile.color);
        }
    };

    const handleCommentClicked = () => {
        if(selectedToolbarAction === EditorToolbarActionNamesMobile.comment) {
            setSelectedToolbarAction(EditorToolbarActionNamesMobile.none)
        }
        else {
            setSelectedToolbarAction(EditorToolbarActionNamesMobile.comment);
        }
    };

    

    const selectedColor =
        showShapeEditingTools === true && currentAnnotationOnCanvas && selectedComment !== -1
            ? currentAnnotationOnCanvas[getColorPropName(currentAnnotationOnCanvas)]
            : currentContributor?.color;
    
    const isColorChangeDisabled = selectedComment === -1 ? false : !showShapeEditingTools;

    return (
        <div id ="editor-toolbar-actions-mobile" className='skip-comment-click-outside' style={editorToolbarActionsStyle}>                        
            <EditorToolbarColorBubble
                isDisabled = {isColorChangeDisabled}
                handleColorPickerClicked={handleColorPickerClicked}
                selectedColor={selectedColor}
                selectedToolbarAction={selectedToolbarAction}/>

            <EditorToolbarActionButton
                handleClicked={handleCommentClicked}
                isPressed={selectedToolbarAction === EditorToolbarActionNamesMobile.comment}
                icon={
                    <AddCommentIcon
                        color={
                            selectedTool === '' || selectedTool === undefined
                                ? selectedToolbarAction === EditorToolbarActionNamesMobile.comment ? 'var(--primary-text-color)':'var(--secondary-text-color)'
                                : 'var(--primary-color)'
                        }
                    />
                }
            />

            {showMuteButton && (
                <EditorToolbarMuteButton
                    videoPlayer={videoPlayer}
                    handleAudioClicked={handleAudioClicked}></EditorToolbarMuteButton>
            )}
        </div>
    );
};

export default EditorToolbarActionsMobile;
