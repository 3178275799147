import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import ReactDataGrid from 'react-data-grid';
import { Data } from 'react-data-grid-addons';
import { Pagination, proofActions } from '../../../components';
import { history, utilService } from '../../../_helpers';
import { tenantService } from '../../Tenant';
const defaultColumnProperties = {
    sortable: false,
    filterable: false,
    resizable: true
};

const columns = [
    {
        key: 'name',
        name: 'Name',
        width: 250
    },
    {
        key: 'versionNumber',
        name: 'Version'
    },
    {
        key: 'createdAt',
        name: 'Created'
    },
    {
        key: 'createdBy',
        name: 'Owner'
    }
].map((c) => ({ ...c, ...defaultColumnProperties }));
const ROW_COUNT = 50;

const groupBy = ['jobType'];
class ListSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expandedRows: {},
            rows: [],
            pageSize: 10,
            pageIndex: 0,
            totalPages: 0,
            totalCount: 0,
            proofFilters: []
        };
    }

    async componentDidMount() {
        await this.loadProofVersionsList();
    }
    async loadProofVersionsList(passDefaultId = true) {

        let proofSettings = await tenantService.getProofSettings();    
        let proofFilters = [];
        
        if (proofSettings.comparisonType == 1){
            proofFilters = [{
                FilterColumn : "directoryNodeId",
                FilterValue : this.props.currentDirectoryNodeId
            }];
        }        
        else if (proofSettings.comparisonType == 2){
            proofFilters = [{
                FilterColumn : "proofId",
                FilterValue : this.props.proofId
            }];

        }
                            
        var requestObject = {
            page: this.state.pageIndex,
            pageSize: this.state.pageSize,
            proofSortModel: {
                sortDirection: -1,
                sortColumn: 'createdAt'
            },
            proofFilters: proofFilters,
            

        };

        if (passDefaultId && this.props.defaultSelectedId) {
            requestObject.defaultSelectedProofId = this.props.defaultSelectedProofId;
        }

        var proofVersionsResult = await this.props.dispatch(
            proofActions.getAllProofsVersions(requestObject)
        );
        const { list, pageIndex, pageSize, totalCount, totalPages } = proofVersionsResult;
        await utilService.setStateAsync.bind(this)({
            rows: list,
            pageIndex,
            pageSize,
            totalCount,
            totalPages,
            expandedRows: {}
        });
                        
        if(this.props.defaultSelectedId){                       
            var selected;

            for(let i = 0; i< list.length; i++){
                
                if(list[i].id ===  this.props.defaultSelectedId)
                {
                    selected = list[i];
                    break;
                }

                var versions = list[i].versions;
                if (versions){
                    for (let j = 0;  j< versions.length; j++ ){
                        
                        if(versions[j].id ===  this.props.defaultSelectedId)
                        {                            
                            selected = versions[j];
                            break;
                        }
                    }
                }
            }
            
            if(selected){
                this.setState({ selectedRow: selected }, () => {
                    this.props.onRowSelected(selected);
                });
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        // this.setState({
        //     rows: nextProps.rows || [],
        //     columns: nextProps.columns,
        //     totalPages: nextProps.totalPages,
        //     totalCount: nextProps.totalCount,
        //     proofsFilters: nextProps.proofsFilters
        //         ? nextProps.proofsFilters
        //         : this.state.proofsFilters,
        //     expandedRows: {},
        // });
    }
    getSubRowDetails(expandedRows, rowItem) {
        var versions = rowItem.versions
            ? rowItem.versions.map((item) => {
                  return { ...item };
              })
            : rowItem.versions;
        const isExpanded = expandedRows[rowItem.id] ? expandedRows[rowItem.id] : false;
        return {
            group: rowItem.versions && rowItem.versions.length > 0,
            expanded: isExpanded,
            children: versions,
            field: 'name',
            treeDepth: rowItem.treeDepth || 0,
            siblingIndex: rowItem.siblingIndex,
            numberSiblings: rowItem.numberSiblings
        };
    }

    updateSubRowDetails(subRows, parentTreeDepth) {
        const treeDepth = parentTreeDepth || 0;
        subRows.forEach((sr, i) => {
            sr.treeDepth = treeDepth + 1;
            sr.siblingIndex = i;
            sr.numberSiblings = subRows.length;
        });
    }

    onCellExpand(args, rows, expandedRows) {        
        const rowKey = args.rowData.id;
        const rowIndex = rows.indexOf(args.rowData);
        const subRows = args.expandArgs.children;
        if (expandedRows && !expandedRows[rowKey]) {
            expandedRows[rowKey] = true;
            this.updateSubRowDetails(subRows, args.rowData.treeDepth);
            rows.splice(rowIndex + 1, 0, ...subRows);
        } else if (expandedRows[rowKey]) {
            expandedRows[rowKey] = false;
            rows.splice(rowIndex + 1, subRows.length);
        }
        return { expandedRows, rows };
    }
    onRowSelected(index, row) {
        if (index === -1) return;

        var selectedRow = this.state.rows.find((item) => item.id == row.id);
        this.setState({ selectedRow: selectedRow }, () => {
            this.props.onRowSelected(selectedRow);
        });
    }
    async handlePageIndexChange(pageIndex) {
        await utilService.setStateAsync.bind(this)({ pageIndex: pageIndex });
        await this.loadProofVersionsList(false);
    }
    pageChanged(data) {
        const page = data.selected;
        this.setState({ pageIndex: page });
        if (this.handlePageIndexChange) {
            this.handlePageIndexChange(page);
        }
    }
    async handleSearchClick(event) {
        event.preventDefault();
        var { value } = event.target;
        await utilService.setStateAsync.bind(this)({
            proofFilters: value ? [{ filterColumn: 'documentName', filterValue: value }] : []
        });
        await this.loadProofVersionsList(false);
    }
    render() {
        const visibleRows = Data.Selectors.getRows(this.state);
        return (
            <div>
                <div className="d-flex justify-content-between">
                    {/* <div className="input-search" style={{ marginRight: '10px' }}>
                        <input
                            type="text"
                            className="input-search__input"
                            placeholder="Search..."
                            onChange={this.handleSearchClick.bind(this)}
                        />
                        <span style={{ marginLeft: '-25px', color: 'gray' }} className="">
                            <i className="fas fa-search" />
                        </span>
                    </div> */}
                    <div className="mrnda-input-text--search">
                        <input
                            type="text"
                            className="mrnda-input-text"
                            placeholder="Search..."
                            onChange={this.handleSearchClick.bind(this)}
                        />
                        <span className="mrnda-icon-btn">
                            <i className="fas fa-search" />
                        </span>
                    </div>
                    <Pagination
                        totalPages={this.state.totalPages}
                        pageChanged={this.pageChanged.bind(this)}
                        pageSizeChanged={() => {}}
                        selectedPage={this.state.pageIndex}
                        totalCount={this.state.totalCount}
                        showCountText={false}
                        showPageSizeChanger={false}
                    />
                </div>
                <ReactDataGrid
                    columns={columns}
                    rowGetter={(i) => visibleRows[i]}
                    rowsCount={visibleRows.length}
                    minHeight={400}
                    getSubRowDetails={(rowItem) =>
                        this.getSubRowDetails(this.state.expandedRows, rowItem)
                    }
                    onCellExpand={(args) =>
                        this.setState(({ rows, expandedRows }) =>
                            this.onCellExpand(args, rows, expandedRows)
                        )
                    }
                    enableCellAutoFocus={false}
                    enableCellSelect={false}
                    // enableRowSelect="single"
                    rowKey="id"
                    onRowClick={this.onRowSelected.bind(this)}
                />
            </div>
        );
    }
}
function mapStateToProps(state) {
    console.log('States in CreateProofModal:', state);
    const { s3Info } = state;
    return {
        s3TempInfo: s3Info.s3Info
    };
}

const connectedCreateProofModel = connect(mapStateToProps)(ListSelector);
export { connectedCreateProofModel as ListSelector };
