import React, { useEffect, useRef, useState } from 'react';
import { RangeSelector } from '../../../_shared';
import CheckIcon from '../../../_shared/Icon/CheckIcon';
import CloseIcon from '../../../_shared/Icon/CloseIcon';
import DragLeftIcon from '../../../_shared/Icon/DragLeftIcon';
import DragRightIcon from '../../../_shared/Icon/DragRightIcon';
import ArrowRightIcon from '../../../_shared/Icon/ArrowRightIcon';
import { getProgressMarkerPosition, getReadableTimeRange } from '../VideoAnnotationUtil';

function FramePicker({
    frameCount,
    onThumbCLick,
    handleOnRangeSliderChange,
    rangeSelectorValue,
    frameRate,
    framePickerOnCheckClick,
    framePickerOnCloseClick,
    ...props
}) {

    const [toolStyle, setToolStyle] = useState({});
    const toolsDivRef = useRef();
    const [currentFrameValues, setCurrentFrameValues] = useState([...rangeSelectorValue]); 

    useEffect( () => {
        positionTools(rangeSelectorValue, frameCount);
    }, [rangeSelectorValue, frameCount, toolsDivRef.current]) 

    const positionTools = (frames, frameCount) => {
        
        var positionStart = getProgressMarkerPosition(frames[0], frameCount);
        var positionEnd = getProgressMarkerPosition(frames[1], frameCount);
        var width  = positionEnd - positionStart;        
        var toolsWidth = toolsDivRef.current?.clientWidth;
        var finalValue =  (positionStart + width/2) - (toolsWidth === undefined? 0: toolsWidth/2);
        setToolStyle({ left : finalValue});
    }

    return (
        <div className="mrnda-video-editor-area-controls-rangle-selector">
            <div  ref = {toolsDivRef} style={toolStyle} className="mrnda-video-editor-area-controls-rangle-selector--tools">
                <div className="mrnda-video-editor-area-controls-rangle-selector--tools-range">
                    <MetadataTimeRange
                        frameRange={rangeSelectorValue}
                        frameRate={frameRate}></MetadataTimeRange>
                </div>

                {/* {rangeSelectorValue[0] !== rangeSelectorValue[1] && (
                    <>
                        <div onClick={framePickerOnCheckClick} className="mrnda-video-editor-area-controls-rangle-selector--tools-tick">
                            <CheckIcon />
                        </div>
                        <div onClick={framePickerOnCloseClick} className="mrnda-video-editor-area-controls-rangle-selector--tools-close">
                            <CloseIcon />
                        </div>
                    </>
                )} */}
            </div>

            <RangeSelector
                maxValue={frameCount}
                onThumbCLick={() => {
                    onThumbCLick();
                }}
                // onChange={handleOnRangeSliderChange}
                onDragging={handleOnRangeSliderChange} 
                value={[...rangeSelectorValue]}
                leftIcon={<DragLeftIcon width={5} />}
                rightIcon={<DragRightIcon width={5} />}
                disableSliderClick={true}
                {...props}
            />
        </div>
    );
}

const MetadataTimeRange = ({ frameRange, frameRate, classes }) => {
    if (frameRange[0] === frameRange[1]) {
        return (
            <span className={classes}>
                <span>{frameRange[0]}</span>
            </span>
        );
    } else {
        return (
            <span className={classes}>
                <span>
                    {frameRange[0]} <ArrowRightIcon /> {frameRange[1]}
                </span>
            </span>
        );
    }
};

export default FramePicker;
