import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { MultiSelect } from '../../_shared';
import { workflowService } from './services/workflow.service';
import { workflowStatusService } from './services/workflowStatus.service';
import { store } from '../../../_helpers';
import { alertActions } from '../../App';

function WorkflowConfigRow({
    workflowStatus,
    isHeader,
    membersData,
    workflow,
    updateWorkflow,
    maxStatusCount,
    workflowStatuses,
    filteredMembers
}) {
    const [isEnabled, setIsEnabled] = useState(false);
    const [notificationGroup, setNotificationGroup] = useState([]);
    useEffect(() => {        
        var total = workflow.statuses.filter(x => x.enabled === true).length;
        var allEnable = total === maxStatusCount;        
        setIsEnabled(allEnable);
        console.log(workflow.name, total, maxStatusCount, allEnable);
    }, [workflow.statuses]);

    const onCheckChanged = async (e) => {
        var newCheck = e.target.checked;        
        store.dispatch(alertActions.loading(true));
        {
            var requestData =
                isHeader !== true
                    ? buildRequestData([{ statusId: workflowStatus.id, isVisible: newCheck }])
                    : buildRequestData(buildFullList(newCheck));


        
            var res = await workflowService.updateStatusVisibility(requestData);
            updateWorkflow(res.workflow);
        }
        store.dispatch(alertActions.loading(false));
    };

    const buildFullList = (visibility) => {
        var list = [];

        workflowStatuses.forEach((status) => {
            list.push({
                statusId: status.id,
                isVisible: visibility
            });
        });

        return list;
    };


    const onNotificationMemberSelectChanged  =  async (selectedOptions)  => {            
        store.dispatch(alertActions.loading(true));

        var updateRequest = { 
            workflowId : workflow.id,
            statusId : workflowStatus.id,
            notificationGroup : selectedOptions            
        }

        var workflowCopy = {...workflow};
        workflowCopy.statuses.forEach(ele => {

            if (ele.id === workflowStatus.id) {
                ele.notificationGroup = selectedOptions;
            }
            return ele;
        });

        updateWorkflow(workflowCopy); 
        await workflowStatusService.updateWorkflowStatusNotificationGroup(updateRequest);    
        store.dispatch(alertActions.loading(false));
    }
    const buildRequestData = (statusVisibilityList) => {
        var requestData = {
            workflowId: workflow.id,
            statusVisibility: statusVisibilityList
        };

        return requestData;
    };
    let isChecked = isHeader ? isEnabled : workflow.statuses.some((x) => x.id == workflowStatus.id);
    return (
        <div
            className="workflow-config-row"
            style={isHeader === true ? { marginTop: '12px', marginBottom: '12px' } : {}}>
            <div className="workflow-config-cell-enable">
                <label className="check-container workflow-config-visibility__check">
                    
                    <input
                        type="checkbox"
                        data-toggle="toggle"
                        data-on="Enabled"
                        data-off="Disabled"
                        name="visibility"
                        value={ isHeader === true? isEnabled :  workflowStatus?.enabled }
                        checked={ isHeader === true? isEnabled : workflowStatus?.enabled  }
                        onChange={(e) => {
                            onCheckChanged(e);
                        }}
                    />
                    <span
                        style={{ margin: 0, height: '17px' }}
                        className="check-container__checkmark">
                        <i className="fas fa-check" />
                    </span>
                </label>

                <span className="workflow-config-cell-enable-label">
                        {isHeader === true ? 'Select All' : workflowStatus.label}
                </span>

            </div>

            <div className="workflow-config-cell-group">
                {isHeader === true ? (
                    <span>Notify users / groups</span>
                ) : (
                    <div style={{zIndex: '2' }} >
                        <span>
                            <MultiSelect
                                isMulti={true}
                                options={filteredMembers}
                                selectedOptions={workflowStatus.notificationGroup}
                                onChange={(selectedOptions, addedUserIds, removedUserIds) =>
                                    onNotificationMemberSelectChanged(
                                        selectedOptions
                                    )
                                }
                                // onFocus={() => this.onMembersSelectFocus(permission.value)}
                                closeMenuOnSelect={true}
                            />
                        </span>

                            {/* <button
                                className="btn mrnda-btn"
                                onClick={() => {
                                }}
                            >
                                Save
                            </button> */}
                    </div>
                )}
            </div>
        </div>
    );
}

export default WorkflowConfigRow;
